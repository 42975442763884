body {
	color: #666666;
	font-size: 13px;
	font-weight: 400;
	line-height: 1.471;
	margin: 0 !important;
	height: auto;
}

html { height: auto; overflow:auto; }

.clearfix:after, form:after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}

.btn-group-vertical>.btn-group:after, .btn-group-vertical>.btn-group:before, .btn-toolbar:after, .btn-toolbar:before, .clearfix:after, .clearfix:before, .container-fluid:after, .container-fluid:before, .container:after, .container:before, .dl-horizontal dd:after, .dl-horizontal dd:before, .form-horizontal .form-group:after, .form-horizontal .form-group:before, .modal-footer:after, .modal-footer:before, .modal-header:after, .modal-header:before, .nav:after, .nav:before, .navbar-collapse:after, .navbar-collapse:before, .navbar-header:after, .navbar-header:before, .navbar:after, .navbar:before, .pager:after, .pager:before, .panel-body:after, .panel-body:before, .row:after, .row:before {
	display: table;
	content: " ";
}

*, :after, :before {
	box-sizing: border-box;
}


.top_nav {
	grid-area: header;
}

.nav_content{
	grid-area: nav-content;
}

.left-navigation {
	grid-area: navigation;
}

.right_content {
	grid-area: content;
}

footer{
	grid-area: footer;
	justify-items: center;
}

.nav_title{
	grid-area: logo;
	display: none;
}

.main_container {
	display: grid;
	grid-template-rows: 57px 1fr;
	grid-template-columns: 1fr;
	grid-template-areas: 
		"header"
		"nav-content"
}

.right_content {
	background: #F7F7F7
}

@media (min-width: 500px) {
	.main_container {
	grid-template-areas: 
		"header"
		"nav-content"
	}
}

@media (min-width: 700px) {
	.main_container {
	grid-template-columns: 0.05fr 1fr;
	grid-template-areas: 
		"logo     \9\9header"
		"nav-content\9nav-content"
	}
	
	.left-navigation, .nav_title{
		display: block;
	}

	.nav_content{
        display: grid;
        grid-template-columns: 0.05fr 1fr;
		grid-template-rows: 1fr -webkit-min-content ;
		grid-template-rows: 1fr min-content ;
        grid-template-areas: 
            "navigation        content"
            "navigation        footer"
    }
}

@media (max-width: 670px){
    .nav-sm .nav_content{
        display: grid;
		grid-template-rows: 1fr -webkit-min-content ;
		grid-template-rows: 1fr min-content ;
        grid-template-areas: 
            "navigation     content"
            "navigation        footer"
    }

    .nav-md .nav_content{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: 
            "content"
            "footer"
    }
}

/*!
 * Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2022 Fonticons, Inc.
 */
 .fa {
	font-family: "Font Awesome 6 Pro";
	font-family: var(--fa-style-family, "Font Awesome 6 Pro");
	font-weight: 900;
	font-weight: var(--fa-style, 900); }
  
  .fa,
  .fa-classic,
  .fa-sharp,
  .fas,
  .fa-solid,
  .far,
  .fa-regular,
  .fal,
  .fa-light,
  .fat,
  .fa-thin,
  .fad,
  .fa-duotone,
  .fass,
  .fa-sharp-solid,
  .fab,
  .fa-brands {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	display: var(--fa-display, inline-block);
	font-style: normal;
	-webkit-font-feature-settings: normal;
	        font-feature-settings: normal;
	font-variant: normal;
	line-height: 1;
	text-rendering: auto; }
  
  .fas,
  .fa-classic,
  .fa-solid,
  .far,
  .fa-regular,
  .fal,
  .fa-light,
  .fat,
  .fa-thin {
	font-family: 'Font Awesome 6 Pro'; }
  
  .fab,
  .fa-brands {
	font-family: 'Font Awesome 6 Brands'; }
  
  .fad,
  .fa-classic.fa-duotone,
  .fa-duotone {
	font-family: 'Font Awesome 6 Duotone'; }
  
  .fass,
  .fa-sharp {
	font-family: 'Font Awesome 6 Sharp';
	font-weight: 900; }
  
  .fa-1x {
	font-size: 1em; }
  
  .fa-2x {
	font-size: 2em; }
  
  .fa-3x {
	font-size: 3em; }
  
  .fa-4x {
	font-size: 4em; }
  
  .fa-5x {
	font-size: 5em; }
  
  .fa-6x {
	font-size: 6em; }
  
  .fa-7x {
	font-size: 7em; }
  
  .fa-8x {
	font-size: 8em; }
  
  .fa-9x {
	font-size: 9em; }
  
  .fa-10x {
	font-size: 10em; }
  
  .fa-2xs {
	font-size: 0.625em;
	line-height: 0.1em;
	vertical-align: 0.225em; }
  
  .fa-xs {
	font-size: 0.75em;
	line-height: 0.08333em;
	vertical-align: 0.125em; }
  
  .fa-sm {
	font-size: 0.875em;
	line-height: 0.07143em;
	vertical-align: 0.05357em; }
  
  .fa-lg {
	font-size: 1.25em;
	line-height: 0.05em;
	vertical-align: -0.075em; }
  
  .fa-xl {
	font-size: 1.5em;
	line-height: 0.04167em;
	vertical-align: -0.125em; }
  
  .fa-2xl {
	font-size: 2em;
	line-height: 0.03125em;
	vertical-align: -0.1875em; }
  
  .fa-fw {
	text-align: center;
	width: 1.25em; }
  
  .fa-ul {
	list-style-type: none;
	margin-left: 2.5em;
	margin-left: var(--fa-li-margin, 2.5em);
	padding-left: 0; }
	.fa-ul > li {
	  position: relative; }
  
  .fa-li {
	left: calc(2em * -1);
	left: calc(var(--fa-li-width, 2em) * -1);
	position: absolute;
	text-align: center;
	width: 2em;
	width: var(--fa-li-width, 2em);
	line-height: inherit; }
  
  .fa-border {
	border-color: #eee;
	border-color: var(--fa-border-color, #eee);
	border-radius: 0.1em;
	border-radius: var(--fa-border-radius, 0.1em);
	border-style: solid;
	border-style: var(--fa-border-style, solid);
	border-width: 0.08em;
	border-width: var(--fa-border-width, 0.08em);
	padding: 0.2em 0.25em 0.15em;
	padding: var(--fa-border-padding, 0.2em 0.25em 0.15em); }
  
  .fa-pull-left {
	float: left;
	margin-right: 0.3em;
	margin-right: var(--fa-pull-margin, 0.3em); }
  
  .fa-pull-right {
	float: right;
	margin-left: 0.3em;
	margin-left: var(--fa-pull-margin, 0.3em); }
  
  .fa-beat {
	-webkit-animation-name: fa-beat;
			animation-name: fa-beat;
	-webkit-animation-delay: 0s;
	-webkit-animation-delay: var(--fa-animation-delay, 0s);
			animation-delay: 0s;
			animation-delay: var(--fa-animation-delay, 0s);
	-webkit-animation-direction: normal;
	-webkit-animation-direction: var(--fa-animation-direction, normal);
			animation-direction: normal;
			animation-direction: var(--fa-animation-direction, normal);
	-webkit-animation-duration: 1s;
	-webkit-animation-duration: var(--fa-animation-duration, 1s);
			animation-duration: 1s;
			animation-duration: var(--fa-animation-duration, 1s);
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
			animation-iteration-count: infinite;
			animation-iteration-count: var(--fa-animation-iteration-count, infinite);
	-webkit-animation-timing-function: ease-in-out;
	-webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
			animation-timing-function: ease-in-out;
			animation-timing-function: var(--fa-animation-timing, ease-in-out); }
  
  .fa-bounce {
	-webkit-animation-name: fa-bounce;
			animation-name: fa-bounce;
	-webkit-animation-delay: 0s;
	-webkit-animation-delay: var(--fa-animation-delay, 0s);
			animation-delay: 0s;
			animation-delay: var(--fa-animation-delay, 0s);
	-webkit-animation-direction: normal;
	-webkit-animation-direction: var(--fa-animation-direction, normal);
			animation-direction: normal;
			animation-direction: var(--fa-animation-direction, normal);
	-webkit-animation-duration: 1s;
	-webkit-animation-duration: var(--fa-animation-duration, 1s);
			animation-duration: 1s;
			animation-duration: var(--fa-animation-duration, 1s);
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
			animation-iteration-count: infinite;
			animation-iteration-count: var(--fa-animation-iteration-count, infinite);
	-webkit-animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
	-webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
			animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
			animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1)); }
  
  .fa-fade {
	-webkit-animation-name: fa-fade;
			animation-name: fa-fade;
	-webkit-animation-delay: 0s;
	-webkit-animation-delay: var(--fa-animation-delay, 0s);
			animation-delay: 0s;
			animation-delay: var(--fa-animation-delay, 0s);
	-webkit-animation-direction: normal;
	-webkit-animation-direction: var(--fa-animation-direction, normal);
			animation-direction: normal;
			animation-direction: var(--fa-animation-direction, normal);
	-webkit-animation-duration: 1s;
	-webkit-animation-duration: var(--fa-animation-duration, 1s);
			animation-duration: 1s;
			animation-duration: var(--fa-animation-duration, 1s);
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
			animation-iteration-count: infinite;
			animation-iteration-count: var(--fa-animation-iteration-count, infinite);
	-webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
	-webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
			animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
			animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }
  
  .fa-beat-fade {
	-webkit-animation-name: fa-beat-fade;
			animation-name: fa-beat-fade;
	-webkit-animation-delay: 0s;
	-webkit-animation-delay: var(--fa-animation-delay, 0s);
			animation-delay: 0s;
			animation-delay: var(--fa-animation-delay, 0s);
	-webkit-animation-direction: normal;
	-webkit-animation-direction: var(--fa-animation-direction, normal);
			animation-direction: normal;
			animation-direction: var(--fa-animation-direction, normal);
	-webkit-animation-duration: 1s;
	-webkit-animation-duration: var(--fa-animation-duration, 1s);
			animation-duration: 1s;
			animation-duration: var(--fa-animation-duration, 1s);
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
			animation-iteration-count: infinite;
			animation-iteration-count: var(--fa-animation-iteration-count, infinite);
	-webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
	-webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
			animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
			animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }
  
  .fa-flip {
	-webkit-animation-name: fa-flip;
			animation-name: fa-flip;
	-webkit-animation-delay: 0s;
	-webkit-animation-delay: var(--fa-animation-delay, 0s);
			animation-delay: 0s;
			animation-delay: var(--fa-animation-delay, 0s);
	-webkit-animation-direction: normal;
	-webkit-animation-direction: var(--fa-animation-direction, normal);
			animation-direction: normal;
			animation-direction: var(--fa-animation-direction, normal);
	-webkit-animation-duration: 1s;
	-webkit-animation-duration: var(--fa-animation-duration, 1s);
			animation-duration: 1s;
			animation-duration: var(--fa-animation-duration, 1s);
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
			animation-iteration-count: infinite;
			animation-iteration-count: var(--fa-animation-iteration-count, infinite);
	-webkit-animation-timing-function: ease-in-out;
	-webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
			animation-timing-function: ease-in-out;
			animation-timing-function: var(--fa-animation-timing, ease-in-out); }
  
  .fa-shake {
	-webkit-animation-name: fa-shake;
			animation-name: fa-shake;
	-webkit-animation-delay: 0s;
	-webkit-animation-delay: var(--fa-animation-delay, 0s);
			animation-delay: 0s;
			animation-delay: var(--fa-animation-delay, 0s);
	-webkit-animation-direction: normal;
	-webkit-animation-direction: var(--fa-animation-direction, normal);
			animation-direction: normal;
			animation-direction: var(--fa-animation-direction, normal);
	-webkit-animation-duration: 1s;
	-webkit-animation-duration: var(--fa-animation-duration, 1s);
			animation-duration: 1s;
			animation-duration: var(--fa-animation-duration, 1s);
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
			animation-iteration-count: infinite;
			animation-iteration-count: var(--fa-animation-iteration-count, infinite);
	-webkit-animation-timing-function: linear;
	-webkit-animation-timing-function: var(--fa-animation-timing, linear);
			animation-timing-function: linear;
			animation-timing-function: var(--fa-animation-timing, linear); }
  
  .fa-spin {
	-webkit-animation-name: fa-spin;
			animation-name: fa-spin;
	-webkit-animation-delay: 0s;
	-webkit-animation-delay: var(--fa-animation-delay, 0s);
			animation-delay: 0s;
			animation-delay: var(--fa-animation-delay, 0s);
	-webkit-animation-direction: normal;
	-webkit-animation-direction: var(--fa-animation-direction, normal);
			animation-direction: normal;
			animation-direction: var(--fa-animation-direction, normal);
	-webkit-animation-duration: 2s;
	-webkit-animation-duration: var(--fa-animation-duration, 2s);
			animation-duration: 2s;
			animation-duration: var(--fa-animation-duration, 2s);
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
			animation-iteration-count: infinite;
			animation-iteration-count: var(--fa-animation-iteration-count, infinite);
	-webkit-animation-timing-function: linear;
	-webkit-animation-timing-function: var(--fa-animation-timing, linear);
			animation-timing-function: linear;
			animation-timing-function: var(--fa-animation-timing, linear); }
  
  .fa-spin-reverse {
	--fa-animation-direction: reverse; }
  
  .fa-pulse,
  .fa-spin-pulse {
	-webkit-animation-name: fa-spin;
			animation-name: fa-spin;
	-webkit-animation-direction: normal;
	-webkit-animation-direction: var(--fa-animation-direction, normal);
			animation-direction: normal;
			animation-direction: var(--fa-animation-direction, normal);
	-webkit-animation-duration: 1s;
	-webkit-animation-duration: var(--fa-animation-duration, 1s);
			animation-duration: 1s;
			animation-duration: var(--fa-animation-duration, 1s);
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
			animation-iteration-count: infinite;
			animation-iteration-count: var(--fa-animation-iteration-count, infinite);
	-webkit-animation-timing-function: steps(8);
	-webkit-animation-timing-function: var(--fa-animation-timing, steps(8));
			animation-timing-function: steps(8);
			animation-timing-function: var(--fa-animation-timing, steps(8)); }
  
  @media (prefers-reduced-motion: reduce) {
	.fa-beat,
	.fa-bounce,
	.fa-fade,
	.fa-beat-fade,
	.fa-flip,
	.fa-pulse,
	.fa-shake,
	.fa-spin,
	.fa-spin-pulse {
	  -webkit-animation-delay: -1ms;
			  animation-delay: -1ms;
	  -webkit-animation-duration: 1ms;
			  animation-duration: 1ms;
	  -webkit-animation-iteration-count: 1;
			  animation-iteration-count: 1;
	  transition-delay: 0s;
	  transition-duration: 0s; } }
  
  @-webkit-keyframes fa-beat {
	0%, 90% {
	  -webkit-transform: scale(1);
			  transform: scale(1); }
	45% {
	  -webkit-transform: scale(1.25);
	  -webkit-transform: scale(var(--fa-beat-scale, 1.25));
			  transform: scale(1.25);
			  transform: scale(var(--fa-beat-scale, 1.25)); } }
  
  @keyframes fa-beat {
	0%, 90% {
	  -webkit-transform: scale(1);
			  transform: scale(1); }
	45% {
	  -webkit-transform: scale(1.25);
	  -webkit-transform: scale(var(--fa-beat-scale, 1.25));
			  transform: scale(1.25);
			  transform: scale(var(--fa-beat-scale, 1.25)); } }
  
  @-webkit-keyframes fa-bounce {
	0% {
	  -webkit-transform: scale(1, 1) translateY(0);
			  transform: scale(1, 1) translateY(0); }
	10% {
	  -webkit-transform: scale(1.1, 0.9) translateY(0);
	  -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
			  transform: scale(1.1, 0.9) translateY(0);
			  transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0); }
	30% {
	  -webkit-transform: scale(0.9, 1.1) translateY(-0.5em);
	  -webkit-transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
			  transform: scale(0.9, 1.1) translateY(-0.5em);
			  transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em)); }
	50% {
	  -webkit-transform: scale(1.05, 0.95) translateY(0);
	  -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
			  transform: scale(1.05, 0.95) translateY(0);
			  transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0); }
	57% {
	  -webkit-transform: scale(1, 1) translateY(-0.125em);
	  -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
			  transform: scale(1, 1) translateY(-0.125em);
			  transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em)); }
	64% {
	  -webkit-transform: scale(1, 1) translateY(0);
			  transform: scale(1, 1) translateY(0); }
	100% {
	  -webkit-transform: scale(1, 1) translateY(0);
			  transform: scale(1, 1) translateY(0); } }
  
  @keyframes fa-bounce {
	0% {
	  -webkit-transform: scale(1, 1) translateY(0);
			  transform: scale(1, 1) translateY(0); }
	10% {
	  -webkit-transform: scale(1.1, 0.9) translateY(0);
	  -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
			  transform: scale(1.1, 0.9) translateY(0);
			  transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0); }
	30% {
	  -webkit-transform: scale(0.9, 1.1) translateY(-0.5em);
	  -webkit-transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
			  transform: scale(0.9, 1.1) translateY(-0.5em);
			  transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em)); }
	50% {
	  -webkit-transform: scale(1.05, 0.95) translateY(0);
	  -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
			  transform: scale(1.05, 0.95) translateY(0);
			  transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0); }
	57% {
	  -webkit-transform: scale(1, 1) translateY(-0.125em);
	  -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
			  transform: scale(1, 1) translateY(-0.125em);
			  transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em)); }
	64% {
	  -webkit-transform: scale(1, 1) translateY(0);
			  transform: scale(1, 1) translateY(0); }
	100% {
	  -webkit-transform: scale(1, 1) translateY(0);
			  transform: scale(1, 1) translateY(0); } }
  
  @-webkit-keyframes fa-fade {
	50% {
	  opacity: 0.4;
	  opacity: var(--fa-fade-opacity, 0.4); } }
  
  @keyframes fa-fade {
	50% {
	  opacity: 0.4;
	  opacity: var(--fa-fade-opacity, 0.4); } }
  
  @-webkit-keyframes fa-beat-fade {
	0%, 100% {
	  opacity: 0.4;
	  opacity: var(--fa-beat-fade-opacity, 0.4);
	  -webkit-transform: scale(1);
			  transform: scale(1); }
	50% {
	  opacity: 1;
	  -webkit-transform: scale(1.125);
	  -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
			  transform: scale(1.125);
			  transform: scale(var(--fa-beat-fade-scale, 1.125)); } }
  
  @keyframes fa-beat-fade {
	0%, 100% {
	  opacity: 0.4;
	  opacity: var(--fa-beat-fade-opacity, 0.4);
	  -webkit-transform: scale(1);
			  transform: scale(1); }
	50% {
	  opacity: 1;
	  -webkit-transform: scale(1.125);
	  -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
			  transform: scale(1.125);
			  transform: scale(var(--fa-beat-fade-scale, 1.125)); } }
  
  @-webkit-keyframes fa-flip {
	50% {
	  -webkit-transform: rotate3d(0, 1, 0, -180deg);
	  -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
			  transform: rotate3d(0, 1, 0, -180deg);
			  transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg)); } }
  
  @keyframes fa-flip {
	50% {
	  -webkit-transform: rotate3d(0, 1, 0, -180deg);
	  -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
			  transform: rotate3d(0, 1, 0, -180deg);
			  transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg)); } }
  
  @-webkit-keyframes fa-shake {
	0% {
	  -webkit-transform: rotate(-15deg);
			  transform: rotate(-15deg); }
	4% {
	  -webkit-transform: rotate(15deg);
			  transform: rotate(15deg); }
	8%, 24% {
	  -webkit-transform: rotate(-18deg);
			  transform: rotate(-18deg); }
	12%, 28% {
	  -webkit-transform: rotate(18deg);
			  transform: rotate(18deg); }
	16% {
	  -webkit-transform: rotate(-22deg);
			  transform: rotate(-22deg); }
	20% {
	  -webkit-transform: rotate(22deg);
			  transform: rotate(22deg); }
	32% {
	  -webkit-transform: rotate(-12deg);
			  transform: rotate(-12deg); }
	36% {
	  -webkit-transform: rotate(12deg);
			  transform: rotate(12deg); }
	40%, 100% {
	  -webkit-transform: rotate(0deg);
			  transform: rotate(0deg); } }
  
  @keyframes fa-shake {
	0% {
	  -webkit-transform: rotate(-15deg);
			  transform: rotate(-15deg); }
	4% {
	  -webkit-transform: rotate(15deg);
			  transform: rotate(15deg); }
	8%, 24% {
	  -webkit-transform: rotate(-18deg);
			  transform: rotate(-18deg); }
	12%, 28% {
	  -webkit-transform: rotate(18deg);
			  transform: rotate(18deg); }
	16% {
	  -webkit-transform: rotate(-22deg);
			  transform: rotate(-22deg); }
	20% {
	  -webkit-transform: rotate(22deg);
			  transform: rotate(22deg); }
	32% {
	  -webkit-transform: rotate(-12deg);
			  transform: rotate(-12deg); }
	36% {
	  -webkit-transform: rotate(12deg);
			  transform: rotate(12deg); }
	40%, 100% {
	  -webkit-transform: rotate(0deg);
			  transform: rotate(0deg); } }
  
  @-webkit-keyframes fa-spin {
	0% {
	  -webkit-transform: rotate(0deg);
			  transform: rotate(0deg); }
	100% {
	  -webkit-transform: rotate(360deg);
			  transform: rotate(360deg); } }
  
  @keyframes fa-spin {
	0% {
	  -webkit-transform: rotate(0deg);
			  transform: rotate(0deg); }
	100% {
	  -webkit-transform: rotate(360deg);
			  transform: rotate(360deg); } }
  
  .fa-rotate-90 {
	-webkit-transform: rotate(90deg);
			transform: rotate(90deg); }
  
  .fa-rotate-180 {
	-webkit-transform: rotate(180deg);
			transform: rotate(180deg); }
  
  .fa-rotate-270 {
	-webkit-transform: rotate(270deg);
			transform: rotate(270deg); }
  
  .fa-flip-horizontal {
	-webkit-transform: scale(-1, 1);
			transform: scale(-1, 1); }
  
  .fa-flip-vertical {
	-webkit-transform: scale(1, -1);
			transform: scale(1, -1); }
  
  .fa-flip-both,
  .fa-flip-horizontal.fa-flip-vertical {
	-webkit-transform: scale(-1, -1);
			transform: scale(-1, -1); }
  
  .fa-rotate-by {
	-webkit-transform: rotate(none);
	-webkit-transform: rotate(var(--fa-rotate-angle, none));
			transform: rotate(none);
			transform: rotate(var(--fa-rotate-angle, none)); }
  
  .fa-stack {
	display: inline-block;
	height: 2em;
	line-height: 2em;
	position: relative;
	vertical-align: middle;
	width: 2.5em; }
  
  .fa-stack-1x,
  .fa-stack-2x {
	left: 0;
	position: absolute;
	text-align: center;
	width: 100%;
	z-index: auto;
	z-index: var(--fa-stack-z-index, auto); }
  
  .fa-stack-1x {
	line-height: inherit; }
  
  .fa-stack-2x {
	font-size: 2em; }
  
  .fa-inverse {
	color: #fff;
	color: var(--fa-inverse, #fff); }
  
  /* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
  readers do not read off random characters that represent icons */
  .fa-0::before {
	content: "0"; }
  
  .fa-1::before {
	content: "1"; }
  
  .fa-2::before {
	content: "2"; }
  
  .fa-3::before {
	content: "3"; }
  
  .fa-4::before {
	content: "4"; }
  
  .fa-5::before {
	content: "5"; }
  
  .fa-6::before {
	content: "6"; }
  
  .fa-7::before {
	content: "7"; }
  
  .fa-8::before {
	content: "8"; }
  
  .fa-9::before {
	content: "9"; }
  
  .fa-fill-drip::before {
	content: "\F576"; }
  
  .fa-arrows-to-circle::before {
	content: "\E4BD"; }
  
  .fa-circle-chevron-right::before {
	content: "\F138"; }
  
  .fa-chevron-circle-right::before {
	content: "\F138"; }
  
  .fa-wagon-covered::before {
	content: "\F8EE"; }
  
  .fa-line-height::before {
	content: "\F871"; }
  
  .fa-bagel::before {
	content: "\E3D7"; }
  
  .fa-transporter-7::before {
	content: "\E2A8"; }
  
  .fa-at::before {
	content: "@"; }
  
  .fa-rectangles-mixed::before {
	content: "\E323"; }
  
  .fa-phone-arrow-up-right::before {
	content: "\E224"; }
  
  .fa-phone-arrow-up::before {
	content: "\E224"; }
  
  .fa-phone-outgoing::before {
	content: "\E224"; }
  
  .fa-trash-can::before {
	content: "\F2ED"; }
  
  .fa-trash-alt::before {
	content: "\F2ED"; }
  
  .fa-circle-l::before {
	content: "\E114"; }
  
  .fa-head-side-goggles::before {
	content: "\F6EA"; }
  
  .fa-head-vr::before {
	content: "\F6EA"; }
  
  .fa-text-height::before {
	content: "\F034"; }
  
  .fa-user-xmark::before {
	content: "\F235"; }
  
  .fa-user-times::before {
	content: "\F235"; }
  
  .fa-face-hand-yawn::before {
	content: "\E379"; }
  
  .fa-gauge-simple-min::before {
	content: "\F62D"; }
  
  .fa-tachometer-slowest::before {
	content: "\F62D"; }
  
  .fa-stethoscope::before {
	content: "\F0F1"; }
  
  .fa-coffin::before {
	content: "\F6C6"; }
  
  .fa-message::before {
	content: "\F27A"; }
  
  .fa-comment-alt::before {
	content: "\F27A"; }
  
  .fa-salad::before {
	content: "\F81E"; }
  
  .fa-bowl-salad::before {
	content: "\F81E"; }
  
  .fa-info::before {
	content: "\F129"; }
  
  .fa-robot-astromech::before {
	content: "\E2D2"; }
  
  .fa-ring-diamond::before {
	content: "\E5AB"; }
  
  .fa-fondue-pot::before {
	content: "\E40D"; }
  
  .fa-theta::before {
	content: "\F69E"; }
  
  .fa-face-hand-peeking::before {
	content: "\E481"; }
  
  .fa-square-user::before {
	content: "\E283"; }
  
  .fa-down-left-and-up-right-to-center::before {
	content: "\F422"; }
  
  .fa-compress-alt::before {
	content: "\F422"; }
  
  .fa-explosion::before {
	content: "\E4E9"; }
  
  .fa-file-lines::before {
	content: "\F15C"; }
  
  .fa-file-alt::before {
	content: "\F15C"; }
  
  .fa-file-text::before {
	content: "\F15C"; }
  
  .fa-wave-square::before {
	content: "\F83E"; }
  
  .fa-ring::before {
	content: "\F70B"; }
  
  .fa-building-un::before {
	content: "\E4D9"; }
  
  .fa-dice-three::before {
	content: "\F527"; }
  
  .fa-tire-pressure-warning::before {
	content: "\F633"; }
  
  .fa-wifi-fair::before {
	content: "\F6AB"; }
  
  .fa-wifi-2::before {
	content: "\F6AB"; }
  
  .fa-calendar-days::before {
	content: "\F073"; }
  
  .fa-calendar-alt::before {
	content: "\F073"; }
  
  .fa-mp3-player::before {
	content: "\F8CE"; }
  
  .fa-anchor-circle-check::before {
	content: "\E4AA"; }
  
  .fa-tally-4::before {
	content: "\E297"; }
  
  .fa-rectangle-history::before {
	content: "\E4A2"; }
  
  .fa-building-circle-arrow-right::before {
	content: "\E4D1"; }
  
  .fa-volleyball::before {
	content: "\F45F"; }
  
  .fa-volleyball-ball::before {
	content: "\F45F"; }
  
  .fa-sun-haze::before {
	content: "\F765"; }
  
  .fa-text-size::before {
	content: "\F894"; }
  
  .fa-ufo::before {
	content: "\E047"; }
  
  .fa-fork::before {
	content: "\F2E3"; }
  
  .fa-utensil-fork::before {
	content: "\F2E3"; }
  
  .fa-arrows-up-to-line::before {
	content: "\E4C2"; }
  
  .fa-mobile-signal::before {
	content: "\E1EF"; }
  
  .fa-barcode-scan::before {
	content: "\F465"; }
  
  .fa-sort-down::before {
	content: "\F0DD"; }
  
  .fa-sort-desc::before {
	content: "\F0DD"; }
  
  .fa-folder-arrow-down::before {
	content: "\E053"; }
  
  .fa-folder-download::before {
	content: "\E053"; }
  
  .fa-circle-minus::before {
	content: "\F056"; }
  
  .fa-minus-circle::before {
	content: "\F056"; }
  
  .fa-face-icicles::before {
	content: "\E37C"; }
  
  .fa-shovel::before {
	content: "\F713"; }
  
  .fa-door-open::before {
	content: "\F52B"; }
  
  .fa-films::before {
	content: "\E17A"; }
  
  .fa-right-from-bracket::before {
	content: "\F2F5"; }
  
  .fa-sign-out-alt::before {
	content: "\F2F5"; }
  
  .fa-face-glasses::before {
	content: "\E377"; }
  
  .fa-nfc::before {
	content: "\E1F7"; }
  
  .fa-atom::before {
	content: "\F5D2"; }
  
  .fa-soap::before {
	content: "\E06E"; }
  
  .fa-icons::before {
	content: "\F86D"; }
  
  .fa-heart-music-camera-bolt::before {
	content: "\F86D"; }
  
  .fa-microphone-lines-slash::before {
	content: "\F539"; }
  
  .fa-microphone-alt-slash::before {
	content: "\F539"; }
  
  .fa-closed-captioning-slash::before {
	content: "\E135"; }
  
  .fa-calculator-simple::before {
	content: "\F64C"; }
  
  .fa-calculator-alt::before {
	content: "\F64C"; }
  
  .fa-bridge-circle-check::before {
	content: "\E4C9"; }
  
  .fa-sliders-up::before {
	content: "\F3F1"; }
  
  .fa-sliders-v::before {
	content: "\F3F1"; }
  
  .fa-location-minus::before {
	content: "\F609"; }
  
  .fa-map-marker-minus::before {
	content: "\F609"; }
  
  .fa-pump-medical::before {
	content: "\E06A"; }
  
  .fa-fingerprint::before {
	content: "\F577"; }
  
  .fa-ski-boot::before {
	content: "\E3CC"; }
  
  .fa-standard-definition::before {
	content: "\E28A"; }
  
  .fa-rectangle-sd::before {
	content: "\E28A"; }
  
  .fa-h1::before {
	content: "\F313"; }
  
  .fa-hand-point-right::before {
	content: "\F0A4"; }
  
  .fa-magnifying-glass-location::before {
	content: "\F689"; }
  
  .fa-search-location::before {
	content: "\F689"; }
  
  .fa-message-bot::before {
	content: "\E3B8"; }
  
  .fa-forward-step::before {
	content: "\F051"; }
  
  .fa-step-forward::before {
	content: "\F051"; }
  
  .fa-face-smile-beam::before {
	content: "\F5B8"; }
  
  .fa-smile-beam::before {
	content: "\F5B8"; }
  
  .fa-light-ceiling::before {
	content: "\E016"; }
  
  .fa-message-exclamation::before {
	content: "\F4A5"; }
  
  .fa-comment-alt-exclamation::before {
	content: "\F4A5"; }
  
  .fa-bowl-scoop::before {
	content: "\E3DE"; }
  
  .fa-bowl-shaved-ice::before {
	content: "\E3DE"; }
  
  .fa-square-x::before {
	content: "\E286"; }
  
  .fa-utility-pole-double::before {
	content: "\E2C4"; }
  
  .fa-flag-checkered::before {
	content: "\F11E"; }
  
  .fa-chevrons-up::before {
	content: "\F325"; }
  
  .fa-chevron-double-up::before {
	content: "\F325"; }
  
  .fa-football::before {
	content: "\F44E"; }
  
  .fa-football-ball::before {
	content: "\F44E"; }
  
  .fa-user-vneck::before {
	content: "\E461"; }
  
  .fa-school-circle-exclamation::before {
	content: "\E56C"; }
  
  .fa-crop::before {
	content: "\F125"; }
  
  .fa-angles-down::before {
	content: "\F103"; }
  
  .fa-angle-double-down::before {
	content: "\F103"; }
  
  .fa-users-rectangle::before {
	content: "\E594"; }
  
  .fa-people-roof::before {
	content: "\E537"; }
  
  .fa-square-arrow-right::before {
	content: "\F33B"; }
  
  .fa-arrow-square-right::before {
	content: "\F33B"; }
  
  .fa-location-plus::before {
	content: "\F60A"; }
  
  .fa-map-marker-plus::before {
	content: "\F60A"; }
  
  .fa-lightbulb-exclamation-on::before {
	content: "\E1CA"; }
  
  .fa-people-line::before {
	content: "\E534"; }
  
  .fa-beer-mug-empty::before {
	content: "\F0FC"; }
  
  .fa-beer::before {
	content: "\F0FC"; }
  
  .fa-crate-empty::before {
	content: "\E151"; }
  
  .fa-diagram-predecessor::before {
	content: "\E477"; }
  
  .fa-transporter::before {
	content: "\E042"; }
  
  .fa-calendar-circle-user::before {
	content: "\E471"; }
  
  .fa-arrow-up-long::before {
	content: "\F176"; }
  
  .fa-long-arrow-up::before {
	content: "\F176"; }
  
  .fa-person-carry-box::before {
	content: "\F4CF"; }
  
  .fa-person-carry::before {
	content: "\F4CF"; }
  
  .fa-fire-flame-simple::before {
	content: "\F46A"; }
  
  .fa-burn::before {
	content: "\F46A"; }
  
  .fa-person::before {
	content: "\F183"; }
  
  .fa-male::before {
	content: "\F183"; }
  
  .fa-laptop::before {
	content: "\F109"; }
  
  .fa-file-csv::before {
	content: "\F6DD"; }
  
  .fa-menorah::before {
	content: "\F676"; }
  
  .fa-union::before {
	content: "\F6A2"; }
  
  .fa-chevrons-left::before {
	content: "\F323"; }
  
  .fa-chevron-double-left::before {
	content: "\F323"; }
  
  .fa-circle-heart::before {
	content: "\F4C7"; }
  
  .fa-heart-circle::before {
	content: "\F4C7"; }
  
  .fa-truck-plane::before {
	content: "\E58F"; }
  
  .fa-record-vinyl::before {
	content: "\F8D9"; }
  
  .fa-bring-forward::before {
	content: "\F856"; }
  
  .fa-square-p::before {
	content: "\E279"; }
  
  .fa-face-grin-stars::before {
	content: "\F587"; }
  
  .fa-grin-stars::before {
	content: "\F587"; }
  
  .fa-sigma::before {
	content: "\F68B"; }
  
  .fa-camera-movie::before {
	content: "\F8A9"; }
  
  .fa-bong::before {
	content: "\F55C"; }
  
  .fa-clarinet::before {
	content: "\F8AD"; }
  
  .fa-truck-flatbed::before {
	content: "\E2B6"; }
  
  .fa-spaghetti-monster-flying::before {
	content: "\F67B"; }
  
  .fa-pastafarianism::before {
	content: "\F67B"; }
  
  .fa-arrow-down-up-across-line::before {
	content: "\E4AF"; }
  
  .fa-leaf-heart::before {
	content: "\F4CB"; }
  
  .fa-house-building::before {
	content: "\E1B1"; }
  
  .fa-cheese-swiss::before {
	content: "\F7F0"; }
  
  .fa-spoon::before {
	content: "\F2E5"; }
  
  .fa-utensil-spoon::before {
	content: "\F2E5"; }
  
  .fa-jar-wheat::before {
	content: "\E517"; }
  
  .fa-envelopes-bulk::before {
	content: "\F674"; }
  
  .fa-mail-bulk::before {
	content: "\F674"; }
  
  .fa-file-circle-exclamation::before {
	content: "\E4EB"; }
  
  .fa-bow-arrow::before {
	content: "\F6B9"; }
  
  .fa-cart-xmark::before {
	content: "\E0DD"; }
  
  .fa-hexagon-xmark::before {
	content: "\F2EE"; }
  
  .fa-times-hexagon::before {
	content: "\F2EE"; }
  
  .fa-xmark-hexagon::before {
	content: "\F2EE"; }
  
  .fa-circle-h::before {
	content: "\F47E"; }
  
  .fa-hospital-symbol::before {
	content: "\F47E"; }
  
  .fa-merge::before {
	content: "\E526"; }
  
  .fa-pager::before {
	content: "\F815"; }
  
  .fa-cart-minus::before {
	content: "\E0DB"; }
  
  .fa-address-book::before {
	content: "\F2B9"; }
  
  .fa-contact-book::before {
	content: "\F2B9"; }
  
  .fa-pan-frying::before {
	content: "\E42C"; }
  
  .fa-grid::before {
	content: "\E195"; }
  
  .fa-grid-3::before {
	content: "\E195"; }
  
  .fa-football-helmet::before {
	content: "\F44F"; }
  
  .fa-hand-love::before {
	content: "\E1A5"; }
  
  .fa-trees::before {
	content: "\F724"; }
  
  .fa-strikethrough::before {
	content: "\F0CC"; }
  
  .fa-page::before {
	content: "\E428"; }
  
  .fa-k::before {
	content: "K"; }
  
  .fa-diagram-previous::before {
	content: "\E478"; }
  
  .fa-gauge-min::before {
	content: "\F628"; }
  
  .fa-tachometer-alt-slowest::before {
	content: "\F628"; }
  
  .fa-folder-grid::before {
	content: "\E188"; }
  
  .fa-eggplant::before {
	content: "\E16C"; }
  
  .fa-ram::before {
	content: "\F70A"; }
  
  .fa-landmark-flag::before {
	content: "\E51C"; }
  
  .fa-lips::before {
	content: "\F600"; }
  
  .fa-pencil::before {
	content: "\F303"; }
  
  .fa-pencil-alt::before {
	content: "\F303"; }
  
  .fa-backward::before {
	content: "\F04A"; }
  
  .fa-caret-right::before {
	content: "\F0DA"; }
  
  .fa-comments::before {
	content: "\F086"; }
  
  .fa-paste::before {
	content: "\F0EA"; }
  
  .fa-file-clipboard::before {
	content: "\F0EA"; }
  
  .fa-desktop-arrow-down::before {
	content: "\E155"; }
  
  .fa-code-pull-request::before {
	content: "\E13C"; }
  
  .fa-pumpkin::before {
	content: "\F707"; }
  
  .fa-clipboard-list::before {
	content: "\F46D"; }
  
  .fa-pen-field::before {
	content: "\E211"; }
  
  .fa-blueberries::before {
	content: "\E2E8"; }
  
  .fa-truck-ramp-box::before {
	content: "\F4DE"; }
  
  .fa-truck-loading::before {
	content: "\F4DE"; }
  
  .fa-note::before {
	content: "\E1FF"; }
  
  .fa-arrow-down-to-square::before {
	content: "\E096"; }
  
  .fa-user-check::before {
	content: "\F4FC"; }
  
  .fa-cloud-xmark::before {
	content: "\E35F"; }
  
  .fa-vial-virus::before {
	content: "\E597"; }
  
  .fa-book-blank::before {
	content: "\F5D9"; }
  
  .fa-book-alt::before {
	content: "\F5D9"; }
  
  .fa-golf-flag-hole::before {
	content: "\E3AC"; }
  
  .fa-message-arrow-down::before {
	content: "\E1DB"; }
  
  .fa-comment-alt-arrow-down::before {
	content: "\E1DB"; }
  
  .fa-face-unamused::before {
	content: "\E39F"; }
  
  .fa-sheet-plastic::before {
	content: "\E571"; }
  
  .fa-circle-9::before {
	content: "\E0F6"; }
  
  .fa-blog::before {
	content: "\F781"; }
  
  .fa-user-ninja::before {
	content: "\F504"; }
  
  .fa-pencil-slash::before {
	content: "\E215"; }
  
  .fa-bowling-pins::before {
	content: "\F437"; }
  
  .fa-person-arrow-up-from-line::before {
	content: "\E539"; }
  
  .fa-down-right::before {
	content: "\E16B"; }
  
  .fa-scroll-torah::before {
	content: "\F6A0"; }
  
  .fa-torah::before {
	content: "\F6A0"; }
  
  .fa-blinds-open::before {
	content: "\F8FC"; }
  
  .fa-fence::before {
	content: "\E303"; }
  
  .fa-up::before {
	content: "\F357"; }
  
  .fa-arrow-alt-up::before {
	content: "\F357"; }
  
  .fa-broom-ball::before {
	content: "\F458"; }
  
  .fa-quidditch::before {
	content: "\F458"; }
  
  .fa-quidditch-broom-ball::before {
	content: "\F458"; }
  
  .fa-drumstick::before {
	content: "\F6D6"; }
  
  .fa-square-v::before {
	content: "\E284"; }
  
  .fa-face-awesome::before {
	content: "\E409"; }
  
  .fa-gave-dandy::before {
	content: "\E409"; }
  
  .fa-dial-off::before {
	content: "\E162"; }
  
  .fa-toggle-off::before {
	content: "\F204"; }
  
  .fa-face-smile-horns::before {
	content: "\E391"; }
  
  .fa-box-archive::before {
	content: "\F187"; }
  
  .fa-archive::before {
	content: "\F187"; }
  
  .fa-grapes::before {
	content: "\E306"; }
  
  .fa-person-drowning::before {
	content: "\E545"; }
  
  .fa-dial-max::before {
	content: "\E15E"; }
  
  .fa-circle-m::before {
	content: "\E115"; }
  
  .fa-calendar-image::before {
	content: "\E0D4"; }
  
  .fa-circle-caret-down::before {
	content: "\F32D"; }
  
  .fa-caret-circle-down::before {
	content: "\F32D"; }
  
  .fa-arrow-down-9-1::before {
	content: "\F886"; }
  
  .fa-sort-numeric-desc::before {
	content: "\F886"; }
  
  .fa-sort-numeric-down-alt::before {
	content: "\F886"; }
  
  .fa-face-grin-tongue-squint::before {
	content: "\F58A"; }
  
  .fa-grin-tongue-squint::before {
	content: "\F58A"; }
  
  .fa-shish-kebab::before {
	content: "\F821"; }
  
  .fa-spray-can::before {
	content: "\F5BD"; }
  
  .fa-alarm-snooze::before {
	content: "\F845"; }
  
  .fa-scarecrow::before {
	content: "\F70D"; }
  
  .fa-truck-monster::before {
	content: "\F63B"; }
  
  .fa-gift-card::before {
	content: "\F663"; }
  
  .fa-w::before {
	content: "W"; }
  
  .fa-code-pull-request-draft::before {
	content: "\E3FA"; }
  
  .fa-square-b::before {
	content: "\E264"; }
  
  .fa-elephant::before {
	content: "\F6DA"; }
  
  .fa-earth-africa::before {
	content: "\F57C"; }
  
  .fa-globe-africa::before {
	content: "\F57C"; }
  
  .fa-rainbow::before {
	content: "\F75B"; }
  
  .fa-circle-notch::before {
	content: "\F1CE"; }
  
  .fa-tablet-screen-button::before {
	content: "\F3FA"; }
  
  .fa-tablet-alt::before {
	content: "\F3FA"; }
  
  .fa-paw::before {
	content: "\F1B0"; }
  
  .fa-message-question::before {
	content: "\E1E3"; }
  
  .fa-cloud::before {
	content: "\F0C2"; }
  
  .fa-trowel-bricks::before {
	content: "\E58A"; }
  
  .fa-square-3::before {
	content: "\E258"; }
  
  .fa-face-flushed::before {
	content: "\F579"; }
  
  .fa-flushed::before {
	content: "\F579"; }
  
  .fa-hospital-user::before {
	content: "\F80D"; }
  
  .fa-microwave::before {
	content: "\E01B"; }
  
  .fa-tent-arrow-left-right::before {
	content: "\E57F"; }
  
  .fa-cart-circle-arrow-up::before {
	content: "\E3F0"; }
  
  .fa-trash-clock::before {
	content: "\E2B0"; }
  
  .fa-gavel::before {
	content: "\F0E3"; }
  
  .fa-legal::before {
	content: "\F0E3"; }
  
  .fa-sprinkler-ceiling::before {
	content: "\E44C"; }
  
  .fa-browsers::before {
	content: "\E0CB"; }
  
  .fa-trillium::before {
	content: "\E588"; }
  
  .fa-music-slash::before {
	content: "\F8D1"; }
  
  .fa-truck-ramp::before {
	content: "\F4E0"; }
  
  .fa-binoculars::before {
	content: "\F1E5"; }
  
  .fa-microphone-slash::before {
	content: "\F131"; }
  
  .fa-box-tissue::before {
	content: "\E05B"; }
  
  .fa-circle-c::before {
	content: "\E101"; }
  
  .fa-star-christmas::before {
	content: "\F7D4"; }
  
  .fa-chart-bullet::before {
	content: "\E0E1"; }
  
  .fa-motorcycle::before {
	content: "\F21C"; }
  
  .fa-tree-christmas::before {
	content: "\F7DB"; }
  
  .fa-tire-flat::before {
	content: "\F632"; }
  
  .fa-sunglasses::before {
	content: "\F892"; }
  
  .fa-badge::before {
	content: "\F335"; }
  
  .fa-message-pen::before {
	content: "\F4A4"; }
  
  .fa-comment-alt-edit::before {
	content: "\F4A4"; }
  
  .fa-message-edit::before {
	content: "\F4A4"; }
  
  .fa-bell-concierge::before {
	content: "\F562"; }
  
  .fa-concierge-bell::before {
	content: "\F562"; }
  
  .fa-pen-ruler::before {
	content: "\F5AE"; }
  
  .fa-pencil-ruler::before {
	content: "\F5AE"; }
  
  .fa-chess-rook-piece::before {
	content: "\F448"; }
  
  .fa-chess-rook-alt::before {
	content: "\F448"; }
  
  .fa-square-root::before {
	content: "\F697"; }
  
  .fa-album-collection-circle-plus::before {
	content: "\E48E"; }
  
  .fa-people-arrows::before {
	content: "\E068"; }
  
  .fa-people-arrows-left-right::before {
	content: "\E068"; }
  
  .fa-face-angry-horns::before {
	content: "\E368"; }
  
  .fa-mars-and-venus-burst::before {
	content: "\E523"; }
  
  .fa-tombstone::before {
	content: "\F720"; }
  
  .fa-square-caret-right::before {
	content: "\F152"; }
  
  .fa-caret-square-right::before {
	content: "\F152"; }
  
  .fa-scissors::before {
	content: "\F0C4"; }
  
  .fa-cut::before {
	content: "\F0C4"; }
  
  .fa-list-music::before {
	content: "\F8C9"; }
  
  .fa-sun-plant-wilt::before {
	content: "\E57A"; }
  
  .fa-toilets-portable::before {
	content: "\E584"; }
  
  .fa-hockey-puck::before {
	content: "\F453"; }
  
  .fa-hyphen::before {
	content: "-"; }
  
  .fa-table::before {
	content: "\F0CE"; }
  
  .fa-user-chef::before {
	content: "\E3D2"; }
  
  .fa-message-image::before {
	content: "\E1E0"; }
  
  .fa-comment-alt-image::before {
	content: "\E1E0"; }
  
  .fa-users-medical::before {
	content: "\F830"; }
  
  .fa-sensor-triangle-exclamation::before {
	content: "\E029"; }
  
  .fa-sensor-alert::before {
	content: "\E029"; }
  
  .fa-magnifying-glass-arrow-right::before {
	content: "\E521"; }
  
  .fa-tachograph-digital::before {
	content: "\F566"; }
  
  .fa-digital-tachograph::before {
	content: "\F566"; }
  
  .fa-face-mask::before {
	content: "\E37F"; }
  
  .fa-pickleball::before {
	content: "\E435"; }
  
  .fa-star-sharp-half::before {
	content: "\E28C"; }
  
  .fa-users-slash::before {
	content: "\E073"; }
  
  .fa-clover::before {
	content: "\E139"; }
  
  .fa-meat::before {
	content: "\F814"; }
  
  .fa-reply::before {
	content: "\F3E5"; }
  
  .fa-mail-reply::before {
	content: "\F3E5"; }
  
  .fa-star-and-crescent::before {
	content: "\F699"; }
  
  .fa-empty-set::before {
	content: "\F656"; }
  
  .fa-house-fire::before {
	content: "\E50C"; }
  
  .fa-square-minus::before {
	content: "\F146"; }
  
  .fa-minus-square::before {
	content: "\F146"; }
  
  .fa-helicopter::before {
	content: "\F533"; }
  
  .fa-bird::before {
	content: "\E469"; }
  
  .fa-compass::before {
	content: "\F14E"; }
  
  .fa-square-caret-down::before {
	content: "\F150"; }
  
  .fa-caret-square-down::before {
	content: "\F150"; }
  
  .fa-heart-half-stroke::before {
	content: "\E1AC"; }
  
  .fa-heart-half-alt::before {
	content: "\E1AC"; }
  
  .fa-file-circle-question::before {
	content: "\E4EF"; }
  
  .fa-laptop-code::before {
	content: "\F5FC"; }
  
  .fa-joystick::before {
	content: "\F8C5"; }
  
  .fa-grill-fire::before {
	content: "\E5A4"; }
  
  .fa-rectangle-vertical-history::before {
	content: "\E237"; }
  
  .fa-swatchbook::before {
	content: "\F5C3"; }
  
  .fa-prescription-bottle::before {
	content: "\F485"; }
  
  .fa-bars::before {
	content: "\F0C9"; }
  
  .fa-navicon::before {
	content: "\F0C9"; }
  
  .fa-keyboard-left::before {
	content: "\E1C3"; }
  
  .fa-people-group::before {
	content: "\E533"; }
  
  .fa-hourglass-end::before {
	content: "\F253"; }
  
  .fa-hourglass-3::before {
	content: "\F253"; }
  
  .fa-heart-crack::before {
	content: "\F7A9"; }
  
  .fa-heart-broken::before {
	content: "\F7A9"; }
  
  .fa-face-beam-hand-over-mouth::before {
	content: "\E47C"; }
  
  .fa-droplet-percent::before {
	content: "\F750"; }
  
  .fa-humidity::before {
	content: "\F750"; }
  
  .fa-square-up-right::before {
	content: "\F360"; }
  
  .fa-external-link-square-alt::before {
	content: "\F360"; }
  
  .fa-face-kiss-beam::before {
	content: "\F597"; }
  
  .fa-kiss-beam::before {
	content: "\F597"; }
  
  .fa-corn::before {
	content: "\F6C7"; }
  
  .fa-roller-coaster::before {
	content: "\E324"; }
  
  .fa-photo-film-music::before {
	content: "\E228"; }
  
  .fa-radar::before {
	content: "\E024"; }
  
  .fa-sickle::before {
	content: "\F822"; }
  
  .fa-film::before {
	content: "\F008"; }
  
  .fa-coconut::before {
	content: "\E2F6"; }
  
  .fa-ruler-horizontal::before {
	content: "\F547"; }
  
  .fa-shield-cross::before {
	content: "\F712"; }
  
  .fa-cassette-tape::before {
	content: "\F8AB"; }
  
  .fa-square-terminal::before {
	content: "\E32A"; }
  
  .fa-people-robbery::before {
	content: "\E536"; }
  
  .fa-lightbulb::before {
	content: "\F0EB"; }
  
  .fa-caret-left::before {
	content: "\F0D9"; }
  
  .fa-comment-middle::before {
	content: "\E149"; }
  
  .fa-trash-can-list::before {
	content: "\E2AB"; }
  
  .fa-block::before {
	content: "\E46A"; }
  
  .fa-circle-exclamation::before {
	content: "\F06A"; }
  
  .fa-exclamation-circle::before {
	content: "\F06A"; }
  
  .fa-school-circle-xmark::before {
	content: "\E56D"; }
  
  .fa-arrow-right-from-bracket::before {
	content: "\F08B"; }
  
  .fa-sign-out::before {
	content: "\F08B"; }
  
  .fa-face-frown-slight::before {
	content: "\E376"; }
  
  .fa-circle-chevron-down::before {
	content: "\F13A"; }
  
  .fa-chevron-circle-down::before {
	content: "\F13A"; }
  
  .fa-sidebar-flip::before {
	content: "\E24F"; }
  
  .fa-unlock-keyhole::before {
	content: "\F13E"; }
  
  .fa-unlock-alt::before {
	content: "\F13E"; }
  
  .fa-temperature-list::before {
	content: "\E299"; }
  
  .fa-cloud-showers-heavy::before {
	content: "\F740"; }
  
  .fa-headphones-simple::before {
	content: "\F58F"; }
  
  .fa-headphones-alt::before {
	content: "\F58F"; }
  
  .fa-sitemap::before {
	content: "\F0E8"; }
  
  .fa-pipe-section::before {
	content: "\E438"; }
  
  .fa-space-station-moon-construction::before {
	content: "\E034"; }
  
  .fa-space-station-moon-alt::before {
	content: "\E034"; }
  
  .fa-circle-dollar-to-slot::before {
	content: "\F4B9"; }
  
  .fa-donate::before {
	content: "\F4B9"; }
  
  .fa-memory::before {
	content: "\F538"; }
  
  .fa-face-sleeping::before {
	content: "\E38D"; }
  
  .fa-road-spikes::before {
	content: "\E568"; }
  
  .fa-fire-burner::before {
	content: "\E4F1"; }
  
  .fa-squirrel::before {
	content: "\F71A"; }
  
  .fa-arrow-up-to-line::before {
	content: "\F341"; }
  
  .fa-arrow-to-top::before {
	content: "\F341"; }
  
  .fa-flag::before {
	content: "\F024"; }
  
  .fa-face-cowboy-hat::before {
	content: "\E36E"; }
  
  .fa-hanukiah::before {
	content: "\F6E6"; }
  
  .fa-chart-scatter-3d::before {
	content: "\E0E8"; }
  
  .fa-square-code::before {
	content: "\E267"; }
  
  .fa-feather::before {
	content: "\F52D"; }
  
  .fa-volume-low::before {
	content: "\F027"; }
  
  .fa-volume-down::before {
	content: "\F027"; }
  
  .fa-xmark-to-slot::before {
	content: "\F771"; }
  
  .fa-times-to-slot::before {
	content: "\F771"; }
  
  .fa-vote-nay::before {
	content: "\F771"; }
  
  .fa-box-taped::before {
	content: "\F49A"; }
  
  .fa-box-alt::before {
	content: "\F49A"; }
  
  .fa-comment-slash::before {
	content: "\F4B3"; }
  
  .fa-swords::before {
	content: "\F71D"; }
  
  .fa-cloud-sun-rain::before {
	content: "\F743"; }
  
  .fa-album::before {
	content: "\F89F"; }
  
  .fa-circle-n::before {
	content: "\E118"; }
  
  .fa-compress::before {
	content: "\F066"; }
  
  .fa-wheat-awn::before {
	content: "\E2CD"; }
  
  .fa-wheat-alt::before {
	content: "\E2CD"; }
  
  .fa-ankh::before {
	content: "\F644"; }
  
  .fa-hands-holding-child::before {
	content: "\E4FA"; }
  
  .fa-asterisk::before {
	content: "*"; }
  
  .fa-key-skeleton-left-right::before {
	content: "\E3B4"; }
  
  .fa-comment-lines::before {
	content: "\F4B0"; }
  
  .fa-luchador-mask::before {
	content: "\F455"; }
  
  .fa-luchador::before {
	content: "\F455"; }
  
  .fa-mask-luchador::before {
	content: "\F455"; }
  
  .fa-square-check::before {
	content: "\F14A"; }
  
  .fa-check-square::before {
	content: "\F14A"; }
  
  .fa-shredder::before {
	content: "\F68A"; }
  
  .fa-book-open-cover::before {
	content: "\E0C0"; }
  
  .fa-book-open-alt::before {
	content: "\E0C0"; }
  
  .fa-sandwich::before {
	content: "\F81F"; }
  
  .fa-peseta-sign::before {
	content: "\E221"; }
  
  .fa-square-parking-slash::before {
	content: "\F617"; }
  
  .fa-parking-slash::before {
	content: "\F617"; }
  
  .fa-train-tunnel::before {
	content: "\E454"; }
  
  .fa-heading::before {
	content: "\F1DC"; }
  
  .fa-header::before {
	content: "\F1DC"; }
  
  .fa-ghost::before {
	content: "\F6E2"; }
  
  .fa-face-anguished::before {
	content: "\E369"; }
  
  .fa-hockey-sticks::before {
	content: "\F454"; }
  
  .fa-abacus::before {
	content: "\F640"; }
  
  .fa-film-simple::before {
	content: "\F3A0"; }
  
  .fa-film-alt::before {
	content: "\F3A0"; }
  
  .fa-list::before {
	content: "\F03A"; }
  
  .fa-list-squares::before {
	content: "\F03A"; }
  
  .fa-tree-palm::before {
	content: "\F82B"; }
  
  .fa-square-phone-flip::before {
	content: "\F87B"; }
  
  .fa-phone-square-alt::before {
	content: "\F87B"; }
  
  .fa-cart-plus::before {
	content: "\F217"; }
  
  .fa-gamepad::before {
	content: "\F11B"; }
  
  .fa-border-center-v::before {
	content: "\F89D"; }
  
  .fa-circle-dot::before {
	content: "\F192"; }
  
  .fa-dot-circle::before {
	content: "\F192"; }
  
  .fa-clipboard-medical::before {
	content: "\E133"; }
  
  .fa-face-dizzy::before {
	content: "\F567"; }
  
  .fa-dizzy::before {
	content: "\F567"; }
  
  .fa-egg::before {
	content: "\F7FB"; }
  
  .fa-up-to-line::before {
	content: "\F34D"; }
  
  .fa-arrow-alt-to-top::before {
	content: "\F34D"; }
  
  .fa-house-medical-circle-xmark::before {
	content: "\E513"; }
  
  .fa-watch-fitness::before {
	content: "\F63E"; }
  
  .fa-clock-nine-thirty::before {
	content: "\E34D"; }
  
  .fa-campground::before {
	content: "\F6BB"; }
  
  .fa-folder-plus::before {
	content: "\F65E"; }
  
  .fa-jug::before {
	content: "\F8C6"; }
  
  .fa-futbol::before {
	content: "\F1E3"; }
  
  .fa-futbol-ball::before {
	content: "\F1E3"; }
  
  .fa-soccer-ball::before {
	content: "\F1E3"; }
  
  .fa-snow-blowing::before {
	content: "\F761"; }
  
  .fa-paintbrush::before {
	content: "\F1FC"; }
  
  .fa-paint-brush::before {
	content: "\F1FC"; }
  
  .fa-lock::before {
	content: "\F023"; }
  
  .fa-arrow-down-from-line::before {
	content: "\F345"; }
  
  .fa-arrow-from-top::before {
	content: "\F345"; }
  
  .fa-gas-pump::before {
	content: "\F52F"; }
  
  .fa-signal-bars-slash::before {
	content: "\F694"; }
  
  .fa-signal-alt-slash::before {
	content: "\F694"; }
  
  .fa-monkey::before {
	content: "\F6FB"; }
  
  .fa-rectangle-pro::before {
	content: "\E235"; }
  
  .fa-pro::before {
	content: "\E235"; }
  
  .fa-house-night::before {
	content: "\E010"; }
  
  .fa-hot-tub-person::before {
	content: "\F593"; }
  
  .fa-hot-tub::before {
	content: "\F593"; }
  
  .fa-blanket::before {
	content: "\F498"; }
  
  .fa-map-location::before {
	content: "\F59F"; }
  
  .fa-map-marked::before {
	content: "\F59F"; }
  
  .fa-house-flood-water::before {
	content: "\E50E"; }
  
  .fa-comments-question-check::before {
	content: "\E14F"; }
  
  .fa-tree::before {
	content: "\F1BB"; }
  
  .fa-arrows-cross::before {
	content: "\E0A2"; }
  
  .fa-backpack::before {
	content: "\F5D4"; }
  
  .fa-square-small::before {
	content: "\E27E"; }
  
  .fa-folder-arrow-up::before {
	content: "\E054"; }
  
  .fa-folder-upload::before {
	content: "\E054"; }
  
  .fa-bridge-lock::before {
	content: "\E4CC"; }
  
  .fa-crosshairs-simple::before {
	content: "\E59F"; }
  
  .fa-sack-dollar::before {
	content: "\F81D"; }
  
  .fa-pen-to-square::before {
	content: "\F044"; }
  
  .fa-edit::before {
	content: "\F044"; }
  
  .fa-square-sliders::before {
	content: "\F3F0"; }
  
  .fa-sliders-h-square::before {
	content: "\F3F0"; }
  
  .fa-car-side::before {
	content: "\F5E4"; }
  
  .fa-message-middle-top::before {
	content: "\E1E2"; }
  
  .fa-comment-middle-top-alt::before {
	content: "\E1E2"; }
  
  .fa-lightbulb-on::before {
	content: "\F672"; }
  
  .fa-knife::before {
	content: "\F2E4"; }
  
  .fa-utensil-knife::before {
	content: "\F2E4"; }
  
  .fa-share-nodes::before {
	content: "\F1E0"; }
  
  .fa-share-alt::before {
	content: "\F1E0"; }
  
  .fa-wave-sine::before {
	content: "\F899"; }
  
  .fa-heart-circle-minus::before {
	content: "\E4FF"; }
  
  .fa-circle-w::before {
	content: "\E12C"; }
  
  .fa-circle-calendar::before {
	content: "\E102"; }
  
  .fa-calendar-circle::before {
	content: "\E102"; }
  
  .fa-hourglass-half::before {
	content: "\F252"; }
  
  .fa-hourglass-2::before {
	content: "\F252"; }
  
  .fa-microscope::before {
	content: "\F610"; }
  
  .fa-sunset::before {
	content: "\F767"; }
  
  .fa-sink::before {
	content: "\E06D"; }
  
  .fa-calendar-exclamation::before {
	content: "\F334"; }
  
  .fa-truck-container-empty::before {
	content: "\E2B5"; }
  
  .fa-hand-heart::before {
	content: "\F4BC"; }
  
  .fa-bag-shopping::before {
	content: "\F290"; }
  
  .fa-shopping-bag::before {
	content: "\F290"; }
  
  .fa-arrow-down-z-a::before {
	content: "\F881"; }
  
  .fa-sort-alpha-desc::before {
	content: "\F881"; }
  
  .fa-sort-alpha-down-alt::before {
	content: "\F881"; }
  
  .fa-mitten::before {
	content: "\F7B5"; }
  
  .fa-reply-clock::before {
	content: "\E239"; }
  
  .fa-reply-time::before {
	content: "\E239"; }
  
  .fa-person-rays::before {
	content: "\E54D"; }
  
  .fa-right::before {
	content: "\F356"; }
  
  .fa-arrow-alt-right::before {
	content: "\F356"; }
  
  .fa-circle-f::before {
	content: "\E10E"; }
  
  .fa-users::before {
	content: "\F0C0"; }
  
  .fa-face-pleading::before {
	content: "\E386"; }
  
  .fa-eye-slash::before {
	content: "\F070"; }
  
  .fa-flask-vial::before {
	content: "\E4F3"; }
  
  .fa-police-box::before {
	content: "\E021"; }
  
  .fa-cucumber::before {
	content: "\E401"; }
  
  .fa-head-side-brain::before {
	content: "\F808"; }
  
  .fa-hand::before {
	content: "\F256"; }
  
  .fa-hand-paper::before {
	content: "\F256"; }
  
  .fa-person-biking-mountain::before {
	content: "\F84B"; }
  
  .fa-biking-mountain::before {
	content: "\F84B"; }
  
  .fa-utensils-slash::before {
	content: "\E464"; }
  
  .fa-print-magnifying-glass::before {
	content: "\F81A"; }
  
  .fa-print-search::before {
	content: "\F81A"; }
  
  .fa-folder-bookmark::before {
	content: "\E186"; }
  
  .fa-om::before {
	content: "\F679"; }
  
  .fa-pi::before {
	content: "\F67E"; }
  
  .fa-flask-round-potion::before {
	content: "\F6E1"; }
  
  .fa-flask-potion::before {
	content: "\F6E1"; }
  
  .fa-face-shush::before {
	content: "\E38C"; }
  
  .fa-worm::before {
	content: "\E599"; }
  
  .fa-house-circle-xmark::before {
	content: "\E50B"; }
  
  .fa-plug::before {
	content: "\F1E6"; }
  
  .fa-calendar-circle-exclamation::before {
	content: "\E46E"; }
  
  .fa-square-i::before {
	content: "\E272"; }
  
  .fa-chevron-up::before {
	content: "\F077"; }
  
  .fa-face-saluting::before {
	content: "\E484"; }
  
  .fa-gauge-simple-low::before {
	content: "\F62C"; }
  
  .fa-tachometer-slow::before {
	content: "\F62C"; }
  
  .fa-face-persevering::before {
	content: "\E385"; }
  
  .fa-circle-camera::before {
	content: "\E103"; }
  
  .fa-camera-circle::before {
	content: "\E103"; }
  
  .fa-hand-spock::before {
	content: "\F259"; }
  
  .fa-spider-web::before {
	content: "\F719"; }
  
  .fa-circle-microphone::before {
	content: "\E116"; }
  
  .fa-microphone-circle::before {
	content: "\E116"; }
  
  .fa-book-arrow-up::before {
	content: "\E0BA"; }
  
  .fa-popsicle::before {
	content: "\E43E"; }
  
  .fa-command::before {
	content: "\E142"; }
  
  .fa-blinds::before {
	content: "\F8FB"; }
  
  .fa-stopwatch::before {
	content: "\F2F2"; }
  
  .fa-saxophone::before {
	content: "\F8DC"; }
  
  .fa-square-2::before {
	content: "\E257"; }
  
  .fa-field-hockey-stick-ball::before {
	content: "\F44C"; }
  
  .fa-field-hockey::before {
	content: "\F44C"; }
  
  .fa-arrow-up-square-triangle::before {
	content: "\F88B"; }
  
  .fa-sort-shapes-up-alt::before {
	content: "\F88B"; }
  
  .fa-face-scream::before {
	content: "\E38B"; }
  
  .fa-square-m::before {
	content: "\E276"; }
  
  .fa-camera-web::before {
	content: "\F832"; }
  
  .fa-webcam::before {
	content: "\F832"; }
  
  .fa-comment-arrow-down::before {
	content: "\E143"; }
  
  .fa-lightbulb-cfl::before {
	content: "\E5A6"; }
  
  .fa-window-frame-open::before {
	content: "\E050"; }
  
  .fa-face-kiss::before {
	content: "\F596"; }
  
  .fa-kiss::before {
	content: "\F596"; }
  
  .fa-bridge-circle-xmark::before {
	content: "\E4CB"; }
  
  .fa-period::before {
	content: "."; }
  
  .fa-face-grin-tongue::before {
	content: "\F589"; }
  
  .fa-grin-tongue::before {
	content: "\F589"; }
  
  .fa-up-to-dotted-line::before {
	content: "\E457"; }
  
  .fa-thought-bubble::before {
	content: "\E32E"; }
  
  .fa-raygun::before {
	content: "\E025"; }
  
  .fa-flute::before {
	content: "\F8B9"; }
  
  .fa-acorn::before {
	content: "\F6AE"; }
  
  .fa-video-arrow-up-right::before {
	content: "\E2C9"; }
  
  .fa-grate-droplet::before {
	content: "\E194"; }
  
  .fa-seal-exclamation::before {
	content: "\E242"; }
  
  .fa-chess-bishop::before {
	content: "\F43A"; }
  
  .fa-message-sms::before {
	content: "\E1E5"; }
  
  .fa-coffee-beans::before {
	content: "\E13F"; }
  
  .fa-hat-witch::before {
	content: "\F6E7"; }
  
  .fa-face-grin-wink::before {
	content: "\F58C"; }
  
  .fa-grin-wink::before {
	content: "\F58C"; }
  
  .fa-clock-three-thirty::before {
	content: "\E357"; }
  
  .fa-ear-deaf::before {
	content: "\F2A4"; }
  
  .fa-deaf::before {
	content: "\F2A4"; }
  
  .fa-deafness::before {
	content: "\F2A4"; }
  
  .fa-hard-of-hearing::before {
	content: "\F2A4"; }
  
  .fa-alarm-clock::before {
	content: "\F34E"; }
  
  .fa-eclipse::before {
	content: "\F749"; }
  
  .fa-face-relieved::before {
	content: "\E389"; }
  
  .fa-road-circle-check::before {
	content: "\E564"; }
  
  .fa-dice-five::before {
	content: "\F523"; }
  
  .fa-octagon-minus::before {
	content: "\F308"; }
  
  .fa-minus-octagon::before {
	content: "\F308"; }
  
  .fa-square-rss::before {
	content: "\F143"; }
  
  .fa-rss-square::before {
	content: "\F143"; }
  
  .fa-face-zany::before {
	content: "\E3A4"; }
  
  .fa-land-mine-on::before {
	content: "\E51B"; }
  
  .fa-square-arrow-up-left::before {
	content: "\E263"; }
  
  .fa-i-cursor::before {
	content: "\F246"; }
  
  .fa-salt-shaker::before {
	content: "\E446"; }
  
  .fa-stamp::before {
	content: "\F5BF"; }
  
  .fa-file-plus::before {
	content: "\F319"; }
  
  .fa-draw-square::before {
	content: "\F5EF"; }
  
  .fa-toilet-paper-under-slash::before {
	content: "\E2A1"; }
  
  .fa-toilet-paper-reverse-slash::before {
	content: "\E2A1"; }
  
  .fa-stairs::before {
	content: "\E289"; }
  
  .fa-drone-front::before {
	content: "\F860"; }
  
  .fa-drone-alt::before {
	content: "\F860"; }
  
  .fa-glass-empty::before {
	content: "\E191"; }
  
  .fa-dial-high::before {
	content: "\E15C"; }
  
  .fa-user-helmet-safety::before {
	content: "\F82C"; }
  
  .fa-user-construction::before {
	content: "\F82C"; }
  
  .fa-user-hard-hat::before {
	content: "\F82C"; }
  
  .fa-i::before {
	content: "I"; }
  
  .fa-hryvnia-sign::before {
	content: "\F6F2"; }
  
  .fa-hryvnia::before {
	content: "\F6F2"; }
  
  .fa-arrow-down-left-and-arrow-up-right-to-center::before {
	content: "\E092"; }
  
  .fa-pills::before {
	content: "\F484"; }
  
  .fa-face-grin-wide::before {
	content: "\F581"; }
  
  .fa-grin-alt::before {
	content: "\F581"; }
  
  .fa-tooth::before {
	content: "\F5C9"; }
  
  .fa-basketball-hoop::before {
	content: "\F435"; }
  
  .fa-objects-align-bottom::before {
	content: "\E3BB"; }
  
  .fa-v::before {
	content: "V"; }
  
  .fa-sparkles::before {
	content: "\F890"; }
  
  .fa-squid::before {
	content: "\E450"; }
  
  .fa-leafy-green::before {
	content: "\E41D"; }
  
  .fa-circle-arrow-up-right::before {
	content: "\E0FC"; }
  
  .fa-calendars::before {
	content: "\E0D7"; }
  
  .fa-bangladeshi-taka-sign::before {
	content: "\E2E6"; }
  
  .fa-bicycle::before {
	content: "\F206"; }
  
  .fa-hammer-war::before {
	content: "\F6E4"; }
  
  .fa-circle-d::before {
	content: "\E104"; }
  
  .fa-spider-black-widow::before {
	content: "\F718"; }
  
  .fa-staff-snake::before {
	content: "\E579"; }
  
  .fa-rod-asclepius::before {
	content: "\E579"; }
  
  .fa-rod-snake::before {
	content: "\E579"; }
  
  .fa-staff-aesculapius::before {
	content: "\E579"; }
  
  .fa-pear::before {
	content: "\E20C"; }
  
  .fa-head-side-cough-slash::before {
	content: "\E062"; }
  
  .fa-triangle::before {
	content: "\F2EC"; }
  
  .fa-apartment::before {
	content: "\E468"; }
  
  .fa-truck-medical::before {
	content: "\F0F9"; }
  
  .fa-ambulance::before {
	content: "\F0F9"; }
  
  .fa-pepper::before {
	content: "\E432"; }
  
  .fa-piano::before {
	content: "\F8D4"; }
  
  .fa-gun-squirt::before {
	content: "\E19D"; }
  
  .fa-wheat-awn-circle-exclamation::before {
	content: "\E598"; }
  
  .fa-snowman::before {
	content: "\F7D0"; }
  
  .fa-user-alien::before {
	content: "\E04A"; }
  
  .fa-shield-check::before {
	content: "\F2F7"; }
  
  .fa-mortar-pestle::before {
	content: "\F5A7"; }
  
  .fa-road-barrier::before {
	content: "\E562"; }
  
  .fa-chart-candlestick::before {
	content: "\E0E2"; }
  
  .fa-briefcase-blank::before {
	content: "\E0C8"; }
  
  .fa-school::before {
	content: "\F549"; }
  
  .fa-igloo::before {
	content: "\F7AE"; }
  
  .fa-bracket-round::before {
	content: "("; }
  
  .fa-parenthesis::before {
	content: "("; }
  
  .fa-joint::before {
	content: "\F595"; }
  
  .fa-horse-saddle::before {
	content: "\F8C3"; }
  
  .fa-mug-marshmallows::before {
	content: "\F7B7"; }
  
  .fa-filters::before {
	content: "\E17E"; }
  
  .fa-bell-on::before {
	content: "\F8FA"; }
  
  .fa-angle-right::before {
	content: "\F105"; }
  
  .fa-dial-med::before {
	content: "\E15F"; }
  
  .fa-horse::before {
	content: "\F6F0"; }
  
  .fa-q::before {
	content: "Q"; }
  
  .fa-monitor-waveform::before {
	content: "\F611"; }
  
  .fa-monitor-heart-rate::before {
	content: "\F611"; }
  
  .fa-link-simple::before {
	content: "\E1CD"; }
  
  .fa-whistle::before {
	content: "\F460"; }
  
  .fa-g::before {
	content: "G"; }
  
  .fa-wine-glass-crack::before {
	content: "\F4BB"; }
  
  .fa-fragile::before {
	content: "\F4BB"; }
  
  .fa-slot-machine::before {
	content: "\E3CE"; }
  
  .fa-notes-medical::before {
	content: "\F481"; }
  
  .fa-car-wash::before {
	content: "\F5E6"; }
  
  .fa-escalator::before {
	content: "\E171"; }
  
  .fa-comment-image::before {
	content: "\E148"; }
  
  .fa-temperature-half::before {
	content: "\F2C9"; }
  
  .fa-temperature-2::before {
	content: "\F2C9"; }
  
  .fa-thermometer-2::before {
	content: "\F2C9"; }
  
  .fa-thermometer-half::before {
	content: "\F2C9"; }
  
  .fa-dong-sign::before {
	content: "\E169"; }
  
  .fa-donut::before {
	content: "\E406"; }
  
  .fa-doughnut::before {
	content: "\E406"; }
  
  .fa-capsules::before {
	content: "\F46B"; }
  
  .fa-poo-storm::before {
	content: "\F75A"; }
  
  .fa-poo-bolt::before {
	content: "\F75A"; }
  
  .fa-tally-1::before {
	content: "\E294"; }
  
  .fa-face-frown-open::before {
	content: "\F57A"; }
  
  .fa-frown-open::before {
	content: "\F57A"; }
  
  .fa-square-dashed::before {
	content: "\E269"; }
  
  .fa-square-j::before {
	content: "\E273"; }
  
  .fa-hand-point-up::before {
	content: "\F0A6"; }
  
  .fa-money-bill::before {
	content: "\F0D6"; }
  
  .fa-arrow-up-big-small::before {
	content: "\F88E"; }
  
  .fa-sort-size-up::before {
	content: "\F88E"; }
  
  .fa-barcode-read::before {
	content: "\F464"; }
  
  .fa-baguette::before {
	content: "\E3D8"; }
  
  .fa-bowl-soft-serve::before {
	content: "\E46B"; }
  
  .fa-face-holding-back-tears::before {
	content: "\E482"; }
  
  .fa-square-up::before {
	content: "\F353"; }
  
  .fa-arrow-alt-square-up::before {
	content: "\F353"; }
  
  .fa-train-subway-tunnel::before {
	content: "\E2A3"; }
  
  .fa-subway-tunnel::before {
	content: "\E2A3"; }
  
  .fa-square-exclamation::before {
	content: "\F321"; }
  
  .fa-exclamation-square::before {
	content: "\F321"; }
  
  .fa-semicolon::before {
	content: ";"; }
  
  .fa-bookmark::before {
	content: "\F02E"; }
  
  .fa-fan-table::before {
	content: "\E004"; }
  
  .fa-align-justify::before {
	content: "\F039"; }
  
  .fa-battery-low::before {
	content: "\E0B1"; }
  
  .fa-battery-1::before {
	content: "\E0B1"; }
  
  .fa-credit-card-front::before {
	content: "\F38A"; }
  
  .fa-brain-arrow-curved-right::before {
	content: "\F677"; }
  
  .fa-mind-share::before {
	content: "\F677"; }
  
  .fa-umbrella-beach::before {
	content: "\F5CA"; }
  
  .fa-helmet-un::before {
	content: "\E503"; }
  
  .fa-location-smile::before {
	content: "\F60D"; }
  
  .fa-map-marker-smile::before {
	content: "\F60D"; }
  
  .fa-arrow-left-to-line::before {
	content: "\F33E"; }
  
  .fa-arrow-to-left::before {
	content: "\F33E"; }
  
  .fa-bullseye::before {
	content: "\F140"; }
  
  .fa-sushi::before {
	content: "\E48A"; }
  
  .fa-nigiri::before {
	content: "\E48A"; }
  
  .fa-message-captions::before {
	content: "\E1DE"; }
  
  .fa-comment-alt-captions::before {
	content: "\E1DE"; }
  
  .fa-trash-list::before {
	content: "\E2B1"; }
  
  .fa-bacon::before {
	content: "\F7E5"; }
  
  .fa-option::before {
	content: "\E318"; }
  
  .fa-hand-point-down::before {
	content: "\F0A7"; }
  
  .fa-arrow-up-from-bracket::before {
	content: "\E09A"; }
  
  .fa-trash-plus::before {
	content: "\E2B2"; }
  
  .fa-objects-align-top::before {
	content: "\E3C0"; }
  
  .fa-folder::before {
	content: "\F07B"; }
  
  .fa-folder-blank::before {
	content: "\F07B"; }
  
  .fa-face-anxious-sweat::before {
	content: "\E36A"; }
  
  .fa-credit-card-blank::before {
	content: "\F389"; }
  
  .fa-file-waveform::before {
	content: "\F478"; }
  
  .fa-file-medical-alt::before {
	content: "\F478"; }
  
  .fa-microchip-ai::before {
	content: "\E1EC"; }
  
  .fa-mug::before {
	content: "\F874"; }
  
  .fa-plane-up-slash::before {
	content: "\E22E"; }
  
  .fa-radiation::before {
	content: "\F7B9"; }
  
  .fa-pen-circle::before {
	content: "\E20E"; }
  
  .fa-chart-simple::before {
	content: "\E473"; }
  
  .fa-crutches::before {
	content: "\F7F8"; }
  
  .fa-circle-parking::before {
	content: "\F615"; }
  
  .fa-parking-circle::before {
	content: "\F615"; }
  
  .fa-mars-stroke::before {
	content: "\F229"; }
  
  .fa-leaf-oak::before {
	content: "\F6F7"; }
  
  .fa-square-bolt::before {
	content: "\E265"; }
  
  .fa-vial::before {
	content: "\F492"; }
  
  .fa-gauge::before {
	content: "\F624"; }
  
  .fa-dashboard::before {
	content: "\F624"; }
  
  .fa-gauge-med::before {
	content: "\F624"; }
  
  .fa-tachometer-alt-average::before {
	content: "\F624"; }
  
  .fa-wand-magic-sparkles::before {
	content: "\E2CA"; }
  
  .fa-magic-wand-sparkles::before {
	content: "\E2CA"; }
  
  .fa-lambda::before {
	content: "\F66E"; }
  
  .fa-e::before {
	content: "E"; }
  
  .fa-pizza::before {
	content: "\F817"; }
  
  .fa-bowl-chopsticks-noodles::before {
	content: "\E2EA"; }
  
  .fa-h3::before {
	content: "\F315"; }
  
  .fa-pen-clip::before {
	content: "\F305"; }
  
  .fa-pen-alt::before {
	content: "\F305"; }
  
  .fa-bridge-circle-exclamation::before {
	content: "\E4CA"; }
  
  .fa-badge-percent::before {
	content: "\F646"; }
  
  .fa-user::before {
	content: "\F007"; }
  
  .fa-sensor::before {
	content: "\E028"; }
  
  .fa-comma::before {
	content: ","; }
  
  .fa-school-circle-check::before {
	content: "\E56B"; }
  
  .fa-toilet-paper-under::before {
	content: "\E2A0"; }
  
  .fa-toilet-paper-reverse::before {
	content: "\E2A0"; }
  
  .fa-light-emergency::before {
	content: "\E41F"; }
  
  .fa-arrow-down-to-arc::before {
	content: "\E4AE"; }
  
  .fa-dumpster::before {
	content: "\F793"; }
  
  .fa-van-shuttle::before {
	content: "\F5B6"; }
  
  .fa-shuttle-van::before {
	content: "\F5B6"; }
  
  .fa-building-user::before {
	content: "\E4DA"; }
  
  .fa-light-switch::before {
	content: "\E017"; }
  
  .fa-square-caret-left::before {
	content: "\F191"; }
  
  .fa-caret-square-left::before {
	content: "\F191"; }
  
  .fa-highlighter::before {
	content: "\F591"; }
  
  .fa-wave-pulse::before {
	content: "\F5F8"; }
  
  .fa-heart-rate::before {
	content: "\F5F8"; }
  
  .fa-key::before {
	content: "\F084"; }
  
  .fa-hat-santa::before {
	content: "\F7A7"; }
  
  .fa-tamale::before {
	content: "\E451"; }
  
  .fa-box-check::before {
	content: "\F467"; }
  
  .fa-bullhorn::before {
	content: "\F0A1"; }
  
  .fa-steak::before {
	content: "\F824"; }
  
  .fa-location-crosshairs-slash::before {
	content: "\F603"; }
  
  .fa-location-slash::before {
	content: "\F603"; }
  
  .fa-person-dolly::before {
	content: "\F4D0"; }
  
  .fa-globe::before {
	content: "\F0AC"; }
  
  .fa-synagogue::before {
	content: "\F69B"; }
  
  .fa-file-chart-column::before {
	content: "\F659"; }
  
  .fa-file-chart-line::before {
	content: "\F659"; }
  
  .fa-person-half-dress::before {
	content: "\E548"; }
  
  .fa-folder-image::before {
	content: "\E18A"; }
  
  .fa-calendar-pen::before {
	content: "\F333"; }
  
  .fa-calendar-edit::before {
	content: "\F333"; }
  
  .fa-road-bridge::before {
	content: "\E563"; }
  
  .fa-face-smile-tear::before {
	content: "\E393"; }
  
  .fa-message-plus::before {
	content: "\F4A8"; }
  
  .fa-comment-alt-plus::before {
	content: "\F4A8"; }
  
  .fa-location-arrow::before {
	content: "\F124"; }
  
  .fa-c::before {
	content: "C"; }
  
  .fa-tablet-button::before {
	content: "\F10A"; }
  
  .fa-rectangle-history-circle-user::before {
	content: "\E4A4"; }
  
  .fa-building-lock::before {
	content: "\E4D6"; }
  
  .fa-chart-line-up::before {
	content: "\E0E5"; }
  
  .fa-mailbox::before {
	content: "\F813"; }
  
  .fa-truck-bolt::before {
	content: "\E3D0"; }
  
  .fa-pizza-slice::before {
	content: "\F818"; }
  
  .fa-money-bill-wave::before {
	content: "\F53A"; }
  
  .fa-chart-area::before {
	content: "\F1FE"; }
  
  .fa-area-chart::before {
	content: "\F1FE"; }
  
  .fa-house-flag::before {
	content: "\E50D"; }
  
  .fa-person-circle-minus::before {
	content: "\E540"; }
  
  .fa-scalpel::before {
	content: "\F61D"; }
  
  .fa-ban::before {
	content: "\F05E"; }
  
  .fa-cancel::before {
	content: "\F05E"; }
  
  .fa-bell-exclamation::before {
	content: "\F848"; }
  
  .fa-circle-bookmark::before {
	content: "\E100"; }
  
  .fa-bookmark-circle::before {
	content: "\E100"; }
  
  .fa-egg-fried::before {
	content: "\F7FC"; }
  
  .fa-face-weary::before {
	content: "\E3A1"; }
  
  .fa-uniform-martial-arts::before {
	content: "\E3D1"; }
  
  .fa-camera-rotate::before {
	content: "\E0D8"; }
  
  .fa-sun-dust::before {
	content: "\F764"; }
  
  .fa-comment-text::before {
	content: "\E14D"; }
  
  .fa-spray-can-sparkles::before {
	content: "\F5D0"; }
  
  .fa-air-freshener::before {
	content: "\F5D0"; }
  
  .fa-signal-bars::before {
	content: "\F690"; }
  
  .fa-signal-alt::before {
	content: "\F690"; }
  
  .fa-signal-alt-4::before {
	content: "\F690"; }
  
  .fa-signal-bars-strong::before {
	content: "\F690"; }
  
  .fa-diamond-exclamation::before {
	content: "\E405"; }
  
  .fa-star::before {
	content: "\F005"; }
  
  .fa-dial-min::before {
	content: "\E161"; }
  
  .fa-repeat::before {
	content: "\F363"; }
  
  .fa-cross::before {
	content: "\F654"; }
  
  .fa-page-caret-down::before {
	content: "\E429"; }
  
  .fa-file-caret-down::before {
	content: "\E429"; }
  
  .fa-box::before {
	content: "\F466"; }
  
  .fa-venus-mars::before {
	content: "\F228"; }
  
  .fa-clock-seven-thirty::before {
	content: "\E351"; }
  
  .fa-arrow-pointer::before {
	content: "\F245"; }
  
  .fa-mouse-pointer::before {
	content: "\F245"; }
  
  .fa-clock-four-thirty::before {
	content: "\E34B"; }
  
  .fa-signal-bars-good::before {
	content: "\F693"; }
  
  .fa-signal-alt-3::before {
	content: "\F693"; }
  
  .fa-cactus::before {
	content: "\F8A7"; }
  
  .fa-maximize::before {
	content: "\F31E"; }
  
  .fa-expand-arrows-alt::before {
	content: "\F31E"; }
  
  .fa-charging-station::before {
	content: "\F5E7"; }
  
  .fa-shapes::before {
	content: "\F61F"; }
  
  .fa-triangle-circle-square::before {
	content: "\F61F"; }
  
  .fa-plane-tail::before {
	content: "\E22C"; }
  
  .fa-gauge-simple-max::before {
	content: "\F62B"; }
  
  .fa-tachometer-fastest::before {
	content: "\F62B"; }
  
  .fa-circle-u::before {
	content: "\E127"; }
  
  .fa-shield-slash::before {
	content: "\E24B"; }
  
  .fa-square-phone-hangup::before {
	content: "\E27A"; }
  
  .fa-phone-square-down::before {
	content: "\E27A"; }
  
  .fa-arrow-up-left::before {
	content: "\E09D"; }
  
  .fa-transporter-1::before {
	content: "\E043"; }
  
  .fa-peanuts::before {
	content: "\E431"; }
  
  .fa-shuffle::before {
	content: "\F074"; }
  
  .fa-random::before {
	content: "\F074"; }
  
  .fa-person-running::before {
	content: "\F70C"; }
  
  .fa-running::before {
	content: "\F70C"; }
  
  .fa-mobile-retro::before {
	content: "\E527"; }
  
  .fa-grip-lines-vertical::before {
	content: "\F7A5"; }
  
  .fa-arrow-up-from-square::before {
	content: "\E09C"; }
  
  .fa-file-dashed-line::before {
	content: "\F877"; }
  
  .fa-page-break::before {
	content: "\F877"; }
  
  .fa-bracket-curly-right::before {
	content: "}"; }
  
  .fa-spider::before {
	content: "\F717"; }
  
  .fa-clock-three::before {
	content: "\E356"; }
  
  .fa-hands-bound::before {
	content: "\E4F9"; }
  
  .fa-scalpel-line-dashed::before {
	content: "\F61E"; }
  
  .fa-scalpel-path::before {
	content: "\F61E"; }
  
  .fa-file-invoice-dollar::before {
	content: "\F571"; }
  
  .fa-pipe-smoking::before {
	content: "\E3C4"; }
  
  .fa-face-astonished::before {
	content: "\E36B"; }
  
  .fa-window::before {
	content: "\F40E"; }
  
  .fa-plane-circle-exclamation::before {
	content: "\E556"; }
  
  .fa-ear::before {
	content: "\F5F0"; }
  
  .fa-file-lock::before {
	content: "\E3A6"; }
  
  .fa-diagram-venn::before {
	content: "\E15A"; }
  
  .fa-x-ray::before {
	content: "\F497"; }
  
  .fa-goal-net::before {
	content: "\E3AB"; }
  
  .fa-coffin-cross::before {
	content: "\E051"; }
  
  .fa-spell-check::before {
	content: "\F891"; }
  
  .fa-location-xmark::before {
	content: "\F60E"; }
  
  .fa-map-marker-times::before {
	content: "\F60E"; }
  
  .fa-map-marker-xmark::before {
	content: "\F60E"; }
  
  .fa-lasso::before {
	content: "\F8C8"; }
  
  .fa-slash::before {
	content: "\F715"; }
  
  .fa-person-to-portal::before {
	content: "\E022"; }
  
  .fa-portal-enter::before {
	content: "\E022"; }
  
  .fa-calendar-star::before {
	content: "\F736"; }
  
  .fa-computer-mouse::before {
	content: "\F8CC"; }
  
  .fa-mouse::before {
	content: "\F8CC"; }
  
  .fa-arrow-right-to-bracket::before {
	content: "\F090"; }
  
  .fa-sign-in::before {
	content: "\F090"; }
  
  .fa-pegasus::before {
	content: "\F703"; }
  
  .fa-files-medical::before {
	content: "\F7FD"; }
  
  .fa-nfc-lock::before {
	content: "\E1F8"; }
  
  .fa-person-ski-lift::before {
	content: "\F7C8"; }
  
  .fa-ski-lift::before {
	content: "\F7C8"; }
  
  .fa-square-6::before {
	content: "\E25B"; }
  
  .fa-shop-slash::before {
	content: "\E070"; }
  
  .fa-store-alt-slash::before {
	content: "\E070"; }
  
  .fa-wind-turbine::before {
	content: "\F89B"; }
  
  .fa-sliders-simple::before {
	content: "\E253"; }
  
  .fa-badge-sheriff::before {
	content: "\F8A2"; }
  
  .fa-server::before {
	content: "\F233"; }
  
  .fa-virus-covid-slash::before {
	content: "\E4A9"; }
  
  .fa-intersection::before {
	content: "\F668"; }
  
  .fa-shop-lock::before {
	content: "\E4A5"; }
  
  .fa-family::before {
	content: "\E300"; }
  
  .fa-hourglass-start::before {
	content: "\F251"; }
  
  .fa-hourglass-1::before {
	content: "\F251"; }
  
  .fa-user-hair-buns::before {
	content: "\E3D3"; }
  
  .fa-blender-phone::before {
	content: "\F6B6"; }
  
  .fa-hourglass-clock::before {
	content: "\E41B"; }
  
  .fa-person-seat-reclined::before {
	content: "\E21F"; }
  
  .fa-paper-plane-top::before {
	content: "\E20A"; }
  
  .fa-paper-plane-alt::before {
	content: "\E20A"; }
  
  .fa-send::before {
	content: "\E20A"; }
  
  .fa-message-arrow-up::before {
	content: "\E1DC"; }
  
  .fa-comment-alt-arrow-up::before {
	content: "\E1DC"; }
  
  .fa-lightbulb-exclamation::before {
	content: "\F671"; }
  
  .fa-layer-minus::before {
	content: "\F5FE"; }
  
  .fa-layer-group-minus::before {
	content: "\F5FE"; }
  
  .fa-circle-e::before {
	content: "\E109"; }
  
  .fa-building-wheat::before {
	content: "\E4DB"; }
  
  .fa-gauge-max::before {
	content: "\F626"; }
  
  .fa-tachometer-alt-fastest::before {
	content: "\F626"; }
  
  .fa-person-breastfeeding::before {
	content: "\E53A"; }
  
  .fa-apostrophe::before {
	content: "'"; }
  
  .fa-fire-hydrant::before {
	content: "\E17F"; }
  
  .fa-right-to-bracket::before {
	content: "\F2F6"; }
  
  .fa-sign-in-alt::before {
	content: "\F2F6"; }
  
  .fa-video-plus::before {
	content: "\F4E1"; }
  
  .fa-square-right::before {
	content: "\F352"; }
  
  .fa-arrow-alt-square-right::before {
	content: "\F352"; }
  
  .fa-comment-smile::before {
	content: "\F4B4"; }
  
  .fa-venus::before {
	content: "\F221"; }
  
  .fa-passport::before {
	content: "\F5AB"; }
  
  .fa-inbox-in::before {
	content: "\F310"; }
  
  .fa-inbox-arrow-down::before {
	content: "\F310"; }
  
  .fa-heart-pulse::before {
	content: "\F21E"; }
  
  .fa-heartbeat::before {
	content: "\F21E"; }
  
  .fa-circle-8::before {
	content: "\E0F5"; }
  
  .fa-clouds-moon::before {
	content: "\F745"; }
  
  .fa-clock-ten-thirty::before {
	content: "\E355"; }
  
  .fa-people-carry-box::before {
	content: "\F4CE"; }
  
  .fa-people-carry::before {
	content: "\F4CE"; }
  
  .fa-folder-user::before {
	content: "\E18E"; }
  
  .fa-trash-can-xmark::before {
	content: "\E2AE"; }
  
  .fa-temperature-high::before {
	content: "\F769"; }
  
  .fa-microchip::before {
	content: "\F2DB"; }
  
  .fa-left-long-to-line::before {
	content: "\E41E"; }
  
  .fa-crown::before {
	content: "\F521"; }
  
  .fa-weight-hanging::before {
	content: "\F5CD"; }
  
  .fa-xmarks-lines::before {
	content: "\E59A"; }
  
  .fa-file-prescription::before {
	content: "\F572"; }
  
  .fa-calendar-range::before {
	content: "\E0D6"; }
  
  .fa-flower-daffodil::before {
	content: "\F800"; }
  
  .fa-hand-back-point-up::before {
	content: "\E1A2"; }
  
  .fa-weight-scale::before {
	content: "\F496"; }
  
  .fa-weight::before {
	content: "\F496"; }
  
  .fa-star-exclamation::before {
	content: "\F2F3"; }
  
  .fa-books::before {
	content: "\F5DB"; }
  
  .fa-user-group::before {
	content: "\F500"; }
  
  .fa-user-friends::before {
	content: "\F500"; }
  
  .fa-arrow-up-a-z::before {
	content: "\F15E"; }
  
  .fa-sort-alpha-up::before {
	content: "\F15E"; }
  
  .fa-layer-plus::before {
	content: "\F5FF"; }
  
  .fa-layer-group-plus::before {
	content: "\F5FF"; }
  
  .fa-play-pause::before {
	content: "\E22F"; }
  
  .fa-block-question::before {
	content: "\E3DD"; }
  
  .fa-snooze::before {
	content: "\F880"; }
  
  .fa-zzz::before {
	content: "\F880"; }
  
  .fa-scanner-image::before {
	content: "\F8F3"; }
  
  .fa-tv-retro::before {
	content: "\F401"; }
  
  .fa-square-t::before {
	content: "\E280"; }
  
  .fa-farm::before {
	content: "\F864"; }
  
  .fa-barn-silo::before {
	content: "\F864"; }
  
  .fa-chess-knight::before {
	content: "\F441"; }
  
  .fa-bars-sort::before {
	content: "\E0AE"; }
  
  .fa-pallet-boxes::before {
	content: "\F483"; }
  
  .fa-palette-boxes::before {
	content: "\F483"; }
  
  .fa-pallet-alt::before {
	content: "\F483"; }
  
  .fa-face-laugh-squint::before {
	content: "\F59B"; }
  
  .fa-laugh-squint::before {
	content: "\F59B"; }
  
  .fa-code-simple::before {
	content: "\E13D"; }
  
  .fa-bolt-slash::before {
	content: "\E0B8"; }
  
  .fa-panel-fire::before {
	content: "\E42F"; }
  
  .fa-binary-circle-check::before {
	content: "\E33C"; }
  
  .fa-comment-minus::before {
	content: "\F4B1"; }
  
  .fa-burrito::before {
	content: "\F7ED"; }
  
  .fa-violin::before {
	content: "\F8ED"; }
  
  .fa-objects-column::before {
	content: "\E3C1"; }
  
  .fa-square-chevron-down::before {
	content: "\F329"; }
  
  .fa-chevron-square-down::before {
	content: "\F329"; }
  
  .fa-comment-plus::before {
	content: "\F4B2"; }
  
  .fa-triangle-instrument::before {
	content: "\F8E2"; }
  
  .fa-triangle-music::before {
	content: "\F8E2"; }
  
  .fa-wheelchair::before {
	content: "\F193"; }
  
  .fa-user-pilot-tie::before {
	content: "\E2C1"; }
  
  .fa-piano-keyboard::before {
	content: "\F8D5"; }
  
  .fa-bed-empty::before {
	content: "\F8F9"; }
  
  .fa-circle-arrow-up::before {
	content: "\F0AA"; }
  
  .fa-arrow-circle-up::before {
	content: "\F0AA"; }
  
  .fa-toggle-on::before {
	content: "\F205"; }
  
  .fa-rectangle-vertical::before {
	content: "\F2FB"; }
  
  .fa-rectangle-portrait::before {
	content: "\F2FB"; }
  
  .fa-person-walking::before {
	content: "\F554"; }
  
  .fa-walking::before {
	content: "\F554"; }
  
  .fa-l::before {
	content: "L"; }
  
  .fa-signal-stream::before {
	content: "\F8DD"; }
  
  .fa-down-to-bracket::before {
	content: "\E4E7"; }
  
  .fa-circle-z::before {
	content: "\E130"; }
  
  .fa-stars::before {
	content: "\F762"; }
  
  .fa-fire::before {
	content: "\F06D"; }
  
  .fa-bed-pulse::before {
	content: "\F487"; }
  
  .fa-procedures::before {
	content: "\F487"; }
  
  .fa-house-day::before {
	content: "\E00E"; }
  
  .fa-shuttle-space::before {
	content: "\F197"; }
  
  .fa-space-shuttle::before {
	content: "\F197"; }
  
  .fa-shirt-long-sleeve::before {
	content: "\E3C7"; }
  
  .fa-chart-pie-simple::before {
	content: "\F64E"; }
  
  .fa-chart-pie-alt::before {
	content: "\F64E"; }
  
  .fa-face-laugh::before {
	content: "\F599"; }
  
  .fa-laugh::before {
	content: "\F599"; }
  
  .fa-folder-open::before {
	content: "\F07C"; }
  
  .fa-album-collection-circle-user::before {
	content: "\E48F"; }
  
  .fa-candy::before {
	content: "\E3E7"; }
  
  .fa-bowl-hot::before {
	content: "\F823"; }
  
  .fa-soup::before {
	content: "\F823"; }
  
  .fa-flatbread::before {
	content: "\E40B"; }
  
  .fa-heart-circle-plus::before {
	content: "\E500"; }
  
  .fa-code-fork::before {
	content: "\E13B"; }
  
  .fa-city::before {
	content: "\F64F"; }
  
  .fa-signal-bars-weak::before {
	content: "\F691"; }
  
  .fa-signal-alt-1::before {
	content: "\F691"; }
  
  .fa-microphone-lines::before {
	content: "\F3C9"; }
  
  .fa-microphone-alt::before {
	content: "\F3C9"; }
  
  .fa-clock-twelve::before {
	content: "\E358"; }
  
  .fa-pepper-hot::before {
	content: "\F816"; }
  
  .fa-citrus-slice::before {
	content: "\E2F5"; }
  
  .fa-sheep::before {
	content: "\F711"; }
  
  .fa-unlock::before {
	content: "\F09C"; }
  
  .fa-colon-sign::before {
	content: "\E140"; }
  
  .fa-headset::before {
	content: "\F590"; }
  
  .fa-badger-honey::before {
	content: "\F6B4"; }
  
  .fa-h4::before {
	content: "\F86A"; }
  
  .fa-store-slash::before {
	content: "\E071"; }
  
  .fa-road-circle-xmark::before {
	content: "\E566"; }
  
  .fa-signal-slash::before {
	content: "\F695"; }
  
  .fa-user-minus::before {
	content: "\F503"; }
  
  .fa-mars-stroke-up::before {
	content: "\F22A"; }
  
  .fa-mars-stroke-v::before {
	content: "\F22A"; }
  
  .fa-champagne-glasses::before {
	content: "\F79F"; }
  
  .fa-glass-cheers::before {
	content: "\F79F"; }
  
  .fa-taco::before {
	content: "\F826"; }
  
  .fa-hexagon-plus::before {
	content: "\F300"; }
  
  .fa-plus-hexagon::before {
	content: "\F300"; }
  
  .fa-clipboard::before {
	content: "\F328"; }
  
  .fa-house-circle-exclamation::before {
	content: "\E50A"; }
  
  .fa-file-arrow-up::before {
	content: "\F574"; }
  
  .fa-file-upload::before {
	content: "\F574"; }
  
  .fa-wifi::before {
	content: "\F1EB"; }
  
  .fa-wifi-3::before {
	content: "\F1EB"; }
  
  .fa-wifi-strong::before {
	content: "\F1EB"; }
  
  .fa-messages::before {
	content: "\F4B6"; }
  
  .fa-comments-alt::before {
	content: "\F4B6"; }
  
  .fa-bath::before {
	content: "\F2CD"; }
  
  .fa-bathtub::before {
	content: "\F2CD"; }
  
  .fa-umbrella-simple::before {
	content: "\E2BC"; }
  
  .fa-umbrella-alt::before {
	content: "\E2BC"; }
  
  .fa-rectangle-history-circle-plus::before {
	content: "\E4A3"; }
  
  .fa-underline::before {
	content: "\F0CD"; }
  
  .fa-user-pen::before {
	content: "\F4FF"; }
  
  .fa-user-edit::before {
	content: "\F4FF"; }
  
  .fa-binary-slash::before {
	content: "\E33E"; }
  
  .fa-square-o::before {
	content: "\E278"; }
  
  .fa-signature::before {
	content: "\F5B7"; }
  
  .fa-stroopwafel::before {
	content: "\F551"; }
  
  .fa-bold::before {
	content: "\F032"; }
  
  .fa-anchor-lock::before {
	content: "\E4AD"; }
  
  .fa-building-ngo::before {
	content: "\E4D7"; }
  
  .fa-transporter-3::before {
	content: "\E045"; }
  
  .fa-engine-warning::before {
	content: "\F5F2"; }
  
  .fa-engine-exclamation::before {
	content: "\F5F2"; }
  
  .fa-circle-down-right::before {
	content: "\E108"; }
  
  .fa-square-k::before {
	content: "\E274"; }
  
  .fa-manat-sign::before {
	content: "\E1D5"; }
  
  .fa-money-check-pen::before {
	content: "\F872"; }
  
  .fa-money-check-edit::before {
	content: "\F872"; }
  
  .fa-not-equal::before {
	content: "\F53E"; }
  
  .fa-border-top-left::before {
	content: "\F853"; }
  
  .fa-border-style::before {
	content: "\F853"; }
  
  .fa-map-location-dot::before {
	content: "\F5A0"; }
  
  .fa-map-marked-alt::before {
	content: "\F5A0"; }
  
  .fa-tilde::before {
	content: "~"; }
  
  .fa-jedi::before {
	content: "\F669"; }
  
  .fa-square-poll-vertical::before {
	content: "\F681"; }
  
  .fa-poll::before {
	content: "\F681"; }
  
  .fa-arrow-down-square-triangle::before {
	content: "\F889"; }
  
  .fa-sort-shapes-down-alt::before {
	content: "\F889"; }
  
  .fa-mug-hot::before {
	content: "\F7B6"; }
  
  .fa-dog-leashed::before {
	content: "\F6D4"; }
  
  .fa-car-battery::before {
	content: "\F5DF"; }
  
  .fa-battery-car::before {
	content: "\F5DF"; }
  
  .fa-face-downcast-sweat::before {
	content: "\E371"; }
  
  .fa-memo-circle-info::before {
	content: "\E49A"; }
  
  .fa-gift::before {
	content: "\F06B"; }
  
  .fa-dice-two::before {
	content: "\F528"; }
  
  .fa-volume::before {
	content: "\F6A8"; }
  
  .fa-volume-medium::before {
	content: "\F6A8"; }
  
  .fa-transporter-5::before {
	content: "\E2A6"; }
  
  .fa-gauge-circle-bolt::before {
	content: "\E496"; }
  
  .fa-coin-front::before {
	content: "\E3FC"; }
  
  .fa-file-slash::before {
	content: "\E3A7"; }
  
  .fa-message-arrow-up-right::before {
	content: "\E1DD"; }
  
  .fa-treasure-chest::before {
	content: "\F723"; }
  
  .fa-chess-queen::before {
	content: "\F445"; }
  
  .fa-paintbrush-fine::before {
	content: "\F5A9"; }
  
  .fa-paint-brush-alt::before {
	content: "\F5A9"; }
  
  .fa-paint-brush-fine::before {
	content: "\F5A9"; }
  
  .fa-paintbrush-alt::before {
	content: "\F5A9"; }
  
  .fa-glasses::before {
	content: "\F530"; }
  
  .fa-hood-cloak::before {
	content: "\F6EF"; }
  
  .fa-square-quote::before {
	content: "\E329"; }
  
  .fa-up-left::before {
	content: "\E2BD"; }
  
  .fa-bring-front::before {
	content: "\F857"; }
  
  .fa-chess-board::before {
	content: "\F43C"; }
  
  .fa-burger-cheese::before {
	content: "\F7F1"; }
  
  .fa-cheeseburger::before {
	content: "\F7F1"; }
  
  .fa-building-circle-check::before {
	content: "\E4D2"; }
  
  .fa-repeat-1::before {
	content: "\F365"; }
  
  .fa-arrow-down-to-line::before {
	content: "\F33D"; }
  
  .fa-arrow-to-bottom::before {
	content: "\F33D"; }
  
  .fa-grid-5::before {
	content: "\E199"; }
  
  .fa-right-long-to-line::before {
	content: "\E444"; }
  
  .fa-person-chalkboard::before {
	content: "\E53D"; }
  
  .fa-mars-stroke-right::before {
	content: "\F22B"; }
  
  .fa-mars-stroke-h::before {
	content: "\F22B"; }
  
  .fa-hand-back-fist::before {
	content: "\F255"; }
  
  .fa-hand-rock::before {
	content: "\F255"; }
  
  .fa-tally::before {
	content: "\F69C"; }
  
  .fa-tally-5::before {
	content: "\F69C"; }
  
  .fa-square-caret-up::before {
	content: "\F151"; }
  
  .fa-caret-square-up::before {
	content: "\F151"; }
  
  .fa-cloud-showers-water::before {
	content: "\E4E4"; }
  
  .fa-chart-bar::before {
	content: "\F080"; }
  
  .fa-bar-chart::before {
	content: "\F080"; }
  
  .fa-hands-bubbles::before {
	content: "\E05E"; }
  
  .fa-hands-wash::before {
	content: "\E05E"; }
  
  .fa-less-than-equal::before {
	content: "\F537"; }
  
  .fa-train::before {
	content: "\F238"; }
  
  .fa-up-from-dotted-line::before {
	content: "\E456"; }
  
  .fa-eye-low-vision::before {
	content: "\F2A8"; }
  
  .fa-low-vision::before {
	content: "\F2A8"; }
  
  .fa-traffic-light-go::before {
	content: "\F638"; }
  
  .fa-face-exhaling::before {
	content: "\E480"; }
  
  .fa-sensor-fire::before {
	content: "\E02A"; }
  
  .fa-user-unlock::before {
	content: "\E058"; }
  
  .fa-hexagon-divide::before {
	content: "\E1AD"; }
  
  .fa-00::before {
	content: "\E467"; }
  
  .fa-crow::before {
	content: "\F520"; }
  
  .fa-cassette-betamax::before {
	content: "\F8A4"; }
  
  .fa-betamax::before {
	content: "\F8A4"; }
  
  .fa-sailboat::before {
	content: "\E445"; }
  
  .fa-window-restore::before {
	content: "\F2D2"; }
  
  .fa-nfc-magnifying-glass::before {
	content: "\E1F9"; }
  
  .fa-file-binary::before {
	content: "\E175"; }
  
  .fa-circle-v::before {
	content: "\E12A"; }
  
  .fa-square-plus::before {
	content: "\F0FE"; }
  
  .fa-plus-square::before {
	content: "\F0FE"; }
  
  .fa-bowl-scoops::before {
	content: "\E3DF"; }
  
  .fa-mistletoe::before {
	content: "\F7B4"; }
  
  .fa-custard::before {
	content: "\E403"; }
  
  .fa-lacrosse-stick::before {
	content: "\E3B5"; }
  
  .fa-hockey-mask::before {
	content: "\F6EE"; }
  
  .fa-sunrise::before {
	content: "\F766"; }
  
  .fa-panel-ews::before {
	content: "\E42E"; }
  
  .fa-torii-gate::before {
	content: "\F6A1"; }
  
  .fa-cloud-exclamation::before {
	content: "\E491"; }
  
  .fa-message-lines::before {
	content: "\F4A6"; }
  
  .fa-comment-alt-lines::before {
	content: "\F4A6"; }
  
  .fa-frog::before {
	content: "\F52E"; }
  
  .fa-bucket::before {
	content: "\E4CF"; }
  
  .fa-floppy-disk-pen::before {
	content: "\E182"; }
  
  .fa-image::before {
	content: "\F03E"; }
  
  .fa-window-frame::before {
	content: "\E04F"; }
  
  .fa-microphone::before {
	content: "\F130"; }
  
  .fa-cow::before {
	content: "\F6C8"; }
  
  .fa-square-ring::before {
	content: "\E44F"; }
  
  .fa-down-from-line::before {
	content: "\F349"; }
  
  .fa-arrow-alt-from-top::before {
	content: "\F349"; }
  
  .fa-caret-up::before {
	content: "\F0D8"; }
  
  .fa-shield-xmark::before {
	content: "\E24C"; }
  
  .fa-shield-times::before {
	content: "\E24C"; }
  
  .fa-screwdriver::before {
	content: "\F54A"; }
  
  .fa-circle-sort-down::before {
	content: "\E031"; }
  
  .fa-sort-circle-down::before {
	content: "\E031"; }
  
  .fa-folder-closed::before {
	content: "\E185"; }
  
  .fa-house-tsunami::before {
	content: "\E515"; }
  
  .fa-square-nfi::before {
	content: "\E576"; }
  
  .fa-forklift::before {
	content: "\F47A"; }
  
  .fa-arrow-up-from-ground-water::before {
	content: "\E4B5"; }
  
  .fa-bracket-square-right::before {
	content: "]"; }
  
  .fa-martini-glass::before {
	content: "\F57B"; }
  
  .fa-glass-martini-alt::before {
	content: "\F57B"; }
  
  .fa-rotate-left::before {
	content: "\F2EA"; }
  
  .fa-rotate-back::before {
	content: "\F2EA"; }
  
  .fa-rotate-backward::before {
	content: "\F2EA"; }
  
  .fa-undo-alt::before {
	content: "\F2EA"; }
  
  .fa-table-columns::before {
	content: "\F0DB"; }
  
  .fa-columns::before {
	content: "\F0DB"; }
  
  .fa-square-a::before {
	content: "\E25F"; }
  
  .fa-tick::before {
	content: "\E32F"; }
  
  .fa-lemon::before {
	content: "\F094"; }
  
  .fa-head-side-mask::before {
	content: "\E063"; }
  
  .fa-handshake::before {
	content: "\F2B5"; }
  
  .fa-gem::before {
	content: "\F3A5"; }
  
  .fa-dolly::before {
	content: "\F472"; }
  
  .fa-dolly-box::before {
	content: "\F472"; }
  
  .fa-smoking::before {
	content: "\F48D"; }
  
  .fa-minimize::before {
	content: "\F78C"; }
  
  .fa-compress-arrows-alt::before {
	content: "\F78C"; }
  
  .fa-refrigerator::before {
	content: "\E026"; }
  
  .fa-monument::before {
	content: "\F5A6"; }
  
  .fa-octagon-xmark::before {
	content: "\F2F0"; }
  
  .fa-times-octagon::before {
	content: "\F2F0"; }
  
  .fa-xmark-octagon::before {
	content: "\F2F0"; }
  
  .fa-align-slash::before {
	content: "\F846"; }
  
  .fa-snowplow::before {
	content: "\F7D2"; }
  
  .fa-angles-right::before {
	content: "\F101"; }
  
  .fa-angle-double-right::before {
	content: "\F101"; }
  
  .fa-truck-ramp-couch::before {
	content: "\F4DD"; }
  
  .fa-truck-couch::before {
	content: "\F4DD"; }
  
  .fa-cannabis::before {
	content: "\F55F"; }
  
  .fa-circle-play::before {
	content: "\F144"; }
  
  .fa-play-circle::before {
	content: "\F144"; }
  
  .fa-arrow-up-right-and-arrow-down-left-from-center::before {
	content: "\E0A0"; }
  
  .fa-tablets::before {
	content: "\F490"; }
  
  .fa-360-degrees::before {
	content: "\E2DC"; }
  
  .fa-ethernet::before {
	content: "\F796"; }
  
  .fa-euro-sign::before {
	content: "\F153"; }
  
  .fa-eur::before {
	content: "\F153"; }
  
  .fa-euro::before {
	content: "\F153"; }
  
  .fa-chair::before {
	content: "\F6C0"; }
  
  .fa-circle-check::before {
	content: "\F058"; }
  
  .fa-check-circle::before {
	content: "\F058"; }
  
  .fa-money-simple-from-bracket::before {
	content: "\E313"; }
  
  .fa-bat::before {
	content: "\F6B5"; }
  
  .fa-circle-stop::before {
	content: "\F28D"; }
  
  .fa-stop-circle::before {
	content: "\F28D"; }
  
  .fa-head-side-headphones::before {
	content: "\F8C2"; }
  
  .fa-phone-rotary::before {
	content: "\F8D3"; }
  
  .fa-compass-drafting::before {
	content: "\F568"; }
  
  .fa-drafting-compass::before {
	content: "\F568"; }
  
  .fa-plate-wheat::before {
	content: "\E55A"; }
  
  .fa-calendar-circle-minus::before {
	content: "\E46F"; }
  
  .fa-chopsticks::before {
	content: "\E3F7"; }
  
  .fa-car-wrench::before {
	content: "\F5E3"; }
  
  .fa-car-mechanic::before {
	content: "\F5E3"; }
  
  .fa-icicles::before {
	content: "\F7AD"; }
  
  .fa-person-shelter::before {
	content: "\E54F"; }
  
  .fa-neuter::before {
	content: "\F22C"; }
  
  .fa-id-badge::before {
	content: "\F2C1"; }
  
  .fa-kazoo::before {
	content: "\F8C7"; }
  
  .fa-marker::before {
	content: "\F5A1"; }
  
  .fa-face-laugh-beam::before {
	content: "\F59A"; }
  
  .fa-laugh-beam::before {
	content: "\F59A"; }
  
  .fa-square-arrow-down-left::before {
	content: "\E261"; }
  
  .fa-battery-bolt::before {
	content: "\F376"; }
  
  .fa-tree-large::before {
	content: "\F7DD"; }
  
  .fa-helicopter-symbol::before {
	content: "\E502"; }
  
  .fa-aperture::before {
	content: "\E2DF"; }
  
  .fa-universal-access::before {
	content: "\F29A"; }
  
  .fa-file-magnifying-glass::before {
	content: "\F865"; }
  
  .fa-file-search::before {
	content: "\F865"; }
  
  .fa-up-right::before {
	content: "\E2BE"; }
  
  .fa-circle-chevron-up::before {
	content: "\F139"; }
  
  .fa-chevron-circle-up::before {
	content: "\F139"; }
  
  .fa-user-police::before {
	content: "\E333"; }
  
  .fa-lari-sign::before {
	content: "\E1C8"; }
  
  .fa-volcano::before {
	content: "\F770"; }
  
  .fa-teddy-bear::before {
	content: "\E3CF"; }
  
  .fa-stocking::before {
	content: "\F7D5"; }
  
  .fa-person-walking-dashed-line-arrow-right::before {
	content: "\E553"; }
  
  .fa-image-slash::before {
	content: "\E1B7"; }
  
  .fa-mask-snorkel::before {
	content: "\E3B7"; }
  
  .fa-smoke::before {
	content: "\F760"; }
  
  .fa-sterling-sign::before {
	content: "\F154"; }
  
  .fa-gbp::before {
	content: "\F154"; }
  
  .fa-pound-sign::before {
	content: "\F154"; }
  
  .fa-battery-exclamation::before {
	content: "\E0B0"; }
  
  .fa-viruses::before {
	content: "\E076"; }
  
  .fa-square-person-confined::before {
	content: "\E577"; }
  
  .fa-user-tie::before {
	content: "\F508"; }
  
  .fa-arrow-down-long::before {
	content: "\F175"; }
  
  .fa-long-arrow-down::before {
	content: "\F175"; }
  
  .fa-tent-arrow-down-to-line::before {
	content: "\E57E"; }
  
  .fa-certificate::before {
	content: "\F0A3"; }
  
  .fa-crystal-ball::before {
	content: "\E362"; }
  
  .fa-reply-all::before {
	content: "\F122"; }
  
  .fa-mail-reply-all::before {
	content: "\F122"; }
  
  .fa-suitcase::before {
	content: "\F0F2"; }
  
  .fa-person-skating::before {
	content: "\F7C5"; }
  
  .fa-skating::before {
	content: "\F7C5"; }
  
  .fa-star-shooting::before {
	content: "\E036"; }
  
  .fa-binary-lock::before {
	content: "\E33D"; }
  
  .fa-filter-circle-dollar::before {
	content: "\F662"; }
  
  .fa-funnel-dollar::before {
	content: "\F662"; }
  
  .fa-camera-retro::before {
	content: "\F083"; }
  
  .fa-circle-arrow-down::before {
	content: "\F0AB"; }
  
  .fa-arrow-circle-down::before {
	content: "\F0AB"; }
  
  .fa-comment-pen::before {
	content: "\F4AE"; }
  
  .fa-comment-edit::before {
	content: "\F4AE"; }
  
  .fa-file-import::before {
	content: "\F56F"; }
  
  .fa-arrow-right-to-file::before {
	content: "\F56F"; }
  
  .fa-banjo::before {
	content: "\F8A3"; }
  
  .fa-square-arrow-up-right::before {
	content: "\F14C"; }
  
  .fa-external-link-square::before {
	content: "\F14C"; }
  
  .fa-light-emergency-on::before {
	content: "\E420"; }
  
  .fa-kerning::before {
	content: "\F86F"; }
  
  .fa-box-open::before {
	content: "\F49E"; }
  
  .fa-square-f::before {
	content: "\E270"; }
  
  .fa-scroll::before {
	content: "\F70E"; }
  
  .fa-spa::before {
	content: "\F5BB"; }
  
  .fa-arrow-left-from-line::before {
	content: "\F344"; }
  
  .fa-arrow-from-right::before {
	content: "\F344"; }
  
  .fa-strawberry::before {
	content: "\E32B"; }
  
  .fa-location-pin-lock::before {
	content: "\E51F"; }
  
  .fa-pause::before {
	content: "\F04C"; }
  
  .fa-clock-eight-thirty::before {
	content: "\E346"; }
  
  .fa-plane-engines::before {
	content: "\F3DE"; }
  
  .fa-plane-alt::before {
	content: "\F3DE"; }
  
  .fa-hill-avalanche::before {
	content: "\E507"; }
  
  .fa-temperature-empty::before {
	content: "\F2CB"; }
  
  .fa-temperature-0::before {
	content: "\F2CB"; }
  
  .fa-thermometer-0::before {
	content: "\F2CB"; }
  
  .fa-thermometer-empty::before {
	content: "\F2CB"; }
  
  .fa-bomb::before {
	content: "\F1E2"; }
  
  .fa-gauge-low::before {
	content: "\F627"; }
  
  .fa-tachometer-alt-slow::before {
	content: "\F627"; }
  
  .fa-registered::before {
	content: "\F25D"; }
  
  .fa-trash-can-plus::before {
	content: "\E2AC"; }
  
  .fa-address-card::before {
	content: "\F2BB"; }
  
  .fa-contact-card::before {
	content: "\F2BB"; }
  
  .fa-vcard::before {
	content: "\F2BB"; }
  
  .fa-scale-unbalanced-flip::before {
	content: "\F516"; }
  
  .fa-balance-scale-right::before {
	content: "\F516"; }
  
  .fa-globe-snow::before {
	content: "\F7A3"; }
  
  .fa-subscript::before {
	content: "\F12C"; }
  
  .fa-diamond-turn-right::before {
	content: "\F5EB"; }
  
  .fa-directions::before {
	content: "\F5EB"; }
  
  .fa-integral::before {
	content: "\F667"; }
  
  .fa-burst::before {
	content: "\E4DC"; }
  
  .fa-house-laptop::before {
	content: "\E066"; }
  
  .fa-laptop-house::before {
	content: "\E066"; }
  
  .fa-face-tired::before {
	content: "\F5C8"; }
  
  .fa-tired::before {
	content: "\F5C8"; }
  
  .fa-money-bills::before {
	content: "\E1F3"; }
  
  .fa-blinds-raised::before {
	content: "\F8FD"; }
  
  .fa-smog::before {
	content: "\F75F"; }
  
  .fa-ufo-beam::before {
	content: "\E048"; }
  
  .fa-circle-caret-up::before {
	content: "\F331"; }
  
  .fa-caret-circle-up::before {
	content: "\F331"; }
  
  .fa-user-vneck-hair-long::before {
	content: "\E463"; }
  
  .fa-square-a-lock::before {
	content: "\E44D"; }
  
  .fa-crutch::before {
	content: "\F7F7"; }
  
  .fa-gas-pump-slash::before {
	content: "\F5F4"; }
  
  .fa-cloud-arrow-up::before {
	content: "\F0EE"; }
  
  .fa-cloud-upload::before {
	content: "\F0EE"; }
  
  .fa-cloud-upload-alt::before {
	content: "\F0EE"; }
  
  .fa-palette::before {
	content: "\F53F"; }
  
  .fa-transporter-4::before {
	content: "\E2A5"; }
  
  .fa-objects-align-right::before {
	content: "\E3BF"; }
  
  .fa-arrows-turn-right::before {
	content: "\E4C0"; }
  
  .fa-vest::before {
	content: "\E085"; }
  
  .fa-pig::before {
	content: "\F706"; }
  
  .fa-inbox-full::before {
	content: "\E1BA"; }
  
  .fa-circle-envelope::before {
	content: "\E10C"; }
  
  .fa-envelope-circle::before {
	content: "\E10C"; }
  
  .fa-triangle-person-digging::before {
	content: "\F85D"; }
  
  .fa-construction::before {
	content: "\F85D"; }
  
  .fa-ferry::before {
	content: "\E4EA"; }
  
  .fa-bullseye-arrow::before {
	content: "\F648"; }
  
  .fa-arrows-down-to-people::before {
	content: "\E4B9"; }
  
  .fa-seedling::before {
	content: "\F4D8"; }
  
  .fa-sprout::before {
	content: "\F4D8"; }
  
  .fa-clock-seven::before {
	content: "\E350"; }
  
  .fa-left-right::before {
	content: "\F337"; }
  
  .fa-arrows-alt-h::before {
	content: "\F337"; }
  
  .fa-boxes-packing::before {
	content: "\E4C7"; }
  
  .fa-circle-arrow-left::before {
	content: "\F0A8"; }
  
  .fa-arrow-circle-left::before {
	content: "\F0A8"; }
  
  .fa-flashlight::before {
	content: "\F8B8"; }
  
  .fa-group-arrows-rotate::before {
	content: "\E4F6"; }
  
  .fa-bowl-food::before {
	content: "\E4C6"; }
  
  .fa-square-9::before {
	content: "\E25E"; }
  
  .fa-candy-cane::before {
	content: "\F786"; }
  
  .fa-arrow-down-wide-short::before {
	content: "\F160"; }
  
  .fa-sort-amount-asc::before {
	content: "\F160"; }
  
  .fa-sort-amount-down::before {
	content: "\F160"; }
  
  .fa-square-dollar::before {
	content: "\F2E9"; }
  
  .fa-dollar-square::before {
	content: "\F2E9"; }
  
  .fa-usd-square::before {
	content: "\F2E9"; }
  
  .fa-hand-holding-seedling::before {
	content: "\F4BF"; }
  
  .fa-message-check::before {
	content: "\F4A2"; }
  
  .fa-comment-alt-check::before {
	content: "\F4A2"; }
  
  .fa-cloud-bolt::before {
	content: "\F76C"; }
  
  .fa-thunderstorm::before {
	content: "\F76C"; }
  
  .fa-text-slash::before {
	content: "\F87D"; }
  
  .fa-remove-format::before {
	content: "\F87D"; }
  
  .fa-watch::before {
	content: "\F2E1"; }
  
  .fa-circle-down-left::before {
	content: "\E107"; }
  
  .fa-text::before {
	content: "\F893"; }
  
  .fa-projector::before {
	content: "\F8D6"; }
  
  .fa-face-smile-wink::before {
	content: "\F4DA"; }
  
  .fa-smile-wink::before {
	content: "\F4DA"; }
  
  .fa-tombstone-blank::before {
	content: "\F721"; }
  
  .fa-tombstone-alt::before {
	content: "\F721"; }
  
  .fa-chess-king-piece::before {
	content: "\F440"; }
  
  .fa-chess-king-alt::before {
	content: "\F440"; }
  
  .fa-circle-6::before {
	content: "\E0F3"; }
  
  .fa-left::before {
	content: "\F355"; }
  
  .fa-arrow-alt-left::before {
	content: "\F355"; }
  
  .fa-file-word::before {
	content: "\F1C2"; }
  
  .fa-file-powerpoint::before {
	content: "\F1C4"; }
  
  .fa-square-down::before {
	content: "\F350"; }
  
  .fa-arrow-alt-square-down::before {
	content: "\F350"; }
  
  .fa-objects-align-center-vertical::before {
	content: "\E3BD"; }
  
  .fa-arrows-left-right::before {
	content: "\F07E"; }
  
  .fa-arrows-h::before {
	content: "\F07E"; }
  
  .fa-house-lock::before {
	content: "\E510"; }
  
  .fa-cloud-arrow-down::before {
	content: "\F0ED"; }
  
  .fa-cloud-download::before {
	content: "\F0ED"; }
  
  .fa-cloud-download-alt::before {
	content: "\F0ED"; }
  
  .fa-wreath::before {
	content: "\F7E2"; }
  
  .fa-children::before {
	content: "\E4E1"; }
  
  .fa-meter-droplet::before {
	content: "\E1EA"; }
  
  .fa-chalkboard::before {
	content: "\F51B"; }
  
  .fa-blackboard::before {
	content: "\F51B"; }
  
  .fa-user-large-slash::before {
	content: "\F4FA"; }
  
  .fa-user-alt-slash::before {
	content: "\F4FA"; }
  
  .fa-signal-strong::before {
	content: "\F68F"; }
  
  .fa-signal-4::before {
	content: "\F68F"; }
  
  .fa-lollipop::before {
	content: "\E424"; }
  
  .fa-lollypop::before {
	content: "\E424"; }
  
  .fa-list-tree::before {
	content: "\E1D2"; }
  
  .fa-envelope-open::before {
	content: "\F2B6"; }
  
  .fa-draw-circle::before {
	content: "\F5ED"; }
  
  .fa-cat-space::before {
	content: "\E001"; }
  
  .fa-handshake-simple-slash::before {
	content: "\E05F"; }
  
  .fa-handshake-alt-slash::before {
	content: "\E05F"; }
  
  .fa-rabbit-running::before {
	content: "\F709"; }
  
  .fa-rabbit-fast::before {
	content: "\F709"; }
  
  .fa-memo-pad::before {
	content: "\E1DA"; }
  
  .fa-mattress-pillow::before {
	content: "\E525"; }
  
  .fa-alarm-plus::before {
	content: "\F844"; }
  
  .fa-alicorn::before {
	content: "\F6B0"; }
  
  .fa-comment-question::before {
	content: "\E14B"; }
  
  .fa-gingerbread-man::before {
	content: "\F79D"; }
  
  .fa-guarani-sign::before {
	content: "\E19A"; }
  
  .fa-burger-fries::before {
	content: "\E0CD"; }
  
  .fa-mug-tea::before {
	content: "\F875"; }
  
  .fa-border-top::before {
	content: "\F855"; }
  
  .fa-arrows-rotate::before {
	content: "\F021"; }
  
  .fa-refresh::before {
	content: "\F021"; }
  
  .fa-sync::before {
	content: "\F021"; }
  
  .fa-circle-book-open::before {
	content: "\E0FF"; }
  
  .fa-book-circle::before {
	content: "\E0FF"; }
  
  .fa-arrows-to-dotted-line::before {
	content: "\E0A6"; }
  
  .fa-fire-extinguisher::before {
	content: "\F134"; }
  
  .fa-garage-open::before {
	content: "\E00B"; }
  
  .fa-shelves-empty::before {
	content: "\E246"; }
  
  .fa-cruzeiro-sign::before {
	content: "\E152"; }
  
  .fa-watch-apple::before {
	content: "\E2CB"; }
  
  .fa-watch-calculator::before {
	content: "\F8F0"; }
  
  .fa-list-dropdown::before {
	content: "\E1CF"; }
  
  .fa-cabinet-filing::before {
	content: "\F64B"; }
  
  .fa-burger-soda::before {
	content: "\F858"; }
  
  .fa-square-arrow-up::before {
	content: "\F33C"; }
  
  .fa-arrow-square-up::before {
	content: "\F33C"; }
  
  .fa-greater-than-equal::before {
	content: "\F532"; }
  
  .fa-pallet-box::before {
	content: "\E208"; }
  
  .fa-face-confounded::before {
	content: "\E36C"; }
  
  .fa-shield-halved::before {
	content: "\F3ED"; }
  
  .fa-shield-alt::before {
	content: "\F3ED"; }
  
  .fa-truck-plow::before {
	content: "\F7DE"; }
  
  .fa-book-atlas::before {
	content: "\F558"; }
  
  .fa-atlas::before {
	content: "\F558"; }
  
  .fa-virus::before {
	content: "\E074"; }
  
  .fa-comment-middle-top::before {
	content: "\E14A"; }
  
  .fa-envelope-circle-check::before {
	content: "\E4E8"; }
  
  .fa-layer-group::before {
	content: "\F5FD"; }
  
  .fa-restroom-simple::before {
	content: "\E23A"; }
  
  .fa-arrows-to-dot::before {
	content: "\E4BE"; }
  
  .fa-border-outer::before {
	content: "\F851"; }
  
  .fa-hashtag-lock::before {
	content: "\E415"; }
  
  .fa-clock-two-thirty::before {
	content: "\E35B"; }
  
  .fa-archway::before {
	content: "\F557"; }
  
  .fa-heart-circle-check::before {
	content: "\E4FD"; }
  
  .fa-house-chimney-crack::before {
	content: "\F6F1"; }
  
  .fa-house-damage::before {
	content: "\F6F1"; }
  
  .fa-file-zipper::before {
	content: "\F1C6"; }
  
  .fa-file-archive::before {
	content: "\F1C6"; }
  
  .fa-heart-half::before {
	content: "\E1AB"; }
  
  .fa-comment-check::before {
	content: "\F4AC"; }
  
  .fa-square::before {
	content: "\F0C8"; }
  
  .fa-memo::before {
	content: "\E1D8"; }
  
  .fa-martini-glass-empty::before {
	content: "\F000"; }
  
  .fa-glass-martini::before {
	content: "\F000"; }
  
  .fa-couch::before {
	content: "\F4B8"; }
  
  .fa-cedi-sign::before {
	content: "\E0DF"; }
  
  .fa-italic::before {
	content: "\F033"; }
  
  .fa-glass-citrus::before {
	content: "\F869"; }
  
  .fa-calendar-lines-pen::before {
	content: "\E472"; }
  
  .fa-church::before {
	content: "\F51D"; }
  
  .fa-person-snowmobiling::before {
	content: "\F7D1"; }
  
  .fa-snowmobile::before {
	content: "\F7D1"; }
  
  .fa-face-hushed::before {
	content: "\E37B"; }
  
  .fa-comments-dollar::before {
	content: "\F653"; }
  
  .fa-link-simple-slash::before {
	content: "\E1CE"; }
  
  .fa-democrat::before {
	content: "\F747"; }
  
  .fa-face-confused::before {
	content: "\E36D"; }
  
  .fa-pinball::before {
	content: "\E229"; }
  
  .fa-z::before {
	content: "Z"; }
  
  .fa-person-skiing::before {
	content: "\F7C9"; }
  
  .fa-skiing::before {
	content: "\F7C9"; }
  
  .fa-deer::before {
	content: "\F78E"; }
  
  .fa-input-pipe::before {
	content: "\E1BE"; }
  
  .fa-road-lock::before {
	content: "\E567"; }
  
  .fa-a::before {
	content: "A"; }
  
  .fa-bookmark-slash::before {
	content: "\E0C2"; }
  
  .fa-temperature-arrow-down::before {
	content: "\E03F"; }
  
  .fa-temperature-down::before {
	content: "\E03F"; }
  
  .fa-mace::before {
	content: "\F6F8"; }
  
  .fa-feather-pointed::before {
	content: "\F56B"; }
  
  .fa-feather-alt::before {
	content: "\F56B"; }
  
  .fa-sausage::before {
	content: "\F820"; }
  
  .fa-trash-can-clock::before {
	content: "\E2AA"; }
  
  .fa-p::before {
	content: "P"; }
  
  .fa-snowflake::before {
	content: "\F2DC"; }
  
  .fa-stomach::before {
	content: "\F623"; }
  
  .fa-newspaper::before {
	content: "\F1EA"; }
  
  .fa-rectangle-ad::before {
	content: "\F641"; }
  
  .fa-ad::before {
	content: "\F641"; }
  
  .fa-guitar-electric::before {
	content: "\F8BE"; }
  
  .fa-arrow-turn-down-right::before {
	content: "\E3D6"; }
  
  .fa-moon-cloud::before {
	content: "\F754"; }
  
  .fa-bread-slice-butter::before {
	content: "\E3E1"; }
  
  .fa-circle-arrow-right::before {
	content: "\F0A9"; }
  
  .fa-arrow-circle-right::before {
	content: "\F0A9"; }
  
  .fa-user-group-crown::before {
	content: "\F6A5"; }
  
  .fa-users-crown::before {
	content: "\F6A5"; }
  
  .fa-circle-i::before {
	content: "\E111"; }
  
  .fa-toilet-paper-check::before {
	content: "\E5B2"; }
  
  .fa-filter-circle-xmark::before {
	content: "\E17B"; }
  
  .fa-locust::before {
	content: "\E520"; }
  
  .fa-sort::before {
	content: "\F0DC"; }
  
  .fa-unsorted::before {
	content: "\F0DC"; }
  
  .fa-list-ol::before {
	content: "\F0CB"; }
  
  .fa-list-1-2::before {
	content: "\F0CB"; }
  
  .fa-list-numeric::before {
	content: "\F0CB"; }
  
  .fa-chart-waterfall::before {
	content: "\E0EB"; }
  
  .fa-face-party::before {
	content: "\E383"; }
  
  .fa-kidneys::before {
	content: "\F5FB"; }
  
  .fa-wifi-exclamation::before {
	content: "\E2CF"; }
  
  .fa-chart-network::before {
	content: "\F78A"; }
  
  .fa-person-dress-burst::before {
	content: "\E544"; }
  
  .fa-dice-d4::before {
	content: "\F6D0"; }
  
  .fa-money-check-dollar::before {
	content: "\F53D"; }
  
  .fa-money-check-alt::before {
	content: "\F53D"; }
  
  .fa-vector-square::before {
	content: "\F5CB"; }
  
  .fa-bread-slice::before {
	content: "\F7EC"; }
  
  .fa-language::before {
	content: "\F1AB"; }
  
  .fa-wheat-awn-slash::before {
	content: "\E338"; }
  
  .fa-face-kiss-wink-heart::before {
	content: "\F598"; }
  
  .fa-kiss-wink-heart::before {
	content: "\F598"; }
  
  .fa-dagger::before {
	content: "\F6CB"; }
  
  .fa-podium::before {
	content: "\F680"; }
  
  .fa-memo-circle-check::before {
	content: "\E1D9"; }
  
  .fa-route-highway::before {
	content: "\F61A"; }
  
  .fa-down-to-line::before {
	content: "\F34A"; }
  
  .fa-arrow-alt-to-bottom::before {
	content: "\F34A"; }
  
  .fa-filter::before {
	content: "\F0B0"; }
  
  .fa-square-g::before {
	content: "\E271"; }
  
  .fa-circle-phone::before {
	content: "\E11B"; }
  
  .fa-phone-circle::before {
	content: "\E11B"; }
  
  .fa-clipboard-prescription::before {
	content: "\F5E8"; }
  
  .fa-user-nurse-hair::before {
	content: "\E45D"; }
  
  .fa-question::before {
	content: "?"; }
  
  .fa-file-signature::before {
	content: "\F573"; }
  
  .fa-toggle-large-on::before {
	content: "\E5B1"; }
  
  .fa-up-down-left-right::before {
	content: "\F0B2"; }
  
  .fa-arrows-alt::before {
	content: "\F0B2"; }
  
  .fa-dryer-heat::before {
	content: "\F862"; }
  
  .fa-dryer-alt::before {
	content: "\F862"; }
  
  .fa-house-chimney-user::before {
	content: "\E065"; }
  
  .fa-hand-holding-heart::before {
	content: "\F4BE"; }
  
  .fa-arrow-up-small-big::before {
	content: "\F88F"; }
  
  .fa-sort-size-up-alt::before {
	content: "\F88F"; }
  
  .fa-train-track::before {
	content: "\E453"; }
  
  .fa-puzzle-piece::before {
	content: "\F12E"; }
  
  .fa-money-check::before {
	content: "\F53C"; }
  
  .fa-star-half-stroke::before {
	content: "\F5C0"; }
  
  .fa-star-half-alt::before {
	content: "\F5C0"; }
  
  .fa-file-exclamation::before {
	content: "\F31A"; }
  
  .fa-code::before {
	content: "\F121"; }
  
  .fa-whiskey-glass::before {
	content: "\F7A0"; }
  
  .fa-glass-whiskey::before {
	content: "\F7A0"; }
  
  .fa-moon-stars::before {
	content: "\F755"; }
  
  .fa-building-circle-exclamation::before {
	content: "\E4D3"; }
  
  .fa-clothes-hanger::before {
	content: "\E136"; }
  
  .fa-mobile-notch::before {
	content: "\E1EE"; }
  
  .fa-mobile-iphone::before {
	content: "\E1EE"; }
  
  .fa-magnifying-glass-chart::before {
	content: "\E522"; }
  
  .fa-arrow-up-right-from-square::before {
	content: "\F08E"; }
  
  .fa-external-link::before {
	content: "\F08E"; }
  
  .fa-cubes-stacked::before {
	content: "\E4E6"; }
  
  .fa-images-user::before {
	content: "\E1B9"; }
  
  .fa-won-sign::before {
	content: "\F159"; }
  
  .fa-krw::before {
	content: "\F159"; }
  
  .fa-won::before {
	content: "\F159"; }
  
  .fa-image-polaroid-user::before {
	content: "\E1B6"; }
  
  .fa-virus-covid::before {
	content: "\E4A8"; }
  
  .fa-square-ellipsis::before {
	content: "\E26E"; }
  
  .fa-pie::before {
	content: "\F705"; }
  
  .fa-chess-knight-piece::before {
	content: "\F442"; }
  
  .fa-chess-knight-alt::before {
	content: "\F442"; }
  
  .fa-austral-sign::before {
	content: "\E0A9"; }
  
  .fa-cloud-plus::before {
	content: "\E35E"; }
  
  .fa-f::before {
	content: "F"; }
  
  .fa-leaf::before {
	content: "\F06C"; }
  
  .fa-bed-bunk::before {
	content: "\F8F8"; }
  
  .fa-road::before {
	content: "\F018"; }
  
  .fa-taxi::before {
	content: "\F1BA"; }
  
  .fa-cab::before {
	content: "\F1BA"; }
  
  .fa-person-circle-plus::before {
	content: "\E541"; }
  
  .fa-chart-pie::before {
	content: "\F200"; }
  
  .fa-pie-chart::before {
	content: "\F200"; }
  
  .fa-bolt-lightning::before {
	content: "\E0B7"; }
  
  .fa-clock-eight::before {
	content: "\E345"; }
  
  .fa-sack-xmark::before {
	content: "\E56A"; }
  
  .fa-file-excel::before {
	content: "\F1C3"; }
  
  .fa-file-contract::before {
	content: "\F56C"; }
  
  .fa-fish-fins::before {
	content: "\E4F2"; }
  
  .fa-circle-q::before {
	content: "\E11E"; }
  
  .fa-building-flag::before {
	content: "\E4D5"; }
  
  .fa-face-grin-beam::before {
	content: "\F582"; }
  
  .fa-grin-beam::before {
	content: "\F582"; }
  
  .fa-object-ungroup::before {
	content: "\F248"; }
  
  .fa-face-disguise::before {
	content: "\E370"; }
  
  .fa-circle-arrow-down-right::before {
	content: "\E0FA"; }
  
  .fa-alien-8bit::before {
	content: "\F8F6"; }
  
  .fa-alien-monster::before {
	content: "\F8F6"; }
  
  .fa-hand-point-ribbon::before {
	content: "\E1A6"; }
  
  .fa-poop::before {
	content: "\F619"; }
  
  .fa-object-exclude::before {
	content: "\E49C"; }
  
  .fa-telescope::before {
	content: "\E03E"; }
  
  .fa-location-pin::before {
	content: "\F041"; }
  
  .fa-map-marker::before {
	content: "\F041"; }
  
  .fa-square-list::before {
	content: "\E489"; }
  
  .fa-kaaba::before {
	content: "\F66B"; }
  
  .fa-toilet-paper::before {
	content: "\F71E"; }
  
  .fa-helmet-safety::before {
	content: "\F807"; }
  
  .fa-hard-hat::before {
	content: "\F807"; }
  
  .fa-hat-hard::before {
	content: "\F807"; }
  
  .fa-comment-code::before {
	content: "\E147"; }
  
  .fa-sim-cards::before {
	content: "\E251"; }
  
  .fa-starship::before {
	content: "\E039"; }
  
  .fa-eject::before {
	content: "\F052"; }
  
  .fa-circle-right::before {
	content: "\F35A"; }
  
  .fa-arrow-alt-circle-right::before {
	content: "\F35A"; }
  
  .fa-plane-circle-check::before {
	content: "\E555"; }
  
  .fa-seal::before {
	content: "\E241"; }
  
  .fa-user-cowboy::before {
	content: "\F8EA"; }
  
  .fa-hexagon-vertical-nft::before {
	content: "\E505"; }
  
  .fa-face-rolling-eyes::before {
	content: "\F5A5"; }
  
  .fa-meh-rolling-eyes::before {
	content: "\F5A5"; }
  
  .fa-bread-loaf::before {
	content: "\F7EB"; }
  
  .fa-rings-wedding::before {
	content: "\F81B"; }
  
  .fa-object-group::before {
	content: "\F247"; }
  
  .fa-french-fries::before {
	content: "\F803"; }
  
  .fa-chart-line::before {
	content: "\F201"; }
  
  .fa-line-chart::before {
	content: "\F201"; }
  
  .fa-calendar-arrow-down::before {
	content: "\E0D0"; }
  
  .fa-calendar-download::before {
	content: "\E0D0"; }
  
  .fa-send-back::before {
	content: "\F87E"; }
  
  .fa-mask-ventilator::before {
	content: "\E524"; }
  
  .fa-signature-lock::before {
	content: "\E3CA"; }
  
  .fa-arrow-right::before {
	content: "\F061"; }
  
  .fa-signs-post::before {
	content: "\F277"; }
  
  .fa-map-signs::before {
	content: "\F277"; }
  
  .fa-octagon-plus::before {
	content: "\F301"; }
  
  .fa-plus-octagon::before {
	content: "\F301"; }
  
  .fa-cash-register::before {
	content: "\F788"; }
  
  .fa-person-circle-question::before {
	content: "\E542"; }
  
  .fa-melon-slice::before {
	content: "\E311"; }
  
  .fa-space-station-moon::before {
	content: "\E033"; }
  
  .fa-message-smile::before {
	content: "\F4AA"; }
  
  .fa-comment-alt-smile::before {
	content: "\F4AA"; }
  
  .fa-cup-straw::before {
	content: "\E363"; }
  
  .fa-left-from-line::before {
	content: "\F348"; }
  
  .fa-arrow-alt-from-right::before {
	content: "\F348"; }
  
  .fa-h::before {
	content: "H"; }
  
  .fa-basket-shopping-simple::before {
	content: "\E0AF"; }
  
  .fa-shopping-basket-alt::before {
	content: "\E0AF"; }
  
  .fa-hands-holding-heart::before {
	content: "\F4C3"; }
  
  .fa-hands-heart::before {
	content: "\F4C3"; }
  
  .fa-clock-nine::before {
	content: "\E34C"; }
  
  .fa-tarp::before {
	content: "\E57B"; }
  
  .fa-face-sleepy::before {
	content: "\E38E"; }
  
  .fa-hand-horns::before {
	content: "\E1A9"; }
  
  .fa-screwdriver-wrench::before {
	content: "\F7D9"; }
  
  .fa-tools::before {
	content: "\F7D9"; }
  
  .fa-arrows-to-eye::before {
	content: "\E4BF"; }
  
  .fa-circle-three-quarters::before {
	content: "\E125"; }
  
  .fa-trophy-star::before {
	content: "\F2EB"; }
  
  .fa-trophy-alt::before {
	content: "\F2EB"; }
  
  .fa-plug-circle-bolt::before {
	content: "\E55B"; }
  
  .fa-face-thermometer::before {
	content: "\E39A"; }
  
  .fa-shirt-running::before {
	content: "\E3C8"; }
  
  .fa-book-circle-arrow-up::before {
	content: "\E0BD"; }
  
  .fa-face-nauseated::before {
	content: "\E381"; }
  
  .fa-heart::before {
	content: "\F004"; }
  
  .fa-file-chart-pie::before {
	content: "\F65A"; }
  
  .fa-mars-and-venus::before {
	content: "\F224"; }
  
  .fa-house-user::before {
	content: "\E1B0"; }
  
  .fa-home-user::before {
	content: "\E1B0"; }
  
  .fa-circle-arrow-down-left::before {
	content: "\E0F9"; }
  
  .fa-dumpster-fire::before {
	content: "\F794"; }
  
  .fa-hexagon-minus::before {
	content: "\F307"; }
  
  .fa-minus-hexagon::before {
	content: "\F307"; }
  
  .fa-left-to-line::before {
	content: "\F34B"; }
  
  .fa-arrow-alt-to-left::before {
	content: "\F34B"; }
  
  .fa-house-crack::before {
	content: "\E3B1"; }
  
  .fa-paw-simple::before {
	content: "\F701"; }
  
  .fa-paw-alt::before {
	content: "\F701"; }
  
  .fa-arrow-left-long-to-line::before {
	content: "\E3D4"; }
  
  .fa-brackets-round::before {
	content: "\E0C5"; }
  
  .fa-parentheses::before {
	content: "\E0C5"; }
  
  .fa-martini-glass-citrus::before {
	content: "\F561"; }
  
  .fa-cocktail::before {
	content: "\F561"; }
  
  .fa-user-shakespeare::before {
	content: "\E2C2"; }
  
  .fa-arrow-right-to-arc::before {
	content: "\E4B2"; }
  
  .fa-face-surprise::before {
	content: "\F5C2"; }
  
  .fa-surprise::before {
	content: "\F5C2"; }
  
  .fa-bottle-water::before {
	content: "\E4C5"; }
  
  .fa-circle-pause::before {
	content: "\F28B"; }
  
  .fa-pause-circle::before {
	content: "\F28B"; }
  
  .fa-gauge-circle-plus::before {
	content: "\E498"; }
  
  .fa-folders::before {
	content: "\F660"; }
  
  .fa-angel::before {
	content: "\F779"; }
  
  .fa-value-absolute::before {
	content: "\F6A6"; }
  
  .fa-rabbit::before {
	content: "\F708"; }
  
  .fa-toilet-paper-slash::before {
	content: "\E072"; }
  
  .fa-apple-whole::before {
	content: "\F5D1"; }
  
  .fa-apple-alt::before {
	content: "\F5D1"; }
  
  .fa-kitchen-set::before {
	content: "\E51A"; }
  
  .fa-lock-keyhole::before {
	content: "\F30D"; }
  
  .fa-lock-alt::before {
	content: "\F30D"; }
  
  .fa-r::before {
	content: "R"; }
  
  .fa-temperature-quarter::before {
	content: "\F2CA"; }
  
  .fa-temperature-1::before {
	content: "\F2CA"; }
  
  .fa-thermometer-1::before {
	content: "\F2CA"; }
  
  .fa-thermometer-quarter::before {
	content: "\F2CA"; }
  
  .fa-square-info::before {
	content: "\F30F"; }
  
  .fa-info-square::before {
	content: "\F30F"; }
  
  .fa-wifi-slash::before {
	content: "\F6AC"; }
  
  .fa-toilet-paper-xmark::before {
	content: "\E5B3"; }
  
  .fa-hands-holding-dollar::before {
	content: "\F4C5"; }
  
  .fa-hands-usd::before {
	content: "\F4C5"; }
  
  .fa-cube::before {
	content: "\F1B2"; }
  
  .fa-arrow-down-triangle-square::before {
	content: "\F888"; }
  
  .fa-sort-shapes-down::before {
	content: "\F888"; }
  
  .fa-bitcoin-sign::before {
	content: "\E0B4"; }
  
  .fa-shutters::before {
	content: "\E449"; }
  
  .fa-shield-dog::before {
	content: "\E573"; }
  
  .fa-solar-panel::before {
	content: "\F5BA"; }
  
  .fa-lock-open::before {
	content: "\F3C1"; }
  
  .fa-table-tree::before {
	content: "\E293"; }
  
  .fa-house-chimney-heart::before {
	content: "\E1B2"; }
  
  .fa-tally-3::before {
	content: "\E296"; }
  
  .fa-elevator::before {
	content: "\E16D"; }
  
  .fa-money-bill-transfer::before {
	content: "\E528"; }
  
  .fa-money-bill-trend-up::before {
	content: "\E529"; }
  
  .fa-house-flood-water-circle-arrow-right::before {
	content: "\E50F"; }
  
  .fa-square-poll-horizontal::before {
	content: "\F682"; }
  
  .fa-poll-h::before {
	content: "\F682"; }
  
  .fa-circle::before {
	content: "\F111"; }
  
  .fa-cart-circle-exclamation::before {
	content: "\E3F2"; }
  
  .fa-sword::before {
	content: "\F71C"; }
  
  .fa-backward-fast::before {
	content: "\F049"; }
  
  .fa-fast-backward::before {
	content: "\F049"; }
  
  .fa-recycle::before {
	content: "\F1B8"; }
  
  .fa-user-astronaut::before {
	content: "\F4FB"; }
  
  .fa-plane-slash::before {
	content: "\E069"; }
  
  .fa-circle-dashed::before {
	content: "\E105"; }
  
  .fa-trademark::before {
	content: "\F25C"; }
  
  .fa-basketball::before {
	content: "\F434"; }
  
  .fa-basketball-ball::before {
	content: "\F434"; }
  
  .fa-fork-knife::before {
	content: "\F2E6"; }
  
  .fa-utensils-alt::before {
	content: "\F2E6"; }
  
  .fa-satellite-dish::before {
	content: "\F7C0"; }
  
  .fa-badge-check::before {
	content: "\F336"; }
  
  .fa-circle-up::before {
	content: "\F35B"; }
  
  .fa-arrow-alt-circle-up::before {
	content: "\F35B"; }
  
  .fa-slider::before {
	content: "\E252"; }
  
  .fa-mobile-screen-button::before {
	content: "\F3CD"; }
  
  .fa-mobile-alt::before {
	content: "\F3CD"; }
  
  .fa-clock-one-thirty::before {
	content: "\E34F"; }
  
  .fa-inbox-out::before {
	content: "\F311"; }
  
  .fa-inbox-arrow-up::before {
	content: "\F311"; }
  
  .fa-cloud-slash::before {
	content: "\E137"; }
  
  .fa-volume-high::before {
	content: "\F028"; }
  
  .fa-volume-up::before {
	content: "\F028"; }
  
  .fa-users-rays::before {
	content: "\E593"; }
  
  .fa-wallet::before {
	content: "\F555"; }
  
  .fa-octagon-check::before {
	content: "\E426"; }
  
  .fa-flatbread-stuffed::before {
	content: "\E40C"; }
  
  .fa-clipboard-check::before {
	content: "\F46C"; }
  
  .fa-cart-circle-plus::before {
	content: "\E3F3"; }
  
  .fa-truck-clock::before {
	content: "\F48C"; }
  
  .fa-shipping-timed::before {
	content: "\F48C"; }
  
  .fa-pool-8-ball::before {
	content: "\E3C5"; }
  
  .fa-file-audio::before {
	content: "\F1C7"; }
  
  .fa-turn-down-left::before {
	content: "\E331"; }
  
  .fa-lock-hashtag::before {
	content: "\E423"; }
  
  .fa-chart-radar::before {
	content: "\E0E7"; }
  
  .fa-staff::before {
	content: "\F71B"; }
  
  .fa-burger::before {
	content: "\F805"; }
  
  .fa-hamburger::before {
	content: "\F805"; }
  
  .fa-utility-pole::before {
	content: "\E2C3"; }
  
  .fa-transporter-6::before {
	content: "\E2A7"; }
  
  .fa-wrench::before {
	content: "\F0AD"; }
  
  .fa-bugs::before {
	content: "\E4D0"; }
  
  .fa-vector-polygon::before {
	content: "\E2C7"; }
  
  .fa-diagram-nested::before {
	content: "\E157"; }
  
  .fa-rupee-sign::before {
	content: "\F156"; }
  
  .fa-rupee::before {
	content: "\F156"; }
  
  .fa-file-image::before {
	content: "\F1C5"; }
  
  .fa-circle-question::before {
	content: "\F059"; }
  
  .fa-question-circle::before {
	content: "\F059"; }
  
  .fa-image-user::before {
	content: "\E1B8"; }
  
  .fa-plane-departure::before {
	content: "\F5B0"; }
  
  .fa-handshake-slash::before {
	content: "\E060"; }
  
  .fa-book-bookmark::before {
	content: "\E0BB"; }
  
  .fa-border-center-h::before {
	content: "\F89C"; }
  
  .fa-can-food::before {
	content: "\E3E6"; }
  
  .fa-typewriter::before {
	content: "\F8E7"; }
  
  .fa-arrow-right-from-arc::before {
	content: "\E4B1"; }
  
  .fa-circle-k::before {
	content: "\E113"; }
  
  .fa-face-hand-over-mouth::before {
	content: "\E378"; }
  
  .fa-popcorn::before {
	content: "\F819"; }
  
  .fa-house-water::before {
	content: "\F74F"; }
  
  .fa-house-flood::before {
	content: "\F74F"; }
  
  .fa-object-subtract::before {
	content: "\E49E"; }
  
  .fa-code-branch::before {
	content: "\F126"; }
  
  .fa-warehouse-full::before {
	content: "\F495"; }
  
  .fa-warehouse-alt::before {
	content: "\F495"; }
  
  .fa-hat-cowboy::before {
	content: "\F8C0"; }
  
  .fa-bridge::before {
	content: "\E4C8"; }
  
  .fa-phone-flip::before {
	content: "\F879"; }
  
  .fa-phone-alt::before {
	content: "\F879"; }
  
  .fa-arrow-down-from-dotted-line::before {
	content: "\E090"; }
  
  .fa-square-quarters::before {
	content: "\E44E"; }
  
  .fa-truck-front::before {
	content: "\E2B7"; }
  
  .fa-cat::before {
	content: "\F6BE"; }
  
  .fa-trash-xmark::before {
	content: "\E2B4"; }
  
  .fa-circle-caret-left::before {
	content: "\F32E"; }
  
  .fa-caret-circle-left::before {
	content: "\F32E"; }
  
  .fa-files::before {
	content: "\E178"; }
  
  .fa-anchor-circle-exclamation::before {
	content: "\E4AB"; }
  
  .fa-face-clouds::before {
	content: "\E47D"; }
  
  .fa-user-crown::before {
	content: "\F6A4"; }
  
  .fa-truck-field::before {
	content: "\E58D"; }
  
  .fa-route::before {
	content: "\F4D7"; }
  
  .fa-cart-circle-check::before {
	content: "\E3F1"; }
  
  .fa-clipboard-question::before {
	content: "\E4E3"; }
  
  .fa-panorama::before {
	content: "\E209"; }
  
  .fa-comment-medical::before {
	content: "\F7F5"; }
  
  .fa-teeth-open::before {
	content: "\F62F"; }
  
  .fa-user-tie-hair-long::before {
	content: "\E460"; }
  
  .fa-file-circle-minus::before {
	content: "\E4ED"; }
  
  .fa-head-side-medical::before {
	content: "\F809"; }
  
  .fa-tags::before {
	content: "\F02C"; }
  
  .fa-wine-glass::before {
	content: "\F4E3"; }
  
  .fa-forward-fast::before {
	content: "\F050"; }
  
  .fa-fast-forward::before {
	content: "\F050"; }
  
  .fa-face-meh-blank::before {
	content: "\F5A4"; }
  
  .fa-meh-blank::before {
	content: "\F5A4"; }
  
  .fa-user-robot::before {
	content: "\E04B"; }
  
  .fa-square-parking::before {
	content: "\F540"; }
  
  .fa-parking::before {
	content: "\F540"; }
  
  .fa-card-diamond::before {
	content: "\E3EA"; }
  
  .fa-face-zipper::before {
	content: "\E3A5"; }
  
  .fa-face-raised-eyebrow::before {
	content: "\E388"; }
  
  .fa-house-signal::before {
	content: "\E012"; }
  
  .fa-square-chevron-up::before {
	content: "\F32C"; }
  
  .fa-chevron-square-up::before {
	content: "\F32C"; }
  
  .fa-bars-progress::before {
	content: "\F828"; }
  
  .fa-tasks-alt::before {
	content: "\F828"; }
  
  .fa-faucet-drip::before {
	content: "\E006"; }
  
  .fa-arrows-to-line::before {
	content: "\E0A7"; }
  
  .fa-dolphin::before {
	content: "\E168"; }
  
  .fa-arrow-up-right::before {
	content: "\E09F"; }
  
  .fa-circle-r::before {
	content: "\E120"; }
  
  .fa-cart-flatbed::before {
	content: "\F474"; }
  
  .fa-dolly-flatbed::before {
	content: "\F474"; }
  
  .fa-ban-smoking::before {
	content: "\F54D"; }
  
  .fa-smoking-ban::before {
	content: "\F54D"; }
  
  .fa-circle-sort-up::before {
	content: "\E032"; }
  
  .fa-sort-circle-up::before {
	content: "\E032"; }
  
  .fa-terminal::before {
	content: "\F120"; }
  
  .fa-mobile-button::before {
	content: "\F10B"; }
  
  .fa-house-medical-flag::before {
	content: "\E514"; }
  
  .fa-basket-shopping::before {
	content: "\F291"; }
  
  .fa-shopping-basket::before {
	content: "\F291"; }
  
  .fa-tape::before {
	content: "\F4DB"; }
  
  .fa-chestnut::before {
	content: "\E3F6"; }
  
  .fa-bus-simple::before {
	content: "\F55E"; }
  
  .fa-bus-alt::before {
	content: "\F55E"; }
  
  .fa-eye::before {
	content: "\F06E"; }
  
  .fa-face-sad-cry::before {
	content: "\F5B3"; }
  
  .fa-sad-cry::before {
	content: "\F5B3"; }
  
  .fa-heat::before {
	content: "\E00C"; }
  
  .fa-ticket-airline::before {
	content: "\E29A"; }
  
  .fa-boot-heeled::before {
	content: "\E33F"; }
  
  .fa-arrows-minimize::before {
	content: "\E0A5"; }
  
  .fa-compress-arrows::before {
	content: "\E0A5"; }
  
  .fa-audio-description::before {
	content: "\F29E"; }
  
  .fa-person-military-to-person::before {
	content: "\E54C"; }
  
  .fa-file-shield::before {
	content: "\E4F0"; }
  
  .fa-hexagon::before {
	content: "\F312"; }
  
  .fa-manhole::before {
	content: "\E1D6"; }
  
  .fa-user-slash::before {
	content: "\F506"; }
  
  .fa-pen::before {
	content: "\F304"; }
  
  .fa-tower-observation::before {
	content: "\E586"; }
  
  .fa-floppy-disks::before {
	content: "\E183"; }
  
  .fa-toilet-paper-blank-under::before {
	content: "\E29F"; }
  
  .fa-toilet-paper-reverse-alt::before {
	content: "\E29F"; }
  
  .fa-file-code::before {
	content: "\F1C9"; }
  
  .fa-signal::before {
	content: "\F012"; }
  
  .fa-signal-5::before {
	content: "\F012"; }
  
  .fa-signal-perfect::before {
	content: "\F012"; }
  
  .fa-pump::before {
	content: "\E442"; }
  
  .fa-bus::before {
	content: "\F207"; }
  
  .fa-heart-circle-xmark::before {
	content: "\E501"; }
  
  .fa-arrow-up-left-from-circle::before {
	content: "\E09E"; }
  
  .fa-house-chimney::before {
	content: "\E3AF"; }
  
  .fa-home-lg::before {
	content: "\E3AF"; }
  
  .fa-window-maximize::before {
	content: "\F2D0"; }
  
  .fa-dryer::before {
	content: "\F861"; }
  
  .fa-face-frown::before {
	content: "\F119"; }
  
  .fa-frown::before {
	content: "\F119"; }
  
  .fa-chess-bishop-piece::before {
	content: "\F43B"; }
  
  .fa-chess-bishop-alt::before {
	content: "\F43B"; }
  
  .fa-shirt-tank-top::before {
	content: "\E3C9"; }
  
  .fa-diploma::before {
	content: "\F5EA"; }
  
  .fa-scroll-ribbon::before {
	content: "\F5EA"; }
  
  .fa-screencast::before {
	content: "\E23E"; }
  
  .fa-walker::before {
	content: "\F831"; }
  
  .fa-prescription::before {
	content: "\F5B1"; }
  
  .fa-shop::before {
	content: "\F54F"; }
  
  .fa-store-alt::before {
	content: "\F54F"; }
  
  .fa-floppy-disk::before {
	content: "\F0C7"; }
  
  .fa-save::before {
	content: "\F0C7"; }
  
  .fa-vihara::before {
	content: "\F6A7"; }
  
  .fa-face-kiss-closed-eyes::before {
	content: "\E37D"; }
  
  .fa-scale-unbalanced::before {
	content: "\F515"; }
  
  .fa-balance-scale-left::before {
	content: "\F515"; }
  
  .fa-file-user::before {
	content: "\F65C"; }
  
  .fa-user-police-tie::before {
	content: "\E334"; }
  
  .fa-face-tongue-money::before {
	content: "\E39D"; }
  
  .fa-tennis-ball::before {
	content: "\F45E"; }
  
  .fa-square-l::before {
	content: "\E275"; }
  
  .fa-sort-up::before {
	content: "\F0DE"; }
  
  .fa-sort-asc::before {
	content: "\F0DE"; }
  
  .fa-calendar-arrow-up::before {
	content: "\E0D1"; }
  
  .fa-calendar-upload::before {
	content: "\E0D1"; }
  
  .fa-comment-dots::before {
	content: "\F4AD"; }
  
  .fa-commenting::before {
	content: "\F4AD"; }
  
  .fa-plant-wilt::before {
	content: "\E5AA"; }
  
  .fa-scarf::before {
	content: "\F7C1"; }
  
  .fa-album-circle-plus::before {
	content: "\E48C"; }
  
  .fa-user-nurse-hair-long::before {
	content: "\E45E"; }
  
  .fa-diamond::before {
	content: "\F219"; }
  
  .fa-square-left::before {
	content: "\F351"; }
  
  .fa-arrow-alt-square-left::before {
	content: "\F351"; }
  
  .fa-face-grin-squint::before {
	content: "\F585"; }
  
  .fa-grin-squint::before {
	content: "\F585"; }
  
  .fa-circle-ellipsis-vertical::before {
	content: "\E10B"; }
  
  .fa-hand-holding-dollar::before {
	content: "\F4C0"; }
  
  .fa-hand-holding-usd::before {
	content: "\F4C0"; }
  
  .fa-grid-dividers::before {
	content: "\E3AD"; }
  
  .fa-bacterium::before {
	content: "\E05A"; }
  
  .fa-hand-pointer::before {
	content: "\F25A"; }
  
  .fa-drum-steelpan::before {
	content: "\F56A"; }
  
  .fa-hand-scissors::before {
	content: "\F257"; }
  
  .fa-hands-praying::before {
	content: "\F684"; }
  
  .fa-praying-hands::before {
	content: "\F684"; }
  
  .fa-face-pensive::before {
	content: "\E384"; }
  
  .fa-user-music::before {
	content: "\F8EB"; }
  
  .fa-arrow-rotate-right::before {
	content: "\F01E"; }
  
  .fa-arrow-right-rotate::before {
	content: "\F01E"; }
  
  .fa-arrow-rotate-forward::before {
	content: "\F01E"; }
  
  .fa-redo::before {
	content: "\F01E"; }
  
  .fa-messages-dollar::before {
	content: "\F652"; }
  
  .fa-comments-alt-dollar::before {
	content: "\F652"; }
  
  .fa-sensor-on::before {
	content: "\E02B"; }
  
  .fa-balloon::before {
	content: "\E2E3"; }
  
  .fa-biohazard::before {
	content: "\F780"; }
  
  .fa-chess-queen-piece::before {
	content: "\F446"; }
  
  .fa-chess-queen-alt::before {
	content: "\F446"; }
  
  .fa-location-crosshairs::before {
	content: "\F601"; }
  
  .fa-location::before {
	content: "\F601"; }
  
  .fa-mars-double::before {
	content: "\F227"; }
  
  .fa-house-person-leave::before {
	content: "\E00F"; }
  
  .fa-house-leave::before {
	content: "\E00F"; }
  
  .fa-house-person-depart::before {
	content: "\E00F"; }
  
  .fa-ruler-triangle::before {
	content: "\F61C"; }
  
  .fa-card-club::before {
	content: "\E3E9"; }
  
  .fa-child-dress::before {
	content: "\E59C"; }
  
  .fa-users-between-lines::before {
	content: "\E591"; }
  
  .fa-lungs-virus::before {
	content: "\E067"; }
  
  .fa-spinner-third::before {
	content: "\F3F4"; }
  
  .fa-face-grin-tears::before {
	content: "\F588"; }
  
  .fa-grin-tears::before {
	content: "\F588"; }
  
  .fa-phone::before {
	content: "\F095"; }
  
  .fa-computer-mouse-scrollwheel::before {
	content: "\F8CD"; }
  
  .fa-mouse-alt::before {
	content: "\F8CD"; }
  
  .fa-calendar-xmark::before {
	content: "\F273"; }
  
  .fa-calendar-times::before {
	content: "\F273"; }
  
  .fa-child-reaching::before {
	content: "\E59D"; }
  
  .fa-table-layout::before {
	content: "\E290"; }
  
  .fa-narwhal::before {
	content: "\F6FE"; }
  
  .fa-ramp-loading::before {
	content: "\F4D4"; }
  
  .fa-calendar-circle-plus::before {
	content: "\E470"; }
  
  .fa-toothbrush::before {
	content: "\F635"; }
  
  .fa-border-inner::before {
	content: "\F84E"; }
  
  .fa-paw-claws::before {
	content: "\F702"; }
  
  .fa-kiwi-fruit::before {
	content: "\E30C"; }
  
  .fa-traffic-light-slow::before {
	content: "\F639"; }
  
  .fa-rectangle-code::before {
	content: "\E322"; }
  
  .fa-head-side-virus::before {
	content: "\E064"; }
  
  .fa-keyboard-brightness::before {
	content: "\E1C0"; }
  
  .fa-books-medical::before {
	content: "\F7E8"; }
  
  .fa-lightbulb-slash::before {
	content: "\F673"; }
  
  .fa-house-blank::before {
	content: "\E487"; }
  
  .fa-home-blank::before {
	content: "\E487"; }
  
  .fa-square-5::before {
	content: "\E25A"; }
  
  .fa-square-heart::before {
	content: "\F4C8"; }
  
  .fa-heart-square::before {
	content: "\F4C8"; }
  
  .fa-puzzle::before {
	content: "\E443"; }
  
  .fa-user-gear::before {
	content: "\F4FE"; }
  
  .fa-user-cog::before {
	content: "\F4FE"; }
  
  .fa-pipe-circle-check::before {
	content: "\E436"; }
  
  .fa-arrow-up-1-9::before {
	content: "\F163"; }
  
  .fa-sort-numeric-up::before {
	content: "\F163"; }
  
  .fa-octagon-exclamation::before {
	content: "\E204"; }
  
  .fa-dial-low::before {
	content: "\E15D"; }
  
  .fa-door-closed::before {
	content: "\F52A"; }
  
  .fa-laptop-mobile::before {
	content: "\F87A"; }
  
  .fa-phone-laptop::before {
	content: "\F87A"; }
  
  .fa-conveyor-belt-boxes::before {
	content: "\F46F"; }
  
  .fa-conveyor-belt-alt::before {
	content: "\F46F"; }
  
  .fa-shield-virus::before {
	content: "\E06C"; }
  
  .fa-starfighter-twin-ion-engine-advanced::before {
	content: "\E28E"; }
  
  .fa-starfighter-alt-advanced::before {
	content: "\E28E"; }
  
  .fa-dice-six::before {
	content: "\F526"; }
  
  .fa-starfighter-twin-ion-engine::before {
	content: "\E038"; }
  
  .fa-starfighter-alt::before {
	content: "\E038"; }
  
  .fa-rocket-launch::before {
	content: "\E027"; }
  
  .fa-mosquito-net::before {
	content: "\E52C"; }
  
  .fa-vent-damper::before {
	content: "\E465"; }
  
  .fa-bridge-water::before {
	content: "\E4CE"; }
  
  .fa-ban-bug::before {
	content: "\F7F9"; }
  
  .fa-debug::before {
	content: "\F7F9"; }
  
  .fa-person-booth::before {
	content: "\F756"; }
  
  .fa-text-width::before {
	content: "\F035"; }
  
  .fa-garage-car::before {
	content: "\E00A"; }
  
  .fa-square-kanban::before {
	content: "\E488"; }
  
  .fa-hat-wizard::before {
	content: "\F6E8"; }
  
  .fa-pen-fancy::before {
	content: "\F5AC"; }
  
  .fa-coffee-pot::before {
	content: "\E002"; }
  
  .fa-mouse-field::before {
	content: "\E5A8"; }
  
  .fa-person-digging::before {
	content: "\F85E"; }
  
  .fa-digging::before {
	content: "\F85E"; }
  
  .fa-shower-down::before {
	content: "\E24D"; }
  
  .fa-shower-alt::before {
	content: "\E24D"; }
  
  .fa-box-circle-check::before {
	content: "\E0C4"; }
  
  .fa-brightness::before {
	content: "\E0C9"; }
  
  .fa-car-side-bolt::before {
	content: "\E344"; }
  
  .fa-ornament::before {
	content: "\F7B8"; }
  
  .fa-phone-arrow-down-left::before {
	content: "\E223"; }
  
  .fa-phone-arrow-down::before {
	content: "\E223"; }
  
  .fa-phone-incoming::before {
	content: "\E223"; }
  
  .fa-cloud-word::before {
	content: "\E138"; }
  
  .fa-hand-fingers-crossed::before {
	content: "\E1A3"; }
  
  .fa-trash::before {
	content: "\F1F8"; }
  
  .fa-gauge-simple::before {
	content: "\F629"; }
  
  .fa-gauge-simple-med::before {
	content: "\F629"; }
  
  .fa-tachometer-average::before {
	content: "\F629"; }
  
  .fa-arrow-down-small-big::before {
	content: "\F88D"; }
  
  .fa-sort-size-down-alt::before {
	content: "\F88D"; }
  
  .fa-book-medical::before {
	content: "\F7E6"; }
  
  .fa-face-melting::before {
	content: "\E483"; }
  
  .fa-poo::before {
	content: "\F2FE"; }
  
  .fa-pen-clip-slash::before {
	content: "\E20F"; }
  
  .fa-pen-alt-slash::before {
	content: "\E20F"; }
  
  .fa-quote-right::before {
	content: "\F10E"; }
  
  .fa-quote-right-alt::before {
	content: "\F10E"; }
  
  .fa-scroll-old::before {
	content: "\F70F"; }
  
  .fa-guitars::before {
	content: "\F8BF"; }
  
  .fa-phone-xmark::before {
	content: "\E227"; }
  
  .fa-hose::before {
	content: "\E419"; }
  
  .fa-clock-six::before {
	content: "\E352"; }
  
  .fa-shirt::before {
	content: "\F553"; }
  
  .fa-t-shirt::before {
	content: "\F553"; }
  
  .fa-tshirt::before {
	content: "\F553"; }
  
  .fa-square-r::before {
	content: "\E27C"; }
  
  .fa-cubes::before {
	content: "\F1B3"; }
  
  .fa-envelope-open-dollar::before {
	content: "\F657"; }
  
  .fa-divide::before {
	content: "\F529"; }
  
  .fa-sun-cloud::before {
	content: "\F763"; }
  
  .fa-lamp-floor::before {
	content: "\E015"; }
  
  .fa-square-7::before {
	content: "\E25C"; }
  
  .fa-tenge-sign::before {
	content: "\F7D7"; }
  
  .fa-tenge::before {
	content: "\F7D7"; }
  
  .fa-headphones::before {
	content: "\F025"; }
  
  .fa-hands-holding::before {
	content: "\F4C2"; }
  
  .fa-campfire::before {
	content: "\F6BA"; }
  
  .fa-circle-ampersand::before {
	content: "\E0F8"; }
  
  .fa-snowflakes::before {
	content: "\F7CF"; }
  
  .fa-hands-clapping::before {
	content: "\E1A8"; }
  
  .fa-republican::before {
	content: "\F75E"; }
  
  .fa-leaf-maple::before {
	content: "\F6F6"; }
  
  .fa-arrow-left::before {
	content: "\F060"; }
  
  .fa-person-circle-xmark::before {
	content: "\E543"; }
  
  .fa-ruler::before {
	content: "\F545"; }
  
  .fa-cup-straw-swoosh::before {
	content: "\E364"; }
  
  .fa-temperature-sun::before {
	content: "\F76A"; }
  
  .fa-temperature-hot::before {
	content: "\F76A"; }
  
  .fa-align-left::before {
	content: "\F036"; }
  
  .fa-dice-d6::before {
	content: "\F6D1"; }
  
  .fa-restroom::before {
	content: "\F7BD"; }
  
  .fa-high-definition::before {
	content: "\E1AE"; }
  
  .fa-rectangle-hd::before {
	content: "\E1AE"; }
  
  .fa-j::before {
	content: "J"; }
  
  .fa-galaxy::before {
	content: "\E008"; }
  
  .fa-users-viewfinder::before {
	content: "\E595"; }
  
  .fa-file-video::before {
	content: "\F1C8"; }
  
  .fa-cherries::before {
	content: "\E0EC"; }
  
  .fa-up-right-from-square::before {
	content: "\F35D"; }
  
  .fa-external-link-alt::before {
	content: "\F35D"; }
  
  .fa-circle-sort::before {
	content: "\E030"; }
  
  .fa-sort-circle::before {
	content: "\E030"; }
  
  .fa-table-cells::before {
	content: "\F00A"; }
  
  .fa-th::before {
	content: "\F00A"; }
  
  .fa-file-pdf::before {
	content: "\F1C1"; }
  
  .fa-siren::before {
	content: "\E02D"; }
  
  .fa-arrow-up-to-dotted-line::before {
	content: "\E0A1"; }
  
  .fa-image-landscape::before {
	content: "\E1B5"; }
  
  .fa-landscape::before {
	content: "\E1B5"; }
  
  .fa-tank-water::before {
	content: "\E452"; }
  
  .fa-curling-stone::before {
	content: "\F44A"; }
  
  .fa-curling::before {
	content: "\F44A"; }
  
  .fa-gamepad-modern::before {
	content: "\E5A2"; }
  
  .fa-gamepad-alt::before {
	content: "\E5A2"; }
  
  .fa-messages-question::before {
	content: "\E1E7"; }
  
  .fa-book-bible::before {
	content: "\F647"; }
  
  .fa-bible::before {
	content: "\F647"; }
  
  .fa-o::before {
	content: "O"; }
  
  .fa-suitcase-medical::before {
	content: "\F0FA"; }
  
  .fa-medkit::before {
	content: "\F0FA"; }
  
  .fa-briefcase-arrow-right::before {
	content: "\E2F2"; }
  
  .fa-expand-wide::before {
	content: "\F320"; }
  
  .fa-clock-eleven-thirty::before {
	content: "\E348"; }
  
  .fa-rv::before {
	content: "\F7BE"; }
  
  .fa-user-secret::before {
	content: "\F21B"; }
  
  .fa-otter::before {
	content: "\F700"; }
  
  .fa-dreidel::before {
	content: "\F792"; }
  
  .fa-person-dress::before {
	content: "\F182"; }
  
  .fa-female::before {
	content: "\F182"; }
  
  .fa-comment-dollar::before {
	content: "\F651"; }
  
  .fa-business-time::before {
	content: "\F64A"; }
  
  .fa-briefcase-clock::before {
	content: "\F64A"; }
  
  .fa-flower-tulip::before {
	content: "\F801"; }
  
  .fa-people-pants-simple::before {
	content: "\E21A"; }
  
  .fa-cloud-drizzle::before {
	content: "\F738"; }
  
  .fa-table-cells-large::before {
	content: "\F009"; }
  
  .fa-th-large::before {
	content: "\F009"; }
  
  .fa-book-tanakh::before {
	content: "\F827"; }
  
  .fa-tanakh::before {
	content: "\F827"; }
  
  .fa-solar-system::before {
	content: "\E02F"; }
  
  .fa-seal-question::before {
	content: "\E243"; }
  
  .fa-phone-volume::before {
	content: "\F2A0"; }
  
  .fa-volume-control-phone::before {
	content: "\F2A0"; }
  
  .fa-disc-drive::before {
	content: "\F8B5"; }
  
  .fa-hat-cowboy-side::before {
	content: "\F8C1"; }
  
  .fa-table-rows::before {
	content: "\E292"; }
  
  .fa-rows::before {
	content: "\E292"; }
  
  .fa-location-exclamation::before {
	content: "\F608"; }
  
  .fa-map-marker-exclamation::before {
	content: "\F608"; }
  
  .fa-face-fearful::before {
	content: "\E375"; }
  
  .fa-clipboard-user::before {
	content: "\F7F3"; }
  
  .fa-bus-school::before {
	content: "\F5DD"; }
  
  .fa-film-slash::before {
	content: "\E179"; }
  
  .fa-square-arrow-down-right::before {
	content: "\E262"; }
  
  .fa-book-sparkles::before {
	content: "\F6B8"; }
  
  .fa-book-spells::before {
	content: "\F6B8"; }
  
  .fa-washing-machine::before {
	content: "\F898"; }
  
  .fa-washer::before {
	content: "\F898"; }
  
  .fa-child::before {
	content: "\F1AE"; }
  
  .fa-lira-sign::before {
	content: "\F195"; }
  
  .fa-user-visor::before {
	content: "\E04C"; }
  
  .fa-file-plus-minus::before {
	content: "\E177"; }
  
  .fa-chess-clock-flip::before {
	content: "\F43E"; }
  
  .fa-chess-clock-alt::before {
	content: "\F43E"; }
  
  .fa-satellite::before {
	content: "\F7BF"; }
  
  .fa-plane-lock::before {
	content: "\E558"; }
  
  .fa-steering-wheel::before {
	content: "\F622"; }
  
  .fa-tag::before {
	content: "\F02B"; }
  
  .fa-stretcher::before {
	content: "\F825"; }
  
  .fa-book-section::before {
	content: "\E0C1"; }
  
  .fa-book-law::before {
	content: "\E0C1"; }
  
  .fa-inboxes::before {
	content: "\E1BB"; }
  
  .fa-coffee-bean::before {
	content: "\E13E"; }
  
  .fa-brackets-curly::before {
	content: "\F7EA"; }
  
  .fa-ellipsis-stroke-vertical::before {
	content: "\F39C"; }
  
  .fa-ellipsis-v-alt::before {
	content: "\F39C"; }
  
  .fa-comment::before {
	content: "\F075"; }
  
  .fa-square-1::before {
	content: "\E256"; }
  
  .fa-cake-candles::before {
	content: "\F1FD"; }
  
  .fa-birthday-cake::before {
	content: "\F1FD"; }
  
  .fa-cake::before {
	content: "\F1FD"; }
  
  .fa-head-side::before {
	content: "\F6E9"; }
  
  .fa-envelope::before {
	content: "\F0E0"; }
  
  .fa-dolly-empty::before {
	content: "\F473"; }
  
  .fa-face-tissue::before {
	content: "\E39C"; }
  
  .fa-angles-up::before {
	content: "\F102"; }
  
  .fa-angle-double-up::before {
	content: "\F102"; }
  
  .fa-paperclip::before {
	content: "\F0C6"; }
  
  .fa-chart-line-down::before {
	content: "\F64D"; }
  
  .fa-arrow-right-to-city::before {
	content: "\E4B3"; }
  
  .fa-lock-a::before {
	content: "\E422"; }
  
  .fa-ribbon::before {
	content: "\F4D6"; }
  
  .fa-lungs::before {
	content: "\F604"; }
  
  .fa-person-pinball::before {
	content: "\E21D"; }
  
  .fa-arrow-up-9-1::before {
	content: "\F887"; }
  
  .fa-sort-numeric-up-alt::before {
	content: "\F887"; }
  
  .fa-apple-core::before {
	content: "\E08F"; }
  
  .fa-circle-y::before {
	content: "\E12F"; }
  
  .fa-h6::before {
	content: "\E413"; }
  
  .fa-litecoin-sign::before {
	content: "\E1D3"; }
  
  .fa-circle-small::before {
	content: "\E122"; }
  
  .fa-border-none::before {
	content: "\F850"; }
  
  .fa-arrow-turn-down-left::before {
	content: "\E2E1"; }
  
  .fa-circle-nodes::before {
	content: "\E4E2"; }
  
  .fa-parachute-box::before {
	content: "\F4CD"; }
  
  .fa-message-medical::before {
	content: "\F7F4"; }
  
  .fa-comment-alt-medical::before {
	content: "\F7F4"; }
  
  .fa-rugby-ball::before {
	content: "\E3C6"; }
  
  .fa-comment-music::before {
	content: "\F8B0"; }
  
  .fa-indent::before {
	content: "\F03C"; }
  
  .fa-tree-deciduous::before {
	content: "\F400"; }
  
  .fa-tree-alt::before {
	content: "\F400"; }
  
  .fa-puzzle-piece-simple::before {
	content: "\E231"; }
  
  .fa-puzzle-piece-alt::before {
	content: "\E231"; }
  
  .fa-truck-field-un::before {
	content: "\E58E"; }
  
  .fa-nfc-trash::before {
	content: "\E1FD"; }
  
  .fa-hourglass::before {
	content: "\F254"; }
  
  .fa-hourglass-empty::before {
	content: "\F254"; }
  
  .fa-mountain::before {
	content: "\F6FC"; }
  
  .fa-file-xmark::before {
	content: "\F317"; }
  
  .fa-file-times::before {
	content: "\F317"; }
  
  .fa-house-heart::before {
	content: "\F4C9"; }
  
  .fa-home-heart::before {
	content: "\F4C9"; }
  
  .fa-house-chimney-blank::before {
	content: "\E3B0"; }
  
  .fa-meter-bolt::before {
	content: "\E1E9"; }
  
  .fa-user-doctor::before {
	content: "\F0F0"; }
  
  .fa-user-md::before {
	content: "\F0F0"; }
  
  .fa-slash-back::before {
	content: "\\"; }
  
  .fa-circle-info::before {
	content: "\F05A"; }
  
  .fa-info-circle::before {
	content: "\F05A"; }
  
  .fa-fishing-rod::before {
	content: "\E3A8"; }
  
  .fa-hammer-crash::before {
	content: "\E414"; }
  
  .fa-cloud-meatball::before {
	content: "\F73B"; }
  
  .fa-camera-polaroid::before {
	content: "\F8AA"; }
  
  .fa-camera::before {
	content: "\F030"; }
  
  .fa-camera-alt::before {
	content: "\F030"; }
  
  .fa-square-virus::before {
	content: "\E578"; }
  
  .fa-cart-arrow-up::before {
	content: "\E3EE"; }
  
  .fa-meteor::before {
	content: "\F753"; }
  
  .fa-car-on::before {
	content: "\E4DD"; }
  
  .fa-sleigh::before {
	content: "\F7CC"; }
  
  .fa-arrow-down-1-9::before {
	content: "\F162"; }
  
  .fa-sort-numeric-asc::before {
	content: "\F162"; }
  
  .fa-sort-numeric-down::before {
	content: "\F162"; }
  
  .fa-square-4::before {
	content: "\E259"; }
  
  .fa-hand-holding-droplet::before {
	content: "\F4C1"; }
  
  .fa-hand-holding-water::before {
	content: "\F4C1"; }
  
  .fa-waveform::before {
	content: "\F8F1"; }
  
  .fa-water::before {
	content: "\F773"; }
  
  .fa-star-sharp-half-stroke::before {
	content: "\E28D"; }
  
  .fa-star-sharp-half-alt::before {
	content: "\E28D"; }
  
  .fa-nfc-signal::before {
	content: "\E1FB"; }
  
  .fa-plane-prop::before {
	content: "\E22B"; }
  
  .fa-calendar-check::before {
	content: "\F274"; }
  
  .fa-clock-desk::before {
	content: "\E134"; }
  
  .fa-calendar-clock::before {
	content: "\E0D2"; }
  
  .fa-calendar-time::before {
	content: "\E0D2"; }
  
  .fa-braille::before {
	content: "\F2A1"; }
  
  .fa-prescription-bottle-medical::before {
	content: "\F486"; }
  
  .fa-prescription-bottle-alt::before {
	content: "\F486"; }
  
  .fa-plate-utensils::before {
	content: "\E43B"; }
  
  .fa-family-pants::before {
	content: "\E302"; }
  
  .fa-hose-reel::before {
	content: "\E41A"; }
  
  .fa-house-window::before {
	content: "\E3B3"; }
  
  .fa-landmark::before {
	content: "\F66F"; }
  
  .fa-truck::before {
	content: "\F0D1"; }
  
  .fa-crosshairs::before {
	content: "\F05B"; }
  
  .fa-cloud-rainbow::before {
	content: "\F73E"; }
  
  .fa-person-cane::before {
	content: "\E53C"; }
  
  .fa-alien::before {
	content: "\F8F5"; }
  
  .fa-tent::before {
	content: "\E57D"; }
  
  .fa-vest-patches::before {
	content: "\E086"; }
  
  .fa-people-dress-simple::before {
	content: "\E218"; }
  
  .fa-check-double::before {
	content: "\F560"; }
  
  .fa-arrow-down-a-z::before {
	content: "\F15D"; }
  
  .fa-sort-alpha-asc::before {
	content: "\F15D"; }
  
  .fa-sort-alpha-down::before {
	content: "\F15D"; }
  
  .fa-bowling-ball-pin::before {
	content: "\E0C3"; }
  
  .fa-bell-school-slash::before {
	content: "\F5D6"; }
  
  .fa-plus-large::before {
	content: "\E59E"; }
  
  .fa-money-bill-wheat::before {
	content: "\E52A"; }
  
  .fa-camera-viewfinder::before {
	content: "\E0DA"; }
  
  .fa-screenshot::before {
	content: "\E0DA"; }
  
  .fa-message-music::before {
	content: "\F8AF"; }
  
  .fa-comment-alt-music::before {
	content: "\F8AF"; }
  
  .fa-car-building::before {
	content: "\F859"; }
  
  .fa-border-bottom-right::before {
	content: "\F854"; }
  
  .fa-border-style-alt::before {
	content: "\F854"; }
  
  .fa-octagon::before {
	content: "\F306"; }
  
  .fa-comment-arrow-up-right::before {
	content: "\E145"; }
  
  .fa-octagon-divide::before {
	content: "\E203"; }
  
  .fa-cookie::before {
	content: "\F563"; }
  
  .fa-arrow-rotate-left::before {
	content: "\F0E2"; }
  
  .fa-arrow-left-rotate::before {
	content: "\F0E2"; }
  
  .fa-arrow-rotate-back::before {
	content: "\F0E2"; }
  
  .fa-arrow-rotate-backward::before {
	content: "\F0E2"; }
  
  .fa-undo::before {
	content: "\F0E2"; }
  
  .fa-tv-music::before {
	content: "\F8E6"; }
  
  .fa-hard-drive::before {
	content: "\F0A0"; }
  
  .fa-hdd::before {
	content: "\F0A0"; }
  
  .fa-reel::before {
	content: "\E238"; }
  
  .fa-face-grin-squint-tears::before {
	content: "\F586"; }
  
  .fa-grin-squint-tears::before {
	content: "\F586"; }
  
  .fa-dumbbell::before {
	content: "\F44B"; }
  
  .fa-rectangle-list::before {
	content: "\F022"; }
  
  .fa-list-alt::before {
	content: "\F022"; }
  
  .fa-tarp-droplet::before {
	content: "\E57C"; }
  
  .fa-alarm-exclamation::before {
	content: "\F843"; }
  
  .fa-house-medical-circle-check::before {
	content: "\E511"; }
  
  .fa-traffic-cone::before {
	content: "\F636"; }
  
  .fa-grate::before {
	content: "\E193"; }
  
  .fa-arrow-down-right::before {
	content: "\E093"; }
  
  .fa-person-skiing-nordic::before {
	content: "\F7CA"; }
  
  .fa-skiing-nordic::before {
	content: "\F7CA"; }
  
  .fa-calendar-plus::before {
	content: "\F271"; }
  
  .fa-person-from-portal::before {
	content: "\E023"; }
  
  .fa-portal-exit::before {
	content: "\E023"; }
  
  .fa-plane-arrival::before {
	content: "\F5AF"; }
  
  .fa-cowbell-circle-plus::before {
	content: "\F8B4"; }
  
  .fa-cowbell-more::before {
	content: "\F8B4"; }
  
  .fa-circle-left::before {
	content: "\F359"; }
  
  .fa-arrow-alt-circle-left::before {
	content: "\F359"; }
  
  .fa-distribute-spacing-vertical::before {
	content: "\E366"; }
  
  .fa-signal-bars-fair::before {
	content: "\F692"; }
  
  .fa-signal-alt-2::before {
	content: "\F692"; }
  
  .fa-sportsball::before {
	content: "\E44B"; }
  
  .fa-train-subway::before {
	content: "\F239"; }
  
  .fa-subway::before {
	content: "\F239"; }
  
  .fa-chart-gantt::before {
	content: "\E0E4"; }
  
  .fa-face-smile-upside-down::before {
	content: "\E395"; }
  
  .fa-ball-pile::before {
	content: "\F77E"; }
  
  .fa-badge-dollar::before {
	content: "\F645"; }
  
  .fa-money-bills-simple::before {
	content: "\E1F4"; }
  
  .fa-money-bills-alt::before {
	content: "\E1F4"; }
  
  .fa-list-timeline::before {
	content: "\E1D1"; }
  
  .fa-indian-rupee-sign::before {
	content: "\E1BC"; }
  
  .fa-indian-rupee::before {
	content: "\E1BC"; }
  
  .fa-inr::before {
	content: "\E1BC"; }
  
  .fa-crop-simple::before {
	content: "\F565"; }
  
  .fa-crop-alt::before {
	content: "\F565"; }
  
  .fa-money-bill-1::before {
	content: "\F3D1"; }
  
  .fa-money-bill-alt::before {
	content: "\F3D1"; }
  
  .fa-left-long::before {
	content: "\F30A"; }
  
  .fa-long-arrow-alt-left::before {
	content: "\F30A"; }
  
  .fa-keyboard-down::before {
	content: "\E1C2"; }
  
  .fa-circle-up-right::before {
	content: "\E129"; }
  
  .fa-cloud-bolt-moon::before {
	content: "\F76D"; }
  
  .fa-thunderstorm-moon::before {
	content: "\F76D"; }
  
  .fa-dna::before {
	content: "\F471"; }
  
  .fa-virus-slash::before {
	content: "\E075"; }
  
  .fa-bracket-round-right::before {
	content: ")"; }
  
  .fa-circle-5::before {
	content: "\E0F2"; }
  
  .fa-minus::before {
	content: "\F068"; }
  
  .fa-subtract::before {
	content: "\F068"; }
  
  .fa-fire-flame::before {
	content: "\F6DF"; }
  
  .fa-flame::before {
	content: "\F6DF"; }
  
  .fa-right-to-line::before {
	content: "\F34C"; }
  
  .fa-arrow-alt-to-right::before {
	content: "\F34C"; }
  
  .fa-child-rifle::before {
	content: "\E4E0"; }
  
  .fa-gif::before {
	content: "\E190"; }
  
  .fa-chess::before {
	content: "\F439"; }
  
  .fa-trash-slash::before {
	content: "\E2B3"; }
  
  .fa-arrow-left-long::before {
	content: "\F177"; }
  
  .fa-long-arrow-left::before {
	content: "\F177"; }
  
  .fa-plug-circle-check::before {
	content: "\E55C"; }
  
  .fa-font-case::before {
	content: "\F866"; }
  
  .fa-street-view::before {
	content: "\F21D"; }
  
  .fa-arrow-down-left::before {
	content: "\E091"; }
  
  .fa-franc-sign::before {
	content: "\E18F"; }
  
  .fa-flask-round-poison::before {
	content: "\F6E0"; }
  
  .fa-flask-poison::before {
	content: "\F6E0"; }
  
  .fa-volume-off::before {
	content: "\F026"; }
  
  .fa-book-circle-arrow-right::before {
	content: "\E0BC"; }
  
  .fa-chart-user::before {
	content: "\F6A3"; }
  
  .fa-user-chart::before {
	content: "\F6A3"; }
  
  .fa-hands-asl-interpreting::before {
	content: "\F2A3"; }
  
  .fa-american-sign-language-interpreting::before {
	content: "\F2A3"; }
  
  .fa-asl-interpreting::before {
	content: "\F2A3"; }
  
  .fa-hands-american-sign-language-interpreting::before {
	content: "\F2A3"; }
  
  .fa-presentation-screen::before {
	content: "\F685"; }
  
  .fa-presentation::before {
	content: "\F685"; }
  
  .fa-circle-bolt::before {
	content: "\E0FE"; }
  
  .fa-face-smile-halo::before {
	content: "\E38F"; }
  
  .fa-cart-circle-arrow-down::before {
	content: "\E3EF"; }
  
  .fa-house-person-return::before {
	content: "\E011"; }
  
  .fa-house-person-arrive::before {
	content: "\E011"; }
  
  .fa-house-return::before {
	content: "\E011"; }
  
  .fa-message-xmark::before {
	content: "\F4AB"; }
  
  .fa-comment-alt-times::before {
	content: "\F4AB"; }
  
  .fa-message-times::before {
	content: "\F4AB"; }
  
  .fa-file-certificate::before {
	content: "\F5F3"; }
  
  .fa-file-award::before {
	content: "\F5F3"; }
  
  .fa-user-doctor-hair-long::before {
	content: "\E459"; }
  
  .fa-camera-security::before {
	content: "\F8FE"; }
  
  .fa-camera-home::before {
	content: "\F8FE"; }
  
  .fa-gear::before {
	content: "\F013"; }
  
  .fa-cog::before {
	content: "\F013"; }
  
  .fa-droplet-slash::before {
	content: "\F5C7"; }
  
  .fa-tint-slash::before {
	content: "\F5C7"; }
  
  .fa-book-heart::before {
	content: "\F499"; }
  
  .fa-mosque::before {
	content: "\F678"; }
  
  .fa-duck::before {
	content: "\F6D8"; }
  
  .fa-mosquito::before {
	content: "\E52B"; }
  
  .fa-star-of-david::before {
	content: "\F69A"; }
  
  .fa-flag-swallowtail::before {
	content: "\F74C"; }
  
  .fa-flag-alt::before {
	content: "\F74C"; }
  
  .fa-person-military-rifle::before {
	content: "\E54B"; }
  
  .fa-car-garage::before {
	content: "\F5E2"; }
  
  .fa-cart-shopping::before {
	content: "\F07A"; }
  
  .fa-shopping-cart::before {
	content: "\F07A"; }
  
  .fa-book-font::before {
	content: "\E0BF"; }
  
  .fa-shield-plus::before {
	content: "\E24A"; }
  
  .fa-vials::before {
	content: "\F493"; }
  
  .fa-eye-dropper-full::before {
	content: "\E172"; }
  
  .fa-distribute-spacing-horizontal::before {
	content: "\E365"; }
  
  .fa-tablet-rugged::before {
	content: "\F48F"; }
  
  .fa-temperature-snow::before {
	content: "\F768"; }
  
  .fa-temperature-frigid::before {
	content: "\F768"; }
  
  .fa-moped::before {
	content: "\E3B9"; }
  
  .fa-face-smile-plus::before {
	content: "\F5B9"; }
  
  .fa-smile-plus::before {
	content: "\F5B9"; }
  
  .fa-radio-tuner::before {
	content: "\F8D8"; }
  
  .fa-radio-alt::before {
	content: "\F8D8"; }
  
  .fa-face-swear::before {
	content: "\E399"; }
  
  .fa-water-arrow-down::before {
	content: "\F774"; }
  
  .fa-water-lower::before {
	content: "\F774"; }
  
  .fa-scanner-touchscreen::before {
	content: "\F48A"; }
  
  .fa-circle-7::before {
	content: "\E0F4"; }
  
  .fa-plug-circle-plus::before {
	content: "\E55F"; }
  
  .fa-person-ski-jumping::before {
	content: "\F7C7"; }
  
  .fa-ski-jump::before {
	content: "\F7C7"; }
  
  .fa-place-of-worship::before {
	content: "\F67F"; }
  
  .fa-water-arrow-up::before {
	content: "\F775"; }
  
  .fa-water-rise::before {
	content: "\F775"; }
  
  .fa-waveform-lines::before {
	content: "\F8F2"; }
  
  .fa-waveform-path::before {
	content: "\F8F2"; }
  
  .fa-split::before {
	content: "\E254"; }
  
  .fa-film-canister::before {
	content: "\F8B7"; }
  
  .fa-film-cannister::before {
	content: "\F8B7"; }
  
  .fa-folder-xmark::before {
	content: "\F65F"; }
  
  .fa-folder-times::before {
	content: "\F65F"; }
  
  .fa-toilet-paper-blank::before {
	content: "\F71F"; }
  
  .fa-toilet-paper-alt::before {
	content: "\F71F"; }
  
  .fa-tablet-screen::before {
	content: "\F3FC"; }
  
  .fa-tablet-android-alt::before {
	content: "\F3FC"; }
  
  .fa-hexagon-vertical-nft-slanted::before {
	content: "\E506"; }
  
  .fa-folder-music::before {
	content: "\E18D"; }
  
  .fa-display-medical::before {
	content: "\E166"; }
  
  .fa-desktop-medical::before {
	content: "\E166"; }
  
  .fa-share-all::before {
	content: "\F367"; }
  
  .fa-peapod::before {
	content: "\E31C"; }
  
  .fa-chess-clock::before {
	content: "\F43D"; }
  
  .fa-axe::before {
	content: "\F6B2"; }
  
  .fa-square-d::before {
	content: "\E268"; }
  
  .fa-grip-vertical::before {
	content: "\F58E"; }
  
  .fa-mobile-signal-out::before {
	content: "\E1F0"; }
  
  .fa-arrow-turn-up::before {
	content: "\F148"; }
  
  .fa-level-up::before {
	content: "\F148"; }
  
  .fa-u::before {
	content: "U"; }
  
  .fa-arrow-up-from-dotted-line::before {
	content: "\E09B"; }
  
  .fa-square-root-variable::before {
	content: "\F698"; }
  
  .fa-square-root-alt::before {
	content: "\F698"; }
  
  .fa-light-switch-on::before {
	content: "\E019"; }
  
  .fa-arrow-down-arrow-up::before {
	content: "\F883"; }
  
  .fa-sort-alt::before {
	content: "\F883"; }
  
  .fa-raindrops::before {
	content: "\F75C"; }
  
  .fa-dash::before {
	content: "\E404"; }
  
  .fa-minus-large::before {
	content: "\E404"; }
  
  .fa-clock::before {
	content: "\F017"; }
  
  .fa-clock-four::before {
	content: "\F017"; }
  
  .fa-input-numeric::before {
	content: "\E1BD"; }
  
  .fa-truck-tow::before {
	content: "\E2B8"; }
  
  .fa-backward-step::before {
	content: "\F048"; }
  
  .fa-step-backward::before {
	content: "\F048"; }
  
  .fa-pallet::before {
	content: "\F482"; }
  
  .fa-car-bolt::before {
	content: "\E341"; }
  
  .fa-arrows-maximize::before {
	content: "\F31D"; }
  
  .fa-expand-arrows::before {
	content: "\F31D"; }
  
  .fa-faucet::before {
	content: "\E005"; }
  
  .fa-cloud-sleet::before {
	content: "\F741"; }
  
  .fa-lamp-street::before {
	content: "\E1C5"; }
  
  .fa-list-radio::before {
	content: "\E1D0"; }
  
  .fa-pen-nib-slash::before {
	content: "\E4A1"; }
  
  .fa-baseball-bat-ball::before {
	content: "\F432"; }
  
  .fa-square-up-left::before {
	content: "\E282"; }
  
  .fa-overline::before {
	content: "\F876"; }
  
  .fa-s::before {
	content: "S"; }
  
  .fa-timeline::before {
	content: "\E29C"; }
  
  .fa-keyboard::before {
	content: "\F11C"; }
  
  .fa-arrows-from-dotted-line::before {
	content: "\E0A3"; }
  
  .fa-usb-drive::before {
	content: "\F8E9"; }
  
  .fa-ballot::before {
	content: "\F732"; }
  
  .fa-caret-down::before {
	content: "\F0D7"; }
  
  .fa-location-dot-slash::before {
	content: "\F605"; }
  
  .fa-map-marker-alt-slash::before {
	content: "\F605"; }
  
  .fa-cards::before {
	content: "\E3ED"; }
  
  .fa-house-chimney-medical::before {
	content: "\F7F2"; }
  
  .fa-clinic-medical::before {
	content: "\F7F2"; }
  
  .fa-boxing-glove::before {
	content: "\F438"; }
  
  .fa-glove-boxing::before {
	content: "\F438"; }
  
  .fa-temperature-three-quarters::before {
	content: "\F2C8"; }
  
  .fa-temperature-3::before {
	content: "\F2C8"; }
  
  .fa-thermometer-3::before {
	content: "\F2C8"; }
  
  .fa-thermometer-three-quarters::before {
	content: "\F2C8"; }
  
  .fa-bell-school::before {
	content: "\F5D5"; }
  
  .fa-mobile-screen::before {
	content: "\F3CF"; }
  
  .fa-mobile-android-alt::before {
	content: "\F3CF"; }
  
  .fa-plane-up::before {
	content: "\E22D"; }
  
  .fa-folder-heart::before {
	content: "\E189"; }
  
  .fa-circle-location-arrow::before {
	content: "\F602"; }
  
  .fa-location-circle::before {
	content: "\F602"; }
  
  .fa-face-head-bandage::before {
	content: "\E37A"; }
  
  .fa-sushi-roll::before {
	content: "\E48B"; }
  
  .fa-maki-roll::before {
	content: "\E48B"; }
  
  .fa-makizushi::before {
	content: "\E48B"; }
  
  .fa-car-bump::before {
	content: "\F5E0"; }
  
  .fa-piggy-bank::before {
	content: "\F4D3"; }
  
  .fa-racquet::before {
	content: "\F45A"; }
  
  .fa-car-mirrors::before {
	content: "\E343"; }
  
  .fa-industry-windows::before {
	content: "\F3B3"; }
  
  .fa-industry-alt::before {
	content: "\F3B3"; }
  
  .fa-bolt-auto::before {
	content: "\E0B6"; }
  
  .fa-battery-half::before {
	content: "\F242"; }
  
  .fa-battery-3::before {
	content: "\F242"; }
  
  .fa-flux-capacitor::before {
	content: "\F8BA"; }
  
  .fa-mountain-city::before {
	content: "\E52E"; }
  
  .fa-coins::before {
	content: "\F51E"; }
  
  .fa-honey-pot::before {
	content: "\E418"; }
  
  .fa-olive::before {
	content: "\E316"; }
  
  .fa-khanda::before {
	content: "\F66D"; }
  
  .fa-filter-list::before {
	content: "\E17C"; }
  
  .fa-outlet::before {
	content: "\E01C"; }
  
  .fa-sliders::before {
	content: "\F1DE"; }
  
  .fa-sliders-h::before {
	content: "\F1DE"; }
  
  .fa-cauldron::before {
	content: "\F6BF"; }
  
  .fa-people::before {
	content: "\E216"; }
  
  .fa-folder-tree::before {
	content: "\F802"; }
  
  .fa-network-wired::before {
	content: "\F6FF"; }
  
  .fa-croissant::before {
	content: "\F7F6"; }
  
  .fa-map-pin::before {
	content: "\F276"; }
  
  .fa-hamsa::before {
	content: "\F665"; }
  
  .fa-cent-sign::before {
	content: "\E3F5"; }
  
  .fa-swords-laser::before {
	content: "\E03D"; }
  
  .fa-flask::before {
	content: "\F0C3"; }
  
  .fa-person-pregnant::before {
	content: "\E31E"; }
  
  .fa-square-u::before {
	content: "\E281"; }
  
  .fa-wand-sparkles::before {
	content: "\F72B"; }
  
  .fa-router::before {
	content: "\F8DA"; }
  
  .fa-ellipsis-vertical::before {
	content: "\F142"; }
  
  .fa-ellipsis-v::before {
	content: "\F142"; }
  
  .fa-sword-laser-alt::before {
	content: "\E03C"; }
  
  .fa-ticket::before {
	content: "\F145"; }
  
  .fa-power-off::before {
	content: "\F011"; }
  
  .fa-coin::before {
	content: "\F85C"; }
  
  .fa-laptop-slash::before {
	content: "\E1C7"; }
  
  .fa-right-long::before {
	content: "\F30B"; }
  
  .fa-long-arrow-alt-right::before {
	content: "\F30B"; }
  
  .fa-circle-b::before {
	content: "\E0FD"; }
  
  .fa-person-dress-simple::before {
	content: "\E21C"; }
  
  .fa-pipe-collar::before {
	content: "\E437"; }
  
  .fa-lights-holiday::before {
	content: "\F7B2"; }
  
  .fa-citrus::before {
	content: "\E2F4"; }
  
  .fa-flag-usa::before {
	content: "\F74D"; }
  
  .fa-laptop-file::before {
	content: "\E51D"; }
  
  .fa-tty::before {
	content: "\F1E4"; }
  
  .fa-teletype::before {
	content: "\F1E4"; }
  
  .fa-chart-tree-map::before {
	content: "\E0EA"; }
  
  .fa-diagram-next::before {
	content: "\E476"; }
  
  .fa-person-rifle::before {
	content: "\E54E"; }
  
  .fa-clock-five-thirty::before {
	content: "\E34A"; }
  
  .fa-pipe-valve::before {
	content: "\E439"; }
  
  .fa-arrow-up-from-arc::before {
	content: "\E4B4"; }
  
  .fa-face-spiral-eyes::before {
	content: "\E485"; }
  
  .fa-compress-wide::before {
	content: "\F326"; }
  
  .fa-circle-phone-hangup::before {
	content: "\E11D"; }
  
  .fa-phone-circle-down::before {
	content: "\E11D"; }
  
  .fa-house-medical-circle-exclamation::before {
	content: "\E512"; }
  
  .fa-badminton::before {
	content: "\E33A"; }
  
  .fa-closed-captioning::before {
	content: "\F20A"; }
  
  .fa-person-hiking::before {
	content: "\F6EC"; }
  
  .fa-hiking::before {
	content: "\F6EC"; }
  
  .fa-right-from-line::before {
	content: "\F347"; }
  
  .fa-arrow-alt-from-left::before {
	content: "\F347"; }
  
  .fa-venus-double::before {
	content: "\F226"; }
  
  .fa-images::before {
	content: "\F302"; }
  
  .fa-calculator::before {
	content: "\F1EC"; }
  
  .fa-shuttlecock::before {
	content: "\F45B"; }
  
  .fa-user-hair::before {
	content: "\E45A"; }
  
  .fa-eye-evil::before {
	content: "\F6DB"; }
  
  .fa-people-pulling::before {
	content: "\E535"; }
  
  .fa-n::before {
	content: "N"; }
  
  .fa-garage::before {
	content: "\E009"; }
  
  .fa-cable-car::before {
	content: "\F7DA"; }
  
  .fa-tram::before {
	content: "\F7DA"; }
  
  .fa-shovel-snow::before {
	content: "\F7C3"; }
  
  .fa-cloud-rain::before {
	content: "\F73D"; }
  
  .fa-face-lying::before {
	content: "\E37E"; }
  
  .fa-sprinkler::before {
	content: "\E035"; }
  
  .fa-building-circle-xmark::before {
	content: "\E4D4"; }
  
  .fa-person-sledding::before {
	content: "\F7CB"; }
  
  .fa-sledding::before {
	content: "\F7CB"; }
  
  .fa-game-console-handheld::before {
	content: "\F8BB"; }
  
  .fa-ship::before {
	content: "\F21A"; }
  
  .fa-clock-six-thirty::before {
	content: "\E353"; }
  
  .fa-battery-slash::before {
	content: "\F377"; }
  
  .fa-tugrik-sign::before {
	content: "\E2BA"; }
  
  .fa-arrows-down-to-line::before {
	content: "\E4B8"; }
  
  .fa-download::before {
	content: "\F019"; }
  
  .fa-shelves::before {
	content: "\F480"; }
  
  .fa-inventory::before {
	content: "\F480"; }
  
  .fa-cloud-snow::before {
	content: "\F742"; }
  
  .fa-face-grin::before {
	content: "\F580"; }
  
  .fa-grin::before {
	content: "\F580"; }
  
  .fa-delete-left::before {
	content: "\F55A"; }
  
  .fa-backspace::before {
	content: "\F55A"; }
  
  .fa-oven::before {
	content: "\E01D"; }
  
  .fa-eye-dropper::before {
	content: "\F1FB"; }
  
  .fa-eye-dropper-empty::before {
	content: "\F1FB"; }
  
  .fa-eyedropper::before {
	content: "\F1FB"; }
  
  .fa-comment-captions::before {
	content: "\E146"; }
  
  .fa-comments-question::before {
	content: "\E14E"; }
  
  .fa-scribble::before {
	content: "\E23F"; }
  
  .fa-rotate-exclamation::before {
	content: "\E23C"; }
  
  .fa-file-circle-check::before {
	content: "\E5A0"; }
  
  .fa-glass::before {
	content: "\F804"; }
  
  .fa-loader::before {
	content: "\E1D4"; }
  
  .fa-forward::before {
	content: "\F04E"; }
  
  .fa-user-pilot::before {
	content: "\E2C0"; }
  
  .fa-mobile::before {
	content: "\F3CE"; }
  
  .fa-mobile-android::before {
	content: "\F3CE"; }
  
  .fa-mobile-phone::before {
	content: "\F3CE"; }
  
  .fa-code-pull-request-closed::before {
	content: "\E3F9"; }
  
  .fa-face-meh::before {
	content: "\F11A"; }
  
  .fa-meh::before {
	content: "\F11A"; }
  
  .fa-align-center::before {
	content: "\F037"; }
  
  .fa-book-skull::before {
	content: "\F6B7"; }
  
  .fa-book-dead::before {
	content: "\F6B7"; }
  
  .fa-id-card::before {
	content: "\F2C2"; }
  
  .fa-drivers-license::before {
	content: "\F2C2"; }
  
  .fa-face-dotted::before {
	content: "\E47F"; }
  
  .fa-face-worried::before {
	content: "\E3A3"; }
  
  .fa-outdent::before {
	content: "\F03B"; }
  
  .fa-dedent::before {
	content: "\F03B"; }
  
  .fa-heart-circle-exclamation::before {
	content: "\E4FE"; }
  
  .fa-house::before {
	content: "\F015"; }
  
  .fa-home::before {
	content: "\F015"; }
  
  .fa-home-alt::before {
	content: "\F015"; }
  
  .fa-home-lg-alt::before {
	content: "\F015"; }
  
  .fa-vector-circle::before {
	content: "\E2C6"; }
  
  .fa-car-circle-bolt::before {
	content: "\E342"; }
  
  .fa-calendar-week::before {
	content: "\F784"; }
  
  .fa-flying-disc::before {
	content: "\E3A9"; }
  
  .fa-laptop-medical::before {
	content: "\F812"; }
  
  .fa-square-down-right::before {
	content: "\E26C"; }
  
  .fa-b::before {
	content: "B"; }
  
  .fa-seat-airline::before {
	content: "\E244"; }
  
  .fa-moon-over-sun::before {
	content: "\F74A"; }
  
  .fa-eclipse-alt::before {
	content: "\F74A"; }
  
  .fa-pipe::before {
	content: "|"; }
  
  .fa-file-medical::before {
	content: "\F477"; }
  
  .fa-potato::before {
	content: "\E440"; }
  
  .fa-dice-one::before {
	content: "\F525"; }
  
  .fa-circle-a::before {
	content: "\E0F7"; }
  
  .fa-helmet-battle::before {
	content: "\F6EB"; }
  
  .fa-butter::before {
	content: "\E3E4"; }
  
  .fa-blanket-fire::before {
	content: "\E3DA"; }
  
  .fa-kiwi-bird::before {
	content: "\F535"; }
  
  .fa-castle::before {
	content: "\E0DE"; }
  
  .fa-golf-club::before {
	content: "\F451"; }
  
  .fa-arrow-right-arrow-left::before {
	content: "\F0EC"; }
  
  .fa-exchange::before {
	content: "\F0EC"; }
  
  .fa-rotate-right::before {
	content: "\F2F9"; }
  
  .fa-redo-alt::before {
	content: "\F2F9"; }
  
  .fa-rotate-forward::before {
	content: "\F2F9"; }
  
  .fa-utensils::before {
	content: "\F2E7"; }
  
  .fa-cutlery::before {
	content: "\F2E7"; }
  
  .fa-arrow-up-wide-short::before {
	content: "\F161"; }
  
  .fa-sort-amount-up::before {
	content: "\F161"; }
  
  .fa-balloons::before {
	content: "\E2E4"; }
  
  .fa-mill-sign::before {
	content: "\E1ED"; }
  
  .fa-bowl-rice::before {
	content: "\E2EB"; }
  
  .fa-timeline-arrow::before {
	content: "\E29D"; }
  
  .fa-skull::before {
	content: "\F54C"; }
  
  .fa-game-board-simple::before {
	content: "\F868"; }
  
  .fa-game-board-alt::before {
	content: "\F868"; }
  
  .fa-circle-video::before {
	content: "\E12B"; }
  
  .fa-video-circle::before {
	content: "\E12B"; }
  
  .fa-chart-scatter-bubble::before {
	content: "\E0E9"; }
  
  .fa-house-turret::before {
	content: "\E1B4"; }
  
  .fa-banana::before {
	content: "\E2E5"; }
  
  .fa-hand-holding-skull::before {
	content: "\E1A4"; }
  
  .fa-people-dress::before {
	content: "\E217"; }
  
  .fa-loveseat::before {
	content: "\F4CC"; }
  
  .fa-couch-small::before {
	content: "\F4CC"; }
  
  .fa-tower-broadcast::before {
	content: "\F519"; }
  
  .fa-broadcast-tower::before {
	content: "\F519"; }
  
  .fa-truck-pickup::before {
	content: "\F63C"; }
  
  .fa-block-quote::before {
	content: "\E0B5"; }
  
  .fa-up-long::before {
	content: "\F30C"; }
  
  .fa-long-arrow-alt-up::before {
	content: "\F30C"; }
  
  .fa-stop::before {
	content: "\F04D"; }
  
  .fa-code-merge::before {
	content: "\F387"; }
  
  .fa-money-check-dollar-pen::before {
	content: "\F873"; }
  
  .fa-money-check-edit-alt::before {
	content: "\F873"; }
  
  .fa-up-from-line::before {
	content: "\F346"; }
  
  .fa-arrow-alt-from-bottom::before {
	content: "\F346"; }
  
  .fa-upload::before {
	content: "\F093"; }
  
  .fa-hurricane::before {
	content: "\F751"; }
  
  .fa-people-pants::before {
	content: "\E219"; }
  
  .fa-mound::before {
	content: "\E52D"; }
  
  .fa-windsock::before {
	content: "\F777"; }
  
  .fa-circle-half::before {
	content: "\E110"; }
  
  .fa-brake-warning::before {
	content: "\E0C7"; }
  
  .fa-toilet-portable::before {
	content: "\E583"; }
  
  .fa-compact-disc::before {
	content: "\F51F"; }
  
  .fa-file-arrow-down::before {
	content: "\F56D"; }
  
  .fa-file-download::before {
	content: "\F56D"; }
  
  .fa-saxophone-fire::before {
	content: "\F8DB"; }
  
  .fa-sax-hot::before {
	content: "\F8DB"; }
  
  .fa-camera-web-slash::before {
	content: "\F833"; }
  
  .fa-webcam-slash::before {
	content: "\F833"; }
  
  .fa-folder-medical::before {
	content: "\E18C"; }
  
  .fa-folder-gear::before {
	content: "\E187"; }
  
  .fa-folder-cog::before {
	content: "\E187"; }
  
  .fa-hand-wave::before {
	content: "\E1A7"; }
  
  .fa-arrow-up-arrow-down::before {
	content: "\E099"; }
  
  .fa-sort-up-down::before {
	content: "\E099"; }
  
  .fa-caravan::before {
	content: "\F8FF"; }
  
  .fa-shield-cat::before {
	content: "\E572"; }
  
  .fa-message-slash::before {
	content: "\F4A9"; }
  
  .fa-comment-alt-slash::before {
	content: "\F4A9"; }
  
  .fa-bolt::before {
	content: "\F0E7"; }
  
  .fa-zap::before {
	content: "\F0E7"; }
  
  .fa-trash-can-check::before {
	content: "\E2A9"; }
  
  .fa-glass-water::before {
	content: "\E4F4"; }
  
  .fa-oil-well::before {
	content: "\E532"; }
  
  .fa-person-simple::before {
	content: "\E220"; }
  
  .fa-vault::before {
	content: "\E2C5"; }
  
  .fa-mars::before {
	content: "\F222"; }
  
  .fa-toilet::before {
	content: "\F7D8"; }
  
  .fa-plane-circle-xmark::before {
	content: "\E557"; }
  
  .fa-yen-sign::before {
	content: "\F157"; }
  
  .fa-cny::before {
	content: "\F157"; }
  
  .fa-jpy::before {
	content: "\F157"; }
  
  .fa-rmb::before {
	content: "\F157"; }
  
  .fa-yen::before {
	content: "\F157"; }
  
  .fa-notes::before {
	content: "\E202"; }
  
  .fa-ruble-sign::before {
	content: "\F158"; }
  
  .fa-rouble::before {
	content: "\F158"; }
  
  .fa-rub::before {
	content: "\F158"; }
  
  .fa-ruble::before {
	content: "\F158"; }
  
  .fa-trash-undo::before {
	content: "\F895"; }
  
  .fa-trash-arrow-turn-left::before {
	content: "\F895"; }
  
  .fa-champagne-glass::before {
	content: "\F79E"; }
  
  .fa-glass-champagne::before {
	content: "\F79E"; }
  
  .fa-objects-align-center-horizontal::before {
	content: "\E3BC"; }
  
  .fa-sun::before {
	content: "\F185"; }
  
  .fa-trash-can-slash::before {
	content: "\E2AD"; }
  
  .fa-trash-alt-slash::before {
	content: "\E2AD"; }
  
  .fa-screen-users::before {
	content: "\F63D"; }
  
  .fa-users-class::before {
	content: "\F63D"; }
  
  .fa-guitar::before {
	content: "\F7A6"; }
  
  .fa-square-arrow-left::before {
	content: "\F33A"; }
  
  .fa-arrow-square-left::before {
	content: "\F33A"; }
  
  .fa-square-8::before {
	content: "\E25D"; }
  
  .fa-face-smile-hearts::before {
	content: "\E390"; }
  
  .fa-brackets-square::before {
	content: "\F7E9"; }
  
  .fa-brackets::before {
	content: "\F7E9"; }
  
  .fa-laptop-arrow-down::before {
	content: "\E1C6"; }
  
  .fa-hockey-stick-puck::before {
	content: "\E3AE"; }
  
  .fa-house-tree::before {
	content: "\E1B3"; }
  
  .fa-signal-fair::before {
	content: "\F68D"; }
  
  .fa-signal-2::before {
	content: "\F68D"; }
  
  .fa-face-laugh-wink::before {
	content: "\F59C"; }
  
  .fa-laugh-wink::before {
	content: "\F59C"; }
  
  .fa-circle-dollar::before {
	content: "\F2E8"; }
  
  .fa-dollar-circle::before {
	content: "\F2E8"; }
  
  .fa-usd-circle::before {
	content: "\F2E8"; }
  
  .fa-horse-head::before {
	content: "\F7AB"; }
  
  .fa-arrows-repeat::before {
	content: "\F364"; }
  
  .fa-repeat-alt::before {
	content: "\F364"; }
  
  .fa-bore-hole::before {
	content: "\E4C3"; }
  
  .fa-industry::before {
	content: "\F275"; }
  
  .fa-image-polaroid::before {
	content: "\F8C4"; }
  
  .fa-wave-triangle::before {
	content: "\F89A"; }
  
  .fa-circle-down::before {
	content: "\F358"; }
  
  .fa-arrow-alt-circle-down::before {
	content: "\F358"; }
  
  .fa-grill::before {
	content: "\E5A3"; }
  
  .fa-arrows-turn-to-dots::before {
	content: "\E4C1"; }
  
  .fa-chart-mixed::before {
	content: "\F643"; }
  
  .fa-analytics::before {
	content: "\F643"; }
  
  .fa-florin-sign::before {
	content: "\E184"; }
  
  .fa-arrow-down-short-wide::before {
	content: "\F884"; }
  
  .fa-sort-amount-desc::before {
	content: "\F884"; }
  
  .fa-sort-amount-down-alt::before {
	content: "\F884"; }
  
  .fa-less-than::before {
	content: "<"; }
  
  .fa-display-code::before {
	content: "\E165"; }
  
  .fa-desktop-code::before {
	content: "\E165"; }
  
  .fa-face-drooling::before {
	content: "\E372"; }
  
  .fa-oil-temperature::before {
	content: "\F614"; }
  
  .fa-oil-temp::before {
	content: "\F614"; }
  
  .fa-square-question::before {
	content: "\F2FD"; }
  
  .fa-question-square::before {
	content: "\F2FD"; }
  
  .fa-air-conditioner::before {
	content: "\F8F4"; }
  
  .fa-angle-down::before {
	content: "\F107"; }
  
  .fa-mountains::before {
	content: "\F6FD"; }
  
  .fa-omega::before {
	content: "\F67A"; }
  
  .fa-car-tunnel::before {
	content: "\E4DE"; }
  
  .fa-person-dolly-empty::before {
	content: "\F4D1"; }
  
  .fa-pan-food::before {
	content: "\E42B"; }
  
  .fa-head-side-cough::before {
	content: "\E061"; }
  
  .fa-grip-lines::before {
	content: "\F7A4"; }
  
  .fa-thumbs-down::before {
	content: "\F165"; }
  
  .fa-user-lock::before {
	content: "\F502"; }
  
  .fa-arrow-right-long::before {
	content: "\F178"; }
  
  .fa-long-arrow-right::before {
	content: "\F178"; }
  
  .fa-tickets-airline::before {
	content: "\E29B"; }
  
  .fa-anchor-circle-xmark::before {
	content: "\E4AC"; }
  
  .fa-ellipsis::before {
	content: "\F141"; }
  
  .fa-ellipsis-h::before {
	content: "\F141"; }
  
  .fa-nfc-slash::before {
	content: "\E1FC"; }
  
  .fa-chess-pawn::before {
	content: "\F443"; }
  
  .fa-kit-medical::before {
	content: "\F479"; }
  
  .fa-first-aid::before {
	content: "\F479"; }
  
  .fa-grid-2-plus::before {
	content: "\E197"; }
  
  .fa-bells::before {
	content: "\F77F"; }
  
  .fa-person-through-window::before {
	content: "\E5A9"; }
  
  .fa-toolbox::before {
	content: "\F552"; }
  
  .fa-envelope-dot::before {
	content: "\E16F"; }
  
  .fa-envelope-badge::before {
	content: "\E16F"; }
  
  .fa-hands-holding-circle::before {
	content: "\E4FB"; }
  
  .fa-bug::before {
	content: "\F188"; }
  
  .fa-bowl-chopsticks::before {
	content: "\E2E9"; }
  
  .fa-credit-card::before {
	content: "\F09D"; }
  
  .fa-credit-card-alt::before {
	content: "\F09D"; }
  
  .fa-circle-s::before {
	content: "\E121"; }
  
  .fa-box-ballot::before {
	content: "\F735"; }
  
  .fa-car::before {
	content: "\F1B9"; }
  
  .fa-automobile::before {
	content: "\F1B9"; }
  
  .fa-hand-holding-hand::before {
	content: "\E4F7"; }
  
  .fa-user-tie-hair::before {
	content: "\E45F"; }
  
  .fa-podium-star::before {
	content: "\F758"; }
  
  .fa-user-hair-mullet::before {
	content: "\E45C"; }
  
  .fa-business-front::before {
	content: "\E45C"; }
  
  .fa-party-back::before {
	content: "\E45C"; }
  
  .fa-trian-balbot::before {
	content: "\E45C"; }
  
  .fa-microphone-stand::before {
	content: "\F8CB"; }
  
  .fa-book-open-reader::before {
	content: "\F5DA"; }
  
  .fa-book-reader::before {
	content: "\F5DA"; }
  
  .fa-family-dress::before {
	content: "\E301"; }
  
  .fa-circle-x::before {
	content: "\E12E"; }
  
  .fa-cabin::before {
	content: "\E46D"; }
  
  .fa-mountain-sun::before {
	content: "\E52F"; }
  
  .fa-chart-simple-horizontal::before {
	content: "\E474"; }
  
  .fa-arrows-left-right-to-line::before {
	content: "\E4BA"; }
  
  .fa-hand-back-point-left::before {
	content: "\E19F"; }
  
  .fa-message-dots::before {
	content: "\F4A3"; }
  
  .fa-comment-alt-dots::before {
	content: "\F4A3"; }
  
  .fa-messaging::before {
	content: "\F4A3"; }
  
  .fa-file-heart::before {
	content: "\E176"; }
  
  .fa-beer-mug::before {
	content: "\E0B3"; }
  
  .fa-beer-foam::before {
	content: "\E0B3"; }
  
  .fa-dice-d20::before {
	content: "\F6CF"; }
  
  .fa-drone::before {
	content: "\F85F"; }
  
  .fa-truck-droplet::before {
	content: "\E58C"; }
  
  .fa-file-circle-xmark::before {
	content: "\E5A1"; }
  
  .fa-temperature-arrow-up::before {
	content: "\E040"; }
  
  .fa-temperature-up::before {
	content: "\E040"; }
  
  .fa-medal::before {
	content: "\F5A2"; }
  
  .fa-bed::before {
	content: "\F236"; }
  
  .fa-book-copy::before {
	content: "\E0BE"; }
  
  .fa-square-h::before {
	content: "\F0FD"; }
  
  .fa-h-square::before {
	content: "\F0FD"; }
  
  .fa-square-c::before {
	content: "\E266"; }
  
  .fa-clock-two::before {
	content: "\E35A"; }
  
  .fa-square-ellipsis-vertical::before {
	content: "\E26F"; }
  
  .fa-podcast::before {
	content: "\F2CE"; }
  
  .fa-bee::before {
	content: "\E0B2"; }
  
  .fa-temperature-full::before {
	content: "\F2C7"; }
  
  .fa-temperature-4::before {
	content: "\F2C7"; }
  
  .fa-thermometer-4::before {
	content: "\F2C7"; }
  
  .fa-thermometer-full::before {
	content: "\F2C7"; }
  
  .fa-bell::before {
	content: "\F0F3"; }
  
  .fa-candy-bar::before {
	content: "\E3E8"; }
  
  .fa-chocolate-bar::before {
	content: "\E3E8"; }
  
  .fa-xmark-large::before {
	content: "\E59B"; }
  
  .fa-pinata::before {
	content: "\E3C3"; }
  
  .fa-arrows-from-line::before {
	content: "\E0A4"; }
  
  .fa-superscript::before {
	content: "\F12B"; }
  
  .fa-bowl-spoon::before {
	content: "\E3E0"; }
  
  .fa-hexagon-check::before {
	content: "\E416"; }
  
  .fa-plug-circle-xmark::before {
	content: "\E560"; }
  
  .fa-star-of-life::before {
	content: "\F621"; }
  
  .fa-phone-slash::before {
	content: "\F3DD"; }
  
  .fa-traffic-light-stop::before {
	content: "\F63A"; }
  
  .fa-paint-roller::before {
	content: "\F5AA"; }
  
  .fa-accent-grave::before {
	content: "`"; }
  
  .fa-handshake-angle::before {
	content: "\F4C4"; }
  
  .fa-hands-helping::before {
	content: "\F4C4"; }
  
  .fa-circle-0::before {
	content: "\E0ED"; }
  
  .fa-dial-med-low::before {
	content: "\E160"; }
  
  .fa-location-dot::before {
	content: "\F3C5"; }
  
  .fa-map-marker-alt::before {
	content: "\F3C5"; }
  
  .fa-crab::before {
	content: "\E3FF"; }
  
  .fa-box-open-full::before {
	content: "\F49C"; }
  
  .fa-box-full::before {
	content: "\F49C"; }
  
  .fa-file::before {
	content: "\F15B"; }
  
  .fa-greater-than::before {
	content: ">"; }
  
  .fa-quotes::before {
	content: "\E234"; }
  
  .fa-pretzel::before {
	content: "\E441"; }
  
  .fa-person-swimming::before {
	content: "\F5C4"; }
  
  .fa-swimmer::before {
	content: "\F5C4"; }
  
  .fa-arrow-down::before {
	content: "\F063"; }
  
  .fa-user-robot-xmarks::before {
	content: "\E4A7"; }
  
  .fa-message-quote::before {
	content: "\E1E4"; }
  
  .fa-comment-alt-quote::before {
	content: "\E1E4"; }
  
  .fa-candy-corn::before {
	content: "\F6BD"; }
  
  .fa-folder-magnifying-glass::before {
	content: "\E18B"; }
  
  .fa-folder-search::before {
	content: "\E18B"; }
  
  .fa-notebook::before {
	content: "\E201"; }
  
  .fa-droplet::before {
	content: "\F043"; }
  
  .fa-tint::before {
	content: "\F043"; }
  
  .fa-bullseye-pointer::before {
	content: "\F649"; }
  
  .fa-eraser::before {
	content: "\F12D"; }
  
  .fa-hexagon-image::before {
	content: "\E504"; }
  
  .fa-earth-americas::before {
	content: "\F57D"; }
  
  .fa-earth::before {
	content: "\F57D"; }
  
  .fa-earth-america::before {
	content: "\F57D"; }
  
  .fa-globe-americas::before {
	content: "\F57D"; }
  
  .fa-crate-apple::before {
	content: "\F6B1"; }
  
  .fa-apple-crate::before {
	content: "\F6B1"; }
  
  .fa-person-burst::before {
	content: "\E53B"; }
  
  .fa-game-board::before {
	content: "\F867"; }
  
  .fa-hat-chef::before {
	content: "\F86B"; }
  
  .fa-hand-back-point-right::before {
	content: "\E1A1"; }
  
  .fa-dove::before {
	content: "\F4BA"; }
  
  .fa-battery-empty::before {
	content: "\F244"; }
  
  .fa-battery-0::before {
	content: "\F244"; }
  
  .fa-grid-4::before {
	content: "\E198"; }
  
  .fa-socks::before {
	content: "\F696"; }
  
  .fa-face-sunglasses::before {
	content: "\E398"; }
  
  .fa-inbox::before {
	content: "\F01C"; }
  
  .fa-square-0::before {
	content: "\E255"; }
  
  .fa-section::before {
	content: "\E447"; }
  
  .fa-square-this-way-up::before {
	content: "\F49F"; }
  
  .fa-box-up::before {
	content: "\F49F"; }
  
  .fa-gauge-high::before {
	content: "\F625"; }
  
  .fa-tachometer-alt::before {
	content: "\F625"; }
  
  .fa-tachometer-alt-fast::before {
	content: "\F625"; }
  
  .fa-square-ampersand::before {
	content: "\E260"; }
  
  .fa-envelope-open-text::before {
	content: "\F658"; }
  
  .fa-lamp-desk::before {
	content: "\E014"; }
  
  .fa-hospital::before {
	content: "\F0F8"; }
  
  .fa-hospital-alt::before {
	content: "\F0F8"; }
  
  .fa-hospital-wide::before {
	content: "\F0F8"; }
  
  .fa-poll-people::before {
	content: "\F759"; }
  
  .fa-whiskey-glass-ice::before {
	content: "\F7A1"; }
  
  .fa-glass-whiskey-rocks::before {
	content: "\F7A1"; }
  
  .fa-wine-bottle::before {
	content: "\F72F"; }
  
  .fa-chess-rook::before {
	content: "\F447"; }
  
  .fa-user-bounty-hunter::before {
	content: "\E2BF"; }
  
  .fa-bars-staggered::before {
	content: "\F550"; }
  
  .fa-reorder::before {
	content: "\F550"; }
  
  .fa-stream::before {
	content: "\F550"; }
  
  .fa-diagram-sankey::before {
	content: "\E158"; }
  
  .fa-cloud-hail-mixed::before {
	content: "\F73A"; }
  
  .fa-circle-up-left::before {
	content: "\E128"; }
  
  .fa-dharmachakra::before {
	content: "\F655"; }
  
  .fa-objects-align-left::before {
	content: "\E3BE"; }
  
  .fa-oil-can-drip::before {
	content: "\E205"; }
  
  .fa-face-smiling-hands::before {
	content: "\E396"; }
  
  .fa-broccoli::before {
	content: "\E3E2"; }
  
  .fa-route-interstate::before {
	content: "\F61B"; }
  
  .fa-ear-muffs::before {
	content: "\F795"; }
  
  .fa-hotdog::before {
	content: "\F80F"; }
  
  .fa-transporter-empty::before {
	content: "\E046"; }
  
  .fa-person-walking-with-cane::before {
	content: "\F29D"; }
  
  .fa-blind::before {
	content: "\F29D"; }
  
  .fa-angle-90::before {
	content: "\E08D"; }
  
  .fa-rectangle-terminal::before {
	content: "\E236"; }
  
  .fa-kite::before {
	content: "\F6F4"; }
  
  .fa-drum::before {
	content: "\F569"; }
  
  .fa-scrubber::before {
	content: "\F2F8"; }
  
  .fa-ice-cream::before {
	content: "\F810"; }
  
  .fa-heart-circle-bolt::before {
	content: "\E4FC"; }
  
  .fa-fish-bones::before {
	content: "\E304"; }
  
  .fa-deer-rudolph::before {
	content: "\F78F"; }
  
  .fa-fax::before {
	content: "\F1AC"; }
  
  .fa-paragraph::before {
	content: "\F1DD"; }
  
  .fa-head-side-heart::before {
	content: "\E1AA"; }
  
  .fa-square-e::before {
	content: "\E26D"; }
  
  .fa-meter-fire::before {
	content: "\E1EB"; }
  
  .fa-cloud-hail::before {
	content: "\F739"; }
  
  .fa-check-to-slot::before {
	content: "\F772"; }
  
  .fa-vote-yea::before {
	content: "\F772"; }
  
  .fa-money-from-bracket::before {
	content: "\E312"; }
  
  .fa-star-half::before {
	content: "\F089"; }
  
  .fa-car-bus::before {
	content: "\F85A"; }
  
  .fa-speaker::before {
	content: "\F8DF"; }
  
  .fa-timer::before {
	content: "\E29E"; }
  
  .fa-boxes-stacked::before {
	content: "\F468"; }
  
  .fa-boxes::before {
	content: "\F468"; }
  
  .fa-boxes-alt::before {
	content: "\F468"; }
  
  .fa-grill-hot::before {
	content: "\E5A5"; }
  
  .fa-ballot-check::before {
	content: "\F733"; }
  
  .fa-link::before {
	content: "\F0C1"; }
  
  .fa-chain::before {
	content: "\F0C1"; }
  
  .fa-ear-listen::before {
	content: "\F2A2"; }
  
  .fa-assistive-listening-systems::before {
	content: "\F2A2"; }
  
  .fa-file-minus::before {
	content: "\F318"; }
  
  .fa-tree-city::before {
	content: "\E587"; }
  
  .fa-play::before {
	content: "\F04B"; }
  
  .fa-font::before {
	content: "\F031"; }
  
  .fa-cup-togo::before {
	content: "\F6C5"; }
  
  .fa-coffee-togo::before {
	content: "\F6C5"; }
  
  .fa-square-down-left::before {
	content: "\E26B"; }
  
  .fa-burger-lettuce::before {
	content: "\E3E3"; }
  
  .fa-rupiah-sign::before {
	content: "\E23D"; }
  
  .fa-magnifying-glass::before {
	content: "\F002"; }
  
  .fa-search::before {
	content: "\F002"; }
  
  .fa-table-tennis-paddle-ball::before {
	content: "\F45D"; }
  
  .fa-ping-pong-paddle-ball::before {
	content: "\F45D"; }
  
  .fa-table-tennis::before {
	content: "\F45D"; }
  
  .fa-person-dots-from-line::before {
	content: "\F470"; }
  
  .fa-diagnoses::before {
	content: "\F470"; }
  
  .fa-chevrons-down::before {
	content: "\F322"; }
  
  .fa-chevron-double-down::before {
	content: "\F322"; }
  
  .fa-trash-can-arrow-up::before {
	content: "\F82A"; }
  
  .fa-trash-restore-alt::before {
	content: "\F82A"; }
  
  .fa-signal-good::before {
	content: "\F68E"; }
  
  .fa-signal-3::before {
	content: "\F68E"; }
  
  .fa-location-question::before {
	content: "\F60B"; }
  
  .fa-map-marker-question::before {
	content: "\F60B"; }
  
  .fa-floppy-disk-circle-xmark::before {
	content: "\E181"; }
  
  .fa-floppy-disk-times::before {
	content: "\E181"; }
  
  .fa-save-circle-xmark::before {
	content: "\E181"; }
  
  .fa-save-times::before {
	content: "\E181"; }
  
  .fa-naira-sign::before {
	content: "\E1F6"; }
  
  .fa-peach::before {
	content: "\E20B"; }
  
  .fa-taxi-bus::before {
	content: "\E298"; }
  
  .fa-bracket-curly::before {
	content: "{"; }
  
  .fa-bracket-curly-left::before {
	content: "{"; }
  
  .fa-lobster::before {
	content: "\E421"; }
  
  .fa-cart-flatbed-empty::before {
	content: "\F476"; }
  
  .fa-dolly-flatbed-empty::before {
	content: "\F476"; }
  
  .fa-colon::before {
	content: ":"; }
  
  .fa-cart-arrow-down::before {
	content: "\F218"; }
  
  .fa-wand::before {
	content: "\F72A"; }
  
  .fa-walkie-talkie::before {
	content: "\F8EF"; }
  
  .fa-file-pen::before {
	content: "\F31C"; }
  
  .fa-file-edit::before {
	content: "\F31C"; }
  
  .fa-receipt::before {
	content: "\F543"; }
  
  .fa-table-picnic::before {
	content: "\E32D"; }
  
  .fa-square-pen::before {
	content: "\F14B"; }
  
  .fa-pen-square::before {
	content: "\F14B"; }
  
  .fa-pencil-square::before {
	content: "\F14B"; }
  
  .fa-circle-microphone-lines::before {
	content: "\E117"; }
  
  .fa-microphone-circle-alt::before {
	content: "\E117"; }
  
  .fa-display-slash::before {
	content: "\E2FA"; }
  
  .fa-desktop-slash::before {
	content: "\E2FA"; }
  
  .fa-suitcase-rolling::before {
	content: "\F5C1"; }
  
  .fa-person-circle-exclamation::before {
	content: "\E53F"; }
  
  .fa-transporter-2::before {
	content: "\E044"; }
  
  .fa-hands-holding-diamond::before {
	content: "\F47C"; }
  
  .fa-hand-receiving::before {
	content: "\F47C"; }
  
  .fa-money-bill-simple-wave::before {
	content: "\E1F2"; }
  
  .fa-chevron-down::before {
	content: "\F078"; }
  
  .fa-battery-full::before {
	content: "\F240"; }
  
  .fa-battery::before {
	content: "\F240"; }
  
  .fa-battery-5::before {
	content: "\F240"; }
  
  .fa-bell-plus::before {
	content: "\F849"; }
  
  .fa-book-arrow-right::before {
	content: "\E0B9"; }
  
  .fa-hospitals::before {
	content: "\F80E"; }
  
  .fa-club::before {
	content: "\F327"; }
  
  .fa-skull-crossbones::before {
	content: "\F714"; }
  
  .fa-droplet-degree::before {
	content: "\F748"; }
  
  .fa-dewpoint::before {
	content: "\F748"; }
  
  .fa-code-compare::before {
	content: "\E13A"; }
  
  .fa-list-ul::before {
	content: "\F0CA"; }
  
  .fa-list-dots::before {
	content: "\F0CA"; }
  
  .fa-hand-holding-magic::before {
	content: "\F6E5"; }
  
  .fa-watermelon-slice::before {
	content: "\E337"; }
  
  .fa-circle-ellipsis::before {
	content: "\E10A"; }
  
  .fa-school-lock::before {
	content: "\E56F"; }
  
  .fa-tower-cell::before {
	content: "\E585"; }
  
  .fa-sd-cards::before {
	content: "\E240"; }
  
  .fa-down-long::before {
	content: "\F309"; }
  
  .fa-long-arrow-alt-down::before {
	content: "\F309"; }
  
  .fa-envelopes::before {
	content: "\E170"; }
  
  .fa-phone-office::before {
	content: "\F67D"; }
  
  .fa-ranking-star::before {
	content: "\E561"; }
  
  .fa-chess-king::before {
	content: "\F43F"; }
  
  .fa-nfc-pen::before {
	content: "\E1FA"; }
  
  .fa-person-harassing::before {
	content: "\E549"; }
  
  .fa-hat-winter::before {
	content: "\F7A8"; }
  
  .fa-brazilian-real-sign::before {
	content: "\E46C"; }
  
  .fa-landmark-dome::before {
	content: "\F752"; }
  
  .fa-landmark-alt::before {
	content: "\F752"; }
  
  .fa-bone-break::before {
	content: "\F5D8"; }
  
  .fa-arrow-up::before {
	content: "\F062"; }
  
  .fa-down-from-dotted-line::before {
	content: "\E407"; }
  
  .fa-tv::before {
	content: "\F26C"; }
  
  .fa-television::before {
	content: "\F26C"; }
  
  .fa-tv-alt::before {
	content: "\F26C"; }
  
  .fa-border-left::before {
	content: "\F84F"; }
  
  .fa-circle-divide::before {
	content: "\E106"; }
  
  .fa-shrimp::before {
	content: "\E448"; }
  
  .fa-list-check::before {
	content: "\F0AE"; }
  
  .fa-tasks::before {
	content: "\F0AE"; }
  
  .fa-diagram-subtask::before {
	content: "\E479"; }
  
  .fa-jug-detergent::before {
	content: "\E519"; }
  
  .fa-circle-user::before {
	content: "\F2BD"; }
  
  .fa-user-circle::before {
	content: "\F2BD"; }
  
  .fa-square-y::before {
	content: "\E287"; }
  
  .fa-user-doctor-hair::before {
	content: "\E458"; }
  
  .fa-planet-ringed::before {
	content: "\E020"; }
  
  .fa-mushroom::before {
	content: "\E425"; }
  
  .fa-user-shield::before {
	content: "\F505"; }
  
  .fa-megaphone::before {
	content: "\F675"; }
  
  .fa-circle-exclamation-check::before {
	content: "\E10D"; }
  
  .fa-wind::before {
	content: "\F72E"; }
  
  .fa-box-dollar::before {
	content: "\F4A0"; }
  
  .fa-box-usd::before {
	content: "\F4A0"; }
  
  .fa-car-burst::before {
	content: "\F5E1"; }
  
  .fa-car-crash::before {
	content: "\F5E1"; }
  
  .fa-y::before {
	content: "Y"; }
  
  .fa-user-headset::before {
	content: "\F82D"; }
  
  .fa-arrows-retweet::before {
	content: "\F361"; }
  
  .fa-retweet-alt::before {
	content: "\F361"; }
  
  .fa-person-snowboarding::before {
	content: "\F7CE"; }
  
  .fa-snowboarding::before {
	content: "\F7CE"; }
  
  .fa-square-chevron-right::before {
	content: "\F32B"; }
  
  .fa-chevron-square-right::before {
	content: "\F32B"; }
  
  .fa-lacrosse-stick-ball::before {
	content: "\E3B6"; }
  
  .fa-truck-fast::before {
	content: "\F48B"; }
  
  .fa-shipping-fast::before {
	content: "\F48B"; }
  
  .fa-star-sharp::before {
	content: "\E28B"; }
  
  .fa-circle-1::before {
	content: "\E0EE"; }
  
  .fa-circle-star::before {
	content: "\E123"; }
  
  .fa-star-circle::before {
	content: "\E123"; }
  
  .fa-fish::before {
	content: "\F578"; }
  
  .fa-cloud-fog::before {
	content: "\F74E"; }
  
  .fa-fog::before {
	content: "\F74E"; }
  
  .fa-waffle::before {
	content: "\E466"; }
  
  .fa-music-note::before {
	content: "\F8CF"; }
  
  .fa-music-alt::before {
	content: "\F8CF"; }
  
  .fa-hexagon-exclamation::before {
	content: "\E417"; }
  
  .fa-cart-shopping-fast::before {
	content: "\E0DC"; }
  
  .fa-object-union::before {
	content: "\E49F"; }
  
  .fa-user-graduate::before {
	content: "\F501"; }
  
  .fa-starfighter::before {
	content: "\E037"; }
  
  .fa-circle-half-stroke::before {
	content: "\F042"; }
  
  .fa-adjust::before {
	content: "\F042"; }
  
  .fa-arrow-right-long-to-line::before {
	content: "\E3D5"; }
  
  .fa-square-arrow-down::before {
	content: "\F339"; }
  
  .fa-arrow-square-down::before {
	content: "\F339"; }
  
  .fa-clapperboard::before {
	content: "\E131"; }
  
  .fa-square-chevron-left::before {
	content: "\F32A"; }
  
  .fa-chevron-square-left::before {
	content: "\F32A"; }
  
  .fa-phone-intercom::before {
	content: "\E434"; }
  
  .fa-link-horizontal::before {
	content: "\E1CB"; }
  
  .fa-chain-horizontal::before {
	content: "\E1CB"; }
  
  .fa-mango::before {
	content: "\E30F"; }
  
  .fa-music-note-slash::before {
	content: "\F8D0"; }
  
  .fa-music-alt-slash::before {
	content: "\F8D0"; }
  
  .fa-circle-radiation::before {
	content: "\F7BA"; }
  
  .fa-radiation-alt::before {
	content: "\F7BA"; }
  
  .fa-face-tongue-sweat::before {
	content: "\E39E"; }
  
  .fa-globe-stand::before {
	content: "\F5F6"; }
  
  .fa-baseball::before {
	content: "\F433"; }
  
  .fa-baseball-ball::before {
	content: "\F433"; }
  
  .fa-circle-p::before {
	content: "\E11A"; }
  
  .fa-award-simple::before {
	content: "\E0AB"; }
  
  .fa-jet-fighter-up::before {
	content: "\E518"; }
  
  .fa-diagram-project::before {
	content: "\F542"; }
  
  .fa-project-diagram::before {
	content: "\F542"; }
  
  .fa-pedestal::before {
	content: "\E20D"; }
  
  .fa-chart-pyramid::before {
	content: "\E0E6"; }
  
  .fa-sidebar::before {
	content: "\E24E"; }
  
  .fa-snowman-head::before {
	content: "\F79B"; }
  
  .fa-frosty-head::before {
	content: "\F79B"; }
  
  .fa-copy::before {
	content: "\F0C5"; }
  
  .fa-burger-glass::before {
	content: "\E0CE"; }
  
  .fa-volume-xmark::before {
	content: "\F6A9"; }
  
  .fa-volume-mute::before {
	content: "\F6A9"; }
  
  .fa-volume-times::before {
	content: "\F6A9"; }
  
  .fa-hand-sparkles::before {
	content: "\E05D"; }
  
  .fa-bars-filter::before {
	content: "\E0AD"; }
  
  .fa-paintbrush-pencil::before {
	content: "\E206"; }
  
  .fa-party-bell::before {
	content: "\E31A"; }
  
  .fa-user-vneck-hair::before {
	content: "\E462"; }
  
  .fa-jack-o-lantern::before {
	content: "\F30E"; }
  
  .fa-grip::before {
	content: "\F58D"; }
  
  .fa-grip-horizontal::before {
	content: "\F58D"; }
  
  .fa-share-from-square::before {
	content: "\F14D"; }
  
  .fa-share-square::before {
	content: "\F14D"; }
  
  .fa-keynote::before {
	content: "\F66C"; }
  
  .fa-gun::before {
	content: "\E19B"; }
  
  .fa-square-phone::before {
	content: "\F098"; }
  
  .fa-phone-square::before {
	content: "\F098"; }
  
  .fa-plus::before {
	content: "+"; }
  
  .fa-add::before {
	content: "+"; }
  
  .fa-expand::before {
	content: "\F065"; }
  
  .fa-computer::before {
	content: "\E4E5"; }
  
  .fa-fort::before {
	content: "\E486"; }
  
  .fa-cloud-check::before {
	content: "\E35C"; }
  
  .fa-xmark::before {
	content: "\F00D"; }
  
  .fa-close::before {
	content: "\F00D"; }
  
  .fa-multiply::before {
	content: "\F00D"; }
  
  .fa-remove::before {
	content: "\F00D"; }
  
  .fa-times::before {
	content: "\F00D"; }
  
  .fa-face-smirking::before {
	content: "\E397"; }
  
  .fa-arrows-up-down-left-right::before {
	content: "\F047"; }
  
  .fa-arrows::before {
	content: "\F047"; }
  
  .fa-chalkboard-user::before {
	content: "\F51C"; }
  
  .fa-chalkboard-teacher::before {
	content: "\F51C"; }
  
  .fa-rhombus::before {
	content: "\E23B"; }
  
  .fa-claw-marks::before {
	content: "\F6C2"; }
  
  .fa-peso-sign::before {
	content: "\E222"; }
  
  .fa-face-smile-tongue::before {
	content: "\E394"; }
  
  .fa-cart-circle-xmark::before {
	content: "\E3F4"; }
  
  .fa-building-shield::before {
	content: "\E4D8"; }
  
  .fa-circle-phone-flip::before {
	content: "\E11C"; }
  
  .fa-phone-circle-alt::before {
	content: "\E11C"; }
  
  .fa-baby::before {
	content: "\F77C"; }
  
  .fa-users-line::before {
	content: "\E592"; }
  
  .fa-quote-left::before {
	content: "\F10D"; }
  
  .fa-quote-left-alt::before {
	content: "\F10D"; }
  
  .fa-tractor::before {
	content: "\F722"; }
  
  .fa-key-skeleton::before {
	content: "\F6F3"; }
  
  .fa-trash-arrow-up::before {
	content: "\F829"; }
  
  .fa-trash-restore::before {
	content: "\F829"; }
  
  .fa-arrow-down-up-lock::before {
	content: "\E4B0"; }
  
  .fa-arrow-down-to-bracket::before {
	content: "\E094"; }
  
  .fa-lines-leaning::before {
	content: "\E51E"; }
  
  .fa-square-q::before {
	content: "\E27B"; }
  
  .fa-ruler-combined::before {
	content: "\F546"; }
  
  .fa-symbols::before {
	content: "\F86E"; }
  
  .fa-icons-alt::before {
	content: "\F86E"; }
  
  .fa-copyright::before {
	content: "\F1F9"; }
  
  .fa-highlighter-line::before {
	content: "\E1AF"; }
  
  .fa-bracket-square::before {
	content: "["; }
  
  .fa-bracket::before {
	content: "["; }
  
  .fa-bracket-left::before {
	content: "["; }
  
  .fa-island-tropical::before {
	content: "\F811"; }
  
  .fa-island-tree-palm::before {
	content: "\F811"; }
  
  .fa-arrow-right-from-line::before {
	content: "\F343"; }
  
  .fa-arrow-from-left::before {
	content: "\F343"; }
  
  .fa-h2::before {
	content: "\F314"; }
  
  .fa-equals::before {
	content: "="; }
  
  .fa-cake-slice::before {
	content: "\E3E5"; }
  
  .fa-shortcake::before {
	content: "\E3E5"; }
  
  .fa-peanut::before {
	content: "\E430"; }
  
  .fa-wrench-simple::before {
	content: "\E2D1"; }
  
  .fa-blender::before {
	content: "\F517"; }
  
  .fa-teeth::before {
	content: "\F62E"; }
  
  .fa-tally-2::before {
	content: "\E295"; }
  
  .fa-shekel-sign::before {
	content: "\F20B"; }
  
  .fa-ils::before {
	content: "\F20B"; }
  
  .fa-shekel::before {
	content: "\F20B"; }
  
  .fa-sheqel::before {
	content: "\F20B"; }
  
  .fa-sheqel-sign::before {
	content: "\F20B"; }
  
  .fa-cars::before {
	content: "\F85B"; }
  
  .fa-axe-battle::before {
	content: "\F6B3"; }
  
  .fa-user-hair-long::before {
	content: "\E45B"; }
  
  .fa-map::before {
	content: "\F279"; }
  
  .fa-file-circle-info::before {
	content: "\E493"; }
  
  .fa-face-disappointed::before {
	content: "\E36F"; }
  
  .fa-lasso-sparkles::before {
	content: "\E1C9"; }
  
  .fa-clock-eleven::before {
	content: "\E347"; }
  
  .fa-rocket::before {
	content: "\F135"; }
  
  .fa-siren-on::before {
	content: "\E02E"; }
  
  .fa-clock-ten::before {
	content: "\E354"; }
  
  .fa-candle-holder::before {
	content: "\F6BC"; }
  
  .fa-video-arrow-down-left::before {
	content: "\E2C8"; }
  
  .fa-photo-film::before {
	content: "\F87C"; }
  
  .fa-photo-video::before {
	content: "\F87C"; }
  
  .fa-floppy-disk-circle-arrow-right::before {
	content: "\E180"; }
  
  .fa-save-circle-arrow-right::before {
	content: "\E180"; }
  
  .fa-folder-minus::before {
	content: "\F65D"; }
  
  .fa-planet-moon::before {
	content: "\E01F"; }
  
  .fa-face-eyes-xmarks::before {
	content: "\E374"; }
  
  .fa-chart-scatter::before {
	content: "\F7EE"; }
  
  .fa-display-arrow-down::before {
	content: "\E164"; }
  
  .fa-store::before {
	content: "\F54E"; }
  
  .fa-arrow-trend-up::before {
	content: "\E098"; }
  
  .fa-plug-circle-minus::before {
	content: "\E55E"; }
  
  .fa-olive-branch::before {
	content: "\E317"; }
  
  .fa-angle::before {
	content: "\E08C"; }
  
  .fa-vacuum-robot::before {
	content: "\E04E"; }
  
  .fa-sign-hanging::before {
	content: "\F4D9"; }
  
  .fa-sign::before {
	content: "\F4D9"; }
  
  .fa-square-divide::before {
	content: "\E26A"; }
  
  .fa-signal-stream-slash::before {
	content: "\E250"; }
  
  .fa-bezier-curve::before {
	content: "\F55B"; }
  
  .fa-eye-dropper-half::before {
	content: "\E173"; }
  
  .fa-store-lock::before {
	content: "\E4A6"; }
  
  .fa-bell-slash::before {
	content: "\F1F6"; }
  
  .fa-cloud-bolt-sun::before {
	content: "\F76E"; }
  
  .fa-thunderstorm-sun::before {
	content: "\F76E"; }
  
  .fa-camera-slash::before {
	content: "\E0D9"; }
  
  .fa-comment-quote::before {
	content: "\E14C"; }
  
  .fa-tablet::before {
	content: "\F3FB"; }
  
  .fa-tablet-android::before {
	content: "\F3FB"; }
  
  .fa-school-flag::before {
	content: "\E56E"; }
  
  .fa-message-code::before {
	content: "\E1DF"; }
  
  .fa-glass-half::before {
	content: "\E192"; }
  
  .fa-glass-half-empty::before {
	content: "\E192"; }
  
  .fa-glass-half-full::before {
	content: "\E192"; }
  
  .fa-fill::before {
	content: "\F575"; }
  
  .fa-message-minus::before {
	content: "\F4A7"; }
  
  .fa-comment-alt-minus::before {
	content: "\F4A7"; }
  
  .fa-angle-up::before {
	content: "\F106"; }
  
  .fa-drumstick-bite::before {
	content: "\F6D7"; }
  
  .fa-link-horizontal-slash::before {
	content: "\E1CC"; }
  
  .fa-chain-horizontal-slash::before {
	content: "\E1CC"; }
  
  .fa-holly-berry::before {
	content: "\F7AA"; }
  
  .fa-chevron-left::before {
	content: "\F053"; }
  
  .fa-bacteria::before {
	content: "\E059"; }
  
  .fa-clouds::before {
	content: "\F744"; }
  
  .fa-money-bill-simple::before {
	content: "\E1F1"; }
  
  .fa-hand-lizard::before {
	content: "\F258"; }
  
  .fa-table-pivot::before {
	content: "\E291"; }
  
  .fa-filter-slash::before {
	content: "\E17D"; }
  
  .fa-trash-can-undo::before {
	content: "\F896"; }
  
  .fa-trash-can-arrow-turn-left::before {
	content: "\F896"; }
  
  .fa-trash-undo-alt::before {
	content: "\F896"; }
  
  .fa-notdef::before {
	content: "\E1FE"; }
  
  .fa-disease::before {
	content: "\F7FA"; }
  
  .fa-person-to-door::before {
	content: "\E433"; }
  
  .fa-turntable::before {
	content: "\F8E4"; }
  
  .fa-briefcase-medical::before {
	content: "\F469"; }
  
  .fa-genderless::before {
	content: "\F22D"; }
  
  .fa-chevron-right::before {
	content: "\F054"; }
  
  .fa-signal-weak::before {
	content: "\F68C"; }
  
  .fa-signal-1::before {
	content: "\F68C"; }
  
  .fa-clock-five::before {
	content: "\E349"; }
  
  .fa-retweet::before {
	content: "\F079"; }
  
  .fa-car-rear::before {
	content: "\F5DE"; }
  
  .fa-car-alt::before {
	content: "\F5DE"; }
  
  .fa-pump-soap::before {
	content: "\E06B"; }
  
  .fa-computer-classic::before {
	content: "\F8B1"; }
  
  .fa-frame::before {
	content: "\E495"; }
  
  .fa-video-slash::before {
	content: "\F4E2"; }
  
  .fa-battery-quarter::before {
	content: "\F243"; }
  
  .fa-battery-2::before {
	content: "\F243"; }
  
  .fa-ellipsis-stroke::before {
	content: "\F39B"; }
  
  .fa-ellipsis-h-alt::before {
	content: "\F39B"; }
  
  .fa-radio::before {
	content: "\F8D7"; }
  
  .fa-baby-carriage::before {
	content: "\F77D"; }
  
  .fa-carriage-baby::before {
	content: "\F77D"; }
  
  .fa-face-expressionless::before {
	content: "\E373"; }
  
  .fa-down-to-dotted-line::before {
	content: "\E408"; }
  
  .fa-cloud-music::before {
	content: "\F8AE"; }
  
  .fa-traffic-light::before {
	content: "\F637"; }
  
  .fa-cloud-minus::before {
	content: "\E35D"; }
  
  .fa-thermometer::before {
	content: "\F491"; }
  
  .fa-shield-minus::before {
	content: "\E249"; }
  
  .fa-vr-cardboard::before {
	content: "\F729"; }
  
  .fa-car-tilt::before {
	content: "\F5E5"; }
  
  .fa-gauge-circle-minus::before {
	content: "\E497"; }
  
  .fa-brightness-low::before {
	content: "\E0CA"; }
  
  .fa-hand-middle-finger::before {
	content: "\F806"; }
  
  .fa-percent::before {
	content: "%"; }
  
  .fa-percentage::before {
	content: "%"; }
  
  .fa-truck-moving::before {
	content: "\F4DF"; }
  
  .fa-glass-water-droplet::before {
	content: "\E4F5"; }
  
  .fa-conveyor-belt::before {
	content: "\F46E"; }
  
  .fa-location-check::before {
	content: "\F606"; }
  
  .fa-map-marker-check::before {
	content: "\F606"; }
  
  .fa-coin-vertical::before {
	content: "\E3FD"; }
  
  .fa-display::before {
	content: "\E163"; }
  
  .fa-person-sign::before {
	content: "\F757"; }
  
  .fa-face-smile::before {
	content: "\F118"; }
  
  .fa-smile::before {
	content: "\F118"; }
  
  .fa-phone-hangup::before {
	content: "\E225"; }
  
  .fa-signature-slash::before {
	content: "\E3CB"; }
  
  .fa-thumbtack::before {
	content: "\F08D"; }
  
  .fa-thumb-tack::before {
	content: "\F08D"; }
  
  .fa-wheat-slash::before {
	content: "\E339"; }
  
  .fa-trophy::before {
	content: "\F091"; }
  
  .fa-clouds-sun::before {
	content: "\F746"; }
  
  .fa-person-praying::before {
	content: "\F683"; }
  
  .fa-pray::before {
	content: "\F683"; }
  
  .fa-hammer::before {
	content: "\F6E3"; }
  
  .fa-face-vomit::before {
	content: "\E3A0"; }
  
  .fa-speakers::before {
	content: "\F8E0"; }
  
  .fa-tty-answer::before {
	content: "\E2B9"; }
  
  .fa-teletype-answer::before {
	content: "\E2B9"; }
  
  .fa-mug-tea-saucer::before {
	content: "\E1F5"; }
  
  .fa-diagram-lean-canvas::before {
	content: "\E156"; }
  
  .fa-alt::before {
	content: "\E08A"; }
  
  .fa-dial::before {
	content: "\E15B"; }
  
  .fa-dial-med-high::before {
	content: "\E15B"; }
  
  .fa-hand-peace::before {
	content: "\F25B"; }
  
  .fa-circle-trash::before {
	content: "\E126"; }
  
  .fa-trash-circle::before {
	content: "\E126"; }
  
  .fa-rotate::before {
	content: "\F2F1"; }
  
  .fa-sync-alt::before {
	content: "\F2F1"; }
  
  .fa-circle-quarters::before {
	content: "\E3F8"; }
  
  .fa-spinner::before {
	content: "\F110"; }
  
  .fa-tower-control::before {
	content: "\E2A2"; }
  
  .fa-arrow-up-triangle-square::before {
	content: "\F88A"; }
  
  .fa-sort-shapes-up::before {
	content: "\F88A"; }
  
  .fa-whale::before {
	content: "\F72C"; }
  
  .fa-robot::before {
	content: "\F544"; }
  
  .fa-peace::before {
	content: "\F67C"; }
  
  .fa-party-horn::before {
	content: "\E31B"; }
  
  .fa-gears::before {
	content: "\F085"; }
  
  .fa-cogs::before {
	content: "\F085"; }
  
  .fa-sun-bright::before {
	content: "\E28F"; }
  
  .fa-sun-alt::before {
	content: "\E28F"; }
  
  .fa-warehouse::before {
	content: "\F494"; }
  
  .fa-lock-keyhole-open::before {
	content: "\F3C2"; }
  
  .fa-lock-open-alt::before {
	content: "\F3C2"; }
  
  .fa-square-fragile::before {
	content: "\F49B"; }
  
  .fa-box-fragile::before {
	content: "\F49B"; }
  
  .fa-square-wine-glass-crack::before {
	content: "\F49B"; }
  
  .fa-arrow-up-right-dots::before {
	content: "\E4B7"; }
  
  .fa-square-n::before {
	content: "\E277"; }
  
  .fa-splotch::before {
	content: "\F5BC"; }
  
  .fa-face-grin-hearts::before {
	content: "\F584"; }
  
  .fa-grin-hearts::before {
	content: "\F584"; }
  
  .fa-meter::before {
	content: "\E1E8"; }
  
  .fa-mandolin::before {
	content: "\F6F9"; }
  
  .fa-dice-four::before {
	content: "\F524"; }
  
  .fa-sim-card::before {
	content: "\F7C4"; }
  
  .fa-transgender::before {
	content: "\F225"; }
  
  .fa-transgender-alt::before {
	content: "\F225"; }
  
  .fa-mercury::before {
	content: "\F223"; }
  
  .fa-up-from-bracket::before {
	content: "\E590"; }
  
  .fa-knife-kitchen::before {
	content: "\F6F5"; }
  
  .fa-border-right::before {
	content: "\F852"; }
  
  .fa-arrow-turn-down::before {
	content: "\F149"; }
  
  .fa-level-down::before {
	content: "\F149"; }
  
  .fa-spade::before {
	content: "\F2F4"; }
  
  .fa-card-spade::before {
	content: "\E3EC"; }
  
  .fa-line-columns::before {
	content: "\F870"; }
  
  .fa-arrow-right-to-line::before {
	content: "\F340"; }
  
  .fa-arrow-to-right::before {
	content: "\F340"; }
  
  .fa-person-falling-burst::before {
	content: "\E547"; }
  
  .fa-flag-pennant::before {
	content: "\F456"; }
  
  .fa-pennant::before {
	content: "\F456"; }
  
  .fa-conveyor-belt-empty::before {
	content: "\E150"; }
  
  .fa-award::before {
	content: "\F559"; }
  
  .fa-ticket-simple::before {
	content: "\F3FF"; }
  
  .fa-ticket-alt::before {
	content: "\F3FF"; }
  
  .fa-building::before {
	content: "\F1AD"; }
  
  .fa-angles-left::before {
	content: "\F100"; }
  
  .fa-angle-double-left::before {
	content: "\F100"; }
  
  .fa-camcorder::before {
	content: "\F8A8"; }
  
  .fa-video-handheld::before {
	content: "\F8A8"; }
  
  .fa-pancakes::before {
	content: "\E42D"; }
  
  .fa-album-circle-user::before {
	content: "\E48D"; }
  
  .fa-qrcode::before {
	content: "\F029"; }
  
  .fa-dice-d10::before {
	content: "\F6CD"; }
  
  .fa-fireplace::before {
	content: "\F79A"; }
  
  .fa-browser::before {
	content: "\F37E"; }
  
  .fa-pen-paintbrush::before {
	content: "\F618"; }
  
  .fa-pencil-paintbrush::before {
	content: "\F618"; }
  
  .fa-fish-cooked::before {
	content: "\F7FE"; }
  
  .fa-chair-office::before {
	content: "\F6C1"; }
  
  .fa-nesting-dolls::before {
	content: "\E3BA"; }
  
  .fa-clock-rotate-left::before {
	content: "\F1DA"; }
  
  .fa-history::before {
	content: "\F1DA"; }
  
  .fa-trumpet::before {
	content: "\F8E3"; }
  
  .fa-face-grin-beam-sweat::before {
	content: "\F583"; }
  
  .fa-grin-beam-sweat::before {
	content: "\F583"; }
  
  .fa-fire-smoke::before {
	content: "\F74B"; }
  
  .fa-phone-missed::before {
	content: "\E226"; }
  
  .fa-file-export::before {
	content: "\F56E"; }
  
  .fa-arrow-right-from-file::before {
	content: "\F56E"; }
  
  .fa-shield::before {
	content: "\F132"; }
  
  .fa-shield-blank::before {
	content: "\F132"; }
  
  .fa-arrow-up-short-wide::before {
	content: "\F885"; }
  
  .fa-sort-amount-up-alt::before {
	content: "\F885"; }
  
  .fa-arrows-repeat-1::before {
	content: "\F366"; }
  
  .fa-repeat-1-alt::before {
	content: "\F366"; }
  
  .fa-gun-slash::before {
	content: "\E19C"; }
  
  .fa-avocado::before {
	content: "\E0AA"; }
  
  .fa-binary::before {
	content: "\E33B"; }
  
  .fa-glasses-round::before {
	content: "\F5F5"; }
  
  .fa-glasses-alt::before {
	content: "\F5F5"; }
  
  .fa-phone-plus::before {
	content: "\F4D2"; }
  
  .fa-ditto::before {
	content: "\""; }
  
  .fa-person-seat::before {
	content: "\E21E"; }
  
  .fa-house-medical::before {
	content: "\E3B2"; }
  
  .fa-golf-ball-tee::before {
	content: "\F450"; }
  
  .fa-golf-ball::before {
	content: "\F450"; }
  
  .fa-circle-chevron-left::before {
	content: "\F137"; }
  
  .fa-chevron-circle-left::before {
	content: "\F137"; }
  
  .fa-house-chimney-window::before {
	content: "\E00D"; }
  
  .fa-scythe::before {
	content: "\F710"; }
  
  .fa-pen-nib::before {
	content: "\F5AD"; }
  
  .fa-ban-parking::before {
	content: "\F616"; }
  
  .fa-parking-circle-slash::before {
	content: "\F616"; }
  
  .fa-tent-arrow-turn-left::before {
	content: "\E580"; }
  
  .fa-face-diagonal-mouth::before {
	content: "\E47E"; }
  
  .fa-diagram-cells::before {
	content: "\E475"; }
  
  .fa-cricket-bat-ball::before {
	content: "\F449"; }
  
  .fa-cricket::before {
	content: "\F449"; }
  
  .fa-tents::before {
	content: "\E582"; }
  
  .fa-wand-magic::before {
	content: "\F0D0"; }
  
  .fa-magic::before {
	content: "\F0D0"; }
  
  .fa-dog::before {
	content: "\F6D3"; }
  
  .fa-pen-line::before {
	content: "\E212"; }
  
  .fa-atom-simple::before {
	content: "\F5D3"; }
  
  .fa-atom-alt::before {
	content: "\F5D3"; }
  
  .fa-ampersand::before {
	content: "&"; }
  
  .fa-carrot::before {
	content: "\F787"; }
  
  .fa-arrow-up-from-line::before {
	content: "\F342"; }
  
  .fa-arrow-from-bottom::before {
	content: "\F342"; }
  
  .fa-moon::before {
	content: "\F186"; }
  
  .fa-pen-slash::before {
	content: "\E213"; }
  
  .fa-wine-glass-empty::before {
	content: "\F5CE"; }
  
  .fa-wine-glass-alt::before {
	content: "\F5CE"; }
  
  .fa-square-star::before {
	content: "\E27F"; }
  
  .fa-cheese::before {
	content: "\F7EF"; }
  
  .fa-send-backward::before {
	content: "\F87F"; }
  
  .fa-yin-yang::before {
	content: "\F6AD"; }
  
  .fa-music::before {
	content: "\F001"; }
  
  .fa-compass-slash::before {
	content: "\F5E9"; }
  
  .fa-clock-one::before {
	content: "\E34E"; }
  
  .fa-file-music::before {
	content: "\F8B6"; }
  
  .fa-code-commit::before {
	content: "\F386"; }
  
  .fa-temperature-low::before {
	content: "\F76B"; }
  
  .fa-person-biking::before {
	content: "\F84A"; }
  
  .fa-biking::before {
	content: "\F84A"; }
  
  .fa-skeleton::before {
	content: "\F620"; }
  
  .fa-circle-g::before {
	content: "\E10F"; }
  
  .fa-circle-arrow-up-left::before {
	content: "\E0FB"; }
  
  .fa-coin-blank::before {
	content: "\E3FB"; }
  
  .fa-broom::before {
	content: "\F51A"; }
  
  .fa-vacuum::before {
	content: "\E04D"; }
  
  .fa-shield-heart::before {
	content: "\E574"; }
  
  .fa-card-heart::before {
	content: "\E3EB"; }
  
  .fa-lightbulb-cfl-on::before {
	content: "\E5A7"; }
  
  .fa-melon::before {
	content: "\E310"; }
  
  .fa-gopuram::before {
	content: "\F664"; }
  
  .fa-earth-oceania::before {
	content: "\E47B"; }
  
  .fa-globe-oceania::before {
	content: "\E47B"; }
  
  .fa-container-storage::before {
	content: "\F4B7"; }
  
  .fa-face-pouting::before {
	content: "\E387"; }
  
  .fa-square-xmark::before {
	content: "\F2D3"; }
  
  .fa-times-square::before {
	content: "\F2D3"; }
  
  .fa-xmark-square::before {
	content: "\F2D3"; }
  
  .fa-face-explode::before {
	content: "\E2FE"; }
  
  .fa-exploding-head::before {
	content: "\E2FE"; }
  
  .fa-hashtag::before {
	content: "#"; }
  
  .fa-up-right-and-down-left-from-center::before {
	content: "\F424"; }
  
  .fa-expand-alt::before {
	content: "\F424"; }
  
  .fa-oil-can::before {
	content: "\F613"; }
  
  .fa-t::before {
	content: "T"; }
  
  .fa-transformer-bolt::before {
	content: "\E2A4"; }
  
  .fa-hippo::before {
	content: "\F6ED"; }
  
  .fa-chart-column::before {
	content: "\E0E3"; }
  
  .fa-cassette-vhs::before {
	content: "\F8EC"; }
  
  .fa-vhs::before {
	content: "\F8EC"; }
  
  .fa-infinity::before {
	content: "\F534"; }
  
  .fa-vial-circle-check::before {
	content: "\E596"; }
  
  .fa-chimney::before {
	content: "\F78B"; }
  
  .fa-object-intersect::before {
	content: "\E49D"; }
  
  .fa-person-arrow-down-to-line::before {
	content: "\E538"; }
  
  .fa-voicemail::before {
	content: "\F897"; }
  
  .fa-block-brick::before {
	content: "\E3DB"; }
  
  .fa-wall-brick::before {
	content: "\E3DB"; }
  
  .fa-fan::before {
	content: "\F863"; }
  
  .fa-bags-shopping::before {
	content: "\F847"; }
  
  .fa-paragraph-left::before {
	content: "\F878"; }
  
  .fa-paragraph-rtl::before {
	content: "\F878"; }
  
  .fa-person-walking-luggage::before {
	content: "\E554"; }
  
  .fa-caravan-simple::before {
	content: "\E000"; }
  
  .fa-caravan-alt::before {
	content: "\E000"; }
  
  .fa-turtle::before {
	content: "\F726"; }
  
  .fa-up-down::before {
	content: "\F338"; }
  
  .fa-arrows-alt-v::before {
	content: "\F338"; }
  
  .fa-cloud-moon-rain::before {
	content: "\F73C"; }
  
  .fa-booth-curtain::before {
	content: "\F734"; }
  
  .fa-calendar::before {
	content: "\F133"; }
  
  .fa-box-heart::before {
	content: "\F49D"; }
  
  .fa-trailer::before {
	content: "\E041"; }
  
  .fa-user-doctor-message::before {
	content: "\F82E"; }
  
  .fa-user-md-chat::before {
	content: "\F82E"; }
  
  .fa-bahai::before {
	content: "\F666"; }
  
  .fa-haykal::before {
	content: "\F666"; }
  
  .fa-amp-guitar::before {
	content: "\F8A1"; }
  
  .fa-sd-card::before {
	content: "\F7C2"; }
  
  .fa-volume-slash::before {
	content: "\F2E2"; }
  
  .fa-border-bottom::before {
	content: "\F84D"; }
  
  .fa-wifi-weak::before {
	content: "\F6AA"; }
  
  .fa-wifi-1::before {
	content: "\F6AA"; }
  
  .fa-dragon::before {
	content: "\F6D5"; }
  
  .fa-shoe-prints::before {
	content: "\F54B"; }
  
  .fa-circle-plus::before {
	content: "\F055"; }
  
  .fa-plus-circle::before {
	content: "\F055"; }
  
  .fa-face-grin-tongue-wink::before {
	content: "\F58B"; }
  
  .fa-grin-tongue-wink::before {
	content: "\F58B"; }
  
  .fa-hand-holding::before {
	content: "\F4BD"; }
  
  .fa-plug-circle-exclamation::before {
	content: "\E55D"; }
  
  .fa-link-slash::before {
	content: "\F127"; }
  
  .fa-chain-broken::before {
	content: "\F127"; }
  
  .fa-chain-slash::before {
	content: "\F127"; }
  
  .fa-unlink::before {
	content: "\F127"; }
  
  .fa-clone::before {
	content: "\F24D"; }
  
  .fa-person-walking-arrow-loop-left::before {
	content: "\E551"; }
  
  .fa-arrow-up-z-a::before {
	content: "\F882"; }
  
  .fa-sort-alpha-up-alt::before {
	content: "\F882"; }
  
  .fa-fire-flame-curved::before {
	content: "\F7E4"; }
  
  .fa-fire-alt::before {
	content: "\F7E4"; }
  
  .fa-tornado::before {
	content: "\F76F"; }
  
  .fa-file-circle-plus::before {
	content: "\E494"; }
  
  .fa-delete-right::before {
	content: "\E154"; }
  
  .fa-book-quran::before {
	content: "\F687"; }
  
  .fa-quran::before {
	content: "\F687"; }
  
  .fa-circle-quarter::before {
	content: "\E11F"; }
  
  .fa-anchor::before {
	content: "\F13D"; }
  
  .fa-border-all::before {
	content: "\F84C"; }
  
  .fa-function::before {
	content: "\F661"; }
  
  .fa-face-angry::before {
	content: "\F556"; }
  
  .fa-angry::before {
	content: "\F556"; }
  
  .fa-people-simple::before {
	content: "\E21B"; }
  
  .fa-cookie-bite::before {
	content: "\F564"; }
  
  .fa-arrow-trend-down::before {
	content: "\E097"; }
  
  .fa-rss::before {
	content: "\F09E"; }
  
  .fa-feed::before {
	content: "\F09E"; }
  
  .fa-face-monocle::before {
	content: "\E380"; }
  
  .fa-draw-polygon::before {
	content: "\F5EE"; }
  
  .fa-scale-balanced::before {
	content: "\F24E"; }
  
  .fa-balance-scale::before {
	content: "\F24E"; }
  
  .fa-calendar-lines::before {
	content: "\E0D5"; }
  
  .fa-calendar-note::before {
	content: "\E0D5"; }
  
  .fa-arrow-down-big-small::before {
	content: "\F88C"; }
  
  .fa-sort-size-down::before {
	content: "\F88C"; }
  
  .fa-gauge-simple-high::before {
	content: "\F62A"; }
  
  .fa-tachometer::before {
	content: "\F62A"; }
  
  .fa-tachometer-fast::before {
	content: "\F62A"; }
  
  .fa-do-not-enter::before {
	content: "\F5EC"; }
  
  .fa-shower::before {
	content: "\F2CC"; }
  
  .fa-dice-d8::before {
	content: "\F6D2"; }
  
  .fa-desktop::before {
	content: "\F390"; }
  
  .fa-desktop-alt::before {
	content: "\F390"; }
  
  .fa-m::before {
	content: "M"; }
  
  .fa-grip-dots-vertical::before {
	content: "\E411"; }
  
  .fa-face-viewfinder::before {
	content: "\E2FF"; }
  
  .fa-soft-serve::before {
	content: "\E400"; }
  
  .fa-creemee::before {
	content: "\E400"; }
  
  .fa-h5::before {
	content: "\E412"; }
  
  .fa-hand-back-point-down::before {
	content: "\E19E"; }
  
  .fa-table-list::before {
	content: "\F00B"; }
  
  .fa-th-list::before {
	content: "\F00B"; }
  
  .fa-comment-sms::before {
	content: "\F7CD"; }
  
  .fa-sms::before {
	content: "\F7CD"; }
  
  .fa-rectangle::before {
	content: "\F2FA"; }
  
  .fa-rectangle-landscape::before {
	content: "\F2FA"; }
  
  .fa-clipboard-list-check::before {
	content: "\F737"; }
  
  .fa-turkey::before {
	content: "\F725"; }
  
  .fa-book::before {
	content: "\F02D"; }
  
  .fa-user-plus::before {
	content: "\F234"; }
  
  .fa-ice-skate::before {
	content: "\F7AC"; }
  
  .fa-check::before {
	content: "\F00C"; }
  
  .fa-battery-three-quarters::before {
	content: "\F241"; }
  
  .fa-battery-4::before {
	content: "\F241"; }
  
  .fa-tomato::before {
	content: "\E330"; }
  
  .fa-sword-laser::before {
	content: "\E03B"; }
  
  .fa-house-circle-check::before {
	content: "\E509"; }
  
  .fa-buildings::before {
	content: "\E0CC"; }
  
  .fa-angle-left::before {
	content: "\F104"; }
  
  .fa-cart-flatbed-boxes::before {
	content: "\F475"; }
  
  .fa-dolly-flatbed-alt::before {
	content: "\F475"; }
  
  .fa-diagram-successor::before {
	content: "\E47A"; }
  
  .fa-truck-arrow-right::before {
	content: "\E58B"; }
  
  .fa-square-w::before {
	content: "\E285"; }
  
  .fa-arrows-split-up-and-left::before {
	content: "\E4BC"; }
  
  .fa-lamp::before {
	content: "\F4CA"; }
  
  .fa-airplay::before {
	content: "\E089"; }
  
  .fa-hand-fist::before {
	content: "\F6DE"; }
  
  .fa-fist-raised::before {
	content: "\F6DE"; }
  
  .fa-shield-quartered::before {
	content: "\E575"; }
  
  .fa-slash-forward::before {
	content: "/"; }
  
  .fa-location-pen::before {
	content: "\F607"; }
  
  .fa-map-marker-edit::before {
	content: "\F607"; }
  
  .fa-cloud-moon::before {
	content: "\F6C3"; }
  
  .fa-pot-food::before {
	content: "\E43F"; }
  
  .fa-briefcase::before {
	content: "\F0B1"; }
  
  .fa-person-falling::before {
	content: "\E546"; }
  
  .fa-image-portrait::before {
	content: "\F3E0"; }
  
  .fa-portrait::before {
	content: "\F3E0"; }
  
  .fa-user-tag::before {
	content: "\F507"; }
  
  .fa-rug::before {
	content: "\E569"; }
  
  .fa-print-slash::before {
	content: "\F686"; }
  
  .fa-earth-europe::before {
	content: "\F7A2"; }
  
  .fa-globe-europe::before {
	content: "\F7A2"; }
  
  .fa-cart-flatbed-suitcase::before {
	content: "\F59D"; }
  
  .fa-luggage-cart::before {
	content: "\F59D"; }
  
  .fa-hand-back-point-ribbon::before {
	content: "\E1A0"; }
  
  .fa-rectangle-xmark::before {
	content: "\F410"; }
  
  .fa-rectangle-times::before {
	content: "\F410"; }
  
  .fa-times-rectangle::before {
	content: "\F410"; }
  
  .fa-window-close::before {
	content: "\F410"; }
  
  .fa-tire-rugged::before {
	content: "\F634"; }
  
  .fa-lightbulb-dollar::before {
	content: "\F670"; }
  
  .fa-cowbell::before {
	content: "\F8B3"; }
  
  .fa-baht-sign::before {
	content: "\E0AC"; }
  
  .fa-corner::before {
	content: "\E3FE"; }
  
  .fa-chevrons-right::before {
	content: "\F324"; }
  
  .fa-chevron-double-right::before {
	content: "\F324"; }
  
  .fa-book-open::before {
	content: "\F518"; }
  
  .fa-book-journal-whills::before {
	content: "\F66A"; }
  
  .fa-journal-whills::before {
	content: "\F66A"; }
  
  .fa-inhaler::before {
	content: "\F5F9"; }
  
  .fa-handcuffs::before {
	content: "\E4F8"; }
  
  .fa-snake::before {
	content: "\F716"; }
  
  .fa-triangle-exclamation::before {
	content: "\F071"; }
  
  .fa-exclamation-triangle::before {
	content: "\F071"; }
  
  .fa-warning::before {
	content: "\F071"; }
  
  .fa-note-medical::before {
	content: "\E200"; }
  
  .fa-database::before {
	content: "\F1C0"; }
  
  .fa-down-left::before {
	content: "\E16A"; }
  
  .fa-share::before {
	content: "\F064"; }
  
  .fa-arrow-turn-right::before {
	content: "\F064"; }
  
  .fa-mail-forward::before {
	content: "\F064"; }
  
  .fa-face-thinking::before {
	content: "\E39B"; }
  
  .fa-turn-down-right::before {
	content: "\E455"; }
  
  .fa-bottle-droplet::before {
	content: "\E4C4"; }
  
  .fa-mask-face::before {
	content: "\E1D7"; }
  
  .fa-hill-rockslide::before {
	content: "\E508"; }
  
  .fa-scanner-keyboard::before {
	content: "\F489"; }
  
  .fa-circle-o::before {
	content: "\E119"; }
  
  .fa-grid-horizontal::before {
	content: "\E307"; }
  
  .fa-message-dollar::before {
	content: "\F650"; }
  
  .fa-comment-alt-dollar::before {
	content: "\F650"; }
  
  .fa-right-left::before {
	content: "\F362"; }
  
  .fa-exchange-alt::before {
	content: "\F362"; }
  
  .fa-columns-3::before {
	content: "\E361"; }
  
  .fa-paper-plane::before {
	content: "\F1D8"; }
  
  .fa-road-circle-exclamation::before {
	content: "\E565"; }
  
  .fa-dungeon::before {
	content: "\F6D9"; }
  
  .fa-hand-holding-box::before {
	content: "\F47B"; }
  
  .fa-input-text::before {
	content: "\E1BF"; }
  
  .fa-window-flip::before {
	content: "\F40F"; }
  
  .fa-window-alt::before {
	content: "\F40F"; }
  
  .fa-align-right::before {
	content: "\F038"; }
  
  .fa-scanner-gun::before {
	content: "\F488"; }
  
  .fa-scanner::before {
	content: "\F488"; }
  
  .fa-tire::before {
	content: "\F631"; }
  
  .fa-engine::before {
	content: "\E16E"; }
  
  .fa-money-bill-1-wave::before {
	content: "\F53B"; }
  
  .fa-money-bill-wave-alt::before {
	content: "\F53B"; }
  
  .fa-life-ring::before {
	content: "\F1CD"; }
  
  .fa-hands::before {
	content: "\F2A7"; }
  
  .fa-sign-language::before {
	content: "\F2A7"; }
  
  .fa-signing::before {
	content: "\F2A7"; }
  
  .fa-circle-caret-right::before {
	content: "\F330"; }
  
  .fa-caret-circle-right::before {
	content: "\F330"; }
  
  .fa-wheat::before {
	content: "\F72D"; }
  
  .fa-file-spreadsheet::before {
	content: "\F65B"; }
  
  .fa-audio-description-slash::before {
	content: "\E0A8"; }
  
  .fa-calendar-day::before {
	content: "\F783"; }
  
  .fa-water-ladder::before {
	content: "\F5C5"; }
  
  .fa-ladder-water::before {
	content: "\F5C5"; }
  
  .fa-swimming-pool::before {
	content: "\F5C5"; }
  
  .fa-arrows-up-down::before {
	content: "\F07D"; }
  
  .fa-arrows-v::before {
	content: "\F07D"; }
  
  .fa-chess-pawn-piece::before {
	content: "\F444"; }
  
  .fa-chess-pawn-alt::before {
	content: "\F444"; }
  
  .fa-face-grimace::before {
	content: "\F57F"; }
  
  .fa-grimace::before {
	content: "\F57F"; }
  
  .fa-wheelchair-move::before {
	content: "\E2CE"; }
  
  .fa-wheelchair-alt::before {
	content: "\E2CE"; }
  
  .fa-turn-down::before {
	content: "\F3BE"; }
  
  .fa-level-down-alt::before {
	content: "\F3BE"; }
  
  .fa-square-s::before {
	content: "\E27D"; }
  
  .fa-rectangle-barcode::before {
	content: "\F463"; }
  
  .fa-barcode-alt::before {
	content: "\F463"; }
  
  .fa-person-walking-arrow-right::before {
	content: "\E552"; }
  
  .fa-square-envelope::before {
	content: "\F199"; }
  
  .fa-envelope-square::before {
	content: "\F199"; }
  
  .fa-dice::before {
	content: "\F522"; }
  
  .fa-unicorn::before {
	content: "\F727"; }
  
  .fa-bowling-ball::before {
	content: "\F436"; }
  
  .fa-pompebled::before {
	content: "\E43D"; }
  
  .fa-brain::before {
	content: "\F5DC"; }
  
  .fa-watch-smart::before {
	content: "\E2CC"; }
  
  .fa-book-user::before {
	content: "\F7E7"; }
  
  .fa-sensor-cloud::before {
	content: "\E02C"; }
  
  .fa-sensor-smoke::before {
	content: "\E02C"; }
  
  .fa-clapperboard-play::before {
	content: "\E132"; }
  
  .fa-bandage::before {
	content: "\F462"; }
  
  .fa-band-aid::before {
	content: "\F462"; }
  
  .fa-calendar-minus::before {
	content: "\F272"; }
  
  .fa-circle-xmark::before {
	content: "\F057"; }
  
  .fa-times-circle::before {
	content: "\F057"; }
  
  .fa-xmark-circle::before {
	content: "\F057"; }
  
  .fa-circle-4::before {
	content: "\E0F1"; }
  
  .fa-gifts::before {
	content: "\F79C"; }
  
  .fa-album-collection::before {
	content: "\F8A0"; }
  
  .fa-hotel::before {
	content: "\F594"; }
  
  .fa-earth-asia::before {
	content: "\F57E"; }
  
  .fa-globe-asia::before {
	content: "\F57E"; }
  
  .fa-id-card-clip::before {
	content: "\F47F"; }
  
  .fa-id-card-alt::before {
	content: "\F47F"; }
  
  .fa-magnifying-glass-plus::before {
	content: "\F00E"; }
  
  .fa-search-plus::before {
	content: "\F00E"; }
  
  .fa-thumbs-up::before {
	content: "\F164"; }
  
  .fa-cloud-showers::before {
	content: "\F73F"; }
  
  .fa-user-clock::before {
	content: "\F4FD"; }
  
  .fa-onion::before {
	content: "\E427"; }
  
  .fa-clock-twelve-thirty::before {
	content: "\E359"; }
  
  .fa-arrow-down-to-dotted-line::before {
	content: "\E095"; }
  
  .fa-hand-dots::before {
	content: "\F461"; }
  
  .fa-allergies::before {
	content: "\F461"; }
  
  .fa-file-invoice::before {
	content: "\F570"; }
  
  .fa-window-minimize::before {
	content: "\F2D1"; }
  
  .fa-rectangle-wide::before {
	content: "\F2FC"; }
  
  .fa-comment-arrow-up::before {
	content: "\E144"; }
  
  .fa-garlic::before {
	content: "\E40E"; }
  
  .fa-mug-saucer::before {
	content: "\F0F4"; }
  
  .fa-coffee::before {
	content: "\F0F4"; }
  
  .fa-brush::before {
	content: "\F55D"; }
  
  .fa-tree-decorated::before {
	content: "\F7DC"; }
  
  .fa-mask::before {
	content: "\F6FA"; }
  
  .fa-calendar-heart::before {
	content: "\E0D3"; }
  
  .fa-magnifying-glass-minus::before {
	content: "\F010"; }
  
  .fa-search-minus::before {
	content: "\F010"; }
  
  .fa-flower::before {
	content: "\F7FF"; }
  
  .fa-ruler-vertical::before {
	content: "\F548"; }
  
  .fa-user-large::before {
	content: "\F406"; }
  
  .fa-user-alt::before {
	content: "\F406"; }
  
  .fa-starship-freighter::before {
	content: "\E03A"; }
  
  .fa-train-tram::before {
	content: "\E5B4"; }
  
  .fa-bridge-suspension::before {
	content: "\E4CD"; }
  
  .fa-trash-check::before {
	content: "\E2AF"; }
  
  .fa-user-nurse::before {
	content: "\F82F"; }
  
  .fa-boombox::before {
	content: "\F8A5"; }
  
  .fa-syringe::before {
	content: "\F48E"; }
  
  .fa-cloud-sun::before {
	content: "\F6C4"; }
  
  .fa-shield-exclamation::before {
	content: "\E247"; }
  
  .fa-stopwatch-20::before {
	content: "\E06F"; }
  
  .fa-square-full::before {
	content: "\F45C"; }
  
  .fa-grip-dots::before {
	content: "\E410"; }
  
  .fa-comment-exclamation::before {
	content: "\F4AF"; }
  
  .fa-pen-swirl::before {
	content: "\E214"; }
  
  .fa-falafel::before {
	content: "\E40A"; }
  
  .fa-circle-2::before {
	content: "\E0EF"; }
  
  .fa-magnet::before {
	content: "\F076"; }
  
  .fa-jar::before {
	content: "\E516"; }
  
  .fa-gramophone::before {
	content: "\F8BD"; }
  
  .fa-dice-d12::before {
	content: "\F6CE"; }
  
  .fa-note-sticky::before {
	content: "\F249"; }
  
  .fa-sticky-note::before {
	content: "\F249"; }
  
  .fa-down::before {
	content: "\F354"; }
  
  .fa-arrow-alt-down::before {
	content: "\F354"; }
  
  .fa-hundred-points::before {
	content: "\E41C"; }
  
  .fa-100::before {
	content: "\E41C"; }
  
  .fa-paperclip-vertical::before {
	content: "\E3C2"; }
  
  .fa-wind-warning::before {
	content: "\F776"; }
  
  .fa-wind-circle-exclamation::before {
	content: "\F776"; }
  
  .fa-location-pin-slash::before {
	content: "\F60C"; }
  
  .fa-map-marker-slash::before {
	content: "\F60C"; }
  
  .fa-face-sad-sweat::before {
	content: "\E38A"; }
  
  .fa-bug-slash::before {
	content: "\E490"; }
  
  .fa-cupcake::before {
	content: "\E402"; }
  
  .fa-light-switch-off::before {
	content: "\E018"; }
  
  .fa-toggle-large-off::before {
	content: "\E5B0"; }
  
  .fa-pen-fancy-slash::before {
	content: "\E210"; }
  
  .fa-truck-container::before {
	content: "\F4DC"; }
  
  .fa-boot::before {
	content: "\F782"; }
  
  .fa-arrow-up-from-water-pump::before {
	content: "\E4B6"; }
  
  .fa-file-check::before {
	content: "\F316"; }
  
  .fa-bone::before {
	content: "\F5D7"; }
  
  .fa-cards-blank::before {
	content: "\E4DF"; }
  
  .fa-circle-3::before {
	content: "\E0F0"; }
  
  .fa-bench-tree::before {
	content: "\E2E7"; }
  
  .fa-keyboard-brightness-low::before {
	content: "\E1C1"; }
  
  .fa-ski-boot-ski::before {
	content: "\E3CD"; }
  
  .fa-brain-circuit::before {
	content: "\E0C6"; }
  
  .fa-user-injured::before {
	content: "\F728"; }
  
  .fa-block-brick-fire::before {
	content: "\E3DC"; }
  
  .fa-firewall::before {
	content: "\E3DC"; }
  
  .fa-face-sad-tear::before {
	content: "\F5B4"; }
  
  .fa-sad-tear::before {
	content: "\F5B4"; }
  
  .fa-plane::before {
	content: "\F072"; }
  
  .fa-tent-arrows-down::before {
	content: "\E581"; }
  
  .fa-exclamation::before {
	content: "!"; }
  
  .fa-arrows-spin::before {
	content: "\E4BB"; }
  
  .fa-face-smile-relaxed::before {
	content: "\E392"; }
  
  .fa-comment-xmark::before {
	content: "\F4B5"; }
  
  .fa-comment-times::before {
	content: "\F4B5"; }
  
  .fa-print::before {
	content: "\F02F"; }
  
  .fa-turkish-lira-sign::before {
	content: "\E2BB"; }
  
  .fa-try::before {
	content: "\E2BB"; }
  
  .fa-turkish-lira::before {
	content: "\E2BB"; }
  
  .fa-face-nose-steam::before {
	content: "\E382"; }
  
  .fa-circle-waveform-lines::before {
	content: "\E12D"; }
  
  .fa-waveform-circle::before {
	content: "\E12D"; }
  
  .fa-dollar-sign::before {
	content: "$"; }
  
  .fa-dollar::before {
	content: "$"; }
  
  .fa-usd::before {
	content: "$"; }
  
  .fa-ferris-wheel::before {
	content: "\E174"; }
  
  .fa-computer-speaker::before {
	content: "\F8B2"; }
  
  .fa-skull-cow::before {
	content: "\F8DE"; }
  
  .fa-x::before {
	content: "X"; }
  
  .fa-magnifying-glass-dollar::before {
	content: "\F688"; }
  
  .fa-search-dollar::before {
	content: "\F688"; }
  
  .fa-users-gear::before {
	content: "\F509"; }
  
  .fa-users-cog::before {
	content: "\F509"; }
  
  .fa-person-military-pointing::before {
	content: "\E54A"; }
  
  .fa-building-columns::before {
	content: "\F19C"; }
  
  .fa-bank::before {
	content: "\F19C"; }
  
  .fa-institution::before {
	content: "\F19C"; }
  
  .fa-museum::before {
	content: "\F19C"; }
  
  .fa-university::before {
	content: "\F19C"; }
  
  .fa-circle-t::before {
	content: "\E124"; }
  
  .fa-sack::before {
	content: "\F81C"; }
  
  .fa-grid-2::before {
	content: "\E196"; }
  
  .fa-camera-cctv::before {
	content: "\F8AC"; }
  
  .fa-cctv::before {
	content: "\F8AC"; }
  
  .fa-umbrella::before {
	content: "\F0E9"; }
  
  .fa-trowel::before {
	content: "\E589"; }
  
  .fa-horizontal-rule::before {
	content: "\F86C"; }
  
  .fa-bed-front::before {
	content: "\F8F7"; }
  
  .fa-bed-alt::before {
	content: "\F8F7"; }
  
  .fa-d::before {
	content: "D"; }
  
  .fa-stapler::before {
	content: "\E5AF"; }
  
  .fa-masks-theater::before {
	content: "\F630"; }
  
  .fa-theater-masks::before {
	content: "\F630"; }
  
  .fa-kip-sign::before {
	content: "\E1C4"; }
  
  .fa-face-woozy::before {
	content: "\E3A2"; }
  
  .fa-cloud-question::before {
	content: "\E492"; }
  
  .fa-pineapple::before {
	content: "\E31F"; }
  
  .fa-hand-point-left::before {
	content: "\F0A5"; }
  
  .fa-gallery-thumbnails::before {
	content: "\E3AA"; }
  
  .fa-circle-j::before {
	content: "\E112"; }
  
  .fa-eyes::before {
	content: "\E367"; }
  
  .fa-handshake-simple::before {
	content: "\F4C6"; }
  
  .fa-handshake-alt::before {
	content: "\F4C6"; }
  
  .fa-page-caret-up::before {
	content: "\E42A"; }
  
  .fa-file-caret-up::before {
	content: "\E42A"; }
  
  .fa-jet-fighter::before {
	content: "\F0FB"; }
  
  .fa-fighter-jet::before {
	content: "\F0FB"; }
  
  .fa-comet::before {
	content: "\E003"; }
  
  .fa-square-share-nodes::before {
	content: "\F1E1"; }
  
  .fa-share-alt-square::before {
	content: "\F1E1"; }
  
  .fa-shield-keyhole::before {
	content: "\E248"; }
  
  .fa-barcode::before {
	content: "\F02A"; }
  
  .fa-plus-minus::before {
	content: "\E43C"; }
  
  .fa-square-sliders-vertical::before {
	content: "\F3F2"; }
  
  .fa-sliders-v-square::before {
	content: "\F3F2"; }
  
  .fa-video::before {
	content: "\F03D"; }
  
  .fa-video-camera::before {
	content: "\F03D"; }
  
  .fa-message-middle::before {
	content: "\E1E1"; }
  
  .fa-comment-middle-alt::before {
	content: "\E1E1"; }
  
  .fa-graduation-cap::before {
	content: "\F19D"; }
  
  .fa-mortar-board::before {
	content: "\F19D"; }
  
  .fa-hand-holding-medical::before {
	content: "\E05C"; }
  
  .fa-person-circle-check::before {
	content: "\E53E"; }
  
  .fa-square-z::before {
	content: "\E288"; }
  
  .fa-message-text::before {
	content: "\E1E6"; }
  
  .fa-comment-alt-text::before {
	content: "\E1E6"; }
  
  .fa-turn-up::before {
	content: "\F3BF"; }
  
  .fa-level-up-alt::before {
	content: "\F3BF"; }
  
  .sr-only,
  .fa-sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border-width: 0; }
  
  .sr-only-focusable:not(:focus),
  .fa-sr-only-focusable:not(:focus) {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border-width: 0; }
  :root, :host {
	--fa-style-family-brands: 'Font Awesome 6 Brands';
	--fa-font-brands: normal 400 1em/1 'Font Awesome 6 Brands'; }
  
  @font-face {
	font-family: 'Font Awesome 6 Brands';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url(/static/media/fa-brands-400.f84deb5c.woff2) format("woff2"), url(/static/media/fa-brands-400.584b883d.ttf) format("truetype"); }
  
  .fab,
  .fa-brands {
	font-weight: 400; }
  
  .fa-monero:before {
	content: "\F3D0"; }
  
  .fa-hooli:before {
	content: "\F427"; }
  
  .fa-yelp:before {
	content: "\F1E9"; }
  
  .fa-cc-visa:before {
	content: "\F1F0"; }
  
  .fa-lastfm:before {
	content: "\F202"; }
  
  .fa-shopware:before {
	content: "\F5B5"; }
  
  .fa-creative-commons-nc:before {
	content: "\F4E8"; }
  
  .fa-aws:before {
	content: "\F375"; }
  
  .fa-redhat:before {
	content: "\F7BC"; }
  
  .fa-yoast:before {
	content: "\F2B1"; }
  
  .fa-cloudflare:before {
	content: "\E07D"; }
  
  .fa-ups:before {
	content: "\F7E0"; }
  
  .fa-wpexplorer:before {
	content: "\F2DE"; }
  
  .fa-dyalog:before {
	content: "\F399"; }
  
  .fa-bity:before {
	content: "\F37A"; }
  
  .fa-stackpath:before {
	content: "\F842"; }
  
  .fa-buysellads:before {
	content: "\F20D"; }
  
  .fa-first-order:before {
	content: "\F2B0"; }
  
  .fa-modx:before {
	content: "\F285"; }
  
  .fa-guilded:before {
	content: "\E07E"; }
  
  .fa-vnv:before {
	content: "\F40B"; }
  
  .fa-square-js:before {
	content: "\F3B9"; }
  
  .fa-js-square:before {
	content: "\F3B9"; }
  
  .fa-microsoft:before {
	content: "\F3CA"; }
  
  .fa-qq:before {
	content: "\F1D6"; }
  
  .fa-orcid:before {
	content: "\F8D2"; }
  
  .fa-java:before {
	content: "\F4E4"; }
  
  .fa-invision:before {
	content: "\F7B0"; }
  
  .fa-creative-commons-pd-alt:before {
	content: "\F4ED"; }
  
  .fa-centercode:before {
	content: "\F380"; }
  
  .fa-glide-g:before {
	content: "\F2A6"; }
  
  .fa-drupal:before {
	content: "\F1A9"; }
  
  .fa-hire-a-helper:before {
	content: "\F3B0"; }
  
  .fa-creative-commons-by:before {
	content: "\F4E7"; }
  
  .fa-unity:before {
	content: "\E049"; }
  
  .fa-whmcs:before {
	content: "\F40D"; }
  
  .fa-rocketchat:before {
	content: "\F3E8"; }
  
  .fa-vk:before {
	content: "\F189"; }
  
  .fa-untappd:before {
	content: "\F405"; }
  
  .fa-mailchimp:before {
	content: "\F59E"; }
  
  .fa-css3-alt:before {
	content: "\F38B"; }
  
  .fa-square-reddit:before {
	content: "\F1A2"; }
  
  .fa-reddit-square:before {
	content: "\F1A2"; }
  
  .fa-vimeo-v:before {
	content: "\F27D"; }
  
  .fa-contao:before {
	content: "\F26D"; }
  
  .fa-square-font-awesome:before {
	content: "\E5AD"; }
  
  .fa-deskpro:before {
	content: "\F38F"; }
  
  .fa-sistrix:before {
	content: "\F3EE"; }
  
  .fa-square-instagram:before {
	content: "\E055"; }
  
  .fa-instagram-square:before {
	content: "\E055"; }
  
  .fa-battle-net:before {
	content: "\F835"; }
  
  .fa-the-red-yeti:before {
	content: "\F69D"; }
  
  .fa-square-hacker-news:before {
	content: "\F3AF"; }
  
  .fa-hacker-news-square:before {
	content: "\F3AF"; }
  
  .fa-edge:before {
	content: "\F282"; }
  
  .fa-napster:before {
	content: "\F3D2"; }
  
  .fa-square-snapchat:before {
	content: "\F2AD"; }
  
  .fa-snapchat-square:before {
	content: "\F2AD"; }
  
  .fa-google-plus-g:before {
	content: "\F0D5"; }
  
  .fa-artstation:before {
	content: "\F77A"; }
  
  .fa-markdown:before {
	content: "\F60F"; }
  
  .fa-sourcetree:before {
	content: "\F7D3"; }
  
  .fa-google-plus:before {
	content: "\F2B3"; }
  
  .fa-diaspora:before {
	content: "\F791"; }
  
  .fa-foursquare:before {
	content: "\F180"; }
  
  .fa-stack-overflow:before {
	content: "\F16C"; }
  
  .fa-github-alt:before {
	content: "\F113"; }
  
  .fa-phoenix-squadron:before {
	content: "\F511"; }
  
  .fa-pagelines:before {
	content: "\F18C"; }
  
  .fa-algolia:before {
	content: "\F36C"; }
  
  .fa-red-river:before {
	content: "\F3E3"; }
  
  .fa-creative-commons-sa:before {
	content: "\F4EF"; }
  
  .fa-safari:before {
	content: "\F267"; }
  
  .fa-google:before {
	content: "\F1A0"; }
  
  .fa-square-font-awesome-stroke:before {
	content: "\F35C"; }
  
  .fa-font-awesome-alt:before {
	content: "\F35C"; }
  
  .fa-atlassian:before {
	content: "\F77B"; }
  
  .fa-linkedin-in:before {
	content: "\F0E1"; }
  
  .fa-digital-ocean:before {
	content: "\F391"; }
  
  .fa-nimblr:before {
	content: "\F5A8"; }
  
  .fa-chromecast:before {
	content: "\F838"; }
  
  .fa-evernote:before {
	content: "\F839"; }
  
  .fa-hacker-news:before {
	content: "\F1D4"; }
  
  .fa-creative-commons-sampling:before {
	content: "\F4F0"; }
  
  .fa-adversal:before {
	content: "\F36A"; }
  
  .fa-creative-commons:before {
	content: "\F25E"; }
  
  .fa-watchman-monitoring:before {
	content: "\E087"; }
  
  .fa-fonticons:before {
	content: "\F280"; }
  
  .fa-weixin:before {
	content: "\F1D7"; }
  
  .fa-shirtsinbulk:before {
	content: "\F214"; }
  
  .fa-codepen:before {
	content: "\F1CB"; }
  
  .fa-git-alt:before {
	content: "\F841"; }
  
  .fa-lyft:before {
	content: "\F3C3"; }
  
  .fa-rev:before {
	content: "\F5B2"; }
  
  .fa-windows:before {
	content: "\F17A"; }
  
  .fa-wizards-of-the-coast:before {
	content: "\F730"; }
  
  .fa-square-viadeo:before {
	content: "\F2AA"; }
  
  .fa-viadeo-square:before {
	content: "\F2AA"; }
  
  .fa-meetup:before {
	content: "\F2E0"; }
  
  .fa-centos:before {
	content: "\F789"; }
  
  .fa-adn:before {
	content: "\F170"; }
  
  .fa-cloudsmith:before {
	content: "\F384"; }
  
  .fa-pied-piper-alt:before {
	content: "\F1A8"; }
  
  .fa-square-dribbble:before {
	content: "\F397"; }
  
  .fa-dribbble-square:before {
	content: "\F397"; }
  
  .fa-codiepie:before {
	content: "\F284"; }
  
  .fa-node:before {
	content: "\F419"; }
  
  .fa-mix:before {
	content: "\F3CB"; }
  
  .fa-steam:before {
	content: "\F1B6"; }
  
  .fa-cc-apple-pay:before {
	content: "\F416"; }
  
  .fa-scribd:before {
	content: "\F28A"; }
  
  .fa-openid:before {
	content: "\F19B"; }
  
  .fa-instalod:before {
	content: "\E081"; }
  
  .fa-expeditedssl:before {
	content: "\F23E"; }
  
  .fa-sellcast:before {
	content: "\F2DA"; }
  
  .fa-square-twitter:before {
	content: "\F081"; }
  
  .fa-twitter-square:before {
	content: "\F081"; }
  
  .fa-r-project:before {
	content: "\F4F7"; }
  
  .fa-delicious:before {
	content: "\F1A5"; }
  
  .fa-freebsd:before {
	content: "\F3A4"; }
  
  .fa-vuejs:before {
	content: "\F41F"; }
  
  .fa-accusoft:before {
	content: "\F369"; }
  
  .fa-ioxhost:before {
	content: "\F208"; }
  
  .fa-fonticons-fi:before {
	content: "\F3A2"; }
  
  .fa-app-store:before {
	content: "\F36F"; }
  
  .fa-cc-mastercard:before {
	content: "\F1F1"; }
  
  .fa-itunes-note:before {
	content: "\F3B5"; }
  
  .fa-golang:before {
	content: "\E40F"; }
  
  .fa-kickstarter:before {
	content: "\F3BB"; }
  
  .fa-grav:before {
	content: "\F2D6"; }
  
  .fa-weibo:before {
	content: "\F18A"; }
  
  .fa-uncharted:before {
	content: "\E084"; }
  
  .fa-firstdraft:before {
	content: "\F3A1"; }
  
  .fa-square-youtube:before {
	content: "\F431"; }
  
  .fa-youtube-square:before {
	content: "\F431"; }
  
  .fa-wikipedia-w:before {
	content: "\F266"; }
  
  .fa-wpressr:before {
	content: "\F3E4"; }
  
  .fa-rendact:before {
	content: "\F3E4"; }
  
  .fa-angellist:before {
	content: "\F209"; }
  
  .fa-galactic-republic:before {
	content: "\F50C"; }
  
  .fa-nfc-directional:before {
	content: "\E530"; }
  
  .fa-skype:before {
	content: "\F17E"; }
  
  .fa-joget:before {
	content: "\F3B7"; }
  
  .fa-fedora:before {
	content: "\F798"; }
  
  .fa-stripe-s:before {
	content: "\F42A"; }
  
  .fa-meta:before {
	content: "\E49B"; }
  
  .fa-laravel:before {
	content: "\F3BD"; }
  
  .fa-hotjar:before {
	content: "\F3B1"; }
  
  .fa-bluetooth-b:before {
	content: "\F294"; }
  
  .fa-sticker-mule:before {
	content: "\F3F7"; }
  
  .fa-creative-commons-zero:before {
	content: "\F4F3"; }
  
  .fa-hips:before {
	content: "\F452"; }
  
  .fa-behance:before {
	content: "\F1B4"; }
  
  .fa-reddit:before {
	content: "\F1A1"; }
  
  .fa-discord:before {
	content: "\F392"; }
  
  .fa-chrome:before {
	content: "\F268"; }
  
  .fa-app-store-ios:before {
	content: "\F370"; }
  
  .fa-cc-discover:before {
	content: "\F1F2"; }
  
  .fa-wpbeginner:before {
	content: "\F297"; }
  
  .fa-confluence:before {
	content: "\F78D"; }
  
  .fa-mdb:before {
	content: "\F8CA"; }
  
  .fa-dochub:before {
	content: "\F394"; }
  
  .fa-accessible-icon:before {
	content: "\F368"; }
  
  .fa-ebay:before {
	content: "\F4F4"; }
  
  .fa-amazon:before {
	content: "\F270"; }
  
  .fa-unsplash:before {
	content: "\E07C"; }
  
  .fa-yarn:before {
	content: "\F7E3"; }
  
  .fa-square-steam:before {
	content: "\F1B7"; }
  
  .fa-steam-square:before {
	content: "\F1B7"; }
  
  .fa-500px:before {
	content: "\F26E"; }
  
  .fa-square-vimeo:before {
	content: "\F194"; }
  
  .fa-vimeo-square:before {
	content: "\F194"; }
  
  .fa-asymmetrik:before {
	content: "\F372"; }
  
  .fa-font-awesome:before {
	content: "\F2B4"; }
  
  .fa-font-awesome-flag:before {
	content: "\F2B4"; }
  
  .fa-font-awesome-logo-full:before {
	content: "\F2B4"; }
  
  .fa-gratipay:before {
	content: "\F184"; }
  
  .fa-apple:before {
	content: "\F179"; }
  
  .fa-hive:before {
	content: "\E07F"; }
  
  .fa-gitkraken:before {
	content: "\F3A6"; }
  
  .fa-keybase:before {
	content: "\F4F5"; }
  
  .fa-apple-pay:before {
	content: "\F415"; }
  
  .fa-padlet:before {
	content: "\E4A0"; }
  
  .fa-amazon-pay:before {
	content: "\F42C"; }
  
  .fa-square-github:before {
	content: "\F092"; }
  
  .fa-github-square:before {
	content: "\F092"; }
  
  .fa-stumbleupon:before {
	content: "\F1A4"; }
  
  .fa-fedex:before {
	content: "\F797"; }
  
  .fa-phoenix-framework:before {
	content: "\F3DC"; }
  
  .fa-shopify:before {
	content: "\E057"; }
  
  .fa-neos:before {
	content: "\F612"; }
  
  .fa-hackerrank:before {
	content: "\F5F7"; }
  
  .fa-researchgate:before {
	content: "\F4F8"; }
  
  .fa-swift:before {
	content: "\F8E1"; }
  
  .fa-angular:before {
	content: "\F420"; }
  
  .fa-speakap:before {
	content: "\F3F3"; }
  
  .fa-angrycreative:before {
	content: "\F36E"; }
  
  .fa-y-combinator:before {
	content: "\F23B"; }
  
  .fa-empire:before {
	content: "\F1D1"; }
  
  .fa-envira:before {
	content: "\F299"; }
  
  .fa-square-gitlab:before {
	content: "\E5AE"; }
  
  .fa-gitlab-square:before {
	content: "\E5AE"; }
  
  .fa-studiovinari:before {
	content: "\F3F8"; }
  
  .fa-pied-piper:before {
	content: "\F2AE"; }
  
  .fa-wordpress:before {
	content: "\F19A"; }
  
  .fa-product-hunt:before {
	content: "\F288"; }
  
  .fa-firefox:before {
	content: "\F269"; }
  
  .fa-linode:before {
	content: "\F2B8"; }
  
  .fa-goodreads:before {
	content: "\F3A8"; }
  
  .fa-square-odnoklassniki:before {
	content: "\F264"; }
  
  .fa-odnoklassniki-square:before {
	content: "\F264"; }
  
  .fa-jsfiddle:before {
	content: "\F1CC"; }
  
  .fa-sith:before {
	content: "\F512"; }
  
  .fa-themeisle:before {
	content: "\F2B2"; }
  
  .fa-page4:before {
	content: "\F3D7"; }
  
  .fa-hashnode:before {
	content: "\E499"; }
  
  .fa-react:before {
	content: "\F41B"; }
  
  .fa-cc-paypal:before {
	content: "\F1F4"; }
  
  .fa-squarespace:before {
	content: "\F5BE"; }
  
  .fa-cc-stripe:before {
	content: "\F1F5"; }
  
  .fa-creative-commons-share:before {
	content: "\F4F2"; }
  
  .fa-bitcoin:before {
	content: "\F379"; }
  
  .fa-keycdn:before {
	content: "\F3BA"; }
  
  .fa-opera:before {
	content: "\F26A"; }
  
  .fa-itch-io:before {
	content: "\F83A"; }
  
  .fa-umbraco:before {
	content: "\F8E8"; }
  
  .fa-galactic-senate:before {
	content: "\F50D"; }
  
  .fa-ubuntu:before {
	content: "\F7DF"; }
  
  .fa-draft2digital:before {
	content: "\F396"; }
  
  .fa-stripe:before {
	content: "\F429"; }
  
  .fa-houzz:before {
	content: "\F27C"; }
  
  .fa-gg:before {
	content: "\F260"; }
  
  .fa-dhl:before {
	content: "\F790"; }
  
  .fa-square-pinterest:before {
	content: "\F0D3"; }
  
  .fa-pinterest-square:before {
	content: "\F0D3"; }
  
  .fa-xing:before {
	content: "\F168"; }
  
  .fa-blackberry:before {
	content: "\F37B"; }
  
  .fa-creative-commons-pd:before {
	content: "\F4EC"; }
  
  .fa-playstation:before {
	content: "\F3DF"; }
  
  .fa-quinscape:before {
	content: "\F459"; }
  
  .fa-less:before {
	content: "\F41D"; }
  
  .fa-blogger-b:before {
	content: "\F37D"; }
  
  .fa-opencart:before {
	content: "\F23D"; }
  
  .fa-vine:before {
	content: "\F1CA"; }
  
  .fa-paypal:before {
	content: "\F1ED"; }
  
  .fa-gitlab:before {
	content: "\F296"; }
  
  .fa-typo3:before {
	content: "\F42B"; }
  
  .fa-reddit-alien:before {
	content: "\F281"; }
  
  .fa-yahoo:before {
	content: "\F19E"; }
  
  .fa-dailymotion:before {
	content: "\E052"; }
  
  .fa-affiliatetheme:before {
	content: "\F36B"; }
  
  .fa-pied-piper-pp:before {
	content: "\F1A7"; }
  
  .fa-bootstrap:before {
	content: "\F836"; }
  
  .fa-odnoklassniki:before {
	content: "\F263"; }
  
  .fa-nfc-symbol:before {
	content: "\E531"; }
  
  .fa-ethereum:before {
	content: "\F42E"; }
  
  .fa-speaker-deck:before {
	content: "\F83C"; }
  
  .fa-creative-commons-nc-eu:before {
	content: "\F4E9"; }
  
  .fa-patreon:before {
	content: "\F3D9"; }
  
  .fa-avianex:before {
	content: "\F374"; }
  
  .fa-ello:before {
	content: "\F5F1"; }
  
  .fa-gofore:before {
	content: "\F3A7"; }
  
  .fa-bimobject:before {
	content: "\F378"; }
  
  .fa-facebook-f:before {
	content: "\F39E"; }
  
  .fa-square-google-plus:before {
	content: "\F0D4"; }
  
  .fa-google-plus-square:before {
	content: "\F0D4"; }
  
  .fa-mandalorian:before {
	content: "\F50F"; }
  
  .fa-first-order-alt:before {
	content: "\F50A"; }
  
  .fa-osi:before {
	content: "\F41A"; }
  
  .fa-google-wallet:before {
	content: "\F1EE"; }
  
  .fa-d-and-d-beyond:before {
	content: "\F6CA"; }
  
  .fa-periscope:before {
	content: "\F3DA"; }
  
  .fa-fulcrum:before {
	content: "\F50B"; }
  
  .fa-cloudscale:before {
	content: "\F383"; }
  
  .fa-forumbee:before {
	content: "\F211"; }
  
  .fa-mizuni:before {
	content: "\F3CC"; }
  
  .fa-schlix:before {
	content: "\F3EA"; }
  
  .fa-square-xing:before {
	content: "\F169"; }
  
  .fa-xing-square:before {
	content: "\F169"; }
  
  .fa-bandcamp:before {
	content: "\F2D5"; }
  
  .fa-wpforms:before {
	content: "\F298"; }
  
  .fa-cloudversify:before {
	content: "\F385"; }
  
  .fa-usps:before {
	content: "\F7E1"; }
  
  .fa-megaport:before {
	content: "\F5A3"; }
  
  .fa-magento:before {
	content: "\F3C4"; }
  
  .fa-spotify:before {
	content: "\F1BC"; }
  
  .fa-optin-monster:before {
	content: "\F23C"; }
  
  .fa-fly:before {
	content: "\F417"; }
  
  .fa-aviato:before {
	content: "\F421"; }
  
  .fa-itunes:before {
	content: "\F3B4"; }
  
  .fa-cuttlefish:before {
	content: "\F38C"; }
  
  .fa-blogger:before {
	content: "\F37C"; }
  
  .fa-flickr:before {
	content: "\F16E"; }
  
  .fa-viber:before {
	content: "\F409"; }
  
  .fa-soundcloud:before {
	content: "\F1BE"; }
  
  .fa-digg:before {
	content: "\F1A6"; }
  
  .fa-tencent-weibo:before {
	content: "\F1D5"; }
  
  .fa-symfony:before {
	content: "\F83D"; }
  
  .fa-maxcdn:before {
	content: "\F136"; }
  
  .fa-etsy:before {
	content: "\F2D7"; }
  
  .fa-facebook-messenger:before {
	content: "\F39F"; }
  
  .fa-audible:before {
	content: "\F373"; }
  
  .fa-think-peaks:before {
	content: "\F731"; }
  
  .fa-bilibili:before {
	content: "\E3D9"; }
  
  .fa-erlang:before {
	content: "\F39D"; }
  
  .fa-cotton-bureau:before {
	content: "\F89E"; }
  
  .fa-dashcube:before {
	content: "\F210"; }
  
  .fa-42-group:before {
	content: "\E080"; }
  
  .fa-innosoft:before {
	content: "\E080"; }
  
  .fa-stack-exchange:before {
	content: "\F18D"; }
  
  .fa-elementor:before {
	content: "\F430"; }
  
  .fa-square-pied-piper:before {
	content: "\E01E"; }
  
  .fa-pied-piper-square:before {
	content: "\E01E"; }
  
  .fa-creative-commons-nd:before {
	content: "\F4EB"; }
  
  .fa-palfed:before {
	content: "\F3D8"; }
  
  .fa-superpowers:before {
	content: "\F2DD"; }
  
  .fa-resolving:before {
	content: "\F3E7"; }
  
  .fa-xbox:before {
	content: "\F412"; }
  
  .fa-searchengin:before {
	content: "\F3EB"; }
  
  .fa-tiktok:before {
	content: "\E07B"; }
  
  .fa-square-facebook:before {
	content: "\F082"; }
  
  .fa-facebook-square:before {
	content: "\F082"; }
  
  .fa-renren:before {
	content: "\F18B"; }
  
  .fa-linux:before {
	content: "\F17C"; }
  
  .fa-glide:before {
	content: "\F2A5"; }
  
  .fa-linkedin:before {
	content: "\F08C"; }
  
  .fa-hubspot:before {
	content: "\F3B2"; }
  
  .fa-deploydog:before {
	content: "\F38E"; }
  
  .fa-twitch:before {
	content: "\F1E8"; }
  
  .fa-ravelry:before {
	content: "\F2D9"; }
  
  .fa-mixer:before {
	content: "\E056"; }
  
  .fa-square-lastfm:before {
	content: "\F203"; }
  
  .fa-lastfm-square:before {
	content: "\F203"; }
  
  .fa-vimeo:before {
	content: "\F40A"; }
  
  .fa-mendeley:before {
	content: "\F7B3"; }
  
  .fa-uniregistry:before {
	content: "\F404"; }
  
  .fa-figma:before {
	content: "\F799"; }
  
  .fa-creative-commons-remix:before {
	content: "\F4EE"; }
  
  .fa-cc-amazon-pay:before {
	content: "\F42D"; }
  
  .fa-dropbox:before {
	content: "\F16B"; }
  
  .fa-instagram:before {
	content: "\F16D"; }
  
  .fa-cmplid:before {
	content: "\E360"; }
  
  .fa-facebook:before {
	content: "\F09A"; }
  
  .fa-gripfire:before {
	content: "\F3AC"; }
  
  .fa-jedi-order:before {
	content: "\F50E"; }
  
  .fa-uikit:before {
	content: "\F403"; }
  
  .fa-fort-awesome-alt:before {
	content: "\F3A3"; }
  
  .fa-phabricator:before {
	content: "\F3DB"; }
  
  .fa-ussunnah:before {
	content: "\F407"; }
  
  .fa-earlybirds:before {
	content: "\F39A"; }
  
  .fa-trade-federation:before {
	content: "\F513"; }
  
  .fa-autoprefixer:before {
	content: "\F41C"; }
  
  .fa-whatsapp:before {
	content: "\F232"; }
  
  .fa-slideshare:before {
	content: "\F1E7"; }
  
  .fa-google-play:before {
	content: "\F3AB"; }
  
  .fa-viadeo:before {
	content: "\F2A9"; }
  
  .fa-line:before {
	content: "\F3C0"; }
  
  .fa-google-drive:before {
	content: "\F3AA"; }
  
  .fa-servicestack:before {
	content: "\F3EC"; }
  
  .fa-simplybuilt:before {
	content: "\F215"; }
  
  .fa-bitbucket:before {
	content: "\F171"; }
  
  .fa-imdb:before {
	content: "\F2D8"; }
  
  .fa-deezer:before {
	content: "\E077"; }
  
  .fa-raspberry-pi:before {
	content: "\F7BB"; }
  
  .fa-jira:before {
	content: "\F7B1"; }
  
  .fa-docker:before {
	content: "\F395"; }
  
  .fa-screenpal:before {
	content: "\E570"; }
  
  .fa-bluetooth:before {
	content: "\F293"; }
  
  .fa-gitter:before {
	content: "\F426"; }
  
  .fa-d-and-d:before {
	content: "\F38D"; }
  
  .fa-microblog:before {
	content: "\E01A"; }
  
  .fa-cc-diners-club:before {
	content: "\F24C"; }
  
  .fa-gg-circle:before {
	content: "\F261"; }
  
  .fa-pied-piper-hat:before {
	content: "\F4E5"; }
  
  .fa-kickstarter-k:before {
	content: "\F3BC"; }
  
  .fa-yandex:before {
	content: "\F413"; }
  
  .fa-readme:before {
	content: "\F4D5"; }
  
  .fa-html5:before {
	content: "\F13B"; }
  
  .fa-sellsy:before {
	content: "\F213"; }
  
  .fa-sass:before {
	content: "\F41E"; }
  
  .fa-wirsindhandwerk:before {
	content: "\E2D0"; }
  
  .fa-wsh:before {
	content: "\E2D0"; }
  
  .fa-buromobelexperte:before {
	content: "\F37F"; }
  
  .fa-salesforce:before {
	content: "\F83B"; }
  
  .fa-octopus-deploy:before {
	content: "\E082"; }
  
  .fa-medapps:before {
	content: "\F3C6"; }
  
  .fa-ns8:before {
	content: "\F3D5"; }
  
  .fa-pinterest-p:before {
	content: "\F231"; }
  
  .fa-apper:before {
	content: "\F371"; }
  
  .fa-fort-awesome:before {
	content: "\F286"; }
  
  .fa-waze:before {
	content: "\F83F"; }
  
  .fa-cc-jcb:before {
	content: "\F24B"; }
  
  .fa-snapchat:before {
	content: "\F2AB"; }
  
  .fa-snapchat-ghost:before {
	content: "\F2AB"; }
  
  .fa-fantasy-flight-games:before {
	content: "\F6DC"; }
  
  .fa-rust:before {
	content: "\E07A"; }
  
  .fa-wix:before {
	content: "\F5CF"; }
  
  .fa-square-behance:before {
	content: "\F1B5"; }
  
  .fa-behance-square:before {
	content: "\F1B5"; }
  
  .fa-supple:before {
	content: "\F3F9"; }
  
  .fa-rebel:before {
	content: "\F1D0"; }
  
  .fa-css3:before {
	content: "\F13C"; }
  
  .fa-staylinked:before {
	content: "\F3F5"; }
  
  .fa-kaggle:before {
	content: "\F5FA"; }
  
  .fa-space-awesome:before {
	content: "\E5AC"; }
  
  .fa-deviantart:before {
	content: "\F1BD"; }
  
  .fa-cpanel:before {
	content: "\F388"; }
  
  .fa-goodreads-g:before {
	content: "\F3A9"; }
  
  .fa-square-git:before {
	content: "\F1D2"; }
  
  .fa-git-square:before {
	content: "\F1D2"; }
  
  .fa-square-tumblr:before {
	content: "\F174"; }
  
  .fa-tumblr-square:before {
	content: "\F174"; }
  
  .fa-trello:before {
	content: "\F181"; }
  
  .fa-creative-commons-nc-jp:before {
	content: "\F4EA"; }
  
  .fa-get-pocket:before {
	content: "\F265"; }
  
  .fa-perbyte:before {
	content: "\E083"; }
  
  .fa-grunt:before {
	content: "\F3AD"; }
  
  .fa-weebly:before {
	content: "\F5CC"; }
  
  .fa-connectdevelop:before {
	content: "\F20E"; }
  
  .fa-leanpub:before {
	content: "\F212"; }
  
  .fa-black-tie:before {
	content: "\F27E"; }
  
  .fa-themeco:before {
	content: "\F5C6"; }
  
  .fa-python:before {
	content: "\F3E2"; }
  
  .fa-android:before {
	content: "\F17B"; }
  
  .fa-bots:before {
	content: "\E340"; }
  
  .fa-free-code-camp:before {
	content: "\F2C5"; }
  
  .fa-hornbill:before {
	content: "\F592"; }
  
  .fa-js:before {
	content: "\F3B8"; }
  
  .fa-ideal:before {
	content: "\E013"; }
  
  .fa-git:before {
	content: "\F1D3"; }
  
  .fa-dev:before {
	content: "\F6CC"; }
  
  .fa-sketch:before {
	content: "\F7C6"; }
  
  .fa-yandex-international:before {
	content: "\F414"; }
  
  .fa-cc-amex:before {
	content: "\F1F3"; }
  
  .fa-uber:before {
	content: "\F402"; }
  
  .fa-github:before {
	content: "\F09B"; }
  
  .fa-php:before {
	content: "\F457"; }
  
  .fa-alipay:before {
	content: "\F642"; }
  
  .fa-youtube:before {
	content: "\F167"; }
  
  .fa-skyatlas:before {
	content: "\F216"; }
  
  .fa-firefox-browser:before {
	content: "\E007"; }
  
  .fa-replyd:before {
	content: "\F3E6"; }
  
  .fa-suse:before {
	content: "\F7D6"; }
  
  .fa-jenkins:before {
	content: "\F3B6"; }
  
  .fa-twitter:before {
	content: "\F099"; }
  
  .fa-rockrms:before {
	content: "\F3E9"; }
  
  .fa-pinterest:before {
	content: "\F0D2"; }
  
  .fa-buffer:before {
	content: "\F837"; }
  
  .fa-npm:before {
	content: "\F3D4"; }
  
  .fa-yammer:before {
	content: "\F840"; }
  
  .fa-btc:before {
	content: "\F15A"; }
  
  .fa-dribbble:before {
	content: "\F17D"; }
  
  .fa-stumbleupon-circle:before {
	content: "\F1A3"; }
  
  .fa-internet-explorer:before {
	content: "\F26B"; }
  
  .fa-telegram:before {
	content: "\F2C6"; }
  
  .fa-telegram-plane:before {
	content: "\F2C6"; }
  
  .fa-old-republic:before {
	content: "\F510"; }
  
  .fa-square-whatsapp:before {
	content: "\F40C"; }
  
  .fa-whatsapp-square:before {
	content: "\F40C"; }
  
  .fa-node-js:before {
	content: "\F3D3"; }
  
  .fa-edge-legacy:before {
	content: "\E078"; }
  
  .fa-slack:before {
	content: "\F198"; }
  
  .fa-slack-hash:before {
	content: "\F198"; }
  
  .fa-medrt:before {
	content: "\F3C8"; }
  
  .fa-usb:before {
	content: "\F287"; }
  
  .fa-tumblr:before {
	content: "\F173"; }
  
  .fa-vaadin:before {
	content: "\F408"; }
  
  .fa-quora:before {
	content: "\F2C4"; }
  
  .fa-reacteurope:before {
	content: "\F75D"; }
  
  .fa-medium:before {
	content: "\F23A"; }
  
  .fa-medium-m:before {
	content: "\F23A"; }
  
  .fa-amilia:before {
	content: "\F36D"; }
  
  .fa-mixcloud:before {
	content: "\F289"; }
  
  .fa-flipboard:before {
	content: "\F44D"; }
  
  .fa-viacoin:before {
	content: "\F237"; }
  
  .fa-critical-role:before {
	content: "\F6C9"; }
  
  .fa-sitrox:before {
	content: "\E44A"; }
  
  .fa-discourse:before {
	content: "\F393"; }
  
  .fa-joomla:before {
	content: "\F1AA"; }
  
  .fa-mastodon:before {
	content: "\F4F6"; }
  
  .fa-airbnb:before {
	content: "\F834"; }
  
  .fa-wolf-pack-battalion:before {
	content: "\F514"; }
  
  .fa-buy-n-large:before {
	content: "\F8A6"; }
  
  .fa-gulp:before {
	content: "\F3AE"; }
  
  .fa-creative-commons-sampling-plus:before {
	content: "\F4F1"; }
  
  .fa-strava:before {
	content: "\F428"; }
  
  .fa-ember:before {
	content: "\F423"; }
  
  .fa-canadian-maple-leaf:before {
	content: "\F785"; }
  
  .fa-teamspeak:before {
	content: "\F4F9"; }
  
  .fa-pushed:before {
	content: "\F3E1"; }
  
  .fa-wordpress-simple:before {
	content: "\F411"; }
  
  .fa-nutritionix:before {
	content: "\F3D6"; }
  
  .fa-wodu:before {
	content: "\E088"; }
  
  .fa-google-pay:before {
	content: "\E079"; }
  
  .fa-intercom:before {
	content: "\F7AF"; }
  
  .fa-zhihu:before {
	content: "\F63F"; }
  
  .fa-korvue:before {
	content: "\F42F"; }
  
  .fa-pix:before {
	content: "\E43A"; }
  
  .fa-steam-symbol:before {
	content: "\F3F6"; }
  :root, :host {
	--fa-style-family-duotone: 'Font Awesome 6 Duotone';
	--fa-font-duotone: normal 900 1em/1 'Font Awesome 6 Duotone'; }
  
  @font-face {
	font-family: 'Font Awesome 6 Duotone';
	font-style: normal;
	font-weight: 900;
	font-display: block;
	src: url(/static/media/fa-duotone-900.a5068b6a.woff2) format("woff2"), url(/static/media/fa-duotone-900.08446e6b.ttf) format("truetype"); }
  
  .fad,
  .fa-duotone {
	position: relative;
	font-weight: 900;
	letter-spacing: normal; }
  
  .fad::before,
  .fa-duotone::before {
	position: absolute;
	color: inherit;
	color: var(--fa-primary-color, inherit);
	opacity: 1;
	opacity: var(--fa-primary-opacity, 1); }
  
  .fad::after,
  .fa-duotone::after {
	color: inherit;
	color: var(--fa-secondary-color, inherit);
	opacity: 0.4;
	opacity: var(--fa-secondary-opacity, 0.4); }
  
  .fa-swap-opacity .fad::before,
  .fa-swap-opacity .fa-duotone::before,
  .fad.fa-swap-opacity::before,
  .fa-duotone.fa-swap-opacity::before {
	opacity: 0.4;
	opacity: var(--fa-secondary-opacity, 0.4); }
  
  .fa-swap-opacity .fad::after,
  .fa-swap-opacity .fa-duotone::after,
  .fad.fa-swap-opacity::after,
  .fa-duotone.fa-swap-opacity::after {
	opacity: 1;
	opacity: var(--fa-primary-opacity, 1); }
  
  .fad.fa-inverse,
  .fa-duotone.fa-inverse {
	color: #fff;
	color: var(--fa-inverse, #fff); }
  
  .fad.fa-stack-1x, .fad.fa-stack-2x,
  .fa-duotone.fa-stack-1x, .fa-duotone.fa-stack-2x {
	position: absolute; }
  
  /* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen readers do not read off random characters that represent icons */
  .fad.fa-0::after, .fa-duotone.fa-0::after {
	content: "00"; }
  
  .fad.fa-1::after, .fa-duotone.fa-1::after {
	content: "11"; }
  
  .fad.fa-2::after, .fa-duotone.fa-2::after {
	content: "22"; }
  
  .fad.fa-3::after, .fa-duotone.fa-3::after {
	content: "33"; }
  
  .fad.fa-4::after, .fa-duotone.fa-4::after {
	content: "44"; }
  
  .fad.fa-5::after, .fa-duotone.fa-5::after {
	content: "55"; }
  
  .fad.fa-6::after, .fa-duotone.fa-6::after {
	content: "66"; }
  
  .fad.fa-7::after, .fa-duotone.fa-7::after {
	content: "77"; }
  
  .fad.fa-8::after, .fa-duotone.fa-8::after {
	content: "88"; }
  
  .fad.fa-9::after, .fa-duotone.fa-9::after {
	content: "99"; }
  
  .fad.fa-fill-drip::after, .fa-duotone.fa-fill-drip::after {
	content: "\F576\F576"; }
  
  .fad.fa-arrows-to-circle::after, .fa-duotone.fa-arrows-to-circle::after {
	content: "\E4BD\E4BD"; }
  
  .fad.fa-circle-chevron-right::after, .fa-duotone.fa-circle-chevron-right::after {
	content: "\F138\F138"; }
  
  .fad.fa-chevron-circle-right::after, .fa-duotone.fa-chevron-circle-right::after {
	content: "\F138\F138"; }
  
  .fad.fa-wagon-covered::after, .fa-duotone.fa-wagon-covered::after {
	content: "\F8EE\F8EE"; }
  
  .fad.fa-line-height::after, .fa-duotone.fa-line-height::after {
	content: "\F871\F871"; }
  
  .fad.fa-bagel::after, .fa-duotone.fa-bagel::after {
	content: "\E3D7\E3D7"; }
  
  .fad.fa-transporter-7::after, .fa-duotone.fa-transporter-7::after {
	content: "\E2A8\E2A8"; }
  
  .fad.fa-at::after, .fa-duotone.fa-at::after {
	content: "@@"; }
  
  .fad.fa-rectangles-mixed::after, .fa-duotone.fa-rectangles-mixed::after {
	content: "\E323\E323"; }
  
  .fad.fa-phone-arrow-up-right::after, .fa-duotone.fa-phone-arrow-up-right::after {
	content: "\E224\E224"; }
  
  .fad.fa-phone-arrow-up::after, .fa-duotone.fa-phone-arrow-up::after {
	content: "\E224\E224"; }
  
  .fad.fa-phone-outgoing::after, .fa-duotone.fa-phone-outgoing::after {
	content: "\E224\E224"; }
  
  .fad.fa-trash-can::after, .fa-duotone.fa-trash-can::after {
	content: "\F2ED\F2ED"; }
  
  .fad.fa-trash-alt::after, .fa-duotone.fa-trash-alt::after {
	content: "\F2ED\F2ED"; }
  
  .fad.fa-circle-l::after, .fa-duotone.fa-circle-l::after {
	content: "\E114\E114"; }
  
  .fad.fa-head-side-goggles::after, .fa-duotone.fa-head-side-goggles::after {
	content: "\F6EA\F6EA"; }
  
  .fad.fa-head-vr::after, .fa-duotone.fa-head-vr::after {
	content: "\F6EA\F6EA"; }
  
  .fad.fa-text-height::after, .fa-duotone.fa-text-height::after {
	content: "\F034\F034"; }
  
  .fad.fa-user-xmark::after, .fa-duotone.fa-user-xmark::after {
	content: "\F235\F235"; }
  
  .fad.fa-user-times::after, .fa-duotone.fa-user-times::after {
	content: "\F235\F235"; }
  
  .fad.fa-face-hand-yawn::after, .fa-duotone.fa-face-hand-yawn::after {
	content: "\E379\E379"; }
  
  .fad.fa-gauge-simple-min::after, .fa-duotone.fa-gauge-simple-min::after {
	content: "\F62D\F62D"; }
  
  .fad.fa-tachometer-slowest::after, .fa-duotone.fa-tachometer-slowest::after {
	content: "\F62D\F62D"; }
  
  .fad.fa-stethoscope::after, .fa-duotone.fa-stethoscope::after {
	content: "\F0F1\F0F1"; }
  
  .fad.fa-coffin::after, .fa-duotone.fa-coffin::after {
	content: "\F6C6\F6C6"; }
  
  .fad.fa-message::after, .fa-duotone.fa-message::after {
	content: "\F27A\F27A"; }
  
  .fad.fa-comment-alt::after, .fa-duotone.fa-comment-alt::after {
	content: "\F27A\F27A"; }
  
  .fad.fa-salad::after, .fa-duotone.fa-salad::after {
	content: "\F81E\F81E"; }
  
  .fad.fa-bowl-salad::after, .fa-duotone.fa-bowl-salad::after {
	content: "\F81E\F81E"; }
  
  .fad.fa-info::after, .fa-duotone.fa-info::after {
	content: "\F129\F129"; }
  
  .fad.fa-robot-astromech::after, .fa-duotone.fa-robot-astromech::after {
	content: "\E2D2\E2D2"; }
  
  .fad.fa-ring-diamond::after, .fa-duotone.fa-ring-diamond::after {
	content: "\E5AB\E5AB"; }
  
  .fad.fa-fondue-pot::after, .fa-duotone.fa-fondue-pot::after {
	content: "\E40D\E40D"; }
  
  .fad.fa-theta::after, .fa-duotone.fa-theta::after {
	content: "\F69E\F69E"; }
  
  .fad.fa-face-hand-peeking::after, .fa-duotone.fa-face-hand-peeking::after {
	content: "\E481\E481"; }
  
  .fad.fa-square-user::after, .fa-duotone.fa-square-user::after {
	content: "\E283\E283"; }
  
  .fad.fa-down-left-and-up-right-to-center::after, .fa-duotone.fa-down-left-and-up-right-to-center::after {
	content: "\F422\F422"; }
  
  .fad.fa-compress-alt::after, .fa-duotone.fa-compress-alt::after {
	content: "\F422\F422"; }
  
  .fad.fa-explosion::after, .fa-duotone.fa-explosion::after {
	content: "\E4E9\E4E9"; }
  
  .fad.fa-file-lines::after, .fa-duotone.fa-file-lines::after {
	content: "\F15C\F15C"; }
  
  .fad.fa-file-alt::after, .fa-duotone.fa-file-alt::after {
	content: "\F15C\F15C"; }
  
  .fad.fa-file-text::after, .fa-duotone.fa-file-text::after {
	content: "\F15C\F15C"; }
  
  .fad.fa-wave-square::after, .fa-duotone.fa-wave-square::after {
	content: "\F83E\F83E"; }
  
  .fad.fa-ring::after, .fa-duotone.fa-ring::after {
	content: "\F70B\F70B"; }
  
  .fad.fa-building-un::after, .fa-duotone.fa-building-un::after {
	content: "\E4D9\E4D9"; }
  
  .fad.fa-dice-three::after, .fa-duotone.fa-dice-three::after {
	content: "\F527\F527"; }
  
  .fad.fa-tire-pressure-warning::after, .fa-duotone.fa-tire-pressure-warning::after {
	content: "\F633\F633"; }
  
  .fad.fa-wifi-fair::after, .fa-duotone.fa-wifi-fair::after {
	content: "\F6AB\F6AB"; }
  
  .fad.fa-wifi-2::after, .fa-duotone.fa-wifi-2::after {
	content: "\F6AB\F6AB"; }
  
  .fad.fa-calendar-days::after, .fa-duotone.fa-calendar-days::after {
	content: "\F073\F073"; }
  
  .fad.fa-calendar-alt::after, .fa-duotone.fa-calendar-alt::after {
	content: "\F073\F073"; }
  
  .fad.fa-mp3-player::after, .fa-duotone.fa-mp3-player::after {
	content: "\F8CE\F8CE"; }
  
  .fad.fa-anchor-circle-check::after, .fa-duotone.fa-anchor-circle-check::after {
	content: "\E4AA\E4AA"; }
  
  .fad.fa-tally-4::after, .fa-duotone.fa-tally-4::after {
	content: "\E297\E297"; }
  
  .fad.fa-rectangle-history::after, .fa-duotone.fa-rectangle-history::after {
	content: "\E4A2\E4A2"; }
  
  .fad.fa-building-circle-arrow-right::after, .fa-duotone.fa-building-circle-arrow-right::after {
	content: "\E4D1\E4D1"; }
  
  .fad.fa-volleyball::after, .fa-duotone.fa-volleyball::after {
	content: "\F45F\F45F"; }
  
  .fad.fa-volleyball-ball::after, .fa-duotone.fa-volleyball-ball::after {
	content: "\F45F\F45F"; }
  
  .fad.fa-sun-haze::after, .fa-duotone.fa-sun-haze::after {
	content: "\F765\F765"; }
  
  .fad.fa-text-size::after, .fa-duotone.fa-text-size::after {
	content: "\F894\F894"; }
  
  .fad.fa-ufo::after, .fa-duotone.fa-ufo::after {
	content: "\E047\E047"; }
  
  .fad.fa-fork::after, .fa-duotone.fa-fork::after {
	content: "\F2E3\F2E3"; }
  
  .fad.fa-utensil-fork::after, .fa-duotone.fa-utensil-fork::after {
	content: "\F2E3\F2E3"; }
  
  .fad.fa-arrows-up-to-line::after, .fa-duotone.fa-arrows-up-to-line::after {
	content: "\E4C2\E4C2"; }
  
  .fad.fa-mobile-signal::after, .fa-duotone.fa-mobile-signal::after {
	content: "\E1EF\E1EF"; }
  
  .fad.fa-barcode-scan::after, .fa-duotone.fa-barcode-scan::after {
	content: "\F465\F465"; }
  
  .fad.fa-sort-down::after, .fa-duotone.fa-sort-down::after {
	content: "\F0DD\F0DD"; }
  
  .fad.fa-sort-desc::after, .fa-duotone.fa-sort-desc::after {
	content: "\F0DD\F0DD"; }
  
  .fad.fa-folder-arrow-down::after, .fa-duotone.fa-folder-arrow-down::after {
	content: "\E053\E053"; }
  
  .fad.fa-folder-download::after, .fa-duotone.fa-folder-download::after {
	content: "\E053\E053"; }
  
  .fad.fa-circle-minus::after, .fa-duotone.fa-circle-minus::after {
	content: "\F056\F056"; }
  
  .fad.fa-minus-circle::after, .fa-duotone.fa-minus-circle::after {
	content: "\F056\F056"; }
  
  .fad.fa-face-icicles::after, .fa-duotone.fa-face-icicles::after {
	content: "\E37C\E37C"; }
  
  .fad.fa-shovel::after, .fa-duotone.fa-shovel::after {
	content: "\F713\F713"; }
  
  .fad.fa-door-open::after, .fa-duotone.fa-door-open::after {
	content: "\F52B\F52B"; }
  
  .fad.fa-films::after, .fa-duotone.fa-films::after {
	content: "\E17A\E17A"; }
  
  .fad.fa-right-from-bracket::after, .fa-duotone.fa-right-from-bracket::after {
	content: "\F2F5\F2F5"; }
  
  .fad.fa-sign-out-alt::after, .fa-duotone.fa-sign-out-alt::after {
	content: "\F2F5\F2F5"; }
  
  .fad.fa-face-glasses::after, .fa-duotone.fa-face-glasses::after {
	content: "\E377\E377"; }
  
  .fad.fa-nfc::after, .fa-duotone.fa-nfc::after {
	content: "\E1F7\E1F7"; }
  
  .fad.fa-atom::after, .fa-duotone.fa-atom::after {
	content: "\F5D2\F5D2"; }
  
  .fad.fa-soap::after, .fa-duotone.fa-soap::after {
	content: "\E06E\E06E"; }
  
  .fad.fa-icons::after, .fa-duotone.fa-icons::after {
	content: "\F86D\F86D"; }
  
  .fad.fa-heart-music-camera-bolt::after, .fa-duotone.fa-heart-music-camera-bolt::after {
	content: "\F86D\F86D"; }
  
  .fad.fa-microphone-lines-slash::after, .fa-duotone.fa-microphone-lines-slash::after {
	content: "\F539\F539"; }
  
  .fad.fa-microphone-alt-slash::after, .fa-duotone.fa-microphone-alt-slash::after {
	content: "\F539\F539"; }
  
  .fad.fa-closed-captioning-slash::after, .fa-duotone.fa-closed-captioning-slash::after {
	content: "\E135\E135"; }
  
  .fad.fa-calculator-simple::after, .fa-duotone.fa-calculator-simple::after {
	content: "\F64C\F64C"; }
  
  .fad.fa-calculator-alt::after, .fa-duotone.fa-calculator-alt::after {
	content: "\F64C\F64C"; }
  
  .fad.fa-bridge-circle-check::after, .fa-duotone.fa-bridge-circle-check::after {
	content: "\E4C9\E4C9"; }
  
  .fad.fa-sliders-up::after, .fa-duotone.fa-sliders-up::after {
	content: "\F3F1\F3F1"; }
  
  .fad.fa-sliders-v::after, .fa-duotone.fa-sliders-v::after {
	content: "\F3F1\F3F1"; }
  
  .fad.fa-location-minus::after, .fa-duotone.fa-location-minus::after {
	content: "\F609\F609"; }
  
  .fad.fa-map-marker-minus::after, .fa-duotone.fa-map-marker-minus::after {
	content: "\F609\F609"; }
  
  .fad.fa-pump-medical::after, .fa-duotone.fa-pump-medical::after {
	content: "\E06A\E06A"; }
  
  .fad.fa-fingerprint::after, .fa-duotone.fa-fingerprint::after {
	content: "\F577\F577"; }
  
  .fad.fa-ski-boot::after, .fa-duotone.fa-ski-boot::after {
	content: "\E3CC\E3CC"; }
  
  .fad.fa-standard-definition::after, .fa-duotone.fa-standard-definition::after {
	content: "\E28A\E28A"; }
  
  .fad.fa-rectangle-sd::after, .fa-duotone.fa-rectangle-sd::after {
	content: "\E28A\E28A"; }
  
  .fad.fa-h1::after, .fa-duotone.fa-h1::after {
	content: "\F313\F313"; }
  
  .fad.fa-hand-point-right::after, .fa-duotone.fa-hand-point-right::after {
	content: "\F0A4\F0A4"; }
  
  .fad.fa-magnifying-glass-location::after, .fa-duotone.fa-magnifying-glass-location::after {
	content: "\F689\F689"; }
  
  .fad.fa-search-location::after, .fa-duotone.fa-search-location::after {
	content: "\F689\F689"; }
  
  .fad.fa-message-bot::after, .fa-duotone.fa-message-bot::after {
	content: "\E3B8\E3B8"; }
  
  .fad.fa-forward-step::after, .fa-duotone.fa-forward-step::after {
	content: "\F051\F051"; }
  
  .fad.fa-step-forward::after, .fa-duotone.fa-step-forward::after {
	content: "\F051\F051"; }
  
  .fad.fa-face-smile-beam::after, .fa-duotone.fa-face-smile-beam::after {
	content: "\F5B8\F5B8"; }
  
  .fad.fa-smile-beam::after, .fa-duotone.fa-smile-beam::after {
	content: "\F5B8\F5B8"; }
  
  .fad.fa-light-ceiling::after, .fa-duotone.fa-light-ceiling::after {
	content: "\E016\E016"; }
  
  .fad.fa-message-exclamation::after, .fa-duotone.fa-message-exclamation::after {
	content: "\F4A5\F4A5"; }
  
  .fad.fa-comment-alt-exclamation::after, .fa-duotone.fa-comment-alt-exclamation::after {
	content: "\F4A5\F4A5"; }
  
  .fad.fa-bowl-scoop::after, .fa-duotone.fa-bowl-scoop::after {
	content: "\E3DE\E3DE"; }
  
  .fad.fa-bowl-shaved-ice::after, .fa-duotone.fa-bowl-shaved-ice::after {
	content: "\E3DE\E3DE"; }
  
  .fad.fa-square-x::after, .fa-duotone.fa-square-x::after {
	content: "\E286\E286"; }
  
  .fad.fa-utility-pole-double::after, .fa-duotone.fa-utility-pole-double::after {
	content: "\E2C4\E2C4"; }
  
  .fad.fa-flag-checkered::after, .fa-duotone.fa-flag-checkered::after {
	content: "\F11E\F11E"; }
  
  .fad.fa-chevrons-up::after, .fa-duotone.fa-chevrons-up::after {
	content: "\F325\F325"; }
  
  .fad.fa-chevron-double-up::after, .fa-duotone.fa-chevron-double-up::after {
	content: "\F325\F325"; }
  
  .fad.fa-football::after, .fa-duotone.fa-football::after {
	content: "\F44E\F44E"; }
  
  .fad.fa-football-ball::after, .fa-duotone.fa-football-ball::after {
	content: "\F44E\F44E"; }
  
  .fad.fa-user-vneck::after, .fa-duotone.fa-user-vneck::after {
	content: "\E461\E461"; }
  
  .fad.fa-school-circle-exclamation::after, .fa-duotone.fa-school-circle-exclamation::after {
	content: "\E56C\E56C"; }
  
  .fad.fa-crop::after, .fa-duotone.fa-crop::after {
	content: "\F125\F125"; }
  
  .fad.fa-angles-down::after, .fa-duotone.fa-angles-down::after {
	content: "\F103\F103"; }
  
  .fad.fa-angle-double-down::after, .fa-duotone.fa-angle-double-down::after {
	content: "\F103\F103"; }
  
  .fad.fa-users-rectangle::after, .fa-duotone.fa-users-rectangle::after {
	content: "\E594\E594"; }
  
  .fad.fa-people-roof::after, .fa-duotone.fa-people-roof::after {
	content: "\E537\E537"; }
  
  .fad.fa-square-arrow-right::after, .fa-duotone.fa-square-arrow-right::after {
	content: "\F33B\F33B"; }
  
  .fad.fa-arrow-square-right::after, .fa-duotone.fa-arrow-square-right::after {
	content: "\F33B\F33B"; }
  
  .fad.fa-location-plus::after, .fa-duotone.fa-location-plus::after {
	content: "\F60A\F60A"; }
  
  .fad.fa-map-marker-plus::after, .fa-duotone.fa-map-marker-plus::after {
	content: "\F60A\F60A"; }
  
  .fad.fa-lightbulb-exclamation-on::after, .fa-duotone.fa-lightbulb-exclamation-on::after {
	content: "\E1CA\E1CA"; }
  
  .fad.fa-people-line::after, .fa-duotone.fa-people-line::after {
	content: "\E534\E534"; }
  
  .fad.fa-beer-mug-empty::after, .fa-duotone.fa-beer-mug-empty::after {
	content: "\F0FC\F0FC"; }
  
  .fad.fa-beer::after, .fa-duotone.fa-beer::after {
	content: "\F0FC\F0FC"; }
  
  .fad.fa-crate-empty::after, .fa-duotone.fa-crate-empty::after {
	content: "\E151\E151"; }
  
  .fad.fa-diagram-predecessor::after, .fa-duotone.fa-diagram-predecessor::after {
	content: "\E477\E477"; }
  
  .fad.fa-transporter::after, .fa-duotone.fa-transporter::after {
	content: "\E042\E042"; }
  
  .fad.fa-calendar-circle-user::after, .fa-duotone.fa-calendar-circle-user::after {
	content: "\E471\E471"; }
  
  .fad.fa-arrow-up-long::after, .fa-duotone.fa-arrow-up-long::after {
	content: "\F176\F176"; }
  
  .fad.fa-long-arrow-up::after, .fa-duotone.fa-long-arrow-up::after {
	content: "\F176\F176"; }
  
  .fad.fa-person-carry-box::after, .fa-duotone.fa-person-carry-box::after {
	content: "\F4CF\F4CF"; }
  
  .fad.fa-person-carry::after, .fa-duotone.fa-person-carry::after {
	content: "\F4CF\F4CF"; }
  
  .fad.fa-fire-flame-simple::after, .fa-duotone.fa-fire-flame-simple::after {
	content: "\F46A\F46A"; }
  
  .fad.fa-burn::after, .fa-duotone.fa-burn::after {
	content: "\F46A\F46A"; }
  
  .fad.fa-person::after, .fa-duotone.fa-person::after {
	content: "\F183\F183"; }
  
  .fad.fa-male::after, .fa-duotone.fa-male::after {
	content: "\F183\F183"; }
  
  .fad.fa-laptop::after, .fa-duotone.fa-laptop::after {
	content: "\F109\F109"; }
  
  .fad.fa-file-csv::after, .fa-duotone.fa-file-csv::after {
	content: "\F6DD\F6DD"; }
  
  .fad.fa-menorah::after, .fa-duotone.fa-menorah::after {
	content: "\F676\F676"; }
  
  .fad.fa-union::after, .fa-duotone.fa-union::after {
	content: "\F6A2\F6A2"; }
  
  .fad.fa-chevrons-left::after, .fa-duotone.fa-chevrons-left::after {
	content: "\F323\F323"; }
  
  .fad.fa-chevron-double-left::after, .fa-duotone.fa-chevron-double-left::after {
	content: "\F323\F323"; }
  
  .fad.fa-circle-heart::after, .fa-duotone.fa-circle-heart::after {
	content: "\F4C7\F4C7"; }
  
  .fad.fa-heart-circle::after, .fa-duotone.fa-heart-circle::after {
	content: "\F4C7\F4C7"; }
  
  .fad.fa-truck-plane::after, .fa-duotone.fa-truck-plane::after {
	content: "\E58F\E58F"; }
  
  .fad.fa-record-vinyl::after, .fa-duotone.fa-record-vinyl::after {
	content: "\F8D9\F8D9"; }
  
  .fad.fa-bring-forward::after, .fa-duotone.fa-bring-forward::after {
	content: "\F856\F856"; }
  
  .fad.fa-square-p::after, .fa-duotone.fa-square-p::after {
	content: "\E279\E279"; }
  
  .fad.fa-face-grin-stars::after, .fa-duotone.fa-face-grin-stars::after {
	content: "\F587\F587"; }
  
  .fad.fa-grin-stars::after, .fa-duotone.fa-grin-stars::after {
	content: "\F587\F587"; }
  
  .fad.fa-sigma::after, .fa-duotone.fa-sigma::after {
	content: "\F68B\F68B"; }
  
  .fad.fa-camera-movie::after, .fa-duotone.fa-camera-movie::after {
	content: "\F8A9\F8A9"; }
  
  .fad.fa-bong::after, .fa-duotone.fa-bong::after {
	content: "\F55C\F55C"; }
  
  .fad.fa-clarinet::after, .fa-duotone.fa-clarinet::after {
	content: "\F8AD\F8AD"; }
  
  .fad.fa-truck-flatbed::after, .fa-duotone.fa-truck-flatbed::after {
	content: "\E2B6\E2B6"; }
  
  .fad.fa-spaghetti-monster-flying::after, .fa-duotone.fa-spaghetti-monster-flying::after {
	content: "\F67B\F67B"; }
  
  .fad.fa-pastafarianism::after, .fa-duotone.fa-pastafarianism::after {
	content: "\F67B\F67B"; }
  
  .fad.fa-arrow-down-up-across-line::after, .fa-duotone.fa-arrow-down-up-across-line::after {
	content: "\E4AF\E4AF"; }
  
  .fad.fa-leaf-heart::after, .fa-duotone.fa-leaf-heart::after {
	content: "\F4CB\F4CB"; }
  
  .fad.fa-house-building::after, .fa-duotone.fa-house-building::after {
	content: "\E1B1\E1B1"; }
  
  .fad.fa-cheese-swiss::after, .fa-duotone.fa-cheese-swiss::after {
	content: "\F7F0\F7F0"; }
  
  .fad.fa-spoon::after, .fa-duotone.fa-spoon::after {
	content: "\F2E5\F2E5"; }
  
  .fad.fa-utensil-spoon::after, .fa-duotone.fa-utensil-spoon::after {
	content: "\F2E5\F2E5"; }
  
  .fad.fa-jar-wheat::after, .fa-duotone.fa-jar-wheat::after {
	content: "\E517\E517"; }
  
  .fad.fa-envelopes-bulk::after, .fa-duotone.fa-envelopes-bulk::after {
	content: "\F674\F674"; }
  
  .fad.fa-mail-bulk::after, .fa-duotone.fa-mail-bulk::after {
	content: "\F674\F674"; }
  
  .fad.fa-file-circle-exclamation::after, .fa-duotone.fa-file-circle-exclamation::after {
	content: "\E4EB\E4EB"; }
  
  .fad.fa-bow-arrow::after, .fa-duotone.fa-bow-arrow::after {
	content: "\F6B9\F6B9"; }
  
  .fad.fa-cart-xmark::after, .fa-duotone.fa-cart-xmark::after {
	content: "\E0DD\E0DD"; }
  
  .fad.fa-hexagon-xmark::after, .fa-duotone.fa-hexagon-xmark::after {
	content: "\F2EE\F2EE"; }
  
  .fad.fa-times-hexagon::after, .fa-duotone.fa-times-hexagon::after {
	content: "\F2EE\F2EE"; }
  
  .fad.fa-xmark-hexagon::after, .fa-duotone.fa-xmark-hexagon::after {
	content: "\F2EE\F2EE"; }
  
  .fad.fa-circle-h::after, .fa-duotone.fa-circle-h::after {
	content: "\F47E\F47E"; }
  
  .fad.fa-hospital-symbol::after, .fa-duotone.fa-hospital-symbol::after {
	content: "\F47E\F47E"; }
  
  .fad.fa-merge::after, .fa-duotone.fa-merge::after {
	content: "\E526\E526"; }
  
  .fad.fa-pager::after, .fa-duotone.fa-pager::after {
	content: "\F815\F815"; }
  
  .fad.fa-cart-minus::after, .fa-duotone.fa-cart-minus::after {
	content: "\E0DB\E0DB"; }
  
  .fad.fa-address-book::after, .fa-duotone.fa-address-book::after {
	content: "\F2B9\F2B9"; }
  
  .fad.fa-contact-book::after, .fa-duotone.fa-contact-book::after {
	content: "\F2B9\F2B9"; }
  
  .fad.fa-pan-frying::after, .fa-duotone.fa-pan-frying::after {
	content: "\E42C\E42C"; }
  
  .fad.fa-grid::after, .fa-duotone.fa-grid::after {
	content: "\E195\E195"; }
  
  .fad.fa-grid-3::after, .fa-duotone.fa-grid-3::after {
	content: "\E195\E195"; }
  
  .fad.fa-football-helmet::after, .fa-duotone.fa-football-helmet::after {
	content: "\F44F\F44F"; }
  
  .fad.fa-hand-love::after, .fa-duotone.fa-hand-love::after {
	content: "\E1A5\E1A5"; }
  
  .fad.fa-trees::after, .fa-duotone.fa-trees::after {
	content: "\F724\F724"; }
  
  .fad.fa-strikethrough::after, .fa-duotone.fa-strikethrough::after {
	content: "\F0CC\F0CC"; }
  
  .fad.fa-page::after, .fa-duotone.fa-page::after {
	content: "\E428\E428"; }
  
  .fad.fa-k::after, .fa-duotone.fa-k::after {
	content: "KK"; }
  
  .fad.fa-diagram-previous::after, .fa-duotone.fa-diagram-previous::after {
	content: "\E478\E478"; }
  
  .fad.fa-gauge-min::after, .fa-duotone.fa-gauge-min::after {
	content: "\F628\F628"; }
  
  .fad.fa-tachometer-alt-slowest::after, .fa-duotone.fa-tachometer-alt-slowest::after {
	content: "\F628\F628"; }
  
  .fad.fa-folder-grid::after, .fa-duotone.fa-folder-grid::after {
	content: "\E188\E188"; }
  
  .fad.fa-eggplant::after, .fa-duotone.fa-eggplant::after {
	content: "\E16C\E16C"; }
  
  .fad.fa-ram::after, .fa-duotone.fa-ram::after {
	content: "\F70A\F70A"; }
  
  .fad.fa-landmark-flag::after, .fa-duotone.fa-landmark-flag::after {
	content: "\E51C\E51C"; }
  
  .fad.fa-lips::after, .fa-duotone.fa-lips::after {
	content: "\F600\F600"; }
  
  .fad.fa-pencil::after, .fa-duotone.fa-pencil::after {
	content: "\F303\F303"; }
  
  .fad.fa-pencil-alt::after, .fa-duotone.fa-pencil-alt::after {
	content: "\F303\F303"; }
  
  .fad.fa-backward::after, .fa-duotone.fa-backward::after {
	content: "\F04A\F04A"; }
  
  .fad.fa-caret-right::after, .fa-duotone.fa-caret-right::after {
	content: "\F0DA\F0DA"; }
  
  .fad.fa-comments::after, .fa-duotone.fa-comments::after {
	content: "\F086\F086"; }
  
  .fad.fa-paste::after, .fa-duotone.fa-paste::after {
	content: "\F0EA\F0EA"; }
  
  .fad.fa-file-clipboard::after, .fa-duotone.fa-file-clipboard::after {
	content: "\F0EA\F0EA"; }
  
  .fad.fa-desktop-arrow-down::after, .fa-duotone.fa-desktop-arrow-down::after {
	content: "\E155\E155"; }
  
  .fad.fa-code-pull-request::after, .fa-duotone.fa-code-pull-request::after {
	content: "\E13C\E13C"; }
  
  .fad.fa-pumpkin::after, .fa-duotone.fa-pumpkin::after {
	content: "\F707\F707"; }
  
  .fad.fa-clipboard-list::after, .fa-duotone.fa-clipboard-list::after {
	content: "\F46D\F46D"; }
  
  .fad.fa-pen-field::after, .fa-duotone.fa-pen-field::after {
	content: "\E211\E211"; }
  
  .fad.fa-blueberries::after, .fa-duotone.fa-blueberries::after {
	content: "\E2E8\E2E8"; }
  
  .fad.fa-truck-ramp-box::after, .fa-duotone.fa-truck-ramp-box::after {
	content: "\F4DE\F4DE"; }
  
  .fad.fa-truck-loading::after, .fa-duotone.fa-truck-loading::after {
	content: "\F4DE\F4DE"; }
  
  .fad.fa-note::after, .fa-duotone.fa-note::after {
	content: "\E1FF\E1FF"; }
  
  .fad.fa-arrow-down-to-square::after, .fa-duotone.fa-arrow-down-to-square::after {
	content: "\E096\E096"; }
  
  .fad.fa-user-check::after, .fa-duotone.fa-user-check::after {
	content: "\F4FC\F4FC"; }
  
  .fad.fa-cloud-xmark::after, .fa-duotone.fa-cloud-xmark::after {
	content: "\E35F\E35F"; }
  
  .fad.fa-vial-virus::after, .fa-duotone.fa-vial-virus::after {
	content: "\E597\E597"; }
  
  .fad.fa-book-blank::after, .fa-duotone.fa-book-blank::after {
	content: "\F5D9\F5D9"; }
  
  .fad.fa-book-alt::after, .fa-duotone.fa-book-alt::after {
	content: "\F5D9\F5D9"; }
  
  .fad.fa-golf-flag-hole::after, .fa-duotone.fa-golf-flag-hole::after {
	content: "\E3AC\E3AC"; }
  
  .fad.fa-message-arrow-down::after, .fa-duotone.fa-message-arrow-down::after {
	content: "\E1DB\E1DB"; }
  
  .fad.fa-comment-alt-arrow-down::after, .fa-duotone.fa-comment-alt-arrow-down::after {
	content: "\E1DB\E1DB"; }
  
  .fad.fa-face-unamused::after, .fa-duotone.fa-face-unamused::after {
	content: "\E39F\E39F"; }
  
  .fad.fa-sheet-plastic::after, .fa-duotone.fa-sheet-plastic::after {
	content: "\E571\E571"; }
  
  .fad.fa-circle-9::after, .fa-duotone.fa-circle-9::after {
	content: "\E0F6\E0F6"; }
  
  .fad.fa-blog::after, .fa-duotone.fa-blog::after {
	content: "\F781\F781"; }
  
  .fad.fa-user-ninja::after, .fa-duotone.fa-user-ninja::after {
	content: "\F504\F504"; }
  
  .fad.fa-pencil-slash::after, .fa-duotone.fa-pencil-slash::after {
	content: "\E215\E215"; }
  
  .fad.fa-bowling-pins::after, .fa-duotone.fa-bowling-pins::after {
	content: "\F437\F437"; }
  
  .fad.fa-person-arrow-up-from-line::after, .fa-duotone.fa-person-arrow-up-from-line::after {
	content: "\E539\E539"; }
  
  .fad.fa-down-right::after, .fa-duotone.fa-down-right::after {
	content: "\E16B\E16B"; }
  
  .fad.fa-scroll-torah::after, .fa-duotone.fa-scroll-torah::after {
	content: "\F6A0\F6A0"; }
  
  .fad.fa-torah::after, .fa-duotone.fa-torah::after {
	content: "\F6A0\F6A0"; }
  
  .fad.fa-blinds-open::after, .fa-duotone.fa-blinds-open::after {
	content: "\F8FC\F8FC"; }
  
  .fad.fa-fence::after, .fa-duotone.fa-fence::after {
	content: "\E303\E303"; }
  
  .fad.fa-up::after, .fa-duotone.fa-up::after {
	content: "\F357\F357"; }
  
  .fad.fa-arrow-alt-up::after, .fa-duotone.fa-arrow-alt-up::after {
	content: "\F357\F357"; }
  
  .fad.fa-broom-ball::after, .fa-duotone.fa-broom-ball::after {
	content: "\F458\F458"; }
  
  .fad.fa-quidditch::after, .fa-duotone.fa-quidditch::after {
	content: "\F458\F458"; }
  
  .fad.fa-quidditch-broom-ball::after, .fa-duotone.fa-quidditch-broom-ball::after {
	content: "\F458\F458"; }
  
  .fad.fa-drumstick::after, .fa-duotone.fa-drumstick::after {
	content: "\F6D6\F6D6"; }
  
  .fad.fa-square-v::after, .fa-duotone.fa-square-v::after {
	content: "\E284\E284"; }
  
  .fad.fa-face-awesome::after, .fa-duotone.fa-face-awesome::after {
	content: "\E409\E409"; }
  
  .fad.fa-gave-dandy::after, .fa-duotone.fa-gave-dandy::after {
	content: "\E409\E409"; }
  
  .fad.fa-dial-off::after, .fa-duotone.fa-dial-off::after {
	content: "\E162\E162"; }
  
  .fad.fa-toggle-off::after, .fa-duotone.fa-toggle-off::after {
	content: "\F204\F204"; }
  
  .fad.fa-face-smile-horns::after, .fa-duotone.fa-face-smile-horns::after {
	content: "\E391\E391"; }
  
  .fad.fa-box-archive::after, .fa-duotone.fa-box-archive::after {
	content: "\F187\F187"; }
  
  .fad.fa-archive::after, .fa-duotone.fa-archive::after {
	content: "\F187\F187"; }
  
  .fad.fa-grapes::after, .fa-duotone.fa-grapes::after {
	content: "\E306\E306"; }
  
  .fad.fa-person-drowning::after, .fa-duotone.fa-person-drowning::after {
	content: "\E545\E545"; }
  
  .fad.fa-dial-max::after, .fa-duotone.fa-dial-max::after {
	content: "\E15E\E15E"; }
  
  .fad.fa-circle-m::after, .fa-duotone.fa-circle-m::after {
	content: "\E115\E115"; }
  
  .fad.fa-calendar-image::after, .fa-duotone.fa-calendar-image::after {
	content: "\E0D4\E0D4"; }
  
  .fad.fa-circle-caret-down::after, .fa-duotone.fa-circle-caret-down::after {
	content: "\F32D\F32D"; }
  
  .fad.fa-caret-circle-down::after, .fa-duotone.fa-caret-circle-down::after {
	content: "\F32D\F32D"; }
  
  .fad.fa-arrow-down-9-1::after, .fa-duotone.fa-arrow-down-9-1::after {
	content: "\F886\F886"; }
  
  .fad.fa-sort-numeric-desc::after, .fa-duotone.fa-sort-numeric-desc::after {
	content: "\F886\F886"; }
  
  .fad.fa-sort-numeric-down-alt::after, .fa-duotone.fa-sort-numeric-down-alt::after {
	content: "\F886\F886"; }
  
  .fad.fa-face-grin-tongue-squint::after, .fa-duotone.fa-face-grin-tongue-squint::after {
	content: "\F58A\F58A"; }
  
  .fad.fa-grin-tongue-squint::after, .fa-duotone.fa-grin-tongue-squint::after {
	content: "\F58A\F58A"; }
  
  .fad.fa-shish-kebab::after, .fa-duotone.fa-shish-kebab::after {
	content: "\F821\F821"; }
  
  .fad.fa-spray-can::after, .fa-duotone.fa-spray-can::after {
	content: "\F5BD\F5BD"; }
  
  .fad.fa-alarm-snooze::after, .fa-duotone.fa-alarm-snooze::after {
	content: "\F845\F845"; }
  
  .fad.fa-scarecrow::after, .fa-duotone.fa-scarecrow::after {
	content: "\F70D\F70D"; }
  
  .fad.fa-truck-monster::after, .fa-duotone.fa-truck-monster::after {
	content: "\F63B\F63B"; }
  
  .fad.fa-gift-card::after, .fa-duotone.fa-gift-card::after {
	content: "\F663\F663"; }
  
  .fad.fa-w::after, .fa-duotone.fa-w::after {
	content: "WW"; }
  
  .fad.fa-code-pull-request-draft::after, .fa-duotone.fa-code-pull-request-draft::after {
	content: "\E3FA\E3FA"; }
  
  .fad.fa-square-b::after, .fa-duotone.fa-square-b::after {
	content: "\E264\E264"; }
  
  .fad.fa-elephant::after, .fa-duotone.fa-elephant::after {
	content: "\F6DA\F6DA"; }
  
  .fad.fa-earth-africa::after, .fa-duotone.fa-earth-africa::after {
	content: "\F57C\F57C"; }
  
  .fad.fa-globe-africa::after, .fa-duotone.fa-globe-africa::after {
	content: "\F57C\F57C"; }
  
  .fad.fa-rainbow::after, .fa-duotone.fa-rainbow::after {
	content: "\F75B\F75B"; }
  
  .fad.fa-circle-notch::after, .fa-duotone.fa-circle-notch::after {
	content: "\F1CE\F1CE"; }
  
  .fad.fa-tablet-screen-button::after, .fa-duotone.fa-tablet-screen-button::after {
	content: "\F3FA\F3FA"; }
  
  .fad.fa-tablet-alt::after, .fa-duotone.fa-tablet-alt::after {
	content: "\F3FA\F3FA"; }
  
  .fad.fa-paw::after, .fa-duotone.fa-paw::after {
	content: "\F1B0\F1B0"; }
  
  .fad.fa-message-question::after, .fa-duotone.fa-message-question::after {
	content: "\E1E3\E1E3"; }
  
  .fad.fa-cloud::after, .fa-duotone.fa-cloud::after {
	content: "\F0C2\F0C2"; }
  
  .fad.fa-trowel-bricks::after, .fa-duotone.fa-trowel-bricks::after {
	content: "\E58A\E58A"; }
  
  .fad.fa-square-3::after, .fa-duotone.fa-square-3::after {
	content: "\E258\E258"; }
  
  .fad.fa-face-flushed::after, .fa-duotone.fa-face-flushed::after {
	content: "\F579\F579"; }
  
  .fad.fa-flushed::after, .fa-duotone.fa-flushed::after {
	content: "\F579\F579"; }
  
  .fad.fa-hospital-user::after, .fa-duotone.fa-hospital-user::after {
	content: "\F80D\F80D"; }
  
  .fad.fa-microwave::after, .fa-duotone.fa-microwave::after {
	content: "\E01B\E01B"; }
  
  .fad.fa-tent-arrow-left-right::after, .fa-duotone.fa-tent-arrow-left-right::after {
	content: "\E57F\E57F"; }
  
  .fad.fa-cart-circle-arrow-up::after, .fa-duotone.fa-cart-circle-arrow-up::after {
	content: "\E3F0\E3F0"; }
  
  .fad.fa-trash-clock::after, .fa-duotone.fa-trash-clock::after {
	content: "\E2B0\E2B0"; }
  
  .fad.fa-gavel::after, .fa-duotone.fa-gavel::after {
	content: "\F0E3\F0E3"; }
  
  .fad.fa-legal::after, .fa-duotone.fa-legal::after {
	content: "\F0E3\F0E3"; }
  
  .fad.fa-sprinkler-ceiling::after, .fa-duotone.fa-sprinkler-ceiling::after {
	content: "\E44C\E44C"; }
  
  .fad.fa-browsers::after, .fa-duotone.fa-browsers::after {
	content: "\E0CB\E0CB"; }
  
  .fad.fa-trillium::after, .fa-duotone.fa-trillium::after {
	content: "\E588\E588"; }
  
  .fad.fa-music-slash::after, .fa-duotone.fa-music-slash::after {
	content: "\F8D1\F8D1"; }
  
  .fad.fa-truck-ramp::after, .fa-duotone.fa-truck-ramp::after {
	content: "\F4E0\F4E0"; }
  
  .fad.fa-binoculars::after, .fa-duotone.fa-binoculars::after {
	content: "\F1E5\F1E5"; }
  
  .fad.fa-microphone-slash::after, .fa-duotone.fa-microphone-slash::after {
	content: "\F131\F131"; }
  
  .fad.fa-box-tissue::after, .fa-duotone.fa-box-tissue::after {
	content: "\E05B\E05B"; }
  
  .fad.fa-circle-c::after, .fa-duotone.fa-circle-c::after {
	content: "\E101\E101"; }
  
  .fad.fa-star-christmas::after, .fa-duotone.fa-star-christmas::after {
	content: "\F7D4\F7D4"; }
  
  .fad.fa-chart-bullet::after, .fa-duotone.fa-chart-bullet::after {
	content: "\E0E1\E0E1"; }
  
  .fad.fa-motorcycle::after, .fa-duotone.fa-motorcycle::after {
	content: "\F21C\F21C"; }
  
  .fad.fa-tree-christmas::after, .fa-duotone.fa-tree-christmas::after {
	content: "\F7DB\F7DB"; }
  
  .fad.fa-tire-flat::after, .fa-duotone.fa-tire-flat::after {
	content: "\F632\F632"; }
  
  .fad.fa-sunglasses::after, .fa-duotone.fa-sunglasses::after {
	content: "\F892\F892"; }
  
  .fad.fa-badge::after, .fa-duotone.fa-badge::after {
	content: "\F335\F335"; }
  
  .fad.fa-message-pen::after, .fa-duotone.fa-message-pen::after {
	content: "\F4A4\F4A4"; }
  
  .fad.fa-comment-alt-edit::after, .fa-duotone.fa-comment-alt-edit::after {
	content: "\F4A4\F4A4"; }
  
  .fad.fa-message-edit::after, .fa-duotone.fa-message-edit::after {
	content: "\F4A4\F4A4"; }
  
  .fad.fa-bell-concierge::after, .fa-duotone.fa-bell-concierge::after {
	content: "\F562\F562"; }
  
  .fad.fa-concierge-bell::after, .fa-duotone.fa-concierge-bell::after {
	content: "\F562\F562"; }
  
  .fad.fa-pen-ruler::after, .fa-duotone.fa-pen-ruler::after {
	content: "\F5AE\F5AE"; }
  
  .fad.fa-pencil-ruler::after, .fa-duotone.fa-pencil-ruler::after {
	content: "\F5AE\F5AE"; }
  
  .fad.fa-chess-rook-piece::after, .fa-duotone.fa-chess-rook-piece::after {
	content: "\F448\F448"; }
  
  .fad.fa-chess-rook-alt::after, .fa-duotone.fa-chess-rook-alt::after {
	content: "\F448\F448"; }
  
  .fad.fa-square-root::after, .fa-duotone.fa-square-root::after {
	content: "\F697\F697"; }
  
  .fad.fa-album-collection-circle-plus::after, .fa-duotone.fa-album-collection-circle-plus::after {
	content: "\E48E\E48E"; }
  
  .fad.fa-people-arrows::after, .fa-duotone.fa-people-arrows::after {
	content: "\E068\E068"; }
  
  .fad.fa-people-arrows-left-right::after, .fa-duotone.fa-people-arrows-left-right::after {
	content: "\E068\E068"; }
  
  .fad.fa-face-angry-horns::after, .fa-duotone.fa-face-angry-horns::after {
	content: "\E368\E368"; }
  
  .fad.fa-mars-and-venus-burst::after, .fa-duotone.fa-mars-and-venus-burst::after {
	content: "\E523\E523"; }
  
  .fad.fa-tombstone::after, .fa-duotone.fa-tombstone::after {
	content: "\F720\F720"; }
  
  .fad.fa-square-caret-right::after, .fa-duotone.fa-square-caret-right::after {
	content: "\F152\F152"; }
  
  .fad.fa-caret-square-right::after, .fa-duotone.fa-caret-square-right::after {
	content: "\F152\F152"; }
  
  .fad.fa-scissors::after, .fa-duotone.fa-scissors::after {
	content: "\F0C4\F0C4"; }
  
  .fad.fa-cut::after, .fa-duotone.fa-cut::after {
	content: "\F0C4\F0C4"; }
  
  .fad.fa-list-music::after, .fa-duotone.fa-list-music::after {
	content: "\F8C9\F8C9"; }
  
  .fad.fa-sun-plant-wilt::after, .fa-duotone.fa-sun-plant-wilt::after {
	content: "\E57A\E57A"; }
  
  .fad.fa-toilets-portable::after, .fa-duotone.fa-toilets-portable::after {
	content: "\E584\E584"; }
  
  .fad.fa-hockey-puck::after, .fa-duotone.fa-hockey-puck::after {
	content: "\F453\F453"; }
  
  .fad.fa-hyphen::after, .fa-duotone.fa-hyphen::after {
	content: "--"; }
  
  .fad.fa-table::after, .fa-duotone.fa-table::after {
	content: "\F0CE\F0CE"; }
  
  .fad.fa-user-chef::after, .fa-duotone.fa-user-chef::after {
	content: "\E3D2\E3D2"; }
  
  .fad.fa-message-image::after, .fa-duotone.fa-message-image::after {
	content: "\E1E0\E1E0"; }
  
  .fad.fa-comment-alt-image::after, .fa-duotone.fa-comment-alt-image::after {
	content: "\E1E0\E1E0"; }
  
  .fad.fa-users-medical::after, .fa-duotone.fa-users-medical::after {
	content: "\F830\F830"; }
  
  .fad.fa-sensor-triangle-exclamation::after, .fa-duotone.fa-sensor-triangle-exclamation::after {
	content: "\E029\E029"; }
  
  .fad.fa-sensor-alert::after, .fa-duotone.fa-sensor-alert::after {
	content: "\E029\E029"; }
  
  .fad.fa-magnifying-glass-arrow-right::after, .fa-duotone.fa-magnifying-glass-arrow-right::after {
	content: "\E521\E521"; }
  
  .fad.fa-tachograph-digital::after, .fa-duotone.fa-tachograph-digital::after {
	content: "\F566\F566"; }
  
  .fad.fa-digital-tachograph::after, .fa-duotone.fa-digital-tachograph::after {
	content: "\F566\F566"; }
  
  .fad.fa-face-mask::after, .fa-duotone.fa-face-mask::after {
	content: "\E37F\E37F"; }
  
  .fad.fa-pickleball::after, .fa-duotone.fa-pickleball::after {
	content: "\E435\E435"; }
  
  .fad.fa-star-sharp-half::after, .fa-duotone.fa-star-sharp-half::after {
	content: "\E28C\E28C"; }
  
  .fad.fa-users-slash::after, .fa-duotone.fa-users-slash::after {
	content: "\E073\E073"; }
  
  .fad.fa-clover::after, .fa-duotone.fa-clover::after {
	content: "\E139\E139"; }
  
  .fad.fa-meat::after, .fa-duotone.fa-meat::after {
	content: "\F814\F814"; }
  
  .fad.fa-reply::after, .fa-duotone.fa-reply::after {
	content: "\F3E5\F3E5"; }
  
  .fad.fa-mail-reply::after, .fa-duotone.fa-mail-reply::after {
	content: "\F3E5\F3E5"; }
  
  .fad.fa-star-and-crescent::after, .fa-duotone.fa-star-and-crescent::after {
	content: "\F699\F699"; }
  
  .fad.fa-empty-set::after, .fa-duotone.fa-empty-set::after {
	content: "\F656\F656"; }
  
  .fad.fa-house-fire::after, .fa-duotone.fa-house-fire::after {
	content: "\E50C\E50C"; }
  
  .fad.fa-square-minus::after, .fa-duotone.fa-square-minus::after {
	content: "\F146\F146"; }
  
  .fad.fa-minus-square::after, .fa-duotone.fa-minus-square::after {
	content: "\F146\F146"; }
  
  .fad.fa-helicopter::after, .fa-duotone.fa-helicopter::after {
	content: "\F533\F533"; }
  
  .fad.fa-bird::after, .fa-duotone.fa-bird::after {
	content: "\E469\E469"; }
  
  .fad.fa-compass::after, .fa-duotone.fa-compass::after {
	content: "\F14E\F14E"; }
  
  .fad.fa-square-caret-down::after, .fa-duotone.fa-square-caret-down::after {
	content: "\F150\F150"; }
  
  .fad.fa-caret-square-down::after, .fa-duotone.fa-caret-square-down::after {
	content: "\F150\F150"; }
  
  .fad.fa-heart-half-stroke::after, .fa-duotone.fa-heart-half-stroke::after {
	content: "\E1AC\E1AC"; }
  
  .fad.fa-heart-half-alt::after, .fa-duotone.fa-heart-half-alt::after {
	content: "\E1AC\E1AC"; }
  
  .fad.fa-file-circle-question::after, .fa-duotone.fa-file-circle-question::after {
	content: "\E4EF\E4EF"; }
  
  .fad.fa-laptop-code::after, .fa-duotone.fa-laptop-code::after {
	content: "\F5FC\F5FC"; }
  
  .fad.fa-joystick::after, .fa-duotone.fa-joystick::after {
	content: "\F8C5\F8C5"; }
  
  .fad.fa-grill-fire::after, .fa-duotone.fa-grill-fire::after {
	content: "\E5A4\E5A4"; }
  
  .fad.fa-rectangle-vertical-history::after, .fa-duotone.fa-rectangle-vertical-history::after {
	content: "\E237\E237"; }
  
  .fad.fa-swatchbook::after, .fa-duotone.fa-swatchbook::after {
	content: "\F5C3\F5C3"; }
  
  .fad.fa-prescription-bottle::after, .fa-duotone.fa-prescription-bottle::after {
	content: "\F485\F485"; }
  
  .fad.fa-bars::after, .fa-duotone.fa-bars::after {
	content: "\F0C9\F0C9"; }
  
  .fad.fa-navicon::after, .fa-duotone.fa-navicon::after {
	content: "\F0C9\F0C9"; }
  
  .fad.fa-keyboard-left::after, .fa-duotone.fa-keyboard-left::after {
	content: "\E1C3\E1C3"; }
  
  .fad.fa-people-group::after, .fa-duotone.fa-people-group::after {
	content: "\E533\E533"; }
  
  .fad.fa-hourglass-end::after, .fa-duotone.fa-hourglass-end::after {
	content: "\F253\F253"; }
  
  .fad.fa-hourglass-3::after, .fa-duotone.fa-hourglass-3::after {
	content: "\F253\F253"; }
  
  .fad.fa-heart-crack::after, .fa-duotone.fa-heart-crack::after {
	content: "\F7A9\F7A9"; }
  
  .fad.fa-heart-broken::after, .fa-duotone.fa-heart-broken::after {
	content: "\F7A9\F7A9"; }
  
  .fad.fa-face-beam-hand-over-mouth::after, .fa-duotone.fa-face-beam-hand-over-mouth::after {
	content: "\E47C\E47C"; }
  
  .fad.fa-droplet-percent::after, .fa-duotone.fa-droplet-percent::after {
	content: "\F750\F750"; }
  
  .fad.fa-humidity::after, .fa-duotone.fa-humidity::after {
	content: "\F750\F750"; }
  
  .fad.fa-square-up-right::after, .fa-duotone.fa-square-up-right::after {
	content: "\F360\F360"; }
  
  .fad.fa-external-link-square-alt::after, .fa-duotone.fa-external-link-square-alt::after {
	content: "\F360\F360"; }
  
  .fad.fa-face-kiss-beam::after, .fa-duotone.fa-face-kiss-beam::after {
	content: "\F597\F597"; }
  
  .fad.fa-kiss-beam::after, .fa-duotone.fa-kiss-beam::after {
	content: "\F597\F597"; }
  
  .fad.fa-corn::after, .fa-duotone.fa-corn::after {
	content: "\F6C7\F6C7"; }
  
  .fad.fa-roller-coaster::after, .fa-duotone.fa-roller-coaster::after {
	content: "\E324\E324"; }
  
  .fad.fa-photo-film-music::after, .fa-duotone.fa-photo-film-music::after {
	content: "\E228\E228"; }
  
  .fad.fa-radar::after, .fa-duotone.fa-radar::after {
	content: "\E024\E024"; }
  
  .fad.fa-sickle::after, .fa-duotone.fa-sickle::after {
	content: "\F822\F822"; }
  
  .fad.fa-film::after, .fa-duotone.fa-film::after {
	content: "\F008\F008"; }
  
  .fad.fa-coconut::after, .fa-duotone.fa-coconut::after {
	content: "\E2F6\E2F6"; }
  
  .fad.fa-ruler-horizontal::after, .fa-duotone.fa-ruler-horizontal::after {
	content: "\F547\F547"; }
  
  .fad.fa-shield-cross::after, .fa-duotone.fa-shield-cross::after {
	content: "\F712\F712"; }
  
  .fad.fa-cassette-tape::after, .fa-duotone.fa-cassette-tape::after {
	content: "\F8AB\F8AB"; }
  
  .fad.fa-square-terminal::after, .fa-duotone.fa-square-terminal::after {
	content: "\E32A\E32A"; }
  
  .fad.fa-people-robbery::after, .fa-duotone.fa-people-robbery::after {
	content: "\E536\E536"; }
  
  .fad.fa-lightbulb::after, .fa-duotone.fa-lightbulb::after {
	content: "\F0EB\F0EB"; }
  
  .fad.fa-caret-left::after, .fa-duotone.fa-caret-left::after {
	content: "\F0D9\F0D9"; }
  
  .fad.fa-comment-middle::after, .fa-duotone.fa-comment-middle::after {
	content: "\E149\E149"; }
  
  .fad.fa-trash-can-list::after, .fa-duotone.fa-trash-can-list::after {
	content: "\E2AB\E2AB"; }
  
  .fad.fa-block::after, .fa-duotone.fa-block::after {
	content: "\E46A\E46A"; }
  
  .fad.fa-circle-exclamation::after, .fa-duotone.fa-circle-exclamation::after {
	content: "\F06A\F06A"; }
  
  .fad.fa-exclamation-circle::after, .fa-duotone.fa-exclamation-circle::after {
	content: "\F06A\F06A"; }
  
  .fad.fa-school-circle-xmark::after, .fa-duotone.fa-school-circle-xmark::after {
	content: "\E56D\E56D"; }
  
  .fad.fa-arrow-right-from-bracket::after, .fa-duotone.fa-arrow-right-from-bracket::after {
	content: "\F08B\F08B"; }
  
  .fad.fa-sign-out::after, .fa-duotone.fa-sign-out::after {
	content: "\F08B\F08B"; }
  
  .fad.fa-face-frown-slight::after, .fa-duotone.fa-face-frown-slight::after {
	content: "\E376\E376"; }
  
  .fad.fa-circle-chevron-down::after, .fa-duotone.fa-circle-chevron-down::after {
	content: "\F13A\F13A"; }
  
  .fad.fa-chevron-circle-down::after, .fa-duotone.fa-chevron-circle-down::after {
	content: "\F13A\F13A"; }
  
  .fad.fa-sidebar-flip::after, .fa-duotone.fa-sidebar-flip::after {
	content: "\E24F\E24F"; }
  
  .fad.fa-unlock-keyhole::after, .fa-duotone.fa-unlock-keyhole::after {
	content: "\F13E\F13E"; }
  
  .fad.fa-unlock-alt::after, .fa-duotone.fa-unlock-alt::after {
	content: "\F13E\F13E"; }
  
  .fad.fa-temperature-list::after, .fa-duotone.fa-temperature-list::after {
	content: "\E299\E299"; }
  
  .fad.fa-cloud-showers-heavy::after, .fa-duotone.fa-cloud-showers-heavy::after {
	content: "\F740\F740"; }
  
  .fad.fa-headphones-simple::after, .fa-duotone.fa-headphones-simple::after {
	content: "\F58F\F58F"; }
  
  .fad.fa-headphones-alt::after, .fa-duotone.fa-headphones-alt::after {
	content: "\F58F\F58F"; }
  
  .fad.fa-sitemap::after, .fa-duotone.fa-sitemap::after {
	content: "\F0E8\F0E8"; }
  
  .fad.fa-pipe-section::after, .fa-duotone.fa-pipe-section::after {
	content: "\E438\E438"; }
  
  .fad.fa-space-station-moon-construction::after, .fa-duotone.fa-space-station-moon-construction::after {
	content: "\E034\E034"; }
  
  .fad.fa-space-station-moon-alt::after, .fa-duotone.fa-space-station-moon-alt::after {
	content: "\E034\E034"; }
  
  .fad.fa-circle-dollar-to-slot::after, .fa-duotone.fa-circle-dollar-to-slot::after {
	content: "\F4B9\F4B9"; }
  
  .fad.fa-donate::after, .fa-duotone.fa-donate::after {
	content: "\F4B9\F4B9"; }
  
  .fad.fa-memory::after, .fa-duotone.fa-memory::after {
	content: "\F538\F538"; }
  
  .fad.fa-face-sleeping::after, .fa-duotone.fa-face-sleeping::after {
	content: "\E38D\E38D"; }
  
  .fad.fa-road-spikes::after, .fa-duotone.fa-road-spikes::after {
	content: "\E568\E568"; }
  
  .fad.fa-fire-burner::after, .fa-duotone.fa-fire-burner::after {
	content: "\E4F1\E4F1"; }
  
  .fad.fa-squirrel::after, .fa-duotone.fa-squirrel::after {
	content: "\F71A\F71A"; }
  
  .fad.fa-arrow-up-to-line::after, .fa-duotone.fa-arrow-up-to-line::after {
	content: "\F341\F341"; }
  
  .fad.fa-arrow-to-top::after, .fa-duotone.fa-arrow-to-top::after {
	content: "\F341\F341"; }
  
  .fad.fa-flag::after, .fa-duotone.fa-flag::after {
	content: "\F024\F024"; }
  
  .fad.fa-face-cowboy-hat::after, .fa-duotone.fa-face-cowboy-hat::after {
	content: "\E36E\E36E"; }
  
  .fad.fa-hanukiah::after, .fa-duotone.fa-hanukiah::after {
	content: "\F6E6\F6E6"; }
  
  .fad.fa-chart-scatter-3d::after, .fa-duotone.fa-chart-scatter-3d::after {
	content: "\E0E8\E0E8"; }
  
  .fad.fa-square-code::after, .fa-duotone.fa-square-code::after {
	content: "\E267\E267"; }
  
  .fad.fa-feather::after, .fa-duotone.fa-feather::after {
	content: "\F52D\F52D"; }
  
  .fad.fa-volume-low::after, .fa-duotone.fa-volume-low::after {
	content: "\F027\F027"; }
  
  .fad.fa-volume-down::after, .fa-duotone.fa-volume-down::after {
	content: "\F027\F027"; }
  
  .fad.fa-xmark-to-slot::after, .fa-duotone.fa-xmark-to-slot::after {
	content: "\F771\F771"; }
  
  .fad.fa-times-to-slot::after, .fa-duotone.fa-times-to-slot::after {
	content: "\F771\F771"; }
  
  .fad.fa-vote-nay::after, .fa-duotone.fa-vote-nay::after {
	content: "\F771\F771"; }
  
  .fad.fa-box-taped::after, .fa-duotone.fa-box-taped::after {
	content: "\F49A\F49A"; }
  
  .fad.fa-box-alt::after, .fa-duotone.fa-box-alt::after {
	content: "\F49A\F49A"; }
  
  .fad.fa-comment-slash::after, .fa-duotone.fa-comment-slash::after {
	content: "\F4B3\F4B3"; }
  
  .fad.fa-swords::after, .fa-duotone.fa-swords::after {
	content: "\F71D\F71D"; }
  
  .fad.fa-cloud-sun-rain::after, .fa-duotone.fa-cloud-sun-rain::after {
	content: "\F743\F743"; }
  
  .fad.fa-album::after, .fa-duotone.fa-album::after {
	content: "\F89F\F89F"; }
  
  .fad.fa-circle-n::after, .fa-duotone.fa-circle-n::after {
	content: "\E118\E118"; }
  
  .fad.fa-compress::after, .fa-duotone.fa-compress::after {
	content: "\F066\F066"; }
  
  .fad.fa-wheat-awn::after, .fa-duotone.fa-wheat-awn::after {
	content: "\E2CD\E2CD"; }
  
  .fad.fa-wheat-alt::after, .fa-duotone.fa-wheat-alt::after {
	content: "\E2CD\E2CD"; }
  
  .fad.fa-ankh::after, .fa-duotone.fa-ankh::after {
	content: "\F644\F644"; }
  
  .fad.fa-hands-holding-child::after, .fa-duotone.fa-hands-holding-child::after {
	content: "\E4FA\E4FA"; }
  
  .fad.fa-asterisk::after, .fa-duotone.fa-asterisk::after {
	content: "**"; }
  
  .fad.fa-key-skeleton-left-right::after, .fa-duotone.fa-key-skeleton-left-right::after {
	content: "\E3B4\E3B4"; }
  
  .fad.fa-comment-lines::after, .fa-duotone.fa-comment-lines::after {
	content: "\F4B0\F4B0"; }
  
  .fad.fa-luchador-mask::after, .fa-duotone.fa-luchador-mask::after {
	content: "\F455\F455"; }
  
  .fad.fa-luchador::after, .fa-duotone.fa-luchador::after {
	content: "\F455\F455"; }
  
  .fad.fa-mask-luchador::after, .fa-duotone.fa-mask-luchador::after {
	content: "\F455\F455"; }
  
  .fad.fa-square-check::after, .fa-duotone.fa-square-check::after {
	content: "\F14A\F14A"; }
  
  .fad.fa-check-square::after, .fa-duotone.fa-check-square::after {
	content: "\F14A\F14A"; }
  
  .fad.fa-shredder::after, .fa-duotone.fa-shredder::after {
	content: "\F68A\F68A"; }
  
  .fad.fa-book-open-cover::after, .fa-duotone.fa-book-open-cover::after {
	content: "\E0C0\E0C0"; }
  
  .fad.fa-book-open-alt::after, .fa-duotone.fa-book-open-alt::after {
	content: "\E0C0\E0C0"; }
  
  .fad.fa-sandwich::after, .fa-duotone.fa-sandwich::after {
	content: "\F81F\F81F"; }
  
  .fad.fa-peseta-sign::after, .fa-duotone.fa-peseta-sign::after {
	content: "\E221\E221"; }
  
  .fad.fa-square-parking-slash::after, .fa-duotone.fa-square-parking-slash::after {
	content: "\F617\F617"; }
  
  .fad.fa-parking-slash::after, .fa-duotone.fa-parking-slash::after {
	content: "\F617\F617"; }
  
  .fad.fa-train-tunnel::after, .fa-duotone.fa-train-tunnel::after {
	content: "\E454\E454"; }
  
  .fad.fa-heading::after, .fa-duotone.fa-heading::after {
	content: "\F1DC\F1DC"; }
  
  .fad.fa-header::after, .fa-duotone.fa-header::after {
	content: "\F1DC\F1DC"; }
  
  .fad.fa-ghost::after, .fa-duotone.fa-ghost::after {
	content: "\F6E2\F6E2"; }
  
  .fad.fa-face-anguished::after, .fa-duotone.fa-face-anguished::after {
	content: "\E369\E369"; }
  
  .fad.fa-hockey-sticks::after, .fa-duotone.fa-hockey-sticks::after {
	content: "\F454\F454"; }
  
  .fad.fa-abacus::after, .fa-duotone.fa-abacus::after {
	content: "\F640\F640"; }
  
  .fad.fa-film-simple::after, .fa-duotone.fa-film-simple::after {
	content: "\F3A0\F3A0"; }
  
  .fad.fa-film-alt::after, .fa-duotone.fa-film-alt::after {
	content: "\F3A0\F3A0"; }
  
  .fad.fa-list::after, .fa-duotone.fa-list::after {
	content: "\F03A\F03A"; }
  
  .fad.fa-list-squares::after, .fa-duotone.fa-list-squares::after {
	content: "\F03A\F03A"; }
  
  .fad.fa-tree-palm::after, .fa-duotone.fa-tree-palm::after {
	content: "\F82B\F82B"; }
  
  .fad.fa-square-phone-flip::after, .fa-duotone.fa-square-phone-flip::after {
	content: "\F87B\F87B"; }
  
  .fad.fa-phone-square-alt::after, .fa-duotone.fa-phone-square-alt::after {
	content: "\F87B\F87B"; }
  
  .fad.fa-cart-plus::after, .fa-duotone.fa-cart-plus::after {
	content: "\F217\F217"; }
  
  .fad.fa-gamepad::after, .fa-duotone.fa-gamepad::after {
	content: "\F11B\F11B"; }
  
  .fad.fa-border-center-v::after, .fa-duotone.fa-border-center-v::after {
	content: "\F89D\F89D"; }
  
  .fad.fa-circle-dot::after, .fa-duotone.fa-circle-dot::after {
	content: "\F192\F192"; }
  
  .fad.fa-dot-circle::after, .fa-duotone.fa-dot-circle::after {
	content: "\F192\F192"; }
  
  .fad.fa-clipboard-medical::after, .fa-duotone.fa-clipboard-medical::after {
	content: "\E133\E133"; }
  
  .fad.fa-face-dizzy::after, .fa-duotone.fa-face-dizzy::after {
	content: "\F567\F567"; }
  
  .fad.fa-dizzy::after, .fa-duotone.fa-dizzy::after {
	content: "\F567\F567"; }
  
  .fad.fa-egg::after, .fa-duotone.fa-egg::after {
	content: "\F7FB\F7FB"; }
  
  .fad.fa-up-to-line::after, .fa-duotone.fa-up-to-line::after {
	content: "\F34D\F34D"; }
  
  .fad.fa-arrow-alt-to-top::after, .fa-duotone.fa-arrow-alt-to-top::after {
	content: "\F34D\F34D"; }
  
  .fad.fa-house-medical-circle-xmark::after, .fa-duotone.fa-house-medical-circle-xmark::after {
	content: "\E513\E513"; }
  
  .fad.fa-watch-fitness::after, .fa-duotone.fa-watch-fitness::after {
	content: "\F63E\F63E"; }
  
  .fad.fa-clock-nine-thirty::after, .fa-duotone.fa-clock-nine-thirty::after {
	content: "\E34D\E34D"; }
  
  .fad.fa-campground::after, .fa-duotone.fa-campground::after {
	content: "\F6BB\F6BB"; }
  
  .fad.fa-folder-plus::after, .fa-duotone.fa-folder-plus::after {
	content: "\F65E\F65E"; }
  
  .fad.fa-jug::after, .fa-duotone.fa-jug::after {
	content: "\F8C6\F8C6"; }
  
  .fad.fa-futbol::after, .fa-duotone.fa-futbol::after {
	content: "\F1E3\F1E3"; }
  
  .fad.fa-futbol-ball::after, .fa-duotone.fa-futbol-ball::after {
	content: "\F1E3\F1E3"; }
  
  .fad.fa-soccer-ball::after, .fa-duotone.fa-soccer-ball::after {
	content: "\F1E3\F1E3"; }
  
  .fad.fa-snow-blowing::after, .fa-duotone.fa-snow-blowing::after {
	content: "\F761\F761"; }
  
  .fad.fa-paintbrush::after, .fa-duotone.fa-paintbrush::after {
	content: "\F1FC\F1FC"; }
  
  .fad.fa-paint-brush::after, .fa-duotone.fa-paint-brush::after {
	content: "\F1FC\F1FC"; }
  
  .fad.fa-lock::after, .fa-duotone.fa-lock::after {
	content: "\F023\F023"; }
  
  .fad.fa-arrow-down-from-line::after, .fa-duotone.fa-arrow-down-from-line::after {
	content: "\F345\F345"; }
  
  .fad.fa-arrow-from-top::after, .fa-duotone.fa-arrow-from-top::after {
	content: "\F345\F345"; }
  
  .fad.fa-gas-pump::after, .fa-duotone.fa-gas-pump::after {
	content: "\F52F\F52F"; }
  
  .fad.fa-signal-bars-slash::after, .fa-duotone.fa-signal-bars-slash::after {
	content: "\F694\F694"; }
  
  .fad.fa-signal-alt-slash::after, .fa-duotone.fa-signal-alt-slash::after {
	content: "\F694\F694"; }
  
  .fad.fa-monkey::after, .fa-duotone.fa-monkey::after {
	content: "\F6FB\F6FB"; }
  
  .fad.fa-rectangle-pro::after, .fa-duotone.fa-rectangle-pro::after {
	content: "\E235\E235"; }
  
  .fad.fa-pro::after, .fa-duotone.fa-pro::after {
	content: "\E235\E235"; }
  
  .fad.fa-house-night::after, .fa-duotone.fa-house-night::after {
	content: "\E010\E010"; }
  
  .fad.fa-hot-tub-person::after, .fa-duotone.fa-hot-tub-person::after {
	content: "\F593\F593"; }
  
  .fad.fa-hot-tub::after, .fa-duotone.fa-hot-tub::after {
	content: "\F593\F593"; }
  
  .fad.fa-blanket::after, .fa-duotone.fa-blanket::after {
	content: "\F498\F498"; }
  
  .fad.fa-map-location::after, .fa-duotone.fa-map-location::after {
	content: "\F59F\F59F"; }
  
  .fad.fa-map-marked::after, .fa-duotone.fa-map-marked::after {
	content: "\F59F\F59F"; }
  
  .fad.fa-house-flood-water::after, .fa-duotone.fa-house-flood-water::after {
	content: "\E50E\E50E"; }
  
  .fad.fa-comments-question-check::after, .fa-duotone.fa-comments-question-check::after {
	content: "\E14F\E14F"; }
  
  .fad.fa-tree::after, .fa-duotone.fa-tree::after {
	content: "\F1BB\F1BB"; }
  
  .fad.fa-arrows-cross::after, .fa-duotone.fa-arrows-cross::after {
	content: "\E0A2\E0A2"; }
  
  .fad.fa-backpack::after, .fa-duotone.fa-backpack::after {
	content: "\F5D4\F5D4"; }
  
  .fad.fa-square-small::after, .fa-duotone.fa-square-small::after {
	content: "\E27E\E27E"; }
  
  .fad.fa-folder-arrow-up::after, .fa-duotone.fa-folder-arrow-up::after {
	content: "\E054\E054"; }
  
  .fad.fa-folder-upload::after, .fa-duotone.fa-folder-upload::after {
	content: "\E054\E054"; }
  
  .fad.fa-bridge-lock::after, .fa-duotone.fa-bridge-lock::after {
	content: "\E4CC\E4CC"; }
  
  .fad.fa-crosshairs-simple::after, .fa-duotone.fa-crosshairs-simple::after {
	content: "\E59F\E59F"; }
  
  .fad.fa-sack-dollar::after, .fa-duotone.fa-sack-dollar::after {
	content: "\F81D\F81D"; }
  
  .fad.fa-pen-to-square::after, .fa-duotone.fa-pen-to-square::after {
	content: "\F044\F044"; }
  
  .fad.fa-edit::after, .fa-duotone.fa-edit::after {
	content: "\F044\F044"; }
  
  .fad.fa-square-sliders::after, .fa-duotone.fa-square-sliders::after {
	content: "\F3F0\F3F0"; }
  
  .fad.fa-sliders-h-square::after, .fa-duotone.fa-sliders-h-square::after {
	content: "\F3F0\F3F0"; }
  
  .fad.fa-car-side::after, .fa-duotone.fa-car-side::after {
	content: "\F5E4\F5E4"; }
  
  .fad.fa-message-middle-top::after, .fa-duotone.fa-message-middle-top::after {
	content: "\E1E2\E1E2"; }
  
  .fad.fa-comment-middle-top-alt::after, .fa-duotone.fa-comment-middle-top-alt::after {
	content: "\E1E2\E1E2"; }
  
  .fad.fa-lightbulb-on::after, .fa-duotone.fa-lightbulb-on::after {
	content: "\F672\F672"; }
  
  .fad.fa-knife::after, .fa-duotone.fa-knife::after {
	content: "\F2E4\F2E4"; }
  
  .fad.fa-utensil-knife::after, .fa-duotone.fa-utensil-knife::after {
	content: "\F2E4\F2E4"; }
  
  .fad.fa-share-nodes::after, .fa-duotone.fa-share-nodes::after {
	content: "\F1E0\F1E0"; }
  
  .fad.fa-share-alt::after, .fa-duotone.fa-share-alt::after {
	content: "\F1E0\F1E0"; }
  
  .fad.fa-wave-sine::after, .fa-duotone.fa-wave-sine::after {
	content: "\F899\F899"; }
  
  .fad.fa-heart-circle-minus::after, .fa-duotone.fa-heart-circle-minus::after {
	content: "\E4FF\E4FF"; }
  
  .fad.fa-circle-w::after, .fa-duotone.fa-circle-w::after {
	content: "\E12C\E12C"; }
  
  .fad.fa-circle-calendar::after, .fa-duotone.fa-circle-calendar::after {
	content: "\E102\E102"; }
  
  .fad.fa-calendar-circle::after, .fa-duotone.fa-calendar-circle::after {
	content: "\E102\E102"; }
  
  .fad.fa-hourglass-half::after, .fa-duotone.fa-hourglass-half::after {
	content: "\F252\F252"; }
  
  .fad.fa-hourglass-2::after, .fa-duotone.fa-hourglass-2::after {
	content: "\F252\F252"; }
  
  .fad.fa-microscope::after, .fa-duotone.fa-microscope::after {
	content: "\F610\F610"; }
  
  .fad.fa-sunset::after, .fa-duotone.fa-sunset::after {
	content: "\F767\F767"; }
  
  .fad.fa-sink::after, .fa-duotone.fa-sink::after {
	content: "\E06D\E06D"; }
  
  .fad.fa-calendar-exclamation::after, .fa-duotone.fa-calendar-exclamation::after {
	content: "\F334\F334"; }
  
  .fad.fa-truck-container-empty::after, .fa-duotone.fa-truck-container-empty::after {
	content: "\E2B5\E2B5"; }
  
  .fad.fa-hand-heart::after, .fa-duotone.fa-hand-heart::after {
	content: "\F4BC\F4BC"; }
  
  .fad.fa-bag-shopping::after, .fa-duotone.fa-bag-shopping::after {
	content: "\F290\F290"; }
  
  .fad.fa-shopping-bag::after, .fa-duotone.fa-shopping-bag::after {
	content: "\F290\F290"; }
  
  .fad.fa-arrow-down-z-a::after, .fa-duotone.fa-arrow-down-z-a::after {
	content: "\F881\F881"; }
  
  .fad.fa-sort-alpha-desc::after, .fa-duotone.fa-sort-alpha-desc::after {
	content: "\F881\F881"; }
  
  .fad.fa-sort-alpha-down-alt::after, .fa-duotone.fa-sort-alpha-down-alt::after {
	content: "\F881\F881"; }
  
  .fad.fa-mitten::after, .fa-duotone.fa-mitten::after {
	content: "\F7B5\F7B5"; }
  
  .fad.fa-reply-clock::after, .fa-duotone.fa-reply-clock::after {
	content: "\E239\E239"; }
  
  .fad.fa-reply-time::after, .fa-duotone.fa-reply-time::after {
	content: "\E239\E239"; }
  
  .fad.fa-person-rays::after, .fa-duotone.fa-person-rays::after {
	content: "\E54D\E54D"; }
  
  .fad.fa-right::after, .fa-duotone.fa-right::after {
	content: "\F356\F356"; }
  
  .fad.fa-arrow-alt-right::after, .fa-duotone.fa-arrow-alt-right::after {
	content: "\F356\F356"; }
  
  .fad.fa-circle-f::after, .fa-duotone.fa-circle-f::after {
	content: "\E10E\E10E"; }
  
  .fad.fa-users::after, .fa-duotone.fa-users::after {
	content: "\F0C0\F0C0"; }
  
  .fad.fa-face-pleading::after, .fa-duotone.fa-face-pleading::after {
	content: "\E386\E386"; }
  
  .fad.fa-eye-slash::after, .fa-duotone.fa-eye-slash::after {
	content: "\F070\F070"; }
  
  .fad.fa-flask-vial::after, .fa-duotone.fa-flask-vial::after {
	content: "\E4F3\E4F3"; }
  
  .fad.fa-police-box::after, .fa-duotone.fa-police-box::after {
	content: "\E021\E021"; }
  
  .fad.fa-cucumber::after, .fa-duotone.fa-cucumber::after {
	content: "\E401\E401"; }
  
  .fad.fa-head-side-brain::after, .fa-duotone.fa-head-side-brain::after {
	content: "\F808\F808"; }
  
  .fad.fa-hand::after, .fa-duotone.fa-hand::after {
	content: "\F256\F256"; }
  
  .fad.fa-hand-paper::after, .fa-duotone.fa-hand-paper::after {
	content: "\F256\F256"; }
  
  .fad.fa-person-biking-mountain::after, .fa-duotone.fa-person-biking-mountain::after {
	content: "\F84B\F84B"; }
  
  .fad.fa-biking-mountain::after, .fa-duotone.fa-biking-mountain::after {
	content: "\F84B\F84B"; }
  
  .fad.fa-utensils-slash::after, .fa-duotone.fa-utensils-slash::after {
	content: "\E464\E464"; }
  
  .fad.fa-print-magnifying-glass::after, .fa-duotone.fa-print-magnifying-glass::after {
	content: "\F81A\F81A"; }
  
  .fad.fa-print-search::after, .fa-duotone.fa-print-search::after {
	content: "\F81A\F81A"; }
  
  .fad.fa-folder-bookmark::after, .fa-duotone.fa-folder-bookmark::after {
	content: "\E186\E186"; }
  
  .fad.fa-om::after, .fa-duotone.fa-om::after {
	content: "\F679\F679"; }
  
  .fad.fa-pi::after, .fa-duotone.fa-pi::after {
	content: "\F67E\F67E"; }
  
  .fad.fa-flask-round-potion::after, .fa-duotone.fa-flask-round-potion::after {
	content: "\F6E1\F6E1"; }
  
  .fad.fa-flask-potion::after, .fa-duotone.fa-flask-potion::after {
	content: "\F6E1\F6E1"; }
  
  .fad.fa-face-shush::after, .fa-duotone.fa-face-shush::after {
	content: "\E38C\E38C"; }
  
  .fad.fa-worm::after, .fa-duotone.fa-worm::after {
	content: "\E599\E599"; }
  
  .fad.fa-house-circle-xmark::after, .fa-duotone.fa-house-circle-xmark::after {
	content: "\E50B\E50B"; }
  
  .fad.fa-plug::after, .fa-duotone.fa-plug::after {
	content: "\F1E6\F1E6"; }
  
  .fad.fa-calendar-circle-exclamation::after, .fa-duotone.fa-calendar-circle-exclamation::after {
	content: "\E46E\E46E"; }
  
  .fad.fa-square-i::after, .fa-duotone.fa-square-i::after {
	content: "\E272\E272"; }
  
  .fad.fa-chevron-up::after, .fa-duotone.fa-chevron-up::after {
	content: "\F077\F077"; }
  
  .fad.fa-face-saluting::after, .fa-duotone.fa-face-saluting::after {
	content: "\E484\E484"; }
  
  .fad.fa-gauge-simple-low::after, .fa-duotone.fa-gauge-simple-low::after {
	content: "\F62C\F62C"; }
  
  .fad.fa-tachometer-slow::after, .fa-duotone.fa-tachometer-slow::after {
	content: "\F62C\F62C"; }
  
  .fad.fa-face-persevering::after, .fa-duotone.fa-face-persevering::after {
	content: "\E385\E385"; }
  
  .fad.fa-circle-camera::after, .fa-duotone.fa-circle-camera::after {
	content: "\E103\E103"; }
  
  .fad.fa-camera-circle::after, .fa-duotone.fa-camera-circle::after {
	content: "\E103\E103"; }
  
  .fad.fa-hand-spock::after, .fa-duotone.fa-hand-spock::after {
	content: "\F259\F259"; }
  
  .fad.fa-spider-web::after, .fa-duotone.fa-spider-web::after {
	content: "\F719\F719"; }
  
  .fad.fa-circle-microphone::after, .fa-duotone.fa-circle-microphone::after {
	content: "\E116\E116"; }
  
  .fad.fa-microphone-circle::after, .fa-duotone.fa-microphone-circle::after {
	content: "\E116\E116"; }
  
  .fad.fa-book-arrow-up::after, .fa-duotone.fa-book-arrow-up::after {
	content: "\E0BA\E0BA"; }
  
  .fad.fa-popsicle::after, .fa-duotone.fa-popsicle::after {
	content: "\E43E\E43E"; }
  
  .fad.fa-command::after, .fa-duotone.fa-command::after {
	content: "\E142\E142"; }
  
  .fad.fa-blinds::after, .fa-duotone.fa-blinds::after {
	content: "\F8FB\F8FB"; }
  
  .fad.fa-stopwatch::after, .fa-duotone.fa-stopwatch::after {
	content: "\F2F2\F2F2"; }
  
  .fad.fa-saxophone::after, .fa-duotone.fa-saxophone::after {
	content: "\F8DC\F8DC"; }
  
  .fad.fa-square-2::after, .fa-duotone.fa-square-2::after {
	content: "\E257\E257"; }
  
  .fad.fa-field-hockey-stick-ball::after, .fa-duotone.fa-field-hockey-stick-ball::after {
	content: "\F44C\F44C"; }
  
  .fad.fa-field-hockey::after, .fa-duotone.fa-field-hockey::after {
	content: "\F44C\F44C"; }
  
  .fad.fa-arrow-up-square-triangle::after, .fa-duotone.fa-arrow-up-square-triangle::after {
	content: "\F88B\F88B"; }
  
  .fad.fa-sort-shapes-up-alt::after, .fa-duotone.fa-sort-shapes-up-alt::after {
	content: "\F88B\F88B"; }
  
  .fad.fa-face-scream::after, .fa-duotone.fa-face-scream::after {
	content: "\E38B\E38B"; }
  
  .fad.fa-square-m::after, .fa-duotone.fa-square-m::after {
	content: "\E276\E276"; }
  
  .fad.fa-camera-web::after, .fa-duotone.fa-camera-web::after {
	content: "\F832\F832"; }
  
  .fad.fa-webcam::after, .fa-duotone.fa-webcam::after {
	content: "\F832\F832"; }
  
  .fad.fa-comment-arrow-down::after, .fa-duotone.fa-comment-arrow-down::after {
	content: "\E143\E143"; }
  
  .fad.fa-lightbulb-cfl::after, .fa-duotone.fa-lightbulb-cfl::after {
	content: "\E5A6\E5A6"; }
  
  .fad.fa-window-frame-open::after, .fa-duotone.fa-window-frame-open::after {
	content: "\E050\E050"; }
  
  .fad.fa-face-kiss::after, .fa-duotone.fa-face-kiss::after {
	content: "\F596\F596"; }
  
  .fad.fa-kiss::after, .fa-duotone.fa-kiss::after {
	content: "\F596\F596"; }
  
  .fad.fa-bridge-circle-xmark::after, .fa-duotone.fa-bridge-circle-xmark::after {
	content: "\E4CB\E4CB"; }
  
  .fad.fa-period::after, .fa-duotone.fa-period::after {
	content: ".."; }
  
  .fad.fa-face-grin-tongue::after, .fa-duotone.fa-face-grin-tongue::after {
	content: "\F589\F589"; }
  
  .fad.fa-grin-tongue::after, .fa-duotone.fa-grin-tongue::after {
	content: "\F589\F589"; }
  
  .fad.fa-up-to-dotted-line::after, .fa-duotone.fa-up-to-dotted-line::after {
	content: "\E457\E457"; }
  
  .fad.fa-thought-bubble::after, .fa-duotone.fa-thought-bubble::after {
	content: "\E32E\E32E"; }
  
  .fad.fa-raygun::after, .fa-duotone.fa-raygun::after {
	content: "\E025\E025"; }
  
  .fad.fa-flute::after, .fa-duotone.fa-flute::after {
	content: "\F8B9\F8B9"; }
  
  .fad.fa-acorn::after, .fa-duotone.fa-acorn::after {
	content: "\F6AE\F6AE"; }
  
  .fad.fa-video-arrow-up-right::after, .fa-duotone.fa-video-arrow-up-right::after {
	content: "\E2C9\E2C9"; }
  
  .fad.fa-grate-droplet::after, .fa-duotone.fa-grate-droplet::after {
	content: "\E194\E194"; }
  
  .fad.fa-seal-exclamation::after, .fa-duotone.fa-seal-exclamation::after {
	content: "\E242\E242"; }
  
  .fad.fa-chess-bishop::after, .fa-duotone.fa-chess-bishop::after {
	content: "\F43A\F43A"; }
  
  .fad.fa-message-sms::after, .fa-duotone.fa-message-sms::after {
	content: "\E1E5\E1E5"; }
  
  .fad.fa-coffee-beans::after, .fa-duotone.fa-coffee-beans::after {
	content: "\E13F\E13F"; }
  
  .fad.fa-hat-witch::after, .fa-duotone.fa-hat-witch::after {
	content: "\F6E7\F6E7"; }
  
  .fad.fa-face-grin-wink::after, .fa-duotone.fa-face-grin-wink::after {
	content: "\F58C\F58C"; }
  
  .fad.fa-grin-wink::after, .fa-duotone.fa-grin-wink::after {
	content: "\F58C\F58C"; }
  
  .fad.fa-clock-three-thirty::after, .fa-duotone.fa-clock-three-thirty::after {
	content: "\E357\E357"; }
  
  .fad.fa-ear-deaf::after, .fa-duotone.fa-ear-deaf::after {
	content: "\F2A4\F2A4"; }
  
  .fad.fa-deaf::after, .fa-duotone.fa-deaf::after {
	content: "\F2A4\F2A4"; }
  
  .fad.fa-deafness::after, .fa-duotone.fa-deafness::after {
	content: "\F2A4\F2A4"; }
  
  .fad.fa-hard-of-hearing::after, .fa-duotone.fa-hard-of-hearing::after {
	content: "\F2A4\F2A4"; }
  
  .fad.fa-alarm-clock::after, .fa-duotone.fa-alarm-clock::after {
	content: "\F34E\F34E"; }
  
  .fad.fa-eclipse::after, .fa-duotone.fa-eclipse::after {
	content: "\F749\F749"; }
  
  .fad.fa-face-relieved::after, .fa-duotone.fa-face-relieved::after {
	content: "\E389\E389"; }
  
  .fad.fa-road-circle-check::after, .fa-duotone.fa-road-circle-check::after {
	content: "\E564\E564"; }
  
  .fad.fa-dice-five::after, .fa-duotone.fa-dice-five::after {
	content: "\F523\F523"; }
  
  .fad.fa-octagon-minus::after, .fa-duotone.fa-octagon-minus::after {
	content: "\F308\F308"; }
  
  .fad.fa-minus-octagon::after, .fa-duotone.fa-minus-octagon::after {
	content: "\F308\F308"; }
  
  .fad.fa-square-rss::after, .fa-duotone.fa-square-rss::after {
	content: "\F143\F143"; }
  
  .fad.fa-rss-square::after, .fa-duotone.fa-rss-square::after {
	content: "\F143\F143"; }
  
  .fad.fa-face-zany::after, .fa-duotone.fa-face-zany::after {
	content: "\E3A4\E3A4"; }
  
  .fad.fa-land-mine-on::after, .fa-duotone.fa-land-mine-on::after {
	content: "\E51B\E51B"; }
  
  .fad.fa-square-arrow-up-left::after, .fa-duotone.fa-square-arrow-up-left::after {
	content: "\E263\E263"; }
  
  .fad.fa-i-cursor::after, .fa-duotone.fa-i-cursor::after {
	content: "\F246\F246"; }
  
  .fad.fa-salt-shaker::after, .fa-duotone.fa-salt-shaker::after {
	content: "\E446\E446"; }
  
  .fad.fa-stamp::after, .fa-duotone.fa-stamp::after {
	content: "\F5BF\F5BF"; }
  
  .fad.fa-file-plus::after, .fa-duotone.fa-file-plus::after {
	content: "\F319\F319"; }
  
  .fad.fa-draw-square::after, .fa-duotone.fa-draw-square::after {
	content: "\F5EF\F5EF"; }
  
  .fad.fa-toilet-paper-under-slash::after, .fa-duotone.fa-toilet-paper-under-slash::after {
	content: "\E2A1\E2A1"; }
  
  .fad.fa-toilet-paper-reverse-slash::after, .fa-duotone.fa-toilet-paper-reverse-slash::after {
	content: "\E2A1\E2A1"; }
  
  .fad.fa-stairs::after, .fa-duotone.fa-stairs::after {
	content: "\E289\E289"; }
  
  .fad.fa-drone-front::after, .fa-duotone.fa-drone-front::after {
	content: "\F860\F860"; }
  
  .fad.fa-drone-alt::after, .fa-duotone.fa-drone-alt::after {
	content: "\F860\F860"; }
  
  .fad.fa-glass-empty::after, .fa-duotone.fa-glass-empty::after {
	content: "\E191\E191"; }
  
  .fad.fa-dial-high::after, .fa-duotone.fa-dial-high::after {
	content: "\E15C\E15C"; }
  
  .fad.fa-user-helmet-safety::after, .fa-duotone.fa-user-helmet-safety::after {
	content: "\F82C\F82C"; }
  
  .fad.fa-user-construction::after, .fa-duotone.fa-user-construction::after {
	content: "\F82C\F82C"; }
  
  .fad.fa-user-hard-hat::after, .fa-duotone.fa-user-hard-hat::after {
	content: "\F82C\F82C"; }
  
  .fad.fa-i::after, .fa-duotone.fa-i::after {
	content: "II"; }
  
  .fad.fa-hryvnia-sign::after, .fa-duotone.fa-hryvnia-sign::after {
	content: "\F6F2\F6F2"; }
  
  .fad.fa-hryvnia::after, .fa-duotone.fa-hryvnia::after {
	content: "\F6F2\F6F2"; }
  
  .fad.fa-arrow-down-left-and-arrow-up-right-to-center::after, .fa-duotone.fa-arrow-down-left-and-arrow-up-right-to-center::after {
	content: "\E092\E092"; }
  
  .fad.fa-pills::after, .fa-duotone.fa-pills::after {
	content: "\F484\F484"; }
  
  .fad.fa-face-grin-wide::after, .fa-duotone.fa-face-grin-wide::after {
	content: "\F581\F581"; }
  
  .fad.fa-grin-alt::after, .fa-duotone.fa-grin-alt::after {
	content: "\F581\F581"; }
  
  .fad.fa-tooth::after, .fa-duotone.fa-tooth::after {
	content: "\F5C9\F5C9"; }
  
  .fad.fa-basketball-hoop::after, .fa-duotone.fa-basketball-hoop::after {
	content: "\F435\F435"; }
  
  .fad.fa-objects-align-bottom::after, .fa-duotone.fa-objects-align-bottom::after {
	content: "\E3BB\E3BB"; }
  
  .fad.fa-v::after, .fa-duotone.fa-v::after {
	content: "VV"; }
  
  .fad.fa-sparkles::after, .fa-duotone.fa-sparkles::after {
	content: "\F890\F890"; }
  
  .fad.fa-squid::after, .fa-duotone.fa-squid::after {
	content: "\E450\E450"; }
  
  .fad.fa-leafy-green::after, .fa-duotone.fa-leafy-green::after {
	content: "\E41D\E41D"; }
  
  .fad.fa-circle-arrow-up-right::after, .fa-duotone.fa-circle-arrow-up-right::after {
	content: "\E0FC\E0FC"; }
  
  .fad.fa-calendars::after, .fa-duotone.fa-calendars::after {
	content: "\E0D7\E0D7"; }
  
  .fad.fa-bangladeshi-taka-sign::after, .fa-duotone.fa-bangladeshi-taka-sign::after {
	content: "\E2E6\E2E6"; }
  
  .fad.fa-bicycle::after, .fa-duotone.fa-bicycle::after {
	content: "\F206\F206"; }
  
  .fad.fa-hammer-war::after, .fa-duotone.fa-hammer-war::after {
	content: "\F6E4\F6E4"; }
  
  .fad.fa-circle-d::after, .fa-duotone.fa-circle-d::after {
	content: "\E104\E104"; }
  
  .fad.fa-spider-black-widow::after, .fa-duotone.fa-spider-black-widow::after {
	content: "\F718\F718"; }
  
  .fad.fa-staff-snake::after, .fa-duotone.fa-staff-snake::after {
	content: "\E579\E579"; }
  
  .fad.fa-rod-asclepius::after, .fa-duotone.fa-rod-asclepius::after {
	content: "\E579\E579"; }
  
  .fad.fa-rod-snake::after, .fa-duotone.fa-rod-snake::after {
	content: "\E579\E579"; }
  
  .fad.fa-staff-aesculapius::after, .fa-duotone.fa-staff-aesculapius::after {
	content: "\E579\E579"; }
  
  .fad.fa-pear::after, .fa-duotone.fa-pear::after {
	content: "\E20C\E20C"; }
  
  .fad.fa-head-side-cough-slash::after, .fa-duotone.fa-head-side-cough-slash::after {
	content: "\E062\E062"; }
  
  .fad.fa-triangle::after, .fa-duotone.fa-triangle::after {
	content: "\F2EC\F2EC"; }
  
  .fad.fa-apartment::after, .fa-duotone.fa-apartment::after {
	content: "\E468\E468"; }
  
  .fad.fa-truck-medical::after, .fa-duotone.fa-truck-medical::after {
	content: "\F0F9\F0F9"; }
  
  .fad.fa-ambulance::after, .fa-duotone.fa-ambulance::after {
	content: "\F0F9\F0F9"; }
  
  .fad.fa-pepper::after, .fa-duotone.fa-pepper::after {
	content: "\E432\E432"; }
  
  .fad.fa-piano::after, .fa-duotone.fa-piano::after {
	content: "\F8D4\F8D4"; }
  
  .fad.fa-gun-squirt::after, .fa-duotone.fa-gun-squirt::after {
	content: "\E19D\E19D"; }
  
  .fad.fa-wheat-awn-circle-exclamation::after, .fa-duotone.fa-wheat-awn-circle-exclamation::after {
	content: "\E598\E598"; }
  
  .fad.fa-snowman::after, .fa-duotone.fa-snowman::after {
	content: "\F7D0\F7D0"; }
  
  .fad.fa-user-alien::after, .fa-duotone.fa-user-alien::after {
	content: "\E04A\E04A"; }
  
  .fad.fa-shield-check::after, .fa-duotone.fa-shield-check::after {
	content: "\F2F7\F2F7"; }
  
  .fad.fa-mortar-pestle::after, .fa-duotone.fa-mortar-pestle::after {
	content: "\F5A7\F5A7"; }
  
  .fad.fa-road-barrier::after, .fa-duotone.fa-road-barrier::after {
	content: "\E562\E562"; }
  
  .fad.fa-chart-candlestick::after, .fa-duotone.fa-chart-candlestick::after {
	content: "\E0E2\E0E2"; }
  
  .fad.fa-briefcase-blank::after, .fa-duotone.fa-briefcase-blank::after {
	content: "\E0C8\E0C8"; }
  
  .fad.fa-school::after, .fa-duotone.fa-school::after {
	content: "\F549\F549"; }
  
  .fad.fa-igloo::after, .fa-duotone.fa-igloo::after {
	content: "\F7AE\F7AE"; }
  
  .fad.fa-bracket-round::after, .fa-duotone.fa-bracket-round::after {
	content: "(("; }
  
  .fad.fa-parenthesis::after, .fa-duotone.fa-parenthesis::after {
	content: "(("; }
  
  .fad.fa-joint::after, .fa-duotone.fa-joint::after {
	content: "\F595\F595"; }
  
  .fad.fa-horse-saddle::after, .fa-duotone.fa-horse-saddle::after {
	content: "\F8C3\F8C3"; }
  
  .fad.fa-mug-marshmallows::after, .fa-duotone.fa-mug-marshmallows::after {
	content: "\F7B7\F7B7"; }
  
  .fad.fa-filters::after, .fa-duotone.fa-filters::after {
	content: "\E17E\E17E"; }
  
  .fad.fa-bell-on::after, .fa-duotone.fa-bell-on::after {
	content: "\F8FA\F8FA"; }
  
  .fad.fa-angle-right::after, .fa-duotone.fa-angle-right::after {
	content: "\F105\F105"; }
  
  .fad.fa-dial-med::after, .fa-duotone.fa-dial-med::after {
	content: "\E15F\E15F"; }
  
  .fad.fa-horse::after, .fa-duotone.fa-horse::after {
	content: "\F6F0\F6F0"; }
  
  .fad.fa-q::after, .fa-duotone.fa-q::after {
	content: "QQ"; }
  
  .fad.fa-monitor-waveform::after, .fa-duotone.fa-monitor-waveform::after {
	content: "\F611\F611"; }
  
  .fad.fa-monitor-heart-rate::after, .fa-duotone.fa-monitor-heart-rate::after {
	content: "\F611\F611"; }
  
  .fad.fa-link-simple::after, .fa-duotone.fa-link-simple::after {
	content: "\E1CD\E1CD"; }
  
  .fad.fa-whistle::after, .fa-duotone.fa-whistle::after {
	content: "\F460\F460"; }
  
  .fad.fa-g::after, .fa-duotone.fa-g::after {
	content: "GG"; }
  
  .fad.fa-wine-glass-crack::after, .fa-duotone.fa-wine-glass-crack::after {
	content: "\F4BB\F4BB"; }
  
  .fad.fa-fragile::after, .fa-duotone.fa-fragile::after {
	content: "\F4BB\F4BB"; }
  
  .fad.fa-slot-machine::after, .fa-duotone.fa-slot-machine::after {
	content: "\E3CE\E3CE"; }
  
  .fad.fa-notes-medical::after, .fa-duotone.fa-notes-medical::after {
	content: "\F481\F481"; }
  
  .fad.fa-car-wash::after, .fa-duotone.fa-car-wash::after {
	content: "\F5E6\F5E6"; }
  
  .fad.fa-escalator::after, .fa-duotone.fa-escalator::after {
	content: "\E171\E171"; }
  
  .fad.fa-comment-image::after, .fa-duotone.fa-comment-image::after {
	content: "\E148\E148"; }
  
  .fad.fa-temperature-half::after, .fa-duotone.fa-temperature-half::after {
	content: "\F2C9\F2C9"; }
  
  .fad.fa-temperature-2::after, .fa-duotone.fa-temperature-2::after {
	content: "\F2C9\F2C9"; }
  
  .fad.fa-thermometer-2::after, .fa-duotone.fa-thermometer-2::after {
	content: "\F2C9\F2C9"; }
  
  .fad.fa-thermometer-half::after, .fa-duotone.fa-thermometer-half::after {
	content: "\F2C9\F2C9"; }
  
  .fad.fa-dong-sign::after, .fa-duotone.fa-dong-sign::after {
	content: "\E169\E169"; }
  
  .fad.fa-donut::after, .fa-duotone.fa-donut::after {
	content: "\E406\E406"; }
  
  .fad.fa-doughnut::after, .fa-duotone.fa-doughnut::after {
	content: "\E406\E406"; }
  
  .fad.fa-capsules::after, .fa-duotone.fa-capsules::after {
	content: "\F46B\F46B"; }
  
  .fad.fa-poo-storm::after, .fa-duotone.fa-poo-storm::after {
	content: "\F75A\F75A"; }
  
  .fad.fa-poo-bolt::after, .fa-duotone.fa-poo-bolt::after {
	content: "\F75A\F75A"; }
  
  .fad.fa-tally-1::after, .fa-duotone.fa-tally-1::after {
	content: "\E294\E294"; }
  
  .fad.fa-face-frown-open::after, .fa-duotone.fa-face-frown-open::after {
	content: "\F57A\F57A"; }
  
  .fad.fa-frown-open::after, .fa-duotone.fa-frown-open::after {
	content: "\F57A\F57A"; }
  
  .fad.fa-square-dashed::after, .fa-duotone.fa-square-dashed::after {
	content: "\E269\E269"; }
  
  .fad.fa-square-j::after, .fa-duotone.fa-square-j::after {
	content: "\E273\E273"; }
  
  .fad.fa-hand-point-up::after, .fa-duotone.fa-hand-point-up::after {
	content: "\F0A6\F0A6"; }
  
  .fad.fa-money-bill::after, .fa-duotone.fa-money-bill::after {
	content: "\F0D6\F0D6"; }
  
  .fad.fa-arrow-up-big-small::after, .fa-duotone.fa-arrow-up-big-small::after {
	content: "\F88E\F88E"; }
  
  .fad.fa-sort-size-up::after, .fa-duotone.fa-sort-size-up::after {
	content: "\F88E\F88E"; }
  
  .fad.fa-barcode-read::after, .fa-duotone.fa-barcode-read::after {
	content: "\F464\F464"; }
  
  .fad.fa-baguette::after, .fa-duotone.fa-baguette::after {
	content: "\E3D8\E3D8"; }
  
  .fad.fa-bowl-soft-serve::after, .fa-duotone.fa-bowl-soft-serve::after {
	content: "\E46B\E46B"; }
  
  .fad.fa-face-holding-back-tears::after, .fa-duotone.fa-face-holding-back-tears::after {
	content: "\E482\E482"; }
  
  .fad.fa-square-up::after, .fa-duotone.fa-square-up::after {
	content: "\F353\F353"; }
  
  .fad.fa-arrow-alt-square-up::after, .fa-duotone.fa-arrow-alt-square-up::after {
	content: "\F353\F353"; }
  
  .fad.fa-train-subway-tunnel::after, .fa-duotone.fa-train-subway-tunnel::after {
	content: "\E2A3\E2A3"; }
  
  .fad.fa-subway-tunnel::after, .fa-duotone.fa-subway-tunnel::after {
	content: "\E2A3\E2A3"; }
  
  .fad.fa-square-exclamation::after, .fa-duotone.fa-square-exclamation::after {
	content: "\F321\F321"; }
  
  .fad.fa-exclamation-square::after, .fa-duotone.fa-exclamation-square::after {
	content: "\F321\F321"; }
  
  .fad.fa-semicolon::after, .fa-duotone.fa-semicolon::after {
	content: ";;"; }
  
  .fad.fa-bookmark::after, .fa-duotone.fa-bookmark::after {
	content: "\F02E\F02E"; }
  
  .fad.fa-fan-table::after, .fa-duotone.fa-fan-table::after {
	content: "\E004\E004"; }
  
  .fad.fa-align-justify::after, .fa-duotone.fa-align-justify::after {
	content: "\F039\F039"; }
  
  .fad.fa-battery-low::after, .fa-duotone.fa-battery-low::after {
	content: "\E0B1\E0B1"; }
  
  .fad.fa-battery-1::after, .fa-duotone.fa-battery-1::after {
	content: "\E0B1\E0B1"; }
  
  .fad.fa-credit-card-front::after, .fa-duotone.fa-credit-card-front::after {
	content: "\F38A\F38A"; }
  
  .fad.fa-brain-arrow-curved-right::after, .fa-duotone.fa-brain-arrow-curved-right::after {
	content: "\F677\F677"; }
  
  .fad.fa-mind-share::after, .fa-duotone.fa-mind-share::after {
	content: "\F677\F677"; }
  
  .fad.fa-umbrella-beach::after, .fa-duotone.fa-umbrella-beach::after {
	content: "\F5CA\F5CA"; }
  
  .fad.fa-helmet-un::after, .fa-duotone.fa-helmet-un::after {
	content: "\E503\E503"; }
  
  .fad.fa-location-smile::after, .fa-duotone.fa-location-smile::after {
	content: "\F60D\F60D"; }
  
  .fad.fa-map-marker-smile::after, .fa-duotone.fa-map-marker-smile::after {
	content: "\F60D\F60D"; }
  
  .fad.fa-arrow-left-to-line::after, .fa-duotone.fa-arrow-left-to-line::after {
	content: "\F33E\F33E"; }
  
  .fad.fa-arrow-to-left::after, .fa-duotone.fa-arrow-to-left::after {
	content: "\F33E\F33E"; }
  
  .fad.fa-bullseye::after, .fa-duotone.fa-bullseye::after {
	content: "\F140\F140"; }
  
  .fad.fa-sushi::after, .fa-duotone.fa-sushi::after {
	content: "\E48A\E48A"; }
  
  .fad.fa-nigiri::after, .fa-duotone.fa-nigiri::after {
	content: "\E48A\E48A"; }
  
  .fad.fa-message-captions::after, .fa-duotone.fa-message-captions::after {
	content: "\E1DE\E1DE"; }
  
  .fad.fa-comment-alt-captions::after, .fa-duotone.fa-comment-alt-captions::after {
	content: "\E1DE\E1DE"; }
  
  .fad.fa-trash-list::after, .fa-duotone.fa-trash-list::after {
	content: "\E2B1\E2B1"; }
  
  .fad.fa-bacon::after, .fa-duotone.fa-bacon::after {
	content: "\F7E5\F7E5"; }
  
  .fad.fa-option::after, .fa-duotone.fa-option::after {
	content: "\E318\E318"; }
  
  .fad.fa-hand-point-down::after, .fa-duotone.fa-hand-point-down::after {
	content: "\F0A7\F0A7"; }
  
  .fad.fa-arrow-up-from-bracket::after, .fa-duotone.fa-arrow-up-from-bracket::after {
	content: "\E09A\E09A"; }
  
  .fad.fa-trash-plus::after, .fa-duotone.fa-trash-plus::after {
	content: "\E2B2\E2B2"; }
  
  .fad.fa-objects-align-top::after, .fa-duotone.fa-objects-align-top::after {
	content: "\E3C0\E3C0"; }
  
  .fad.fa-folder::after, .fa-duotone.fa-folder::after {
	content: "\F07B\F07B"; }
  
  .fad.fa-folder-blank::after, .fa-duotone.fa-folder-blank::after {
	content: "\F07B\F07B"; }
  
  .fad.fa-face-anxious-sweat::after, .fa-duotone.fa-face-anxious-sweat::after {
	content: "\E36A\E36A"; }
  
  .fad.fa-credit-card-blank::after, .fa-duotone.fa-credit-card-blank::after {
	content: "\F389\F389"; }
  
  .fad.fa-file-waveform::after, .fa-duotone.fa-file-waveform::after {
	content: "\F478\F478"; }
  
  .fad.fa-file-medical-alt::after, .fa-duotone.fa-file-medical-alt::after {
	content: "\F478\F478"; }
  
  .fad.fa-microchip-ai::after, .fa-duotone.fa-microchip-ai::after {
	content: "\E1EC\E1EC"; }
  
  .fad.fa-mug::after, .fa-duotone.fa-mug::after {
	content: "\F874\F874"; }
  
  .fad.fa-plane-up-slash::after, .fa-duotone.fa-plane-up-slash::after {
	content: "\E22E\E22E"; }
  
  .fad.fa-radiation::after, .fa-duotone.fa-radiation::after {
	content: "\F7B9\F7B9"; }
  
  .fad.fa-pen-circle::after, .fa-duotone.fa-pen-circle::after {
	content: "\E20E\E20E"; }
  
  .fad.fa-chart-simple::after, .fa-duotone.fa-chart-simple::after {
	content: "\E473\E473"; }
  
  .fad.fa-crutches::after, .fa-duotone.fa-crutches::after {
	content: "\F7F8\F7F8"; }
  
  .fad.fa-circle-parking::after, .fa-duotone.fa-circle-parking::after {
	content: "\F615\F615"; }
  
  .fad.fa-parking-circle::after, .fa-duotone.fa-parking-circle::after {
	content: "\F615\F615"; }
  
  .fad.fa-mars-stroke::after, .fa-duotone.fa-mars-stroke::after {
	content: "\F229\F229"; }
  
  .fad.fa-leaf-oak::after, .fa-duotone.fa-leaf-oak::after {
	content: "\F6F7\F6F7"; }
  
  .fad.fa-square-bolt::after, .fa-duotone.fa-square-bolt::after {
	content: "\E265\E265"; }
  
  .fad.fa-vial::after, .fa-duotone.fa-vial::after {
	content: "\F492\F492"; }
  
  .fad.fa-gauge::after, .fa-duotone.fa-gauge::after {
	content: "\F624\F624"; }
  
  .fad.fa-dashboard::after, .fa-duotone.fa-dashboard::after {
	content: "\F624\F624"; }
  
  .fad.fa-gauge-med::after, .fa-duotone.fa-gauge-med::after {
	content: "\F624\F624"; }
  
  .fad.fa-tachometer-alt-average::after, .fa-duotone.fa-tachometer-alt-average::after {
	content: "\F624\F624"; }
  
  .fad.fa-wand-magic-sparkles::after, .fa-duotone.fa-wand-magic-sparkles::after {
	content: "\E2CA\E2CA"; }
  
  .fad.fa-magic-wand-sparkles::after, .fa-duotone.fa-magic-wand-sparkles::after {
	content: "\E2CA\E2CA"; }
  
  .fad.fa-lambda::after, .fa-duotone.fa-lambda::after {
	content: "\F66E\F66E"; }
  
  .fad.fa-e::after, .fa-duotone.fa-e::after {
	content: "EE"; }
  
  .fad.fa-pizza::after, .fa-duotone.fa-pizza::after {
	content: "\F817\F817"; }
  
  .fad.fa-bowl-chopsticks-noodles::after, .fa-duotone.fa-bowl-chopsticks-noodles::after {
	content: "\E2EA\E2EA"; }
  
  .fad.fa-h3::after, .fa-duotone.fa-h3::after {
	content: "\F315\F315"; }
  
  .fad.fa-pen-clip::after, .fa-duotone.fa-pen-clip::after {
	content: "\F305\F305"; }
  
  .fad.fa-pen-alt::after, .fa-duotone.fa-pen-alt::after {
	content: "\F305\F305"; }
  
  .fad.fa-bridge-circle-exclamation::after, .fa-duotone.fa-bridge-circle-exclamation::after {
	content: "\E4CA\E4CA"; }
  
  .fad.fa-badge-percent::after, .fa-duotone.fa-badge-percent::after {
	content: "\F646\F646"; }
  
  .fad.fa-user::after, .fa-duotone.fa-user::after {
	content: "\F007\F007"; }
  
  .fad.fa-sensor::after, .fa-duotone.fa-sensor::after {
	content: "\E028\E028"; }
  
  .fad.fa-comma::after, .fa-duotone.fa-comma::after {
	content: ",,"; }
  
  .fad.fa-school-circle-check::after, .fa-duotone.fa-school-circle-check::after {
	content: "\E56B\E56B"; }
  
  .fad.fa-toilet-paper-under::after, .fa-duotone.fa-toilet-paper-under::after {
	content: "\E2A0\E2A0"; }
  
  .fad.fa-toilet-paper-reverse::after, .fa-duotone.fa-toilet-paper-reverse::after {
	content: "\E2A0\E2A0"; }
  
  .fad.fa-light-emergency::after, .fa-duotone.fa-light-emergency::after {
	content: "\E41F\E41F"; }
  
  .fad.fa-arrow-down-to-arc::after, .fa-duotone.fa-arrow-down-to-arc::after {
	content: "\E4AE\E4AE"; }
  
  .fad.fa-dumpster::after, .fa-duotone.fa-dumpster::after {
	content: "\F793\F793"; }
  
  .fad.fa-van-shuttle::after, .fa-duotone.fa-van-shuttle::after {
	content: "\F5B6\F5B6"; }
  
  .fad.fa-shuttle-van::after, .fa-duotone.fa-shuttle-van::after {
	content: "\F5B6\F5B6"; }
  
  .fad.fa-building-user::after, .fa-duotone.fa-building-user::after {
	content: "\E4DA\E4DA"; }
  
  .fad.fa-light-switch::after, .fa-duotone.fa-light-switch::after {
	content: "\E017\E017"; }
  
  .fad.fa-square-caret-left::after, .fa-duotone.fa-square-caret-left::after {
	content: "\F191\F191"; }
  
  .fad.fa-caret-square-left::after, .fa-duotone.fa-caret-square-left::after {
	content: "\F191\F191"; }
  
  .fad.fa-highlighter::after, .fa-duotone.fa-highlighter::after {
	content: "\F591\F591"; }
  
  .fad.fa-wave-pulse::after, .fa-duotone.fa-wave-pulse::after {
	content: "\F5F8\F5F8"; }
  
  .fad.fa-heart-rate::after, .fa-duotone.fa-heart-rate::after {
	content: "\F5F8\F5F8"; }
  
  .fad.fa-key::after, .fa-duotone.fa-key::after {
	content: "\F084\F084"; }
  
  .fad.fa-hat-santa::after, .fa-duotone.fa-hat-santa::after {
	content: "\F7A7\F7A7"; }
  
  .fad.fa-tamale::after, .fa-duotone.fa-tamale::after {
	content: "\E451\E451"; }
  
  .fad.fa-box-check::after, .fa-duotone.fa-box-check::after {
	content: "\F467\F467"; }
  
  .fad.fa-bullhorn::after, .fa-duotone.fa-bullhorn::after {
	content: "\F0A1\F0A1"; }
  
  .fad.fa-steak::after, .fa-duotone.fa-steak::after {
	content: "\F824\F824"; }
  
  .fad.fa-location-crosshairs-slash::after, .fa-duotone.fa-location-crosshairs-slash::after {
	content: "\F603\F603"; }
  
  .fad.fa-location-slash::after, .fa-duotone.fa-location-slash::after {
	content: "\F603\F603"; }
  
  .fad.fa-person-dolly::after, .fa-duotone.fa-person-dolly::after {
	content: "\F4D0\F4D0"; }
  
  .fad.fa-globe::after, .fa-duotone.fa-globe::after {
	content: "\F0AC\F0AC"; }
  
  .fad.fa-synagogue::after, .fa-duotone.fa-synagogue::after {
	content: "\F69B\F69B"; }
  
  .fad.fa-file-chart-column::after, .fa-duotone.fa-file-chart-column::after {
	content: "\F659\F659"; }
  
  .fad.fa-file-chart-line::after, .fa-duotone.fa-file-chart-line::after {
	content: "\F659\F659"; }
  
  .fad.fa-person-half-dress::after, .fa-duotone.fa-person-half-dress::after {
	content: "\E548\E548"; }
  
  .fad.fa-folder-image::after, .fa-duotone.fa-folder-image::after {
	content: "\E18A\E18A"; }
  
  .fad.fa-calendar-pen::after, .fa-duotone.fa-calendar-pen::after {
	content: "\F333\F333"; }
  
  .fad.fa-calendar-edit::after, .fa-duotone.fa-calendar-edit::after {
	content: "\F333\F333"; }
  
  .fad.fa-road-bridge::after, .fa-duotone.fa-road-bridge::after {
	content: "\E563\E563"; }
  
  .fad.fa-face-smile-tear::after, .fa-duotone.fa-face-smile-tear::after {
	content: "\E393\E393"; }
  
  .fad.fa-message-plus::after, .fa-duotone.fa-message-plus::after {
	content: "\F4A8\F4A8"; }
  
  .fad.fa-comment-alt-plus::after, .fa-duotone.fa-comment-alt-plus::after {
	content: "\F4A8\F4A8"; }
  
  .fad.fa-location-arrow::after, .fa-duotone.fa-location-arrow::after {
	content: "\F124\F124"; }
  
  .fad.fa-c::after, .fa-duotone.fa-c::after {
	content: "CC"; }
  
  .fad.fa-tablet-button::after, .fa-duotone.fa-tablet-button::after {
	content: "\F10A\F10A"; }
  
  .fad.fa-rectangle-history-circle-user::after, .fa-duotone.fa-rectangle-history-circle-user::after {
	content: "\E4A4\E4A4"; }
  
  .fad.fa-building-lock::after, .fa-duotone.fa-building-lock::after {
	content: "\E4D6\E4D6"; }
  
  .fad.fa-chart-line-up::after, .fa-duotone.fa-chart-line-up::after {
	content: "\E0E5\E0E5"; }
  
  .fad.fa-mailbox::after, .fa-duotone.fa-mailbox::after {
	content: "\F813\F813"; }
  
  .fad.fa-truck-bolt::after, .fa-duotone.fa-truck-bolt::after {
	content: "\E3D0\E3D0"; }
  
  .fad.fa-pizza-slice::after, .fa-duotone.fa-pizza-slice::after {
	content: "\F818\F818"; }
  
  .fad.fa-money-bill-wave::after, .fa-duotone.fa-money-bill-wave::after {
	content: "\F53A\F53A"; }
  
  .fad.fa-chart-area::after, .fa-duotone.fa-chart-area::after {
	content: "\F1FE\F1FE"; }
  
  .fad.fa-area-chart::after, .fa-duotone.fa-area-chart::after {
	content: "\F1FE\F1FE"; }
  
  .fad.fa-house-flag::after, .fa-duotone.fa-house-flag::after {
	content: "\E50D\E50D"; }
  
  .fad.fa-person-circle-minus::after, .fa-duotone.fa-person-circle-minus::after {
	content: "\E540\E540"; }
  
  .fad.fa-scalpel::after, .fa-duotone.fa-scalpel::after {
	content: "\F61D\F61D"; }
  
  .fad.fa-ban::after, .fa-duotone.fa-ban::after {
	content: "\F05E\F05E"; }
  
  .fad.fa-cancel::after, .fa-duotone.fa-cancel::after {
	content: "\F05E\F05E"; }
  
  .fad.fa-bell-exclamation::after, .fa-duotone.fa-bell-exclamation::after {
	content: "\F848\F848"; }
  
  .fad.fa-circle-bookmark::after, .fa-duotone.fa-circle-bookmark::after {
	content: "\E100\E100"; }
  
  .fad.fa-bookmark-circle::after, .fa-duotone.fa-bookmark-circle::after {
	content: "\E100\E100"; }
  
  .fad.fa-egg-fried::after, .fa-duotone.fa-egg-fried::after {
	content: "\F7FC\F7FC"; }
  
  .fad.fa-face-weary::after, .fa-duotone.fa-face-weary::after {
	content: "\E3A1\E3A1"; }
  
  .fad.fa-uniform-martial-arts::after, .fa-duotone.fa-uniform-martial-arts::after {
	content: "\E3D1\E3D1"; }
  
  .fad.fa-camera-rotate::after, .fa-duotone.fa-camera-rotate::after {
	content: "\E0D8\E0D8"; }
  
  .fad.fa-sun-dust::after, .fa-duotone.fa-sun-dust::after {
	content: "\F764\F764"; }
  
  .fad.fa-comment-text::after, .fa-duotone.fa-comment-text::after {
	content: "\E14D\E14D"; }
  
  .fad.fa-spray-can-sparkles::after, .fa-duotone.fa-spray-can-sparkles::after {
	content: "\F5D0\F5D0"; }
  
  .fad.fa-air-freshener::after, .fa-duotone.fa-air-freshener::after {
	content: "\F5D0\F5D0"; }
  
  .fad.fa-signal-bars::after, .fa-duotone.fa-signal-bars::after {
	content: "\F690\F690"; }
  
  .fad.fa-signal-alt::after, .fa-duotone.fa-signal-alt::after {
	content: "\F690\F690"; }
  
  .fad.fa-signal-alt-4::after, .fa-duotone.fa-signal-alt-4::after {
	content: "\F690\F690"; }
  
  .fad.fa-signal-bars-strong::after, .fa-duotone.fa-signal-bars-strong::after {
	content: "\F690\F690"; }
  
  .fad.fa-diamond-exclamation::after, .fa-duotone.fa-diamond-exclamation::after {
	content: "\E405\E405"; }
  
  .fad.fa-star::after, .fa-duotone.fa-star::after {
	content: "\F005\F005"; }
  
  .fad.fa-dial-min::after, .fa-duotone.fa-dial-min::after {
	content: "\E161\E161"; }
  
  .fad.fa-repeat::after, .fa-duotone.fa-repeat::after {
	content: "\F363\F363"; }
  
  .fad.fa-cross::after, .fa-duotone.fa-cross::after {
	content: "\F654\F654"; }
  
  .fad.fa-page-caret-down::after, .fa-duotone.fa-page-caret-down::after {
	content: "\E429\E429"; }
  
  .fad.fa-file-caret-down::after, .fa-duotone.fa-file-caret-down::after {
	content: "\E429\E429"; }
  
  .fad.fa-box::after, .fa-duotone.fa-box::after {
	content: "\F466\F466"; }
  
  .fad.fa-venus-mars::after, .fa-duotone.fa-venus-mars::after {
	content: "\F228\F228"; }
  
  .fad.fa-clock-seven-thirty::after, .fa-duotone.fa-clock-seven-thirty::after {
	content: "\E351\E351"; }
  
  .fad.fa-arrow-pointer::after, .fa-duotone.fa-arrow-pointer::after {
	content: "\F245\F245"; }
  
  .fad.fa-mouse-pointer::after, .fa-duotone.fa-mouse-pointer::after {
	content: "\F245\F245"; }
  
  .fad.fa-clock-four-thirty::after, .fa-duotone.fa-clock-four-thirty::after {
	content: "\E34B\E34B"; }
  
  .fad.fa-signal-bars-good::after, .fa-duotone.fa-signal-bars-good::after {
	content: "\F693\F693"; }
  
  .fad.fa-signal-alt-3::after, .fa-duotone.fa-signal-alt-3::after {
	content: "\F693\F693"; }
  
  .fad.fa-cactus::after, .fa-duotone.fa-cactus::after {
	content: "\F8A7\F8A7"; }
  
  .fad.fa-maximize::after, .fa-duotone.fa-maximize::after {
	content: "\F31E\F31E"; }
  
  .fad.fa-expand-arrows-alt::after, .fa-duotone.fa-expand-arrows-alt::after {
	content: "\F31E\F31E"; }
  
  .fad.fa-charging-station::after, .fa-duotone.fa-charging-station::after {
	content: "\F5E7\F5E7"; }
  
  .fad.fa-shapes::after, .fa-duotone.fa-shapes::after {
	content: "\F61F\F61F"; }
  
  .fad.fa-triangle-circle-square::after, .fa-duotone.fa-triangle-circle-square::after {
	content: "\F61F\F61F"; }
  
  .fad.fa-plane-tail::after, .fa-duotone.fa-plane-tail::after {
	content: "\E22C\E22C"; }
  
  .fad.fa-gauge-simple-max::after, .fa-duotone.fa-gauge-simple-max::after {
	content: "\F62B\F62B"; }
  
  .fad.fa-tachometer-fastest::after, .fa-duotone.fa-tachometer-fastest::after {
	content: "\F62B\F62B"; }
  
  .fad.fa-circle-u::after, .fa-duotone.fa-circle-u::after {
	content: "\E127\E127"; }
  
  .fad.fa-shield-slash::after, .fa-duotone.fa-shield-slash::after {
	content: "\E24B\E24B"; }
  
  .fad.fa-square-phone-hangup::after, .fa-duotone.fa-square-phone-hangup::after {
	content: "\E27A\E27A"; }
  
  .fad.fa-phone-square-down::after, .fa-duotone.fa-phone-square-down::after {
	content: "\E27A\E27A"; }
  
  .fad.fa-arrow-up-left::after, .fa-duotone.fa-arrow-up-left::after {
	content: "\E09D\E09D"; }
  
  .fad.fa-transporter-1::after, .fa-duotone.fa-transporter-1::after {
	content: "\E043\E043"; }
  
  .fad.fa-peanuts::after, .fa-duotone.fa-peanuts::after {
	content: "\E431\E431"; }
  
  .fad.fa-shuffle::after, .fa-duotone.fa-shuffle::after {
	content: "\F074\F074"; }
  
  .fad.fa-random::after, .fa-duotone.fa-random::after {
	content: "\F074\F074"; }
  
  .fad.fa-person-running::after, .fa-duotone.fa-person-running::after {
	content: "\F70C\F70C"; }
  
  .fad.fa-running::after, .fa-duotone.fa-running::after {
	content: "\F70C\F70C"; }
  
  .fad.fa-mobile-retro::after, .fa-duotone.fa-mobile-retro::after {
	content: "\E527\E527"; }
  
  .fad.fa-grip-lines-vertical::after, .fa-duotone.fa-grip-lines-vertical::after {
	content: "\F7A5\F7A5"; }
  
  .fad.fa-arrow-up-from-square::after, .fa-duotone.fa-arrow-up-from-square::after {
	content: "\E09C\E09C"; }
  
  .fad.fa-file-dashed-line::after, .fa-duotone.fa-file-dashed-line::after {
	content: "\F877\F877"; }
  
  .fad.fa-page-break::after, .fa-duotone.fa-page-break::after {
	content: "\F877\F877"; }
  
  .fad.fa-bracket-curly-right::after, .fa-duotone.fa-bracket-curly-right::after {
	content: "}}"; }
  
  .fad.fa-spider::after, .fa-duotone.fa-spider::after {
	content: "\F717\F717"; }
  
  .fad.fa-clock-three::after, .fa-duotone.fa-clock-three::after {
	content: "\E356\E356"; }
  
  .fad.fa-hands-bound::after, .fa-duotone.fa-hands-bound::after {
	content: "\E4F9\E4F9"; }
  
  .fad.fa-scalpel-line-dashed::after, .fa-duotone.fa-scalpel-line-dashed::after {
	content: "\F61E\F61E"; }
  
  .fad.fa-scalpel-path::after, .fa-duotone.fa-scalpel-path::after {
	content: "\F61E\F61E"; }
  
  .fad.fa-file-invoice-dollar::after, .fa-duotone.fa-file-invoice-dollar::after {
	content: "\F571\F571"; }
  
  .fad.fa-pipe-smoking::after, .fa-duotone.fa-pipe-smoking::after {
	content: "\E3C4\E3C4"; }
  
  .fad.fa-face-astonished::after, .fa-duotone.fa-face-astonished::after {
	content: "\E36B\E36B"; }
  
  .fad.fa-window::after, .fa-duotone.fa-window::after {
	content: "\F40E\F40E"; }
  
  .fad.fa-plane-circle-exclamation::after, .fa-duotone.fa-plane-circle-exclamation::after {
	content: "\E556\E556"; }
  
  .fad.fa-ear::after, .fa-duotone.fa-ear::after {
	content: "\F5F0\F5F0"; }
  
  .fad.fa-file-lock::after, .fa-duotone.fa-file-lock::after {
	content: "\E3A6\E3A6"; }
  
  .fad.fa-diagram-venn::after, .fa-duotone.fa-diagram-venn::after {
	content: "\E15A\E15A"; }
  
  .fad.fa-x-ray::after, .fa-duotone.fa-x-ray::after {
	content: "\F497\F497"; }
  
  .fad.fa-goal-net::after, .fa-duotone.fa-goal-net::after {
	content: "\E3AB\E3AB"; }
  
  .fad.fa-coffin-cross::after, .fa-duotone.fa-coffin-cross::after {
	content: "\E051\E051"; }
  
  .fad.fa-spell-check::after, .fa-duotone.fa-spell-check::after {
	content: "\F891\F891"; }
  
  .fad.fa-location-xmark::after, .fa-duotone.fa-location-xmark::after {
	content: "\F60E\F60E"; }
  
  .fad.fa-map-marker-times::after, .fa-duotone.fa-map-marker-times::after {
	content: "\F60E\F60E"; }
  
  .fad.fa-map-marker-xmark::after, .fa-duotone.fa-map-marker-xmark::after {
	content: "\F60E\F60E"; }
  
  .fad.fa-lasso::after, .fa-duotone.fa-lasso::after {
	content: "\F8C8\F8C8"; }
  
  .fad.fa-slash::after, .fa-duotone.fa-slash::after {
	content: "\F715\F715"; }
  
  .fad.fa-person-to-portal::after, .fa-duotone.fa-person-to-portal::after {
	content: "\E022\E022"; }
  
  .fad.fa-portal-enter::after, .fa-duotone.fa-portal-enter::after {
	content: "\E022\E022"; }
  
  .fad.fa-calendar-star::after, .fa-duotone.fa-calendar-star::after {
	content: "\F736\F736"; }
  
  .fad.fa-computer-mouse::after, .fa-duotone.fa-computer-mouse::after {
	content: "\F8CC\F8CC"; }
  
  .fad.fa-mouse::after, .fa-duotone.fa-mouse::after {
	content: "\F8CC\F8CC"; }
  
  .fad.fa-arrow-right-to-bracket::after, .fa-duotone.fa-arrow-right-to-bracket::after {
	content: "\F090\F090"; }
  
  .fad.fa-sign-in::after, .fa-duotone.fa-sign-in::after {
	content: "\F090\F090"; }
  
  .fad.fa-pegasus::after, .fa-duotone.fa-pegasus::after {
	content: "\F703\F703"; }
  
  .fad.fa-files-medical::after, .fa-duotone.fa-files-medical::after {
	content: "\F7FD\F7FD"; }
  
  .fad.fa-nfc-lock::after, .fa-duotone.fa-nfc-lock::after {
	content: "\E1F8\E1F8"; }
  
  .fad.fa-person-ski-lift::after, .fa-duotone.fa-person-ski-lift::after {
	content: "\F7C8\F7C8"; }
  
  .fad.fa-ski-lift::after, .fa-duotone.fa-ski-lift::after {
	content: "\F7C8\F7C8"; }
  
  .fad.fa-square-6::after, .fa-duotone.fa-square-6::after {
	content: "\E25B\E25B"; }
  
  .fad.fa-shop-slash::after, .fa-duotone.fa-shop-slash::after {
	content: "\E070\E070"; }
  
  .fad.fa-store-alt-slash::after, .fa-duotone.fa-store-alt-slash::after {
	content: "\E070\E070"; }
  
  .fad.fa-wind-turbine::after, .fa-duotone.fa-wind-turbine::after {
	content: "\F89B\F89B"; }
  
  .fad.fa-sliders-simple::after, .fa-duotone.fa-sliders-simple::after {
	content: "\E253\E253"; }
  
  .fad.fa-badge-sheriff::after, .fa-duotone.fa-badge-sheriff::after {
	content: "\F8A2\F8A2"; }
  
  .fad.fa-server::after, .fa-duotone.fa-server::after {
	content: "\F233\F233"; }
  
  .fad.fa-virus-covid-slash::after, .fa-duotone.fa-virus-covid-slash::after {
	content: "\E4A9\E4A9"; }
  
  .fad.fa-intersection::after, .fa-duotone.fa-intersection::after {
	content: "\F668\F668"; }
  
  .fad.fa-shop-lock::after, .fa-duotone.fa-shop-lock::after {
	content: "\E4A5\E4A5"; }
  
  .fad.fa-family::after, .fa-duotone.fa-family::after {
	content: "\E300\E300"; }
  
  .fad.fa-hourglass-start::after, .fa-duotone.fa-hourglass-start::after {
	content: "\F251\F251"; }
  
  .fad.fa-hourglass-1::after, .fa-duotone.fa-hourglass-1::after {
	content: "\F251\F251"; }
  
  .fad.fa-user-hair-buns::after, .fa-duotone.fa-user-hair-buns::after {
	content: "\E3D3\E3D3"; }
  
  .fad.fa-blender-phone::after, .fa-duotone.fa-blender-phone::after {
	content: "\F6B6\F6B6"; }
  
  .fad.fa-hourglass-clock::after, .fa-duotone.fa-hourglass-clock::after {
	content: "\E41B\E41B"; }
  
  .fad.fa-person-seat-reclined::after, .fa-duotone.fa-person-seat-reclined::after {
	content: "\E21F\E21F"; }
  
  .fad.fa-paper-plane-top::after, .fa-duotone.fa-paper-plane-top::after {
	content: "\E20A\E20A"; }
  
  .fad.fa-paper-plane-alt::after, .fa-duotone.fa-paper-plane-alt::after {
	content: "\E20A\E20A"; }
  
  .fad.fa-send::after, .fa-duotone.fa-send::after {
	content: "\E20A\E20A"; }
  
  .fad.fa-message-arrow-up::after, .fa-duotone.fa-message-arrow-up::after {
	content: "\E1DC\E1DC"; }
  
  .fad.fa-comment-alt-arrow-up::after, .fa-duotone.fa-comment-alt-arrow-up::after {
	content: "\E1DC\E1DC"; }
  
  .fad.fa-lightbulb-exclamation::after, .fa-duotone.fa-lightbulb-exclamation::after {
	content: "\F671\F671"; }
  
  .fad.fa-layer-minus::after, .fa-duotone.fa-layer-minus::after {
	content: "\F5FE\F5FE"; }
  
  .fad.fa-layer-group-minus::after, .fa-duotone.fa-layer-group-minus::after {
	content: "\F5FE\F5FE"; }
  
  .fad.fa-circle-e::after, .fa-duotone.fa-circle-e::after {
	content: "\E109\E109"; }
  
  .fad.fa-building-wheat::after, .fa-duotone.fa-building-wheat::after {
	content: "\E4DB\E4DB"; }
  
  .fad.fa-gauge-max::after, .fa-duotone.fa-gauge-max::after {
	content: "\F626\F626"; }
  
  .fad.fa-tachometer-alt-fastest::after, .fa-duotone.fa-tachometer-alt-fastest::after {
	content: "\F626\F626"; }
  
  .fad.fa-person-breastfeeding::after, .fa-duotone.fa-person-breastfeeding::after {
	content: "\E53A\E53A"; }
  
  .fad.fa-apostrophe::after, .fa-duotone.fa-apostrophe::after {
	content: "''"; }
  
  .fad.fa-fire-hydrant::after, .fa-duotone.fa-fire-hydrant::after {
	content: "\E17F\E17F"; }
  
  .fad.fa-right-to-bracket::after, .fa-duotone.fa-right-to-bracket::after {
	content: "\F2F6\F2F6"; }
  
  .fad.fa-sign-in-alt::after, .fa-duotone.fa-sign-in-alt::after {
	content: "\F2F6\F2F6"; }
  
  .fad.fa-video-plus::after, .fa-duotone.fa-video-plus::after {
	content: "\F4E1\F4E1"; }
  
  .fad.fa-square-right::after, .fa-duotone.fa-square-right::after {
	content: "\F352\F352"; }
  
  .fad.fa-arrow-alt-square-right::after, .fa-duotone.fa-arrow-alt-square-right::after {
	content: "\F352\F352"; }
  
  .fad.fa-comment-smile::after, .fa-duotone.fa-comment-smile::after {
	content: "\F4B4\F4B4"; }
  
  .fad.fa-venus::after, .fa-duotone.fa-venus::after {
	content: "\F221\F221"; }
  
  .fad.fa-passport::after, .fa-duotone.fa-passport::after {
	content: "\F5AB\F5AB"; }
  
  .fad.fa-inbox-in::after, .fa-duotone.fa-inbox-in::after {
	content: "\F310\F310"; }
  
  .fad.fa-inbox-arrow-down::after, .fa-duotone.fa-inbox-arrow-down::after {
	content: "\F310\F310"; }
  
  .fad.fa-heart-pulse::after, .fa-duotone.fa-heart-pulse::after {
	content: "\F21E\F21E"; }
  
  .fad.fa-heartbeat::after, .fa-duotone.fa-heartbeat::after {
	content: "\F21E\F21E"; }
  
  .fad.fa-circle-8::after, .fa-duotone.fa-circle-8::after {
	content: "\E0F5\E0F5"; }
  
  .fad.fa-clouds-moon::after, .fa-duotone.fa-clouds-moon::after {
	content: "\F745\F745"; }
  
  .fad.fa-clock-ten-thirty::after, .fa-duotone.fa-clock-ten-thirty::after {
	content: "\E355\E355"; }
  
  .fad.fa-people-carry-box::after, .fa-duotone.fa-people-carry-box::after {
	content: "\F4CE\F4CE"; }
  
  .fad.fa-people-carry::after, .fa-duotone.fa-people-carry::after {
	content: "\F4CE\F4CE"; }
  
  .fad.fa-folder-user::after, .fa-duotone.fa-folder-user::after {
	content: "\E18E\E18E"; }
  
  .fad.fa-trash-can-xmark::after, .fa-duotone.fa-trash-can-xmark::after {
	content: "\E2AE\E2AE"; }
  
  .fad.fa-temperature-high::after, .fa-duotone.fa-temperature-high::after {
	content: "\F769\F769"; }
  
  .fad.fa-microchip::after, .fa-duotone.fa-microchip::after {
	content: "\F2DB\F2DB"; }
  
  .fad.fa-left-long-to-line::after, .fa-duotone.fa-left-long-to-line::after {
	content: "\E41E\E41E"; }
  
  .fad.fa-crown::after, .fa-duotone.fa-crown::after {
	content: "\F521\F521"; }
  
  .fad.fa-weight-hanging::after, .fa-duotone.fa-weight-hanging::after {
	content: "\F5CD\F5CD"; }
  
  .fad.fa-xmarks-lines::after, .fa-duotone.fa-xmarks-lines::after {
	content: "\E59A\E59A"; }
  
  .fad.fa-file-prescription::after, .fa-duotone.fa-file-prescription::after {
	content: "\F572\F572"; }
  
  .fad.fa-calendar-range::after, .fa-duotone.fa-calendar-range::after {
	content: "\E0D6\E0D6"; }
  
  .fad.fa-flower-daffodil::after, .fa-duotone.fa-flower-daffodil::after {
	content: "\F800\F800"; }
  
  .fad.fa-hand-back-point-up::after, .fa-duotone.fa-hand-back-point-up::after {
	content: "\E1A2\E1A2"; }
  
  .fad.fa-weight-scale::after, .fa-duotone.fa-weight-scale::after {
	content: "\F496\F496"; }
  
  .fad.fa-weight::after, .fa-duotone.fa-weight::after {
	content: "\F496\F496"; }
  
  .fad.fa-star-exclamation::after, .fa-duotone.fa-star-exclamation::after {
	content: "\F2F3\F2F3"; }
  
  .fad.fa-books::after, .fa-duotone.fa-books::after {
	content: "\F5DB\F5DB"; }
  
  .fad.fa-user-group::after, .fa-duotone.fa-user-group::after {
	content: "\F500\F500"; }
  
  .fad.fa-user-friends::after, .fa-duotone.fa-user-friends::after {
	content: "\F500\F500"; }
  
  .fad.fa-arrow-up-a-z::after, .fa-duotone.fa-arrow-up-a-z::after {
	content: "\F15E\F15E"; }
  
  .fad.fa-sort-alpha-up::after, .fa-duotone.fa-sort-alpha-up::after {
	content: "\F15E\F15E"; }
  
  .fad.fa-layer-plus::after, .fa-duotone.fa-layer-plus::after {
	content: "\F5FF\F5FF"; }
  
  .fad.fa-layer-group-plus::after, .fa-duotone.fa-layer-group-plus::after {
	content: "\F5FF\F5FF"; }
  
  .fad.fa-play-pause::after, .fa-duotone.fa-play-pause::after {
	content: "\E22F\E22F"; }
  
  .fad.fa-block-question::after, .fa-duotone.fa-block-question::after {
	content: "\E3DD\E3DD"; }
  
  .fad.fa-snooze::after, .fa-duotone.fa-snooze::after {
	content: "\F880\F880"; }
  
  .fad.fa-zzz::after, .fa-duotone.fa-zzz::after {
	content: "\F880\F880"; }
  
  .fad.fa-scanner-image::after, .fa-duotone.fa-scanner-image::after {
	content: "\F8F3\F8F3"; }
  
  .fad.fa-tv-retro::after, .fa-duotone.fa-tv-retro::after {
	content: "\F401\F401"; }
  
  .fad.fa-square-t::after, .fa-duotone.fa-square-t::after {
	content: "\E280\E280"; }
  
  .fad.fa-farm::after, .fa-duotone.fa-farm::after {
	content: "\F864\F864"; }
  
  .fad.fa-barn-silo::after, .fa-duotone.fa-barn-silo::after {
	content: "\F864\F864"; }
  
  .fad.fa-chess-knight::after, .fa-duotone.fa-chess-knight::after {
	content: "\F441\F441"; }
  
  .fad.fa-bars-sort::after, .fa-duotone.fa-bars-sort::after {
	content: "\E0AE\E0AE"; }
  
  .fad.fa-pallet-boxes::after, .fa-duotone.fa-pallet-boxes::after {
	content: "\F483\F483"; }
  
  .fad.fa-palette-boxes::after, .fa-duotone.fa-palette-boxes::after {
	content: "\F483\F483"; }
  
  .fad.fa-pallet-alt::after, .fa-duotone.fa-pallet-alt::after {
	content: "\F483\F483"; }
  
  .fad.fa-face-laugh-squint::after, .fa-duotone.fa-face-laugh-squint::after {
	content: "\F59B\F59B"; }
  
  .fad.fa-laugh-squint::after, .fa-duotone.fa-laugh-squint::after {
	content: "\F59B\F59B"; }
  
  .fad.fa-code-simple::after, .fa-duotone.fa-code-simple::after {
	content: "\E13D\E13D"; }
  
  .fad.fa-bolt-slash::after, .fa-duotone.fa-bolt-slash::after {
	content: "\E0B8\E0B8"; }
  
  .fad.fa-panel-fire::after, .fa-duotone.fa-panel-fire::after {
	content: "\E42F\E42F"; }
  
  .fad.fa-binary-circle-check::after, .fa-duotone.fa-binary-circle-check::after {
	content: "\E33C\E33C"; }
  
  .fad.fa-comment-minus::after, .fa-duotone.fa-comment-minus::after {
	content: "\F4B1\F4B1"; }
  
  .fad.fa-burrito::after, .fa-duotone.fa-burrito::after {
	content: "\F7ED\F7ED"; }
  
  .fad.fa-violin::after, .fa-duotone.fa-violin::after {
	content: "\F8ED\F8ED"; }
  
  .fad.fa-objects-column::after, .fa-duotone.fa-objects-column::after {
	content: "\E3C1\E3C1"; }
  
  .fad.fa-square-chevron-down::after, .fa-duotone.fa-square-chevron-down::after {
	content: "\F329\F329"; }
  
  .fad.fa-chevron-square-down::after, .fa-duotone.fa-chevron-square-down::after {
	content: "\F329\F329"; }
  
  .fad.fa-comment-plus::after, .fa-duotone.fa-comment-plus::after {
	content: "\F4B2\F4B2"; }
  
  .fad.fa-triangle-instrument::after, .fa-duotone.fa-triangle-instrument::after {
	content: "\F8E2\F8E2"; }
  
  .fad.fa-triangle-music::after, .fa-duotone.fa-triangle-music::after {
	content: "\F8E2\F8E2"; }
  
  .fad.fa-wheelchair::after, .fa-duotone.fa-wheelchair::after {
	content: "\F193\F193"; }
  
  .fad.fa-user-pilot-tie::after, .fa-duotone.fa-user-pilot-tie::after {
	content: "\E2C1\E2C1"; }
  
  .fad.fa-piano-keyboard::after, .fa-duotone.fa-piano-keyboard::after {
	content: "\F8D5\F8D5"; }
  
  .fad.fa-bed-empty::after, .fa-duotone.fa-bed-empty::after {
	content: "\F8F9\F8F9"; }
  
  .fad.fa-circle-arrow-up::after, .fa-duotone.fa-circle-arrow-up::after {
	content: "\F0AA\F0AA"; }
  
  .fad.fa-arrow-circle-up::after, .fa-duotone.fa-arrow-circle-up::after {
	content: "\F0AA\F0AA"; }
  
  .fad.fa-toggle-on::after, .fa-duotone.fa-toggle-on::after {
	content: "\F205\F205"; }
  
  .fad.fa-rectangle-vertical::after, .fa-duotone.fa-rectangle-vertical::after {
	content: "\F2FB\F2FB"; }
  
  .fad.fa-rectangle-portrait::after, .fa-duotone.fa-rectangle-portrait::after {
	content: "\F2FB\F2FB"; }
  
  .fad.fa-person-walking::after, .fa-duotone.fa-person-walking::after {
	content: "\F554\F554"; }
  
  .fad.fa-walking::after, .fa-duotone.fa-walking::after {
	content: "\F554\F554"; }
  
  .fad.fa-l::after, .fa-duotone.fa-l::after {
	content: "LL"; }
  
  .fad.fa-signal-stream::after, .fa-duotone.fa-signal-stream::after {
	content: "\F8DD\F8DD"; }
  
  .fad.fa-down-to-bracket::after, .fa-duotone.fa-down-to-bracket::after {
	content: "\E4E7\E4E7"; }
  
  .fad.fa-circle-z::after, .fa-duotone.fa-circle-z::after {
	content: "\E130\E130"; }
  
  .fad.fa-stars::after, .fa-duotone.fa-stars::after {
	content: "\F762\F762"; }
  
  .fad.fa-fire::after, .fa-duotone.fa-fire::after {
	content: "\F06D\F06D"; }
  
  .fad.fa-bed-pulse::after, .fa-duotone.fa-bed-pulse::after {
	content: "\F487\F487"; }
  
  .fad.fa-procedures::after, .fa-duotone.fa-procedures::after {
	content: "\F487\F487"; }
  
  .fad.fa-house-day::after, .fa-duotone.fa-house-day::after {
	content: "\E00E\E00E"; }
  
  .fad.fa-shuttle-space::after, .fa-duotone.fa-shuttle-space::after {
	content: "\F197\F197"; }
  
  .fad.fa-space-shuttle::after, .fa-duotone.fa-space-shuttle::after {
	content: "\F197\F197"; }
  
  .fad.fa-shirt-long-sleeve::after, .fa-duotone.fa-shirt-long-sleeve::after {
	content: "\E3C7\E3C7"; }
  
  .fad.fa-chart-pie-simple::after, .fa-duotone.fa-chart-pie-simple::after {
	content: "\F64E\F64E"; }
  
  .fad.fa-chart-pie-alt::after, .fa-duotone.fa-chart-pie-alt::after {
	content: "\F64E\F64E"; }
  
  .fad.fa-face-laugh::after, .fa-duotone.fa-face-laugh::after {
	content: "\F599\F599"; }
  
  .fad.fa-laugh::after, .fa-duotone.fa-laugh::after {
	content: "\F599\F599"; }
  
  .fad.fa-folder-open::after, .fa-duotone.fa-folder-open::after {
	content: "\F07C\F07C"; }
  
  .fad.fa-album-collection-circle-user::after, .fa-duotone.fa-album-collection-circle-user::after {
	content: "\E48F\E48F"; }
  
  .fad.fa-candy::after, .fa-duotone.fa-candy::after {
	content: "\E3E7\E3E7"; }
  
  .fad.fa-bowl-hot::after, .fa-duotone.fa-bowl-hot::after {
	content: "\F823\F823"; }
  
  .fad.fa-soup::after, .fa-duotone.fa-soup::after {
	content: "\F823\F823"; }
  
  .fad.fa-flatbread::after, .fa-duotone.fa-flatbread::after {
	content: "\E40B\E40B"; }
  
  .fad.fa-heart-circle-plus::after, .fa-duotone.fa-heart-circle-plus::after {
	content: "\E500\E500"; }
  
  .fad.fa-code-fork::after, .fa-duotone.fa-code-fork::after {
	content: "\E13B\E13B"; }
  
  .fad.fa-city::after, .fa-duotone.fa-city::after {
	content: "\F64F\F64F"; }
  
  .fad.fa-signal-bars-weak::after, .fa-duotone.fa-signal-bars-weak::after {
	content: "\F691\F691"; }
  
  .fad.fa-signal-alt-1::after, .fa-duotone.fa-signal-alt-1::after {
	content: "\F691\F691"; }
  
  .fad.fa-microphone-lines::after, .fa-duotone.fa-microphone-lines::after {
	content: "\F3C9\F3C9"; }
  
  .fad.fa-microphone-alt::after, .fa-duotone.fa-microphone-alt::after {
	content: "\F3C9\F3C9"; }
  
  .fad.fa-clock-twelve::after, .fa-duotone.fa-clock-twelve::after {
	content: "\E358\E358"; }
  
  .fad.fa-pepper-hot::after, .fa-duotone.fa-pepper-hot::after {
	content: "\F816\F816"; }
  
  .fad.fa-citrus-slice::after, .fa-duotone.fa-citrus-slice::after {
	content: "\E2F5\E2F5"; }
  
  .fad.fa-sheep::after, .fa-duotone.fa-sheep::after {
	content: "\F711\F711"; }
  
  .fad.fa-unlock::after, .fa-duotone.fa-unlock::after {
	content: "\F09C\F09C"; }
  
  .fad.fa-colon-sign::after, .fa-duotone.fa-colon-sign::after {
	content: "\E140\E140"; }
  
  .fad.fa-headset::after, .fa-duotone.fa-headset::after {
	content: "\F590\F590"; }
  
  .fad.fa-badger-honey::after, .fa-duotone.fa-badger-honey::after {
	content: "\F6B4\F6B4"; }
  
  .fad.fa-h4::after, .fa-duotone.fa-h4::after {
	content: "\F86A\F86A"; }
  
  .fad.fa-store-slash::after, .fa-duotone.fa-store-slash::after {
	content: "\E071\E071"; }
  
  .fad.fa-road-circle-xmark::after, .fa-duotone.fa-road-circle-xmark::after {
	content: "\E566\E566"; }
  
  .fad.fa-signal-slash::after, .fa-duotone.fa-signal-slash::after {
	content: "\F695\F695"; }
  
  .fad.fa-user-minus::after, .fa-duotone.fa-user-minus::after {
	content: "\F503\F503"; }
  
  .fad.fa-mars-stroke-up::after, .fa-duotone.fa-mars-stroke-up::after {
	content: "\F22A\F22A"; }
  
  .fad.fa-mars-stroke-v::after, .fa-duotone.fa-mars-stroke-v::after {
	content: "\F22A\F22A"; }
  
  .fad.fa-champagne-glasses::after, .fa-duotone.fa-champagne-glasses::after {
	content: "\F79F\F79F"; }
  
  .fad.fa-glass-cheers::after, .fa-duotone.fa-glass-cheers::after {
	content: "\F79F\F79F"; }
  
  .fad.fa-taco::after, .fa-duotone.fa-taco::after {
	content: "\F826\F826"; }
  
  .fad.fa-hexagon-plus::after, .fa-duotone.fa-hexagon-plus::after {
	content: "\F300\F300"; }
  
  .fad.fa-plus-hexagon::after, .fa-duotone.fa-plus-hexagon::after {
	content: "\F300\F300"; }
  
  .fad.fa-clipboard::after, .fa-duotone.fa-clipboard::after {
	content: "\F328\F328"; }
  
  .fad.fa-house-circle-exclamation::after, .fa-duotone.fa-house-circle-exclamation::after {
	content: "\E50A\E50A"; }
  
  .fad.fa-file-arrow-up::after, .fa-duotone.fa-file-arrow-up::after {
	content: "\F574\F574"; }
  
  .fad.fa-file-upload::after, .fa-duotone.fa-file-upload::after {
	content: "\F574\F574"; }
  
  .fad.fa-wifi::after, .fa-duotone.fa-wifi::after {
	content: "\F1EB\F1EB"; }
  
  .fad.fa-wifi-3::after, .fa-duotone.fa-wifi-3::after {
	content: "\F1EB\F1EB"; }
  
  .fad.fa-wifi-strong::after, .fa-duotone.fa-wifi-strong::after {
	content: "\F1EB\F1EB"; }
  
  .fad.fa-messages::after, .fa-duotone.fa-messages::after {
	content: "\F4B6\F4B6"; }
  
  .fad.fa-comments-alt::after, .fa-duotone.fa-comments-alt::after {
	content: "\F4B6\F4B6"; }
  
  .fad.fa-bath::after, .fa-duotone.fa-bath::after {
	content: "\F2CD\F2CD"; }
  
  .fad.fa-bathtub::after, .fa-duotone.fa-bathtub::after {
	content: "\F2CD\F2CD"; }
  
  .fad.fa-umbrella-simple::after, .fa-duotone.fa-umbrella-simple::after {
	content: "\E2BC\E2BC"; }
  
  .fad.fa-umbrella-alt::after, .fa-duotone.fa-umbrella-alt::after {
	content: "\E2BC\E2BC"; }
  
  .fad.fa-rectangle-history-circle-plus::after, .fa-duotone.fa-rectangle-history-circle-plus::after {
	content: "\E4A3\E4A3"; }
  
  .fad.fa-underline::after, .fa-duotone.fa-underline::after {
	content: "\F0CD\F0CD"; }
  
  .fad.fa-user-pen::after, .fa-duotone.fa-user-pen::after {
	content: "\F4FF\F4FF"; }
  
  .fad.fa-user-edit::after, .fa-duotone.fa-user-edit::after {
	content: "\F4FF\F4FF"; }
  
  .fad.fa-binary-slash::after, .fa-duotone.fa-binary-slash::after {
	content: "\E33E\E33E"; }
  
  .fad.fa-square-o::after, .fa-duotone.fa-square-o::after {
	content: "\E278\E278"; }
  
  .fad.fa-signature::after, .fa-duotone.fa-signature::after {
	content: "\F5B7\F5B7"; }
  
  .fad.fa-stroopwafel::after, .fa-duotone.fa-stroopwafel::after {
	content: "\F551\F551"; }
  
  .fad.fa-bold::after, .fa-duotone.fa-bold::after {
	content: "\F032\F032"; }
  
  .fad.fa-anchor-lock::after, .fa-duotone.fa-anchor-lock::after {
	content: "\E4AD\E4AD"; }
  
  .fad.fa-building-ngo::after, .fa-duotone.fa-building-ngo::after {
	content: "\E4D7\E4D7"; }
  
  .fad.fa-transporter-3::after, .fa-duotone.fa-transporter-3::after {
	content: "\E045\E045"; }
  
  .fad.fa-engine-warning::after, .fa-duotone.fa-engine-warning::after {
	content: "\F5F2\F5F2"; }
  
  .fad.fa-engine-exclamation::after, .fa-duotone.fa-engine-exclamation::after {
	content: "\F5F2\F5F2"; }
  
  .fad.fa-circle-down-right::after, .fa-duotone.fa-circle-down-right::after {
	content: "\E108\E108"; }
  
  .fad.fa-square-k::after, .fa-duotone.fa-square-k::after {
	content: "\E274\E274"; }
  
  .fad.fa-manat-sign::after, .fa-duotone.fa-manat-sign::after {
	content: "\E1D5\E1D5"; }
  
  .fad.fa-money-check-pen::after, .fa-duotone.fa-money-check-pen::after {
	content: "\F872\F872"; }
  
  .fad.fa-money-check-edit::after, .fa-duotone.fa-money-check-edit::after {
	content: "\F872\F872"; }
  
  .fad.fa-not-equal::after, .fa-duotone.fa-not-equal::after {
	content: "\F53E\F53E"; }
  
  .fad.fa-border-top-left::after, .fa-duotone.fa-border-top-left::after {
	content: "\F853\F853"; }
  
  .fad.fa-border-style::after, .fa-duotone.fa-border-style::after {
	content: "\F853\F853"; }
  
  .fad.fa-map-location-dot::after, .fa-duotone.fa-map-location-dot::after {
	content: "\F5A0\F5A0"; }
  
  .fad.fa-map-marked-alt::after, .fa-duotone.fa-map-marked-alt::after {
	content: "\F5A0\F5A0"; }
  
  .fad.fa-tilde::after, .fa-duotone.fa-tilde::after {
	content: "~~"; }
  
  .fad.fa-jedi::after, .fa-duotone.fa-jedi::after {
	content: "\F669\F669"; }
  
  .fad.fa-square-poll-vertical::after, .fa-duotone.fa-square-poll-vertical::after {
	content: "\F681\F681"; }
  
  .fad.fa-poll::after, .fa-duotone.fa-poll::after {
	content: "\F681\F681"; }
  
  .fad.fa-arrow-down-square-triangle::after, .fa-duotone.fa-arrow-down-square-triangle::after {
	content: "\F889\F889"; }
  
  .fad.fa-sort-shapes-down-alt::after, .fa-duotone.fa-sort-shapes-down-alt::after {
	content: "\F889\F889"; }
  
  .fad.fa-mug-hot::after, .fa-duotone.fa-mug-hot::after {
	content: "\F7B6\F7B6"; }
  
  .fad.fa-dog-leashed::after, .fa-duotone.fa-dog-leashed::after {
	content: "\F6D4\F6D4"; }
  
  .fad.fa-car-battery::after, .fa-duotone.fa-car-battery::after {
	content: "\F5DF\F5DF"; }
  
  .fad.fa-battery-car::after, .fa-duotone.fa-battery-car::after {
	content: "\F5DF\F5DF"; }
  
  .fad.fa-face-downcast-sweat::after, .fa-duotone.fa-face-downcast-sweat::after {
	content: "\E371\E371"; }
  
  .fad.fa-memo-circle-info::after, .fa-duotone.fa-memo-circle-info::after {
	content: "\E49A\E49A"; }
  
  .fad.fa-gift::after, .fa-duotone.fa-gift::after {
	content: "\F06B\F06B"; }
  
  .fad.fa-dice-two::after, .fa-duotone.fa-dice-two::after {
	content: "\F528\F528"; }
  
  .fad.fa-volume::after, .fa-duotone.fa-volume::after {
	content: "\F6A8\F6A8"; }
  
  .fad.fa-volume-medium::after, .fa-duotone.fa-volume-medium::after {
	content: "\F6A8\F6A8"; }
  
  .fad.fa-transporter-5::after, .fa-duotone.fa-transporter-5::after {
	content: "\E2A6\E2A6"; }
  
  .fad.fa-gauge-circle-bolt::after, .fa-duotone.fa-gauge-circle-bolt::after {
	content: "\E496\E496"; }
  
  .fad.fa-coin-front::after, .fa-duotone.fa-coin-front::after {
	content: "\E3FC\E3FC"; }
  
  .fad.fa-file-slash::after, .fa-duotone.fa-file-slash::after {
	content: "\E3A7\E3A7"; }
  
  .fad.fa-message-arrow-up-right::after, .fa-duotone.fa-message-arrow-up-right::after {
	content: "\E1DD\E1DD"; }
  
  .fad.fa-treasure-chest::after, .fa-duotone.fa-treasure-chest::after {
	content: "\F723\F723"; }
  
  .fad.fa-chess-queen::after, .fa-duotone.fa-chess-queen::after {
	content: "\F445\F445"; }
  
  .fad.fa-paintbrush-fine::after, .fa-duotone.fa-paintbrush-fine::after {
	content: "\F5A9\F5A9"; }
  
  .fad.fa-paint-brush-alt::after, .fa-duotone.fa-paint-brush-alt::after {
	content: "\F5A9\F5A9"; }
  
  .fad.fa-paint-brush-fine::after, .fa-duotone.fa-paint-brush-fine::after {
	content: "\F5A9\F5A9"; }
  
  .fad.fa-paintbrush-alt::after, .fa-duotone.fa-paintbrush-alt::after {
	content: "\F5A9\F5A9"; }
  
  .fad.fa-glasses::after, .fa-duotone.fa-glasses::after {
	content: "\F530\F530"; }
  
  .fad.fa-hood-cloak::after, .fa-duotone.fa-hood-cloak::after {
	content: "\F6EF\F6EF"; }
  
  .fad.fa-square-quote::after, .fa-duotone.fa-square-quote::after {
	content: "\E329\E329"; }
  
  .fad.fa-up-left::after, .fa-duotone.fa-up-left::after {
	content: "\E2BD\E2BD"; }
  
  .fad.fa-bring-front::after, .fa-duotone.fa-bring-front::after {
	content: "\F857\F857"; }
  
  .fad.fa-chess-board::after, .fa-duotone.fa-chess-board::after {
	content: "\F43C\F43C"; }
  
  .fad.fa-burger-cheese::after, .fa-duotone.fa-burger-cheese::after {
	content: "\F7F1\F7F1"; }
  
  .fad.fa-cheeseburger::after, .fa-duotone.fa-cheeseburger::after {
	content: "\F7F1\F7F1"; }
  
  .fad.fa-building-circle-check::after, .fa-duotone.fa-building-circle-check::after {
	content: "\E4D2\E4D2"; }
  
  .fad.fa-repeat-1::after, .fa-duotone.fa-repeat-1::after {
	content: "\F365\F365"; }
  
  .fad.fa-arrow-down-to-line::after, .fa-duotone.fa-arrow-down-to-line::after {
	content: "\F33D\F33D"; }
  
  .fad.fa-arrow-to-bottom::after, .fa-duotone.fa-arrow-to-bottom::after {
	content: "\F33D\F33D"; }
  
  .fad.fa-grid-5::after, .fa-duotone.fa-grid-5::after {
	content: "\E199\E199"; }
  
  .fad.fa-right-long-to-line::after, .fa-duotone.fa-right-long-to-line::after {
	content: "\E444\E444"; }
  
  .fad.fa-person-chalkboard::after, .fa-duotone.fa-person-chalkboard::after {
	content: "\E53D\E53D"; }
  
  .fad.fa-mars-stroke-right::after, .fa-duotone.fa-mars-stroke-right::after {
	content: "\F22B\F22B"; }
  
  .fad.fa-mars-stroke-h::after, .fa-duotone.fa-mars-stroke-h::after {
	content: "\F22B\F22B"; }
  
  .fad.fa-hand-back-fist::after, .fa-duotone.fa-hand-back-fist::after {
	content: "\F255\F255"; }
  
  .fad.fa-hand-rock::after, .fa-duotone.fa-hand-rock::after {
	content: "\F255\F255"; }
  
  .fad.fa-tally::after, .fa-duotone.fa-tally::after {
	content: "\F69C\F69C"; }
  
  .fad.fa-tally-5::after, .fa-duotone.fa-tally-5::after {
	content: "\F69C\F69C"; }
  
  .fad.fa-square-caret-up::after, .fa-duotone.fa-square-caret-up::after {
	content: "\F151\F151"; }
  
  .fad.fa-caret-square-up::after, .fa-duotone.fa-caret-square-up::after {
	content: "\F151\F151"; }
  
  .fad.fa-cloud-showers-water::after, .fa-duotone.fa-cloud-showers-water::after {
	content: "\E4E4\E4E4"; }
  
  .fad.fa-chart-bar::after, .fa-duotone.fa-chart-bar::after {
	content: "\F080\F080"; }
  
  .fad.fa-bar-chart::after, .fa-duotone.fa-bar-chart::after {
	content: "\F080\F080"; }
  
  .fad.fa-hands-bubbles::after, .fa-duotone.fa-hands-bubbles::after {
	content: "\E05E\E05E"; }
  
  .fad.fa-hands-wash::after, .fa-duotone.fa-hands-wash::after {
	content: "\E05E\E05E"; }
  
  .fad.fa-less-than-equal::after, .fa-duotone.fa-less-than-equal::after {
	content: "\F537\F537"; }
  
  .fad.fa-train::after, .fa-duotone.fa-train::after {
	content: "\F238\F238"; }
  
  .fad.fa-up-from-dotted-line::after, .fa-duotone.fa-up-from-dotted-line::after {
	content: "\E456\E456"; }
  
  .fad.fa-eye-low-vision::after, .fa-duotone.fa-eye-low-vision::after {
	content: "\F2A8\F2A8"; }
  
  .fad.fa-low-vision::after, .fa-duotone.fa-low-vision::after {
	content: "\F2A8\F2A8"; }
  
  .fad.fa-traffic-light-go::after, .fa-duotone.fa-traffic-light-go::after {
	content: "\F638\F638"; }
  
  .fad.fa-face-exhaling::after, .fa-duotone.fa-face-exhaling::after {
	content: "\E480\E480"; }
  
  .fad.fa-sensor-fire::after, .fa-duotone.fa-sensor-fire::after {
	content: "\E02A\E02A"; }
  
  .fad.fa-user-unlock::after, .fa-duotone.fa-user-unlock::after {
	content: "\E058\E058"; }
  
  .fad.fa-hexagon-divide::after, .fa-duotone.fa-hexagon-divide::after {
	content: "\E1AD\E1AD"; }
  
  .fad.fa-00::after, .fa-duotone.fa-00::after {
	content: "\E467\E467"; }
  
  .fad.fa-crow::after, .fa-duotone.fa-crow::after {
	content: "\F520\F520"; }
  
  .fad.fa-cassette-betamax::after, .fa-duotone.fa-cassette-betamax::after {
	content: "\F8A4\F8A4"; }
  
  .fad.fa-betamax::after, .fa-duotone.fa-betamax::after {
	content: "\F8A4\F8A4"; }
  
  .fad.fa-sailboat::after, .fa-duotone.fa-sailboat::after {
	content: "\E445\E445"; }
  
  .fad.fa-window-restore::after, .fa-duotone.fa-window-restore::after {
	content: "\F2D2\F2D2"; }
  
  .fad.fa-nfc-magnifying-glass::after, .fa-duotone.fa-nfc-magnifying-glass::after {
	content: "\E1F9\E1F9"; }
  
  .fad.fa-file-binary::after, .fa-duotone.fa-file-binary::after {
	content: "\E175\E175"; }
  
  .fad.fa-circle-v::after, .fa-duotone.fa-circle-v::after {
	content: "\E12A\E12A"; }
  
  .fad.fa-square-plus::after, .fa-duotone.fa-square-plus::after {
	content: "\F0FE\F0FE"; }
  
  .fad.fa-plus-square::after, .fa-duotone.fa-plus-square::after {
	content: "\F0FE\F0FE"; }
  
  .fad.fa-bowl-scoops::after, .fa-duotone.fa-bowl-scoops::after {
	content: "\E3DF\E3DF"; }
  
  .fad.fa-mistletoe::after, .fa-duotone.fa-mistletoe::after {
	content: "\F7B4\F7B4"; }
  
  .fad.fa-custard::after, .fa-duotone.fa-custard::after {
	content: "\E403\E403"; }
  
  .fad.fa-lacrosse-stick::after, .fa-duotone.fa-lacrosse-stick::after {
	content: "\E3B5\E3B5"; }
  
  .fad.fa-hockey-mask::after, .fa-duotone.fa-hockey-mask::after {
	content: "\F6EE\F6EE"; }
  
  .fad.fa-sunrise::after, .fa-duotone.fa-sunrise::after {
	content: "\F766\F766"; }
  
  .fad.fa-panel-ews::after, .fa-duotone.fa-panel-ews::after {
	content: "\E42E\E42E"; }
  
  .fad.fa-torii-gate::after, .fa-duotone.fa-torii-gate::after {
	content: "\F6A1\F6A1"; }
  
  .fad.fa-cloud-exclamation::after, .fa-duotone.fa-cloud-exclamation::after {
	content: "\E491\E491"; }
  
  .fad.fa-message-lines::after, .fa-duotone.fa-message-lines::after {
	content: "\F4A6\F4A6"; }
  
  .fad.fa-comment-alt-lines::after, .fa-duotone.fa-comment-alt-lines::after {
	content: "\F4A6\F4A6"; }
  
  .fad.fa-frog::after, .fa-duotone.fa-frog::after {
	content: "\F52E\F52E"; }
  
  .fad.fa-bucket::after, .fa-duotone.fa-bucket::after {
	content: "\E4CF\E4CF"; }
  
  .fad.fa-floppy-disk-pen::after, .fa-duotone.fa-floppy-disk-pen::after {
	content: "\E182\E182"; }
  
  .fad.fa-image::after, .fa-duotone.fa-image::after {
	content: "\F03E\F03E"; }
  
  .fad.fa-window-frame::after, .fa-duotone.fa-window-frame::after {
	content: "\E04F\E04F"; }
  
  .fad.fa-microphone::after, .fa-duotone.fa-microphone::after {
	content: "\F130\F130"; }
  
  .fad.fa-cow::after, .fa-duotone.fa-cow::after {
	content: "\F6C8\F6C8"; }
  
  .fad.fa-square-ring::after, .fa-duotone.fa-square-ring::after {
	content: "\E44F\E44F"; }
  
  .fad.fa-down-from-line::after, .fa-duotone.fa-down-from-line::after {
	content: "\F349\F349"; }
  
  .fad.fa-arrow-alt-from-top::after, .fa-duotone.fa-arrow-alt-from-top::after {
	content: "\F349\F349"; }
  
  .fad.fa-caret-up::after, .fa-duotone.fa-caret-up::after {
	content: "\F0D8\F0D8"; }
  
  .fad.fa-shield-xmark::after, .fa-duotone.fa-shield-xmark::after {
	content: "\E24C\E24C"; }
  
  .fad.fa-shield-times::after, .fa-duotone.fa-shield-times::after {
	content: "\E24C\E24C"; }
  
  .fad.fa-screwdriver::after, .fa-duotone.fa-screwdriver::after {
	content: "\F54A\F54A"; }
  
  .fad.fa-circle-sort-down::after, .fa-duotone.fa-circle-sort-down::after {
	content: "\E031\E031"; }
  
  .fad.fa-sort-circle-down::after, .fa-duotone.fa-sort-circle-down::after {
	content: "\E031\E031"; }
  
  .fad.fa-folder-closed::after, .fa-duotone.fa-folder-closed::after {
	content: "\E185\E185"; }
  
  .fad.fa-house-tsunami::after, .fa-duotone.fa-house-tsunami::after {
	content: "\E515\E515"; }
  
  .fad.fa-square-nfi::after, .fa-duotone.fa-square-nfi::after {
	content: "\E576\E576"; }
  
  .fad.fa-forklift::after, .fa-duotone.fa-forklift::after {
	content: "\F47A\F47A"; }
  
  .fad.fa-arrow-up-from-ground-water::after, .fa-duotone.fa-arrow-up-from-ground-water::after {
	content: "\E4B5\E4B5"; }
  
  .fad.fa-bracket-square-right::after, .fa-duotone.fa-bracket-square-right::after {
	content: "]]"; }
  
  .fad.fa-martini-glass::after, .fa-duotone.fa-martini-glass::after {
	content: "\F57B\F57B"; }
  
  .fad.fa-glass-martini-alt::after, .fa-duotone.fa-glass-martini-alt::after {
	content: "\F57B\F57B"; }
  
  .fad.fa-rotate-left::after, .fa-duotone.fa-rotate-left::after {
	content: "\F2EA\F2EA"; }
  
  .fad.fa-rotate-back::after, .fa-duotone.fa-rotate-back::after {
	content: "\F2EA\F2EA"; }
  
  .fad.fa-rotate-backward::after, .fa-duotone.fa-rotate-backward::after {
	content: "\F2EA\F2EA"; }
  
  .fad.fa-undo-alt::after, .fa-duotone.fa-undo-alt::after {
	content: "\F2EA\F2EA"; }
  
  .fad.fa-table-columns::after, .fa-duotone.fa-table-columns::after {
	content: "\F0DB\F0DB"; }
  
  .fad.fa-columns::after, .fa-duotone.fa-columns::after {
	content: "\F0DB\F0DB"; }
  
  .fad.fa-square-a::after, .fa-duotone.fa-square-a::after {
	content: "\E25F\E25F"; }
  
  .fad.fa-tick::after, .fa-duotone.fa-tick::after {
	content: "\E32F\E32F"; }
  
  .fad.fa-lemon::after, .fa-duotone.fa-lemon::after {
	content: "\F094\F094"; }
  
  .fad.fa-head-side-mask::after, .fa-duotone.fa-head-side-mask::after {
	content: "\E063\E063"; }
  
  .fad.fa-handshake::after, .fa-duotone.fa-handshake::after {
	content: "\F2B5\F2B5"; }
  
  .fad.fa-gem::after, .fa-duotone.fa-gem::after {
	content: "\F3A5\F3A5"; }
  
  .fad.fa-dolly::after, .fa-duotone.fa-dolly::after {
	content: "\F472\F472"; }
  
  .fad.fa-dolly-box::after, .fa-duotone.fa-dolly-box::after {
	content: "\F472\F472"; }
  
  .fad.fa-smoking::after, .fa-duotone.fa-smoking::after {
	content: "\F48D\F48D"; }
  
  .fad.fa-minimize::after, .fa-duotone.fa-minimize::after {
	content: "\F78C\F78C"; }
  
  .fad.fa-compress-arrows-alt::after, .fa-duotone.fa-compress-arrows-alt::after {
	content: "\F78C\F78C"; }
  
  .fad.fa-refrigerator::after, .fa-duotone.fa-refrigerator::after {
	content: "\E026\E026"; }
  
  .fad.fa-monument::after, .fa-duotone.fa-monument::after {
	content: "\F5A6\F5A6"; }
  
  .fad.fa-octagon-xmark::after, .fa-duotone.fa-octagon-xmark::after {
	content: "\F2F0\F2F0"; }
  
  .fad.fa-times-octagon::after, .fa-duotone.fa-times-octagon::after {
	content: "\F2F0\F2F0"; }
  
  .fad.fa-xmark-octagon::after, .fa-duotone.fa-xmark-octagon::after {
	content: "\F2F0\F2F0"; }
  
  .fad.fa-align-slash::after, .fa-duotone.fa-align-slash::after {
	content: "\F846\F846"; }
  
  .fad.fa-snowplow::after, .fa-duotone.fa-snowplow::after {
	content: "\F7D2\F7D2"; }
  
  .fad.fa-angles-right::after, .fa-duotone.fa-angles-right::after {
	content: "\F101\F101"; }
  
  .fad.fa-angle-double-right::after, .fa-duotone.fa-angle-double-right::after {
	content: "\F101\F101"; }
  
  .fad.fa-truck-ramp-couch::after, .fa-duotone.fa-truck-ramp-couch::after {
	content: "\F4DD\F4DD"; }
  
  .fad.fa-truck-couch::after, .fa-duotone.fa-truck-couch::after {
	content: "\F4DD\F4DD"; }
  
  .fad.fa-cannabis::after, .fa-duotone.fa-cannabis::after {
	content: "\F55F\F55F"; }
  
  .fad.fa-circle-play::after, .fa-duotone.fa-circle-play::after {
	content: "\F144\F144"; }
  
  .fad.fa-play-circle::after, .fa-duotone.fa-play-circle::after {
	content: "\F144\F144"; }
  
  .fad.fa-arrow-up-right-and-arrow-down-left-from-center::after, .fa-duotone.fa-arrow-up-right-and-arrow-down-left-from-center::after {
	content: "\E0A0\E0A0"; }
  
  .fad.fa-tablets::after, .fa-duotone.fa-tablets::after {
	content: "\F490\F490"; }
  
  .fad.fa-360-degrees::after, .fa-duotone.fa-360-degrees::after {
	content: "\E2DC\E2DC"; }
  
  .fad.fa-ethernet::after, .fa-duotone.fa-ethernet::after {
	content: "\F796\F796"; }
  
  .fad.fa-euro-sign::after, .fa-duotone.fa-euro-sign::after {
	content: "\F153\F153"; }
  
  .fad.fa-eur::after, .fa-duotone.fa-eur::after {
	content: "\F153\F153"; }
  
  .fad.fa-euro::after, .fa-duotone.fa-euro::after {
	content: "\F153\F153"; }
  
  .fad.fa-chair::after, .fa-duotone.fa-chair::after {
	content: "\F6C0\F6C0"; }
  
  .fad.fa-circle-check::after, .fa-duotone.fa-circle-check::after {
	content: "\F058\F058"; }
  
  .fad.fa-check-circle::after, .fa-duotone.fa-check-circle::after {
	content: "\F058\F058"; }
  
  .fad.fa-money-simple-from-bracket::after, .fa-duotone.fa-money-simple-from-bracket::after {
	content: "\E313\E313"; }
  
  .fad.fa-bat::after, .fa-duotone.fa-bat::after {
	content: "\F6B5\F6B5"; }
  
  .fad.fa-circle-stop::after, .fa-duotone.fa-circle-stop::after {
	content: "\F28D\F28D"; }
  
  .fad.fa-stop-circle::after, .fa-duotone.fa-stop-circle::after {
	content: "\F28D\F28D"; }
  
  .fad.fa-head-side-headphones::after, .fa-duotone.fa-head-side-headphones::after {
	content: "\F8C2\F8C2"; }
  
  .fad.fa-phone-rotary::after, .fa-duotone.fa-phone-rotary::after {
	content: "\F8D3\F8D3"; }
  
  .fad.fa-compass-drafting::after, .fa-duotone.fa-compass-drafting::after {
	content: "\F568\F568"; }
  
  .fad.fa-drafting-compass::after, .fa-duotone.fa-drafting-compass::after {
	content: "\F568\F568"; }
  
  .fad.fa-plate-wheat::after, .fa-duotone.fa-plate-wheat::after {
	content: "\E55A\E55A"; }
  
  .fad.fa-calendar-circle-minus::after, .fa-duotone.fa-calendar-circle-minus::after {
	content: "\E46F\E46F"; }
  
  .fad.fa-chopsticks::after, .fa-duotone.fa-chopsticks::after {
	content: "\E3F7\E3F7"; }
  
  .fad.fa-car-wrench::after, .fa-duotone.fa-car-wrench::after {
	content: "\F5E3\F5E3"; }
  
  .fad.fa-car-mechanic::after, .fa-duotone.fa-car-mechanic::after {
	content: "\F5E3\F5E3"; }
  
  .fad.fa-icicles::after, .fa-duotone.fa-icicles::after {
	content: "\F7AD\F7AD"; }
  
  .fad.fa-person-shelter::after, .fa-duotone.fa-person-shelter::after {
	content: "\E54F\E54F"; }
  
  .fad.fa-neuter::after, .fa-duotone.fa-neuter::after {
	content: "\F22C\F22C"; }
  
  .fad.fa-id-badge::after, .fa-duotone.fa-id-badge::after {
	content: "\F2C1\F2C1"; }
  
  .fad.fa-kazoo::after, .fa-duotone.fa-kazoo::after {
	content: "\F8C7\F8C7"; }
  
  .fad.fa-marker::after, .fa-duotone.fa-marker::after {
	content: "\F5A1\F5A1"; }
  
  .fad.fa-face-laugh-beam::after, .fa-duotone.fa-face-laugh-beam::after {
	content: "\F59A\F59A"; }
  
  .fad.fa-laugh-beam::after, .fa-duotone.fa-laugh-beam::after {
	content: "\F59A\F59A"; }
  
  .fad.fa-square-arrow-down-left::after, .fa-duotone.fa-square-arrow-down-left::after {
	content: "\E261\E261"; }
  
  .fad.fa-battery-bolt::after, .fa-duotone.fa-battery-bolt::after {
	content: "\F376\F376"; }
  
  .fad.fa-tree-large::after, .fa-duotone.fa-tree-large::after {
	content: "\F7DD\F7DD"; }
  
  .fad.fa-helicopter-symbol::after, .fa-duotone.fa-helicopter-symbol::after {
	content: "\E502\E502"; }
  
  .fad.fa-aperture::after, .fa-duotone.fa-aperture::after {
	content: "\E2DF\E2DF"; }
  
  .fad.fa-universal-access::after, .fa-duotone.fa-universal-access::after {
	content: "\F29A\F29A"; }
  
  .fad.fa-file-magnifying-glass::after, .fa-duotone.fa-file-magnifying-glass::after {
	content: "\F865\F865"; }
  
  .fad.fa-file-search::after, .fa-duotone.fa-file-search::after {
	content: "\F865\F865"; }
  
  .fad.fa-up-right::after, .fa-duotone.fa-up-right::after {
	content: "\E2BE\E2BE"; }
  
  .fad.fa-circle-chevron-up::after, .fa-duotone.fa-circle-chevron-up::after {
	content: "\F139\F139"; }
  
  .fad.fa-chevron-circle-up::after, .fa-duotone.fa-chevron-circle-up::after {
	content: "\F139\F139"; }
  
  .fad.fa-user-police::after, .fa-duotone.fa-user-police::after {
	content: "\E333\E333"; }
  
  .fad.fa-lari-sign::after, .fa-duotone.fa-lari-sign::after {
	content: "\E1C8\E1C8"; }
  
  .fad.fa-volcano::after, .fa-duotone.fa-volcano::after {
	content: "\F770\F770"; }
  
  .fad.fa-teddy-bear::after, .fa-duotone.fa-teddy-bear::after {
	content: "\E3CF\E3CF"; }
  
  .fad.fa-stocking::after, .fa-duotone.fa-stocking::after {
	content: "\F7D5\F7D5"; }
  
  .fad.fa-person-walking-dashed-line-arrow-right::after, .fa-duotone.fa-person-walking-dashed-line-arrow-right::after {
	content: "\E553\E553"; }
  
  .fad.fa-image-slash::after, .fa-duotone.fa-image-slash::after {
	content: "\E1B7\E1B7"; }
  
  .fad.fa-mask-snorkel::after, .fa-duotone.fa-mask-snorkel::after {
	content: "\E3B7\E3B7"; }
  
  .fad.fa-smoke::after, .fa-duotone.fa-smoke::after {
	content: "\F760\F760"; }
  
  .fad.fa-sterling-sign::after, .fa-duotone.fa-sterling-sign::after {
	content: "\F154\F154"; }
  
  .fad.fa-gbp::after, .fa-duotone.fa-gbp::after {
	content: "\F154\F154"; }
  
  .fad.fa-pound-sign::after, .fa-duotone.fa-pound-sign::after {
	content: "\F154\F154"; }
  
  .fad.fa-battery-exclamation::after, .fa-duotone.fa-battery-exclamation::after {
	content: "\E0B0\E0B0"; }
  
  .fad.fa-viruses::after, .fa-duotone.fa-viruses::after {
	content: "\E076\E076"; }
  
  .fad.fa-square-person-confined::after, .fa-duotone.fa-square-person-confined::after {
	content: "\E577\E577"; }
  
  .fad.fa-user-tie::after, .fa-duotone.fa-user-tie::after {
	content: "\F508\F508"; }
  
  .fad.fa-arrow-down-long::after, .fa-duotone.fa-arrow-down-long::after {
	content: "\F175\F175"; }
  
  .fad.fa-long-arrow-down::after, .fa-duotone.fa-long-arrow-down::after {
	content: "\F175\F175"; }
  
  .fad.fa-tent-arrow-down-to-line::after, .fa-duotone.fa-tent-arrow-down-to-line::after {
	content: "\E57E\E57E"; }
  
  .fad.fa-certificate::after, .fa-duotone.fa-certificate::after {
	content: "\F0A3\F0A3"; }
  
  .fad.fa-crystal-ball::after, .fa-duotone.fa-crystal-ball::after {
	content: "\E362\E362"; }
  
  .fad.fa-reply-all::after, .fa-duotone.fa-reply-all::after {
	content: "\F122\F122"; }
  
  .fad.fa-mail-reply-all::after, .fa-duotone.fa-mail-reply-all::after {
	content: "\F122\F122"; }
  
  .fad.fa-suitcase::after, .fa-duotone.fa-suitcase::after {
	content: "\F0F2\F0F2"; }
  
  .fad.fa-person-skating::after, .fa-duotone.fa-person-skating::after {
	content: "\F7C5\F7C5"; }
  
  .fad.fa-skating::after, .fa-duotone.fa-skating::after {
	content: "\F7C5\F7C5"; }
  
  .fad.fa-star-shooting::after, .fa-duotone.fa-star-shooting::after {
	content: "\E036\E036"; }
  
  .fad.fa-binary-lock::after, .fa-duotone.fa-binary-lock::after {
	content: "\E33D\E33D"; }
  
  .fad.fa-filter-circle-dollar::after, .fa-duotone.fa-filter-circle-dollar::after {
	content: "\F662\F662"; }
  
  .fad.fa-funnel-dollar::after, .fa-duotone.fa-funnel-dollar::after {
	content: "\F662\F662"; }
  
  .fad.fa-camera-retro::after, .fa-duotone.fa-camera-retro::after {
	content: "\F083\F083"; }
  
  .fad.fa-circle-arrow-down::after, .fa-duotone.fa-circle-arrow-down::after {
	content: "\F0AB\F0AB"; }
  
  .fad.fa-arrow-circle-down::after, .fa-duotone.fa-arrow-circle-down::after {
	content: "\F0AB\F0AB"; }
  
  .fad.fa-comment-pen::after, .fa-duotone.fa-comment-pen::after {
	content: "\F4AE\F4AE"; }
  
  .fad.fa-comment-edit::after, .fa-duotone.fa-comment-edit::after {
	content: "\F4AE\F4AE"; }
  
  .fad.fa-file-import::after, .fa-duotone.fa-file-import::after {
	content: "\F56F\F56F"; }
  
  .fad.fa-arrow-right-to-file::after, .fa-duotone.fa-arrow-right-to-file::after {
	content: "\F56F\F56F"; }
  
  .fad.fa-banjo::after, .fa-duotone.fa-banjo::after {
	content: "\F8A3\F8A3"; }
  
  .fad.fa-square-arrow-up-right::after, .fa-duotone.fa-square-arrow-up-right::after {
	content: "\F14C\F14C"; }
  
  .fad.fa-external-link-square::after, .fa-duotone.fa-external-link-square::after {
	content: "\F14C\F14C"; }
  
  .fad.fa-light-emergency-on::after, .fa-duotone.fa-light-emergency-on::after {
	content: "\E420\E420"; }
  
  .fad.fa-kerning::after, .fa-duotone.fa-kerning::after {
	content: "\F86F\F86F"; }
  
  .fad.fa-box-open::after, .fa-duotone.fa-box-open::after {
	content: "\F49E\F49E"; }
  
  .fad.fa-square-f::after, .fa-duotone.fa-square-f::after {
	content: "\E270\E270"; }
  
  .fad.fa-scroll::after, .fa-duotone.fa-scroll::after {
	content: "\F70E\F70E"; }
  
  .fad.fa-spa::after, .fa-duotone.fa-spa::after {
	content: "\F5BB\F5BB"; }
  
  .fad.fa-arrow-left-from-line::after, .fa-duotone.fa-arrow-left-from-line::after {
	content: "\F344\F344"; }
  
  .fad.fa-arrow-from-right::after, .fa-duotone.fa-arrow-from-right::after {
	content: "\F344\F344"; }
  
  .fad.fa-strawberry::after, .fa-duotone.fa-strawberry::after {
	content: "\E32B\E32B"; }
  
  .fad.fa-location-pin-lock::after, .fa-duotone.fa-location-pin-lock::after {
	content: "\E51F\E51F"; }
  
  .fad.fa-pause::after, .fa-duotone.fa-pause::after {
	content: "\F04C\F04C"; }
  
  .fad.fa-clock-eight-thirty::after, .fa-duotone.fa-clock-eight-thirty::after {
	content: "\E346\E346"; }
  
  .fad.fa-plane-engines::after, .fa-duotone.fa-plane-engines::after {
	content: "\F3DE\F3DE"; }
  
  .fad.fa-plane-alt::after, .fa-duotone.fa-plane-alt::after {
	content: "\F3DE\F3DE"; }
  
  .fad.fa-hill-avalanche::after, .fa-duotone.fa-hill-avalanche::after {
	content: "\E507\E507"; }
  
  .fad.fa-temperature-empty::after, .fa-duotone.fa-temperature-empty::after {
	content: "\F2CB\F2CB"; }
  
  .fad.fa-temperature-0::after, .fa-duotone.fa-temperature-0::after {
	content: "\F2CB\F2CB"; }
  
  .fad.fa-thermometer-0::after, .fa-duotone.fa-thermometer-0::after {
	content: "\F2CB\F2CB"; }
  
  .fad.fa-thermometer-empty::after, .fa-duotone.fa-thermometer-empty::after {
	content: "\F2CB\F2CB"; }
  
  .fad.fa-bomb::after, .fa-duotone.fa-bomb::after {
	content: "\F1E2\F1E2"; }
  
  .fad.fa-gauge-low::after, .fa-duotone.fa-gauge-low::after {
	content: "\F627\F627"; }
  
  .fad.fa-tachometer-alt-slow::after, .fa-duotone.fa-tachometer-alt-slow::after {
	content: "\F627\F627"; }
  
  .fad.fa-registered::after, .fa-duotone.fa-registered::after {
	content: "\F25D\F25D"; }
  
  .fad.fa-trash-can-plus::after, .fa-duotone.fa-trash-can-plus::after {
	content: "\E2AC\E2AC"; }
  
  .fad.fa-address-card::after, .fa-duotone.fa-address-card::after {
	content: "\F2BB\F2BB"; }
  
  .fad.fa-contact-card::after, .fa-duotone.fa-contact-card::after {
	content: "\F2BB\F2BB"; }
  
  .fad.fa-vcard::after, .fa-duotone.fa-vcard::after {
	content: "\F2BB\F2BB"; }
  
  .fad.fa-scale-unbalanced-flip::after, .fa-duotone.fa-scale-unbalanced-flip::after {
	content: "\F516\F516"; }
  
  .fad.fa-balance-scale-right::after, .fa-duotone.fa-balance-scale-right::after {
	content: "\F516\F516"; }
  
  .fad.fa-globe-snow::after, .fa-duotone.fa-globe-snow::after {
	content: "\F7A3\F7A3"; }
  
  .fad.fa-subscript::after, .fa-duotone.fa-subscript::after {
	content: "\F12C\F12C"; }
  
  .fad.fa-diamond-turn-right::after, .fa-duotone.fa-diamond-turn-right::after {
	content: "\F5EB\F5EB"; }
  
  .fad.fa-directions::after, .fa-duotone.fa-directions::after {
	content: "\F5EB\F5EB"; }
  
  .fad.fa-integral::after, .fa-duotone.fa-integral::after {
	content: "\F667\F667"; }
  
  .fad.fa-burst::after, .fa-duotone.fa-burst::after {
	content: "\E4DC\E4DC"; }
  
  .fad.fa-house-laptop::after, .fa-duotone.fa-house-laptop::after {
	content: "\E066\E066"; }
  
  .fad.fa-laptop-house::after, .fa-duotone.fa-laptop-house::after {
	content: "\E066\E066"; }
  
  .fad.fa-face-tired::after, .fa-duotone.fa-face-tired::after {
	content: "\F5C8\F5C8"; }
  
  .fad.fa-tired::after, .fa-duotone.fa-tired::after {
	content: "\F5C8\F5C8"; }
  
  .fad.fa-money-bills::after, .fa-duotone.fa-money-bills::after {
	content: "\E1F3\E1F3"; }
  
  .fad.fa-blinds-raised::after, .fa-duotone.fa-blinds-raised::after {
	content: "\F8FD\F8FD"; }
  
  .fad.fa-smog::after, .fa-duotone.fa-smog::after {
	content: "\F75F\F75F"; }
  
  .fad.fa-ufo-beam::after, .fa-duotone.fa-ufo-beam::after {
	content: "\E048\E048"; }
  
  .fad.fa-circle-caret-up::after, .fa-duotone.fa-circle-caret-up::after {
	content: "\F331\F331"; }
  
  .fad.fa-caret-circle-up::after, .fa-duotone.fa-caret-circle-up::after {
	content: "\F331\F331"; }
  
  .fad.fa-user-vneck-hair-long::after, .fa-duotone.fa-user-vneck-hair-long::after {
	content: "\E463\E463"; }
  
  .fad.fa-square-a-lock::after, .fa-duotone.fa-square-a-lock::after {
	content: "\E44D\E44D"; }
  
  .fad.fa-crutch::after, .fa-duotone.fa-crutch::after {
	content: "\F7F7\F7F7"; }
  
  .fad.fa-gas-pump-slash::after, .fa-duotone.fa-gas-pump-slash::after {
	content: "\F5F4\F5F4"; }
  
  .fad.fa-cloud-arrow-up::after, .fa-duotone.fa-cloud-arrow-up::after {
	content: "\F0EE\F0EE"; }
  
  .fad.fa-cloud-upload::after, .fa-duotone.fa-cloud-upload::after {
	content: "\F0EE\F0EE"; }
  
  .fad.fa-cloud-upload-alt::after, .fa-duotone.fa-cloud-upload-alt::after {
	content: "\F0EE\F0EE"; }
  
  .fad.fa-palette::after, .fa-duotone.fa-palette::after {
	content: "\F53F\F53F"; }
  
  .fad.fa-transporter-4::after, .fa-duotone.fa-transporter-4::after {
	content: "\E2A5\E2A5"; }
  
  .fad.fa-objects-align-right::after, .fa-duotone.fa-objects-align-right::after {
	content: "\E3BF\E3BF"; }
  
  .fad.fa-arrows-turn-right::after, .fa-duotone.fa-arrows-turn-right::after {
	content: "\E4C0\E4C0"; }
  
  .fad.fa-vest::after, .fa-duotone.fa-vest::after {
	content: "\E085\E085"; }
  
  .fad.fa-pig::after, .fa-duotone.fa-pig::after {
	content: "\F706\F706"; }
  
  .fad.fa-inbox-full::after, .fa-duotone.fa-inbox-full::after {
	content: "\E1BA\E1BA"; }
  
  .fad.fa-circle-envelope::after, .fa-duotone.fa-circle-envelope::after {
	content: "\E10C\E10C"; }
  
  .fad.fa-envelope-circle::after, .fa-duotone.fa-envelope-circle::after {
	content: "\E10C\E10C"; }
  
  .fad.fa-triangle-person-digging::after, .fa-duotone.fa-triangle-person-digging::after {
	content: "\F85D\F85D"; }
  
  .fad.fa-construction::after, .fa-duotone.fa-construction::after {
	content: "\F85D\F85D"; }
  
  .fad.fa-ferry::after, .fa-duotone.fa-ferry::after {
	content: "\E4EA\E4EA"; }
  
  .fad.fa-bullseye-arrow::after, .fa-duotone.fa-bullseye-arrow::after {
	content: "\F648\F648"; }
  
  .fad.fa-arrows-down-to-people::after, .fa-duotone.fa-arrows-down-to-people::after {
	content: "\E4B9\E4B9"; }
  
  .fad.fa-seedling::after, .fa-duotone.fa-seedling::after {
	content: "\F4D8\F4D8"; }
  
  .fad.fa-sprout::after, .fa-duotone.fa-sprout::after {
	content: "\F4D8\F4D8"; }
  
  .fad.fa-clock-seven::after, .fa-duotone.fa-clock-seven::after {
	content: "\E350\E350"; }
  
  .fad.fa-left-right::after, .fa-duotone.fa-left-right::after {
	content: "\F337\F337"; }
  
  .fad.fa-arrows-alt-h::after, .fa-duotone.fa-arrows-alt-h::after {
	content: "\F337\F337"; }
  
  .fad.fa-boxes-packing::after, .fa-duotone.fa-boxes-packing::after {
	content: "\E4C7\E4C7"; }
  
  .fad.fa-circle-arrow-left::after, .fa-duotone.fa-circle-arrow-left::after {
	content: "\F0A8\F0A8"; }
  
  .fad.fa-arrow-circle-left::after, .fa-duotone.fa-arrow-circle-left::after {
	content: "\F0A8\F0A8"; }
  
  .fad.fa-flashlight::after, .fa-duotone.fa-flashlight::after {
	content: "\F8B8\F8B8"; }
  
  .fad.fa-group-arrows-rotate::after, .fa-duotone.fa-group-arrows-rotate::after {
	content: "\E4F6\E4F6"; }
  
  .fad.fa-bowl-food::after, .fa-duotone.fa-bowl-food::after {
	content: "\E4C6\E4C6"; }
  
  .fad.fa-square-9::after, .fa-duotone.fa-square-9::after {
	content: "\E25E\E25E"; }
  
  .fad.fa-candy-cane::after, .fa-duotone.fa-candy-cane::after {
	content: "\F786\F786"; }
  
  .fad.fa-arrow-down-wide-short::after, .fa-duotone.fa-arrow-down-wide-short::after {
	content: "\F160\F160"; }
  
  .fad.fa-sort-amount-asc::after, .fa-duotone.fa-sort-amount-asc::after {
	content: "\F160\F160"; }
  
  .fad.fa-sort-amount-down::after, .fa-duotone.fa-sort-amount-down::after {
	content: "\F160\F160"; }
  
  .fad.fa-square-dollar::after, .fa-duotone.fa-square-dollar::after {
	content: "\F2E9\F2E9"; }
  
  .fad.fa-dollar-square::after, .fa-duotone.fa-dollar-square::after {
	content: "\F2E9\F2E9"; }
  
  .fad.fa-usd-square::after, .fa-duotone.fa-usd-square::after {
	content: "\F2E9\F2E9"; }
  
  .fad.fa-hand-holding-seedling::after, .fa-duotone.fa-hand-holding-seedling::after {
	content: "\F4BF\F4BF"; }
  
  .fad.fa-message-check::after, .fa-duotone.fa-message-check::after {
	content: "\F4A2\F4A2"; }
  
  .fad.fa-comment-alt-check::after, .fa-duotone.fa-comment-alt-check::after {
	content: "\F4A2\F4A2"; }
  
  .fad.fa-cloud-bolt::after, .fa-duotone.fa-cloud-bolt::after {
	content: "\F76C\F76C"; }
  
  .fad.fa-thunderstorm::after, .fa-duotone.fa-thunderstorm::after {
	content: "\F76C\F76C"; }
  
  .fad.fa-text-slash::after, .fa-duotone.fa-text-slash::after {
	content: "\F87D\F87D"; }
  
  .fad.fa-remove-format::after, .fa-duotone.fa-remove-format::after {
	content: "\F87D\F87D"; }
  
  .fad.fa-watch::after, .fa-duotone.fa-watch::after {
	content: "\F2E1\F2E1"; }
  
  .fad.fa-circle-down-left::after, .fa-duotone.fa-circle-down-left::after {
	content: "\E107\E107"; }
  
  .fad.fa-text::after, .fa-duotone.fa-text::after {
	content: "\F893\F893"; }
  
  .fad.fa-projector::after, .fa-duotone.fa-projector::after {
	content: "\F8D6\F8D6"; }
  
  .fad.fa-face-smile-wink::after, .fa-duotone.fa-face-smile-wink::after {
	content: "\F4DA\F4DA"; }
  
  .fad.fa-smile-wink::after, .fa-duotone.fa-smile-wink::after {
	content: "\F4DA\F4DA"; }
  
  .fad.fa-tombstone-blank::after, .fa-duotone.fa-tombstone-blank::after {
	content: "\F721\F721"; }
  
  .fad.fa-tombstone-alt::after, .fa-duotone.fa-tombstone-alt::after {
	content: "\F721\F721"; }
  
  .fad.fa-chess-king-piece::after, .fa-duotone.fa-chess-king-piece::after {
	content: "\F440\F440"; }
  
  .fad.fa-chess-king-alt::after, .fa-duotone.fa-chess-king-alt::after {
	content: "\F440\F440"; }
  
  .fad.fa-circle-6::after, .fa-duotone.fa-circle-6::after {
	content: "\E0F3\E0F3"; }
  
  .fad.fa-left::after, .fa-duotone.fa-left::after {
	content: "\F355\F355"; }
  
  .fad.fa-arrow-alt-left::after, .fa-duotone.fa-arrow-alt-left::after {
	content: "\F355\F355"; }
  
  .fad.fa-file-word::after, .fa-duotone.fa-file-word::after {
	content: "\F1C2\F1C2"; }
  
  .fad.fa-file-powerpoint::after, .fa-duotone.fa-file-powerpoint::after {
	content: "\F1C4\F1C4"; }
  
  .fad.fa-square-down::after, .fa-duotone.fa-square-down::after {
	content: "\F350\F350"; }
  
  .fad.fa-arrow-alt-square-down::after, .fa-duotone.fa-arrow-alt-square-down::after {
	content: "\F350\F350"; }
  
  .fad.fa-objects-align-center-vertical::after, .fa-duotone.fa-objects-align-center-vertical::after {
	content: "\E3BD\E3BD"; }
  
  .fad.fa-arrows-left-right::after, .fa-duotone.fa-arrows-left-right::after {
	content: "\F07E\F07E"; }
  
  .fad.fa-arrows-h::after, .fa-duotone.fa-arrows-h::after {
	content: "\F07E\F07E"; }
  
  .fad.fa-house-lock::after, .fa-duotone.fa-house-lock::after {
	content: "\E510\E510"; }
  
  .fad.fa-cloud-arrow-down::after, .fa-duotone.fa-cloud-arrow-down::after {
	content: "\F0ED\F0ED"; }
  
  .fad.fa-cloud-download::after, .fa-duotone.fa-cloud-download::after {
	content: "\F0ED\F0ED"; }
  
  .fad.fa-cloud-download-alt::after, .fa-duotone.fa-cloud-download-alt::after {
	content: "\F0ED\F0ED"; }
  
  .fad.fa-wreath::after, .fa-duotone.fa-wreath::after {
	content: "\F7E2\F7E2"; }
  
  .fad.fa-children::after, .fa-duotone.fa-children::after {
	content: "\E4E1\E4E1"; }
  
  .fad.fa-meter-droplet::after, .fa-duotone.fa-meter-droplet::after {
	content: "\E1EA\E1EA"; }
  
  .fad.fa-chalkboard::after, .fa-duotone.fa-chalkboard::after {
	content: "\F51B\F51B"; }
  
  .fad.fa-blackboard::after, .fa-duotone.fa-blackboard::after {
	content: "\F51B\F51B"; }
  
  .fad.fa-user-large-slash::after, .fa-duotone.fa-user-large-slash::after {
	content: "\F4FA\F4FA"; }
  
  .fad.fa-user-alt-slash::after, .fa-duotone.fa-user-alt-slash::after {
	content: "\F4FA\F4FA"; }
  
  .fad.fa-signal-strong::after, .fa-duotone.fa-signal-strong::after {
	content: "\F68F\F68F"; }
  
  .fad.fa-signal-4::after, .fa-duotone.fa-signal-4::after {
	content: "\F68F\F68F"; }
  
  .fad.fa-lollipop::after, .fa-duotone.fa-lollipop::after {
	content: "\E424\E424"; }
  
  .fad.fa-lollypop::after, .fa-duotone.fa-lollypop::after {
	content: "\E424\E424"; }
  
  .fad.fa-list-tree::after, .fa-duotone.fa-list-tree::after {
	content: "\E1D2\E1D2"; }
  
  .fad.fa-envelope-open::after, .fa-duotone.fa-envelope-open::after {
	content: "\F2B6\F2B6"; }
  
  .fad.fa-draw-circle::after, .fa-duotone.fa-draw-circle::after {
	content: "\F5ED\F5ED"; }
  
  .fad.fa-cat-space::after, .fa-duotone.fa-cat-space::after {
	content: "\E001\E001"; }
  
  .fad.fa-handshake-simple-slash::after, .fa-duotone.fa-handshake-simple-slash::after {
	content: "\E05F\E05F"; }
  
  .fad.fa-handshake-alt-slash::after, .fa-duotone.fa-handshake-alt-slash::after {
	content: "\E05F\E05F"; }
  
  .fad.fa-rabbit-running::after, .fa-duotone.fa-rabbit-running::after {
	content: "\F709\F709"; }
  
  .fad.fa-rabbit-fast::after, .fa-duotone.fa-rabbit-fast::after {
	content: "\F709\F709"; }
  
  .fad.fa-memo-pad::after, .fa-duotone.fa-memo-pad::after {
	content: "\E1DA\E1DA"; }
  
  .fad.fa-mattress-pillow::after, .fa-duotone.fa-mattress-pillow::after {
	content: "\E525\E525"; }
  
  .fad.fa-alarm-plus::after, .fa-duotone.fa-alarm-plus::after {
	content: "\F844\F844"; }
  
  .fad.fa-alicorn::after, .fa-duotone.fa-alicorn::after {
	content: "\F6B0\F6B0"; }
  
  .fad.fa-comment-question::after, .fa-duotone.fa-comment-question::after {
	content: "\E14B\E14B"; }
  
  .fad.fa-gingerbread-man::after, .fa-duotone.fa-gingerbread-man::after {
	content: "\F79D\F79D"; }
  
  .fad.fa-guarani-sign::after, .fa-duotone.fa-guarani-sign::after {
	content: "\E19A\E19A"; }
  
  .fad.fa-burger-fries::after, .fa-duotone.fa-burger-fries::after {
	content: "\E0CD\E0CD"; }
  
  .fad.fa-mug-tea::after, .fa-duotone.fa-mug-tea::after {
	content: "\F875\F875"; }
  
  .fad.fa-border-top::after, .fa-duotone.fa-border-top::after {
	content: "\F855\F855"; }
  
  .fad.fa-arrows-rotate::after, .fa-duotone.fa-arrows-rotate::after {
	content: "\F021\F021"; }
  
  .fad.fa-refresh::after, .fa-duotone.fa-refresh::after {
	content: "\F021\F021"; }
  
  .fad.fa-sync::after, .fa-duotone.fa-sync::after {
	content: "\F021\F021"; }
  
  .fad.fa-circle-book-open::after, .fa-duotone.fa-circle-book-open::after {
	content: "\E0FF\E0FF"; }
  
  .fad.fa-book-circle::after, .fa-duotone.fa-book-circle::after {
	content: "\E0FF\E0FF"; }
  
  .fad.fa-arrows-to-dotted-line::after, .fa-duotone.fa-arrows-to-dotted-line::after {
	content: "\E0A6\E0A6"; }
  
  .fad.fa-fire-extinguisher::after, .fa-duotone.fa-fire-extinguisher::after {
	content: "\F134\F134"; }
  
  .fad.fa-garage-open::after, .fa-duotone.fa-garage-open::after {
	content: "\E00B\E00B"; }
  
  .fad.fa-shelves-empty::after, .fa-duotone.fa-shelves-empty::after {
	content: "\E246\E246"; }
  
  .fad.fa-cruzeiro-sign::after, .fa-duotone.fa-cruzeiro-sign::after {
	content: "\E152\E152"; }
  
  .fad.fa-watch-apple::after, .fa-duotone.fa-watch-apple::after {
	content: "\E2CB\E2CB"; }
  
  .fad.fa-watch-calculator::after, .fa-duotone.fa-watch-calculator::after {
	content: "\F8F0\F8F0"; }
  
  .fad.fa-list-dropdown::after, .fa-duotone.fa-list-dropdown::after {
	content: "\E1CF\E1CF"; }
  
  .fad.fa-cabinet-filing::after, .fa-duotone.fa-cabinet-filing::after {
	content: "\F64B\F64B"; }
  
  .fad.fa-burger-soda::after, .fa-duotone.fa-burger-soda::after {
	content: "\F858\F858"; }
  
  .fad.fa-square-arrow-up::after, .fa-duotone.fa-square-arrow-up::after {
	content: "\F33C\F33C"; }
  
  .fad.fa-arrow-square-up::after, .fa-duotone.fa-arrow-square-up::after {
	content: "\F33C\F33C"; }
  
  .fad.fa-greater-than-equal::after, .fa-duotone.fa-greater-than-equal::after {
	content: "\F532\F532"; }
  
  .fad.fa-pallet-box::after, .fa-duotone.fa-pallet-box::after {
	content: "\E208\E208"; }
  
  .fad.fa-face-confounded::after, .fa-duotone.fa-face-confounded::after {
	content: "\E36C\E36C"; }
  
  .fad.fa-shield-halved::after, .fa-duotone.fa-shield-halved::after {
	content: "\F3ED\F3ED"; }
  
  .fad.fa-shield-alt::after, .fa-duotone.fa-shield-alt::after {
	content: "\F3ED\F3ED"; }
  
  .fad.fa-truck-plow::after, .fa-duotone.fa-truck-plow::after {
	content: "\F7DE\F7DE"; }
  
  .fad.fa-book-atlas::after, .fa-duotone.fa-book-atlas::after {
	content: "\F558\F558"; }
  
  .fad.fa-atlas::after, .fa-duotone.fa-atlas::after {
	content: "\F558\F558"; }
  
  .fad.fa-virus::after, .fa-duotone.fa-virus::after {
	content: "\E074\E074"; }
  
  .fad.fa-comment-middle-top::after, .fa-duotone.fa-comment-middle-top::after {
	content: "\E14A\E14A"; }
  
  .fad.fa-envelope-circle-check::after, .fa-duotone.fa-envelope-circle-check::after {
	content: "\E4E8\E4E8"; }
  
  .fad.fa-layer-group::after, .fa-duotone.fa-layer-group::after {
	content: "\F5FD\F5FD"; }
  
  .fad.fa-restroom-simple::after, .fa-duotone.fa-restroom-simple::after {
	content: "\E23A\E23A"; }
  
  .fad.fa-arrows-to-dot::after, .fa-duotone.fa-arrows-to-dot::after {
	content: "\E4BE\E4BE"; }
  
  .fad.fa-border-outer::after, .fa-duotone.fa-border-outer::after {
	content: "\F851\F851"; }
  
  .fad.fa-hashtag-lock::after, .fa-duotone.fa-hashtag-lock::after {
	content: "\E415\E415"; }
  
  .fad.fa-clock-two-thirty::after, .fa-duotone.fa-clock-two-thirty::after {
	content: "\E35B\E35B"; }
  
  .fad.fa-archway::after, .fa-duotone.fa-archway::after {
	content: "\F557\F557"; }
  
  .fad.fa-heart-circle-check::after, .fa-duotone.fa-heart-circle-check::after {
	content: "\E4FD\E4FD"; }
  
  .fad.fa-house-chimney-crack::after, .fa-duotone.fa-house-chimney-crack::after {
	content: "\F6F1\F6F1"; }
  
  .fad.fa-house-damage::after, .fa-duotone.fa-house-damage::after {
	content: "\F6F1\F6F1"; }
  
  .fad.fa-file-zipper::after, .fa-duotone.fa-file-zipper::after {
	content: "\F1C6\F1C6"; }
  
  .fad.fa-file-archive::after, .fa-duotone.fa-file-archive::after {
	content: "\F1C6\F1C6"; }
  
  .fad.fa-heart-half::after, .fa-duotone.fa-heart-half::after {
	content: "\E1AB\E1AB"; }
  
  .fad.fa-comment-check::after, .fa-duotone.fa-comment-check::after {
	content: "\F4AC\F4AC"; }
  
  .fad.fa-square::after, .fa-duotone.fa-square::after {
	content: "\F0C8\F0C8"; }
  
  .fad.fa-memo::after, .fa-duotone.fa-memo::after {
	content: "\E1D8\E1D8"; }
  
  .fad.fa-martini-glass-empty::after, .fa-duotone.fa-martini-glass-empty::after {
	content: "\F000\F000"; }
  
  .fad.fa-glass-martini::after, .fa-duotone.fa-glass-martini::after {
	content: "\F000\F000"; }
  
  .fad.fa-couch::after, .fa-duotone.fa-couch::after {
	content: "\F4B8\F4B8"; }
  
  .fad.fa-cedi-sign::after, .fa-duotone.fa-cedi-sign::after {
	content: "\E0DF\E0DF"; }
  
  .fad.fa-italic::after, .fa-duotone.fa-italic::after {
	content: "\F033\F033"; }
  
  .fad.fa-glass-citrus::after, .fa-duotone.fa-glass-citrus::after {
	content: "\F869\F869"; }
  
  .fad.fa-calendar-lines-pen::after, .fa-duotone.fa-calendar-lines-pen::after {
	content: "\E472\E472"; }
  
  .fad.fa-church::after, .fa-duotone.fa-church::after {
	content: "\F51D\F51D"; }
  
  .fad.fa-person-snowmobiling::after, .fa-duotone.fa-person-snowmobiling::after {
	content: "\F7D1\F7D1"; }
  
  .fad.fa-snowmobile::after, .fa-duotone.fa-snowmobile::after {
	content: "\F7D1\F7D1"; }
  
  .fad.fa-face-hushed::after, .fa-duotone.fa-face-hushed::after {
	content: "\E37B\E37B"; }
  
  .fad.fa-comments-dollar::after, .fa-duotone.fa-comments-dollar::after {
	content: "\F653\F653"; }
  
  .fad.fa-link-simple-slash::after, .fa-duotone.fa-link-simple-slash::after {
	content: "\E1CE\E1CE"; }
  
  .fad.fa-democrat::after, .fa-duotone.fa-democrat::after {
	content: "\F747\F747"; }
  
  .fad.fa-face-confused::after, .fa-duotone.fa-face-confused::after {
	content: "\E36D\E36D"; }
  
  .fad.fa-pinball::after, .fa-duotone.fa-pinball::after {
	content: "\E229\E229"; }
  
  .fad.fa-z::after, .fa-duotone.fa-z::after {
	content: "ZZ"; }
  
  .fad.fa-person-skiing::after, .fa-duotone.fa-person-skiing::after {
	content: "\F7C9\F7C9"; }
  
  .fad.fa-skiing::after, .fa-duotone.fa-skiing::after {
	content: "\F7C9\F7C9"; }
  
  .fad.fa-deer::after, .fa-duotone.fa-deer::after {
	content: "\F78E\F78E"; }
  
  .fad.fa-input-pipe::after, .fa-duotone.fa-input-pipe::after {
	content: "\E1BE\E1BE"; }
  
  .fad.fa-road-lock::after, .fa-duotone.fa-road-lock::after {
	content: "\E567\E567"; }
  
  .fad.fa-a::after, .fa-duotone.fa-a::after {
	content: "AA"; }
  
  .fad.fa-bookmark-slash::after, .fa-duotone.fa-bookmark-slash::after {
	content: "\E0C2\E0C2"; }
  
  .fad.fa-temperature-arrow-down::after, .fa-duotone.fa-temperature-arrow-down::after {
	content: "\E03F\E03F"; }
  
  .fad.fa-temperature-down::after, .fa-duotone.fa-temperature-down::after {
	content: "\E03F\E03F"; }
  
  .fad.fa-mace::after, .fa-duotone.fa-mace::after {
	content: "\F6F8\F6F8"; }
  
  .fad.fa-feather-pointed::after, .fa-duotone.fa-feather-pointed::after {
	content: "\F56B\F56B"; }
  
  .fad.fa-feather-alt::after, .fa-duotone.fa-feather-alt::after {
	content: "\F56B\F56B"; }
  
  .fad.fa-sausage::after, .fa-duotone.fa-sausage::after {
	content: "\F820\F820"; }
  
  .fad.fa-trash-can-clock::after, .fa-duotone.fa-trash-can-clock::after {
	content: "\E2AA\E2AA"; }
  
  .fad.fa-p::after, .fa-duotone.fa-p::after {
	content: "PP"; }
  
  .fad.fa-snowflake::after, .fa-duotone.fa-snowflake::after {
	content: "\F2DC\F2DC"; }
  
  .fad.fa-stomach::after, .fa-duotone.fa-stomach::after {
	content: "\F623\F623"; }
  
  .fad.fa-newspaper::after, .fa-duotone.fa-newspaper::after {
	content: "\F1EA\F1EA"; }
  
  .fad.fa-rectangle-ad::after, .fa-duotone.fa-rectangle-ad::after {
	content: "\F641\F641"; }
  
  .fad.fa-ad::after, .fa-duotone.fa-ad::after {
	content: "\F641\F641"; }
  
  .fad.fa-guitar-electric::after, .fa-duotone.fa-guitar-electric::after {
	content: "\F8BE\F8BE"; }
  
  .fad.fa-arrow-turn-down-right::after, .fa-duotone.fa-arrow-turn-down-right::after {
	content: "\E3D6\E3D6"; }
  
  .fad.fa-moon-cloud::after, .fa-duotone.fa-moon-cloud::after {
	content: "\F754\F754"; }
  
  .fad.fa-bread-slice-butter::after, .fa-duotone.fa-bread-slice-butter::after {
	content: "\E3E1\E3E1"; }
  
  .fad.fa-circle-arrow-right::after, .fa-duotone.fa-circle-arrow-right::after {
	content: "\F0A9\F0A9"; }
  
  .fad.fa-arrow-circle-right::after, .fa-duotone.fa-arrow-circle-right::after {
	content: "\F0A9\F0A9"; }
  
  .fad.fa-user-group-crown::after, .fa-duotone.fa-user-group-crown::after {
	content: "\F6A5\F6A5"; }
  
  .fad.fa-users-crown::after, .fa-duotone.fa-users-crown::after {
	content: "\F6A5\F6A5"; }
  
  .fad.fa-circle-i::after, .fa-duotone.fa-circle-i::after {
	content: "\E111\E111"; }
  
  .fad.fa-toilet-paper-check::after, .fa-duotone.fa-toilet-paper-check::after {
	content: "\E5B2\E5B2"; }
  
  .fad.fa-filter-circle-xmark::after, .fa-duotone.fa-filter-circle-xmark::after {
	content: "\E17B\E17B"; }
  
  .fad.fa-locust::after, .fa-duotone.fa-locust::after {
	content: "\E520\E520"; }
  
  .fad.fa-sort::after, .fa-duotone.fa-sort::after {
	content: "\F0DC\F0DC"; }
  
  .fad.fa-unsorted::after, .fa-duotone.fa-unsorted::after {
	content: "\F0DC\F0DC"; }
  
  .fad.fa-list-ol::after, .fa-duotone.fa-list-ol::after {
	content: "\F0CB\F0CB"; }
  
  .fad.fa-list-1-2::after, .fa-duotone.fa-list-1-2::after {
	content: "\F0CB\F0CB"; }
  
  .fad.fa-list-numeric::after, .fa-duotone.fa-list-numeric::after {
	content: "\F0CB\F0CB"; }
  
  .fad.fa-chart-waterfall::after, .fa-duotone.fa-chart-waterfall::after {
	content: "\E0EB\E0EB"; }
  
  .fad.fa-face-party::after, .fa-duotone.fa-face-party::after {
	content: "\E383\E383"; }
  
  .fad.fa-kidneys::after, .fa-duotone.fa-kidneys::after {
	content: "\F5FB\F5FB"; }
  
  .fad.fa-wifi-exclamation::after, .fa-duotone.fa-wifi-exclamation::after {
	content: "\E2CF\E2CF"; }
  
  .fad.fa-chart-network::after, .fa-duotone.fa-chart-network::after {
	content: "\F78A\F78A"; }
  
  .fad.fa-person-dress-burst::after, .fa-duotone.fa-person-dress-burst::after {
	content: "\E544\E544"; }
  
  .fad.fa-dice-d4::after, .fa-duotone.fa-dice-d4::after {
	content: "\F6D0\F6D0"; }
  
  .fad.fa-money-check-dollar::after, .fa-duotone.fa-money-check-dollar::after {
	content: "\F53D\F53D"; }
  
  .fad.fa-money-check-alt::after, .fa-duotone.fa-money-check-alt::after {
	content: "\F53D\F53D"; }
  
  .fad.fa-vector-square::after, .fa-duotone.fa-vector-square::after {
	content: "\F5CB\F5CB"; }
  
  .fad.fa-bread-slice::after, .fa-duotone.fa-bread-slice::after {
	content: "\F7EC\F7EC"; }
  
  .fad.fa-language::after, .fa-duotone.fa-language::after {
	content: "\F1AB\F1AB"; }
  
  .fad.fa-wheat-awn-slash::after, .fa-duotone.fa-wheat-awn-slash::after {
	content: "\E338\E338"; }
  
  .fad.fa-face-kiss-wink-heart::after, .fa-duotone.fa-face-kiss-wink-heart::after {
	content: "\F598\F598"; }
  
  .fad.fa-kiss-wink-heart::after, .fa-duotone.fa-kiss-wink-heart::after {
	content: "\F598\F598"; }
  
  .fad.fa-dagger::after, .fa-duotone.fa-dagger::after {
	content: "\F6CB\F6CB"; }
  
  .fad.fa-podium::after, .fa-duotone.fa-podium::after {
	content: "\F680\F680"; }
  
  .fad.fa-memo-circle-check::after, .fa-duotone.fa-memo-circle-check::after {
	content: "\E1D9\E1D9"; }
  
  .fad.fa-route-highway::after, .fa-duotone.fa-route-highway::after {
	content: "\F61A\F61A"; }
  
  .fad.fa-down-to-line::after, .fa-duotone.fa-down-to-line::after {
	content: "\F34A\F34A"; }
  
  .fad.fa-arrow-alt-to-bottom::after, .fa-duotone.fa-arrow-alt-to-bottom::after {
	content: "\F34A\F34A"; }
  
  .fad.fa-filter::after, .fa-duotone.fa-filter::after {
	content: "\F0B0\F0B0"; }
  
  .fad.fa-square-g::after, .fa-duotone.fa-square-g::after {
	content: "\E271\E271"; }
  
  .fad.fa-circle-phone::after, .fa-duotone.fa-circle-phone::after {
	content: "\E11B\E11B"; }
  
  .fad.fa-phone-circle::after, .fa-duotone.fa-phone-circle::after {
	content: "\E11B\E11B"; }
  
  .fad.fa-clipboard-prescription::after, .fa-duotone.fa-clipboard-prescription::after {
	content: "\F5E8\F5E8"; }
  
  .fad.fa-user-nurse-hair::after, .fa-duotone.fa-user-nurse-hair::after {
	content: "\E45D\E45D"; }
  
  .fad.fa-question::after, .fa-duotone.fa-question::after {
	content: "??"; }
  
  .fad.fa-file-signature::after, .fa-duotone.fa-file-signature::after {
	content: "\F573\F573"; }
  
  .fad.fa-toggle-large-on::after, .fa-duotone.fa-toggle-large-on::after {
	content: "\E5B1\E5B1"; }
  
  .fad.fa-up-down-left-right::after, .fa-duotone.fa-up-down-left-right::after {
	content: "\F0B2\F0B2"; }
  
  .fad.fa-arrows-alt::after, .fa-duotone.fa-arrows-alt::after {
	content: "\F0B2\F0B2"; }
  
  .fad.fa-dryer-heat::after, .fa-duotone.fa-dryer-heat::after {
	content: "\F862\F862"; }
  
  .fad.fa-dryer-alt::after, .fa-duotone.fa-dryer-alt::after {
	content: "\F862\F862"; }
  
  .fad.fa-house-chimney-user::after, .fa-duotone.fa-house-chimney-user::after {
	content: "\E065\E065"; }
  
  .fad.fa-hand-holding-heart::after, .fa-duotone.fa-hand-holding-heart::after {
	content: "\F4BE\F4BE"; }
  
  .fad.fa-arrow-up-small-big::after, .fa-duotone.fa-arrow-up-small-big::after {
	content: "\F88F\F88F"; }
  
  .fad.fa-sort-size-up-alt::after, .fa-duotone.fa-sort-size-up-alt::after {
	content: "\F88F\F88F"; }
  
  .fad.fa-train-track::after, .fa-duotone.fa-train-track::after {
	content: "\E453\E453"; }
  
  .fad.fa-puzzle-piece::after, .fa-duotone.fa-puzzle-piece::after {
	content: "\F12E\F12E"; }
  
  .fad.fa-money-check::after, .fa-duotone.fa-money-check::after {
	content: "\F53C\F53C"; }
  
  .fad.fa-star-half-stroke::after, .fa-duotone.fa-star-half-stroke::after {
	content: "\F5C0\F5C0"; }
  
  .fad.fa-star-half-alt::after, .fa-duotone.fa-star-half-alt::after {
	content: "\F5C0\F5C0"; }
  
  .fad.fa-file-exclamation::after, .fa-duotone.fa-file-exclamation::after {
	content: "\F31A\F31A"; }
  
  .fad.fa-code::after, .fa-duotone.fa-code::after {
	content: "\F121\F121"; }
  
  .fad.fa-whiskey-glass::after, .fa-duotone.fa-whiskey-glass::after {
	content: "\F7A0\F7A0"; }
  
  .fad.fa-glass-whiskey::after, .fa-duotone.fa-glass-whiskey::after {
	content: "\F7A0\F7A0"; }
  
  .fad.fa-moon-stars::after, .fa-duotone.fa-moon-stars::after {
	content: "\F755\F755"; }
  
  .fad.fa-building-circle-exclamation::after, .fa-duotone.fa-building-circle-exclamation::after {
	content: "\E4D3\E4D3"; }
  
  .fad.fa-clothes-hanger::after, .fa-duotone.fa-clothes-hanger::after {
	content: "\E136\E136"; }
  
  .fad.fa-mobile-notch::after, .fa-duotone.fa-mobile-notch::after {
	content: "\E1EE\E1EE"; }
  
  .fad.fa-mobile-iphone::after, .fa-duotone.fa-mobile-iphone::after {
	content: "\E1EE\E1EE"; }
  
  .fad.fa-magnifying-glass-chart::after, .fa-duotone.fa-magnifying-glass-chart::after {
	content: "\E522\E522"; }
  
  .fad.fa-arrow-up-right-from-square::after, .fa-duotone.fa-arrow-up-right-from-square::after {
	content: "\F08E\F08E"; }
  
  .fad.fa-external-link::after, .fa-duotone.fa-external-link::after {
	content: "\F08E\F08E"; }
  
  .fad.fa-cubes-stacked::after, .fa-duotone.fa-cubes-stacked::after {
	content: "\E4E6\E4E6"; }
  
  .fad.fa-images-user::after, .fa-duotone.fa-images-user::after {
	content: "\E1B9\E1B9"; }
  
  .fad.fa-won-sign::after, .fa-duotone.fa-won-sign::after {
	content: "\F159\F159"; }
  
  .fad.fa-krw::after, .fa-duotone.fa-krw::after {
	content: "\F159\F159"; }
  
  .fad.fa-won::after, .fa-duotone.fa-won::after {
	content: "\F159\F159"; }
  
  .fad.fa-image-polaroid-user::after, .fa-duotone.fa-image-polaroid-user::after {
	content: "\E1B6\E1B6"; }
  
  .fad.fa-virus-covid::after, .fa-duotone.fa-virus-covid::after {
	content: "\E4A8\E4A8"; }
  
  .fad.fa-square-ellipsis::after, .fa-duotone.fa-square-ellipsis::after {
	content: "\E26E\E26E"; }
  
  .fad.fa-pie::after, .fa-duotone.fa-pie::after {
	content: "\F705\F705"; }
  
  .fad.fa-chess-knight-piece::after, .fa-duotone.fa-chess-knight-piece::after {
	content: "\F442\F442"; }
  
  .fad.fa-chess-knight-alt::after, .fa-duotone.fa-chess-knight-alt::after {
	content: "\F442\F442"; }
  
  .fad.fa-austral-sign::after, .fa-duotone.fa-austral-sign::after {
	content: "\E0A9\E0A9"; }
  
  .fad.fa-cloud-plus::after, .fa-duotone.fa-cloud-plus::after {
	content: "\E35E\E35E"; }
  
  .fad.fa-f::after, .fa-duotone.fa-f::after {
	content: "FF"; }
  
  .fad.fa-leaf::after, .fa-duotone.fa-leaf::after {
	content: "\F06C\F06C"; }
  
  .fad.fa-bed-bunk::after, .fa-duotone.fa-bed-bunk::after {
	content: "\F8F8\F8F8"; }
  
  .fad.fa-road::after, .fa-duotone.fa-road::after {
	content: "\F018\F018"; }
  
  .fad.fa-taxi::after, .fa-duotone.fa-taxi::after {
	content: "\F1BA\F1BA"; }
  
  .fad.fa-cab::after, .fa-duotone.fa-cab::after {
	content: "\F1BA\F1BA"; }
  
  .fad.fa-person-circle-plus::after, .fa-duotone.fa-person-circle-plus::after {
	content: "\E541\E541"; }
  
  .fad.fa-chart-pie::after, .fa-duotone.fa-chart-pie::after {
	content: "\F200\F200"; }
  
  .fad.fa-pie-chart::after, .fa-duotone.fa-pie-chart::after {
	content: "\F200\F200"; }
  
  .fad.fa-bolt-lightning::after, .fa-duotone.fa-bolt-lightning::after {
	content: "\E0B7\E0B7"; }
  
  .fad.fa-clock-eight::after, .fa-duotone.fa-clock-eight::after {
	content: "\E345\E345"; }
  
  .fad.fa-sack-xmark::after, .fa-duotone.fa-sack-xmark::after {
	content: "\E56A\E56A"; }
  
  .fad.fa-file-excel::after, .fa-duotone.fa-file-excel::after {
	content: "\F1C3\F1C3"; }
  
  .fad.fa-file-contract::after, .fa-duotone.fa-file-contract::after {
	content: "\F56C\F56C"; }
  
  .fad.fa-fish-fins::after, .fa-duotone.fa-fish-fins::after {
	content: "\E4F2\E4F2"; }
  
  .fad.fa-circle-q::after, .fa-duotone.fa-circle-q::after {
	content: "\E11E\E11E"; }
  
  .fad.fa-building-flag::after, .fa-duotone.fa-building-flag::after {
	content: "\E4D5\E4D5"; }
  
  .fad.fa-face-grin-beam::after, .fa-duotone.fa-face-grin-beam::after {
	content: "\F582\F582"; }
  
  .fad.fa-grin-beam::after, .fa-duotone.fa-grin-beam::after {
	content: "\F582\F582"; }
  
  .fad.fa-object-ungroup::after, .fa-duotone.fa-object-ungroup::after {
	content: "\F248\F248"; }
  
  .fad.fa-face-disguise::after, .fa-duotone.fa-face-disguise::after {
	content: "\E370\E370"; }
  
  .fad.fa-circle-arrow-down-right::after, .fa-duotone.fa-circle-arrow-down-right::after {
	content: "\E0FA\E0FA"; }
  
  .fad.fa-alien-8bit::after, .fa-duotone.fa-alien-8bit::after {
	content: "\F8F6\F8F6"; }
  
  .fad.fa-alien-monster::after, .fa-duotone.fa-alien-monster::after {
	content: "\F8F6\F8F6"; }
  
  .fad.fa-hand-point-ribbon::after, .fa-duotone.fa-hand-point-ribbon::after {
	content: "\E1A6\E1A6"; }
  
  .fad.fa-poop::after, .fa-duotone.fa-poop::after {
	content: "\F619\F619"; }
  
  .fad.fa-object-exclude::after, .fa-duotone.fa-object-exclude::after {
	content: "\E49C\E49C"; }
  
  .fad.fa-telescope::after, .fa-duotone.fa-telescope::after {
	content: "\E03E\E03E"; }
  
  .fad.fa-location-pin::after, .fa-duotone.fa-location-pin::after {
	content: "\F041\F041"; }
  
  .fad.fa-map-marker::after, .fa-duotone.fa-map-marker::after {
	content: "\F041\F041"; }
  
  .fad.fa-square-list::after, .fa-duotone.fa-square-list::after {
	content: "\E489\E489"; }
  
  .fad.fa-kaaba::after, .fa-duotone.fa-kaaba::after {
	content: "\F66B\F66B"; }
  
  .fad.fa-toilet-paper::after, .fa-duotone.fa-toilet-paper::after {
	content: "\F71E\F71E"; }
  
  .fad.fa-helmet-safety::after, .fa-duotone.fa-helmet-safety::after {
	content: "\F807\F807"; }
  
  .fad.fa-hard-hat::after, .fa-duotone.fa-hard-hat::after {
	content: "\F807\F807"; }
  
  .fad.fa-hat-hard::after, .fa-duotone.fa-hat-hard::after {
	content: "\F807\F807"; }
  
  .fad.fa-comment-code::after, .fa-duotone.fa-comment-code::after {
	content: "\E147\E147"; }
  
  .fad.fa-sim-cards::after, .fa-duotone.fa-sim-cards::after {
	content: "\E251\E251"; }
  
  .fad.fa-starship::after, .fa-duotone.fa-starship::after {
	content: "\E039\E039"; }
  
  .fad.fa-eject::after, .fa-duotone.fa-eject::after {
	content: "\F052\F052"; }
  
  .fad.fa-circle-right::after, .fa-duotone.fa-circle-right::after {
	content: "\F35A\F35A"; }
  
  .fad.fa-arrow-alt-circle-right::after, .fa-duotone.fa-arrow-alt-circle-right::after {
	content: "\F35A\F35A"; }
  
  .fad.fa-plane-circle-check::after, .fa-duotone.fa-plane-circle-check::after {
	content: "\E555\E555"; }
  
  .fad.fa-seal::after, .fa-duotone.fa-seal::after {
	content: "\E241\E241"; }
  
  .fad.fa-user-cowboy::after, .fa-duotone.fa-user-cowboy::after {
	content: "\F8EA\F8EA"; }
  
  .fad.fa-hexagon-vertical-nft::after, .fa-duotone.fa-hexagon-vertical-nft::after {
	content: "\E505\E505"; }
  
  .fad.fa-face-rolling-eyes::after, .fa-duotone.fa-face-rolling-eyes::after {
	content: "\F5A5\F5A5"; }
  
  .fad.fa-meh-rolling-eyes::after, .fa-duotone.fa-meh-rolling-eyes::after {
	content: "\F5A5\F5A5"; }
  
  .fad.fa-bread-loaf::after, .fa-duotone.fa-bread-loaf::after {
	content: "\F7EB\F7EB"; }
  
  .fad.fa-rings-wedding::after, .fa-duotone.fa-rings-wedding::after {
	content: "\F81B\F81B"; }
  
  .fad.fa-object-group::after, .fa-duotone.fa-object-group::after {
	content: "\F247\F247"; }
  
  .fad.fa-french-fries::after, .fa-duotone.fa-french-fries::after {
	content: "\F803\F803"; }
  
  .fad.fa-chart-line::after, .fa-duotone.fa-chart-line::after {
	content: "\F201\F201"; }
  
  .fad.fa-line-chart::after, .fa-duotone.fa-line-chart::after {
	content: "\F201\F201"; }
  
  .fad.fa-calendar-arrow-down::after, .fa-duotone.fa-calendar-arrow-down::after {
	content: "\E0D0\E0D0"; }
  
  .fad.fa-calendar-download::after, .fa-duotone.fa-calendar-download::after {
	content: "\E0D0\E0D0"; }
  
  .fad.fa-send-back::after, .fa-duotone.fa-send-back::after {
	content: "\F87E\F87E"; }
  
  .fad.fa-mask-ventilator::after, .fa-duotone.fa-mask-ventilator::after {
	content: "\E524\E524"; }
  
  .fad.fa-signature-lock::after, .fa-duotone.fa-signature-lock::after {
	content: "\E3CA\E3CA"; }
  
  .fad.fa-arrow-right::after, .fa-duotone.fa-arrow-right::after {
	content: "\F061\F061"; }
  
  .fad.fa-signs-post::after, .fa-duotone.fa-signs-post::after {
	content: "\F277\F277"; }
  
  .fad.fa-map-signs::after, .fa-duotone.fa-map-signs::after {
	content: "\F277\F277"; }
  
  .fad.fa-octagon-plus::after, .fa-duotone.fa-octagon-plus::after {
	content: "\F301\F301"; }
  
  .fad.fa-plus-octagon::after, .fa-duotone.fa-plus-octagon::after {
	content: "\F301\F301"; }
  
  .fad.fa-cash-register::after, .fa-duotone.fa-cash-register::after {
	content: "\F788\F788"; }
  
  .fad.fa-person-circle-question::after, .fa-duotone.fa-person-circle-question::after {
	content: "\E542\E542"; }
  
  .fad.fa-melon-slice::after, .fa-duotone.fa-melon-slice::after {
	content: "\E311\E311"; }
  
  .fad.fa-space-station-moon::after, .fa-duotone.fa-space-station-moon::after {
	content: "\E033\E033"; }
  
  .fad.fa-message-smile::after, .fa-duotone.fa-message-smile::after {
	content: "\F4AA\F4AA"; }
  
  .fad.fa-comment-alt-smile::after, .fa-duotone.fa-comment-alt-smile::after {
	content: "\F4AA\F4AA"; }
  
  .fad.fa-cup-straw::after, .fa-duotone.fa-cup-straw::after {
	content: "\E363\E363"; }
  
  .fad.fa-left-from-line::after, .fa-duotone.fa-left-from-line::after {
	content: "\F348\F348"; }
  
  .fad.fa-arrow-alt-from-right::after, .fa-duotone.fa-arrow-alt-from-right::after {
	content: "\F348\F348"; }
  
  .fad.fa-h::after, .fa-duotone.fa-h::after {
	content: "HH"; }
  
  .fad.fa-basket-shopping-simple::after, .fa-duotone.fa-basket-shopping-simple::after {
	content: "\E0AF\E0AF"; }
  
  .fad.fa-shopping-basket-alt::after, .fa-duotone.fa-shopping-basket-alt::after {
	content: "\E0AF\E0AF"; }
  
  .fad.fa-hands-holding-heart::after, .fa-duotone.fa-hands-holding-heart::after {
	content: "\F4C3\F4C3"; }
  
  .fad.fa-hands-heart::after, .fa-duotone.fa-hands-heart::after {
	content: "\F4C3\F4C3"; }
  
  .fad.fa-clock-nine::after, .fa-duotone.fa-clock-nine::after {
	content: "\E34C\E34C"; }
  
  .fad.fa-tarp::after, .fa-duotone.fa-tarp::after {
	content: "\E57B\E57B"; }
  
  .fad.fa-face-sleepy::after, .fa-duotone.fa-face-sleepy::after {
	content: "\E38E\E38E"; }
  
  .fad.fa-hand-horns::after, .fa-duotone.fa-hand-horns::after {
	content: "\E1A9\E1A9"; }
  
  .fad.fa-screwdriver-wrench::after, .fa-duotone.fa-screwdriver-wrench::after {
	content: "\F7D9\F7D9"; }
  
  .fad.fa-tools::after, .fa-duotone.fa-tools::after {
	content: "\F7D9\F7D9"; }
  
  .fad.fa-arrows-to-eye::after, .fa-duotone.fa-arrows-to-eye::after {
	content: "\E4BF\E4BF"; }
  
  .fad.fa-circle-three-quarters::after, .fa-duotone.fa-circle-three-quarters::after {
	content: "\E125\E125"; }
  
  .fad.fa-trophy-star::after, .fa-duotone.fa-trophy-star::after {
	content: "\F2EB\F2EB"; }
  
  .fad.fa-trophy-alt::after, .fa-duotone.fa-trophy-alt::after {
	content: "\F2EB\F2EB"; }
  
  .fad.fa-plug-circle-bolt::after, .fa-duotone.fa-plug-circle-bolt::after {
	content: "\E55B\E55B"; }
  
  .fad.fa-face-thermometer::after, .fa-duotone.fa-face-thermometer::after {
	content: "\E39A\E39A"; }
  
  .fad.fa-shirt-running::after, .fa-duotone.fa-shirt-running::after {
	content: "\E3C8\E3C8"; }
  
  .fad.fa-book-circle-arrow-up::after, .fa-duotone.fa-book-circle-arrow-up::after {
	content: "\E0BD\E0BD"; }
  
  .fad.fa-face-nauseated::after, .fa-duotone.fa-face-nauseated::after {
	content: "\E381\E381"; }
  
  .fad.fa-heart::after, .fa-duotone.fa-heart::after {
	content: "\F004\F004"; }
  
  .fad.fa-file-chart-pie::after, .fa-duotone.fa-file-chart-pie::after {
	content: "\F65A\F65A"; }
  
  .fad.fa-mars-and-venus::after, .fa-duotone.fa-mars-and-venus::after {
	content: "\F224\F224"; }
  
  .fad.fa-house-user::after, .fa-duotone.fa-house-user::after {
	content: "\E1B0\E1B0"; }
  
  .fad.fa-home-user::after, .fa-duotone.fa-home-user::after {
	content: "\E1B0\E1B0"; }
  
  .fad.fa-circle-arrow-down-left::after, .fa-duotone.fa-circle-arrow-down-left::after {
	content: "\E0F9\E0F9"; }
  
  .fad.fa-dumpster-fire::after, .fa-duotone.fa-dumpster-fire::after {
	content: "\F794\F794"; }
  
  .fad.fa-hexagon-minus::after, .fa-duotone.fa-hexagon-minus::after {
	content: "\F307\F307"; }
  
  .fad.fa-minus-hexagon::after, .fa-duotone.fa-minus-hexagon::after {
	content: "\F307\F307"; }
  
  .fad.fa-left-to-line::after, .fa-duotone.fa-left-to-line::after {
	content: "\F34B\F34B"; }
  
  .fad.fa-arrow-alt-to-left::after, .fa-duotone.fa-arrow-alt-to-left::after {
	content: "\F34B\F34B"; }
  
  .fad.fa-house-crack::after, .fa-duotone.fa-house-crack::after {
	content: "\E3B1\E3B1"; }
  
  .fad.fa-paw-simple::after, .fa-duotone.fa-paw-simple::after {
	content: "\F701\F701"; }
  
  .fad.fa-paw-alt::after, .fa-duotone.fa-paw-alt::after {
	content: "\F701\F701"; }
  
  .fad.fa-arrow-left-long-to-line::after, .fa-duotone.fa-arrow-left-long-to-line::after {
	content: "\E3D4\E3D4"; }
  
  .fad.fa-brackets-round::after, .fa-duotone.fa-brackets-round::after {
	content: "\E0C5\E0C5"; }
  
  .fad.fa-parentheses::after, .fa-duotone.fa-parentheses::after {
	content: "\E0C5\E0C5"; }
  
  .fad.fa-martini-glass-citrus::after, .fa-duotone.fa-martini-glass-citrus::after {
	content: "\F561\F561"; }
  
  .fad.fa-cocktail::after, .fa-duotone.fa-cocktail::after {
	content: "\F561\F561"; }
  
  .fad.fa-user-shakespeare::after, .fa-duotone.fa-user-shakespeare::after {
	content: "\E2C2\E2C2"; }
  
  .fad.fa-arrow-right-to-arc::after, .fa-duotone.fa-arrow-right-to-arc::after {
	content: "\E4B2\E4B2"; }
  
  .fad.fa-face-surprise::after, .fa-duotone.fa-face-surprise::after {
	content: "\F5C2\F5C2"; }
  
  .fad.fa-surprise::after, .fa-duotone.fa-surprise::after {
	content: "\F5C2\F5C2"; }
  
  .fad.fa-bottle-water::after, .fa-duotone.fa-bottle-water::after {
	content: "\E4C5\E4C5"; }
  
  .fad.fa-circle-pause::after, .fa-duotone.fa-circle-pause::after {
	content: "\F28B\F28B"; }
  
  .fad.fa-pause-circle::after, .fa-duotone.fa-pause-circle::after {
	content: "\F28B\F28B"; }
  
  .fad.fa-gauge-circle-plus::after, .fa-duotone.fa-gauge-circle-plus::after {
	content: "\E498\E498"; }
  
  .fad.fa-folders::after, .fa-duotone.fa-folders::after {
	content: "\F660\F660"; }
  
  .fad.fa-angel::after, .fa-duotone.fa-angel::after {
	content: "\F779\F779"; }
  
  .fad.fa-value-absolute::after, .fa-duotone.fa-value-absolute::after {
	content: "\F6A6\F6A6"; }
  
  .fad.fa-rabbit::after, .fa-duotone.fa-rabbit::after {
	content: "\F708\F708"; }
  
  .fad.fa-toilet-paper-slash::after, .fa-duotone.fa-toilet-paper-slash::after {
	content: "\E072\E072"; }
  
  .fad.fa-apple-whole::after, .fa-duotone.fa-apple-whole::after {
	content: "\F5D1\F5D1"; }
  
  .fad.fa-apple-alt::after, .fa-duotone.fa-apple-alt::after {
	content: "\F5D1\F5D1"; }
  
  .fad.fa-kitchen-set::after, .fa-duotone.fa-kitchen-set::after {
	content: "\E51A\E51A"; }
  
  .fad.fa-lock-keyhole::after, .fa-duotone.fa-lock-keyhole::after {
	content: "\F30D\F30D"; }
  
  .fad.fa-lock-alt::after, .fa-duotone.fa-lock-alt::after {
	content: "\F30D\F30D"; }
  
  .fad.fa-r::after, .fa-duotone.fa-r::after {
	content: "RR"; }
  
  .fad.fa-temperature-quarter::after, .fa-duotone.fa-temperature-quarter::after {
	content: "\F2CA\F2CA"; }
  
  .fad.fa-temperature-1::after, .fa-duotone.fa-temperature-1::after {
	content: "\F2CA\F2CA"; }
  
  .fad.fa-thermometer-1::after, .fa-duotone.fa-thermometer-1::after {
	content: "\F2CA\F2CA"; }
  
  .fad.fa-thermometer-quarter::after, .fa-duotone.fa-thermometer-quarter::after {
	content: "\F2CA\F2CA"; }
  
  .fad.fa-square-info::after, .fa-duotone.fa-square-info::after {
	content: "\F30F\F30F"; }
  
  .fad.fa-info-square::after, .fa-duotone.fa-info-square::after {
	content: "\F30F\F30F"; }
  
  .fad.fa-wifi-slash::after, .fa-duotone.fa-wifi-slash::after {
	content: "\F6AC\F6AC"; }
  
  .fad.fa-toilet-paper-xmark::after, .fa-duotone.fa-toilet-paper-xmark::after {
	content: "\E5B3\E5B3"; }
  
  .fad.fa-hands-holding-dollar::after, .fa-duotone.fa-hands-holding-dollar::after {
	content: "\F4C5\F4C5"; }
  
  .fad.fa-hands-usd::after, .fa-duotone.fa-hands-usd::after {
	content: "\F4C5\F4C5"; }
  
  .fad.fa-cube::after, .fa-duotone.fa-cube::after {
	content: "\F1B2\F1B2"; }
  
  .fad.fa-arrow-down-triangle-square::after, .fa-duotone.fa-arrow-down-triangle-square::after {
	content: "\F888\F888"; }
  
  .fad.fa-sort-shapes-down::after, .fa-duotone.fa-sort-shapes-down::after {
	content: "\F888\F888"; }
  
  .fad.fa-bitcoin-sign::after, .fa-duotone.fa-bitcoin-sign::after {
	content: "\E0B4\E0B4"; }
  
  .fad.fa-shutters::after, .fa-duotone.fa-shutters::after {
	content: "\E449\E449"; }
  
  .fad.fa-shield-dog::after, .fa-duotone.fa-shield-dog::after {
	content: "\E573\E573"; }
  
  .fad.fa-solar-panel::after, .fa-duotone.fa-solar-panel::after {
	content: "\F5BA\F5BA"; }
  
  .fad.fa-lock-open::after, .fa-duotone.fa-lock-open::after {
	content: "\F3C1\F3C1"; }
  
  .fad.fa-table-tree::after, .fa-duotone.fa-table-tree::after {
	content: "\E293\E293"; }
  
  .fad.fa-house-chimney-heart::after, .fa-duotone.fa-house-chimney-heart::after {
	content: "\E1B2\E1B2"; }
  
  .fad.fa-tally-3::after, .fa-duotone.fa-tally-3::after {
	content: "\E296\E296"; }
  
  .fad.fa-elevator::after, .fa-duotone.fa-elevator::after {
	content: "\E16D\E16D"; }
  
  .fad.fa-money-bill-transfer::after, .fa-duotone.fa-money-bill-transfer::after {
	content: "\E528\E528"; }
  
  .fad.fa-money-bill-trend-up::after, .fa-duotone.fa-money-bill-trend-up::after {
	content: "\E529\E529"; }
  
  .fad.fa-house-flood-water-circle-arrow-right::after, .fa-duotone.fa-house-flood-water-circle-arrow-right::after {
	content: "\E50F\E50F"; }
  
  .fad.fa-square-poll-horizontal::after, .fa-duotone.fa-square-poll-horizontal::after {
	content: "\F682\F682"; }
  
  .fad.fa-poll-h::after, .fa-duotone.fa-poll-h::after {
	content: "\F682\F682"; }
  
  .fad.fa-circle::after, .fa-duotone.fa-circle::after {
	content: "\F111\F111"; }
  
  .fad.fa-cart-circle-exclamation::after, .fa-duotone.fa-cart-circle-exclamation::after {
	content: "\E3F2\E3F2"; }
  
  .fad.fa-sword::after, .fa-duotone.fa-sword::after {
	content: "\F71C\F71C"; }
  
  .fad.fa-backward-fast::after, .fa-duotone.fa-backward-fast::after {
	content: "\F049\F049"; }
  
  .fad.fa-fast-backward::after, .fa-duotone.fa-fast-backward::after {
	content: "\F049\F049"; }
  
  .fad.fa-recycle::after, .fa-duotone.fa-recycle::after {
	content: "\F1B8\F1B8"; }
  
  .fad.fa-user-astronaut::after, .fa-duotone.fa-user-astronaut::after {
	content: "\F4FB\F4FB"; }
  
  .fad.fa-plane-slash::after, .fa-duotone.fa-plane-slash::after {
	content: "\E069\E069"; }
  
  .fad.fa-circle-dashed::after, .fa-duotone.fa-circle-dashed::after {
	content: "\E105\E105"; }
  
  .fad.fa-trademark::after, .fa-duotone.fa-trademark::after {
	content: "\F25C\F25C"; }
  
  .fad.fa-basketball::after, .fa-duotone.fa-basketball::after {
	content: "\F434\F434"; }
  
  .fad.fa-basketball-ball::after, .fa-duotone.fa-basketball-ball::after {
	content: "\F434\F434"; }
  
  .fad.fa-fork-knife::after, .fa-duotone.fa-fork-knife::after {
	content: "\F2E6\F2E6"; }
  
  .fad.fa-utensils-alt::after, .fa-duotone.fa-utensils-alt::after {
	content: "\F2E6\F2E6"; }
  
  .fad.fa-satellite-dish::after, .fa-duotone.fa-satellite-dish::after {
	content: "\F7C0\F7C0"; }
  
  .fad.fa-badge-check::after, .fa-duotone.fa-badge-check::after {
	content: "\F336\F336"; }
  
  .fad.fa-circle-up::after, .fa-duotone.fa-circle-up::after {
	content: "\F35B\F35B"; }
  
  .fad.fa-arrow-alt-circle-up::after, .fa-duotone.fa-arrow-alt-circle-up::after {
	content: "\F35B\F35B"; }
  
  .fad.fa-slider::after, .fa-duotone.fa-slider::after {
	content: "\E252\E252"; }
  
  .fad.fa-mobile-screen-button::after, .fa-duotone.fa-mobile-screen-button::after {
	content: "\F3CD\F3CD"; }
  
  .fad.fa-mobile-alt::after, .fa-duotone.fa-mobile-alt::after {
	content: "\F3CD\F3CD"; }
  
  .fad.fa-clock-one-thirty::after, .fa-duotone.fa-clock-one-thirty::after {
	content: "\E34F\E34F"; }
  
  .fad.fa-inbox-out::after, .fa-duotone.fa-inbox-out::after {
	content: "\F311\F311"; }
  
  .fad.fa-inbox-arrow-up::after, .fa-duotone.fa-inbox-arrow-up::after {
	content: "\F311\F311"; }
  
  .fad.fa-cloud-slash::after, .fa-duotone.fa-cloud-slash::after {
	content: "\E137\E137"; }
  
  .fad.fa-volume-high::after, .fa-duotone.fa-volume-high::after {
	content: "\F028\F028"; }
  
  .fad.fa-volume-up::after, .fa-duotone.fa-volume-up::after {
	content: "\F028\F028"; }
  
  .fad.fa-users-rays::after, .fa-duotone.fa-users-rays::after {
	content: "\E593\E593"; }
  
  .fad.fa-wallet::after, .fa-duotone.fa-wallet::after {
	content: "\F555\F555"; }
  
  .fad.fa-octagon-check::after, .fa-duotone.fa-octagon-check::after {
	content: "\E426\E426"; }
  
  .fad.fa-flatbread-stuffed::after, .fa-duotone.fa-flatbread-stuffed::after {
	content: "\E40C\E40C"; }
  
  .fad.fa-clipboard-check::after, .fa-duotone.fa-clipboard-check::after {
	content: "\F46C\F46C"; }
  
  .fad.fa-cart-circle-plus::after, .fa-duotone.fa-cart-circle-plus::after {
	content: "\E3F3\E3F3"; }
  
  .fad.fa-truck-clock::after, .fa-duotone.fa-truck-clock::after {
	content: "\F48C\F48C"; }
  
  .fad.fa-shipping-timed::after, .fa-duotone.fa-shipping-timed::after {
	content: "\F48C\F48C"; }
  
  .fad.fa-pool-8-ball::after, .fa-duotone.fa-pool-8-ball::after {
	content: "\E3C5\E3C5"; }
  
  .fad.fa-file-audio::after, .fa-duotone.fa-file-audio::after {
	content: "\F1C7\F1C7"; }
  
  .fad.fa-turn-down-left::after, .fa-duotone.fa-turn-down-left::after {
	content: "\E331\E331"; }
  
  .fad.fa-lock-hashtag::after, .fa-duotone.fa-lock-hashtag::after {
	content: "\E423\E423"; }
  
  .fad.fa-chart-radar::after, .fa-duotone.fa-chart-radar::after {
	content: "\E0E7\E0E7"; }
  
  .fad.fa-staff::after, .fa-duotone.fa-staff::after {
	content: "\F71B\F71B"; }
  
  .fad.fa-burger::after, .fa-duotone.fa-burger::after {
	content: "\F805\F805"; }
  
  .fad.fa-hamburger::after, .fa-duotone.fa-hamburger::after {
	content: "\F805\F805"; }
  
  .fad.fa-utility-pole::after, .fa-duotone.fa-utility-pole::after {
	content: "\E2C3\E2C3"; }
  
  .fad.fa-transporter-6::after, .fa-duotone.fa-transporter-6::after {
	content: "\E2A7\E2A7"; }
  
  .fad.fa-wrench::after, .fa-duotone.fa-wrench::after {
	content: "\F0AD\F0AD"; }
  
  .fad.fa-bugs::after, .fa-duotone.fa-bugs::after {
	content: "\E4D0\E4D0"; }
  
  .fad.fa-vector-polygon::after, .fa-duotone.fa-vector-polygon::after {
	content: "\E2C7\E2C7"; }
  
  .fad.fa-diagram-nested::after, .fa-duotone.fa-diagram-nested::after {
	content: "\E157\E157"; }
  
  .fad.fa-rupee-sign::after, .fa-duotone.fa-rupee-sign::after {
	content: "\F156\F156"; }
  
  .fad.fa-rupee::after, .fa-duotone.fa-rupee::after {
	content: "\F156\F156"; }
  
  .fad.fa-file-image::after, .fa-duotone.fa-file-image::after {
	content: "\F1C5\F1C5"; }
  
  .fad.fa-circle-question::after, .fa-duotone.fa-circle-question::after {
	content: "\F059\F059"; }
  
  .fad.fa-question-circle::after, .fa-duotone.fa-question-circle::after {
	content: "\F059\F059"; }
  
  .fad.fa-image-user::after, .fa-duotone.fa-image-user::after {
	content: "\E1B8\E1B8"; }
  
  .fad.fa-plane-departure::after, .fa-duotone.fa-plane-departure::after {
	content: "\F5B0\F5B0"; }
  
  .fad.fa-handshake-slash::after, .fa-duotone.fa-handshake-slash::after {
	content: "\E060\E060"; }
  
  .fad.fa-book-bookmark::after, .fa-duotone.fa-book-bookmark::after {
	content: "\E0BB\E0BB"; }
  
  .fad.fa-border-center-h::after, .fa-duotone.fa-border-center-h::after {
	content: "\F89C\F89C"; }
  
  .fad.fa-can-food::after, .fa-duotone.fa-can-food::after {
	content: "\E3E6\E3E6"; }
  
  .fad.fa-typewriter::after, .fa-duotone.fa-typewriter::after {
	content: "\F8E7\F8E7"; }
  
  .fad.fa-arrow-right-from-arc::after, .fa-duotone.fa-arrow-right-from-arc::after {
	content: "\E4B1\E4B1"; }
  
  .fad.fa-circle-k::after, .fa-duotone.fa-circle-k::after {
	content: "\E113\E113"; }
  
  .fad.fa-face-hand-over-mouth::after, .fa-duotone.fa-face-hand-over-mouth::after {
	content: "\E378\E378"; }
  
  .fad.fa-popcorn::after, .fa-duotone.fa-popcorn::after {
	content: "\F819\F819"; }
  
  .fad.fa-house-water::after, .fa-duotone.fa-house-water::after {
	content: "\F74F\F74F"; }
  
  .fad.fa-house-flood::after, .fa-duotone.fa-house-flood::after {
	content: "\F74F\F74F"; }
  
  .fad.fa-object-subtract::after, .fa-duotone.fa-object-subtract::after {
	content: "\E49E\E49E"; }
  
  .fad.fa-code-branch::after, .fa-duotone.fa-code-branch::after {
	content: "\F126\F126"; }
  
  .fad.fa-warehouse-full::after, .fa-duotone.fa-warehouse-full::after {
	content: "\F495\F495"; }
  
  .fad.fa-warehouse-alt::after, .fa-duotone.fa-warehouse-alt::after {
	content: "\F495\F495"; }
  
  .fad.fa-hat-cowboy::after, .fa-duotone.fa-hat-cowboy::after {
	content: "\F8C0\F8C0"; }
  
  .fad.fa-bridge::after, .fa-duotone.fa-bridge::after {
	content: "\E4C8\E4C8"; }
  
  .fad.fa-phone-flip::after, .fa-duotone.fa-phone-flip::after {
	content: "\F879\F879"; }
  
  .fad.fa-phone-alt::after, .fa-duotone.fa-phone-alt::after {
	content: "\F879\F879"; }
  
  .fad.fa-arrow-down-from-dotted-line::after, .fa-duotone.fa-arrow-down-from-dotted-line::after {
	content: "\E090\E090"; }
  
  .fad.fa-square-quarters::after, .fa-duotone.fa-square-quarters::after {
	content: "\E44E\E44E"; }
  
  .fad.fa-truck-front::after, .fa-duotone.fa-truck-front::after {
	content: "\E2B7\E2B7"; }
  
  .fad.fa-cat::after, .fa-duotone.fa-cat::after {
	content: "\F6BE\F6BE"; }
  
  .fad.fa-trash-xmark::after, .fa-duotone.fa-trash-xmark::after {
	content: "\E2B4\E2B4"; }
  
  .fad.fa-circle-caret-left::after, .fa-duotone.fa-circle-caret-left::after {
	content: "\F32E\F32E"; }
  
  .fad.fa-caret-circle-left::after, .fa-duotone.fa-caret-circle-left::after {
	content: "\F32E\F32E"; }
  
  .fad.fa-files::after, .fa-duotone.fa-files::after {
	content: "\E178\E178"; }
  
  .fad.fa-anchor-circle-exclamation::after, .fa-duotone.fa-anchor-circle-exclamation::after {
	content: "\E4AB\E4AB"; }
  
  .fad.fa-face-clouds::after, .fa-duotone.fa-face-clouds::after {
	content: "\E47D\E47D"; }
  
  .fad.fa-user-crown::after, .fa-duotone.fa-user-crown::after {
	content: "\F6A4\F6A4"; }
  
  .fad.fa-truck-field::after, .fa-duotone.fa-truck-field::after {
	content: "\E58D\E58D"; }
  
  .fad.fa-route::after, .fa-duotone.fa-route::after {
	content: "\F4D7\F4D7"; }
  
  .fad.fa-cart-circle-check::after, .fa-duotone.fa-cart-circle-check::after {
	content: "\E3F1\E3F1"; }
  
  .fad.fa-clipboard-question::after, .fa-duotone.fa-clipboard-question::after {
	content: "\E4E3\E4E3"; }
  
  .fad.fa-panorama::after, .fa-duotone.fa-panorama::after {
	content: "\E209\E209"; }
  
  .fad.fa-comment-medical::after, .fa-duotone.fa-comment-medical::after {
	content: "\F7F5\F7F5"; }
  
  .fad.fa-teeth-open::after, .fa-duotone.fa-teeth-open::after {
	content: "\F62F\F62F"; }
  
  .fad.fa-user-tie-hair-long::after, .fa-duotone.fa-user-tie-hair-long::after {
	content: "\E460\E460"; }
  
  .fad.fa-file-circle-minus::after, .fa-duotone.fa-file-circle-minus::after {
	content: "\E4ED\E4ED"; }
  
  .fad.fa-head-side-medical::after, .fa-duotone.fa-head-side-medical::after {
	content: "\F809\F809"; }
  
  .fad.fa-tags::after, .fa-duotone.fa-tags::after {
	content: "\F02C\F02C"; }
  
  .fad.fa-wine-glass::after, .fa-duotone.fa-wine-glass::after {
	content: "\F4E3\F4E3"; }
  
  .fad.fa-forward-fast::after, .fa-duotone.fa-forward-fast::after {
	content: "\F050\F050"; }
  
  .fad.fa-fast-forward::after, .fa-duotone.fa-fast-forward::after {
	content: "\F050\F050"; }
  
  .fad.fa-face-meh-blank::after, .fa-duotone.fa-face-meh-blank::after {
	content: "\F5A4\F5A4"; }
  
  .fad.fa-meh-blank::after, .fa-duotone.fa-meh-blank::after {
	content: "\F5A4\F5A4"; }
  
  .fad.fa-user-robot::after, .fa-duotone.fa-user-robot::after {
	content: "\E04B\E04B"; }
  
  .fad.fa-square-parking::after, .fa-duotone.fa-square-parking::after {
	content: "\F540\F540"; }
  
  .fad.fa-parking::after, .fa-duotone.fa-parking::after {
	content: "\F540\F540"; }
  
  .fad.fa-card-diamond::after, .fa-duotone.fa-card-diamond::after {
	content: "\E3EA\E3EA"; }
  
  .fad.fa-face-zipper::after, .fa-duotone.fa-face-zipper::after {
	content: "\E3A5\E3A5"; }
  
  .fad.fa-face-raised-eyebrow::after, .fa-duotone.fa-face-raised-eyebrow::after {
	content: "\E388\E388"; }
  
  .fad.fa-house-signal::after, .fa-duotone.fa-house-signal::after {
	content: "\E012\E012"; }
  
  .fad.fa-square-chevron-up::after, .fa-duotone.fa-square-chevron-up::after {
	content: "\F32C\F32C"; }
  
  .fad.fa-chevron-square-up::after, .fa-duotone.fa-chevron-square-up::after {
	content: "\F32C\F32C"; }
  
  .fad.fa-bars-progress::after, .fa-duotone.fa-bars-progress::after {
	content: "\F828\F828"; }
  
  .fad.fa-tasks-alt::after, .fa-duotone.fa-tasks-alt::after {
	content: "\F828\F828"; }
  
  .fad.fa-faucet-drip::after, .fa-duotone.fa-faucet-drip::after {
	content: "\E006\E006"; }
  
  .fad.fa-arrows-to-line::after, .fa-duotone.fa-arrows-to-line::after {
	content: "\E0A7\E0A7"; }
  
  .fad.fa-dolphin::after, .fa-duotone.fa-dolphin::after {
	content: "\E168\E168"; }
  
  .fad.fa-arrow-up-right::after, .fa-duotone.fa-arrow-up-right::after {
	content: "\E09F\E09F"; }
  
  .fad.fa-circle-r::after, .fa-duotone.fa-circle-r::after {
	content: "\E120\E120"; }
  
  .fad.fa-cart-flatbed::after, .fa-duotone.fa-cart-flatbed::after {
	content: "\F474\F474"; }
  
  .fad.fa-dolly-flatbed::after, .fa-duotone.fa-dolly-flatbed::after {
	content: "\F474\F474"; }
  
  .fad.fa-ban-smoking::after, .fa-duotone.fa-ban-smoking::after {
	content: "\F54D\F54D"; }
  
  .fad.fa-smoking-ban::after, .fa-duotone.fa-smoking-ban::after {
	content: "\F54D\F54D"; }
  
  .fad.fa-circle-sort-up::after, .fa-duotone.fa-circle-sort-up::after {
	content: "\E032\E032"; }
  
  .fad.fa-sort-circle-up::after, .fa-duotone.fa-sort-circle-up::after {
	content: "\E032\E032"; }
  
  .fad.fa-terminal::after, .fa-duotone.fa-terminal::after {
	content: "\F120\F120"; }
  
  .fad.fa-mobile-button::after, .fa-duotone.fa-mobile-button::after {
	content: "\F10B\F10B"; }
  
  .fad.fa-house-medical-flag::after, .fa-duotone.fa-house-medical-flag::after {
	content: "\E514\E514"; }
  
  .fad.fa-basket-shopping::after, .fa-duotone.fa-basket-shopping::after {
	content: "\F291\F291"; }
  
  .fad.fa-shopping-basket::after, .fa-duotone.fa-shopping-basket::after {
	content: "\F291\F291"; }
  
  .fad.fa-tape::after, .fa-duotone.fa-tape::after {
	content: "\F4DB\F4DB"; }
  
  .fad.fa-chestnut::after, .fa-duotone.fa-chestnut::after {
	content: "\E3F6\E3F6"; }
  
  .fad.fa-bus-simple::after, .fa-duotone.fa-bus-simple::after {
	content: "\F55E\F55E"; }
  
  .fad.fa-bus-alt::after, .fa-duotone.fa-bus-alt::after {
	content: "\F55E\F55E"; }
  
  .fad.fa-eye::after, .fa-duotone.fa-eye::after {
	content: "\F06E\F06E"; }
  
  .fad.fa-face-sad-cry::after, .fa-duotone.fa-face-sad-cry::after {
	content: "\F5B3\F5B3"; }
  
  .fad.fa-sad-cry::after, .fa-duotone.fa-sad-cry::after {
	content: "\F5B3\F5B3"; }
  
  .fad.fa-heat::after, .fa-duotone.fa-heat::after {
	content: "\E00C\E00C"; }
  
  .fad.fa-ticket-airline::after, .fa-duotone.fa-ticket-airline::after {
	content: "\E29A\E29A"; }
  
  .fad.fa-boot-heeled::after, .fa-duotone.fa-boot-heeled::after {
	content: "\E33F\E33F"; }
  
  .fad.fa-arrows-minimize::after, .fa-duotone.fa-arrows-minimize::after {
	content: "\E0A5\E0A5"; }
  
  .fad.fa-compress-arrows::after, .fa-duotone.fa-compress-arrows::after {
	content: "\E0A5\E0A5"; }
  
  .fad.fa-audio-description::after, .fa-duotone.fa-audio-description::after {
	content: "\F29E\F29E"; }
  
  .fad.fa-person-military-to-person::after, .fa-duotone.fa-person-military-to-person::after {
	content: "\E54C\E54C"; }
  
  .fad.fa-file-shield::after, .fa-duotone.fa-file-shield::after {
	content: "\E4F0\E4F0"; }
  
  .fad.fa-hexagon::after, .fa-duotone.fa-hexagon::after {
	content: "\F312\F312"; }
  
  .fad.fa-manhole::after, .fa-duotone.fa-manhole::after {
	content: "\E1D6\E1D6"; }
  
  .fad.fa-user-slash::after, .fa-duotone.fa-user-slash::after {
	content: "\F506\F506"; }
  
  .fad.fa-pen::after, .fa-duotone.fa-pen::after {
	content: "\F304\F304"; }
  
  .fad.fa-tower-observation::after, .fa-duotone.fa-tower-observation::after {
	content: "\E586\E586"; }
  
  .fad.fa-floppy-disks::after, .fa-duotone.fa-floppy-disks::after {
	content: "\E183\E183"; }
  
  .fad.fa-toilet-paper-blank-under::after, .fa-duotone.fa-toilet-paper-blank-under::after {
	content: "\E29F\E29F"; }
  
  .fad.fa-toilet-paper-reverse-alt::after, .fa-duotone.fa-toilet-paper-reverse-alt::after {
	content: "\E29F\E29F"; }
  
  .fad.fa-file-code::after, .fa-duotone.fa-file-code::after {
	content: "\F1C9\F1C9"; }
  
  .fad.fa-signal::after, .fa-duotone.fa-signal::after {
	content: "\F012\F012"; }
  
  .fad.fa-signal-5::after, .fa-duotone.fa-signal-5::after {
	content: "\F012\F012"; }
  
  .fad.fa-signal-perfect::after, .fa-duotone.fa-signal-perfect::after {
	content: "\F012\F012"; }
  
  .fad.fa-pump::after, .fa-duotone.fa-pump::after {
	content: "\E442\E442"; }
  
  .fad.fa-bus::after, .fa-duotone.fa-bus::after {
	content: "\F207\F207"; }
  
  .fad.fa-heart-circle-xmark::after, .fa-duotone.fa-heart-circle-xmark::after {
	content: "\E501\E501"; }
  
  .fad.fa-arrow-up-left-from-circle::after, .fa-duotone.fa-arrow-up-left-from-circle::after {
	content: "\E09E\E09E"; }
  
  .fad.fa-house-chimney::after, .fa-duotone.fa-house-chimney::after {
	content: "\E3AF\E3AF"; }
  
  .fad.fa-home-lg::after, .fa-duotone.fa-home-lg::after {
	content: "\E3AF\E3AF"; }
  
  .fad.fa-window-maximize::after, .fa-duotone.fa-window-maximize::after {
	content: "\F2D0\F2D0"; }
  
  .fad.fa-dryer::after, .fa-duotone.fa-dryer::after {
	content: "\F861\F861"; }
  
  .fad.fa-face-frown::after, .fa-duotone.fa-face-frown::after {
	content: "\F119\F119"; }
  
  .fad.fa-frown::after, .fa-duotone.fa-frown::after {
	content: "\F119\F119"; }
  
  .fad.fa-chess-bishop-piece::after, .fa-duotone.fa-chess-bishop-piece::after {
	content: "\F43B\F43B"; }
  
  .fad.fa-chess-bishop-alt::after, .fa-duotone.fa-chess-bishop-alt::after {
	content: "\F43B\F43B"; }
  
  .fad.fa-shirt-tank-top::after, .fa-duotone.fa-shirt-tank-top::after {
	content: "\E3C9\E3C9"; }
  
  .fad.fa-diploma::after, .fa-duotone.fa-diploma::after {
	content: "\F5EA\F5EA"; }
  
  .fad.fa-scroll-ribbon::after, .fa-duotone.fa-scroll-ribbon::after {
	content: "\F5EA\F5EA"; }
  
  .fad.fa-screencast::after, .fa-duotone.fa-screencast::after {
	content: "\E23E\E23E"; }
  
  .fad.fa-walker::after, .fa-duotone.fa-walker::after {
	content: "\F831\F831"; }
  
  .fad.fa-prescription::after, .fa-duotone.fa-prescription::after {
	content: "\F5B1\F5B1"; }
  
  .fad.fa-shop::after, .fa-duotone.fa-shop::after {
	content: "\F54F\F54F"; }
  
  .fad.fa-store-alt::after, .fa-duotone.fa-store-alt::after {
	content: "\F54F\F54F"; }
  
  .fad.fa-floppy-disk::after, .fa-duotone.fa-floppy-disk::after {
	content: "\F0C7\F0C7"; }
  
  .fad.fa-save::after, .fa-duotone.fa-save::after {
	content: "\F0C7\F0C7"; }
  
  .fad.fa-vihara::after, .fa-duotone.fa-vihara::after {
	content: "\F6A7\F6A7"; }
  
  .fad.fa-face-kiss-closed-eyes::after, .fa-duotone.fa-face-kiss-closed-eyes::after {
	content: "\E37D\E37D"; }
  
  .fad.fa-scale-unbalanced::after, .fa-duotone.fa-scale-unbalanced::after {
	content: "\F515\F515"; }
  
  .fad.fa-balance-scale-left::after, .fa-duotone.fa-balance-scale-left::after {
	content: "\F515\F515"; }
  
  .fad.fa-file-user::after, .fa-duotone.fa-file-user::after {
	content: "\F65C\F65C"; }
  
  .fad.fa-user-police-tie::after, .fa-duotone.fa-user-police-tie::after {
	content: "\E334\E334"; }
  
  .fad.fa-face-tongue-money::after, .fa-duotone.fa-face-tongue-money::after {
	content: "\E39D\E39D"; }
  
  .fad.fa-tennis-ball::after, .fa-duotone.fa-tennis-ball::after {
	content: "\F45E\F45E"; }
  
  .fad.fa-square-l::after, .fa-duotone.fa-square-l::after {
	content: "\E275\E275"; }
  
  .fad.fa-sort-up::after, .fa-duotone.fa-sort-up::after {
	content: "\F0DE\F0DE"; }
  
  .fad.fa-sort-asc::after, .fa-duotone.fa-sort-asc::after {
	content: "\F0DE\F0DE"; }
  
  .fad.fa-calendar-arrow-up::after, .fa-duotone.fa-calendar-arrow-up::after {
	content: "\E0D1\E0D1"; }
  
  .fad.fa-calendar-upload::after, .fa-duotone.fa-calendar-upload::after {
	content: "\E0D1\E0D1"; }
  
  .fad.fa-comment-dots::after, .fa-duotone.fa-comment-dots::after {
	content: "\F4AD\F4AD"; }
  
  .fad.fa-commenting::after, .fa-duotone.fa-commenting::after {
	content: "\F4AD\F4AD"; }
  
  .fad.fa-plant-wilt::after, .fa-duotone.fa-plant-wilt::after {
	content: "\E5AA\E5AA"; }
  
  .fad.fa-scarf::after, .fa-duotone.fa-scarf::after {
	content: "\F7C1\F7C1"; }
  
  .fad.fa-album-circle-plus::after, .fa-duotone.fa-album-circle-plus::after {
	content: "\E48C\E48C"; }
  
  .fad.fa-user-nurse-hair-long::after, .fa-duotone.fa-user-nurse-hair-long::after {
	content: "\E45E\E45E"; }
  
  .fad.fa-diamond::after, .fa-duotone.fa-diamond::after {
	content: "\F219\F219"; }
  
  .fad.fa-square-left::after, .fa-duotone.fa-square-left::after {
	content: "\F351\F351"; }
  
  .fad.fa-arrow-alt-square-left::after, .fa-duotone.fa-arrow-alt-square-left::after {
	content: "\F351\F351"; }
  
  .fad.fa-face-grin-squint::after, .fa-duotone.fa-face-grin-squint::after {
	content: "\F585\F585"; }
  
  .fad.fa-grin-squint::after, .fa-duotone.fa-grin-squint::after {
	content: "\F585\F585"; }
  
  .fad.fa-circle-ellipsis-vertical::after, .fa-duotone.fa-circle-ellipsis-vertical::after {
	content: "\E10B\E10B"; }
  
  .fad.fa-hand-holding-dollar::after, .fa-duotone.fa-hand-holding-dollar::after {
	content: "\F4C0\F4C0"; }
  
  .fad.fa-hand-holding-usd::after, .fa-duotone.fa-hand-holding-usd::after {
	content: "\F4C0\F4C0"; }
  
  .fad.fa-grid-dividers::after, .fa-duotone.fa-grid-dividers::after {
	content: "\E3AD\E3AD"; }
  
  .fad.fa-bacterium::after, .fa-duotone.fa-bacterium::after {
	content: "\E05A\E05A"; }
  
  .fad.fa-hand-pointer::after, .fa-duotone.fa-hand-pointer::after {
	content: "\F25A\F25A"; }
  
  .fad.fa-drum-steelpan::after, .fa-duotone.fa-drum-steelpan::after {
	content: "\F56A\F56A"; }
  
  .fad.fa-hand-scissors::after, .fa-duotone.fa-hand-scissors::after {
	content: "\F257\F257"; }
  
  .fad.fa-hands-praying::after, .fa-duotone.fa-hands-praying::after {
	content: "\F684\F684"; }
  
  .fad.fa-praying-hands::after, .fa-duotone.fa-praying-hands::after {
	content: "\F684\F684"; }
  
  .fad.fa-face-pensive::after, .fa-duotone.fa-face-pensive::after {
	content: "\E384\E384"; }
  
  .fad.fa-user-music::after, .fa-duotone.fa-user-music::after {
	content: "\F8EB\F8EB"; }
  
  .fad.fa-arrow-rotate-right::after, .fa-duotone.fa-arrow-rotate-right::after {
	content: "\F01E\F01E"; }
  
  .fad.fa-arrow-right-rotate::after, .fa-duotone.fa-arrow-right-rotate::after {
	content: "\F01E\F01E"; }
  
  .fad.fa-arrow-rotate-forward::after, .fa-duotone.fa-arrow-rotate-forward::after {
	content: "\F01E\F01E"; }
  
  .fad.fa-redo::after, .fa-duotone.fa-redo::after {
	content: "\F01E\F01E"; }
  
  .fad.fa-messages-dollar::after, .fa-duotone.fa-messages-dollar::after {
	content: "\F652\F652"; }
  
  .fad.fa-comments-alt-dollar::after, .fa-duotone.fa-comments-alt-dollar::after {
	content: "\F652\F652"; }
  
  .fad.fa-sensor-on::after, .fa-duotone.fa-sensor-on::after {
	content: "\E02B\E02B"; }
  
  .fad.fa-balloon::after, .fa-duotone.fa-balloon::after {
	content: "\E2E3\E2E3"; }
  
  .fad.fa-biohazard::after, .fa-duotone.fa-biohazard::after {
	content: "\F780\F780"; }
  
  .fad.fa-chess-queen-piece::after, .fa-duotone.fa-chess-queen-piece::after {
	content: "\F446\F446"; }
  
  .fad.fa-chess-queen-alt::after, .fa-duotone.fa-chess-queen-alt::after {
	content: "\F446\F446"; }
  
  .fad.fa-location-crosshairs::after, .fa-duotone.fa-location-crosshairs::after {
	content: "\F601\F601"; }
  
  .fad.fa-location::after, .fa-duotone.fa-location::after {
	content: "\F601\F601"; }
  
  .fad.fa-mars-double::after, .fa-duotone.fa-mars-double::after {
	content: "\F227\F227"; }
  
  .fad.fa-house-person-leave::after, .fa-duotone.fa-house-person-leave::after {
	content: "\E00F\E00F"; }
  
  .fad.fa-house-leave::after, .fa-duotone.fa-house-leave::after {
	content: "\E00F\E00F"; }
  
  .fad.fa-house-person-depart::after, .fa-duotone.fa-house-person-depart::after {
	content: "\E00F\E00F"; }
  
  .fad.fa-ruler-triangle::after, .fa-duotone.fa-ruler-triangle::after {
	content: "\F61C\F61C"; }
  
  .fad.fa-card-club::after, .fa-duotone.fa-card-club::after {
	content: "\E3E9\E3E9"; }
  
  .fad.fa-child-dress::after, .fa-duotone.fa-child-dress::after {
	content: "\E59C\E59C"; }
  
  .fad.fa-users-between-lines::after, .fa-duotone.fa-users-between-lines::after {
	content: "\E591\E591"; }
  
  .fad.fa-lungs-virus::after, .fa-duotone.fa-lungs-virus::after {
	content: "\E067\E067"; }
  
  .fad.fa-spinner-third::after, .fa-duotone.fa-spinner-third::after {
	content: "\F3F4\F3F4"; }
  
  .fad.fa-face-grin-tears::after, .fa-duotone.fa-face-grin-tears::after {
	content: "\F588\F588"; }
  
  .fad.fa-grin-tears::after, .fa-duotone.fa-grin-tears::after {
	content: "\F588\F588"; }
  
  .fad.fa-phone::after, .fa-duotone.fa-phone::after {
	content: "\F095\F095"; }
  
  .fad.fa-computer-mouse-scrollwheel::after, .fa-duotone.fa-computer-mouse-scrollwheel::after {
	content: "\F8CD\F8CD"; }
  
  .fad.fa-mouse-alt::after, .fa-duotone.fa-mouse-alt::after {
	content: "\F8CD\F8CD"; }
  
  .fad.fa-calendar-xmark::after, .fa-duotone.fa-calendar-xmark::after {
	content: "\F273\F273"; }
  
  .fad.fa-calendar-times::after, .fa-duotone.fa-calendar-times::after {
	content: "\F273\F273"; }
  
  .fad.fa-child-reaching::after, .fa-duotone.fa-child-reaching::after {
	content: "\E59D\E59D"; }
  
  .fad.fa-table-layout::after, .fa-duotone.fa-table-layout::after {
	content: "\E290\E290"; }
  
  .fad.fa-narwhal::after, .fa-duotone.fa-narwhal::after {
	content: "\F6FE\F6FE"; }
  
  .fad.fa-ramp-loading::after, .fa-duotone.fa-ramp-loading::after {
	content: "\F4D4\F4D4"; }
  
  .fad.fa-calendar-circle-plus::after, .fa-duotone.fa-calendar-circle-plus::after {
	content: "\E470\E470"; }
  
  .fad.fa-toothbrush::after, .fa-duotone.fa-toothbrush::after {
	content: "\F635\F635"; }
  
  .fad.fa-border-inner::after, .fa-duotone.fa-border-inner::after {
	content: "\F84E\F84E"; }
  
  .fad.fa-paw-claws::after, .fa-duotone.fa-paw-claws::after {
	content: "\F702\F702"; }
  
  .fad.fa-kiwi-fruit::after, .fa-duotone.fa-kiwi-fruit::after {
	content: "\E30C\E30C"; }
  
  .fad.fa-traffic-light-slow::after, .fa-duotone.fa-traffic-light-slow::after {
	content: "\F639\F639"; }
  
  .fad.fa-rectangle-code::after, .fa-duotone.fa-rectangle-code::after {
	content: "\E322\E322"; }
  
  .fad.fa-head-side-virus::after, .fa-duotone.fa-head-side-virus::after {
	content: "\E064\E064"; }
  
  .fad.fa-keyboard-brightness::after, .fa-duotone.fa-keyboard-brightness::after {
	content: "\E1C0\E1C0"; }
  
  .fad.fa-books-medical::after, .fa-duotone.fa-books-medical::after {
	content: "\F7E8\F7E8"; }
  
  .fad.fa-lightbulb-slash::after, .fa-duotone.fa-lightbulb-slash::after {
	content: "\F673\F673"; }
  
  .fad.fa-house-blank::after, .fa-duotone.fa-house-blank::after {
	content: "\E487\E487"; }
  
  .fad.fa-home-blank::after, .fa-duotone.fa-home-blank::after {
	content: "\E487\E487"; }
  
  .fad.fa-square-5::after, .fa-duotone.fa-square-5::after {
	content: "\E25A\E25A"; }
  
  .fad.fa-square-heart::after, .fa-duotone.fa-square-heart::after {
	content: "\F4C8\F4C8"; }
  
  .fad.fa-heart-square::after, .fa-duotone.fa-heart-square::after {
	content: "\F4C8\F4C8"; }
  
  .fad.fa-puzzle::after, .fa-duotone.fa-puzzle::after {
	content: "\E443\E443"; }
  
  .fad.fa-user-gear::after, .fa-duotone.fa-user-gear::after {
	content: "\F4FE\F4FE"; }
  
  .fad.fa-user-cog::after, .fa-duotone.fa-user-cog::after {
	content: "\F4FE\F4FE"; }
  
  .fad.fa-pipe-circle-check::after, .fa-duotone.fa-pipe-circle-check::after {
	content: "\E436\E436"; }
  
  .fad.fa-arrow-up-1-9::after, .fa-duotone.fa-arrow-up-1-9::after {
	content: "\F163\F163"; }
  
  .fad.fa-sort-numeric-up::after, .fa-duotone.fa-sort-numeric-up::after {
	content: "\F163\F163"; }
  
  .fad.fa-octagon-exclamation::after, .fa-duotone.fa-octagon-exclamation::after {
	content: "\E204\E204"; }
  
  .fad.fa-dial-low::after, .fa-duotone.fa-dial-low::after {
	content: "\E15D\E15D"; }
  
  .fad.fa-door-closed::after, .fa-duotone.fa-door-closed::after {
	content: "\F52A\F52A"; }
  
  .fad.fa-laptop-mobile::after, .fa-duotone.fa-laptop-mobile::after {
	content: "\F87A\F87A"; }
  
  .fad.fa-phone-laptop::after, .fa-duotone.fa-phone-laptop::after {
	content: "\F87A\F87A"; }
  
  .fad.fa-conveyor-belt-boxes::after, .fa-duotone.fa-conveyor-belt-boxes::after {
	content: "\F46F\F46F"; }
  
  .fad.fa-conveyor-belt-alt::after, .fa-duotone.fa-conveyor-belt-alt::after {
	content: "\F46F\F46F"; }
  
  .fad.fa-shield-virus::after, .fa-duotone.fa-shield-virus::after {
	content: "\E06C\E06C"; }
  
  .fad.fa-starfighter-twin-ion-engine-advanced::after, .fa-duotone.fa-starfighter-twin-ion-engine-advanced::after {
	content: "\E28E\E28E"; }
  
  .fad.fa-starfighter-alt-advanced::after, .fa-duotone.fa-starfighter-alt-advanced::after {
	content: "\E28E\E28E"; }
  
  .fad.fa-dice-six::after, .fa-duotone.fa-dice-six::after {
	content: "\F526\F526"; }
  
  .fad.fa-starfighter-twin-ion-engine::after, .fa-duotone.fa-starfighter-twin-ion-engine::after {
	content: "\E038\E038"; }
  
  .fad.fa-starfighter-alt::after, .fa-duotone.fa-starfighter-alt::after {
	content: "\E038\E038"; }
  
  .fad.fa-rocket-launch::after, .fa-duotone.fa-rocket-launch::after {
	content: "\E027\E027"; }
  
  .fad.fa-mosquito-net::after, .fa-duotone.fa-mosquito-net::after {
	content: "\E52C\E52C"; }
  
  .fad.fa-vent-damper::after, .fa-duotone.fa-vent-damper::after {
	content: "\E465\E465"; }
  
  .fad.fa-bridge-water::after, .fa-duotone.fa-bridge-water::after {
	content: "\E4CE\E4CE"; }
  
  .fad.fa-ban-bug::after, .fa-duotone.fa-ban-bug::after {
	content: "\F7F9\F7F9"; }
  
  .fad.fa-debug::after, .fa-duotone.fa-debug::after {
	content: "\F7F9\F7F9"; }
  
  .fad.fa-person-booth::after, .fa-duotone.fa-person-booth::after {
	content: "\F756\F756"; }
  
  .fad.fa-text-width::after, .fa-duotone.fa-text-width::after {
	content: "\F035\F035"; }
  
  .fad.fa-garage-car::after, .fa-duotone.fa-garage-car::after {
	content: "\E00A\E00A"; }
  
  .fad.fa-square-kanban::after, .fa-duotone.fa-square-kanban::after {
	content: "\E488\E488"; }
  
  .fad.fa-hat-wizard::after, .fa-duotone.fa-hat-wizard::after {
	content: "\F6E8\F6E8"; }
  
  .fad.fa-pen-fancy::after, .fa-duotone.fa-pen-fancy::after {
	content: "\F5AC\F5AC"; }
  
  .fad.fa-coffee-pot::after, .fa-duotone.fa-coffee-pot::after {
	content: "\E002\E002"; }
  
  .fad.fa-mouse-field::after, .fa-duotone.fa-mouse-field::after {
	content: "\E5A8\E5A8"; }
  
  .fad.fa-person-digging::after, .fa-duotone.fa-person-digging::after {
	content: "\F85E\F85E"; }
  
  .fad.fa-digging::after, .fa-duotone.fa-digging::after {
	content: "\F85E\F85E"; }
  
  .fad.fa-shower-down::after, .fa-duotone.fa-shower-down::after {
	content: "\E24D\E24D"; }
  
  .fad.fa-shower-alt::after, .fa-duotone.fa-shower-alt::after {
	content: "\E24D\E24D"; }
  
  .fad.fa-box-circle-check::after, .fa-duotone.fa-box-circle-check::after {
	content: "\E0C4\E0C4"; }
  
  .fad.fa-brightness::after, .fa-duotone.fa-brightness::after {
	content: "\E0C9\E0C9"; }
  
  .fad.fa-car-side-bolt::after, .fa-duotone.fa-car-side-bolt::after {
	content: "\E344\E344"; }
  
  .fad.fa-ornament::after, .fa-duotone.fa-ornament::after {
	content: "\F7B8\F7B8"; }
  
  .fad.fa-phone-arrow-down-left::after, .fa-duotone.fa-phone-arrow-down-left::after {
	content: "\E223\E223"; }
  
  .fad.fa-phone-arrow-down::after, .fa-duotone.fa-phone-arrow-down::after {
	content: "\E223\E223"; }
  
  .fad.fa-phone-incoming::after, .fa-duotone.fa-phone-incoming::after {
	content: "\E223\E223"; }
  
  .fad.fa-cloud-word::after, .fa-duotone.fa-cloud-word::after {
	content: "\E138\E138"; }
  
  .fad.fa-hand-fingers-crossed::after, .fa-duotone.fa-hand-fingers-crossed::after {
	content: "\E1A3\E1A3"; }
  
  .fad.fa-trash::after, .fa-duotone.fa-trash::after {
	content: "\F1F8\F1F8"; }
  
  .fad.fa-gauge-simple::after, .fa-duotone.fa-gauge-simple::after {
	content: "\F629\F629"; }
  
  .fad.fa-gauge-simple-med::after, .fa-duotone.fa-gauge-simple-med::after {
	content: "\F629\F629"; }
  
  .fad.fa-tachometer-average::after, .fa-duotone.fa-tachometer-average::after {
	content: "\F629\F629"; }
  
  .fad.fa-arrow-down-small-big::after, .fa-duotone.fa-arrow-down-small-big::after {
	content: "\F88D\F88D"; }
  
  .fad.fa-sort-size-down-alt::after, .fa-duotone.fa-sort-size-down-alt::after {
	content: "\F88D\F88D"; }
  
  .fad.fa-book-medical::after, .fa-duotone.fa-book-medical::after {
	content: "\F7E6\F7E6"; }
  
  .fad.fa-face-melting::after, .fa-duotone.fa-face-melting::after {
	content: "\E483\E483"; }
  
  .fad.fa-poo::after, .fa-duotone.fa-poo::after {
	content: "\F2FE\F2FE"; }
  
  .fad.fa-pen-clip-slash::after, .fa-duotone.fa-pen-clip-slash::after {
	content: "\E20F\E20F"; }
  
  .fad.fa-pen-alt-slash::after, .fa-duotone.fa-pen-alt-slash::after {
	content: "\E20F\E20F"; }
  
  .fad.fa-quote-right::after, .fa-duotone.fa-quote-right::after {
	content: "\F10E\F10E"; }
  
  .fad.fa-quote-right-alt::after, .fa-duotone.fa-quote-right-alt::after {
	content: "\F10E\F10E"; }
  
  .fad.fa-scroll-old::after, .fa-duotone.fa-scroll-old::after {
	content: "\F70F\F70F"; }
  
  .fad.fa-guitars::after, .fa-duotone.fa-guitars::after {
	content: "\F8BF\F8BF"; }
  
  .fad.fa-phone-xmark::after, .fa-duotone.fa-phone-xmark::after {
	content: "\E227\E227"; }
  
  .fad.fa-hose::after, .fa-duotone.fa-hose::after {
	content: "\E419\E419"; }
  
  .fad.fa-clock-six::after, .fa-duotone.fa-clock-six::after {
	content: "\E352\E352"; }
  
  .fad.fa-shirt::after, .fa-duotone.fa-shirt::after {
	content: "\F553\F553"; }
  
  .fad.fa-t-shirt::after, .fa-duotone.fa-t-shirt::after {
	content: "\F553\F553"; }
  
  .fad.fa-tshirt::after, .fa-duotone.fa-tshirt::after {
	content: "\F553\F553"; }
  
  .fad.fa-square-r::after, .fa-duotone.fa-square-r::after {
	content: "\E27C\E27C"; }
  
  .fad.fa-cubes::after, .fa-duotone.fa-cubes::after {
	content: "\F1B3\F1B3"; }
  
  .fad.fa-envelope-open-dollar::after, .fa-duotone.fa-envelope-open-dollar::after {
	content: "\F657\F657"; }
  
  .fad.fa-divide::after, .fa-duotone.fa-divide::after {
	content: "\F529\F529"; }
  
  .fad.fa-sun-cloud::after, .fa-duotone.fa-sun-cloud::after {
	content: "\F763\F763"; }
  
  .fad.fa-lamp-floor::after, .fa-duotone.fa-lamp-floor::after {
	content: "\E015\E015"; }
  
  .fad.fa-square-7::after, .fa-duotone.fa-square-7::after {
	content: "\E25C\E25C"; }
  
  .fad.fa-tenge-sign::after, .fa-duotone.fa-tenge-sign::after {
	content: "\F7D7\F7D7"; }
  
  .fad.fa-tenge::after, .fa-duotone.fa-tenge::after {
	content: "\F7D7\F7D7"; }
  
  .fad.fa-headphones::after, .fa-duotone.fa-headphones::after {
	content: "\F025\F025"; }
  
  .fad.fa-hands-holding::after, .fa-duotone.fa-hands-holding::after {
	content: "\F4C2\F4C2"; }
  
  .fad.fa-campfire::after, .fa-duotone.fa-campfire::after {
	content: "\F6BA\F6BA"; }
  
  .fad.fa-circle-ampersand::after, .fa-duotone.fa-circle-ampersand::after {
	content: "\E0F8\E0F8"; }
  
  .fad.fa-snowflakes::after, .fa-duotone.fa-snowflakes::after {
	content: "\F7CF\F7CF"; }
  
  .fad.fa-hands-clapping::after, .fa-duotone.fa-hands-clapping::after {
	content: "\E1A8\E1A8"; }
  
  .fad.fa-republican::after, .fa-duotone.fa-republican::after {
	content: "\F75E\F75E"; }
  
  .fad.fa-leaf-maple::after, .fa-duotone.fa-leaf-maple::after {
	content: "\F6F6\F6F6"; }
  
  .fad.fa-arrow-left::after, .fa-duotone.fa-arrow-left::after {
	content: "\F060\F060"; }
  
  .fad.fa-person-circle-xmark::after, .fa-duotone.fa-person-circle-xmark::after {
	content: "\E543\E543"; }
  
  .fad.fa-ruler::after, .fa-duotone.fa-ruler::after {
	content: "\F545\F545"; }
  
  .fad.fa-cup-straw-swoosh::after, .fa-duotone.fa-cup-straw-swoosh::after {
	content: "\E364\E364"; }
  
  .fad.fa-temperature-sun::after, .fa-duotone.fa-temperature-sun::after {
	content: "\F76A\F76A"; }
  
  .fad.fa-temperature-hot::after, .fa-duotone.fa-temperature-hot::after {
	content: "\F76A\F76A"; }
  
  .fad.fa-align-left::after, .fa-duotone.fa-align-left::after {
	content: "\F036\F036"; }
  
  .fad.fa-dice-d6::after, .fa-duotone.fa-dice-d6::after {
	content: "\F6D1\F6D1"; }
  
  .fad.fa-restroom::after, .fa-duotone.fa-restroom::after {
	content: "\F7BD\F7BD"; }
  
  .fad.fa-high-definition::after, .fa-duotone.fa-high-definition::after {
	content: "\E1AE\E1AE"; }
  
  .fad.fa-rectangle-hd::after, .fa-duotone.fa-rectangle-hd::after {
	content: "\E1AE\E1AE"; }
  
  .fad.fa-j::after, .fa-duotone.fa-j::after {
	content: "JJ"; }
  
  .fad.fa-galaxy::after, .fa-duotone.fa-galaxy::after {
	content: "\E008\E008"; }
  
  .fad.fa-users-viewfinder::after, .fa-duotone.fa-users-viewfinder::after {
	content: "\E595\E595"; }
  
  .fad.fa-file-video::after, .fa-duotone.fa-file-video::after {
	content: "\F1C8\F1C8"; }
  
  .fad.fa-cherries::after, .fa-duotone.fa-cherries::after {
	content: "\E0EC\E0EC"; }
  
  .fad.fa-up-right-from-square::after, .fa-duotone.fa-up-right-from-square::after {
	content: "\F35D\F35D"; }
  
  .fad.fa-external-link-alt::after, .fa-duotone.fa-external-link-alt::after {
	content: "\F35D\F35D"; }
  
  .fad.fa-circle-sort::after, .fa-duotone.fa-circle-sort::after {
	content: "\E030\E030"; }
  
  .fad.fa-sort-circle::after, .fa-duotone.fa-sort-circle::after {
	content: "\E030\E030"; }
  
  .fad.fa-table-cells::after, .fa-duotone.fa-table-cells::after {
	content: "\F00A\F00A"; }
  
  .fad.fa-th::after, .fa-duotone.fa-th::after {
	content: "\F00A\F00A"; }
  
  .fad.fa-file-pdf::after, .fa-duotone.fa-file-pdf::after {
	content: "\F1C1\F1C1"; }
  
  .fad.fa-siren::after, .fa-duotone.fa-siren::after {
	content: "\E02D\E02D"; }
  
  .fad.fa-arrow-up-to-dotted-line::after, .fa-duotone.fa-arrow-up-to-dotted-line::after {
	content: "\E0A1\E0A1"; }
  
  .fad.fa-image-landscape::after, .fa-duotone.fa-image-landscape::after {
	content: "\E1B5\E1B5"; }
  
  .fad.fa-landscape::after, .fa-duotone.fa-landscape::after {
	content: "\E1B5\E1B5"; }
  
  .fad.fa-tank-water::after, .fa-duotone.fa-tank-water::after {
	content: "\E452\E452"; }
  
  .fad.fa-curling-stone::after, .fa-duotone.fa-curling-stone::after {
	content: "\F44A\F44A"; }
  
  .fad.fa-curling::after, .fa-duotone.fa-curling::after {
	content: "\F44A\F44A"; }
  
  .fad.fa-gamepad-modern::after, .fa-duotone.fa-gamepad-modern::after {
	content: "\E5A2\E5A2"; }
  
  .fad.fa-gamepad-alt::after, .fa-duotone.fa-gamepad-alt::after {
	content: "\E5A2\E5A2"; }
  
  .fad.fa-messages-question::after, .fa-duotone.fa-messages-question::after {
	content: "\E1E7\E1E7"; }
  
  .fad.fa-book-bible::after, .fa-duotone.fa-book-bible::after {
	content: "\F647\F647"; }
  
  .fad.fa-bible::after, .fa-duotone.fa-bible::after {
	content: "\F647\F647"; }
  
  .fad.fa-o::after, .fa-duotone.fa-o::after {
	content: "OO"; }
  
  .fad.fa-suitcase-medical::after, .fa-duotone.fa-suitcase-medical::after {
	content: "\F0FA\F0FA"; }
  
  .fad.fa-medkit::after, .fa-duotone.fa-medkit::after {
	content: "\F0FA\F0FA"; }
  
  .fad.fa-briefcase-arrow-right::after, .fa-duotone.fa-briefcase-arrow-right::after {
	content: "\E2F2\E2F2"; }
  
  .fad.fa-expand-wide::after, .fa-duotone.fa-expand-wide::after {
	content: "\F320\F320"; }
  
  .fad.fa-clock-eleven-thirty::after, .fa-duotone.fa-clock-eleven-thirty::after {
	content: "\E348\E348"; }
  
  .fad.fa-rv::after, .fa-duotone.fa-rv::after {
	content: "\F7BE\F7BE"; }
  
  .fad.fa-user-secret::after, .fa-duotone.fa-user-secret::after {
	content: "\F21B\F21B"; }
  
  .fad.fa-otter::after, .fa-duotone.fa-otter::after {
	content: "\F700\F700"; }
  
  .fad.fa-dreidel::after, .fa-duotone.fa-dreidel::after {
	content: "\F792\F792"; }
  
  .fad.fa-person-dress::after, .fa-duotone.fa-person-dress::after {
	content: "\F182\F182"; }
  
  .fad.fa-female::after, .fa-duotone.fa-female::after {
	content: "\F182\F182"; }
  
  .fad.fa-comment-dollar::after, .fa-duotone.fa-comment-dollar::after {
	content: "\F651\F651"; }
  
  .fad.fa-business-time::after, .fa-duotone.fa-business-time::after {
	content: "\F64A\F64A"; }
  
  .fad.fa-briefcase-clock::after, .fa-duotone.fa-briefcase-clock::after {
	content: "\F64A\F64A"; }
  
  .fad.fa-flower-tulip::after, .fa-duotone.fa-flower-tulip::after {
	content: "\F801\F801"; }
  
  .fad.fa-people-pants-simple::after, .fa-duotone.fa-people-pants-simple::after {
	content: "\E21A\E21A"; }
  
  .fad.fa-cloud-drizzle::after, .fa-duotone.fa-cloud-drizzle::after {
	content: "\F738\F738"; }
  
  .fad.fa-table-cells-large::after, .fa-duotone.fa-table-cells-large::after {
	content: "\F009\F009"; }
  
  .fad.fa-th-large::after, .fa-duotone.fa-th-large::after {
	content: "\F009\F009"; }
  
  .fad.fa-book-tanakh::after, .fa-duotone.fa-book-tanakh::after {
	content: "\F827\F827"; }
  
  .fad.fa-tanakh::after, .fa-duotone.fa-tanakh::after {
	content: "\F827\F827"; }
  
  .fad.fa-solar-system::after, .fa-duotone.fa-solar-system::after {
	content: "\E02F\E02F"; }
  
  .fad.fa-seal-question::after, .fa-duotone.fa-seal-question::after {
	content: "\E243\E243"; }
  
  .fad.fa-phone-volume::after, .fa-duotone.fa-phone-volume::after {
	content: "\F2A0\F2A0"; }
  
  .fad.fa-volume-control-phone::after, .fa-duotone.fa-volume-control-phone::after {
	content: "\F2A0\F2A0"; }
  
  .fad.fa-disc-drive::after, .fa-duotone.fa-disc-drive::after {
	content: "\F8B5\F8B5"; }
  
  .fad.fa-hat-cowboy-side::after, .fa-duotone.fa-hat-cowboy-side::after {
	content: "\F8C1\F8C1"; }
  
  .fad.fa-table-rows::after, .fa-duotone.fa-table-rows::after {
	content: "\E292\E292"; }
  
  .fad.fa-rows::after, .fa-duotone.fa-rows::after {
	content: "\E292\E292"; }
  
  .fad.fa-location-exclamation::after, .fa-duotone.fa-location-exclamation::after {
	content: "\F608\F608"; }
  
  .fad.fa-map-marker-exclamation::after, .fa-duotone.fa-map-marker-exclamation::after {
	content: "\F608\F608"; }
  
  .fad.fa-face-fearful::after, .fa-duotone.fa-face-fearful::after {
	content: "\E375\E375"; }
  
  .fad.fa-clipboard-user::after, .fa-duotone.fa-clipboard-user::after {
	content: "\F7F3\F7F3"; }
  
  .fad.fa-bus-school::after, .fa-duotone.fa-bus-school::after {
	content: "\F5DD\F5DD"; }
  
  .fad.fa-film-slash::after, .fa-duotone.fa-film-slash::after {
	content: "\E179\E179"; }
  
  .fad.fa-square-arrow-down-right::after, .fa-duotone.fa-square-arrow-down-right::after {
	content: "\E262\E262"; }
  
  .fad.fa-book-sparkles::after, .fa-duotone.fa-book-sparkles::after {
	content: "\F6B8\F6B8"; }
  
  .fad.fa-book-spells::after, .fa-duotone.fa-book-spells::after {
	content: "\F6B8\F6B8"; }
  
  .fad.fa-washing-machine::after, .fa-duotone.fa-washing-machine::after {
	content: "\F898\F898"; }
  
  .fad.fa-washer::after, .fa-duotone.fa-washer::after {
	content: "\F898\F898"; }
  
  .fad.fa-child::after, .fa-duotone.fa-child::after {
	content: "\F1AE\F1AE"; }
  
  .fad.fa-lira-sign::after, .fa-duotone.fa-lira-sign::after {
	content: "\F195\F195"; }
  
  .fad.fa-user-visor::after, .fa-duotone.fa-user-visor::after {
	content: "\E04C\E04C"; }
  
  .fad.fa-file-plus-minus::after, .fa-duotone.fa-file-plus-minus::after {
	content: "\E177\E177"; }
  
  .fad.fa-chess-clock-flip::after, .fa-duotone.fa-chess-clock-flip::after {
	content: "\F43E\F43E"; }
  
  .fad.fa-chess-clock-alt::after, .fa-duotone.fa-chess-clock-alt::after {
	content: "\F43E\F43E"; }
  
  .fad.fa-satellite::after, .fa-duotone.fa-satellite::after {
	content: "\F7BF\F7BF"; }
  
  .fad.fa-plane-lock::after, .fa-duotone.fa-plane-lock::after {
	content: "\E558\E558"; }
  
  .fad.fa-steering-wheel::after, .fa-duotone.fa-steering-wheel::after {
	content: "\F622\F622"; }
  
  .fad.fa-tag::after, .fa-duotone.fa-tag::after {
	content: "\F02B\F02B"; }
  
  .fad.fa-stretcher::after, .fa-duotone.fa-stretcher::after {
	content: "\F825\F825"; }
  
  .fad.fa-book-section::after, .fa-duotone.fa-book-section::after {
	content: "\E0C1\E0C1"; }
  
  .fad.fa-book-law::after, .fa-duotone.fa-book-law::after {
	content: "\E0C1\E0C1"; }
  
  .fad.fa-inboxes::after, .fa-duotone.fa-inboxes::after {
	content: "\E1BB\E1BB"; }
  
  .fad.fa-coffee-bean::after, .fa-duotone.fa-coffee-bean::after {
	content: "\E13E\E13E"; }
  
  .fad.fa-brackets-curly::after, .fa-duotone.fa-brackets-curly::after {
	content: "\F7EA\F7EA"; }
  
  .fad.fa-ellipsis-stroke-vertical::after, .fa-duotone.fa-ellipsis-stroke-vertical::after {
	content: "\F39C\F39C"; }
  
  .fad.fa-ellipsis-v-alt::after, .fa-duotone.fa-ellipsis-v-alt::after {
	content: "\F39C\F39C"; }
  
  .fad.fa-comment::after, .fa-duotone.fa-comment::after {
	content: "\F075\F075"; }
  
  .fad.fa-square-1::after, .fa-duotone.fa-square-1::after {
	content: "\E256\E256"; }
  
  .fad.fa-cake-candles::after, .fa-duotone.fa-cake-candles::after {
	content: "\F1FD\F1FD"; }
  
  .fad.fa-birthday-cake::after, .fa-duotone.fa-birthday-cake::after {
	content: "\F1FD\F1FD"; }
  
  .fad.fa-cake::after, .fa-duotone.fa-cake::after {
	content: "\F1FD\F1FD"; }
  
  .fad.fa-head-side::after, .fa-duotone.fa-head-side::after {
	content: "\F6E9\F6E9"; }
  
  .fad.fa-envelope::after, .fa-duotone.fa-envelope::after {
	content: "\F0E0\F0E0"; }
  
  .fad.fa-dolly-empty::after, .fa-duotone.fa-dolly-empty::after {
	content: "\F473\F473"; }
  
  .fad.fa-face-tissue::after, .fa-duotone.fa-face-tissue::after {
	content: "\E39C\E39C"; }
  
  .fad.fa-angles-up::after, .fa-duotone.fa-angles-up::after {
	content: "\F102\F102"; }
  
  .fad.fa-angle-double-up::after, .fa-duotone.fa-angle-double-up::after {
	content: "\F102\F102"; }
  
  .fad.fa-paperclip::after, .fa-duotone.fa-paperclip::after {
	content: "\F0C6\F0C6"; }
  
  .fad.fa-chart-line-down::after, .fa-duotone.fa-chart-line-down::after {
	content: "\F64D\F64D"; }
  
  .fad.fa-arrow-right-to-city::after, .fa-duotone.fa-arrow-right-to-city::after {
	content: "\E4B3\E4B3"; }
  
  .fad.fa-lock-a::after, .fa-duotone.fa-lock-a::after {
	content: "\E422\E422"; }
  
  .fad.fa-ribbon::after, .fa-duotone.fa-ribbon::after {
	content: "\F4D6\F4D6"; }
  
  .fad.fa-lungs::after, .fa-duotone.fa-lungs::after {
	content: "\F604\F604"; }
  
  .fad.fa-person-pinball::after, .fa-duotone.fa-person-pinball::after {
	content: "\E21D\E21D"; }
  
  .fad.fa-arrow-up-9-1::after, .fa-duotone.fa-arrow-up-9-1::after {
	content: "\F887\F887"; }
  
  .fad.fa-sort-numeric-up-alt::after, .fa-duotone.fa-sort-numeric-up-alt::after {
	content: "\F887\F887"; }
  
  .fad.fa-apple-core::after, .fa-duotone.fa-apple-core::after {
	content: "\E08F\E08F"; }
  
  .fad.fa-circle-y::after, .fa-duotone.fa-circle-y::after {
	content: "\E12F\E12F"; }
  
  .fad.fa-h6::after, .fa-duotone.fa-h6::after {
	content: "\E413\E413"; }
  
  .fad.fa-litecoin-sign::after, .fa-duotone.fa-litecoin-sign::after {
	content: "\E1D3\E1D3"; }
  
  .fad.fa-circle-small::after, .fa-duotone.fa-circle-small::after {
	content: "\E122\E122"; }
  
  .fad.fa-border-none::after, .fa-duotone.fa-border-none::after {
	content: "\F850\F850"; }
  
  .fad.fa-arrow-turn-down-left::after, .fa-duotone.fa-arrow-turn-down-left::after {
	content: "\E2E1\E2E1"; }
  
  .fad.fa-circle-nodes::after, .fa-duotone.fa-circle-nodes::after {
	content: "\E4E2\E4E2"; }
  
  .fad.fa-parachute-box::after, .fa-duotone.fa-parachute-box::after {
	content: "\F4CD\F4CD"; }
  
  .fad.fa-message-medical::after, .fa-duotone.fa-message-medical::after {
	content: "\F7F4\F7F4"; }
  
  .fad.fa-comment-alt-medical::after, .fa-duotone.fa-comment-alt-medical::after {
	content: "\F7F4\F7F4"; }
  
  .fad.fa-rugby-ball::after, .fa-duotone.fa-rugby-ball::after {
	content: "\E3C6\E3C6"; }
  
  .fad.fa-comment-music::after, .fa-duotone.fa-comment-music::after {
	content: "\F8B0\F8B0"; }
  
  .fad.fa-indent::after, .fa-duotone.fa-indent::after {
	content: "\F03C\F03C"; }
  
  .fad.fa-tree-deciduous::after, .fa-duotone.fa-tree-deciduous::after {
	content: "\F400\F400"; }
  
  .fad.fa-tree-alt::after, .fa-duotone.fa-tree-alt::after {
	content: "\F400\F400"; }
  
  .fad.fa-puzzle-piece-simple::after, .fa-duotone.fa-puzzle-piece-simple::after {
	content: "\E231\E231"; }
  
  .fad.fa-puzzle-piece-alt::after, .fa-duotone.fa-puzzle-piece-alt::after {
	content: "\E231\E231"; }
  
  .fad.fa-truck-field-un::after, .fa-duotone.fa-truck-field-un::after {
	content: "\E58E\E58E"; }
  
  .fad.fa-nfc-trash::after, .fa-duotone.fa-nfc-trash::after {
	content: "\E1FD\E1FD"; }
  
  .fad.fa-hourglass::after, .fa-duotone.fa-hourglass::after {
	content: "\F254\F254"; }
  
  .fad.fa-hourglass-empty::after, .fa-duotone.fa-hourglass-empty::after {
	content: "\F254\F254"; }
  
  .fad.fa-mountain::after, .fa-duotone.fa-mountain::after {
	content: "\F6FC\F6FC"; }
  
  .fad.fa-file-xmark::after, .fa-duotone.fa-file-xmark::after {
	content: "\F317\F317"; }
  
  .fad.fa-file-times::after, .fa-duotone.fa-file-times::after {
	content: "\F317\F317"; }
  
  .fad.fa-house-heart::after, .fa-duotone.fa-house-heart::after {
	content: "\F4C9\F4C9"; }
  
  .fad.fa-home-heart::after, .fa-duotone.fa-home-heart::after {
	content: "\F4C9\F4C9"; }
  
  .fad.fa-house-chimney-blank::after, .fa-duotone.fa-house-chimney-blank::after {
	content: "\E3B0\E3B0"; }
  
  .fad.fa-meter-bolt::after, .fa-duotone.fa-meter-bolt::after {
	content: "\E1E9\E1E9"; }
  
  .fad.fa-user-doctor::after, .fa-duotone.fa-user-doctor::after {
	content: "\F0F0\F0F0"; }
  
  .fad.fa-user-md::after, .fa-duotone.fa-user-md::after {
	content: "\F0F0\F0F0"; }
  
  .fad.fa-slash-back::after, .fa-duotone.fa-slash-back::after {
	content: "\\\\"; }
  
  .fad.fa-circle-info::after, .fa-duotone.fa-circle-info::after {
	content: "\F05A\F05A"; }
  
  .fad.fa-info-circle::after, .fa-duotone.fa-info-circle::after {
	content: "\F05A\F05A"; }
  
  .fad.fa-fishing-rod::after, .fa-duotone.fa-fishing-rod::after {
	content: "\E3A8\E3A8"; }
  
  .fad.fa-hammer-crash::after, .fa-duotone.fa-hammer-crash::after {
	content: "\E414\E414"; }
  
  .fad.fa-cloud-meatball::after, .fa-duotone.fa-cloud-meatball::after {
	content: "\F73B\F73B"; }
  
  .fad.fa-camera-polaroid::after, .fa-duotone.fa-camera-polaroid::after {
	content: "\F8AA\F8AA"; }
  
  .fad.fa-camera::after, .fa-duotone.fa-camera::after {
	content: "\F030\F030"; }
  
  .fad.fa-camera-alt::after, .fa-duotone.fa-camera-alt::after {
	content: "\F030\F030"; }
  
  .fad.fa-square-virus::after, .fa-duotone.fa-square-virus::after {
	content: "\E578\E578"; }
  
  .fad.fa-cart-arrow-up::after, .fa-duotone.fa-cart-arrow-up::after {
	content: "\E3EE\E3EE"; }
  
  .fad.fa-meteor::after, .fa-duotone.fa-meteor::after {
	content: "\F753\F753"; }
  
  .fad.fa-car-on::after, .fa-duotone.fa-car-on::after {
	content: "\E4DD\E4DD"; }
  
  .fad.fa-sleigh::after, .fa-duotone.fa-sleigh::after {
	content: "\F7CC\F7CC"; }
  
  .fad.fa-arrow-down-1-9::after, .fa-duotone.fa-arrow-down-1-9::after {
	content: "\F162\F162"; }
  
  .fad.fa-sort-numeric-asc::after, .fa-duotone.fa-sort-numeric-asc::after {
	content: "\F162\F162"; }
  
  .fad.fa-sort-numeric-down::after, .fa-duotone.fa-sort-numeric-down::after {
	content: "\F162\F162"; }
  
  .fad.fa-square-4::after, .fa-duotone.fa-square-4::after {
	content: "\E259\E259"; }
  
  .fad.fa-hand-holding-droplet::after, .fa-duotone.fa-hand-holding-droplet::after {
	content: "\F4C1\F4C1"; }
  
  .fad.fa-hand-holding-water::after, .fa-duotone.fa-hand-holding-water::after {
	content: "\F4C1\F4C1"; }
  
  .fad.fa-waveform::after, .fa-duotone.fa-waveform::after {
	content: "\F8F1\F8F1"; }
  
  .fad.fa-water::after, .fa-duotone.fa-water::after {
	content: "\F773\F773"; }
  
  .fad.fa-star-sharp-half-stroke::after, .fa-duotone.fa-star-sharp-half-stroke::after {
	content: "\E28D\E28D"; }
  
  .fad.fa-star-sharp-half-alt::after, .fa-duotone.fa-star-sharp-half-alt::after {
	content: "\E28D\E28D"; }
  
  .fad.fa-nfc-signal::after, .fa-duotone.fa-nfc-signal::after {
	content: "\E1FB\E1FB"; }
  
  .fad.fa-plane-prop::after, .fa-duotone.fa-plane-prop::after {
	content: "\E22B\E22B"; }
  
  .fad.fa-calendar-check::after, .fa-duotone.fa-calendar-check::after {
	content: "\F274\F274"; }
  
  .fad.fa-clock-desk::after, .fa-duotone.fa-clock-desk::after {
	content: "\E134\E134"; }
  
  .fad.fa-calendar-clock::after, .fa-duotone.fa-calendar-clock::after {
	content: "\E0D2\E0D2"; }
  
  .fad.fa-calendar-time::after, .fa-duotone.fa-calendar-time::after {
	content: "\E0D2\E0D2"; }
  
  .fad.fa-braille::after, .fa-duotone.fa-braille::after {
	content: "\F2A1\F2A1"; }
  
  .fad.fa-prescription-bottle-medical::after, .fa-duotone.fa-prescription-bottle-medical::after {
	content: "\F486\F486"; }
  
  .fad.fa-prescription-bottle-alt::after, .fa-duotone.fa-prescription-bottle-alt::after {
	content: "\F486\F486"; }
  
  .fad.fa-plate-utensils::after, .fa-duotone.fa-plate-utensils::after {
	content: "\E43B\E43B"; }
  
  .fad.fa-family-pants::after, .fa-duotone.fa-family-pants::after {
	content: "\E302\E302"; }
  
  .fad.fa-hose-reel::after, .fa-duotone.fa-hose-reel::after {
	content: "\E41A\E41A"; }
  
  .fad.fa-house-window::after, .fa-duotone.fa-house-window::after {
	content: "\E3B3\E3B3"; }
  
  .fad.fa-landmark::after, .fa-duotone.fa-landmark::after {
	content: "\F66F\F66F"; }
  
  .fad.fa-truck::after, .fa-duotone.fa-truck::after {
	content: "\F0D1\F0D1"; }
  
  .fad.fa-crosshairs::after, .fa-duotone.fa-crosshairs::after {
	content: "\F05B\F05B"; }
  
  .fad.fa-cloud-rainbow::after, .fa-duotone.fa-cloud-rainbow::after {
	content: "\F73E\F73E"; }
  
  .fad.fa-person-cane::after, .fa-duotone.fa-person-cane::after {
	content: "\E53C\E53C"; }
  
  .fad.fa-alien::after, .fa-duotone.fa-alien::after {
	content: "\F8F5\F8F5"; }
  
  .fad.fa-tent::after, .fa-duotone.fa-tent::after {
	content: "\E57D\E57D"; }
  
  .fad.fa-vest-patches::after, .fa-duotone.fa-vest-patches::after {
	content: "\E086\E086"; }
  
  .fad.fa-people-dress-simple::after, .fa-duotone.fa-people-dress-simple::after {
	content: "\E218\E218"; }
  
  .fad.fa-check-double::after, .fa-duotone.fa-check-double::after {
	content: "\F560\F560"; }
  
  .fad.fa-arrow-down-a-z::after, .fa-duotone.fa-arrow-down-a-z::after {
	content: "\F15D\F15D"; }
  
  .fad.fa-sort-alpha-asc::after, .fa-duotone.fa-sort-alpha-asc::after {
	content: "\F15D\F15D"; }
  
  .fad.fa-sort-alpha-down::after, .fa-duotone.fa-sort-alpha-down::after {
	content: "\F15D\F15D"; }
  
  .fad.fa-bowling-ball-pin::after, .fa-duotone.fa-bowling-ball-pin::after {
	content: "\E0C3\E0C3"; }
  
  .fad.fa-bell-school-slash::after, .fa-duotone.fa-bell-school-slash::after {
	content: "\F5D6\F5D6"; }
  
  .fad.fa-plus-large::after, .fa-duotone.fa-plus-large::after {
	content: "\E59E\E59E"; }
  
  .fad.fa-money-bill-wheat::after, .fa-duotone.fa-money-bill-wheat::after {
	content: "\E52A\E52A"; }
  
  .fad.fa-camera-viewfinder::after, .fa-duotone.fa-camera-viewfinder::after {
	content: "\E0DA\E0DA"; }
  
  .fad.fa-screenshot::after, .fa-duotone.fa-screenshot::after {
	content: "\E0DA\E0DA"; }
  
  .fad.fa-message-music::after, .fa-duotone.fa-message-music::after {
	content: "\F8AF\F8AF"; }
  
  .fad.fa-comment-alt-music::after, .fa-duotone.fa-comment-alt-music::after {
	content: "\F8AF\F8AF"; }
  
  .fad.fa-car-building::after, .fa-duotone.fa-car-building::after {
	content: "\F859\F859"; }
  
  .fad.fa-border-bottom-right::after, .fa-duotone.fa-border-bottom-right::after {
	content: "\F854\F854"; }
  
  .fad.fa-border-style-alt::after, .fa-duotone.fa-border-style-alt::after {
	content: "\F854\F854"; }
  
  .fad.fa-octagon::after, .fa-duotone.fa-octagon::after {
	content: "\F306\F306"; }
  
  .fad.fa-comment-arrow-up-right::after, .fa-duotone.fa-comment-arrow-up-right::after {
	content: "\E145\E145"; }
  
  .fad.fa-octagon-divide::after, .fa-duotone.fa-octagon-divide::after {
	content: "\E203\E203"; }
  
  .fad.fa-cookie::after, .fa-duotone.fa-cookie::after {
	content: "\F563\F563"; }
  
  .fad.fa-arrow-rotate-left::after, .fa-duotone.fa-arrow-rotate-left::after {
	content: "\F0E2\F0E2"; }
  
  .fad.fa-arrow-left-rotate::after, .fa-duotone.fa-arrow-left-rotate::after {
	content: "\F0E2\F0E2"; }
  
  .fad.fa-arrow-rotate-back::after, .fa-duotone.fa-arrow-rotate-back::after {
	content: "\F0E2\F0E2"; }
  
  .fad.fa-arrow-rotate-backward::after, .fa-duotone.fa-arrow-rotate-backward::after {
	content: "\F0E2\F0E2"; }
  
  .fad.fa-undo::after, .fa-duotone.fa-undo::after {
	content: "\F0E2\F0E2"; }
  
  .fad.fa-tv-music::after, .fa-duotone.fa-tv-music::after {
	content: "\F8E6\F8E6"; }
  
  .fad.fa-hard-drive::after, .fa-duotone.fa-hard-drive::after {
	content: "\F0A0\F0A0"; }
  
  .fad.fa-hdd::after, .fa-duotone.fa-hdd::after {
	content: "\F0A0\F0A0"; }
  
  .fad.fa-reel::after, .fa-duotone.fa-reel::after {
	content: "\E238\E238"; }
  
  .fad.fa-face-grin-squint-tears::after, .fa-duotone.fa-face-grin-squint-tears::after {
	content: "\F586\F586"; }
  
  .fad.fa-grin-squint-tears::after, .fa-duotone.fa-grin-squint-tears::after {
	content: "\F586\F586"; }
  
  .fad.fa-dumbbell::after, .fa-duotone.fa-dumbbell::after {
	content: "\F44B\F44B"; }
  
  .fad.fa-rectangle-list::after, .fa-duotone.fa-rectangle-list::after {
	content: "\F022\F022"; }
  
  .fad.fa-list-alt::after, .fa-duotone.fa-list-alt::after {
	content: "\F022\F022"; }
  
  .fad.fa-tarp-droplet::after, .fa-duotone.fa-tarp-droplet::after {
	content: "\E57C\E57C"; }
  
  .fad.fa-alarm-exclamation::after, .fa-duotone.fa-alarm-exclamation::after {
	content: "\F843\F843"; }
  
  .fad.fa-house-medical-circle-check::after, .fa-duotone.fa-house-medical-circle-check::after {
	content: "\E511\E511"; }
  
  .fad.fa-traffic-cone::after, .fa-duotone.fa-traffic-cone::after {
	content: "\F636\F636"; }
  
  .fad.fa-grate::after, .fa-duotone.fa-grate::after {
	content: "\E193\E193"; }
  
  .fad.fa-arrow-down-right::after, .fa-duotone.fa-arrow-down-right::after {
	content: "\E093\E093"; }
  
  .fad.fa-person-skiing-nordic::after, .fa-duotone.fa-person-skiing-nordic::after {
	content: "\F7CA\F7CA"; }
  
  .fad.fa-skiing-nordic::after, .fa-duotone.fa-skiing-nordic::after {
	content: "\F7CA\F7CA"; }
  
  .fad.fa-calendar-plus::after, .fa-duotone.fa-calendar-plus::after {
	content: "\F271\F271"; }
  
  .fad.fa-person-from-portal::after, .fa-duotone.fa-person-from-portal::after {
	content: "\E023\E023"; }
  
  .fad.fa-portal-exit::after, .fa-duotone.fa-portal-exit::after {
	content: "\E023\E023"; }
  
  .fad.fa-plane-arrival::after, .fa-duotone.fa-plane-arrival::after {
	content: "\F5AF\F5AF"; }
  
  .fad.fa-cowbell-circle-plus::after, .fa-duotone.fa-cowbell-circle-plus::after {
	content: "\F8B4\F8B4"; }
  
  .fad.fa-cowbell-more::after, .fa-duotone.fa-cowbell-more::after {
	content: "\F8B4\F8B4"; }
  
  .fad.fa-circle-left::after, .fa-duotone.fa-circle-left::after {
	content: "\F359\F359"; }
  
  .fad.fa-arrow-alt-circle-left::after, .fa-duotone.fa-arrow-alt-circle-left::after {
	content: "\F359\F359"; }
  
  .fad.fa-distribute-spacing-vertical::after, .fa-duotone.fa-distribute-spacing-vertical::after {
	content: "\E366\E366"; }
  
  .fad.fa-signal-bars-fair::after, .fa-duotone.fa-signal-bars-fair::after {
	content: "\F692\F692"; }
  
  .fad.fa-signal-alt-2::after, .fa-duotone.fa-signal-alt-2::after {
	content: "\F692\F692"; }
  
  .fad.fa-sportsball::after, .fa-duotone.fa-sportsball::after {
	content: "\E44B\E44B"; }
  
  .fad.fa-train-subway::after, .fa-duotone.fa-train-subway::after {
	content: "\F239\F239"; }
  
  .fad.fa-subway::after, .fa-duotone.fa-subway::after {
	content: "\F239\F239"; }
  
  .fad.fa-chart-gantt::after, .fa-duotone.fa-chart-gantt::after {
	content: "\E0E4\E0E4"; }
  
  .fad.fa-face-smile-upside-down::after, .fa-duotone.fa-face-smile-upside-down::after {
	content: "\E395\E395"; }
  
  .fad.fa-ball-pile::after, .fa-duotone.fa-ball-pile::after {
	content: "\F77E\F77E"; }
  
  .fad.fa-badge-dollar::after, .fa-duotone.fa-badge-dollar::after {
	content: "\F645\F645"; }
  
  .fad.fa-money-bills-simple::after, .fa-duotone.fa-money-bills-simple::after {
	content: "\E1F4\E1F4"; }
  
  .fad.fa-money-bills-alt::after, .fa-duotone.fa-money-bills-alt::after {
	content: "\E1F4\E1F4"; }
  
  .fad.fa-list-timeline::after, .fa-duotone.fa-list-timeline::after {
	content: "\E1D1\E1D1"; }
  
  .fad.fa-indian-rupee-sign::after, .fa-duotone.fa-indian-rupee-sign::after {
	content: "\E1BC\E1BC"; }
  
  .fad.fa-indian-rupee::after, .fa-duotone.fa-indian-rupee::after {
	content: "\E1BC\E1BC"; }
  
  .fad.fa-inr::after, .fa-duotone.fa-inr::after {
	content: "\E1BC\E1BC"; }
  
  .fad.fa-crop-simple::after, .fa-duotone.fa-crop-simple::after {
	content: "\F565\F565"; }
  
  .fad.fa-crop-alt::after, .fa-duotone.fa-crop-alt::after {
	content: "\F565\F565"; }
  
  .fad.fa-money-bill-1::after, .fa-duotone.fa-money-bill-1::after {
	content: "\F3D1\F3D1"; }
  
  .fad.fa-money-bill-alt::after, .fa-duotone.fa-money-bill-alt::after {
	content: "\F3D1\F3D1"; }
  
  .fad.fa-left-long::after, .fa-duotone.fa-left-long::after {
	content: "\F30A\F30A"; }
  
  .fad.fa-long-arrow-alt-left::after, .fa-duotone.fa-long-arrow-alt-left::after {
	content: "\F30A\F30A"; }
  
  .fad.fa-keyboard-down::after, .fa-duotone.fa-keyboard-down::after {
	content: "\E1C2\E1C2"; }
  
  .fad.fa-circle-up-right::after, .fa-duotone.fa-circle-up-right::after {
	content: "\E129\E129"; }
  
  .fad.fa-cloud-bolt-moon::after, .fa-duotone.fa-cloud-bolt-moon::after {
	content: "\F76D\F76D"; }
  
  .fad.fa-thunderstorm-moon::after, .fa-duotone.fa-thunderstorm-moon::after {
	content: "\F76D\F76D"; }
  
  .fad.fa-dna::after, .fa-duotone.fa-dna::after {
	content: "\F471\F471"; }
  
  .fad.fa-virus-slash::after, .fa-duotone.fa-virus-slash::after {
	content: "\E075\E075"; }
  
  .fad.fa-bracket-round-right::after, .fa-duotone.fa-bracket-round-right::after {
	content: "))"; }
  
  .fad.fa-circle-5::after, .fa-duotone.fa-circle-5::after {
	content: "\E0F2\E0F2"; }
  
  .fad.fa-minus::after, .fa-duotone.fa-minus::after {
	content: "\F068\F068"; }
  
  .fad.fa-subtract::after, .fa-duotone.fa-subtract::after {
	content: "\F068\F068"; }
  
  .fad.fa-fire-flame::after, .fa-duotone.fa-fire-flame::after {
	content: "\F6DF\F6DF"; }
  
  .fad.fa-flame::after, .fa-duotone.fa-flame::after {
	content: "\F6DF\F6DF"; }
  
  .fad.fa-right-to-line::after, .fa-duotone.fa-right-to-line::after {
	content: "\F34C\F34C"; }
  
  .fad.fa-arrow-alt-to-right::after, .fa-duotone.fa-arrow-alt-to-right::after {
	content: "\F34C\F34C"; }
  
  .fad.fa-child-rifle::after, .fa-duotone.fa-child-rifle::after {
	content: "\E4E0\E4E0"; }
  
  .fad.fa-gif::after, .fa-duotone.fa-gif::after {
	content: "\E190\E190"; }
  
  .fad.fa-chess::after, .fa-duotone.fa-chess::after {
	content: "\F439\F439"; }
  
  .fad.fa-trash-slash::after, .fa-duotone.fa-trash-slash::after {
	content: "\E2B3\E2B3"; }
  
  .fad.fa-arrow-left-long::after, .fa-duotone.fa-arrow-left-long::after {
	content: "\F177\F177"; }
  
  .fad.fa-long-arrow-left::after, .fa-duotone.fa-long-arrow-left::after {
	content: "\F177\F177"; }
  
  .fad.fa-plug-circle-check::after, .fa-duotone.fa-plug-circle-check::after {
	content: "\E55C\E55C"; }
  
  .fad.fa-font-case::after, .fa-duotone.fa-font-case::after {
	content: "\F866\F866"; }
  
  .fad.fa-street-view::after, .fa-duotone.fa-street-view::after {
	content: "\F21D\F21D"; }
  
  .fad.fa-arrow-down-left::after, .fa-duotone.fa-arrow-down-left::after {
	content: "\E091\E091"; }
  
  .fad.fa-franc-sign::after, .fa-duotone.fa-franc-sign::after {
	content: "\E18F\E18F"; }
  
  .fad.fa-flask-round-poison::after, .fa-duotone.fa-flask-round-poison::after {
	content: "\F6E0\F6E0"; }
  
  .fad.fa-flask-poison::after, .fa-duotone.fa-flask-poison::after {
	content: "\F6E0\F6E0"; }
  
  .fad.fa-volume-off::after, .fa-duotone.fa-volume-off::after {
	content: "\F026\F026"; }
  
  .fad.fa-book-circle-arrow-right::after, .fa-duotone.fa-book-circle-arrow-right::after {
	content: "\E0BC\E0BC"; }
  
  .fad.fa-chart-user::after, .fa-duotone.fa-chart-user::after {
	content: "\F6A3\F6A3"; }
  
  .fad.fa-user-chart::after, .fa-duotone.fa-user-chart::after {
	content: "\F6A3\F6A3"; }
  
  .fad.fa-hands-asl-interpreting::after, .fa-duotone.fa-hands-asl-interpreting::after {
	content: "\F2A3\F2A3"; }
  
  .fad.fa-american-sign-language-interpreting::after, .fa-duotone.fa-american-sign-language-interpreting::after {
	content: "\F2A3\F2A3"; }
  
  .fad.fa-asl-interpreting::after, .fa-duotone.fa-asl-interpreting::after {
	content: "\F2A3\F2A3"; }
  
  .fad.fa-hands-american-sign-language-interpreting::after, .fa-duotone.fa-hands-american-sign-language-interpreting::after {
	content: "\F2A3\F2A3"; }
  
  .fad.fa-presentation-screen::after, .fa-duotone.fa-presentation-screen::after {
	content: "\F685\F685"; }
  
  .fad.fa-presentation::after, .fa-duotone.fa-presentation::after {
	content: "\F685\F685"; }
  
  .fad.fa-circle-bolt::after, .fa-duotone.fa-circle-bolt::after {
	content: "\E0FE\E0FE"; }
  
  .fad.fa-face-smile-halo::after, .fa-duotone.fa-face-smile-halo::after {
	content: "\E38F\E38F"; }
  
  .fad.fa-cart-circle-arrow-down::after, .fa-duotone.fa-cart-circle-arrow-down::after {
	content: "\E3EF\E3EF"; }
  
  .fad.fa-house-person-return::after, .fa-duotone.fa-house-person-return::after {
	content: "\E011\E011"; }
  
  .fad.fa-house-person-arrive::after, .fa-duotone.fa-house-person-arrive::after {
	content: "\E011\E011"; }
  
  .fad.fa-house-return::after, .fa-duotone.fa-house-return::after {
	content: "\E011\E011"; }
  
  .fad.fa-message-xmark::after, .fa-duotone.fa-message-xmark::after {
	content: "\F4AB\F4AB"; }
  
  .fad.fa-comment-alt-times::after, .fa-duotone.fa-comment-alt-times::after {
	content: "\F4AB\F4AB"; }
  
  .fad.fa-message-times::after, .fa-duotone.fa-message-times::after {
	content: "\F4AB\F4AB"; }
  
  .fad.fa-file-certificate::after, .fa-duotone.fa-file-certificate::after {
	content: "\F5F3\F5F3"; }
  
  .fad.fa-file-award::after, .fa-duotone.fa-file-award::after {
	content: "\F5F3\F5F3"; }
  
  .fad.fa-user-doctor-hair-long::after, .fa-duotone.fa-user-doctor-hair-long::after {
	content: "\E459\E459"; }
  
  .fad.fa-camera-security::after, .fa-duotone.fa-camera-security::after {
	content: "\F8FE\F8FE"; }
  
  .fad.fa-camera-home::after, .fa-duotone.fa-camera-home::after {
	content: "\F8FE\F8FE"; }
  
  .fad.fa-gear::after, .fa-duotone.fa-gear::after {
	content: "\F013\F013"; }
  
  .fad.fa-cog::after, .fa-duotone.fa-cog::after {
	content: "\F013\F013"; }
  
  .fad.fa-droplet-slash::after, .fa-duotone.fa-droplet-slash::after {
	content: "\F5C7\F5C7"; }
  
  .fad.fa-tint-slash::after, .fa-duotone.fa-tint-slash::after {
	content: "\F5C7\F5C7"; }
  
  .fad.fa-book-heart::after, .fa-duotone.fa-book-heart::after {
	content: "\F499\F499"; }
  
  .fad.fa-mosque::after, .fa-duotone.fa-mosque::after {
	content: "\F678\F678"; }
  
  .fad.fa-duck::after, .fa-duotone.fa-duck::after {
	content: "\F6D8\F6D8"; }
  
  .fad.fa-mosquito::after, .fa-duotone.fa-mosquito::after {
	content: "\E52B\E52B"; }
  
  .fad.fa-star-of-david::after, .fa-duotone.fa-star-of-david::after {
	content: "\F69A\F69A"; }
  
  .fad.fa-flag-swallowtail::after, .fa-duotone.fa-flag-swallowtail::after {
	content: "\F74C\F74C"; }
  
  .fad.fa-flag-alt::after, .fa-duotone.fa-flag-alt::after {
	content: "\F74C\F74C"; }
  
  .fad.fa-person-military-rifle::after, .fa-duotone.fa-person-military-rifle::after {
	content: "\E54B\E54B"; }
  
  .fad.fa-car-garage::after, .fa-duotone.fa-car-garage::after {
	content: "\F5E2\F5E2"; }
  
  .fad.fa-cart-shopping::after, .fa-duotone.fa-cart-shopping::after {
	content: "\F07A\F07A"; }
  
  .fad.fa-shopping-cart::after, .fa-duotone.fa-shopping-cart::after {
	content: "\F07A\F07A"; }
  
  .fad.fa-book-font::after, .fa-duotone.fa-book-font::after {
	content: "\E0BF\E0BF"; }
  
  .fad.fa-shield-plus::after, .fa-duotone.fa-shield-plus::after {
	content: "\E24A\E24A"; }
  
  .fad.fa-vials::after, .fa-duotone.fa-vials::after {
	content: "\F493\F493"; }
  
  .fad.fa-eye-dropper-full::after, .fa-duotone.fa-eye-dropper-full::after {
	content: "\E172\E172"; }
  
  .fad.fa-distribute-spacing-horizontal::after, .fa-duotone.fa-distribute-spacing-horizontal::after {
	content: "\E365\E365"; }
  
  .fad.fa-tablet-rugged::after, .fa-duotone.fa-tablet-rugged::after {
	content: "\F48F\F48F"; }
  
  .fad.fa-temperature-snow::after, .fa-duotone.fa-temperature-snow::after {
	content: "\F768\F768"; }
  
  .fad.fa-temperature-frigid::after, .fa-duotone.fa-temperature-frigid::after {
	content: "\F768\F768"; }
  
  .fad.fa-moped::after, .fa-duotone.fa-moped::after {
	content: "\E3B9\E3B9"; }
  
  .fad.fa-face-smile-plus::after, .fa-duotone.fa-face-smile-plus::after {
	content: "\F5B9\F5B9"; }
  
  .fad.fa-smile-plus::after, .fa-duotone.fa-smile-plus::after {
	content: "\F5B9\F5B9"; }
  
  .fad.fa-radio-tuner::after, .fa-duotone.fa-radio-tuner::after {
	content: "\F8D8\F8D8"; }
  
  .fad.fa-radio-alt::after, .fa-duotone.fa-radio-alt::after {
	content: "\F8D8\F8D8"; }
  
  .fad.fa-face-swear::after, .fa-duotone.fa-face-swear::after {
	content: "\E399\E399"; }
  
  .fad.fa-water-arrow-down::after, .fa-duotone.fa-water-arrow-down::after {
	content: "\F774\F774"; }
  
  .fad.fa-water-lower::after, .fa-duotone.fa-water-lower::after {
	content: "\F774\F774"; }
  
  .fad.fa-scanner-touchscreen::after, .fa-duotone.fa-scanner-touchscreen::after {
	content: "\F48A\F48A"; }
  
  .fad.fa-circle-7::after, .fa-duotone.fa-circle-7::after {
	content: "\E0F4\E0F4"; }
  
  .fad.fa-plug-circle-plus::after, .fa-duotone.fa-plug-circle-plus::after {
	content: "\E55F\E55F"; }
  
  .fad.fa-person-ski-jumping::after, .fa-duotone.fa-person-ski-jumping::after {
	content: "\F7C7\F7C7"; }
  
  .fad.fa-ski-jump::after, .fa-duotone.fa-ski-jump::after {
	content: "\F7C7\F7C7"; }
  
  .fad.fa-place-of-worship::after, .fa-duotone.fa-place-of-worship::after {
	content: "\F67F\F67F"; }
  
  .fad.fa-water-arrow-up::after, .fa-duotone.fa-water-arrow-up::after {
	content: "\F775\F775"; }
  
  .fad.fa-water-rise::after, .fa-duotone.fa-water-rise::after {
	content: "\F775\F775"; }
  
  .fad.fa-waveform-lines::after, .fa-duotone.fa-waveform-lines::after {
	content: "\F8F2\F8F2"; }
  
  .fad.fa-waveform-path::after, .fa-duotone.fa-waveform-path::after {
	content: "\F8F2\F8F2"; }
  
  .fad.fa-split::after, .fa-duotone.fa-split::after {
	content: "\E254\E254"; }
  
  .fad.fa-film-canister::after, .fa-duotone.fa-film-canister::after {
	content: "\F8B7\F8B7"; }
  
  .fad.fa-film-cannister::after, .fa-duotone.fa-film-cannister::after {
	content: "\F8B7\F8B7"; }
  
  .fad.fa-folder-xmark::after, .fa-duotone.fa-folder-xmark::after {
	content: "\F65F\F65F"; }
  
  .fad.fa-folder-times::after, .fa-duotone.fa-folder-times::after {
	content: "\F65F\F65F"; }
  
  .fad.fa-toilet-paper-blank::after, .fa-duotone.fa-toilet-paper-blank::after {
	content: "\F71F\F71F"; }
  
  .fad.fa-toilet-paper-alt::after, .fa-duotone.fa-toilet-paper-alt::after {
	content: "\F71F\F71F"; }
  
  .fad.fa-tablet-screen::after, .fa-duotone.fa-tablet-screen::after {
	content: "\F3FC\F3FC"; }
  
  .fad.fa-tablet-android-alt::after, .fa-duotone.fa-tablet-android-alt::after {
	content: "\F3FC\F3FC"; }
  
  .fad.fa-hexagon-vertical-nft-slanted::after, .fa-duotone.fa-hexagon-vertical-nft-slanted::after {
	content: "\E506\E506"; }
  
  .fad.fa-folder-music::after, .fa-duotone.fa-folder-music::after {
	content: "\E18D\E18D"; }
  
  .fad.fa-display-medical::after, .fa-duotone.fa-display-medical::after {
	content: "\E166\E166"; }
  
  .fad.fa-desktop-medical::after, .fa-duotone.fa-desktop-medical::after {
	content: "\E166\E166"; }
  
  .fad.fa-share-all::after, .fa-duotone.fa-share-all::after {
	content: "\F367\F367"; }
  
  .fad.fa-peapod::after, .fa-duotone.fa-peapod::after {
	content: "\E31C\E31C"; }
  
  .fad.fa-chess-clock::after, .fa-duotone.fa-chess-clock::after {
	content: "\F43D\F43D"; }
  
  .fad.fa-axe::after, .fa-duotone.fa-axe::after {
	content: "\F6B2\F6B2"; }
  
  .fad.fa-square-d::after, .fa-duotone.fa-square-d::after {
	content: "\E268\E268"; }
  
  .fad.fa-grip-vertical::after, .fa-duotone.fa-grip-vertical::after {
	content: "\F58E\F58E"; }
  
  .fad.fa-mobile-signal-out::after, .fa-duotone.fa-mobile-signal-out::after {
	content: "\E1F0\E1F0"; }
  
  .fad.fa-arrow-turn-up::after, .fa-duotone.fa-arrow-turn-up::after {
	content: "\F148\F148"; }
  
  .fad.fa-level-up::after, .fa-duotone.fa-level-up::after {
	content: "\F148\F148"; }
  
  .fad.fa-u::after, .fa-duotone.fa-u::after {
	content: "UU"; }
  
  .fad.fa-arrow-up-from-dotted-line::after, .fa-duotone.fa-arrow-up-from-dotted-line::after {
	content: "\E09B\E09B"; }
  
  .fad.fa-square-root-variable::after, .fa-duotone.fa-square-root-variable::after {
	content: "\F698\F698"; }
  
  .fad.fa-square-root-alt::after, .fa-duotone.fa-square-root-alt::after {
	content: "\F698\F698"; }
  
  .fad.fa-light-switch-on::after, .fa-duotone.fa-light-switch-on::after {
	content: "\E019\E019"; }
  
  .fad.fa-arrow-down-arrow-up::after, .fa-duotone.fa-arrow-down-arrow-up::after {
	content: "\F883\F883"; }
  
  .fad.fa-sort-alt::after, .fa-duotone.fa-sort-alt::after {
	content: "\F883\F883"; }
  
  .fad.fa-raindrops::after, .fa-duotone.fa-raindrops::after {
	content: "\F75C\F75C"; }
  
  .fad.fa-dash::after, .fa-duotone.fa-dash::after {
	content: "\E404\E404"; }
  
  .fad.fa-minus-large::after, .fa-duotone.fa-minus-large::after {
	content: "\E404\E404"; }
  
  .fad.fa-clock::after, .fa-duotone.fa-clock::after {
	content: "\F017\F017"; }
  
  .fad.fa-clock-four::after, .fa-duotone.fa-clock-four::after {
	content: "\F017\F017"; }
  
  .fad.fa-input-numeric::after, .fa-duotone.fa-input-numeric::after {
	content: "\E1BD\E1BD"; }
  
  .fad.fa-truck-tow::after, .fa-duotone.fa-truck-tow::after {
	content: "\E2B8\E2B8"; }
  
  .fad.fa-backward-step::after, .fa-duotone.fa-backward-step::after {
	content: "\F048\F048"; }
  
  .fad.fa-step-backward::after, .fa-duotone.fa-step-backward::after {
	content: "\F048\F048"; }
  
  .fad.fa-pallet::after, .fa-duotone.fa-pallet::after {
	content: "\F482\F482"; }
  
  .fad.fa-car-bolt::after, .fa-duotone.fa-car-bolt::after {
	content: "\E341\E341"; }
  
  .fad.fa-arrows-maximize::after, .fa-duotone.fa-arrows-maximize::after {
	content: "\F31D\F31D"; }
  
  .fad.fa-expand-arrows::after, .fa-duotone.fa-expand-arrows::after {
	content: "\F31D\F31D"; }
  
  .fad.fa-faucet::after, .fa-duotone.fa-faucet::after {
	content: "\E005\E005"; }
  
  .fad.fa-cloud-sleet::after, .fa-duotone.fa-cloud-sleet::after {
	content: "\F741\F741"; }
  
  .fad.fa-lamp-street::after, .fa-duotone.fa-lamp-street::after {
	content: "\E1C5\E1C5"; }
  
  .fad.fa-list-radio::after, .fa-duotone.fa-list-radio::after {
	content: "\E1D0\E1D0"; }
  
  .fad.fa-pen-nib-slash::after, .fa-duotone.fa-pen-nib-slash::after {
	content: "\E4A1\E4A1"; }
  
  .fad.fa-baseball-bat-ball::after, .fa-duotone.fa-baseball-bat-ball::after {
	content: "\F432\F432"; }
  
  .fad.fa-square-up-left::after, .fa-duotone.fa-square-up-left::after {
	content: "\E282\E282"; }
  
  .fad.fa-overline::after, .fa-duotone.fa-overline::after {
	content: "\F876\F876"; }
  
  .fad.fa-s::after, .fa-duotone.fa-s::after {
	content: "SS"; }
  
  .fad.fa-timeline::after, .fa-duotone.fa-timeline::after {
	content: "\E29C\E29C"; }
  
  .fad.fa-keyboard::after, .fa-duotone.fa-keyboard::after {
	content: "\F11C\F11C"; }
  
  .fad.fa-arrows-from-dotted-line::after, .fa-duotone.fa-arrows-from-dotted-line::after {
	content: "\E0A3\E0A3"; }
  
  .fad.fa-usb-drive::after, .fa-duotone.fa-usb-drive::after {
	content: "\F8E9\F8E9"; }
  
  .fad.fa-ballot::after, .fa-duotone.fa-ballot::after {
	content: "\F732\F732"; }
  
  .fad.fa-caret-down::after, .fa-duotone.fa-caret-down::after {
	content: "\F0D7\F0D7"; }
  
  .fad.fa-location-dot-slash::after, .fa-duotone.fa-location-dot-slash::after {
	content: "\F605\F605"; }
  
  .fad.fa-map-marker-alt-slash::after, .fa-duotone.fa-map-marker-alt-slash::after {
	content: "\F605\F605"; }
  
  .fad.fa-cards::after, .fa-duotone.fa-cards::after {
	content: "\E3ED\E3ED"; }
  
  .fad.fa-house-chimney-medical::after, .fa-duotone.fa-house-chimney-medical::after {
	content: "\F7F2\F7F2"; }
  
  .fad.fa-clinic-medical::after, .fa-duotone.fa-clinic-medical::after {
	content: "\F7F2\F7F2"; }
  
  .fad.fa-boxing-glove::after, .fa-duotone.fa-boxing-glove::after {
	content: "\F438\F438"; }
  
  .fad.fa-glove-boxing::after, .fa-duotone.fa-glove-boxing::after {
	content: "\F438\F438"; }
  
  .fad.fa-temperature-three-quarters::after, .fa-duotone.fa-temperature-three-quarters::after {
	content: "\F2C8\F2C8"; }
  
  .fad.fa-temperature-3::after, .fa-duotone.fa-temperature-3::after {
	content: "\F2C8\F2C8"; }
  
  .fad.fa-thermometer-3::after, .fa-duotone.fa-thermometer-3::after {
	content: "\F2C8\F2C8"; }
  
  .fad.fa-thermometer-three-quarters::after, .fa-duotone.fa-thermometer-three-quarters::after {
	content: "\F2C8\F2C8"; }
  
  .fad.fa-bell-school::after, .fa-duotone.fa-bell-school::after {
	content: "\F5D5\F5D5"; }
  
  .fad.fa-mobile-screen::after, .fa-duotone.fa-mobile-screen::after {
	content: "\F3CF\F3CF"; }
  
  .fad.fa-mobile-android-alt::after, .fa-duotone.fa-mobile-android-alt::after {
	content: "\F3CF\F3CF"; }
  
  .fad.fa-plane-up::after, .fa-duotone.fa-plane-up::after {
	content: "\E22D\E22D"; }
  
  .fad.fa-folder-heart::after, .fa-duotone.fa-folder-heart::after {
	content: "\E189\E189"; }
  
  .fad.fa-circle-location-arrow::after, .fa-duotone.fa-circle-location-arrow::after {
	content: "\F602\F602"; }
  
  .fad.fa-location-circle::after, .fa-duotone.fa-location-circle::after {
	content: "\F602\F602"; }
  
  .fad.fa-face-head-bandage::after, .fa-duotone.fa-face-head-bandage::after {
	content: "\E37A\E37A"; }
  
  .fad.fa-sushi-roll::after, .fa-duotone.fa-sushi-roll::after {
	content: "\E48B\E48B"; }
  
  .fad.fa-maki-roll::after, .fa-duotone.fa-maki-roll::after {
	content: "\E48B\E48B"; }
  
  .fad.fa-makizushi::after, .fa-duotone.fa-makizushi::after {
	content: "\E48B\E48B"; }
  
  .fad.fa-car-bump::after, .fa-duotone.fa-car-bump::after {
	content: "\F5E0\F5E0"; }
  
  .fad.fa-piggy-bank::after, .fa-duotone.fa-piggy-bank::after {
	content: "\F4D3\F4D3"; }
  
  .fad.fa-racquet::after, .fa-duotone.fa-racquet::after {
	content: "\F45A\F45A"; }
  
  .fad.fa-car-mirrors::after, .fa-duotone.fa-car-mirrors::after {
	content: "\E343\E343"; }
  
  .fad.fa-industry-windows::after, .fa-duotone.fa-industry-windows::after {
	content: "\F3B3\F3B3"; }
  
  .fad.fa-industry-alt::after, .fa-duotone.fa-industry-alt::after {
	content: "\F3B3\F3B3"; }
  
  .fad.fa-bolt-auto::after, .fa-duotone.fa-bolt-auto::after {
	content: "\E0B6\E0B6"; }
  
  .fad.fa-battery-half::after, .fa-duotone.fa-battery-half::after {
	content: "\F242\F242"; }
  
  .fad.fa-battery-3::after, .fa-duotone.fa-battery-3::after {
	content: "\F242\F242"; }
  
  .fad.fa-flux-capacitor::after, .fa-duotone.fa-flux-capacitor::after {
	content: "\F8BA\F8BA"; }
  
  .fad.fa-mountain-city::after, .fa-duotone.fa-mountain-city::after {
	content: "\E52E\E52E"; }
  
  .fad.fa-coins::after, .fa-duotone.fa-coins::after {
	content: "\F51E\F51E"; }
  
  .fad.fa-honey-pot::after, .fa-duotone.fa-honey-pot::after {
	content: "\E418\E418"; }
  
  .fad.fa-olive::after, .fa-duotone.fa-olive::after {
	content: "\E316\E316"; }
  
  .fad.fa-khanda::after, .fa-duotone.fa-khanda::after {
	content: "\F66D\F66D"; }
  
  .fad.fa-filter-list::after, .fa-duotone.fa-filter-list::after {
	content: "\E17C\E17C"; }
  
  .fad.fa-outlet::after, .fa-duotone.fa-outlet::after {
	content: "\E01C\E01C"; }
  
  .fad.fa-sliders::after, .fa-duotone.fa-sliders::after {
	content: "\F1DE\F1DE"; }
  
  .fad.fa-sliders-h::after, .fa-duotone.fa-sliders-h::after {
	content: "\F1DE\F1DE"; }
  
  .fad.fa-cauldron::after, .fa-duotone.fa-cauldron::after {
	content: "\F6BF\F6BF"; }
  
  .fad.fa-people::after, .fa-duotone.fa-people::after {
	content: "\E216\E216"; }
  
  .fad.fa-folder-tree::after, .fa-duotone.fa-folder-tree::after {
	content: "\F802\F802"; }
  
  .fad.fa-network-wired::after, .fa-duotone.fa-network-wired::after {
	content: "\F6FF\F6FF"; }
  
  .fad.fa-croissant::after, .fa-duotone.fa-croissant::after {
	content: "\F7F6\F7F6"; }
  
  .fad.fa-map-pin::after, .fa-duotone.fa-map-pin::after {
	content: "\F276\F276"; }
  
  .fad.fa-hamsa::after, .fa-duotone.fa-hamsa::after {
	content: "\F665\F665"; }
  
  .fad.fa-cent-sign::after, .fa-duotone.fa-cent-sign::after {
	content: "\E3F5\E3F5"; }
  
  .fad.fa-swords-laser::after, .fa-duotone.fa-swords-laser::after {
	content: "\E03D\E03D"; }
  
  .fad.fa-flask::after, .fa-duotone.fa-flask::after {
	content: "\F0C3\F0C3"; }
  
  .fad.fa-person-pregnant::after, .fa-duotone.fa-person-pregnant::after {
	content: "\E31E\E31E"; }
  
  .fad.fa-square-u::after, .fa-duotone.fa-square-u::after {
	content: "\E281\E281"; }
  
  .fad.fa-wand-sparkles::after, .fa-duotone.fa-wand-sparkles::after {
	content: "\F72B\F72B"; }
  
  .fad.fa-router::after, .fa-duotone.fa-router::after {
	content: "\F8DA\F8DA"; }
  
  .fad.fa-ellipsis-vertical::after, .fa-duotone.fa-ellipsis-vertical::after {
	content: "\F142\F142"; }
  
  .fad.fa-ellipsis-v::after, .fa-duotone.fa-ellipsis-v::after {
	content: "\F142\F142"; }
  
  .fad.fa-sword-laser-alt::after, .fa-duotone.fa-sword-laser-alt::after {
	content: "\E03C\E03C"; }
  
  .fad.fa-ticket::after, .fa-duotone.fa-ticket::after {
	content: "\F145\F145"; }
  
  .fad.fa-power-off::after, .fa-duotone.fa-power-off::after {
	content: "\F011\F011"; }
  
  .fad.fa-coin::after, .fa-duotone.fa-coin::after {
	content: "\F85C\F85C"; }
  
  .fad.fa-laptop-slash::after, .fa-duotone.fa-laptop-slash::after {
	content: "\E1C7\E1C7"; }
  
  .fad.fa-right-long::after, .fa-duotone.fa-right-long::after {
	content: "\F30B\F30B"; }
  
  .fad.fa-long-arrow-alt-right::after, .fa-duotone.fa-long-arrow-alt-right::after {
	content: "\F30B\F30B"; }
  
  .fad.fa-circle-b::after, .fa-duotone.fa-circle-b::after {
	content: "\E0FD\E0FD"; }
  
  .fad.fa-person-dress-simple::after, .fa-duotone.fa-person-dress-simple::after {
	content: "\E21C\E21C"; }
  
  .fad.fa-pipe-collar::after, .fa-duotone.fa-pipe-collar::after {
	content: "\E437\E437"; }
  
  .fad.fa-lights-holiday::after, .fa-duotone.fa-lights-holiday::after {
	content: "\F7B2\F7B2"; }
  
  .fad.fa-citrus::after, .fa-duotone.fa-citrus::after {
	content: "\E2F4\E2F4"; }
  
  .fad.fa-flag-usa::after, .fa-duotone.fa-flag-usa::after {
	content: "\F74D\F74D"; }
  
  .fad.fa-laptop-file::after, .fa-duotone.fa-laptop-file::after {
	content: "\E51D\E51D"; }
  
  .fad.fa-tty::after, .fa-duotone.fa-tty::after {
	content: "\F1E4\F1E4"; }
  
  .fad.fa-teletype::after, .fa-duotone.fa-teletype::after {
	content: "\F1E4\F1E4"; }
  
  .fad.fa-chart-tree-map::after, .fa-duotone.fa-chart-tree-map::after {
	content: "\E0EA\E0EA"; }
  
  .fad.fa-diagram-next::after, .fa-duotone.fa-diagram-next::after {
	content: "\E476\E476"; }
  
  .fad.fa-person-rifle::after, .fa-duotone.fa-person-rifle::after {
	content: "\E54E\E54E"; }
  
  .fad.fa-clock-five-thirty::after, .fa-duotone.fa-clock-five-thirty::after {
	content: "\E34A\E34A"; }
  
  .fad.fa-pipe-valve::after, .fa-duotone.fa-pipe-valve::after {
	content: "\E439\E439"; }
  
  .fad.fa-arrow-up-from-arc::after, .fa-duotone.fa-arrow-up-from-arc::after {
	content: "\E4B4\E4B4"; }
  
  .fad.fa-face-spiral-eyes::after, .fa-duotone.fa-face-spiral-eyes::after {
	content: "\E485\E485"; }
  
  .fad.fa-compress-wide::after, .fa-duotone.fa-compress-wide::after {
	content: "\F326\F326"; }
  
  .fad.fa-circle-phone-hangup::after, .fa-duotone.fa-circle-phone-hangup::after {
	content: "\E11D\E11D"; }
  
  .fad.fa-phone-circle-down::after, .fa-duotone.fa-phone-circle-down::after {
	content: "\E11D\E11D"; }
  
  .fad.fa-house-medical-circle-exclamation::after, .fa-duotone.fa-house-medical-circle-exclamation::after {
	content: "\E512\E512"; }
  
  .fad.fa-badminton::after, .fa-duotone.fa-badminton::after {
	content: "\E33A\E33A"; }
  
  .fad.fa-closed-captioning::after, .fa-duotone.fa-closed-captioning::after {
	content: "\F20A\F20A"; }
  
  .fad.fa-person-hiking::after, .fa-duotone.fa-person-hiking::after {
	content: "\F6EC\F6EC"; }
  
  .fad.fa-hiking::after, .fa-duotone.fa-hiking::after {
	content: "\F6EC\F6EC"; }
  
  .fad.fa-right-from-line::after, .fa-duotone.fa-right-from-line::after {
	content: "\F347\F347"; }
  
  .fad.fa-arrow-alt-from-left::after, .fa-duotone.fa-arrow-alt-from-left::after {
	content: "\F347\F347"; }
  
  .fad.fa-venus-double::after, .fa-duotone.fa-venus-double::after {
	content: "\F226\F226"; }
  
  .fad.fa-images::after, .fa-duotone.fa-images::after {
	content: "\F302\F302"; }
  
  .fad.fa-calculator::after, .fa-duotone.fa-calculator::after {
	content: "\F1EC\F1EC"; }
  
  .fad.fa-shuttlecock::after, .fa-duotone.fa-shuttlecock::after {
	content: "\F45B\F45B"; }
  
  .fad.fa-user-hair::after, .fa-duotone.fa-user-hair::after {
	content: "\E45A\E45A"; }
  
  .fad.fa-eye-evil::after, .fa-duotone.fa-eye-evil::after {
	content: "\F6DB\F6DB"; }
  
  .fad.fa-people-pulling::after, .fa-duotone.fa-people-pulling::after {
	content: "\E535\E535"; }
  
  .fad.fa-n::after, .fa-duotone.fa-n::after {
	content: "NN"; }
  
  .fad.fa-garage::after, .fa-duotone.fa-garage::after {
	content: "\E009\E009"; }
  
  .fad.fa-cable-car::after, .fa-duotone.fa-cable-car::after {
	content: "\F7DA\F7DA"; }
  
  .fad.fa-tram::after, .fa-duotone.fa-tram::after {
	content: "\F7DA\F7DA"; }
  
  .fad.fa-shovel-snow::after, .fa-duotone.fa-shovel-snow::after {
	content: "\F7C3\F7C3"; }
  
  .fad.fa-cloud-rain::after, .fa-duotone.fa-cloud-rain::after {
	content: "\F73D\F73D"; }
  
  .fad.fa-face-lying::after, .fa-duotone.fa-face-lying::after {
	content: "\E37E\E37E"; }
  
  .fad.fa-sprinkler::after, .fa-duotone.fa-sprinkler::after {
	content: "\E035\E035"; }
  
  .fad.fa-building-circle-xmark::after, .fa-duotone.fa-building-circle-xmark::after {
	content: "\E4D4\E4D4"; }
  
  .fad.fa-person-sledding::after, .fa-duotone.fa-person-sledding::after {
	content: "\F7CB\F7CB"; }
  
  .fad.fa-sledding::after, .fa-duotone.fa-sledding::after {
	content: "\F7CB\F7CB"; }
  
  .fad.fa-game-console-handheld::after, .fa-duotone.fa-game-console-handheld::after {
	content: "\F8BB\F8BB"; }
  
  .fad.fa-ship::after, .fa-duotone.fa-ship::after {
	content: "\F21A\F21A"; }
  
  .fad.fa-clock-six-thirty::after, .fa-duotone.fa-clock-six-thirty::after {
	content: "\E353\E353"; }
  
  .fad.fa-battery-slash::after, .fa-duotone.fa-battery-slash::after {
	content: "\F377\F377"; }
  
  .fad.fa-tugrik-sign::after, .fa-duotone.fa-tugrik-sign::after {
	content: "\E2BA\E2BA"; }
  
  .fad.fa-arrows-down-to-line::after, .fa-duotone.fa-arrows-down-to-line::after {
	content: "\E4B8\E4B8"; }
  
  .fad.fa-download::after, .fa-duotone.fa-download::after {
	content: "\F019\F019"; }
  
  .fad.fa-shelves::after, .fa-duotone.fa-shelves::after {
	content: "\F480\F480"; }
  
  .fad.fa-inventory::after, .fa-duotone.fa-inventory::after {
	content: "\F480\F480"; }
  
  .fad.fa-cloud-snow::after, .fa-duotone.fa-cloud-snow::after {
	content: "\F742\F742"; }
  
  .fad.fa-face-grin::after, .fa-duotone.fa-face-grin::after {
	content: "\F580\F580"; }
  
  .fad.fa-grin::after, .fa-duotone.fa-grin::after {
	content: "\F580\F580"; }
  
  .fad.fa-delete-left::after, .fa-duotone.fa-delete-left::after {
	content: "\F55A\F55A"; }
  
  .fad.fa-backspace::after, .fa-duotone.fa-backspace::after {
	content: "\F55A\F55A"; }
  
  .fad.fa-oven::after, .fa-duotone.fa-oven::after {
	content: "\E01D\E01D"; }
  
  .fad.fa-eye-dropper::after, .fa-duotone.fa-eye-dropper::after {
	content: "\F1FB\F1FB"; }
  
  .fad.fa-eye-dropper-empty::after, .fa-duotone.fa-eye-dropper-empty::after {
	content: "\F1FB\F1FB"; }
  
  .fad.fa-eyedropper::after, .fa-duotone.fa-eyedropper::after {
	content: "\F1FB\F1FB"; }
  
  .fad.fa-comment-captions::after, .fa-duotone.fa-comment-captions::after {
	content: "\E146\E146"; }
  
  .fad.fa-comments-question::after, .fa-duotone.fa-comments-question::after {
	content: "\E14E\E14E"; }
  
  .fad.fa-scribble::after, .fa-duotone.fa-scribble::after {
	content: "\E23F\E23F"; }
  
  .fad.fa-rotate-exclamation::after, .fa-duotone.fa-rotate-exclamation::after {
	content: "\E23C\E23C"; }
  
  .fad.fa-file-circle-check::after, .fa-duotone.fa-file-circle-check::after {
	content: "\E5A0\E5A0"; }
  
  .fad.fa-glass::after, .fa-duotone.fa-glass::after {
	content: "\F804\F804"; }
  
  .fad.fa-loader::after, .fa-duotone.fa-loader::after {
	content: "\E1D4\E1D4"; }
  
  .fad.fa-forward::after, .fa-duotone.fa-forward::after {
	content: "\F04E\F04E"; }
  
  .fad.fa-user-pilot::after, .fa-duotone.fa-user-pilot::after {
	content: "\E2C0\E2C0"; }
  
  .fad.fa-mobile::after, .fa-duotone.fa-mobile::after {
	content: "\F3CE\F3CE"; }
  
  .fad.fa-mobile-android::after, .fa-duotone.fa-mobile-android::after {
	content: "\F3CE\F3CE"; }
  
  .fad.fa-mobile-phone::after, .fa-duotone.fa-mobile-phone::after {
	content: "\F3CE\F3CE"; }
  
  .fad.fa-code-pull-request-closed::after, .fa-duotone.fa-code-pull-request-closed::after {
	content: "\E3F9\E3F9"; }
  
  .fad.fa-face-meh::after, .fa-duotone.fa-face-meh::after {
	content: "\F11A\F11A"; }
  
  .fad.fa-meh::after, .fa-duotone.fa-meh::after {
	content: "\F11A\F11A"; }
  
  .fad.fa-align-center::after, .fa-duotone.fa-align-center::after {
	content: "\F037\F037"; }
  
  .fad.fa-book-skull::after, .fa-duotone.fa-book-skull::after {
	content: "\F6B7\F6B7"; }
  
  .fad.fa-book-dead::after, .fa-duotone.fa-book-dead::after {
	content: "\F6B7\F6B7"; }
  
  .fad.fa-id-card::after, .fa-duotone.fa-id-card::after {
	content: "\F2C2\F2C2"; }
  
  .fad.fa-drivers-license::after, .fa-duotone.fa-drivers-license::after {
	content: "\F2C2\F2C2"; }
  
  .fad.fa-face-dotted::after, .fa-duotone.fa-face-dotted::after {
	content: "\E47F\E47F"; }
  
  .fad.fa-face-worried::after, .fa-duotone.fa-face-worried::after {
	content: "\E3A3\E3A3"; }
  
  .fad.fa-outdent::after, .fa-duotone.fa-outdent::after {
	content: "\F03B\F03B"; }
  
  .fad.fa-dedent::after, .fa-duotone.fa-dedent::after {
	content: "\F03B\F03B"; }
  
  .fad.fa-heart-circle-exclamation::after, .fa-duotone.fa-heart-circle-exclamation::after {
	content: "\E4FE\E4FE"; }
  
  .fad.fa-house::after, .fa-duotone.fa-house::after {
	content: "\F015\F015"; }
  
  .fad.fa-home::after, .fa-duotone.fa-home::after {
	content: "\F015\F015"; }
  
  .fad.fa-home-alt::after, .fa-duotone.fa-home-alt::after {
	content: "\F015\F015"; }
  
  .fad.fa-home-lg-alt::after, .fa-duotone.fa-home-lg-alt::after {
	content: "\F015\F015"; }
  
  .fad.fa-vector-circle::after, .fa-duotone.fa-vector-circle::after {
	content: "\E2C6\E2C6"; }
  
  .fad.fa-car-circle-bolt::after, .fa-duotone.fa-car-circle-bolt::after {
	content: "\E342\E342"; }
  
  .fad.fa-calendar-week::after, .fa-duotone.fa-calendar-week::after {
	content: "\F784\F784"; }
  
  .fad.fa-flying-disc::after, .fa-duotone.fa-flying-disc::after {
	content: "\E3A9\E3A9"; }
  
  .fad.fa-laptop-medical::after, .fa-duotone.fa-laptop-medical::after {
	content: "\F812\F812"; }
  
  .fad.fa-square-down-right::after, .fa-duotone.fa-square-down-right::after {
	content: "\E26C\E26C"; }
  
  .fad.fa-b::after, .fa-duotone.fa-b::after {
	content: "BB"; }
  
  .fad.fa-seat-airline::after, .fa-duotone.fa-seat-airline::after {
	content: "\E244\E244"; }
  
  .fad.fa-moon-over-sun::after, .fa-duotone.fa-moon-over-sun::after {
	content: "\F74A\F74A"; }
  
  .fad.fa-eclipse-alt::after, .fa-duotone.fa-eclipse-alt::after {
	content: "\F74A\F74A"; }
  
  .fad.fa-pipe::after, .fa-duotone.fa-pipe::after {
	content: "||"; }
  
  .fad.fa-file-medical::after, .fa-duotone.fa-file-medical::after {
	content: "\F477\F477"; }
  
  .fad.fa-potato::after, .fa-duotone.fa-potato::after {
	content: "\E440\E440"; }
  
  .fad.fa-dice-one::after, .fa-duotone.fa-dice-one::after {
	content: "\F525\F525"; }
  
  .fad.fa-circle-a::after, .fa-duotone.fa-circle-a::after {
	content: "\E0F7\E0F7"; }
  
  .fad.fa-helmet-battle::after, .fa-duotone.fa-helmet-battle::after {
	content: "\F6EB\F6EB"; }
  
  .fad.fa-butter::after, .fa-duotone.fa-butter::after {
	content: "\E3E4\E3E4"; }
  
  .fad.fa-blanket-fire::after, .fa-duotone.fa-blanket-fire::after {
	content: "\E3DA\E3DA"; }
  
  .fad.fa-kiwi-bird::after, .fa-duotone.fa-kiwi-bird::after {
	content: "\F535\F535"; }
  
  .fad.fa-castle::after, .fa-duotone.fa-castle::after {
	content: "\E0DE\E0DE"; }
  
  .fad.fa-golf-club::after, .fa-duotone.fa-golf-club::after {
	content: "\F451\F451"; }
  
  .fad.fa-arrow-right-arrow-left::after, .fa-duotone.fa-arrow-right-arrow-left::after {
	content: "\F0EC\F0EC"; }
  
  .fad.fa-exchange::after, .fa-duotone.fa-exchange::after {
	content: "\F0EC\F0EC"; }
  
  .fad.fa-rotate-right::after, .fa-duotone.fa-rotate-right::after {
	content: "\F2F9\F2F9"; }
  
  .fad.fa-redo-alt::after, .fa-duotone.fa-redo-alt::after {
	content: "\F2F9\F2F9"; }
  
  .fad.fa-rotate-forward::after, .fa-duotone.fa-rotate-forward::after {
	content: "\F2F9\F2F9"; }
  
  .fad.fa-utensils::after, .fa-duotone.fa-utensils::after {
	content: "\F2E7\F2E7"; }
  
  .fad.fa-cutlery::after, .fa-duotone.fa-cutlery::after {
	content: "\F2E7\F2E7"; }
  
  .fad.fa-arrow-up-wide-short::after, .fa-duotone.fa-arrow-up-wide-short::after {
	content: "\F161\F161"; }
  
  .fad.fa-sort-amount-up::after, .fa-duotone.fa-sort-amount-up::after {
	content: "\F161\F161"; }
  
  .fad.fa-balloons::after, .fa-duotone.fa-balloons::after {
	content: "\E2E4\E2E4"; }
  
  .fad.fa-mill-sign::after, .fa-duotone.fa-mill-sign::after {
	content: "\E1ED\E1ED"; }
  
  .fad.fa-bowl-rice::after, .fa-duotone.fa-bowl-rice::after {
	content: "\E2EB\E2EB"; }
  
  .fad.fa-timeline-arrow::after, .fa-duotone.fa-timeline-arrow::after {
	content: "\E29D\E29D"; }
  
  .fad.fa-skull::after, .fa-duotone.fa-skull::after {
	content: "\F54C\F54C"; }
  
  .fad.fa-game-board-simple::after, .fa-duotone.fa-game-board-simple::after {
	content: "\F868\F868"; }
  
  .fad.fa-game-board-alt::after, .fa-duotone.fa-game-board-alt::after {
	content: "\F868\F868"; }
  
  .fad.fa-circle-video::after, .fa-duotone.fa-circle-video::after {
	content: "\E12B\E12B"; }
  
  .fad.fa-video-circle::after, .fa-duotone.fa-video-circle::after {
	content: "\E12B\E12B"; }
  
  .fad.fa-chart-scatter-bubble::after, .fa-duotone.fa-chart-scatter-bubble::after {
	content: "\E0E9\E0E9"; }
  
  .fad.fa-house-turret::after, .fa-duotone.fa-house-turret::after {
	content: "\E1B4\E1B4"; }
  
  .fad.fa-banana::after, .fa-duotone.fa-banana::after {
	content: "\E2E5\E2E5"; }
  
  .fad.fa-hand-holding-skull::after, .fa-duotone.fa-hand-holding-skull::after {
	content: "\E1A4\E1A4"; }
  
  .fad.fa-people-dress::after, .fa-duotone.fa-people-dress::after {
	content: "\E217\E217"; }
  
  .fad.fa-loveseat::after, .fa-duotone.fa-loveseat::after {
	content: "\F4CC\F4CC"; }
  
  .fad.fa-couch-small::after, .fa-duotone.fa-couch-small::after {
	content: "\F4CC\F4CC"; }
  
  .fad.fa-tower-broadcast::after, .fa-duotone.fa-tower-broadcast::after {
	content: "\F519\F519"; }
  
  .fad.fa-broadcast-tower::after, .fa-duotone.fa-broadcast-tower::after {
	content: "\F519\F519"; }
  
  .fad.fa-truck-pickup::after, .fa-duotone.fa-truck-pickup::after {
	content: "\F63C\F63C"; }
  
  .fad.fa-block-quote::after, .fa-duotone.fa-block-quote::after {
	content: "\E0B5\E0B5"; }
  
  .fad.fa-up-long::after, .fa-duotone.fa-up-long::after {
	content: "\F30C\F30C"; }
  
  .fad.fa-long-arrow-alt-up::after, .fa-duotone.fa-long-arrow-alt-up::after {
	content: "\F30C\F30C"; }
  
  .fad.fa-stop::after, .fa-duotone.fa-stop::after {
	content: "\F04D\F04D"; }
  
  .fad.fa-code-merge::after, .fa-duotone.fa-code-merge::after {
	content: "\F387\F387"; }
  
  .fad.fa-money-check-dollar-pen::after, .fa-duotone.fa-money-check-dollar-pen::after {
	content: "\F873\F873"; }
  
  .fad.fa-money-check-edit-alt::after, .fa-duotone.fa-money-check-edit-alt::after {
	content: "\F873\F873"; }
  
  .fad.fa-up-from-line::after, .fa-duotone.fa-up-from-line::after {
	content: "\F346\F346"; }
  
  .fad.fa-arrow-alt-from-bottom::after, .fa-duotone.fa-arrow-alt-from-bottom::after {
	content: "\F346\F346"; }
  
  .fad.fa-upload::after, .fa-duotone.fa-upload::after {
	content: "\F093\F093"; }
  
  .fad.fa-hurricane::after, .fa-duotone.fa-hurricane::after {
	content: "\F751\F751"; }
  
  .fad.fa-people-pants::after, .fa-duotone.fa-people-pants::after {
	content: "\E219\E219"; }
  
  .fad.fa-mound::after, .fa-duotone.fa-mound::after {
	content: "\E52D\E52D"; }
  
  .fad.fa-windsock::after, .fa-duotone.fa-windsock::after {
	content: "\F777\F777"; }
  
  .fad.fa-circle-half::after, .fa-duotone.fa-circle-half::after {
	content: "\E110\E110"; }
  
  .fad.fa-brake-warning::after, .fa-duotone.fa-brake-warning::after {
	content: "\E0C7\E0C7"; }
  
  .fad.fa-toilet-portable::after, .fa-duotone.fa-toilet-portable::after {
	content: "\E583\E583"; }
  
  .fad.fa-compact-disc::after, .fa-duotone.fa-compact-disc::after {
	content: "\F51F\F51F"; }
  
  .fad.fa-file-arrow-down::after, .fa-duotone.fa-file-arrow-down::after {
	content: "\F56D\F56D"; }
  
  .fad.fa-file-download::after, .fa-duotone.fa-file-download::after {
	content: "\F56D\F56D"; }
  
  .fad.fa-saxophone-fire::after, .fa-duotone.fa-saxophone-fire::after {
	content: "\F8DB\F8DB"; }
  
  .fad.fa-sax-hot::after, .fa-duotone.fa-sax-hot::after {
	content: "\F8DB\F8DB"; }
  
  .fad.fa-camera-web-slash::after, .fa-duotone.fa-camera-web-slash::after {
	content: "\F833\F833"; }
  
  .fad.fa-webcam-slash::after, .fa-duotone.fa-webcam-slash::after {
	content: "\F833\F833"; }
  
  .fad.fa-folder-medical::after, .fa-duotone.fa-folder-medical::after {
	content: "\E18C\E18C"; }
  
  .fad.fa-folder-gear::after, .fa-duotone.fa-folder-gear::after {
	content: "\E187\E187"; }
  
  .fad.fa-folder-cog::after, .fa-duotone.fa-folder-cog::after {
	content: "\E187\E187"; }
  
  .fad.fa-hand-wave::after, .fa-duotone.fa-hand-wave::after {
	content: "\E1A7\E1A7"; }
  
  .fad.fa-arrow-up-arrow-down::after, .fa-duotone.fa-arrow-up-arrow-down::after {
	content: "\E099\E099"; }
  
  .fad.fa-sort-up-down::after, .fa-duotone.fa-sort-up-down::after {
	content: "\E099\E099"; }
  
  .fad.fa-caravan::after, .fa-duotone.fa-caravan::after {
	content: "\F8FF\F8FF"; }
  
  .fad.fa-shield-cat::after, .fa-duotone.fa-shield-cat::after {
	content: "\E572\E572"; }
  
  .fad.fa-message-slash::after, .fa-duotone.fa-message-slash::after {
	content: "\F4A9\F4A9"; }
  
  .fad.fa-comment-alt-slash::after, .fa-duotone.fa-comment-alt-slash::after {
	content: "\F4A9\F4A9"; }
  
  .fad.fa-bolt::after, .fa-duotone.fa-bolt::after {
	content: "\F0E7\F0E7"; }
  
  .fad.fa-zap::after, .fa-duotone.fa-zap::after {
	content: "\F0E7\F0E7"; }
  
  .fad.fa-trash-can-check::after, .fa-duotone.fa-trash-can-check::after {
	content: "\E2A9\E2A9"; }
  
  .fad.fa-glass-water::after, .fa-duotone.fa-glass-water::after {
	content: "\E4F4\E4F4"; }
  
  .fad.fa-oil-well::after, .fa-duotone.fa-oil-well::after {
	content: "\E532\E532"; }
  
  .fad.fa-person-simple::after, .fa-duotone.fa-person-simple::after {
	content: "\E220\E220"; }
  
  .fad.fa-vault::after, .fa-duotone.fa-vault::after {
	content: "\E2C5\E2C5"; }
  
  .fad.fa-mars::after, .fa-duotone.fa-mars::after {
	content: "\F222\F222"; }
  
  .fad.fa-toilet::after, .fa-duotone.fa-toilet::after {
	content: "\F7D8\F7D8"; }
  
  .fad.fa-plane-circle-xmark::after, .fa-duotone.fa-plane-circle-xmark::after {
	content: "\E557\E557"; }
  
  .fad.fa-yen-sign::after, .fa-duotone.fa-yen-sign::after {
	content: "\F157\F157"; }
  
  .fad.fa-cny::after, .fa-duotone.fa-cny::after {
	content: "\F157\F157"; }
  
  .fad.fa-jpy::after, .fa-duotone.fa-jpy::after {
	content: "\F157\F157"; }
  
  .fad.fa-rmb::after, .fa-duotone.fa-rmb::after {
	content: "\F157\F157"; }
  
  .fad.fa-yen::after, .fa-duotone.fa-yen::after {
	content: "\F157\F157"; }
  
  .fad.fa-notes::after, .fa-duotone.fa-notes::after {
	content: "\E202\E202"; }
  
  .fad.fa-ruble-sign::after, .fa-duotone.fa-ruble-sign::after {
	content: "\F158\F158"; }
  
  .fad.fa-rouble::after, .fa-duotone.fa-rouble::after {
	content: "\F158\F158"; }
  
  .fad.fa-rub::after, .fa-duotone.fa-rub::after {
	content: "\F158\F158"; }
  
  .fad.fa-ruble::after, .fa-duotone.fa-ruble::after {
	content: "\F158\F158"; }
  
  .fad.fa-trash-undo::after, .fa-duotone.fa-trash-undo::after {
	content: "\F895\F895"; }
  
  .fad.fa-trash-arrow-turn-left::after, .fa-duotone.fa-trash-arrow-turn-left::after {
	content: "\F895\F895"; }
  
  .fad.fa-champagne-glass::after, .fa-duotone.fa-champagne-glass::after {
	content: "\F79E\F79E"; }
  
  .fad.fa-glass-champagne::after, .fa-duotone.fa-glass-champagne::after {
	content: "\F79E\F79E"; }
  
  .fad.fa-objects-align-center-horizontal::after, .fa-duotone.fa-objects-align-center-horizontal::after {
	content: "\E3BC\E3BC"; }
  
  .fad.fa-sun::after, .fa-duotone.fa-sun::after {
	content: "\F185\F185"; }
  
  .fad.fa-trash-can-slash::after, .fa-duotone.fa-trash-can-slash::after {
	content: "\E2AD\E2AD"; }
  
  .fad.fa-trash-alt-slash::after, .fa-duotone.fa-trash-alt-slash::after {
	content: "\E2AD\E2AD"; }
  
  .fad.fa-screen-users::after, .fa-duotone.fa-screen-users::after {
	content: "\F63D\F63D"; }
  
  .fad.fa-users-class::after, .fa-duotone.fa-users-class::after {
	content: "\F63D\F63D"; }
  
  .fad.fa-guitar::after, .fa-duotone.fa-guitar::after {
	content: "\F7A6\F7A6"; }
  
  .fad.fa-square-arrow-left::after, .fa-duotone.fa-square-arrow-left::after {
	content: "\F33A\F33A"; }
  
  .fad.fa-arrow-square-left::after, .fa-duotone.fa-arrow-square-left::after {
	content: "\F33A\F33A"; }
  
  .fad.fa-square-8::after, .fa-duotone.fa-square-8::after {
	content: "\E25D\E25D"; }
  
  .fad.fa-face-smile-hearts::after, .fa-duotone.fa-face-smile-hearts::after {
	content: "\E390\E390"; }
  
  .fad.fa-brackets-square::after, .fa-duotone.fa-brackets-square::after {
	content: "\F7E9\F7E9"; }
  
  .fad.fa-brackets::after, .fa-duotone.fa-brackets::after {
	content: "\F7E9\F7E9"; }
  
  .fad.fa-laptop-arrow-down::after, .fa-duotone.fa-laptop-arrow-down::after {
	content: "\E1C6\E1C6"; }
  
  .fad.fa-hockey-stick-puck::after, .fa-duotone.fa-hockey-stick-puck::after {
	content: "\E3AE\E3AE"; }
  
  .fad.fa-house-tree::after, .fa-duotone.fa-house-tree::after {
	content: "\E1B3\E1B3"; }
  
  .fad.fa-signal-fair::after, .fa-duotone.fa-signal-fair::after {
	content: "\F68D\F68D"; }
  
  .fad.fa-signal-2::after, .fa-duotone.fa-signal-2::after {
	content: "\F68D\F68D"; }
  
  .fad.fa-face-laugh-wink::after, .fa-duotone.fa-face-laugh-wink::after {
	content: "\F59C\F59C"; }
  
  .fad.fa-laugh-wink::after, .fa-duotone.fa-laugh-wink::after {
	content: "\F59C\F59C"; }
  
  .fad.fa-circle-dollar::after, .fa-duotone.fa-circle-dollar::after {
	content: "\F2E8\F2E8"; }
  
  .fad.fa-dollar-circle::after, .fa-duotone.fa-dollar-circle::after {
	content: "\F2E8\F2E8"; }
  
  .fad.fa-usd-circle::after, .fa-duotone.fa-usd-circle::after {
	content: "\F2E8\F2E8"; }
  
  .fad.fa-horse-head::after, .fa-duotone.fa-horse-head::after {
	content: "\F7AB\F7AB"; }
  
  .fad.fa-arrows-repeat::after, .fa-duotone.fa-arrows-repeat::after {
	content: "\F364\F364"; }
  
  .fad.fa-repeat-alt::after, .fa-duotone.fa-repeat-alt::after {
	content: "\F364\F364"; }
  
  .fad.fa-bore-hole::after, .fa-duotone.fa-bore-hole::after {
	content: "\E4C3\E4C3"; }
  
  .fad.fa-industry::after, .fa-duotone.fa-industry::after {
	content: "\F275\F275"; }
  
  .fad.fa-image-polaroid::after, .fa-duotone.fa-image-polaroid::after {
	content: "\F8C4\F8C4"; }
  
  .fad.fa-wave-triangle::after, .fa-duotone.fa-wave-triangle::after {
	content: "\F89A\F89A"; }
  
  .fad.fa-circle-down::after, .fa-duotone.fa-circle-down::after {
	content: "\F358\F358"; }
  
  .fad.fa-arrow-alt-circle-down::after, .fa-duotone.fa-arrow-alt-circle-down::after {
	content: "\F358\F358"; }
  
  .fad.fa-grill::after, .fa-duotone.fa-grill::after {
	content: "\E5A3\E5A3"; }
  
  .fad.fa-arrows-turn-to-dots::after, .fa-duotone.fa-arrows-turn-to-dots::after {
	content: "\E4C1\E4C1"; }
  
  .fad.fa-chart-mixed::after, .fa-duotone.fa-chart-mixed::after {
	content: "\F643\F643"; }
  
  .fad.fa-analytics::after, .fa-duotone.fa-analytics::after {
	content: "\F643\F643"; }
  
  .fad.fa-florin-sign::after, .fa-duotone.fa-florin-sign::after {
	content: "\E184\E184"; }
  
  .fad.fa-arrow-down-short-wide::after, .fa-duotone.fa-arrow-down-short-wide::after {
	content: "\F884\F884"; }
  
  .fad.fa-sort-amount-desc::after, .fa-duotone.fa-sort-amount-desc::after {
	content: "\F884\F884"; }
  
  .fad.fa-sort-amount-down-alt::after, .fa-duotone.fa-sort-amount-down-alt::after {
	content: "\F884\F884"; }
  
  .fad.fa-less-than::after, .fa-duotone.fa-less-than::after {
	content: "<<"; }
  
  .fad.fa-display-code::after, .fa-duotone.fa-display-code::after {
	content: "\E165\E165"; }
  
  .fad.fa-desktop-code::after, .fa-duotone.fa-desktop-code::after {
	content: "\E165\E165"; }
  
  .fad.fa-face-drooling::after, .fa-duotone.fa-face-drooling::after {
	content: "\E372\E372"; }
  
  .fad.fa-oil-temperature::after, .fa-duotone.fa-oil-temperature::after {
	content: "\F614\F614"; }
  
  .fad.fa-oil-temp::after, .fa-duotone.fa-oil-temp::after {
	content: "\F614\F614"; }
  
  .fad.fa-square-question::after, .fa-duotone.fa-square-question::after {
	content: "\F2FD\F2FD"; }
  
  .fad.fa-question-square::after, .fa-duotone.fa-question-square::after {
	content: "\F2FD\F2FD"; }
  
  .fad.fa-air-conditioner::after, .fa-duotone.fa-air-conditioner::after {
	content: "\F8F4\F8F4"; }
  
  .fad.fa-angle-down::after, .fa-duotone.fa-angle-down::after {
	content: "\F107\F107"; }
  
  .fad.fa-mountains::after, .fa-duotone.fa-mountains::after {
	content: "\F6FD\F6FD"; }
  
  .fad.fa-omega::after, .fa-duotone.fa-omega::after {
	content: "\F67A\F67A"; }
  
  .fad.fa-car-tunnel::after, .fa-duotone.fa-car-tunnel::after {
	content: "\E4DE\E4DE"; }
  
  .fad.fa-person-dolly-empty::after, .fa-duotone.fa-person-dolly-empty::after {
	content: "\F4D1\F4D1"; }
  
  .fad.fa-pan-food::after, .fa-duotone.fa-pan-food::after {
	content: "\E42B\E42B"; }
  
  .fad.fa-head-side-cough::after, .fa-duotone.fa-head-side-cough::after {
	content: "\E061\E061"; }
  
  .fad.fa-grip-lines::after, .fa-duotone.fa-grip-lines::after {
	content: "\F7A4\F7A4"; }
  
  .fad.fa-thumbs-down::after, .fa-duotone.fa-thumbs-down::after {
	content: "\F165\F165"; }
  
  .fad.fa-user-lock::after, .fa-duotone.fa-user-lock::after {
	content: "\F502\F502"; }
  
  .fad.fa-arrow-right-long::after, .fa-duotone.fa-arrow-right-long::after {
	content: "\F178\F178"; }
  
  .fad.fa-long-arrow-right::after, .fa-duotone.fa-long-arrow-right::after {
	content: "\F178\F178"; }
  
  .fad.fa-tickets-airline::after, .fa-duotone.fa-tickets-airline::after {
	content: "\E29B\E29B"; }
  
  .fad.fa-anchor-circle-xmark::after, .fa-duotone.fa-anchor-circle-xmark::after {
	content: "\E4AC\E4AC"; }
  
  .fad.fa-ellipsis::after, .fa-duotone.fa-ellipsis::after {
	content: "\F141\F141"; }
  
  .fad.fa-ellipsis-h::after, .fa-duotone.fa-ellipsis-h::after {
	content: "\F141\F141"; }
  
  .fad.fa-nfc-slash::after, .fa-duotone.fa-nfc-slash::after {
	content: "\E1FC\E1FC"; }
  
  .fad.fa-chess-pawn::after, .fa-duotone.fa-chess-pawn::after {
	content: "\F443\F443"; }
  
  .fad.fa-kit-medical::after, .fa-duotone.fa-kit-medical::after {
	content: "\F479\F479"; }
  
  .fad.fa-first-aid::after, .fa-duotone.fa-first-aid::after {
	content: "\F479\F479"; }
  
  .fad.fa-grid-2-plus::after, .fa-duotone.fa-grid-2-plus::after {
	content: "\E197\E197"; }
  
  .fad.fa-bells::after, .fa-duotone.fa-bells::after {
	content: "\F77F\F77F"; }
  
  .fad.fa-person-through-window::after, .fa-duotone.fa-person-through-window::after {
	content: "\E5A9\E5A9"; }
  
  .fad.fa-toolbox::after, .fa-duotone.fa-toolbox::after {
	content: "\F552\F552"; }
  
  .fad.fa-envelope-dot::after, .fa-duotone.fa-envelope-dot::after {
	content: "\E16F\E16F"; }
  
  .fad.fa-envelope-badge::after, .fa-duotone.fa-envelope-badge::after {
	content: "\E16F\E16F"; }
  
  .fad.fa-hands-holding-circle::after, .fa-duotone.fa-hands-holding-circle::after {
	content: "\E4FB\E4FB"; }
  
  .fad.fa-bug::after, .fa-duotone.fa-bug::after {
	content: "\F188\F188"; }
  
  .fad.fa-bowl-chopsticks::after, .fa-duotone.fa-bowl-chopsticks::after {
	content: "\E2E9\E2E9"; }
  
  .fad.fa-credit-card::after, .fa-duotone.fa-credit-card::after {
	content: "\F09D\F09D"; }
  
  .fad.fa-credit-card-alt::after, .fa-duotone.fa-credit-card-alt::after {
	content: "\F09D\F09D"; }
  
  .fad.fa-circle-s::after, .fa-duotone.fa-circle-s::after {
	content: "\E121\E121"; }
  
  .fad.fa-box-ballot::after, .fa-duotone.fa-box-ballot::after {
	content: "\F735\F735"; }
  
  .fad.fa-car::after, .fa-duotone.fa-car::after {
	content: "\F1B9\F1B9"; }
  
  .fad.fa-automobile::after, .fa-duotone.fa-automobile::after {
	content: "\F1B9\F1B9"; }
  
  .fad.fa-hand-holding-hand::after, .fa-duotone.fa-hand-holding-hand::after {
	content: "\E4F7\E4F7"; }
  
  .fad.fa-user-tie-hair::after, .fa-duotone.fa-user-tie-hair::after {
	content: "\E45F\E45F"; }
  
  .fad.fa-podium-star::after, .fa-duotone.fa-podium-star::after {
	content: "\F758\F758"; }
  
  .fad.fa-user-hair-mullet::after, .fa-duotone.fa-user-hair-mullet::after {
	content: "\E45C\E45C"; }
  
  .fad.fa-business-front::after, .fa-duotone.fa-business-front::after {
	content: "\E45C\E45C"; }
  
  .fad.fa-party-back::after, .fa-duotone.fa-party-back::after {
	content: "\E45C\E45C"; }
  
  .fad.fa-trian-balbot::after, .fa-duotone.fa-trian-balbot::after {
	content: "\E45C\E45C"; }
  
  .fad.fa-microphone-stand::after, .fa-duotone.fa-microphone-stand::after {
	content: "\F8CB\F8CB"; }
  
  .fad.fa-book-open-reader::after, .fa-duotone.fa-book-open-reader::after {
	content: "\F5DA\F5DA"; }
  
  .fad.fa-book-reader::after, .fa-duotone.fa-book-reader::after {
	content: "\F5DA\F5DA"; }
  
  .fad.fa-family-dress::after, .fa-duotone.fa-family-dress::after {
	content: "\E301\E301"; }
  
  .fad.fa-circle-x::after, .fa-duotone.fa-circle-x::after {
	content: "\E12E\E12E"; }
  
  .fad.fa-cabin::after, .fa-duotone.fa-cabin::after {
	content: "\E46D\E46D"; }
  
  .fad.fa-mountain-sun::after, .fa-duotone.fa-mountain-sun::after {
	content: "\E52F\E52F"; }
  
  .fad.fa-chart-simple-horizontal::after, .fa-duotone.fa-chart-simple-horizontal::after {
	content: "\E474\E474"; }
  
  .fad.fa-arrows-left-right-to-line::after, .fa-duotone.fa-arrows-left-right-to-line::after {
	content: "\E4BA\E4BA"; }
  
  .fad.fa-hand-back-point-left::after, .fa-duotone.fa-hand-back-point-left::after {
	content: "\E19F\E19F"; }
  
  .fad.fa-message-dots::after, .fa-duotone.fa-message-dots::after {
	content: "\F4A3\F4A3"; }
  
  .fad.fa-comment-alt-dots::after, .fa-duotone.fa-comment-alt-dots::after {
	content: "\F4A3\F4A3"; }
  
  .fad.fa-messaging::after, .fa-duotone.fa-messaging::after {
	content: "\F4A3\F4A3"; }
  
  .fad.fa-file-heart::after, .fa-duotone.fa-file-heart::after {
	content: "\E176\E176"; }
  
  .fad.fa-beer-mug::after, .fa-duotone.fa-beer-mug::after {
	content: "\E0B3\E0B3"; }
  
  .fad.fa-beer-foam::after, .fa-duotone.fa-beer-foam::after {
	content: "\E0B3\E0B3"; }
  
  .fad.fa-dice-d20::after, .fa-duotone.fa-dice-d20::after {
	content: "\F6CF\F6CF"; }
  
  .fad.fa-drone::after, .fa-duotone.fa-drone::after {
	content: "\F85F\F85F"; }
  
  .fad.fa-truck-droplet::after, .fa-duotone.fa-truck-droplet::after {
	content: "\E58C\E58C"; }
  
  .fad.fa-file-circle-xmark::after, .fa-duotone.fa-file-circle-xmark::after {
	content: "\E5A1\E5A1"; }
  
  .fad.fa-temperature-arrow-up::after, .fa-duotone.fa-temperature-arrow-up::after {
	content: "\E040\E040"; }
  
  .fad.fa-temperature-up::after, .fa-duotone.fa-temperature-up::after {
	content: "\E040\E040"; }
  
  .fad.fa-medal::after, .fa-duotone.fa-medal::after {
	content: "\F5A2\F5A2"; }
  
  .fad.fa-bed::after, .fa-duotone.fa-bed::after {
	content: "\F236\F236"; }
  
  .fad.fa-book-copy::after, .fa-duotone.fa-book-copy::after {
	content: "\E0BE\E0BE"; }
  
  .fad.fa-square-h::after, .fa-duotone.fa-square-h::after {
	content: "\F0FD\F0FD"; }
  
  .fad.fa-h-square::after, .fa-duotone.fa-h-square::after {
	content: "\F0FD\F0FD"; }
  
  .fad.fa-square-c::after, .fa-duotone.fa-square-c::after {
	content: "\E266\E266"; }
  
  .fad.fa-clock-two::after, .fa-duotone.fa-clock-two::after {
	content: "\E35A\E35A"; }
  
  .fad.fa-square-ellipsis-vertical::after, .fa-duotone.fa-square-ellipsis-vertical::after {
	content: "\E26F\E26F"; }
  
  .fad.fa-podcast::after, .fa-duotone.fa-podcast::after {
	content: "\F2CE\F2CE"; }
  
  .fad.fa-bee::after, .fa-duotone.fa-bee::after {
	content: "\E0B2\E0B2"; }
  
  .fad.fa-temperature-full::after, .fa-duotone.fa-temperature-full::after {
	content: "\F2C7\F2C7"; }
  
  .fad.fa-temperature-4::after, .fa-duotone.fa-temperature-4::after {
	content: "\F2C7\F2C7"; }
  
  .fad.fa-thermometer-4::after, .fa-duotone.fa-thermometer-4::after {
	content: "\F2C7\F2C7"; }
  
  .fad.fa-thermometer-full::after, .fa-duotone.fa-thermometer-full::after {
	content: "\F2C7\F2C7"; }
  
  .fad.fa-bell::after, .fa-duotone.fa-bell::after {
	content: "\F0F3\F0F3"; }
  
  .fad.fa-candy-bar::after, .fa-duotone.fa-candy-bar::after {
	content: "\E3E8\E3E8"; }
  
  .fad.fa-chocolate-bar::after, .fa-duotone.fa-chocolate-bar::after {
	content: "\E3E8\E3E8"; }
  
  .fad.fa-xmark-large::after, .fa-duotone.fa-xmark-large::after {
	content: "\E59B\E59B"; }
  
  .fad.fa-pinata::after, .fa-duotone.fa-pinata::after {
	content: "\E3C3\E3C3"; }
  
  .fad.fa-arrows-from-line::after, .fa-duotone.fa-arrows-from-line::after {
	content: "\E0A4\E0A4"; }
  
  .fad.fa-superscript::after, .fa-duotone.fa-superscript::after {
	content: "\F12B\F12B"; }
  
  .fad.fa-bowl-spoon::after, .fa-duotone.fa-bowl-spoon::after {
	content: "\E3E0\E3E0"; }
  
  .fad.fa-hexagon-check::after, .fa-duotone.fa-hexagon-check::after {
	content: "\E416\E416"; }
  
  .fad.fa-plug-circle-xmark::after, .fa-duotone.fa-plug-circle-xmark::after {
	content: "\E560\E560"; }
  
  .fad.fa-star-of-life::after, .fa-duotone.fa-star-of-life::after {
	content: "\F621\F621"; }
  
  .fad.fa-phone-slash::after, .fa-duotone.fa-phone-slash::after {
	content: "\F3DD\F3DD"; }
  
  .fad.fa-traffic-light-stop::after, .fa-duotone.fa-traffic-light-stop::after {
	content: "\F63A\F63A"; }
  
  .fad.fa-paint-roller::after, .fa-duotone.fa-paint-roller::after {
	content: "\F5AA\F5AA"; }
  
  .fad.fa-accent-grave::after, .fa-duotone.fa-accent-grave::after {
	content: "``"; }
  
  .fad.fa-handshake-angle::after, .fa-duotone.fa-handshake-angle::after {
	content: "\F4C4\F4C4"; }
  
  .fad.fa-hands-helping::after, .fa-duotone.fa-hands-helping::after {
	content: "\F4C4\F4C4"; }
  
  .fad.fa-circle-0::after, .fa-duotone.fa-circle-0::after {
	content: "\E0ED\E0ED"; }
  
  .fad.fa-dial-med-low::after, .fa-duotone.fa-dial-med-low::after {
	content: "\E160\E160"; }
  
  .fad.fa-location-dot::after, .fa-duotone.fa-location-dot::after {
	content: "\F3C5\F3C5"; }
  
  .fad.fa-map-marker-alt::after, .fa-duotone.fa-map-marker-alt::after {
	content: "\F3C5\F3C5"; }
  
  .fad.fa-crab::after, .fa-duotone.fa-crab::after {
	content: "\E3FF\E3FF"; }
  
  .fad.fa-box-open-full::after, .fa-duotone.fa-box-open-full::after {
	content: "\F49C\F49C"; }
  
  .fad.fa-box-full::after, .fa-duotone.fa-box-full::after {
	content: "\F49C\F49C"; }
  
  .fad.fa-file::after, .fa-duotone.fa-file::after {
	content: "\F15B\F15B"; }
  
  .fad.fa-greater-than::after, .fa-duotone.fa-greater-than::after {
	content: ">>"; }
  
  .fad.fa-quotes::after, .fa-duotone.fa-quotes::after {
	content: "\E234\E234"; }
  
  .fad.fa-pretzel::after, .fa-duotone.fa-pretzel::after {
	content: "\E441\E441"; }
  
  .fad.fa-person-swimming::after, .fa-duotone.fa-person-swimming::after {
	content: "\F5C4\F5C4"; }
  
  .fad.fa-swimmer::after, .fa-duotone.fa-swimmer::after {
	content: "\F5C4\F5C4"; }
  
  .fad.fa-arrow-down::after, .fa-duotone.fa-arrow-down::after {
	content: "\F063\F063"; }
  
  .fad.fa-user-robot-xmarks::after, .fa-duotone.fa-user-robot-xmarks::after {
	content: "\E4A7\E4A7"; }
  
  .fad.fa-message-quote::after, .fa-duotone.fa-message-quote::after {
	content: "\E1E4\E1E4"; }
  
  .fad.fa-comment-alt-quote::after, .fa-duotone.fa-comment-alt-quote::after {
	content: "\E1E4\E1E4"; }
  
  .fad.fa-candy-corn::after, .fa-duotone.fa-candy-corn::after {
	content: "\F6BD\F6BD"; }
  
  .fad.fa-folder-magnifying-glass::after, .fa-duotone.fa-folder-magnifying-glass::after {
	content: "\E18B\E18B"; }
  
  .fad.fa-folder-search::after, .fa-duotone.fa-folder-search::after {
	content: "\E18B\E18B"; }
  
  .fad.fa-notebook::after, .fa-duotone.fa-notebook::after {
	content: "\E201\E201"; }
  
  .fad.fa-droplet::after, .fa-duotone.fa-droplet::after {
	content: "\F043\F043"; }
  
  .fad.fa-tint::after, .fa-duotone.fa-tint::after {
	content: "\F043\F043"; }
  
  .fad.fa-bullseye-pointer::after, .fa-duotone.fa-bullseye-pointer::after {
	content: "\F649\F649"; }
  
  .fad.fa-eraser::after, .fa-duotone.fa-eraser::after {
	content: "\F12D\F12D"; }
  
  .fad.fa-hexagon-image::after, .fa-duotone.fa-hexagon-image::after {
	content: "\E504\E504"; }
  
  .fad.fa-earth-americas::after, .fa-duotone.fa-earth-americas::after {
	content: "\F57D\F57D"; }
  
  .fad.fa-earth::after, .fa-duotone.fa-earth::after {
	content: "\F57D\F57D"; }
  
  .fad.fa-earth-america::after, .fa-duotone.fa-earth-america::after {
	content: "\F57D\F57D"; }
  
  .fad.fa-globe-americas::after, .fa-duotone.fa-globe-americas::after {
	content: "\F57D\F57D"; }
  
  .fad.fa-crate-apple::after, .fa-duotone.fa-crate-apple::after {
	content: "\F6B1\F6B1"; }
  
  .fad.fa-apple-crate::after, .fa-duotone.fa-apple-crate::after {
	content: "\F6B1\F6B1"; }
  
  .fad.fa-person-burst::after, .fa-duotone.fa-person-burst::after {
	content: "\E53B\E53B"; }
  
  .fad.fa-game-board::after, .fa-duotone.fa-game-board::after {
	content: "\F867\F867"; }
  
  .fad.fa-hat-chef::after, .fa-duotone.fa-hat-chef::after {
	content: "\F86B\F86B"; }
  
  .fad.fa-hand-back-point-right::after, .fa-duotone.fa-hand-back-point-right::after {
	content: "\E1A1\E1A1"; }
  
  .fad.fa-dove::after, .fa-duotone.fa-dove::after {
	content: "\F4BA\F4BA"; }
  
  .fad.fa-battery-empty::after, .fa-duotone.fa-battery-empty::after {
	content: "\F244\F244"; }
  
  .fad.fa-battery-0::after, .fa-duotone.fa-battery-0::after {
	content: "\F244\F244"; }
  
  .fad.fa-grid-4::after, .fa-duotone.fa-grid-4::after {
	content: "\E198\E198"; }
  
  .fad.fa-socks::after, .fa-duotone.fa-socks::after {
	content: "\F696\F696"; }
  
  .fad.fa-face-sunglasses::after, .fa-duotone.fa-face-sunglasses::after {
	content: "\E398\E398"; }
  
  .fad.fa-inbox::after, .fa-duotone.fa-inbox::after {
	content: "\F01C\F01C"; }
  
  .fad.fa-square-0::after, .fa-duotone.fa-square-0::after {
	content: "\E255\E255"; }
  
  .fad.fa-section::after, .fa-duotone.fa-section::after {
	content: "\E447\E447"; }
  
  .fad.fa-square-this-way-up::after, .fa-duotone.fa-square-this-way-up::after {
	content: "\F49F\F49F"; }
  
  .fad.fa-box-up::after, .fa-duotone.fa-box-up::after {
	content: "\F49F\F49F"; }
  
  .fad.fa-gauge-high::after, .fa-duotone.fa-gauge-high::after {
	content: "\F625\F625"; }
  
  .fad.fa-tachometer-alt::after, .fa-duotone.fa-tachometer-alt::after {
	content: "\F625\F625"; }
  
  .fad.fa-tachometer-alt-fast::after, .fa-duotone.fa-tachometer-alt-fast::after {
	content: "\F625\F625"; }
  
  .fad.fa-square-ampersand::after, .fa-duotone.fa-square-ampersand::after {
	content: "\E260\E260"; }
  
  .fad.fa-envelope-open-text::after, .fa-duotone.fa-envelope-open-text::after {
	content: "\F658\F658"; }
  
  .fad.fa-lamp-desk::after, .fa-duotone.fa-lamp-desk::after {
	content: "\E014\E014"; }
  
  .fad.fa-hospital::after, .fa-duotone.fa-hospital::after {
	content: "\F0F8\F0F8"; }
  
  .fad.fa-hospital-alt::after, .fa-duotone.fa-hospital-alt::after {
	content: "\F0F8\F0F8"; }
  
  .fad.fa-hospital-wide::after, .fa-duotone.fa-hospital-wide::after {
	content: "\F0F8\F0F8"; }
  
  .fad.fa-poll-people::after, .fa-duotone.fa-poll-people::after {
	content: "\F759\F759"; }
  
  .fad.fa-whiskey-glass-ice::after, .fa-duotone.fa-whiskey-glass-ice::after {
	content: "\F7A1\F7A1"; }
  
  .fad.fa-glass-whiskey-rocks::after, .fa-duotone.fa-glass-whiskey-rocks::after {
	content: "\F7A1\F7A1"; }
  
  .fad.fa-wine-bottle::after, .fa-duotone.fa-wine-bottle::after {
	content: "\F72F\F72F"; }
  
  .fad.fa-chess-rook::after, .fa-duotone.fa-chess-rook::after {
	content: "\F447\F447"; }
  
  .fad.fa-user-bounty-hunter::after, .fa-duotone.fa-user-bounty-hunter::after {
	content: "\E2BF\E2BF"; }
  
  .fad.fa-bars-staggered::after, .fa-duotone.fa-bars-staggered::after {
	content: "\F550\F550"; }
  
  .fad.fa-reorder::after, .fa-duotone.fa-reorder::after {
	content: "\F550\F550"; }
  
  .fad.fa-stream::after, .fa-duotone.fa-stream::after {
	content: "\F550\F550"; }
  
  .fad.fa-diagram-sankey::after, .fa-duotone.fa-diagram-sankey::after {
	content: "\E158\E158"; }
  
  .fad.fa-cloud-hail-mixed::after, .fa-duotone.fa-cloud-hail-mixed::after {
	content: "\F73A\F73A"; }
  
  .fad.fa-circle-up-left::after, .fa-duotone.fa-circle-up-left::after {
	content: "\E128\E128"; }
  
  .fad.fa-dharmachakra::after, .fa-duotone.fa-dharmachakra::after {
	content: "\F655\F655"; }
  
  .fad.fa-objects-align-left::after, .fa-duotone.fa-objects-align-left::after {
	content: "\E3BE\E3BE"; }
  
  .fad.fa-oil-can-drip::after, .fa-duotone.fa-oil-can-drip::after {
	content: "\E205\E205"; }
  
  .fad.fa-face-smiling-hands::after, .fa-duotone.fa-face-smiling-hands::after {
	content: "\E396\E396"; }
  
  .fad.fa-broccoli::after, .fa-duotone.fa-broccoli::after {
	content: "\E3E2\E3E2"; }
  
  .fad.fa-route-interstate::after, .fa-duotone.fa-route-interstate::after {
	content: "\F61B\F61B"; }
  
  .fad.fa-ear-muffs::after, .fa-duotone.fa-ear-muffs::after {
	content: "\F795\F795"; }
  
  .fad.fa-hotdog::after, .fa-duotone.fa-hotdog::after {
	content: "\F80F\F80F"; }
  
  .fad.fa-transporter-empty::after, .fa-duotone.fa-transporter-empty::after {
	content: "\E046\E046"; }
  
  .fad.fa-person-walking-with-cane::after, .fa-duotone.fa-person-walking-with-cane::after {
	content: "\F29D\F29D"; }
  
  .fad.fa-blind::after, .fa-duotone.fa-blind::after {
	content: "\F29D\F29D"; }
  
  .fad.fa-angle-90::after, .fa-duotone.fa-angle-90::after {
	content: "\E08D\E08D"; }
  
  .fad.fa-rectangle-terminal::after, .fa-duotone.fa-rectangle-terminal::after {
	content: "\E236\E236"; }
  
  .fad.fa-kite::after, .fa-duotone.fa-kite::after {
	content: "\F6F4\F6F4"; }
  
  .fad.fa-drum::after, .fa-duotone.fa-drum::after {
	content: "\F569\F569"; }
  
  .fad.fa-scrubber::after, .fa-duotone.fa-scrubber::after {
	content: "\F2F8\F2F8"; }
  
  .fad.fa-ice-cream::after, .fa-duotone.fa-ice-cream::after {
	content: "\F810\F810"; }
  
  .fad.fa-heart-circle-bolt::after, .fa-duotone.fa-heart-circle-bolt::after {
	content: "\E4FC\E4FC"; }
  
  .fad.fa-fish-bones::after, .fa-duotone.fa-fish-bones::after {
	content: "\E304\E304"; }
  
  .fad.fa-deer-rudolph::after, .fa-duotone.fa-deer-rudolph::after {
	content: "\F78F\F78F"; }
  
  .fad.fa-fax::after, .fa-duotone.fa-fax::after {
	content: "\F1AC\F1AC"; }
  
  .fad.fa-paragraph::after, .fa-duotone.fa-paragraph::after {
	content: "\F1DD\F1DD"; }
  
  .fad.fa-head-side-heart::after, .fa-duotone.fa-head-side-heart::after {
	content: "\E1AA\E1AA"; }
  
  .fad.fa-square-e::after, .fa-duotone.fa-square-e::after {
	content: "\E26D\E26D"; }
  
  .fad.fa-meter-fire::after, .fa-duotone.fa-meter-fire::after {
	content: "\E1EB\E1EB"; }
  
  .fad.fa-cloud-hail::after, .fa-duotone.fa-cloud-hail::after {
	content: "\F739\F739"; }
  
  .fad.fa-check-to-slot::after, .fa-duotone.fa-check-to-slot::after {
	content: "\F772\F772"; }
  
  .fad.fa-vote-yea::after, .fa-duotone.fa-vote-yea::after {
	content: "\F772\F772"; }
  
  .fad.fa-money-from-bracket::after, .fa-duotone.fa-money-from-bracket::after {
	content: "\E312\E312"; }
  
  .fad.fa-star-half::after, .fa-duotone.fa-star-half::after {
	content: "\F089\F089"; }
  
  .fad.fa-car-bus::after, .fa-duotone.fa-car-bus::after {
	content: "\F85A\F85A"; }
  
  .fad.fa-speaker::after, .fa-duotone.fa-speaker::after {
	content: "\F8DF\F8DF"; }
  
  .fad.fa-timer::after, .fa-duotone.fa-timer::after {
	content: "\E29E\E29E"; }
  
  .fad.fa-boxes-stacked::after, .fa-duotone.fa-boxes-stacked::after {
	content: "\F468\F468"; }
  
  .fad.fa-boxes::after, .fa-duotone.fa-boxes::after {
	content: "\F468\F468"; }
  
  .fad.fa-boxes-alt::after, .fa-duotone.fa-boxes-alt::after {
	content: "\F468\F468"; }
  
  .fad.fa-grill-hot::after, .fa-duotone.fa-grill-hot::after {
	content: "\E5A5\E5A5"; }
  
  .fad.fa-ballot-check::after, .fa-duotone.fa-ballot-check::after {
	content: "\F733\F733"; }
  
  .fad.fa-link::after, .fa-duotone.fa-link::after {
	content: "\F0C1\F0C1"; }
  
  .fad.fa-chain::after, .fa-duotone.fa-chain::after {
	content: "\F0C1\F0C1"; }
  
  .fad.fa-ear-listen::after, .fa-duotone.fa-ear-listen::after {
	content: "\F2A2\F2A2"; }
  
  .fad.fa-assistive-listening-systems::after, .fa-duotone.fa-assistive-listening-systems::after {
	content: "\F2A2\F2A2"; }
  
  .fad.fa-file-minus::after, .fa-duotone.fa-file-minus::after {
	content: "\F318\F318"; }
  
  .fad.fa-tree-city::after, .fa-duotone.fa-tree-city::after {
	content: "\E587\E587"; }
  
  .fad.fa-play::after, .fa-duotone.fa-play::after {
	content: "\F04B\F04B"; }
  
  .fad.fa-font::after, .fa-duotone.fa-font::after {
	content: "\F031\F031"; }
  
  .fad.fa-cup-togo::after, .fa-duotone.fa-cup-togo::after {
	content: "\F6C5\F6C5"; }
  
  .fad.fa-coffee-togo::after, .fa-duotone.fa-coffee-togo::after {
	content: "\F6C5\F6C5"; }
  
  .fad.fa-square-down-left::after, .fa-duotone.fa-square-down-left::after {
	content: "\E26B\E26B"; }
  
  .fad.fa-burger-lettuce::after, .fa-duotone.fa-burger-lettuce::after {
	content: "\E3E3\E3E3"; }
  
  .fad.fa-rupiah-sign::after, .fa-duotone.fa-rupiah-sign::after {
	content: "\E23D\E23D"; }
  
  .fad.fa-magnifying-glass::after, .fa-duotone.fa-magnifying-glass::after {
	content: "\F002\F002"; }
  
  .fad.fa-search::after, .fa-duotone.fa-search::after {
	content: "\F002\F002"; }
  
  .fad.fa-table-tennis-paddle-ball::after, .fa-duotone.fa-table-tennis-paddle-ball::after {
	content: "\F45D\F45D"; }
  
  .fad.fa-ping-pong-paddle-ball::after, .fa-duotone.fa-ping-pong-paddle-ball::after {
	content: "\F45D\F45D"; }
  
  .fad.fa-table-tennis::after, .fa-duotone.fa-table-tennis::after {
	content: "\F45D\F45D"; }
  
  .fad.fa-person-dots-from-line::after, .fa-duotone.fa-person-dots-from-line::after {
	content: "\F470\F470"; }
  
  .fad.fa-diagnoses::after, .fa-duotone.fa-diagnoses::after {
	content: "\F470\F470"; }
  
  .fad.fa-chevrons-down::after, .fa-duotone.fa-chevrons-down::after {
	content: "\F322\F322"; }
  
  .fad.fa-chevron-double-down::after, .fa-duotone.fa-chevron-double-down::after {
	content: "\F322\F322"; }
  
  .fad.fa-trash-can-arrow-up::after, .fa-duotone.fa-trash-can-arrow-up::after {
	content: "\F82A\F82A"; }
  
  .fad.fa-trash-restore-alt::after, .fa-duotone.fa-trash-restore-alt::after {
	content: "\F82A\F82A"; }
  
  .fad.fa-signal-good::after, .fa-duotone.fa-signal-good::after {
	content: "\F68E\F68E"; }
  
  .fad.fa-signal-3::after, .fa-duotone.fa-signal-3::after {
	content: "\F68E\F68E"; }
  
  .fad.fa-location-question::after, .fa-duotone.fa-location-question::after {
	content: "\F60B\F60B"; }
  
  .fad.fa-map-marker-question::after, .fa-duotone.fa-map-marker-question::after {
	content: "\F60B\F60B"; }
  
  .fad.fa-floppy-disk-circle-xmark::after, .fa-duotone.fa-floppy-disk-circle-xmark::after {
	content: "\E181\E181"; }
  
  .fad.fa-floppy-disk-times::after, .fa-duotone.fa-floppy-disk-times::after {
	content: "\E181\E181"; }
  
  .fad.fa-save-circle-xmark::after, .fa-duotone.fa-save-circle-xmark::after {
	content: "\E181\E181"; }
  
  .fad.fa-save-times::after, .fa-duotone.fa-save-times::after {
	content: "\E181\E181"; }
  
  .fad.fa-naira-sign::after, .fa-duotone.fa-naira-sign::after {
	content: "\E1F6\E1F6"; }
  
  .fad.fa-peach::after, .fa-duotone.fa-peach::after {
	content: "\E20B\E20B"; }
  
  .fad.fa-taxi-bus::after, .fa-duotone.fa-taxi-bus::after {
	content: "\E298\E298"; }
  
  .fad.fa-bracket-curly::after, .fa-duotone.fa-bracket-curly::after {
	content: "{{"; }
  
  .fad.fa-bracket-curly-left::after, .fa-duotone.fa-bracket-curly-left::after {
	content: "{{"; }
  
  .fad.fa-lobster::after, .fa-duotone.fa-lobster::after {
	content: "\E421\E421"; }
  
  .fad.fa-cart-flatbed-empty::after, .fa-duotone.fa-cart-flatbed-empty::after {
	content: "\F476\F476"; }
  
  .fad.fa-dolly-flatbed-empty::after, .fa-duotone.fa-dolly-flatbed-empty::after {
	content: "\F476\F476"; }
  
  .fad.fa-colon::after, .fa-duotone.fa-colon::after {
	content: "::"; }
  
  .fad.fa-cart-arrow-down::after, .fa-duotone.fa-cart-arrow-down::after {
	content: "\F218\F218"; }
  
  .fad.fa-wand::after, .fa-duotone.fa-wand::after {
	content: "\F72A\F72A"; }
  
  .fad.fa-walkie-talkie::after, .fa-duotone.fa-walkie-talkie::after {
	content: "\F8EF\F8EF"; }
  
  .fad.fa-file-pen::after, .fa-duotone.fa-file-pen::after {
	content: "\F31C\F31C"; }
  
  .fad.fa-file-edit::after, .fa-duotone.fa-file-edit::after {
	content: "\F31C\F31C"; }
  
  .fad.fa-receipt::after, .fa-duotone.fa-receipt::after {
	content: "\F543\F543"; }
  
  .fad.fa-table-picnic::after, .fa-duotone.fa-table-picnic::after {
	content: "\E32D\E32D"; }
  
  .fad.fa-square-pen::after, .fa-duotone.fa-square-pen::after {
	content: "\F14B\F14B"; }
  
  .fad.fa-pen-square::after, .fa-duotone.fa-pen-square::after {
	content: "\F14B\F14B"; }
  
  .fad.fa-pencil-square::after, .fa-duotone.fa-pencil-square::after {
	content: "\F14B\F14B"; }
  
  .fad.fa-circle-microphone-lines::after, .fa-duotone.fa-circle-microphone-lines::after {
	content: "\E117\E117"; }
  
  .fad.fa-microphone-circle-alt::after, .fa-duotone.fa-microphone-circle-alt::after {
	content: "\E117\E117"; }
  
  .fad.fa-display-slash::after, .fa-duotone.fa-display-slash::after {
	content: "\E2FA\E2FA"; }
  
  .fad.fa-desktop-slash::after, .fa-duotone.fa-desktop-slash::after {
	content: "\E2FA\E2FA"; }
  
  .fad.fa-suitcase-rolling::after, .fa-duotone.fa-suitcase-rolling::after {
	content: "\F5C1\F5C1"; }
  
  .fad.fa-person-circle-exclamation::after, .fa-duotone.fa-person-circle-exclamation::after {
	content: "\E53F\E53F"; }
  
  .fad.fa-transporter-2::after, .fa-duotone.fa-transporter-2::after {
	content: "\E044\E044"; }
  
  .fad.fa-hands-holding-diamond::after, .fa-duotone.fa-hands-holding-diamond::after {
	content: "\F47C\F47C"; }
  
  .fad.fa-hand-receiving::after, .fa-duotone.fa-hand-receiving::after {
	content: "\F47C\F47C"; }
  
  .fad.fa-money-bill-simple-wave::after, .fa-duotone.fa-money-bill-simple-wave::after {
	content: "\E1F2\E1F2"; }
  
  .fad.fa-chevron-down::after, .fa-duotone.fa-chevron-down::after {
	content: "\F078\F078"; }
  
  .fad.fa-battery-full::after, .fa-duotone.fa-battery-full::after {
	content: "\F240\F240"; }
  
  .fad.fa-battery::after, .fa-duotone.fa-battery::after {
	content: "\F240\F240"; }
  
  .fad.fa-battery-5::after, .fa-duotone.fa-battery-5::after {
	content: "\F240\F240"; }
  
  .fad.fa-bell-plus::after, .fa-duotone.fa-bell-plus::after {
	content: "\F849\F849"; }
  
  .fad.fa-book-arrow-right::after, .fa-duotone.fa-book-arrow-right::after {
	content: "\E0B9\E0B9"; }
  
  .fad.fa-hospitals::after, .fa-duotone.fa-hospitals::after {
	content: "\F80E\F80E"; }
  
  .fad.fa-club::after, .fa-duotone.fa-club::after {
	content: "\F327\F327"; }
  
  .fad.fa-skull-crossbones::after, .fa-duotone.fa-skull-crossbones::after {
	content: "\F714\F714"; }
  
  .fad.fa-droplet-degree::after, .fa-duotone.fa-droplet-degree::after {
	content: "\F748\F748"; }
  
  .fad.fa-dewpoint::after, .fa-duotone.fa-dewpoint::after {
	content: "\F748\F748"; }
  
  .fad.fa-code-compare::after, .fa-duotone.fa-code-compare::after {
	content: "\E13A\E13A"; }
  
  .fad.fa-list-ul::after, .fa-duotone.fa-list-ul::after {
	content: "\F0CA\F0CA"; }
  
  .fad.fa-list-dots::after, .fa-duotone.fa-list-dots::after {
	content: "\F0CA\F0CA"; }
  
  .fad.fa-hand-holding-magic::after, .fa-duotone.fa-hand-holding-magic::after {
	content: "\F6E5\F6E5"; }
  
  .fad.fa-watermelon-slice::after, .fa-duotone.fa-watermelon-slice::after {
	content: "\E337\E337"; }
  
  .fad.fa-circle-ellipsis::after, .fa-duotone.fa-circle-ellipsis::after {
	content: "\E10A\E10A"; }
  
  .fad.fa-school-lock::after, .fa-duotone.fa-school-lock::after {
	content: "\E56F\E56F"; }
  
  .fad.fa-tower-cell::after, .fa-duotone.fa-tower-cell::after {
	content: "\E585\E585"; }
  
  .fad.fa-sd-cards::after, .fa-duotone.fa-sd-cards::after {
	content: "\E240\E240"; }
  
  .fad.fa-down-long::after, .fa-duotone.fa-down-long::after {
	content: "\F309\F309"; }
  
  .fad.fa-long-arrow-alt-down::after, .fa-duotone.fa-long-arrow-alt-down::after {
	content: "\F309\F309"; }
  
  .fad.fa-envelopes::after, .fa-duotone.fa-envelopes::after {
	content: "\E170\E170"; }
  
  .fad.fa-phone-office::after, .fa-duotone.fa-phone-office::after {
	content: "\F67D\F67D"; }
  
  .fad.fa-ranking-star::after, .fa-duotone.fa-ranking-star::after {
	content: "\E561\E561"; }
  
  .fad.fa-chess-king::after, .fa-duotone.fa-chess-king::after {
	content: "\F43F\F43F"; }
  
  .fad.fa-nfc-pen::after, .fa-duotone.fa-nfc-pen::after {
	content: "\E1FA\E1FA"; }
  
  .fad.fa-person-harassing::after, .fa-duotone.fa-person-harassing::after {
	content: "\E549\E549"; }
  
  .fad.fa-hat-winter::after, .fa-duotone.fa-hat-winter::after {
	content: "\F7A8\F7A8"; }
  
  .fad.fa-brazilian-real-sign::after, .fa-duotone.fa-brazilian-real-sign::after {
	content: "\E46C\E46C"; }
  
  .fad.fa-landmark-dome::after, .fa-duotone.fa-landmark-dome::after {
	content: "\F752\F752"; }
  
  .fad.fa-landmark-alt::after, .fa-duotone.fa-landmark-alt::after {
	content: "\F752\F752"; }
  
  .fad.fa-bone-break::after, .fa-duotone.fa-bone-break::after {
	content: "\F5D8\F5D8"; }
  
  .fad.fa-arrow-up::after, .fa-duotone.fa-arrow-up::after {
	content: "\F062\F062"; }
  
  .fad.fa-down-from-dotted-line::after, .fa-duotone.fa-down-from-dotted-line::after {
	content: "\E407\E407"; }
  
  .fad.fa-tv::after, .fa-duotone.fa-tv::after {
	content: "\F26C\F26C"; }
  
  .fad.fa-television::after, .fa-duotone.fa-television::after {
	content: "\F26C\F26C"; }
  
  .fad.fa-tv-alt::after, .fa-duotone.fa-tv-alt::after {
	content: "\F26C\F26C"; }
  
  .fad.fa-border-left::after, .fa-duotone.fa-border-left::after {
	content: "\F84F\F84F"; }
  
  .fad.fa-circle-divide::after, .fa-duotone.fa-circle-divide::after {
	content: "\E106\E106"; }
  
  .fad.fa-shrimp::after, .fa-duotone.fa-shrimp::after {
	content: "\E448\E448"; }
  
  .fad.fa-list-check::after, .fa-duotone.fa-list-check::after {
	content: "\F0AE\F0AE"; }
  
  .fad.fa-tasks::after, .fa-duotone.fa-tasks::after {
	content: "\F0AE\F0AE"; }
  
  .fad.fa-diagram-subtask::after, .fa-duotone.fa-diagram-subtask::after {
	content: "\E479\E479"; }
  
  .fad.fa-jug-detergent::after, .fa-duotone.fa-jug-detergent::after {
	content: "\E519\E519"; }
  
  .fad.fa-circle-user::after, .fa-duotone.fa-circle-user::after {
	content: "\F2BD\F2BD"; }
  
  .fad.fa-user-circle::after, .fa-duotone.fa-user-circle::after {
	content: "\F2BD\F2BD"; }
  
  .fad.fa-square-y::after, .fa-duotone.fa-square-y::after {
	content: "\E287\E287"; }
  
  .fad.fa-user-doctor-hair::after, .fa-duotone.fa-user-doctor-hair::after {
	content: "\E458\E458"; }
  
  .fad.fa-planet-ringed::after, .fa-duotone.fa-planet-ringed::after {
	content: "\E020\E020"; }
  
  .fad.fa-mushroom::after, .fa-duotone.fa-mushroom::after {
	content: "\E425\E425"; }
  
  .fad.fa-user-shield::after, .fa-duotone.fa-user-shield::after {
	content: "\F505\F505"; }
  
  .fad.fa-megaphone::after, .fa-duotone.fa-megaphone::after {
	content: "\F675\F675"; }
  
  .fad.fa-circle-exclamation-check::after, .fa-duotone.fa-circle-exclamation-check::after {
	content: "\E10D\E10D"; }
  
  .fad.fa-wind::after, .fa-duotone.fa-wind::after {
	content: "\F72E\F72E"; }
  
  .fad.fa-box-dollar::after, .fa-duotone.fa-box-dollar::after {
	content: "\F4A0\F4A0"; }
  
  .fad.fa-box-usd::after, .fa-duotone.fa-box-usd::after {
	content: "\F4A0\F4A0"; }
  
  .fad.fa-car-burst::after, .fa-duotone.fa-car-burst::after {
	content: "\F5E1\F5E1"; }
  
  .fad.fa-car-crash::after, .fa-duotone.fa-car-crash::after {
	content: "\F5E1\F5E1"; }
  
  .fad.fa-y::after, .fa-duotone.fa-y::after {
	content: "YY"; }
  
  .fad.fa-user-headset::after, .fa-duotone.fa-user-headset::after {
	content: "\F82D\F82D"; }
  
  .fad.fa-arrows-retweet::after, .fa-duotone.fa-arrows-retweet::after {
	content: "\F361\F361"; }
  
  .fad.fa-retweet-alt::after, .fa-duotone.fa-retweet-alt::after {
	content: "\F361\F361"; }
  
  .fad.fa-person-snowboarding::after, .fa-duotone.fa-person-snowboarding::after {
	content: "\F7CE\F7CE"; }
  
  .fad.fa-snowboarding::after, .fa-duotone.fa-snowboarding::after {
	content: "\F7CE\F7CE"; }
  
  .fad.fa-square-chevron-right::after, .fa-duotone.fa-square-chevron-right::after {
	content: "\F32B\F32B"; }
  
  .fad.fa-chevron-square-right::after, .fa-duotone.fa-chevron-square-right::after {
	content: "\F32B\F32B"; }
  
  .fad.fa-lacrosse-stick-ball::after, .fa-duotone.fa-lacrosse-stick-ball::after {
	content: "\E3B6\E3B6"; }
  
  .fad.fa-truck-fast::after, .fa-duotone.fa-truck-fast::after {
	content: "\F48B\F48B"; }
  
  .fad.fa-shipping-fast::after, .fa-duotone.fa-shipping-fast::after {
	content: "\F48B\F48B"; }
  
  .fad.fa-star-sharp::after, .fa-duotone.fa-star-sharp::after {
	content: "\E28B\E28B"; }
  
  .fad.fa-circle-1::after, .fa-duotone.fa-circle-1::after {
	content: "\E0EE\E0EE"; }
  
  .fad.fa-circle-star::after, .fa-duotone.fa-circle-star::after {
	content: "\E123\E123"; }
  
  .fad.fa-star-circle::after, .fa-duotone.fa-star-circle::after {
	content: "\E123\E123"; }
  
  .fad.fa-fish::after, .fa-duotone.fa-fish::after {
	content: "\F578\F578"; }
  
  .fad.fa-cloud-fog::after, .fa-duotone.fa-cloud-fog::after {
	content: "\F74E\F74E"; }
  
  .fad.fa-fog::after, .fa-duotone.fa-fog::after {
	content: "\F74E\F74E"; }
  
  .fad.fa-waffle::after, .fa-duotone.fa-waffle::after {
	content: "\E466\E466"; }
  
  .fad.fa-music-note::after, .fa-duotone.fa-music-note::after {
	content: "\F8CF\F8CF"; }
  
  .fad.fa-music-alt::after, .fa-duotone.fa-music-alt::after {
	content: "\F8CF\F8CF"; }
  
  .fad.fa-hexagon-exclamation::after, .fa-duotone.fa-hexagon-exclamation::after {
	content: "\E417\E417"; }
  
  .fad.fa-cart-shopping-fast::after, .fa-duotone.fa-cart-shopping-fast::after {
	content: "\E0DC\E0DC"; }
  
  .fad.fa-object-union::after, .fa-duotone.fa-object-union::after {
	content: "\E49F\E49F"; }
  
  .fad.fa-user-graduate::after, .fa-duotone.fa-user-graduate::after {
	content: "\F501\F501"; }
  
  .fad.fa-starfighter::after, .fa-duotone.fa-starfighter::after {
	content: "\E037\E037"; }
  
  .fad.fa-circle-half-stroke::after, .fa-duotone.fa-circle-half-stroke::after {
	content: "\F042\F042"; }
  
  .fad.fa-adjust::after, .fa-duotone.fa-adjust::after {
	content: "\F042\F042"; }
  
  .fad.fa-arrow-right-long-to-line::after, .fa-duotone.fa-arrow-right-long-to-line::after {
	content: "\E3D5\E3D5"; }
  
  .fad.fa-square-arrow-down::after, .fa-duotone.fa-square-arrow-down::after {
	content: "\F339\F339"; }
  
  .fad.fa-arrow-square-down::after, .fa-duotone.fa-arrow-square-down::after {
	content: "\F339\F339"; }
  
  .fad.fa-clapperboard::after, .fa-duotone.fa-clapperboard::after {
	content: "\E131\E131"; }
  
  .fad.fa-square-chevron-left::after, .fa-duotone.fa-square-chevron-left::after {
	content: "\F32A\F32A"; }
  
  .fad.fa-chevron-square-left::after, .fa-duotone.fa-chevron-square-left::after {
	content: "\F32A\F32A"; }
  
  .fad.fa-phone-intercom::after, .fa-duotone.fa-phone-intercom::after {
	content: "\E434\E434"; }
  
  .fad.fa-link-horizontal::after, .fa-duotone.fa-link-horizontal::after {
	content: "\E1CB\E1CB"; }
  
  .fad.fa-chain-horizontal::after, .fa-duotone.fa-chain-horizontal::after {
	content: "\E1CB\E1CB"; }
  
  .fad.fa-mango::after, .fa-duotone.fa-mango::after {
	content: "\E30F\E30F"; }
  
  .fad.fa-music-note-slash::after, .fa-duotone.fa-music-note-slash::after {
	content: "\F8D0\F8D0"; }
  
  .fad.fa-music-alt-slash::after, .fa-duotone.fa-music-alt-slash::after {
	content: "\F8D0\F8D0"; }
  
  .fad.fa-circle-radiation::after, .fa-duotone.fa-circle-radiation::after {
	content: "\F7BA\F7BA"; }
  
  .fad.fa-radiation-alt::after, .fa-duotone.fa-radiation-alt::after {
	content: "\F7BA\F7BA"; }
  
  .fad.fa-face-tongue-sweat::after, .fa-duotone.fa-face-tongue-sweat::after {
	content: "\E39E\E39E"; }
  
  .fad.fa-globe-stand::after, .fa-duotone.fa-globe-stand::after {
	content: "\F5F6\F5F6"; }
  
  .fad.fa-baseball::after, .fa-duotone.fa-baseball::after {
	content: "\F433\F433"; }
  
  .fad.fa-baseball-ball::after, .fa-duotone.fa-baseball-ball::after {
	content: "\F433\F433"; }
  
  .fad.fa-circle-p::after, .fa-duotone.fa-circle-p::after {
	content: "\E11A\E11A"; }
  
  .fad.fa-award-simple::after, .fa-duotone.fa-award-simple::after {
	content: "\E0AB\E0AB"; }
  
  .fad.fa-jet-fighter-up::after, .fa-duotone.fa-jet-fighter-up::after {
	content: "\E518\E518"; }
  
  .fad.fa-diagram-project::after, .fa-duotone.fa-diagram-project::after {
	content: "\F542\F542"; }
  
  .fad.fa-project-diagram::after, .fa-duotone.fa-project-diagram::after {
	content: "\F542\F542"; }
  
  .fad.fa-pedestal::after, .fa-duotone.fa-pedestal::after {
	content: "\E20D\E20D"; }
  
  .fad.fa-chart-pyramid::after, .fa-duotone.fa-chart-pyramid::after {
	content: "\E0E6\E0E6"; }
  
  .fad.fa-sidebar::after, .fa-duotone.fa-sidebar::after {
	content: "\E24E\E24E"; }
  
  .fad.fa-snowman-head::after, .fa-duotone.fa-snowman-head::after {
	content: "\F79B\F79B"; }
  
  .fad.fa-frosty-head::after, .fa-duotone.fa-frosty-head::after {
	content: "\F79B\F79B"; }
  
  .fad.fa-copy::after, .fa-duotone.fa-copy::after {
	content: "\F0C5\F0C5"; }
  
  .fad.fa-burger-glass::after, .fa-duotone.fa-burger-glass::after {
	content: "\E0CE\E0CE"; }
  
  .fad.fa-volume-xmark::after, .fa-duotone.fa-volume-xmark::after {
	content: "\F6A9\F6A9"; }
  
  .fad.fa-volume-mute::after, .fa-duotone.fa-volume-mute::after {
	content: "\F6A9\F6A9"; }
  
  .fad.fa-volume-times::after, .fa-duotone.fa-volume-times::after {
	content: "\F6A9\F6A9"; }
  
  .fad.fa-hand-sparkles::after, .fa-duotone.fa-hand-sparkles::after {
	content: "\E05D\E05D"; }
  
  .fad.fa-bars-filter::after, .fa-duotone.fa-bars-filter::after {
	content: "\E0AD\E0AD"; }
  
  .fad.fa-paintbrush-pencil::after, .fa-duotone.fa-paintbrush-pencil::after {
	content: "\E206\E206"; }
  
  .fad.fa-party-bell::after, .fa-duotone.fa-party-bell::after {
	content: "\E31A\E31A"; }
  
  .fad.fa-user-vneck-hair::after, .fa-duotone.fa-user-vneck-hair::after {
	content: "\E462\E462"; }
  
  .fad.fa-jack-o-lantern::after, .fa-duotone.fa-jack-o-lantern::after {
	content: "\F30E\F30E"; }
  
  .fad.fa-grip::after, .fa-duotone.fa-grip::after {
	content: "\F58D\F58D"; }
  
  .fad.fa-grip-horizontal::after, .fa-duotone.fa-grip-horizontal::after {
	content: "\F58D\F58D"; }
  
  .fad.fa-share-from-square::after, .fa-duotone.fa-share-from-square::after {
	content: "\F14D\F14D"; }
  
  .fad.fa-share-square::after, .fa-duotone.fa-share-square::after {
	content: "\F14D\F14D"; }
  
  .fad.fa-keynote::after, .fa-duotone.fa-keynote::after {
	content: "\F66C\F66C"; }
  
  .fad.fa-gun::after, .fa-duotone.fa-gun::after {
	content: "\E19B\E19B"; }
  
  .fad.fa-square-phone::after, .fa-duotone.fa-square-phone::after {
	content: "\F098\F098"; }
  
  .fad.fa-phone-square::after, .fa-duotone.fa-phone-square::after {
	content: "\F098\F098"; }
  
  .fad.fa-plus::after, .fa-duotone.fa-plus::after {
	content: "++"; }
  
  .fad.fa-add::after, .fa-duotone.fa-add::after {
	content: "++"; }
  
  .fad.fa-expand::after, .fa-duotone.fa-expand::after {
	content: "\F065\F065"; }
  
  .fad.fa-computer::after, .fa-duotone.fa-computer::after {
	content: "\E4E5\E4E5"; }
  
  .fad.fa-fort::after, .fa-duotone.fa-fort::after {
	content: "\E486\E486"; }
  
  .fad.fa-cloud-check::after, .fa-duotone.fa-cloud-check::after {
	content: "\E35C\E35C"; }
  
  .fad.fa-xmark::after, .fa-duotone.fa-xmark::after {
	content: "\F00D\F00D"; }
  
  .fad.fa-close::after, .fa-duotone.fa-close::after {
	content: "\F00D\F00D"; }
  
  .fad.fa-multiply::after, .fa-duotone.fa-multiply::after {
	content: "\F00D\F00D"; }
  
  .fad.fa-remove::after, .fa-duotone.fa-remove::after {
	content: "\F00D\F00D"; }
  
  .fad.fa-times::after, .fa-duotone.fa-times::after {
	content: "\F00D\F00D"; }
  
  .fad.fa-face-smirking::after, .fa-duotone.fa-face-smirking::after {
	content: "\E397\E397"; }
  
  .fad.fa-arrows-up-down-left-right::after, .fa-duotone.fa-arrows-up-down-left-right::after {
	content: "\F047\F047"; }
  
  .fad.fa-arrows::after, .fa-duotone.fa-arrows::after {
	content: "\F047\F047"; }
  
  .fad.fa-chalkboard-user::after, .fa-duotone.fa-chalkboard-user::after {
	content: "\F51C\F51C"; }
  
  .fad.fa-chalkboard-teacher::after, .fa-duotone.fa-chalkboard-teacher::after {
	content: "\F51C\F51C"; }
  
  .fad.fa-rhombus::after, .fa-duotone.fa-rhombus::after {
	content: "\E23B\E23B"; }
  
  .fad.fa-claw-marks::after, .fa-duotone.fa-claw-marks::after {
	content: "\F6C2\F6C2"; }
  
  .fad.fa-peso-sign::after, .fa-duotone.fa-peso-sign::after {
	content: "\E222\E222"; }
  
  .fad.fa-face-smile-tongue::after, .fa-duotone.fa-face-smile-tongue::after {
	content: "\E394\E394"; }
  
  .fad.fa-cart-circle-xmark::after, .fa-duotone.fa-cart-circle-xmark::after {
	content: "\E3F4\E3F4"; }
  
  .fad.fa-building-shield::after, .fa-duotone.fa-building-shield::after {
	content: "\E4D8\E4D8"; }
  
  .fad.fa-circle-phone-flip::after, .fa-duotone.fa-circle-phone-flip::after {
	content: "\E11C\E11C"; }
  
  .fad.fa-phone-circle-alt::after, .fa-duotone.fa-phone-circle-alt::after {
	content: "\E11C\E11C"; }
  
  .fad.fa-baby::after, .fa-duotone.fa-baby::after {
	content: "\F77C\F77C"; }
  
  .fad.fa-users-line::after, .fa-duotone.fa-users-line::after {
	content: "\E592\E592"; }
  
  .fad.fa-quote-left::after, .fa-duotone.fa-quote-left::after {
	content: "\F10D\F10D"; }
  
  .fad.fa-quote-left-alt::after, .fa-duotone.fa-quote-left-alt::after {
	content: "\F10D\F10D"; }
  
  .fad.fa-tractor::after, .fa-duotone.fa-tractor::after {
	content: "\F722\F722"; }
  
  .fad.fa-key-skeleton::after, .fa-duotone.fa-key-skeleton::after {
	content: "\F6F3\F6F3"; }
  
  .fad.fa-trash-arrow-up::after, .fa-duotone.fa-trash-arrow-up::after {
	content: "\F829\F829"; }
  
  .fad.fa-trash-restore::after, .fa-duotone.fa-trash-restore::after {
	content: "\F829\F829"; }
  
  .fad.fa-arrow-down-up-lock::after, .fa-duotone.fa-arrow-down-up-lock::after {
	content: "\E4B0\E4B0"; }
  
  .fad.fa-arrow-down-to-bracket::after, .fa-duotone.fa-arrow-down-to-bracket::after {
	content: "\E094\E094"; }
  
  .fad.fa-lines-leaning::after, .fa-duotone.fa-lines-leaning::after {
	content: "\E51E\E51E"; }
  
  .fad.fa-square-q::after, .fa-duotone.fa-square-q::after {
	content: "\E27B\E27B"; }
  
  .fad.fa-ruler-combined::after, .fa-duotone.fa-ruler-combined::after {
	content: "\F546\F546"; }
  
  .fad.fa-symbols::after, .fa-duotone.fa-symbols::after {
	content: "\F86E\F86E"; }
  
  .fad.fa-icons-alt::after, .fa-duotone.fa-icons-alt::after {
	content: "\F86E\F86E"; }
  
  .fad.fa-copyright::after, .fa-duotone.fa-copyright::after {
	content: "\F1F9\F1F9"; }
  
  .fad.fa-highlighter-line::after, .fa-duotone.fa-highlighter-line::after {
	content: "\E1AF\E1AF"; }
  
  .fad.fa-bracket-square::after, .fa-duotone.fa-bracket-square::after {
	content: "[["; }
  
  .fad.fa-bracket::after, .fa-duotone.fa-bracket::after {
	content: "[["; }
  
  .fad.fa-bracket-left::after, .fa-duotone.fa-bracket-left::after {
	content: "[["; }
  
  .fad.fa-island-tropical::after, .fa-duotone.fa-island-tropical::after {
	content: "\F811\F811"; }
  
  .fad.fa-island-tree-palm::after, .fa-duotone.fa-island-tree-palm::after {
	content: "\F811\F811"; }
  
  .fad.fa-arrow-right-from-line::after, .fa-duotone.fa-arrow-right-from-line::after {
	content: "\F343\F343"; }
  
  .fad.fa-arrow-from-left::after, .fa-duotone.fa-arrow-from-left::after {
	content: "\F343\F343"; }
  
  .fad.fa-h2::after, .fa-duotone.fa-h2::after {
	content: "\F314\F314"; }
  
  .fad.fa-equals::after, .fa-duotone.fa-equals::after {
	content: "=="; }
  
  .fad.fa-cake-slice::after, .fa-duotone.fa-cake-slice::after {
	content: "\E3E5\E3E5"; }
  
  .fad.fa-shortcake::after, .fa-duotone.fa-shortcake::after {
	content: "\E3E5\E3E5"; }
  
  .fad.fa-peanut::after, .fa-duotone.fa-peanut::after {
	content: "\E430\E430"; }
  
  .fad.fa-wrench-simple::after, .fa-duotone.fa-wrench-simple::after {
	content: "\E2D1\E2D1"; }
  
  .fad.fa-blender::after, .fa-duotone.fa-blender::after {
	content: "\F517\F517"; }
  
  .fad.fa-teeth::after, .fa-duotone.fa-teeth::after {
	content: "\F62E\F62E"; }
  
  .fad.fa-tally-2::after, .fa-duotone.fa-tally-2::after {
	content: "\E295\E295"; }
  
  .fad.fa-shekel-sign::after, .fa-duotone.fa-shekel-sign::after {
	content: "\F20B\F20B"; }
  
  .fad.fa-ils::after, .fa-duotone.fa-ils::after {
	content: "\F20B\F20B"; }
  
  .fad.fa-shekel::after, .fa-duotone.fa-shekel::after {
	content: "\F20B\F20B"; }
  
  .fad.fa-sheqel::after, .fa-duotone.fa-sheqel::after {
	content: "\F20B\F20B"; }
  
  .fad.fa-sheqel-sign::after, .fa-duotone.fa-sheqel-sign::after {
	content: "\F20B\F20B"; }
  
  .fad.fa-cars::after, .fa-duotone.fa-cars::after {
	content: "\F85B\F85B"; }
  
  .fad.fa-axe-battle::after, .fa-duotone.fa-axe-battle::after {
	content: "\F6B3\F6B3"; }
  
  .fad.fa-user-hair-long::after, .fa-duotone.fa-user-hair-long::after {
	content: "\E45B\E45B"; }
  
  .fad.fa-map::after, .fa-duotone.fa-map::after {
	content: "\F279\F279"; }
  
  .fad.fa-file-circle-info::after, .fa-duotone.fa-file-circle-info::after {
	content: "\E493\E493"; }
  
  .fad.fa-face-disappointed::after, .fa-duotone.fa-face-disappointed::after {
	content: "\E36F\E36F"; }
  
  .fad.fa-lasso-sparkles::after, .fa-duotone.fa-lasso-sparkles::after {
	content: "\E1C9\E1C9"; }
  
  .fad.fa-clock-eleven::after, .fa-duotone.fa-clock-eleven::after {
	content: "\E347\E347"; }
  
  .fad.fa-rocket::after, .fa-duotone.fa-rocket::after {
	content: "\F135\F135"; }
  
  .fad.fa-siren-on::after, .fa-duotone.fa-siren-on::after {
	content: "\E02E\E02E"; }
  
  .fad.fa-clock-ten::after, .fa-duotone.fa-clock-ten::after {
	content: "\E354\E354"; }
  
  .fad.fa-candle-holder::after, .fa-duotone.fa-candle-holder::after {
	content: "\F6BC\F6BC"; }
  
  .fad.fa-video-arrow-down-left::after, .fa-duotone.fa-video-arrow-down-left::after {
	content: "\E2C8\E2C8"; }
  
  .fad.fa-photo-film::after, .fa-duotone.fa-photo-film::after {
	content: "\F87C\F87C"; }
  
  .fad.fa-photo-video::after, .fa-duotone.fa-photo-video::after {
	content: "\F87C\F87C"; }
  
  .fad.fa-floppy-disk-circle-arrow-right::after, .fa-duotone.fa-floppy-disk-circle-arrow-right::after {
	content: "\E180\E180"; }
  
  .fad.fa-save-circle-arrow-right::after, .fa-duotone.fa-save-circle-arrow-right::after {
	content: "\E180\E180"; }
  
  .fad.fa-folder-minus::after, .fa-duotone.fa-folder-minus::after {
	content: "\F65D\F65D"; }
  
  .fad.fa-planet-moon::after, .fa-duotone.fa-planet-moon::after {
	content: "\E01F\E01F"; }
  
  .fad.fa-face-eyes-xmarks::after, .fa-duotone.fa-face-eyes-xmarks::after {
	content: "\E374\E374"; }
  
  .fad.fa-chart-scatter::after, .fa-duotone.fa-chart-scatter::after {
	content: "\F7EE\F7EE"; }
  
  .fad.fa-display-arrow-down::after, .fa-duotone.fa-display-arrow-down::after {
	content: "\E164\E164"; }
  
  .fad.fa-store::after, .fa-duotone.fa-store::after {
	content: "\F54E\F54E"; }
  
  .fad.fa-arrow-trend-up::after, .fa-duotone.fa-arrow-trend-up::after {
	content: "\E098\E098"; }
  
  .fad.fa-plug-circle-minus::after, .fa-duotone.fa-plug-circle-minus::after {
	content: "\E55E\E55E"; }
  
  .fad.fa-olive-branch::after, .fa-duotone.fa-olive-branch::after {
	content: "\E317\E317"; }
  
  .fad.fa-angle::after, .fa-duotone.fa-angle::after {
	content: "\E08C\E08C"; }
  
  .fad.fa-vacuum-robot::after, .fa-duotone.fa-vacuum-robot::after {
	content: "\E04E\E04E"; }
  
  .fad.fa-sign-hanging::after, .fa-duotone.fa-sign-hanging::after {
	content: "\F4D9\F4D9"; }
  
  .fad.fa-sign::after, .fa-duotone.fa-sign::after {
	content: "\F4D9\F4D9"; }
  
  .fad.fa-square-divide::after, .fa-duotone.fa-square-divide::after {
	content: "\E26A\E26A"; }
  
  .fad.fa-signal-stream-slash::after, .fa-duotone.fa-signal-stream-slash::after {
	content: "\E250\E250"; }
  
  .fad.fa-bezier-curve::after, .fa-duotone.fa-bezier-curve::after {
	content: "\F55B\F55B"; }
  
  .fad.fa-eye-dropper-half::after, .fa-duotone.fa-eye-dropper-half::after {
	content: "\E173\E173"; }
  
  .fad.fa-store-lock::after, .fa-duotone.fa-store-lock::after {
	content: "\E4A6\E4A6"; }
  
  .fad.fa-bell-slash::after, .fa-duotone.fa-bell-slash::after {
	content: "\F1F6\F1F6"; }
  
  .fad.fa-cloud-bolt-sun::after, .fa-duotone.fa-cloud-bolt-sun::after {
	content: "\F76E\F76E"; }
  
  .fad.fa-thunderstorm-sun::after, .fa-duotone.fa-thunderstorm-sun::after {
	content: "\F76E\F76E"; }
  
  .fad.fa-camera-slash::after, .fa-duotone.fa-camera-slash::after {
	content: "\E0D9\E0D9"; }
  
  .fad.fa-comment-quote::after, .fa-duotone.fa-comment-quote::after {
	content: "\E14C\E14C"; }
  
  .fad.fa-tablet::after, .fa-duotone.fa-tablet::after {
	content: "\F3FB\F3FB"; }
  
  .fad.fa-tablet-android::after, .fa-duotone.fa-tablet-android::after {
	content: "\F3FB\F3FB"; }
  
  .fad.fa-school-flag::after, .fa-duotone.fa-school-flag::after {
	content: "\E56E\E56E"; }
  
  .fad.fa-message-code::after, .fa-duotone.fa-message-code::after {
	content: "\E1DF\E1DF"; }
  
  .fad.fa-glass-half::after, .fa-duotone.fa-glass-half::after {
	content: "\E192\E192"; }
  
  .fad.fa-glass-half-empty::after, .fa-duotone.fa-glass-half-empty::after {
	content: "\E192\E192"; }
  
  .fad.fa-glass-half-full::after, .fa-duotone.fa-glass-half-full::after {
	content: "\E192\E192"; }
  
  .fad.fa-fill::after, .fa-duotone.fa-fill::after {
	content: "\F575\F575"; }
  
  .fad.fa-message-minus::after, .fa-duotone.fa-message-minus::after {
	content: "\F4A7\F4A7"; }
  
  .fad.fa-comment-alt-minus::after, .fa-duotone.fa-comment-alt-minus::after {
	content: "\F4A7\F4A7"; }
  
  .fad.fa-angle-up::after, .fa-duotone.fa-angle-up::after {
	content: "\F106\F106"; }
  
  .fad.fa-drumstick-bite::after, .fa-duotone.fa-drumstick-bite::after {
	content: "\F6D7\F6D7"; }
  
  .fad.fa-link-horizontal-slash::after, .fa-duotone.fa-link-horizontal-slash::after {
	content: "\E1CC\E1CC"; }
  
  .fad.fa-chain-horizontal-slash::after, .fa-duotone.fa-chain-horizontal-slash::after {
	content: "\E1CC\E1CC"; }
  
  .fad.fa-holly-berry::after, .fa-duotone.fa-holly-berry::after {
	content: "\F7AA\F7AA"; }
  
  .fad.fa-chevron-left::after, .fa-duotone.fa-chevron-left::after {
	content: "\F053\F053"; }
  
  .fad.fa-bacteria::after, .fa-duotone.fa-bacteria::after {
	content: "\E059\E059"; }
  
  .fad.fa-clouds::after, .fa-duotone.fa-clouds::after {
	content: "\F744\F744"; }
  
  .fad.fa-money-bill-simple::after, .fa-duotone.fa-money-bill-simple::after {
	content: "\E1F1\E1F1"; }
  
  .fad.fa-hand-lizard::after, .fa-duotone.fa-hand-lizard::after {
	content: "\F258\F258"; }
  
  .fad.fa-table-pivot::after, .fa-duotone.fa-table-pivot::after {
	content: "\E291\E291"; }
  
  .fad.fa-filter-slash::after, .fa-duotone.fa-filter-slash::after {
	content: "\E17D\E17D"; }
  
  .fad.fa-trash-can-undo::after, .fa-duotone.fa-trash-can-undo::after {
	content: "\F896\F896"; }
  
  .fad.fa-trash-can-arrow-turn-left::after, .fa-duotone.fa-trash-can-arrow-turn-left::after {
	content: "\F896\F896"; }
  
  .fad.fa-trash-undo-alt::after, .fa-duotone.fa-trash-undo-alt::after {
	content: "\F896\F896"; }
  
  .fad.fa-notdef::after, .fa-duotone.fa-notdef::after {
	content: "\E1FE\E1FE"; }
  
  .fad.fa-disease::after, .fa-duotone.fa-disease::after {
	content: "\F7FA\F7FA"; }
  
  .fad.fa-person-to-door::after, .fa-duotone.fa-person-to-door::after {
	content: "\E433\E433"; }
  
  .fad.fa-turntable::after, .fa-duotone.fa-turntable::after {
	content: "\F8E4\F8E4"; }
  
  .fad.fa-briefcase-medical::after, .fa-duotone.fa-briefcase-medical::after {
	content: "\F469\F469"; }
  
  .fad.fa-genderless::after, .fa-duotone.fa-genderless::after {
	content: "\F22D\F22D"; }
  
  .fad.fa-chevron-right::after, .fa-duotone.fa-chevron-right::after {
	content: "\F054\F054"; }
  
  .fad.fa-signal-weak::after, .fa-duotone.fa-signal-weak::after {
	content: "\F68C\F68C"; }
  
  .fad.fa-signal-1::after, .fa-duotone.fa-signal-1::after {
	content: "\F68C\F68C"; }
  
  .fad.fa-clock-five::after, .fa-duotone.fa-clock-five::after {
	content: "\E349\E349"; }
  
  .fad.fa-retweet::after, .fa-duotone.fa-retweet::after {
	content: "\F079\F079"; }
  
  .fad.fa-car-rear::after, .fa-duotone.fa-car-rear::after {
	content: "\F5DE\F5DE"; }
  
  .fad.fa-car-alt::after, .fa-duotone.fa-car-alt::after {
	content: "\F5DE\F5DE"; }
  
  .fad.fa-pump-soap::after, .fa-duotone.fa-pump-soap::after {
	content: "\E06B\E06B"; }
  
  .fad.fa-computer-classic::after, .fa-duotone.fa-computer-classic::after {
	content: "\F8B1\F8B1"; }
  
  .fad.fa-frame::after, .fa-duotone.fa-frame::after {
	content: "\E495\E495"; }
  
  .fad.fa-video-slash::after, .fa-duotone.fa-video-slash::after {
	content: "\F4E2\F4E2"; }
  
  .fad.fa-battery-quarter::after, .fa-duotone.fa-battery-quarter::after {
	content: "\F243\F243"; }
  
  .fad.fa-battery-2::after, .fa-duotone.fa-battery-2::after {
	content: "\F243\F243"; }
  
  .fad.fa-ellipsis-stroke::after, .fa-duotone.fa-ellipsis-stroke::after {
	content: "\F39B\F39B"; }
  
  .fad.fa-ellipsis-h-alt::after, .fa-duotone.fa-ellipsis-h-alt::after {
	content: "\F39B\F39B"; }
  
  .fad.fa-radio::after, .fa-duotone.fa-radio::after {
	content: "\F8D7\F8D7"; }
  
  .fad.fa-baby-carriage::after, .fa-duotone.fa-baby-carriage::after {
	content: "\F77D\F77D"; }
  
  .fad.fa-carriage-baby::after, .fa-duotone.fa-carriage-baby::after {
	content: "\F77D\F77D"; }
  
  .fad.fa-face-expressionless::after, .fa-duotone.fa-face-expressionless::after {
	content: "\E373\E373"; }
  
  .fad.fa-down-to-dotted-line::after, .fa-duotone.fa-down-to-dotted-line::after {
	content: "\E408\E408"; }
  
  .fad.fa-cloud-music::after, .fa-duotone.fa-cloud-music::after {
	content: "\F8AE\F8AE"; }
  
  .fad.fa-traffic-light::after, .fa-duotone.fa-traffic-light::after {
	content: "\F637\F637"; }
  
  .fad.fa-cloud-minus::after, .fa-duotone.fa-cloud-minus::after {
	content: "\E35D\E35D"; }
  
  .fad.fa-thermometer::after, .fa-duotone.fa-thermometer::after {
	content: "\F491\F491"; }
  
  .fad.fa-shield-minus::after, .fa-duotone.fa-shield-minus::after {
	content: "\E249\E249"; }
  
  .fad.fa-vr-cardboard::after, .fa-duotone.fa-vr-cardboard::after {
	content: "\F729\F729"; }
  
  .fad.fa-car-tilt::after, .fa-duotone.fa-car-tilt::after {
	content: "\F5E5\F5E5"; }
  
  .fad.fa-gauge-circle-minus::after, .fa-duotone.fa-gauge-circle-minus::after {
	content: "\E497\E497"; }
  
  .fad.fa-brightness-low::after, .fa-duotone.fa-brightness-low::after {
	content: "\E0CA\E0CA"; }
  
  .fad.fa-hand-middle-finger::after, .fa-duotone.fa-hand-middle-finger::after {
	content: "\F806\F806"; }
  
  .fad.fa-percent::after, .fa-duotone.fa-percent::after {
	content: "%%"; }
  
  .fad.fa-percentage::after, .fa-duotone.fa-percentage::after {
	content: "%%"; }
  
  .fad.fa-truck-moving::after, .fa-duotone.fa-truck-moving::after {
	content: "\F4DF\F4DF"; }
  
  .fad.fa-glass-water-droplet::after, .fa-duotone.fa-glass-water-droplet::after {
	content: "\E4F5\E4F5"; }
  
  .fad.fa-conveyor-belt::after, .fa-duotone.fa-conveyor-belt::after {
	content: "\F46E\F46E"; }
  
  .fad.fa-location-check::after, .fa-duotone.fa-location-check::after {
	content: "\F606\F606"; }
  
  .fad.fa-map-marker-check::after, .fa-duotone.fa-map-marker-check::after {
	content: "\F606\F606"; }
  
  .fad.fa-coin-vertical::after, .fa-duotone.fa-coin-vertical::after {
	content: "\E3FD\E3FD"; }
  
  .fad.fa-display::after, .fa-duotone.fa-display::after {
	content: "\E163\E163"; }
  
  .fad.fa-person-sign::after, .fa-duotone.fa-person-sign::after {
	content: "\F757\F757"; }
  
  .fad.fa-face-smile::after, .fa-duotone.fa-face-smile::after {
	content: "\F118\F118"; }
  
  .fad.fa-smile::after, .fa-duotone.fa-smile::after {
	content: "\F118\F118"; }
  
  .fad.fa-phone-hangup::after, .fa-duotone.fa-phone-hangup::after {
	content: "\E225\E225"; }
  
  .fad.fa-signature-slash::after, .fa-duotone.fa-signature-slash::after {
	content: "\E3CB\E3CB"; }
  
  .fad.fa-thumbtack::after, .fa-duotone.fa-thumbtack::after {
	content: "\F08D\F08D"; }
  
  .fad.fa-thumb-tack::after, .fa-duotone.fa-thumb-tack::after {
	content: "\F08D\F08D"; }
  
  .fad.fa-wheat-slash::after, .fa-duotone.fa-wheat-slash::after {
	content: "\E339\E339"; }
  
  .fad.fa-trophy::after, .fa-duotone.fa-trophy::after {
	content: "\F091\F091"; }
  
  .fad.fa-clouds-sun::after, .fa-duotone.fa-clouds-sun::after {
	content: "\F746\F746"; }
  
  .fad.fa-person-praying::after, .fa-duotone.fa-person-praying::after {
	content: "\F683\F683"; }
  
  .fad.fa-pray::after, .fa-duotone.fa-pray::after {
	content: "\F683\F683"; }
  
  .fad.fa-hammer::after, .fa-duotone.fa-hammer::after {
	content: "\F6E3\F6E3"; }
  
  .fad.fa-face-vomit::after, .fa-duotone.fa-face-vomit::after {
	content: "\E3A0\E3A0"; }
  
  .fad.fa-speakers::after, .fa-duotone.fa-speakers::after {
	content: "\F8E0\F8E0"; }
  
  .fad.fa-tty-answer::after, .fa-duotone.fa-tty-answer::after {
	content: "\E2B9\E2B9"; }
  
  .fad.fa-teletype-answer::after, .fa-duotone.fa-teletype-answer::after {
	content: "\E2B9\E2B9"; }
  
  .fad.fa-mug-tea-saucer::after, .fa-duotone.fa-mug-tea-saucer::after {
	content: "\E1F5\E1F5"; }
  
  .fad.fa-diagram-lean-canvas::after, .fa-duotone.fa-diagram-lean-canvas::after {
	content: "\E156\E156"; }
  
  .fad.fa-alt::after, .fa-duotone.fa-alt::after {
	content: "\E08A\E08A"; }
  
  .fad.fa-dial::after, .fa-duotone.fa-dial::after {
	content: "\E15B\E15B"; }
  
  .fad.fa-dial-med-high::after, .fa-duotone.fa-dial-med-high::after {
	content: "\E15B\E15B"; }
  
  .fad.fa-hand-peace::after, .fa-duotone.fa-hand-peace::after {
	content: "\F25B\F25B"; }
  
  .fad.fa-circle-trash::after, .fa-duotone.fa-circle-trash::after {
	content: "\E126\E126"; }
  
  .fad.fa-trash-circle::after, .fa-duotone.fa-trash-circle::after {
	content: "\E126\E126"; }
  
  .fad.fa-rotate::after, .fa-duotone.fa-rotate::after {
	content: "\F2F1\F2F1"; }
  
  .fad.fa-sync-alt::after, .fa-duotone.fa-sync-alt::after {
	content: "\F2F1\F2F1"; }
  
  .fad.fa-circle-quarters::after, .fa-duotone.fa-circle-quarters::after {
	content: "\E3F8\E3F8"; }
  
  .fad.fa-spinner::after, .fa-duotone.fa-spinner::after {
	content: "\F110\F110"; }
  
  .fad.fa-tower-control::after, .fa-duotone.fa-tower-control::after {
	content: "\E2A2\E2A2"; }
  
  .fad.fa-arrow-up-triangle-square::after, .fa-duotone.fa-arrow-up-triangle-square::after {
	content: "\F88A\F88A"; }
  
  .fad.fa-sort-shapes-up::after, .fa-duotone.fa-sort-shapes-up::after {
	content: "\F88A\F88A"; }
  
  .fad.fa-whale::after, .fa-duotone.fa-whale::after {
	content: "\F72C\F72C"; }
  
  .fad.fa-robot::after, .fa-duotone.fa-robot::after {
	content: "\F544\F544"; }
  
  .fad.fa-peace::after, .fa-duotone.fa-peace::after {
	content: "\F67C\F67C"; }
  
  .fad.fa-party-horn::after, .fa-duotone.fa-party-horn::after {
	content: "\E31B\E31B"; }
  
  .fad.fa-gears::after, .fa-duotone.fa-gears::after {
	content: "\F085\F085"; }
  
  .fad.fa-cogs::after, .fa-duotone.fa-cogs::after {
	content: "\F085\F085"; }
  
  .fad.fa-sun-bright::after, .fa-duotone.fa-sun-bright::after {
	content: "\E28F\E28F"; }
  
  .fad.fa-sun-alt::after, .fa-duotone.fa-sun-alt::after {
	content: "\E28F\E28F"; }
  
  .fad.fa-warehouse::after, .fa-duotone.fa-warehouse::after {
	content: "\F494\F494"; }
  
  .fad.fa-lock-keyhole-open::after, .fa-duotone.fa-lock-keyhole-open::after {
	content: "\F3C2\F3C2"; }
  
  .fad.fa-lock-open-alt::after, .fa-duotone.fa-lock-open-alt::after {
	content: "\F3C2\F3C2"; }
  
  .fad.fa-square-fragile::after, .fa-duotone.fa-square-fragile::after {
	content: "\F49B\F49B"; }
  
  .fad.fa-box-fragile::after, .fa-duotone.fa-box-fragile::after {
	content: "\F49B\F49B"; }
  
  .fad.fa-square-wine-glass-crack::after, .fa-duotone.fa-square-wine-glass-crack::after {
	content: "\F49B\F49B"; }
  
  .fad.fa-arrow-up-right-dots::after, .fa-duotone.fa-arrow-up-right-dots::after {
	content: "\E4B7\E4B7"; }
  
  .fad.fa-square-n::after, .fa-duotone.fa-square-n::after {
	content: "\E277\E277"; }
  
  .fad.fa-splotch::after, .fa-duotone.fa-splotch::after {
	content: "\F5BC\F5BC"; }
  
  .fad.fa-face-grin-hearts::after, .fa-duotone.fa-face-grin-hearts::after {
	content: "\F584\F584"; }
  
  .fad.fa-grin-hearts::after, .fa-duotone.fa-grin-hearts::after {
	content: "\F584\F584"; }
  
  .fad.fa-meter::after, .fa-duotone.fa-meter::after {
	content: "\E1E8\E1E8"; }
  
  .fad.fa-mandolin::after, .fa-duotone.fa-mandolin::after {
	content: "\F6F9\F6F9"; }
  
  .fad.fa-dice-four::after, .fa-duotone.fa-dice-four::after {
	content: "\F524\F524"; }
  
  .fad.fa-sim-card::after, .fa-duotone.fa-sim-card::after {
	content: "\F7C4\F7C4"; }
  
  .fad.fa-transgender::after, .fa-duotone.fa-transgender::after {
	content: "\F225\F225"; }
  
  .fad.fa-transgender-alt::after, .fa-duotone.fa-transgender-alt::after {
	content: "\F225\F225"; }
  
  .fad.fa-mercury::after, .fa-duotone.fa-mercury::after {
	content: "\F223\F223"; }
  
  .fad.fa-up-from-bracket::after, .fa-duotone.fa-up-from-bracket::after {
	content: "\E590\E590"; }
  
  .fad.fa-knife-kitchen::after, .fa-duotone.fa-knife-kitchen::after {
	content: "\F6F5\F6F5"; }
  
  .fad.fa-border-right::after, .fa-duotone.fa-border-right::after {
	content: "\F852\F852"; }
  
  .fad.fa-arrow-turn-down::after, .fa-duotone.fa-arrow-turn-down::after {
	content: "\F149\F149"; }
  
  .fad.fa-level-down::after, .fa-duotone.fa-level-down::after {
	content: "\F149\F149"; }
  
  .fad.fa-spade::after, .fa-duotone.fa-spade::after {
	content: "\F2F4\F2F4"; }
  
  .fad.fa-card-spade::after, .fa-duotone.fa-card-spade::after {
	content: "\E3EC\E3EC"; }
  
  .fad.fa-line-columns::after, .fa-duotone.fa-line-columns::after {
	content: "\F870\F870"; }
  
  .fad.fa-arrow-right-to-line::after, .fa-duotone.fa-arrow-right-to-line::after {
	content: "\F340\F340"; }
  
  .fad.fa-arrow-to-right::after, .fa-duotone.fa-arrow-to-right::after {
	content: "\F340\F340"; }
  
  .fad.fa-person-falling-burst::after, .fa-duotone.fa-person-falling-burst::after {
	content: "\E547\E547"; }
  
  .fad.fa-flag-pennant::after, .fa-duotone.fa-flag-pennant::after {
	content: "\F456\F456"; }
  
  .fad.fa-pennant::after, .fa-duotone.fa-pennant::after {
	content: "\F456\F456"; }
  
  .fad.fa-conveyor-belt-empty::after, .fa-duotone.fa-conveyor-belt-empty::after {
	content: "\E150\E150"; }
  
  .fad.fa-award::after, .fa-duotone.fa-award::after {
	content: "\F559\F559"; }
  
  .fad.fa-ticket-simple::after, .fa-duotone.fa-ticket-simple::after {
	content: "\F3FF\F3FF"; }
  
  .fad.fa-ticket-alt::after, .fa-duotone.fa-ticket-alt::after {
	content: "\F3FF\F3FF"; }
  
  .fad.fa-building::after, .fa-duotone.fa-building::after {
	content: "\F1AD\F1AD"; }
  
  .fad.fa-angles-left::after, .fa-duotone.fa-angles-left::after {
	content: "\F100\F100"; }
  
  .fad.fa-angle-double-left::after, .fa-duotone.fa-angle-double-left::after {
	content: "\F100\F100"; }
  
  .fad.fa-camcorder::after, .fa-duotone.fa-camcorder::after {
	content: "\F8A8\F8A8"; }
  
  .fad.fa-video-handheld::after, .fa-duotone.fa-video-handheld::after {
	content: "\F8A8\F8A8"; }
  
  .fad.fa-pancakes::after, .fa-duotone.fa-pancakes::after {
	content: "\E42D\E42D"; }
  
  .fad.fa-album-circle-user::after, .fa-duotone.fa-album-circle-user::after {
	content: "\E48D\E48D"; }
  
  .fad.fa-qrcode::after, .fa-duotone.fa-qrcode::after {
	content: "\F029\F029"; }
  
  .fad.fa-dice-d10::after, .fa-duotone.fa-dice-d10::after {
	content: "\F6CD\F6CD"; }
  
  .fad.fa-fireplace::after, .fa-duotone.fa-fireplace::after {
	content: "\F79A\F79A"; }
  
  .fad.fa-browser::after, .fa-duotone.fa-browser::after {
	content: "\F37E\F37E"; }
  
  .fad.fa-pen-paintbrush::after, .fa-duotone.fa-pen-paintbrush::after {
	content: "\F618\F618"; }
  
  .fad.fa-pencil-paintbrush::after, .fa-duotone.fa-pencil-paintbrush::after {
	content: "\F618\F618"; }
  
  .fad.fa-fish-cooked::after, .fa-duotone.fa-fish-cooked::after {
	content: "\F7FE\F7FE"; }
  
  .fad.fa-chair-office::after, .fa-duotone.fa-chair-office::after {
	content: "\F6C1\F6C1"; }
  
  .fad.fa-nesting-dolls::after, .fa-duotone.fa-nesting-dolls::after {
	content: "\E3BA\E3BA"; }
  
  .fad.fa-clock-rotate-left::after, .fa-duotone.fa-clock-rotate-left::after {
	content: "\F1DA\F1DA"; }
  
  .fad.fa-history::after, .fa-duotone.fa-history::after {
	content: "\F1DA\F1DA"; }
  
  .fad.fa-trumpet::after, .fa-duotone.fa-trumpet::after {
	content: "\F8E3\F8E3"; }
  
  .fad.fa-face-grin-beam-sweat::after, .fa-duotone.fa-face-grin-beam-sweat::after {
	content: "\F583\F583"; }
  
  .fad.fa-grin-beam-sweat::after, .fa-duotone.fa-grin-beam-sweat::after {
	content: "\F583\F583"; }
  
  .fad.fa-fire-smoke::after, .fa-duotone.fa-fire-smoke::after {
	content: "\F74B\F74B"; }
  
  .fad.fa-phone-missed::after, .fa-duotone.fa-phone-missed::after {
	content: "\E226\E226"; }
  
  .fad.fa-file-export::after, .fa-duotone.fa-file-export::after {
	content: "\F56E\F56E"; }
  
  .fad.fa-arrow-right-from-file::after, .fa-duotone.fa-arrow-right-from-file::after {
	content: "\F56E\F56E"; }
  
  .fad.fa-shield::after, .fa-duotone.fa-shield::after {
	content: "\F132\F132"; }
  
  .fad.fa-shield-blank::after, .fa-duotone.fa-shield-blank::after {
	content: "\F132\F132"; }
  
  .fad.fa-arrow-up-short-wide::after, .fa-duotone.fa-arrow-up-short-wide::after {
	content: "\F885\F885"; }
  
  .fad.fa-sort-amount-up-alt::after, .fa-duotone.fa-sort-amount-up-alt::after {
	content: "\F885\F885"; }
  
  .fad.fa-arrows-repeat-1::after, .fa-duotone.fa-arrows-repeat-1::after {
	content: "\F366\F366"; }
  
  .fad.fa-repeat-1-alt::after, .fa-duotone.fa-repeat-1-alt::after {
	content: "\F366\F366"; }
  
  .fad.fa-gun-slash::after, .fa-duotone.fa-gun-slash::after {
	content: "\E19C\E19C"; }
  
  .fad.fa-avocado::after, .fa-duotone.fa-avocado::after {
	content: "\E0AA\E0AA"; }
  
  .fad.fa-binary::after, .fa-duotone.fa-binary::after {
	content: "\E33B\E33B"; }
  
  .fad.fa-glasses-round::after, .fa-duotone.fa-glasses-round::after {
	content: "\F5F5\F5F5"; }
  
  .fad.fa-glasses-alt::after, .fa-duotone.fa-glasses-alt::after {
	content: "\F5F5\F5F5"; }
  
  .fad.fa-phone-plus::after, .fa-duotone.fa-phone-plus::after {
	content: "\F4D2\F4D2"; }
  
  .fad.fa-ditto::after, .fa-duotone.fa-ditto::after {
	content: "\"\""; }
  
  .fad.fa-person-seat::after, .fa-duotone.fa-person-seat::after {
	content: "\E21E\E21E"; }
  
  .fad.fa-house-medical::after, .fa-duotone.fa-house-medical::after {
	content: "\E3B2\E3B2"; }
  
  .fad.fa-golf-ball-tee::after, .fa-duotone.fa-golf-ball-tee::after {
	content: "\F450\F450"; }
  
  .fad.fa-golf-ball::after, .fa-duotone.fa-golf-ball::after {
	content: "\F450\F450"; }
  
  .fad.fa-circle-chevron-left::after, .fa-duotone.fa-circle-chevron-left::after {
	content: "\F137\F137"; }
  
  .fad.fa-chevron-circle-left::after, .fa-duotone.fa-chevron-circle-left::after {
	content: "\F137\F137"; }
  
  .fad.fa-house-chimney-window::after, .fa-duotone.fa-house-chimney-window::after {
	content: "\E00D\E00D"; }
  
  .fad.fa-scythe::after, .fa-duotone.fa-scythe::after {
	content: "\F710\F710"; }
  
  .fad.fa-pen-nib::after, .fa-duotone.fa-pen-nib::after {
	content: "\F5AD\F5AD"; }
  
  .fad.fa-ban-parking::after, .fa-duotone.fa-ban-parking::after {
	content: "\F616\F616"; }
  
  .fad.fa-parking-circle-slash::after, .fa-duotone.fa-parking-circle-slash::after {
	content: "\F616\F616"; }
  
  .fad.fa-tent-arrow-turn-left::after, .fa-duotone.fa-tent-arrow-turn-left::after {
	content: "\E580\E580"; }
  
  .fad.fa-face-diagonal-mouth::after, .fa-duotone.fa-face-diagonal-mouth::after {
	content: "\E47E\E47E"; }
  
  .fad.fa-diagram-cells::after, .fa-duotone.fa-diagram-cells::after {
	content: "\E475\E475"; }
  
  .fad.fa-cricket-bat-ball::after, .fa-duotone.fa-cricket-bat-ball::after {
	content: "\F449\F449"; }
  
  .fad.fa-cricket::after, .fa-duotone.fa-cricket::after {
	content: "\F449\F449"; }
  
  .fad.fa-tents::after, .fa-duotone.fa-tents::after {
	content: "\E582\E582"; }
  
  .fad.fa-wand-magic::after, .fa-duotone.fa-wand-magic::after {
	content: "\F0D0\F0D0"; }
  
  .fad.fa-magic::after, .fa-duotone.fa-magic::after {
	content: "\F0D0\F0D0"; }
  
  .fad.fa-dog::after, .fa-duotone.fa-dog::after {
	content: "\F6D3\F6D3"; }
  
  .fad.fa-pen-line::after, .fa-duotone.fa-pen-line::after {
	content: "\E212\E212"; }
  
  .fad.fa-atom-simple::after, .fa-duotone.fa-atom-simple::after {
	content: "\F5D3\F5D3"; }
  
  .fad.fa-atom-alt::after, .fa-duotone.fa-atom-alt::after {
	content: "\F5D3\F5D3"; }
  
  .fad.fa-ampersand::after, .fa-duotone.fa-ampersand::after {
	content: "&&"; }
  
  .fad.fa-carrot::after, .fa-duotone.fa-carrot::after {
	content: "\F787\F787"; }
  
  .fad.fa-arrow-up-from-line::after, .fa-duotone.fa-arrow-up-from-line::after {
	content: "\F342\F342"; }
  
  .fad.fa-arrow-from-bottom::after, .fa-duotone.fa-arrow-from-bottom::after {
	content: "\F342\F342"; }
  
  .fad.fa-moon::after, .fa-duotone.fa-moon::after {
	content: "\F186\F186"; }
  
  .fad.fa-pen-slash::after, .fa-duotone.fa-pen-slash::after {
	content: "\E213\E213"; }
  
  .fad.fa-wine-glass-empty::after, .fa-duotone.fa-wine-glass-empty::after {
	content: "\F5CE\F5CE"; }
  
  .fad.fa-wine-glass-alt::after, .fa-duotone.fa-wine-glass-alt::after {
	content: "\F5CE\F5CE"; }
  
  .fad.fa-square-star::after, .fa-duotone.fa-square-star::after {
	content: "\E27F\E27F"; }
  
  .fad.fa-cheese::after, .fa-duotone.fa-cheese::after {
	content: "\F7EF\F7EF"; }
  
  .fad.fa-send-backward::after, .fa-duotone.fa-send-backward::after {
	content: "\F87F\F87F"; }
  
  .fad.fa-yin-yang::after, .fa-duotone.fa-yin-yang::after {
	content: "\F6AD\F6AD"; }
  
  .fad.fa-music::after, .fa-duotone.fa-music::after {
	content: "\F001\F001"; }
  
  .fad.fa-compass-slash::after, .fa-duotone.fa-compass-slash::after {
	content: "\F5E9\F5E9"; }
  
  .fad.fa-clock-one::after, .fa-duotone.fa-clock-one::after {
	content: "\E34E\E34E"; }
  
  .fad.fa-file-music::after, .fa-duotone.fa-file-music::after {
	content: "\F8B6\F8B6"; }
  
  .fad.fa-code-commit::after, .fa-duotone.fa-code-commit::after {
	content: "\F386\F386"; }
  
  .fad.fa-temperature-low::after, .fa-duotone.fa-temperature-low::after {
	content: "\F76B\F76B"; }
  
  .fad.fa-person-biking::after, .fa-duotone.fa-person-biking::after {
	content: "\F84A\F84A"; }
  
  .fad.fa-biking::after, .fa-duotone.fa-biking::after {
	content: "\F84A\F84A"; }
  
  .fad.fa-skeleton::after, .fa-duotone.fa-skeleton::after {
	content: "\F620\F620"; }
  
  .fad.fa-circle-g::after, .fa-duotone.fa-circle-g::after {
	content: "\E10F\E10F"; }
  
  .fad.fa-circle-arrow-up-left::after, .fa-duotone.fa-circle-arrow-up-left::after {
	content: "\E0FB\E0FB"; }
  
  .fad.fa-coin-blank::after, .fa-duotone.fa-coin-blank::after {
	content: "\E3FB\E3FB"; }
  
  .fad.fa-broom::after, .fa-duotone.fa-broom::after {
	content: "\F51A\F51A"; }
  
  .fad.fa-vacuum::after, .fa-duotone.fa-vacuum::after {
	content: "\E04D\E04D"; }
  
  .fad.fa-shield-heart::after, .fa-duotone.fa-shield-heart::after {
	content: "\E574\E574"; }
  
  .fad.fa-card-heart::after, .fa-duotone.fa-card-heart::after {
	content: "\E3EB\E3EB"; }
  
  .fad.fa-lightbulb-cfl-on::after, .fa-duotone.fa-lightbulb-cfl-on::after {
	content: "\E5A7\E5A7"; }
  
  .fad.fa-melon::after, .fa-duotone.fa-melon::after {
	content: "\E310\E310"; }
  
  .fad.fa-gopuram::after, .fa-duotone.fa-gopuram::after {
	content: "\F664\F664"; }
  
  .fad.fa-earth-oceania::after, .fa-duotone.fa-earth-oceania::after {
	content: "\E47B\E47B"; }
  
  .fad.fa-globe-oceania::after, .fa-duotone.fa-globe-oceania::after {
	content: "\E47B\E47B"; }
  
  .fad.fa-container-storage::after, .fa-duotone.fa-container-storage::after {
	content: "\F4B7\F4B7"; }
  
  .fad.fa-face-pouting::after, .fa-duotone.fa-face-pouting::after {
	content: "\E387\E387"; }
  
  .fad.fa-square-xmark::after, .fa-duotone.fa-square-xmark::after {
	content: "\F2D3\F2D3"; }
  
  .fad.fa-times-square::after, .fa-duotone.fa-times-square::after {
	content: "\F2D3\F2D3"; }
  
  .fad.fa-xmark-square::after, .fa-duotone.fa-xmark-square::after {
	content: "\F2D3\F2D3"; }
  
  .fad.fa-face-explode::after, .fa-duotone.fa-face-explode::after {
	content: "\E2FE\E2FE"; }
  
  .fad.fa-exploding-head::after, .fa-duotone.fa-exploding-head::after {
	content: "\E2FE\E2FE"; }
  
  .fad.fa-hashtag::after, .fa-duotone.fa-hashtag::after {
	content: "##"; }
  
  .fad.fa-up-right-and-down-left-from-center::after, .fa-duotone.fa-up-right-and-down-left-from-center::after {
	content: "\F424\F424"; }
  
  .fad.fa-expand-alt::after, .fa-duotone.fa-expand-alt::after {
	content: "\F424\F424"; }
  
  .fad.fa-oil-can::after, .fa-duotone.fa-oil-can::after {
	content: "\F613\F613"; }
  
  .fad.fa-t::after, .fa-duotone.fa-t::after {
	content: "TT"; }
  
  .fad.fa-transformer-bolt::after, .fa-duotone.fa-transformer-bolt::after {
	content: "\E2A4\E2A4"; }
  
  .fad.fa-hippo::after, .fa-duotone.fa-hippo::after {
	content: "\F6ED\F6ED"; }
  
  .fad.fa-chart-column::after, .fa-duotone.fa-chart-column::after {
	content: "\E0E3\E0E3"; }
  
  .fad.fa-cassette-vhs::after, .fa-duotone.fa-cassette-vhs::after {
	content: "\F8EC\F8EC"; }
  
  .fad.fa-vhs::after, .fa-duotone.fa-vhs::after {
	content: "\F8EC\F8EC"; }
  
  .fad.fa-infinity::after, .fa-duotone.fa-infinity::after {
	content: "\F534\F534"; }
  
  .fad.fa-vial-circle-check::after, .fa-duotone.fa-vial-circle-check::after {
	content: "\E596\E596"; }
  
  .fad.fa-chimney::after, .fa-duotone.fa-chimney::after {
	content: "\F78B\F78B"; }
  
  .fad.fa-object-intersect::after, .fa-duotone.fa-object-intersect::after {
	content: "\E49D\E49D"; }
  
  .fad.fa-person-arrow-down-to-line::after, .fa-duotone.fa-person-arrow-down-to-line::after {
	content: "\E538\E538"; }
  
  .fad.fa-voicemail::after, .fa-duotone.fa-voicemail::after {
	content: "\F897\F897"; }
  
  .fad.fa-block-brick::after, .fa-duotone.fa-block-brick::after {
	content: "\E3DB\E3DB"; }
  
  .fad.fa-wall-brick::after, .fa-duotone.fa-wall-brick::after {
	content: "\E3DB\E3DB"; }
  
  .fad.fa-fan::after, .fa-duotone.fa-fan::after {
	content: "\F863\F863"; }
  
  .fad.fa-bags-shopping::after, .fa-duotone.fa-bags-shopping::after {
	content: "\F847\F847"; }
  
  .fad.fa-paragraph-left::after, .fa-duotone.fa-paragraph-left::after {
	content: "\F878\F878"; }
  
  .fad.fa-paragraph-rtl::after, .fa-duotone.fa-paragraph-rtl::after {
	content: "\F878\F878"; }
  
  .fad.fa-person-walking-luggage::after, .fa-duotone.fa-person-walking-luggage::after {
	content: "\E554\E554"; }
  
  .fad.fa-caravan-simple::after, .fa-duotone.fa-caravan-simple::after {
	content: "\E000\E000"; }
  
  .fad.fa-caravan-alt::after, .fa-duotone.fa-caravan-alt::after {
	content: "\E000\E000"; }
  
  .fad.fa-turtle::after, .fa-duotone.fa-turtle::after {
	content: "\F726\F726"; }
  
  .fad.fa-up-down::after, .fa-duotone.fa-up-down::after {
	content: "\F338\F338"; }
  
  .fad.fa-arrows-alt-v::after, .fa-duotone.fa-arrows-alt-v::after {
	content: "\F338\F338"; }
  
  .fad.fa-cloud-moon-rain::after, .fa-duotone.fa-cloud-moon-rain::after {
	content: "\F73C\F73C"; }
  
  .fad.fa-booth-curtain::after, .fa-duotone.fa-booth-curtain::after {
	content: "\F734\F734"; }
  
  .fad.fa-calendar::after, .fa-duotone.fa-calendar::after {
	content: "\F133\F133"; }
  
  .fad.fa-box-heart::after, .fa-duotone.fa-box-heart::after {
	content: "\F49D\F49D"; }
  
  .fad.fa-trailer::after, .fa-duotone.fa-trailer::after {
	content: "\E041\E041"; }
  
  .fad.fa-user-doctor-message::after, .fa-duotone.fa-user-doctor-message::after {
	content: "\F82E\F82E"; }
  
  .fad.fa-user-md-chat::after, .fa-duotone.fa-user-md-chat::after {
	content: "\F82E\F82E"; }
  
  .fad.fa-bahai::after, .fa-duotone.fa-bahai::after {
	content: "\F666\F666"; }
  
  .fad.fa-haykal::after, .fa-duotone.fa-haykal::after {
	content: "\F666\F666"; }
  
  .fad.fa-amp-guitar::after, .fa-duotone.fa-amp-guitar::after {
	content: "\F8A1\F8A1"; }
  
  .fad.fa-sd-card::after, .fa-duotone.fa-sd-card::after {
	content: "\F7C2\F7C2"; }
  
  .fad.fa-volume-slash::after, .fa-duotone.fa-volume-slash::after {
	content: "\F2E2\F2E2"; }
  
  .fad.fa-border-bottom::after, .fa-duotone.fa-border-bottom::after {
	content: "\F84D\F84D"; }
  
  .fad.fa-wifi-weak::after, .fa-duotone.fa-wifi-weak::after {
	content: "\F6AA\F6AA"; }
  
  .fad.fa-wifi-1::after, .fa-duotone.fa-wifi-1::after {
	content: "\F6AA\F6AA"; }
  
  .fad.fa-dragon::after, .fa-duotone.fa-dragon::after {
	content: "\F6D5\F6D5"; }
  
  .fad.fa-shoe-prints::after, .fa-duotone.fa-shoe-prints::after {
	content: "\F54B\F54B"; }
  
  .fad.fa-circle-plus::after, .fa-duotone.fa-circle-plus::after {
	content: "\F055\F055"; }
  
  .fad.fa-plus-circle::after, .fa-duotone.fa-plus-circle::after {
	content: "\F055\F055"; }
  
  .fad.fa-face-grin-tongue-wink::after, .fa-duotone.fa-face-grin-tongue-wink::after {
	content: "\F58B\F58B"; }
  
  .fad.fa-grin-tongue-wink::after, .fa-duotone.fa-grin-tongue-wink::after {
	content: "\F58B\F58B"; }
  
  .fad.fa-hand-holding::after, .fa-duotone.fa-hand-holding::after {
	content: "\F4BD\F4BD"; }
  
  .fad.fa-plug-circle-exclamation::after, .fa-duotone.fa-plug-circle-exclamation::after {
	content: "\E55D\E55D"; }
  
  .fad.fa-link-slash::after, .fa-duotone.fa-link-slash::after {
	content: "\F127\F127"; }
  
  .fad.fa-chain-broken::after, .fa-duotone.fa-chain-broken::after {
	content: "\F127\F127"; }
  
  .fad.fa-chain-slash::after, .fa-duotone.fa-chain-slash::after {
	content: "\F127\F127"; }
  
  .fad.fa-unlink::after, .fa-duotone.fa-unlink::after {
	content: "\F127\F127"; }
  
  .fad.fa-clone::after, .fa-duotone.fa-clone::after {
	content: "\F24D\F24D"; }
  
  .fad.fa-person-walking-arrow-loop-left::after, .fa-duotone.fa-person-walking-arrow-loop-left::after {
	content: "\E551\E551"; }
  
  .fad.fa-arrow-up-z-a::after, .fa-duotone.fa-arrow-up-z-a::after {
	content: "\F882\F882"; }
  
  .fad.fa-sort-alpha-up-alt::after, .fa-duotone.fa-sort-alpha-up-alt::after {
	content: "\F882\F882"; }
  
  .fad.fa-fire-flame-curved::after, .fa-duotone.fa-fire-flame-curved::after {
	content: "\F7E4\F7E4"; }
  
  .fad.fa-fire-alt::after, .fa-duotone.fa-fire-alt::after {
	content: "\F7E4\F7E4"; }
  
  .fad.fa-tornado::after, .fa-duotone.fa-tornado::after {
	content: "\F76F\F76F"; }
  
  .fad.fa-file-circle-plus::after, .fa-duotone.fa-file-circle-plus::after {
	content: "\E494\E494"; }
  
  .fad.fa-delete-right::after, .fa-duotone.fa-delete-right::after {
	content: "\E154\E154"; }
  
  .fad.fa-book-quran::after, .fa-duotone.fa-book-quran::after {
	content: "\F687\F687"; }
  
  .fad.fa-quran::after, .fa-duotone.fa-quran::after {
	content: "\F687\F687"; }
  
  .fad.fa-circle-quarter::after, .fa-duotone.fa-circle-quarter::after {
	content: "\E11F\E11F"; }
  
  .fad.fa-anchor::after, .fa-duotone.fa-anchor::after {
	content: "\F13D\F13D"; }
  
  .fad.fa-border-all::after, .fa-duotone.fa-border-all::after {
	content: "\F84C\F84C"; }
  
  .fad.fa-function::after, .fa-duotone.fa-function::after {
	content: "\F661\F661"; }
  
  .fad.fa-face-angry::after, .fa-duotone.fa-face-angry::after {
	content: "\F556\F556"; }
  
  .fad.fa-angry::after, .fa-duotone.fa-angry::after {
	content: "\F556\F556"; }
  
  .fad.fa-people-simple::after, .fa-duotone.fa-people-simple::after {
	content: "\E21B\E21B"; }
  
  .fad.fa-cookie-bite::after, .fa-duotone.fa-cookie-bite::after {
	content: "\F564\F564"; }
  
  .fad.fa-arrow-trend-down::after, .fa-duotone.fa-arrow-trend-down::after {
	content: "\E097\E097"; }
  
  .fad.fa-rss::after, .fa-duotone.fa-rss::after {
	content: "\F09E\F09E"; }
  
  .fad.fa-feed::after, .fa-duotone.fa-feed::after {
	content: "\F09E\F09E"; }
  
  .fad.fa-face-monocle::after, .fa-duotone.fa-face-monocle::after {
	content: "\E380\E380"; }
  
  .fad.fa-draw-polygon::after, .fa-duotone.fa-draw-polygon::after {
	content: "\F5EE\F5EE"; }
  
  .fad.fa-scale-balanced::after, .fa-duotone.fa-scale-balanced::after {
	content: "\F24E\F24E"; }
  
  .fad.fa-balance-scale::after, .fa-duotone.fa-balance-scale::after {
	content: "\F24E\F24E"; }
  
  .fad.fa-calendar-lines::after, .fa-duotone.fa-calendar-lines::after {
	content: "\E0D5\E0D5"; }
  
  .fad.fa-calendar-note::after, .fa-duotone.fa-calendar-note::after {
	content: "\E0D5\E0D5"; }
  
  .fad.fa-arrow-down-big-small::after, .fa-duotone.fa-arrow-down-big-small::after {
	content: "\F88C\F88C"; }
  
  .fad.fa-sort-size-down::after, .fa-duotone.fa-sort-size-down::after {
	content: "\F88C\F88C"; }
  
  .fad.fa-gauge-simple-high::after, .fa-duotone.fa-gauge-simple-high::after {
	content: "\F62A\F62A"; }
  
  .fad.fa-tachometer::after, .fa-duotone.fa-tachometer::after {
	content: "\F62A\F62A"; }
  
  .fad.fa-tachometer-fast::after, .fa-duotone.fa-tachometer-fast::after {
	content: "\F62A\F62A"; }
  
  .fad.fa-do-not-enter::after, .fa-duotone.fa-do-not-enter::after {
	content: "\F5EC\F5EC"; }
  
  .fad.fa-shower::after, .fa-duotone.fa-shower::after {
	content: "\F2CC\F2CC"; }
  
  .fad.fa-dice-d8::after, .fa-duotone.fa-dice-d8::after {
	content: "\F6D2\F6D2"; }
  
  .fad.fa-desktop::after, .fa-duotone.fa-desktop::after {
	content: "\F390\F390"; }
  
  .fad.fa-desktop-alt::after, .fa-duotone.fa-desktop-alt::after {
	content: "\F390\F390"; }
  
  .fad.fa-m::after, .fa-duotone.fa-m::after {
	content: "MM"; }
  
  .fad.fa-grip-dots-vertical::after, .fa-duotone.fa-grip-dots-vertical::after {
	content: "\E411\E411"; }
  
  .fad.fa-face-viewfinder::after, .fa-duotone.fa-face-viewfinder::after {
	content: "\E2FF\E2FF"; }
  
  .fad.fa-soft-serve::after, .fa-duotone.fa-soft-serve::after {
	content: "\E400\E400"; }
  
  .fad.fa-creemee::after, .fa-duotone.fa-creemee::after {
	content: "\E400\E400"; }
  
  .fad.fa-h5::after, .fa-duotone.fa-h5::after {
	content: "\E412\E412"; }
  
  .fad.fa-hand-back-point-down::after, .fa-duotone.fa-hand-back-point-down::after {
	content: "\E19E\E19E"; }
  
  .fad.fa-table-list::after, .fa-duotone.fa-table-list::after {
	content: "\F00B\F00B"; }
  
  .fad.fa-th-list::after, .fa-duotone.fa-th-list::after {
	content: "\F00B\F00B"; }
  
  .fad.fa-comment-sms::after, .fa-duotone.fa-comment-sms::after {
	content: "\F7CD\F7CD"; }
  
  .fad.fa-sms::after, .fa-duotone.fa-sms::after {
	content: "\F7CD\F7CD"; }
  
  .fad.fa-rectangle::after, .fa-duotone.fa-rectangle::after {
	content: "\F2FA\F2FA"; }
  
  .fad.fa-rectangle-landscape::after, .fa-duotone.fa-rectangle-landscape::after {
	content: "\F2FA\F2FA"; }
  
  .fad.fa-clipboard-list-check::after, .fa-duotone.fa-clipboard-list-check::after {
	content: "\F737\F737"; }
  
  .fad.fa-turkey::after, .fa-duotone.fa-turkey::after {
	content: "\F725\F725"; }
  
  .fad.fa-book::after, .fa-duotone.fa-book::after {
	content: "\F02D\F02D"; }
  
  .fad.fa-user-plus::after, .fa-duotone.fa-user-plus::after {
	content: "\F234\F234"; }
  
  .fad.fa-ice-skate::after, .fa-duotone.fa-ice-skate::after {
	content: "\F7AC\F7AC"; }
  
  .fad.fa-check::after, .fa-duotone.fa-check::after {
	content: "\F00C\F00C"; }
  
  .fad.fa-battery-three-quarters::after, .fa-duotone.fa-battery-three-quarters::after {
	content: "\F241\F241"; }
  
  .fad.fa-battery-4::after, .fa-duotone.fa-battery-4::after {
	content: "\F241\F241"; }
  
  .fad.fa-tomato::after, .fa-duotone.fa-tomato::after {
	content: "\E330\E330"; }
  
  .fad.fa-sword-laser::after, .fa-duotone.fa-sword-laser::after {
	content: "\E03B\E03B"; }
  
  .fad.fa-house-circle-check::after, .fa-duotone.fa-house-circle-check::after {
	content: "\E509\E509"; }
  
  .fad.fa-buildings::after, .fa-duotone.fa-buildings::after {
	content: "\E0CC\E0CC"; }
  
  .fad.fa-angle-left::after, .fa-duotone.fa-angle-left::after {
	content: "\F104\F104"; }
  
  .fad.fa-cart-flatbed-boxes::after, .fa-duotone.fa-cart-flatbed-boxes::after {
	content: "\F475\F475"; }
  
  .fad.fa-dolly-flatbed-alt::after, .fa-duotone.fa-dolly-flatbed-alt::after {
	content: "\F475\F475"; }
  
  .fad.fa-diagram-successor::after, .fa-duotone.fa-diagram-successor::after {
	content: "\E47A\E47A"; }
  
  .fad.fa-truck-arrow-right::after, .fa-duotone.fa-truck-arrow-right::after {
	content: "\E58B\E58B"; }
  
  .fad.fa-square-w::after, .fa-duotone.fa-square-w::after {
	content: "\E285\E285"; }
  
  .fad.fa-arrows-split-up-and-left::after, .fa-duotone.fa-arrows-split-up-and-left::after {
	content: "\E4BC\E4BC"; }
  
  .fad.fa-lamp::after, .fa-duotone.fa-lamp::after {
	content: "\F4CA\F4CA"; }
  
  .fad.fa-airplay::after, .fa-duotone.fa-airplay::after {
	content: "\E089\E089"; }
  
  .fad.fa-hand-fist::after, .fa-duotone.fa-hand-fist::after {
	content: "\F6DE\F6DE"; }
  
  .fad.fa-fist-raised::after, .fa-duotone.fa-fist-raised::after {
	content: "\F6DE\F6DE"; }
  
  .fad.fa-shield-quartered::after, .fa-duotone.fa-shield-quartered::after {
	content: "\E575\E575"; }
  
  .fad.fa-slash-forward::after, .fa-duotone.fa-slash-forward::after {
	content: "//"; }
  
  .fad.fa-location-pen::after, .fa-duotone.fa-location-pen::after {
	content: "\F607\F607"; }
  
  .fad.fa-map-marker-edit::after, .fa-duotone.fa-map-marker-edit::after {
	content: "\F607\F607"; }
  
  .fad.fa-cloud-moon::after, .fa-duotone.fa-cloud-moon::after {
	content: "\F6C3\F6C3"; }
  
  .fad.fa-pot-food::after, .fa-duotone.fa-pot-food::after {
	content: "\E43F\E43F"; }
  
  .fad.fa-briefcase::after, .fa-duotone.fa-briefcase::after {
	content: "\F0B1\F0B1"; }
  
  .fad.fa-person-falling::after, .fa-duotone.fa-person-falling::after {
	content: "\E546\E546"; }
  
  .fad.fa-image-portrait::after, .fa-duotone.fa-image-portrait::after {
	content: "\F3E0\F3E0"; }
  
  .fad.fa-portrait::after, .fa-duotone.fa-portrait::after {
	content: "\F3E0\F3E0"; }
  
  .fad.fa-user-tag::after, .fa-duotone.fa-user-tag::after {
	content: "\F507\F507"; }
  
  .fad.fa-rug::after, .fa-duotone.fa-rug::after {
	content: "\E569\E569"; }
  
  .fad.fa-print-slash::after, .fa-duotone.fa-print-slash::after {
	content: "\F686\F686"; }
  
  .fad.fa-earth-europe::after, .fa-duotone.fa-earth-europe::after {
	content: "\F7A2\F7A2"; }
  
  .fad.fa-globe-europe::after, .fa-duotone.fa-globe-europe::after {
	content: "\F7A2\F7A2"; }
  
  .fad.fa-cart-flatbed-suitcase::after, .fa-duotone.fa-cart-flatbed-suitcase::after {
	content: "\F59D\F59D"; }
  
  .fad.fa-luggage-cart::after, .fa-duotone.fa-luggage-cart::after {
	content: "\F59D\F59D"; }
  
  .fad.fa-hand-back-point-ribbon::after, .fa-duotone.fa-hand-back-point-ribbon::after {
	content: "\E1A0\E1A0"; }
  
  .fad.fa-rectangle-xmark::after, .fa-duotone.fa-rectangle-xmark::after {
	content: "\F410\F410"; }
  
  .fad.fa-rectangle-times::after, .fa-duotone.fa-rectangle-times::after {
	content: "\F410\F410"; }
  
  .fad.fa-times-rectangle::after, .fa-duotone.fa-times-rectangle::after {
	content: "\F410\F410"; }
  
  .fad.fa-window-close::after, .fa-duotone.fa-window-close::after {
	content: "\F410\F410"; }
  
  .fad.fa-tire-rugged::after, .fa-duotone.fa-tire-rugged::after {
	content: "\F634\F634"; }
  
  .fad.fa-lightbulb-dollar::after, .fa-duotone.fa-lightbulb-dollar::after {
	content: "\F670\F670"; }
  
  .fad.fa-cowbell::after, .fa-duotone.fa-cowbell::after {
	content: "\F8B3\F8B3"; }
  
  .fad.fa-baht-sign::after, .fa-duotone.fa-baht-sign::after {
	content: "\E0AC\E0AC"; }
  
  .fad.fa-corner::after, .fa-duotone.fa-corner::after {
	content: "\E3FE\E3FE"; }
  
  .fad.fa-chevrons-right::after, .fa-duotone.fa-chevrons-right::after {
	content: "\F324\F324"; }
  
  .fad.fa-chevron-double-right::after, .fa-duotone.fa-chevron-double-right::after {
	content: "\F324\F324"; }
  
  .fad.fa-book-open::after, .fa-duotone.fa-book-open::after {
	content: "\F518\F518"; }
  
  .fad.fa-book-journal-whills::after, .fa-duotone.fa-book-journal-whills::after {
	content: "\F66A\F66A"; }
  
  .fad.fa-journal-whills::after, .fa-duotone.fa-journal-whills::after {
	content: "\F66A\F66A"; }
  
  .fad.fa-inhaler::after, .fa-duotone.fa-inhaler::after {
	content: "\F5F9\F5F9"; }
  
  .fad.fa-handcuffs::after, .fa-duotone.fa-handcuffs::after {
	content: "\E4F8\E4F8"; }
  
  .fad.fa-snake::after, .fa-duotone.fa-snake::after {
	content: "\F716\F716"; }
  
  .fad.fa-triangle-exclamation::after, .fa-duotone.fa-triangle-exclamation::after {
	content: "\F071\F071"; }
  
  .fad.fa-exclamation-triangle::after, .fa-duotone.fa-exclamation-triangle::after {
	content: "\F071\F071"; }
  
  .fad.fa-warning::after, .fa-duotone.fa-warning::after {
	content: "\F071\F071"; }
  
  .fad.fa-note-medical::after, .fa-duotone.fa-note-medical::after {
	content: "\E200\E200"; }
  
  .fad.fa-database::after, .fa-duotone.fa-database::after {
	content: "\F1C0\F1C0"; }
  
  .fad.fa-down-left::after, .fa-duotone.fa-down-left::after {
	content: "\E16A\E16A"; }
  
  .fad.fa-share::after, .fa-duotone.fa-share::after {
	content: "\F064\F064"; }
  
  .fad.fa-arrow-turn-right::after, .fa-duotone.fa-arrow-turn-right::after {
	content: "\F064\F064"; }
  
  .fad.fa-mail-forward::after, .fa-duotone.fa-mail-forward::after {
	content: "\F064\F064"; }
  
  .fad.fa-face-thinking::after, .fa-duotone.fa-face-thinking::after {
	content: "\E39B\E39B"; }
  
  .fad.fa-turn-down-right::after, .fa-duotone.fa-turn-down-right::after {
	content: "\E455\E455"; }
  
  .fad.fa-bottle-droplet::after, .fa-duotone.fa-bottle-droplet::after {
	content: "\E4C4\E4C4"; }
  
  .fad.fa-mask-face::after, .fa-duotone.fa-mask-face::after {
	content: "\E1D7\E1D7"; }
  
  .fad.fa-hill-rockslide::after, .fa-duotone.fa-hill-rockslide::after {
	content: "\E508\E508"; }
  
  .fad.fa-scanner-keyboard::after, .fa-duotone.fa-scanner-keyboard::after {
	content: "\F489\F489"; }
  
  .fad.fa-circle-o::after, .fa-duotone.fa-circle-o::after {
	content: "\E119\E119"; }
  
  .fad.fa-grid-horizontal::after, .fa-duotone.fa-grid-horizontal::after {
	content: "\E307\E307"; }
  
  .fad.fa-message-dollar::after, .fa-duotone.fa-message-dollar::after {
	content: "\F650\F650"; }
  
  .fad.fa-comment-alt-dollar::after, .fa-duotone.fa-comment-alt-dollar::after {
	content: "\F650\F650"; }
  
  .fad.fa-right-left::after, .fa-duotone.fa-right-left::after {
	content: "\F362\F362"; }
  
  .fad.fa-exchange-alt::after, .fa-duotone.fa-exchange-alt::after {
	content: "\F362\F362"; }
  
  .fad.fa-columns-3::after, .fa-duotone.fa-columns-3::after {
	content: "\E361\E361"; }
  
  .fad.fa-paper-plane::after, .fa-duotone.fa-paper-plane::after {
	content: "\F1D8\F1D8"; }
  
  .fad.fa-road-circle-exclamation::after, .fa-duotone.fa-road-circle-exclamation::after {
	content: "\E565\E565"; }
  
  .fad.fa-dungeon::after, .fa-duotone.fa-dungeon::after {
	content: "\F6D9\F6D9"; }
  
  .fad.fa-hand-holding-box::after, .fa-duotone.fa-hand-holding-box::after {
	content: "\F47B\F47B"; }
  
  .fad.fa-input-text::after, .fa-duotone.fa-input-text::after {
	content: "\E1BF\E1BF"; }
  
  .fad.fa-window-flip::after, .fa-duotone.fa-window-flip::after {
	content: "\F40F\F40F"; }
  
  .fad.fa-window-alt::after, .fa-duotone.fa-window-alt::after {
	content: "\F40F\F40F"; }
  
  .fad.fa-align-right::after, .fa-duotone.fa-align-right::after {
	content: "\F038\F038"; }
  
  .fad.fa-scanner-gun::after, .fa-duotone.fa-scanner-gun::after {
	content: "\F488\F488"; }
  
  .fad.fa-scanner::after, .fa-duotone.fa-scanner::after {
	content: "\F488\F488"; }
  
  .fad.fa-tire::after, .fa-duotone.fa-tire::after {
	content: "\F631\F631"; }
  
  .fad.fa-engine::after, .fa-duotone.fa-engine::after {
	content: "\E16E\E16E"; }
  
  .fad.fa-money-bill-1-wave::after, .fa-duotone.fa-money-bill-1-wave::after {
	content: "\F53B\F53B"; }
  
  .fad.fa-money-bill-wave-alt::after, .fa-duotone.fa-money-bill-wave-alt::after {
	content: "\F53B\F53B"; }
  
  .fad.fa-life-ring::after, .fa-duotone.fa-life-ring::after {
	content: "\F1CD\F1CD"; }
  
  .fad.fa-hands::after, .fa-duotone.fa-hands::after {
	content: "\F2A7\F2A7"; }
  
  .fad.fa-sign-language::after, .fa-duotone.fa-sign-language::after {
	content: "\F2A7\F2A7"; }
  
  .fad.fa-signing::after, .fa-duotone.fa-signing::after {
	content: "\F2A7\F2A7"; }
  
  .fad.fa-circle-caret-right::after, .fa-duotone.fa-circle-caret-right::after {
	content: "\F330\F330"; }
  
  .fad.fa-caret-circle-right::after, .fa-duotone.fa-caret-circle-right::after {
	content: "\F330\F330"; }
  
  .fad.fa-wheat::after, .fa-duotone.fa-wheat::after {
	content: "\F72D\F72D"; }
  
  .fad.fa-file-spreadsheet::after, .fa-duotone.fa-file-spreadsheet::after {
	content: "\F65B\F65B"; }
  
  .fad.fa-audio-description-slash::after, .fa-duotone.fa-audio-description-slash::after {
	content: "\E0A8\E0A8"; }
  
  .fad.fa-calendar-day::after, .fa-duotone.fa-calendar-day::after {
	content: "\F783\F783"; }
  
  .fad.fa-water-ladder::after, .fa-duotone.fa-water-ladder::after {
	content: "\F5C5\F5C5"; }
  
  .fad.fa-ladder-water::after, .fa-duotone.fa-ladder-water::after {
	content: "\F5C5\F5C5"; }
  
  .fad.fa-swimming-pool::after, .fa-duotone.fa-swimming-pool::after {
	content: "\F5C5\F5C5"; }
  
  .fad.fa-arrows-up-down::after, .fa-duotone.fa-arrows-up-down::after {
	content: "\F07D\F07D"; }
  
  .fad.fa-arrows-v::after, .fa-duotone.fa-arrows-v::after {
	content: "\F07D\F07D"; }
  
  .fad.fa-chess-pawn-piece::after, .fa-duotone.fa-chess-pawn-piece::after {
	content: "\F444\F444"; }
  
  .fad.fa-chess-pawn-alt::after, .fa-duotone.fa-chess-pawn-alt::after {
	content: "\F444\F444"; }
  
  .fad.fa-face-grimace::after, .fa-duotone.fa-face-grimace::after {
	content: "\F57F\F57F"; }
  
  .fad.fa-grimace::after, .fa-duotone.fa-grimace::after {
	content: "\F57F\F57F"; }
  
  .fad.fa-wheelchair-move::after, .fa-duotone.fa-wheelchair-move::after {
	content: "\E2CE\E2CE"; }
  
  .fad.fa-wheelchair-alt::after, .fa-duotone.fa-wheelchair-alt::after {
	content: "\E2CE\E2CE"; }
  
  .fad.fa-turn-down::after, .fa-duotone.fa-turn-down::after {
	content: "\F3BE\F3BE"; }
  
  .fad.fa-level-down-alt::after, .fa-duotone.fa-level-down-alt::after {
	content: "\F3BE\F3BE"; }
  
  .fad.fa-square-s::after, .fa-duotone.fa-square-s::after {
	content: "\E27D\E27D"; }
  
  .fad.fa-rectangle-barcode::after, .fa-duotone.fa-rectangle-barcode::after {
	content: "\F463\F463"; }
  
  .fad.fa-barcode-alt::after, .fa-duotone.fa-barcode-alt::after {
	content: "\F463\F463"; }
  
  .fad.fa-person-walking-arrow-right::after, .fa-duotone.fa-person-walking-arrow-right::after {
	content: "\E552\E552"; }
  
  .fad.fa-square-envelope::after, .fa-duotone.fa-square-envelope::after {
	content: "\F199\F199"; }
  
  .fad.fa-envelope-square::after, .fa-duotone.fa-envelope-square::after {
	content: "\F199\F199"; }
  
  .fad.fa-dice::after, .fa-duotone.fa-dice::after {
	content: "\F522\F522"; }
  
  .fad.fa-unicorn::after, .fa-duotone.fa-unicorn::after {
	content: "\F727\F727"; }
  
  .fad.fa-bowling-ball::after, .fa-duotone.fa-bowling-ball::after {
	content: "\F436\F436"; }
  
  .fad.fa-pompebled::after, .fa-duotone.fa-pompebled::after {
	content: "\E43D\E43D"; }
  
  .fad.fa-brain::after, .fa-duotone.fa-brain::after {
	content: "\F5DC\F5DC"; }
  
  .fad.fa-watch-smart::after, .fa-duotone.fa-watch-smart::after {
	content: "\E2CC\E2CC"; }
  
  .fad.fa-book-user::after, .fa-duotone.fa-book-user::after {
	content: "\F7E7\F7E7"; }
  
  .fad.fa-sensor-cloud::after, .fa-duotone.fa-sensor-cloud::after {
	content: "\E02C\E02C"; }
  
  .fad.fa-sensor-smoke::after, .fa-duotone.fa-sensor-smoke::after {
	content: "\E02C\E02C"; }
  
  .fad.fa-clapperboard-play::after, .fa-duotone.fa-clapperboard-play::after {
	content: "\E132\E132"; }
  
  .fad.fa-bandage::after, .fa-duotone.fa-bandage::after {
	content: "\F462\F462"; }
  
  .fad.fa-band-aid::after, .fa-duotone.fa-band-aid::after {
	content: "\F462\F462"; }
  
  .fad.fa-calendar-minus::after, .fa-duotone.fa-calendar-minus::after {
	content: "\F272\F272"; }
  
  .fad.fa-circle-xmark::after, .fa-duotone.fa-circle-xmark::after {
	content: "\F057\F057"; }
  
  .fad.fa-times-circle::after, .fa-duotone.fa-times-circle::after {
	content: "\F057\F057"; }
  
  .fad.fa-xmark-circle::after, .fa-duotone.fa-xmark-circle::after {
	content: "\F057\F057"; }
  
  .fad.fa-circle-4::after, .fa-duotone.fa-circle-4::after {
	content: "\E0F1\E0F1"; }
  
  .fad.fa-gifts::after, .fa-duotone.fa-gifts::after {
	content: "\F79C\F79C"; }
  
  .fad.fa-album-collection::after, .fa-duotone.fa-album-collection::after {
	content: "\F8A0\F8A0"; }
  
  .fad.fa-hotel::after, .fa-duotone.fa-hotel::after {
	content: "\F594\F594"; }
  
  .fad.fa-earth-asia::after, .fa-duotone.fa-earth-asia::after {
	content: "\F57E\F57E"; }
  
  .fad.fa-globe-asia::after, .fa-duotone.fa-globe-asia::after {
	content: "\F57E\F57E"; }
  
  .fad.fa-id-card-clip::after, .fa-duotone.fa-id-card-clip::after {
	content: "\F47F\F47F"; }
  
  .fad.fa-id-card-alt::after, .fa-duotone.fa-id-card-alt::after {
	content: "\F47F\F47F"; }
  
  .fad.fa-magnifying-glass-plus::after, .fa-duotone.fa-magnifying-glass-plus::after {
	content: "\F00E\F00E"; }
  
  .fad.fa-search-plus::after, .fa-duotone.fa-search-plus::after {
	content: "\F00E\F00E"; }
  
  .fad.fa-thumbs-up::after, .fa-duotone.fa-thumbs-up::after {
	content: "\F164\F164"; }
  
  .fad.fa-cloud-showers::after, .fa-duotone.fa-cloud-showers::after {
	content: "\F73F\F73F"; }
  
  .fad.fa-user-clock::after, .fa-duotone.fa-user-clock::after {
	content: "\F4FD\F4FD"; }
  
  .fad.fa-onion::after, .fa-duotone.fa-onion::after {
	content: "\E427\E427"; }
  
  .fad.fa-clock-twelve-thirty::after, .fa-duotone.fa-clock-twelve-thirty::after {
	content: "\E359\E359"; }
  
  .fad.fa-arrow-down-to-dotted-line::after, .fa-duotone.fa-arrow-down-to-dotted-line::after {
	content: "\E095\E095"; }
  
  .fad.fa-hand-dots::after, .fa-duotone.fa-hand-dots::after {
	content: "\F461\F461"; }
  
  .fad.fa-allergies::after, .fa-duotone.fa-allergies::after {
	content: "\F461\F461"; }
  
  .fad.fa-file-invoice::after, .fa-duotone.fa-file-invoice::after {
	content: "\F570\F570"; }
  
  .fad.fa-window-minimize::after, .fa-duotone.fa-window-minimize::after {
	content: "\F2D1\F2D1"; }
  
  .fad.fa-rectangle-wide::after, .fa-duotone.fa-rectangle-wide::after {
	content: "\F2FC\F2FC"; }
  
  .fad.fa-comment-arrow-up::after, .fa-duotone.fa-comment-arrow-up::after {
	content: "\E144\E144"; }
  
  .fad.fa-garlic::after, .fa-duotone.fa-garlic::after {
	content: "\E40E\E40E"; }
  
  .fad.fa-mug-saucer::after, .fa-duotone.fa-mug-saucer::after {
	content: "\F0F4\F0F4"; }
  
  .fad.fa-coffee::after, .fa-duotone.fa-coffee::after {
	content: "\F0F4\F0F4"; }
  
  .fad.fa-brush::after, .fa-duotone.fa-brush::after {
	content: "\F55D\F55D"; }
  
  .fad.fa-tree-decorated::after, .fa-duotone.fa-tree-decorated::after {
	content: "\F7DC\F7DC"; }
  
  .fad.fa-mask::after, .fa-duotone.fa-mask::after {
	content: "\F6FA\F6FA"; }
  
  .fad.fa-calendar-heart::after, .fa-duotone.fa-calendar-heart::after {
	content: "\E0D3\E0D3"; }
  
  .fad.fa-magnifying-glass-minus::after, .fa-duotone.fa-magnifying-glass-minus::after {
	content: "\F010\F010"; }
  
  .fad.fa-search-minus::after, .fa-duotone.fa-search-minus::after {
	content: "\F010\F010"; }
  
  .fad.fa-flower::after, .fa-duotone.fa-flower::after {
	content: "\F7FF\F7FF"; }
  
  .fad.fa-ruler-vertical::after, .fa-duotone.fa-ruler-vertical::after {
	content: "\F548\F548"; }
  
  .fad.fa-user-large::after, .fa-duotone.fa-user-large::after {
	content: "\F406\F406"; }
  
  .fad.fa-user-alt::after, .fa-duotone.fa-user-alt::after {
	content: "\F406\F406"; }
  
  .fad.fa-starship-freighter::after, .fa-duotone.fa-starship-freighter::after {
	content: "\E03A\E03A"; }
  
  .fad.fa-train-tram::after, .fa-duotone.fa-train-tram::after {
	content: "\E5B4\E5B4"; }
  
  .fad.fa-bridge-suspension::after, .fa-duotone.fa-bridge-suspension::after {
	content: "\E4CD\E4CD"; }
  
  .fad.fa-trash-check::after, .fa-duotone.fa-trash-check::after {
	content: "\E2AF\E2AF"; }
  
  .fad.fa-user-nurse::after, .fa-duotone.fa-user-nurse::after {
	content: "\F82F\F82F"; }
  
  .fad.fa-boombox::after, .fa-duotone.fa-boombox::after {
	content: "\F8A5\F8A5"; }
  
  .fad.fa-syringe::after, .fa-duotone.fa-syringe::after {
	content: "\F48E\F48E"; }
  
  .fad.fa-cloud-sun::after, .fa-duotone.fa-cloud-sun::after {
	content: "\F6C4\F6C4"; }
  
  .fad.fa-shield-exclamation::after, .fa-duotone.fa-shield-exclamation::after {
	content: "\E247\E247"; }
  
  .fad.fa-stopwatch-20::after, .fa-duotone.fa-stopwatch-20::after {
	content: "\E06F\E06F"; }
  
  .fad.fa-square-full::after, .fa-duotone.fa-square-full::after {
	content: "\F45C\F45C"; }
  
  .fad.fa-grip-dots::after, .fa-duotone.fa-grip-dots::after {
	content: "\E410\E410"; }
  
  .fad.fa-comment-exclamation::after, .fa-duotone.fa-comment-exclamation::after {
	content: "\F4AF\F4AF"; }
  
  .fad.fa-pen-swirl::after, .fa-duotone.fa-pen-swirl::after {
	content: "\E214\E214"; }
  
  .fad.fa-falafel::after, .fa-duotone.fa-falafel::after {
	content: "\E40A\E40A"; }
  
  .fad.fa-circle-2::after, .fa-duotone.fa-circle-2::after {
	content: "\E0EF\E0EF"; }
  
  .fad.fa-magnet::after, .fa-duotone.fa-magnet::after {
	content: "\F076\F076"; }
  
  .fad.fa-jar::after, .fa-duotone.fa-jar::after {
	content: "\E516\E516"; }
  
  .fad.fa-gramophone::after, .fa-duotone.fa-gramophone::after {
	content: "\F8BD\F8BD"; }
  
  .fad.fa-dice-d12::after, .fa-duotone.fa-dice-d12::after {
	content: "\F6CE\F6CE"; }
  
  .fad.fa-note-sticky::after, .fa-duotone.fa-note-sticky::after {
	content: "\F249\F249"; }
  
  .fad.fa-sticky-note::after, .fa-duotone.fa-sticky-note::after {
	content: "\F249\F249"; }
  
  .fad.fa-down::after, .fa-duotone.fa-down::after {
	content: "\F354\F354"; }
  
  .fad.fa-arrow-alt-down::after, .fa-duotone.fa-arrow-alt-down::after {
	content: "\F354\F354"; }
  
  .fad.fa-hundred-points::after, .fa-duotone.fa-hundred-points::after {
	content: "\E41C\E41C"; }
  
  .fad.fa-100::after, .fa-duotone.fa-100::after {
	content: "\E41C\E41C"; }
  
  .fad.fa-paperclip-vertical::after, .fa-duotone.fa-paperclip-vertical::after {
	content: "\E3C2\E3C2"; }
  
  .fad.fa-wind-warning::after, .fa-duotone.fa-wind-warning::after {
	content: "\F776\F776"; }
  
  .fad.fa-wind-circle-exclamation::after, .fa-duotone.fa-wind-circle-exclamation::after {
	content: "\F776\F776"; }
  
  .fad.fa-location-pin-slash::after, .fa-duotone.fa-location-pin-slash::after {
	content: "\F60C\F60C"; }
  
  .fad.fa-map-marker-slash::after, .fa-duotone.fa-map-marker-slash::after {
	content: "\F60C\F60C"; }
  
  .fad.fa-face-sad-sweat::after, .fa-duotone.fa-face-sad-sweat::after {
	content: "\E38A\E38A"; }
  
  .fad.fa-bug-slash::after, .fa-duotone.fa-bug-slash::after {
	content: "\E490\E490"; }
  
  .fad.fa-cupcake::after, .fa-duotone.fa-cupcake::after {
	content: "\E402\E402"; }
  
  .fad.fa-light-switch-off::after, .fa-duotone.fa-light-switch-off::after {
	content: "\E018\E018"; }
  
  .fad.fa-toggle-large-off::after, .fa-duotone.fa-toggle-large-off::after {
	content: "\E5B0\E5B0"; }
  
  .fad.fa-pen-fancy-slash::after, .fa-duotone.fa-pen-fancy-slash::after {
	content: "\E210\E210"; }
  
  .fad.fa-truck-container::after, .fa-duotone.fa-truck-container::after {
	content: "\F4DC\F4DC"; }
  
  .fad.fa-boot::after, .fa-duotone.fa-boot::after {
	content: "\F782\F782"; }
  
  .fad.fa-arrow-up-from-water-pump::after, .fa-duotone.fa-arrow-up-from-water-pump::after {
	content: "\E4B6\E4B6"; }
  
  .fad.fa-file-check::after, .fa-duotone.fa-file-check::after {
	content: "\F316\F316"; }
  
  .fad.fa-bone::after, .fa-duotone.fa-bone::after {
	content: "\F5D7\F5D7"; }
  
  .fad.fa-cards-blank::after, .fa-duotone.fa-cards-blank::after {
	content: "\E4DF\E4DF"; }
  
  .fad.fa-circle-3::after, .fa-duotone.fa-circle-3::after {
	content: "\E0F0\E0F0"; }
  
  .fad.fa-bench-tree::after, .fa-duotone.fa-bench-tree::after {
	content: "\E2E7\E2E7"; }
  
  .fad.fa-keyboard-brightness-low::after, .fa-duotone.fa-keyboard-brightness-low::after {
	content: "\E1C1\E1C1"; }
  
  .fad.fa-ski-boot-ski::after, .fa-duotone.fa-ski-boot-ski::after {
	content: "\E3CD\E3CD"; }
  
  .fad.fa-brain-circuit::after, .fa-duotone.fa-brain-circuit::after {
	content: "\E0C6\E0C6"; }
  
  .fad.fa-user-injured::after, .fa-duotone.fa-user-injured::after {
	content: "\F728\F728"; }
  
  .fad.fa-block-brick-fire::after, .fa-duotone.fa-block-brick-fire::after {
	content: "\E3DC\E3DC"; }
  
  .fad.fa-firewall::after, .fa-duotone.fa-firewall::after {
	content: "\E3DC\E3DC"; }
  
  .fad.fa-face-sad-tear::after, .fa-duotone.fa-face-sad-tear::after {
	content: "\F5B4\F5B4"; }
  
  .fad.fa-sad-tear::after, .fa-duotone.fa-sad-tear::after {
	content: "\F5B4\F5B4"; }
  
  .fad.fa-plane::after, .fa-duotone.fa-plane::after {
	content: "\F072\F072"; }
  
  .fad.fa-tent-arrows-down::after, .fa-duotone.fa-tent-arrows-down::after {
	content: "\E581\E581"; }
  
  .fad.fa-exclamation::after, .fa-duotone.fa-exclamation::after {
	content: "!!"; }
  
  .fad.fa-arrows-spin::after, .fa-duotone.fa-arrows-spin::after {
	content: "\E4BB\E4BB"; }
  
  .fad.fa-face-smile-relaxed::after, .fa-duotone.fa-face-smile-relaxed::after {
	content: "\E392\E392"; }
  
  .fad.fa-comment-xmark::after, .fa-duotone.fa-comment-xmark::after {
	content: "\F4B5\F4B5"; }
  
  .fad.fa-comment-times::after, .fa-duotone.fa-comment-times::after {
	content: "\F4B5\F4B5"; }
  
  .fad.fa-print::after, .fa-duotone.fa-print::after {
	content: "\F02F\F02F"; }
  
  .fad.fa-turkish-lira-sign::after, .fa-duotone.fa-turkish-lira-sign::after {
	content: "\E2BB\E2BB"; }
  
  .fad.fa-try::after, .fa-duotone.fa-try::after {
	content: "\E2BB\E2BB"; }
  
  .fad.fa-turkish-lira::after, .fa-duotone.fa-turkish-lira::after {
	content: "\E2BB\E2BB"; }
  
  .fad.fa-face-nose-steam::after, .fa-duotone.fa-face-nose-steam::after {
	content: "\E382\E382"; }
  
  .fad.fa-circle-waveform-lines::after, .fa-duotone.fa-circle-waveform-lines::after {
	content: "\E12D\E12D"; }
  
  .fad.fa-waveform-circle::after, .fa-duotone.fa-waveform-circle::after {
	content: "\E12D\E12D"; }
  
  .fad.fa-dollar-sign::after, .fa-duotone.fa-dollar-sign::after {
	content: "$$"; }
  
  .fad.fa-dollar::after, .fa-duotone.fa-dollar::after {
	content: "$$"; }
  
  .fad.fa-usd::after, .fa-duotone.fa-usd::after {
	content: "$$"; }
  
  .fad.fa-ferris-wheel::after, .fa-duotone.fa-ferris-wheel::after {
	content: "\E174\E174"; }
  
  .fad.fa-computer-speaker::after, .fa-duotone.fa-computer-speaker::after {
	content: "\F8B2\F8B2"; }
  
  .fad.fa-skull-cow::after, .fa-duotone.fa-skull-cow::after {
	content: "\F8DE\F8DE"; }
  
  .fad.fa-x::after, .fa-duotone.fa-x::after {
	content: "XX"; }
  
  .fad.fa-magnifying-glass-dollar::after, .fa-duotone.fa-magnifying-glass-dollar::after {
	content: "\F688\F688"; }
  
  .fad.fa-search-dollar::after, .fa-duotone.fa-search-dollar::after {
	content: "\F688\F688"; }
  
  .fad.fa-users-gear::after, .fa-duotone.fa-users-gear::after {
	content: "\F509\F509"; }
  
  .fad.fa-users-cog::after, .fa-duotone.fa-users-cog::after {
	content: "\F509\F509"; }
  
  .fad.fa-person-military-pointing::after, .fa-duotone.fa-person-military-pointing::after {
	content: "\E54A\E54A"; }
  
  .fad.fa-building-columns::after, .fa-duotone.fa-building-columns::after {
	content: "\F19C\F19C"; }
  
  .fad.fa-bank::after, .fa-duotone.fa-bank::after {
	content: "\F19C\F19C"; }
  
  .fad.fa-institution::after, .fa-duotone.fa-institution::after {
	content: "\F19C\F19C"; }
  
  .fad.fa-museum::after, .fa-duotone.fa-museum::after {
	content: "\F19C\F19C"; }
  
  .fad.fa-university::after, .fa-duotone.fa-university::after {
	content: "\F19C\F19C"; }
  
  .fad.fa-circle-t::after, .fa-duotone.fa-circle-t::after {
	content: "\E124\E124"; }
  
  .fad.fa-sack::after, .fa-duotone.fa-sack::after {
	content: "\F81C\F81C"; }
  
  .fad.fa-grid-2::after, .fa-duotone.fa-grid-2::after {
	content: "\E196\E196"; }
  
  .fad.fa-camera-cctv::after, .fa-duotone.fa-camera-cctv::after {
	content: "\F8AC\F8AC"; }
  
  .fad.fa-cctv::after, .fa-duotone.fa-cctv::after {
	content: "\F8AC\F8AC"; }
  
  .fad.fa-umbrella::after, .fa-duotone.fa-umbrella::after {
	content: "\F0E9\F0E9"; }
  
  .fad.fa-trowel::after, .fa-duotone.fa-trowel::after {
	content: "\E589\E589"; }
  
  .fad.fa-horizontal-rule::after, .fa-duotone.fa-horizontal-rule::after {
	content: "\F86C\F86C"; }
  
  .fad.fa-bed-front::after, .fa-duotone.fa-bed-front::after {
	content: "\F8F7\F8F7"; }
  
  .fad.fa-bed-alt::after, .fa-duotone.fa-bed-alt::after {
	content: "\F8F7\F8F7"; }
  
  .fad.fa-d::after, .fa-duotone.fa-d::after {
	content: "DD"; }
  
  .fad.fa-stapler::after, .fa-duotone.fa-stapler::after {
	content: "\E5AF\E5AF"; }
  
  .fad.fa-masks-theater::after, .fa-duotone.fa-masks-theater::after {
	content: "\F630\F630"; }
  
  .fad.fa-theater-masks::after, .fa-duotone.fa-theater-masks::after {
	content: "\F630\F630"; }
  
  .fad.fa-kip-sign::after, .fa-duotone.fa-kip-sign::after {
	content: "\E1C4\E1C4"; }
  
  .fad.fa-face-woozy::after, .fa-duotone.fa-face-woozy::after {
	content: "\E3A2\E3A2"; }
  
  .fad.fa-cloud-question::after, .fa-duotone.fa-cloud-question::after {
	content: "\E492\E492"; }
  
  .fad.fa-pineapple::after, .fa-duotone.fa-pineapple::after {
	content: "\E31F\E31F"; }
  
  .fad.fa-hand-point-left::after, .fa-duotone.fa-hand-point-left::after {
	content: "\F0A5\F0A5"; }
  
  .fad.fa-gallery-thumbnails::after, .fa-duotone.fa-gallery-thumbnails::after {
	content: "\E3AA\E3AA"; }
  
  .fad.fa-circle-j::after, .fa-duotone.fa-circle-j::after {
	content: "\E112\E112"; }
  
  .fad.fa-eyes::after, .fa-duotone.fa-eyes::after {
	content: "\E367\E367"; }
  
  .fad.fa-handshake-simple::after, .fa-duotone.fa-handshake-simple::after {
	content: "\F4C6\F4C6"; }
  
  .fad.fa-handshake-alt::after, .fa-duotone.fa-handshake-alt::after {
	content: "\F4C6\F4C6"; }
  
  .fad.fa-page-caret-up::after, .fa-duotone.fa-page-caret-up::after {
	content: "\E42A\E42A"; }
  
  .fad.fa-file-caret-up::after, .fa-duotone.fa-file-caret-up::after {
	content: "\E42A\E42A"; }
  
  .fad.fa-jet-fighter::after, .fa-duotone.fa-jet-fighter::after {
	content: "\F0FB\F0FB"; }
  
  .fad.fa-fighter-jet::after, .fa-duotone.fa-fighter-jet::after {
	content: "\F0FB\F0FB"; }
  
  .fad.fa-comet::after, .fa-duotone.fa-comet::after {
	content: "\E003\E003"; }
  
  .fad.fa-square-share-nodes::after, .fa-duotone.fa-square-share-nodes::after {
	content: "\F1E1\F1E1"; }
  
  .fad.fa-share-alt-square::after, .fa-duotone.fa-share-alt-square::after {
	content: "\F1E1\F1E1"; }
  
  .fad.fa-shield-keyhole::after, .fa-duotone.fa-shield-keyhole::after {
	content: "\E248\E248"; }
  
  .fad.fa-barcode::after, .fa-duotone.fa-barcode::after {
	content: "\F02A\F02A"; }
  
  .fad.fa-plus-minus::after, .fa-duotone.fa-plus-minus::after {
	content: "\E43C\E43C"; }
  
  .fad.fa-square-sliders-vertical::after, .fa-duotone.fa-square-sliders-vertical::after {
	content: "\F3F2\F3F2"; }
  
  .fad.fa-sliders-v-square::after, .fa-duotone.fa-sliders-v-square::after {
	content: "\F3F2\F3F2"; }
  
  .fad.fa-video::after, .fa-duotone.fa-video::after {
	content: "\F03D\F03D"; }
  
  .fad.fa-video-camera::after, .fa-duotone.fa-video-camera::after {
	content: "\F03D\F03D"; }
  
  .fad.fa-message-middle::after, .fa-duotone.fa-message-middle::after {
	content: "\E1E1\E1E1"; }
  
  .fad.fa-comment-middle-alt::after, .fa-duotone.fa-comment-middle-alt::after {
	content: "\E1E1\E1E1"; }
  
  .fad.fa-graduation-cap::after, .fa-duotone.fa-graduation-cap::after {
	content: "\F19D\F19D"; }
  
  .fad.fa-mortar-board::after, .fa-duotone.fa-mortar-board::after {
	content: "\F19D\F19D"; }
  
  .fad.fa-hand-holding-medical::after, .fa-duotone.fa-hand-holding-medical::after {
	content: "\E05C\E05C"; }
  
  .fad.fa-person-circle-check::after, .fa-duotone.fa-person-circle-check::after {
	content: "\E53E\E53E"; }
  
  .fad.fa-square-z::after, .fa-duotone.fa-square-z::after {
	content: "\E288\E288"; }
  
  .fad.fa-message-text::after, .fa-duotone.fa-message-text::after {
	content: "\E1E6\E1E6"; }
  
  .fad.fa-comment-alt-text::after, .fa-duotone.fa-comment-alt-text::after {
	content: "\E1E6\E1E6"; }
  
  .fad.fa-turn-up::after, .fa-duotone.fa-turn-up::after {
	content: "\F3BF\F3BF"; }
  
  .fad.fa-level-up-alt::after, .fa-duotone.fa-level-up-alt::after {
	content: "\F3BF\F3BF"; }
  :root, :host {
	--fa-style-family-classic: 'Font Awesome 6 Pro';
	--fa-font-light: normal 300 1em/1 'Font Awesome 6 Pro'; }
  
  @font-face {
	font-family: 'Font Awesome 6 Pro';
	font-style: normal;
	font-weight: 300;
	font-display: block;
	src: url(/static/media/fa-light-300.c46d631b.woff2) format("woff2"), url(/static/media/fa-light-300.f46e5fd4.ttf) format("truetype"); }
  
  .fal,
  .fa-light {
	font-weight: 300; }
  :root, :host {
	--fa-style-family-classic: 'Font Awesome 6 Pro';
	--fa-font-regular: normal 400 1em/1 'Font Awesome 6 Pro'; }
  
  @font-face {
	font-family: 'Font Awesome 6 Pro';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url(/static/media/fa-regular-400.4a93626e.woff2) format("woff2"), url(/static/media/fa-regular-400.8194f671.ttf) format("truetype"); }
  
  .far,
  .fa-regular {
	font-weight: 400; }
  :root, :host {
	--fa-style-family-classic: 'Font Awesome 6 Pro';
	--fa-font-solid: normal 900 1em/1 'Font Awesome 6 Pro'; }
  
  @font-face {
	font-family: 'Font Awesome 6 Pro';
	font-style: normal;
	font-weight: 900;
	font-display: block;
	src: url(/static/media/fa-solid-900.750e40b5.woff2) format("woff2"), url(/static/media/fa-solid-900.dd8840af.ttf) format("truetype"); }
  
  .fas,
  .fa-solid {
	font-weight: 900; }
  :root, :host {
	--fa-style-family-classic: 'Font Awesome 6 Pro';
	--fa-font-thin: normal 100 1em/1 'Font Awesome 6 Pro'; }
  
  @font-face {
	font-family: 'Font Awesome 6 Pro';
	font-style: normal;
	font-weight: 100;
	font-display: block;
	src: url(/static/media/fa-thin-100.29a80a91.woff2) format("woff2"), url(/static/media/fa-thin-100.d6b811b6.ttf) format("truetype"); }
  
  .fat,
  .fa-thin {
	font-weight: 100; }
  @font-face {
	font-family: 'Font Awesome 5 Brands';
	font-display: block;
	font-weight: 400;
	src: url(/static/media/fa-brands-400.f84deb5c.woff2) format("woff2"), url(/static/media/fa-brands-400.584b883d.ttf) format("truetype"); }
  
  @font-face {
	font-family: 'Font Awesome 5 Pro';
	font-display: block;
	font-weight: 900;
	src: url(/static/media/fa-solid-900.750e40b5.woff2) format("woff2"), url(/static/media/fa-solid-900.dd8840af.ttf) format("truetype"); }
  
  @font-face {
	font-family: 'Font Awesome 5 Pro';
	font-display: block;
	font-weight: 400;
	src: url(/static/media/fa-regular-400.4a93626e.woff2) format("woff2"), url(/static/media/fa-regular-400.8194f671.ttf) format("truetype"); }
  
  @font-face {
	font-family: 'Font Awesome 5 Pro';
	font-display: block;
	font-weight: 300;
	src: url(/static/media/fa-light-300.c46d631b.woff2) format("woff2"), url(/static/media/fa-light-300.f46e5fd4.ttf) format("truetype"); }
  
  @font-face {
	font-family: 'Font Awesome 5 Duotone';
	font-display: block;
	font-weight: 900;
	src: url(/static/media/fa-duotone-900.a5068b6a.woff2) format("woff2"), url(/static/media/fa-duotone-900.08446e6b.ttf) format("truetype"); }
  @font-face {
	font-family: 'FontAwesome';
	font-display: block;
	src: url(/static/media/fa-solid-900.750e40b5.woff2) format("woff2"), url(/static/media/fa-solid-900.dd8840af.ttf) format("truetype"); }
  
  @font-face {
	font-family: 'FontAwesome';
	font-display: block;
	src: url(/static/media/fa-brands-400.f84deb5c.woff2) format("woff2"), url(/static/media/fa-brands-400.584b883d.ttf) format("truetype"); }
  
  @font-face {
	font-family: 'FontAwesome';
	font-display: block;
	src: url(/static/media/fa-regular-400.4a93626e.woff2) format("woff2"), url(/static/media/fa-regular-400.8194f671.ttf) format("truetype");
	unicode-range: U+F003,U+F006,U+F014,U+F016-F017,U+F01A-F01B,U+F01D,U+F022,U+F03E,U+F044,U+F046,U+F05C-F05D,U+F06E,U+F070,U+F087-F088,U+F08A,U+F094,U+F096-F097,U+F09D,U+F0A0,U+F0A2,U+F0A4-F0A7,U+F0C5,U+F0C7,U+F0E5-F0E6,U+F0EB,U+F0F6-F0F8,U+F10C,U+F114-F115,U+F118-F11A,U+F11C-F11D,U+F133,U+F147,U+F14E,U+F150-F152,U+F185-F186,U+F18E,U+F190-F192,U+F196,U+F1C1-F1C9,U+F1D9,U+F1DB,U+F1E3,U+F1EA,U+F1F7,U+F1F9,U+F20A,U+F247-F248,U+F24A,U+F24D,U+F255-F25B,U+F25D,U+F271-F274,U+F278,U+F27B,U+F28C,U+F28E,U+F29C,U+F2B5,U+F2B7,U+F2BA,U+F2BC,U+F2BE,U+F2C0-F2C1,U+F2C3,U+F2D0,U+F2D2,U+F2D4,U+F2DC; }
  
  @font-face {
	font-family: 'FontAwesome';
	font-display: block;
	src: url(/static/media/fa-v4compatibility.93f3ecc1.woff2) format("woff2"), url(/static/media/fa-v4compatibility.72b4a1c3.ttf) format("truetype");
	unicode-range: U+F041,U+F047,U+F065-F066,U+F07D-F07E,U+F080,U+F08B,U+F08E,U+F090,U+F09A,U+F0AC,U+F0AE,U+F0B2,U+F0D0,U+F0D6,U+F0E4,U+F0EC,U+F10A-F10B,U+F123,U+F13E,U+F148-F149,U+F14C,U+F156,U+F15E,U+F160-F161,U+F163,U+F175-F178,U+F195,U+F1F8,U+F219,U+F27A; }
  
:root {
  --primary-color: #909;
}
.primary-color {
  color: #909;
  color: var(--primary-color);
}
.x_title {
  border-bottom: 2px solid #E6E9ED;
  padding: 0 15px 5px 15px;
}
.notification-success {
  background-color: var(--notification-success-bgColor);
  border-color: var(--notification-success-borderColor);
  color: var(--notification-success-color)
}

.notification-warning {
  background-color: var(--notification-warning-bgColor);
  border-color: var(--notification-warning-borderColor);
  color: var(--notification-warning-color)
}

.notification-error {
  background-color: var(--notification-error-bgColor);
  border-color: var(--notification-error-borderColor);
  color: var(--notification-error-color)
} 

.notification-info {
  background-color: var(--notification-info-bgColor);
  border-color: var(--notification-info-borderColor);
  color: var(--notification-info-color)
}

.show-cursor {
  cursor: pointer;
}

.ln_solid {
  border-top: 1px solid #e5e5e5;
  color: #fff;
  background-color: #fff;
  height: 1px;
  margin: 30px 0;
  padding: 0px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-15 {
  margin-right: 15px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px
}

.margin-top-30 {
  margin-top: 30px
}

.margin-left-10 {
  margin-left: 10px;
}

a {
  cursor: pointer;
}
.no-pointer{
  cursor: not-allowed;
  pointer-events: none;
}
.clear-float:after {
  content: "";
  display: table;
  clear: both;
}

.span-nodisplay {
  visibility: hidden;
  margin-top: 5px;
}

.span-nodisplay[data-display-none="true"] {
  display: none;
}

.divError, .divSuccess, .divWarning {
  display: flex;
  align-items: baseline;
  grid-gap:5px;
  gap:5px;
}

.divError {
  margin-bottom: 1rem;
  color: #FF0000;
}

.divSuccess {
  color: #4ACA75;
}

.divWarning {
  color: #FF9103;
}

.span-err-margin {
  margin-top: 5px;
}

.alert {
  margin-bottom: 0;
}

#init-modal, #info-modal {
  background: #FFF;
  color: #333;
  opacity: 0.95;
  position: fixed;
  z-index: 24801;
  display: table;
  height: 100%;
  width: 100%;
}

.loader-content{
    display: table-cell;
    vertical-align: middle;
}

.component-content, .loader-content{
    text-align: center;
    color: #909;
    color: var(--primary-color);
}

.no-data {
  margin: 15px 0px;
  padding: 10px;
  border: 1px dashed gainsboro;
  text-align: center;
  font-weight: bold;
}

.loader-span {
  display: block;
  margin-top: 0.6%;
  color: #909;
  color: var(--primary-color);
}

.margin-0 {
  margin: 0px;
  min-height: 80px;
}
.height-adjust{
  min-height: 75px;
}

.Select-control {
  border-radius: 2px;
}
.form-control {
  color: var(--form-control-text-color);
}
.form-control:focus, .Select.is-focused:not(.is-open) > .Select-control {
  border-color: var(--form-control-border); /* #909 */
  outline: 0;
  box-shadow: inset 0 1px 1px var(--form-control-border), 0 0 8px var(--form-control-border);
}

.Select-placeholder, .Select--single > .Select-control .Select-value {
  font-size: 14px;
  color: #999;
}

.Select.has-value.Select--single > .Select-control .Select-value .Select-value-label, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  color: #555;
}

.Select--multi .Select-value{
  margin-top: 6px !important;
}

.tenant-session-msg {
  position: absolute;
  top: 50px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  text-align: center
}

.show {
  display: block;
}

.hide {
  display: none;
}

.blur {
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');
  -webkit-filter: url(#blur-filter);
  filter: url(#blur-filter);
  -webkit-filter: blur(3px);
  filter: blur(3px);
  pointer-events: none;
}

.layout {
  display: grid;
  grid-row-gap: 10px;
  grid-column-gap: 20px;
  margin: 0 20px;
  background: #ffffff;
  grid-template-columns:1fr;
  margin-bottom: 20px;
}

.outlined-div {
    position: relative;
    height: auto;
    border: 2px solid #EEE;
    padding: 5px 10px 5px 15px;
    box-sizing: border-box;
    background-color: #EEE;
    min-height: 400px;
  }
  
  .outlined-div:after{
    position: absolute;
    width: 33px;
    height: 33px;
    border-top: 2px solid #EEE;
    border-right: 2px solid #EEE;
    top: 50%;
    left: 3%;
    margin-left: -27px;
    content: '';
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-top: -25px;
    background: #ffffff;
  }

.info-icon {
  position: relative;
  top: 6px;
  right: 5px;
  color: #909;
  color: var(--primary-color);
} 
  
.list-icons {
  margin-right: 10px;
}

.paracontent{
  color: #909;
}

button.is-disabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

.list-btn:hover {
  color: #909;
}

.border-bg-none {
  border: none;
  background-color: inherit;
}

.tab {
    width: 180px;
    float: left;
    margin: 0 -16px 0 0;
    cursor: pointer;
}

.tab1 {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.tab2 {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.trackersTab {
  font-size: 14px;
  text-align: center;
  color: var(--inactive-tab-color);
  background: var(--inactive-tab-bg-color);
  padding-top: 6px;
  height: 35px;
  border: 1px solid #C0C;
  margin: 0 7px 0;
  box-shadow: 0 0 2px #fff inset;
}

.tab.setting-active .trackersTab{
  background-color: #C0C;
  color: #FFF;
}

form.gcmForm {
  padding: 0px !important;
}

.toolTipAnchorClass{
  padding: 20px 10px 0px 10px;
}

.buttonClass{
  display: flex;
  align-items: center; 
  justify-content: flex-start;
}

.helpText{
    border-radius: 6px !important;
    font-size: 11px !important;
    padding: 5px 10px !important;
    font-weight: bold !important;
	max-width: 30rem;
}

/* 
TODO: Check if any tooltip with position set to bottom is affected.
.helpText.place-bottom:after{
    border-left: 4px solid transparent !important;
    border-right: 4px solid transparent !important;
    left:55% !important;
} */

.image-preview{
    font-size: 20px !important;
    pointer-events: auto !important;
    padding: 10px 10px 0 10px !important;
    width: 250px;
}
.image-preview:hover {
    visibility: visible !important;
    opacity: 1 !important;
}
.image-preview img{
    margin: auto;
    display: block;
    display: block;
    width: 100%;
    height: auto;
}
span.img-name{
    max-width:210px;
    color: var(--primary-color);
    font-size: 12px;
    cursor: pointer;
    white-space: nowrap; 
    text-overflow: ellipsis;
    overflow-x: hidden;
    margin: 0;
    float: left;
}
i.img-name{ 
    width: 10px;
    position: relative;
    top: 2px;
    float:left;
    color: var(--primary-color);
    font-size: 12px;
    cursor: pointer;
}

.type-light{
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 0px 9px #00000066 !important;
    opacity: 1;
    text-align: left;
    font-size: 13px medium;
    letter-spacing: 0px;
    color: #363636 !important;
}
.__react_component_tooltip.place-bottom:after {
    left: 90%;
}
input[type=text][disabled], input[type=simpleInput][disabled] {
    color: #999;
}
.has-feedback-left {
    padding-left: 45px;
}
.form-control {
    width: 100%;
    border-radius: 2px;
    height: 38px;
}
.form-group {
    margin-bottom: 3px;
    padding: 0;
}
.form-group-div {
    padding: 0 15px;
}
.invalid {
    border: 1px solid red;
    background-color: #FDA49A;
}
.form-control-feedback.left {
    border-right: 1px solid #ccc;
    left: 18px;
}
span.optional {
    color: #999;
}
.form-control-feedback {
    top: 3px;
    margin-top: 24px;
    height: 23px;
    color: var(--primary-color);
    line-height: 24px;
    font-size: 15px;
    z-index: 0 !important;
}
.image-selector {
    display: none !important;
}

label.upload-label {
    float: right;
    position: relative;
    top: -37px;
    height: 36px;
    border-radius: 0px;
}

.remove-file, .remove-file-modal {
    position: absolute;
    float: right;
    right: 115px;
    width: 30px;
    font-size: 15px;
    top: 20px;
    z-index: 1;
    height: 38px;
    color: #000;
    border: 1px solid #ccc;
}

.image, .image-modal{
    width: 37px;
    height: 38px;
    position: absolute;
    top: 20px;
    cursor: pointer;
}

.modal-body .remove-file, .modal-body .image{
    top: 21px;
}

.remove-file:hover{
    background: #FFF;
    color: var(--primary-hover-color);

}

input:-webkit-autofill {
    box-shadow: inset 0 0 0px 9999px white;
}

/* ========= */

.user-input-wrp .floating-label {
	position: absolute;
	pointer-events: none;
	top: 28px;
    transition: 0.2s ease all;
    color: #CCC;
    font-size: 14px;
}
.floatinglabel-left-60 {
    left: 60px;
}
.floatinglabel-left-30 {
    left: 30px;
}
.user-input-wrp input:focus ~ .floating-label,
.user-input-wrp input:not(:focus):not(:placeholder-shown) ~ .floating-label, .floating-label.showPrefix {
	top: -2px;
	left: 15px;
    opacity: 1;
    color:#666;
    font-size: 12px;
}
.textLabel{
    color:#666;
    font-weight: normal;
    margin-bottom: 0px;
    position: relative;
    font-size: 12px;
}

.customRadioGroupLabel{
    position: absolute;
    top:10px;
}

.customRadioLabel {
    font-size: 14px !important;
}
.checkboxLabel{
    font-size: 12px !important;
}

.MuiRadio-colorSecondary.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
}

div[role='radiogroup'] .MuiSvgIcon-root {
    font-size: 2rem !important;
}
label {
    color: var(--main-font-color);
    font-size: var(--main-font-size);
    font-weight: inherit;
}
.labelOut{
    top: 0px;
    transition: 0.2s ease all;
    line-height: 0px;
}
.labelIn{
    top: 28px;
	left: 12px;
    transition: 0.2s ease all;
    color: #CCC;
    font-size: 14px;
    line-height: 0px;
}
.form-row{
    min-height: 85px;
}

.dynamic-form-row {
    min-height: 0px;
}
.dynamic-row{
    min-height: 85px;
}
.ext-height{
    min-height: 80px;
}

.clipboard-icon {
    position: absolute;
    right: 25px;
    top: 21px;
    cursor: pointer;
}

.inputColorText{
    margin-left: 11px;
    width: 73%;
    display: inline;
    position: absolute;
    top: 18px;
    left:40px;
}

.color-label{
    position: absolute;
    left: 15px;
    top: 0px;
    color: #666;
    font-weight: normal;
    margin-bottom: 0px;
    font-size: 12px;
    top:0px;
}

/* css for <input type = 'number'> */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  
    cursor: pointer;
    opacity: 1;
}
input[type=number]::-webkit-inner-spin-button {  
    width: 30px;
    height: 100px;
    margin-right:-50px;
}

/* CSS for Prefix in account name inputbox - Begins */
.prefixHolder span.expand{
    background-color: #E4E4E4;
    border-radius: 5px;
    width: 9px;
    word-wrap: break-word;
    line-height: 4px;
    height: 24px;
    padding: 2px;
    border: 1px solid #AAA;
    display: inline-block;
    cursor: pointer;
}

.prefixHolder{
    position: absolute;
    top: 24px;
    left: 58px;
}

.prefixList span{
    display: inline-block;
    padding: 3px 2px;
    background-color: #E4E4E4;
	border-radius: 5px;
}

.user-input-wrp.accountName .has-feedback-left{
    padding-left: var(--left-padding);
}
/* CSS for Prefix in account name inputbox - Ends */

.clientTitle{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    color: #666666;
    margin-top: 2.5%;
}
.viewabilityTrackers{
    font-size: 18px;
    color: #333333;
    margin-left: -2%;
}
.partnerIntegration_editPartnerIntegration__31r1e .content-title.x_title{
    border-bottom: none;
}
.simpleInput{
    background: #EFEFEF 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 45px;
    display: flex;
    align-items: center;
    padding-left: 2%;
}
.simpleInput .form-control{
    width: 300px;
    height: 30px;
}
.asyncSelect-tooltip{
    display: flex;
}
.asyncSelect-tooltip .info{
    margin-left: 3px;
    display: flex;
    align-items: center;
}
.asyncSelect-tooltip .css-2b097c-container,.css-14jk2my-container{
    flex: auto;
}
.select-tooltip{
    display: flex;
}
.select-tooltip .info{
    margin-left: 3px;
    display: flex;
    align-items: center;
}
.select-tooltip .css-2b097c-container,.css-14jk2my-container{
    flex: auto;
}
.moatParamHeader{
    padding: 10px 0px 10px 15px;
}

.trackerParamHeader {
    padding: 10px 0px 0px 15px;
    margin-bottom: 16px;
}

.moatParamSubHeader{
    font-weight: 600;
}

.trackerSectionHeader {
    font-weight: 600;
    color: #333333;
    border-bottom: 1px solid #E6E6E6;
    padding: 0 0 5px;
    margin: 0 45px 15px 15px;
    width: calc(100% - 35px);
}

.trackerUrlBlock {
    padding-bottom: 15px;
    font-size: 12px;
    word-break: break-all;
}

.trackerUrlBlock.active {
    color: var(--primary-hover-color);
}

.trackerUrlBlock.disabled {
    opacity: 50%;
}

.hideElement {
    display: none !important;
}

.helpTextAnchor{
    font-size: 15px;
    margin-left: 5px;
    margin-right: 10px;
}

.customRadioContainer{
    display: flex;
    align-items: center; 
    justify-content: flex-start;
}
.settings-container{
    position: -webkit-sticky;
    position: sticky;
    top: -15px;
    z-index: 1;
    background: #FFF;
    overflow-y: hidden;
}
.general-settings{
    overflow-y: auto;
    max-height: 400px;
}
.setting-input{
	width: 290px;
    border: 1px solid #CCC;
    padding-left: 6px;
}

.content-row{
	margin: 8px;
}
.content-row label{
    width: 107px;
    margin-right: 3px;
}

.update-details{
	float: right;
}
.button-row{
	margin-top: 10px;
}
.settings-dialog{
    position: relative;
    left: -8px;
}
.settings-dialog .modal-body{
    overflow:auto;
}
.settings-dialog .modal-button{
    margin-left: 10px;
}
.organization-dialog .modal-body{
    max-height: 80vh;
    min-height: 60vh;
}
.user-address{
    resize: none;
}
.change-password-title{
    margin: 0;
    color: #C0C;
    margin-bottom: 16px;
}
.seperator{
    margin-right: 20px;
}

.update-password-details{
    float:right;
}
/* menu start */


.setting-tabs {
    height:37px;
    padding: 1px 0 0 0;
    overflow:visible;
    cursor: pointer;
    margin: 0 0 20px 0;
    border-bottom: 1px solid #efefef;
  }
  .setting-tab {
    min-width:150px;
    text-align: center;
    height:36px;
    overflow:hidden;
    float:left;
    margin:0 -15px 0 0;
  }
  .setting-tab-box {
    font-size: 14px;
    color: var(--inactive-tab-color);
    background: var(--inactive-tab-bg-color);
    padding: 10px;
    height:50px;
    border-radius: 4px;
    border:1px solid #ccc;
    margin:0 10px 0;
    box-shadow: 0 0 2px  #fff inset;    
  }

  .setting-tab.setting-active {
    z-index:40;
    position:relative;
    padding-bottom:1px;
  }
  .setting-tab.setting-active .setting-tab-box{
    background-color: var(--active-tab-bg-color);
    box-shadow: 0 0 2px 0 #fff inset;
    color: var(--active-tab-color);
  }
  .settings-dialog i.spinner {
    position: absolute;
    float: right;
    right: 118px;
    top: 25px;
    z-index: 1;
    font-size: 19px;
    color: #909;
}
.settings-container .loading-modal{
    position: fixed;
    width: auto;
    z-index: 9999999;
    width: 100%;
    margin: -15px 0 0 -16px;
    border-radius: 6px;
    text-align: center;
    height: 100%;
    top: 15px;
    font-size: 13px;
    background-color: rgba(255, 255, 255, 0.8);
}
.loading-modal span{
    position: absolute;
    color: var(--primary-color);
    top: 50%;
    left: 50%;
    padding: 15px;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,0%);
}

.loading-modal > div{
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 15px;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

.hide-loading{
    height:0;
}

/* white label settings css change start */
.settings-dialog.organization-dialog{
    width:855px !important;
    min-height: 700px !important;
}
.settings-dialog.organization-dialog .modal-body{
    overflow-x: hidden;
}
.organization-dialog .sliderLabel{
    font-size: 12px;
    width:150px;
    text-align: center;
    position: absolute;
}

.settings-dialog.organization-dialog .content-area{
    margin-top: 37px;
    /* overflow: scroll; */
    min-height:261px;
}

.settings-dialog.organization-dialog .content-area .has-feedback-left{
    padding-left: 2px;
}
.active-slider{
    border: 1px solid #ac3cbf;
    opacity:1;
}
.non-active-slider{
    border: 1px solid #8b8b8b;
    opacity:0.3;
}
.active-slider-font{
    font-weight: bold;
    font-size: 11px !important;
}
.active-slider-title{
    color: var(--pageTitle-font-color);
}
.toolTipAnchor{
    position: absolute;
    cursor: pointer;
}
.customToolTip{
    position: absolute;
    left: 0;
    top: 2px;
    z-index: 5000;
    -webkit-transform: translate(-23%,30px);
            transform: translate(-23%,30px);
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid lightgrey;
    box-shadow: 2px 2px #f0f0f0;
}
.customToolTip:before, .customToolTip:after {
    content: "";
    display: block;
    height: 0;
    left: 20%;
    position: absolute;
    width: 0;
}
.customToolTip:before {
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 18px solid lightgray;
    margin-left: -5px;
    top: -18px;
    z-index: 4;
}
.customToolTip:after {
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    border-bottom: 17px solid #fff;
    margin-left: -3px;
    top: -17px;
    z-index: 5;
}

#show-footer{
    padding: 2px 15px 15px 15px;
}
.footerSwitchWrapper{
    display: flex;
    align-items: center;
}
.footerMessage{
    margin-left: 25px;
    background-color:#f0f0f0;
    padding: 5px 15px;
    font-size: 12px;
    color: #666666;
}
.footerMessage i {
    margin-right: 10px;
}
.footerConfigWrapper {
    margin-top: 20px;
    position: relative;
    color: #666666 !important;
}
.footerConfigWrapper button{
    margin-left: auto;
}
.footerOverlay{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #FFF;
    opacity: .8;
}
.footerConfigHeader{
    display: flex;
    align-items: center;
}
.footerConfigHeader h5{
    margin: 0px;
}
.noFooterMessage{
    text-align: center;
    font-size: 12px;
}
.footerTable{
    width: 100%;
}
.footerTableHeader div{
    color:var(--primary-color);
    font-size: 12px;
    padding-left:7px;
}
.footerTableRow, .footerTableHeader{
    display: grid;
    width: 100%;
    padding: 7px 0px;
    grid-template-columns: 1fr 1fr 25px;
    align-items: center;
}
.footerTableHeader{
    padding: 0px;
}
.footerTableRow.even{
    background-color: #f0f0f0;
}
.footerTableRow.odd{
    background-color: #ffffff;
}
.footerTableRow input{
    width: 95%;
    outline:rgba(255, 255, 255, 0.8);
    border: 1px solid #ccc;
    height: 30px;
}
.footerTableRow div{
    padding: 0px 5px;
}
.footerError{
    display: none;
    grid-column-end: span 2;
    grid-template-columns: 1fr 1fr;
}
/* white label settings css change end */
.container {
	width: 100%;
	padding: 0
}

.body {
	color: var(--main-font-color);
	font-size: var(--main-font-size);
	font-weight: 400;
	line-height: 1.471
}

.nav-sm .main_container .top_nav {
	display: block;
	z-index: 2
}
.main_container .top_nav {
	display: block;
}
.menu_section>ul {
	margin-top: 10px
}
@media (max-width:991px) {
	.nav-md .container.body .right_col, .nav-md .container.body .top_nav {
		width: 100%;
		margin: 0
	}
	.nav-md .container.body .right_col {
		width: 100%;
		padding-right: 0
	}
	.right_col {
		padding: 10px!important
	}
}
.navbar-brand, .navbar-nav>li>a {
	color: #ECF0F1!important;
	margin-left: 0!important;
	font-weight: 500;
	line-height: 32px;
}

.detail a, .expand, .jqstooltip, .paging_full_numbers a:hover, a, a:focus, a:hover {
	text-decoration: none
}

.overflow_hidden, .sidebar-widget, .tile, .weather-days .col-sm-2, .x_title h2, table.tile_info td p {
	overflow: hidden
}

.top_nav .nav .open>a, .top_nav .nav .open>a:focus, .top_nav .nav .open>a:hover, .top_nav .nav>li>a:focus, .top_nav .nav>li>a:hover {
	background: var(--header-hover-color);
}


.top_nav .navbar-right {
	margin: 0;
	width: 82%;
	float: right !important;
}
.top_nav .navbar-right li {
	display: inline-block;
	float: right;
	position: static
}
@media (min-width:480px) {
	.top_nav .navbar-right li {
		position: relative
	}
}
.top_nav .dropdown-menu li {
	width: 100%
}
.top_nav .dropdown-menu li a {
	width: 100%;
	padding: 10px;
}
.top_nav li a i {
	font-size: 15px
}
.dropdown-menu > .active > a {
	background-color: var(--primary-color);
	white-space: normal;
}

.dropdown-menu>li>a:hover, .dropdown-menu > .active > a:hover {
	background-color: var(--primary-hover-color);
}
.dropdown-menu {
	box-shadow: none;
	display: none;
	float: left;
	font-size: 12px;
	left: 0;
	list-style: none;
	padding: 0;
	position: absolute;
	text-shadow: none;
	top: 100%;
	z-index: 9998;
	border: 1px solid #D9DEE4;
	border-top-left-radius: 0;
	border-top-right-radius: 0
}
.nav_menu {
	float: left;
	background: var(--header-bg-color);
	border-bottom: 1px solid #D9DEE4;
	margin-bottom: 10px;
	width: 100%;
	position: relative
}
@media (min-width:480px) {
	.nav_menu {
		position: static
	}
}

.nav li.current-page {
	background: var(--current-view-bg-color);
}

.nav li li li.current-page {
	background: 0 0
}

.toggle {
	float: left;
	margin: 0;
	padding-top: 16px;
	width: 50px
}
.toggle a {
	padding: 15px 15px 0;
	margin: 0;
	cursor: pointer
}
.toggle a i {
	font-size: 26px
}


.nav.navbar-nav>li>a {
	color: #222!important;
}

.nav.navbar-nav>li>a.info-number {
	background: #222!important;
}

.navbar-nav .open .dropdown-menu {
	position: absolute;
	background: #fff;
	margin-top: 0;
	border: 1px solid #D9DEE4;
    box-shadow: none;
	right: 0;
	left: auto;
	min-width:90px;
	width:100%;
}
.navbar-nav .open .dropdown-menu.msg_list {
	width: 300px
}

a.user-profile {
	color: #5E6974!important;
	min-width:90px;
}
.user-brand {
	height: 57px;
    background-repeat: no-repeat;
    background-size: 100px;
	background-position: center;
	width: 100px;
}

.user-brand::before:hover{
	background-color: rgba(0, 0, 0, 0.37);
}

.user-brand:hover, .user-brand:focus{
    display: block;
    height: 57px;
    background-repeat: no-repeat !important;
    background-size: 100px !important;
	background-position: center !important;
	text-decoration: none;
}

.user-profile img {
	width: 29px;
	height: 29px;
	border-radius: 50%;
	margin-right: 10px;
	border: 1px Solid #DDD
}

a.user-profile .user-title{
	position: absolute;
	left: 50px;
	top: 22px;
	font-size: 9px;
	color: #AAA;
}

.user-profile .far {
	position: relative;
	left: 5px;
}

.btn-success.active, .btn-success:active, .btn-success:focus, .btn-success:hover, .open .dropdown-toggle.btn-success {
	background: #169F85
}
.btn-dark.active, .btn-dark:active, .btn-dark:focus, .btn-dark:hover, .open .dropdown-toggle.btn-dark {
	color: #FFF;
	background-color: #394D5F;
	border-color: #394D5F
}

.badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #777;
    border-radius: 10px;
}

.info-number .badge {
	font-size: 10px;
	font-weight: 400;
	line-height: 13px;
	padding: 2px 6px;
	position: absolute;
	right: 4px;
	top: 8px
}

.mail_list .badge {
	padding: 3px 6px;
	font-size: 8px;
	background: #BAB7B7
}

.bg-green {
	background: #CC00CC!important;
	border: 1px solid #CC00CC!important;
	color: #fff
}
ul.msg_list li {
	background: #f7f7f7;
	padding: 5px;
	display: flex;
	margin: 6px 6px 0;
	width: 96%!important
}
ul.msg_list li:last-child {
	margin-bottom: 6px;
	padding: 10px
}
ul.msg_list li a {
	padding: 3px 5px!important
}
ul.msg_list li a .image img {
	border-radius: 2px;
	-webkit-border-radius: 2px;
	float: left;
	margin-right: 10px;
	width: 11%
}

ul.msg_list li a .time {
	font-size: 11px;
	font-style: italic;
	font-weight: 700;
	position: absolute;
	right: 35px
}
ul.msg_list li a .message {
	display: block!important;
	font-size: 11px
}
a, .fav-color {
	color: var(--primary-color);
}

a:hover {
	color: var(--primary-hover-color);
	opacity: .7;
}
.nav-sm .menu_section h3, .nav-sm .profile, .nav-sm .menu_section span.far {
	display: none
}
.nav>li>a {
	padding: 11px 15px 12px
}
.navbar-brand, .navigation-nav>li>a {
	color: #ECF0F1!important;
	margin-left: 0!important;
	font-weight: 500;
	line-height: 32px;
}

.navigation-right {
	margin-right: 0
}
.top_nav .navigation-right {
	margin: 0;
	width: 82%;
	float: right
}
.top_nav .navigation-right li {
	display: inline-block;
	float: right;
	position: static
}

.nav.navigation-nav>li>a {
	color: #CCC!important
}
.navigation-nav .open .dropdown-menu {
	position: absolute;
	background: #fff;
	margin-top: 0;
	border: 1px solid #D9DEE4;
    box-shadow: none;
	right: 0;
	left: auto;
	min-width:90px;
}
.navigation-nav .open .dropdown-menu.msg_list {
	width: 300px
}

img {
    vertical-align: middle;
}

.dropdown-menu.list-unstyled.msg_list{
	display: none;
}

.navbar-brand, .navbar-nav>li>a, .site_title {
    color: #ECF0F1!important;
    margin-left: 0!important;
}

.nav.top_menu>li>a, .nav>li>a {
    position: relative;
    display: block;
}

.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus, :active, :focus, :visited, a, a:active, a:focus, a:visited {
    outline: 0;
}

a {
    color: var(--primary-color);
    text-decoration: none;
    background-color: transparent;
}

.detail a, .expand, .jqstooltip, .paging_full_numbers a:hover, .site_title:focus, .site_title:hover, a, a:focus, a:hover {
    text-decoration: none;
}

.user-name {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    height: 22px;
	display: inline-block;
	color: var(--main-font-color)
}

.toprightList .dropdown-menu a{
	padding: 5px 10px !important;
}

/* Support widget - css begins */

div.supportButtonHeader{
	padding: 13px 15px 12px;
    line-height: 32px;
	cursor: pointer;
}
.supportHelpIcon i{
	font-weight: 500;
	padding-right: 5px;
}
/* Support widget - css ends */

/* Maintenance warning - css begins */
div.maintenance_warning {
	padding: 13px 15px 12px;
	line-height: 32px;
	text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
div.maintenance_warning .fa-wrench {
	width:20px;
}

.nav.navbar-nav>li:after{
	content: '';
    position: absolute;
    top: 25%;
    height: 50%;
    border-left: 1px solid #999;
}
/* Maintenance warning - css ends */

/* grid for top header lists  - css begins for Responsive */
@media only screen and (max-width: 480px) {
	#profileOption {
		grid-area: profile-option;
	} 

	#userBrand {
		grid-area: user-brand;
	} 

	#systemMaintenance{
		grid-area: system-maintenance;
	}

	ul.toprightList {
		display: grid;
		grid-row-gap: 2px;
		grid-template-areas: 
			"user-brand profile-option"
			"system-maintenance system-maintenance";
			
	}

	div.maintenance_warning{
		max-width: 280px;
		padding: 0px; 
	}

	ul.toprightList .user-name{
		max-width: 70px;
	}

	.user-brand{
		max-width: 100px;
	}

	.toprightList li:after{
		border-width:0px !important;
	}

	.top_nav .navbar-right li{
		position: relative;
	}

	.main_container{
		grid-template-rows: 92px 1fr !important;
	}
}

@media only screen and (min-width: 480px) and (max-width: 1100px){
	.user-name{
		max-width:100px;
	}
}

@media only screen and (max-width: 350px), screen and (min-width: 880px) and (max-width: 990px){
	div.maintenance_warning{
		max-width:254px;
	}
}

@media only screen and (min-width: 350px) and (max-width: 480px){
	div.maintenance_warning{
		max-width:280px;
	}
}

@media only screen and (min-width: 480px) and (max-width: 700px){
	div.maintenance_warning{
		max-width: 135px;
	} 
}

@media only screen and (min-width: 700px) and (max-width: 795px){
	div.maintenance_warning{
		max-width: 108px;
	}
	
}

@media only screen and (min-width: 795px) and (max-width: 880px){
	div.maintenance_warning{
		max-width: 186px;
	} 
	
}

@media only screen and (min-width: 990px) and (max-width: 1100px){
	div.maintenance_warning{
		max-width: 350px;
	} 
} 

@media only screen and (min-width: 1100px)  {
	div.maintenance_warning{
		max-width: 400px;
	}
	.user-name{
		max-width:150px;
	}
}

/* grid for top header lists  - css ends */

.accCard_cardBeatLoader__2aB0z {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.accCard_card__1qhyj {
    /* min-height: 220px;
    height: 220px;
    padding: 0px 20px 20px 0;
    position: relative; */
    min-height: 220px;
    height: 25vh;
    padding: 10px 0px 0px 0;
    position: relative;
    flex-direction: column;
    transition: -webkit-transform 200ms;
    transition: transform 200ms;
    transition: transform 200ms, -webkit-transform 200ms;
    margin:10px;
}

.accCard_navStyleMd__24PJq .accCard_card__1qhyj {
    height: 25vh;
}

/* iPhone 6, 7, & 8 Plus in portrait */
@media only screen 
and (min-device-width : 414px) 
and (max-device-width : 736px) 
and (orientation : portrait) { 
    .accCard_navStyleSm__1A20i .accCard_card__1qhyj {
        height: 25vh;
    }
}

 /* iPhone 5 & 5S in portrait */
@media only screen 
and (min-width : 320px) 
and (max-width : 568px) 
and (orientation : portrait) {
    .accCard_navStyleSm__1A20i .accCard_card__1qhyj {
        height: 45vh;
    }
}

/* iPhone 6, 7, & 8 in portrait */
@media only screen 
and (min-width : 375px) 
and (max-width : 667px) 
and (orientation : portrait) { 
    .accCard_navStyleSm__1A20i .accCard_card__1qhyj {
        height: 35vh;
    }
}

 /* iphone X */
 @media only screen 
 and (min-width: 375px) 
 and (max-width: 812px) 
 and (-webkit-device-pixel-ratio: 3) 
 and (orientation: portrait) { 
     .accCard_navStyleSm__1A20i .accCard_card__1qhyj {
         height: 25vh;
     }
 }
 
/* ----------- Google Pixel ----------- */
/* Target Portrait and Landscape */
@media only screen and (min-width: 411px) and (max-width: 767px) 
and (orientation : portrait) {
    .accCard_navStyleSm__1A20i .accCard_card__1qhyj {
        height: 31vh;
    }
}

/* ----------- Galaxy S4, S5 and Note 3 ----------- */
/* Portrait */
@media screen 
  and (min-width: 360px) 
  and (max-height: 640px) 
  and (-webkit-device-pixel-ratio: 3) 
  and (orientation: portrait) {
    .accCard_navStyleSm__1A20i .accCard_card__1qhyj {
        height: 40vh;
    }
}

.accCard_cardContainer__2NHie {
    position: relative;
    margin: 35px 10px;
    background: #ffffff;
}

.accCard_canHover__2fUxH .accCard_card__1qhyj:hover {
    /* transform: scale(1.048) translateZ(0); */
    /* transition-duration: 200ms;
    transition-delay: 0ms;
    transition-timing-function: cubic-bezier(0.25,0.1,0.25,1); */
    /* -webkit-font-smoothing: subpixel-antialiased; */
}

/* media queries to making the all cards responsive starts */
@media (max-width: 1200px) {
    .accCard_cardContainer__2NHie {
        margin: 10px auto;
        background: #ffffff;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 20px;
        grid-auto-flow: rows;
        margin-bottom: 20px;
    }
  }
  
  @media (min-width: 1000px) and (max-width: 1200px) {
    .accCard_cardContainer__2NHie {
        margin: 10px auto;
        background: #ffffff;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 20px;
        grid-auto-flow: rows;
        margin-bottom: 20px;
    }
  }
  
  @media (min-width: 900px) and (max-width: 1000px) {
    .accCard_cardContainer__2NHie {
        margin: 10px auto;
        background: #ffffff;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 20px;
        grid-auto-flow: rows;
        margin-bottom: 20px;
    }
  }
  
  @media (min-width: 400px) and (max-width: 900px) {
    .accCard_cardContainer__2NHie {
        margin: 10px auto;
        background: #ffffff;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 20px;
        grid-auto-flow: rows;
        margin-bottom: 20px;
    }
  }
  
  @media (min-width: 0px) and (max-width: 400px) {
    .accCard_cardContainer__2NHie {
        margin: 10px auto;
        background: #ffffff;
        grid-template-columns: 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 20px;
        grid-auto-flow: rows;
        margin-bottom: 20px;
    }
  }
  
/* media queries to making the all cards responsive ends */

textarea {
    position:relative;
    margin-left:0px;
    margin-top:-17px;
    height:100px !important;
    resize:none; 
}
.Select--multi .Select-value {
    background-color: var(--select-menu-bg-color); /* #909; */
    color: var(--select-menu-color); /* #ffffff; */
}

.Select-option.is-selected {
    background-color: var(--select-menu-bg-color); /* #909; */
    color: var(--select-menu-color); /* #ffffff; */
}

.Select-option.is-focused {
    background-color: var(--select-menu-hover-bg-color); /* #909; */
    color: var(--select-menu-hover-color); /* #ffffff; */
}

.Select-clear-zone:hover {
    color: var(--primary-hover-color);
}
.form-control:focus, .Select.is-focused:not(.is-open) > .Select-control, .css-1pahdxg-control {
    border-color: var(--form-control-border) !important; /* #909 */
    outline: 0;
    box-shadow: inset 0 1px 1px var(--form-control-border), 0 0 8px var(--form-control-border) !important;
}

.css-26l3qy-menu {
    z-index: 2 !important;
}

.css-1n7v3ny-option {
    background-color: var(--select-menu-hover-bg-color) !important; /* #C0C; */
    color: var(--select-menu-hover-color) !important; /* #ffffff; */
}
.css-9gakcf-option {
    background-color: var(--select-menu-bg-color) !important; /* #909; */
    }
.css-1rhbuit-multiValue {
    background-color: var(--select-menu-bg-color) !important; /* #909; */
    border: 1px solid var(--select-menu-border-color) !important;
}
.css-12jo7m5 {
    color: var(--select-menu-color) !important; /* #ffffff; */
}
  
.css-1alnv5e {
    border-left: 1px solid var(--select-menu-border-color) !important;
    color: #fff !important;
}

.css-1alnv5e:hover {
    background-color: var(--select-menu-bg-color) !important; /* #909; */
    color: var(--select-menu-hover-color) !important; /* #ffffff; */
}

.css-yk16xz-control {
    border-radius: 2px !important;
}

.css-tlfecz-indicatorContainer .css-tj5bde-Svg:hover, .css-1gtu0rj-indicatorContainer .css-tj5bde-Svg:hover{
    color: var(--primary-hover-color) !important;
    cursor: pointer;
}

.css-1wa3eu0-placeholder{
    color: #CCC;
    font-size: 14px;
    font-weight: 100;
}
form, .createForm_nestedForm__3dlQ2 {
    padding: 0 15px;
}
.modal-title{
    color: var(--primary-color);
}
.modal{
    display: block;
    padding-left: 17px;
}

.modal-body {
    color: var(--main-font-color);
}

.modal-height {
    height: 250px;
}

#modal-loader {
    margin: -15px 0 0 -1px;
}

#modal-loader span{
    padding: 15px 0 0 16px;
}
.modal-footer {
    border: none;
    padding: 0 15px 15px;
}
.modal-footer .btn + .btn {
    margin-bottom: 0;
    margin-right: 15px;
}

.loading-modal-margin {
    margin: 2% 0%;
}

.requiredFieldModal:before{
    content:"*";
    font-size: 16px;
    position:absolute;
    top:1px;
    left:-9px;
}

span.requiredFieldModal {
    font-size: 12px;
    color: var(--main-font-color);
    position:absolute;
    top:39px;
    right:35px;
}

.modal-size {
    width: 70%;
    max-height: 50% !important;
}

.modal-size-50 {
    width: 50%;
    max-height: 50%;
}

.modal-size-35 {
    width: 35%;
    max-height: 35%;
}

@media(max-width:775px){
    .modal-size {
        width: auto;
        margin: auto;
    }
}

.modal-header {
    margin: 0px 15px 0px 10px;
}

.modal-zero-top-padding {
    padding-top: 0px;
}

.modal-dialog{
    overflow-y: initial !important
}
.error-div {
    margin: 15px 0px;
    padding: 10px;
    border: 1px dashed gainsboro;
    text-align: center;
    font-weight: bold;
    color: #C00000; 
}

.settings-modal-tabs {
    height:37px;
    padding: 1px 0 0 0;
    overflow:visible;
    cursor: pointer;
    margin: 0 0 20px 0;
    border-bottom: 1px solid #efefef;
  }
  .settings-modal-tab {
    min-width:150px;
    text-align: center;
    height:36px;
    overflow:hidden;
    float:left;
    margin:0 -33px 0 0;
  }
  .settings-modal-tab-box {
    color: var(--inactive-tab-color);
    background: var(--inactive-tab-bg-color);
    font-size: 14px;
    padding: 10px;
    height:50px;
    border-radius: 4px;
    border:1px solid #ccc;
    margin: 0 23px 0 16px;
    box-shadow: 0 0 2px  #fff inset;    
  }

  .settings-modal-tab.settings-modal-active {
    z-index:40;
    position:relative;
    padding-bottom:1px;
  }
  .settings-modal-tab.settings-modal-active .settings-modal-tab-box{
    background-color: var(--active-tab-bg-color);
    box-shadow: 0 0 2px 0 #fff inset;
    color: var(--active-tab-color);
  }
  i.spinner {
    position: absolute;
    float: right;
    right: 118px;
    top: 30px;
    z-index: 1;
    font-size: 19px;
    color: #909;
}
.left_col {
	background: var(--left-col-bg-color);
}
.nav-sm .container.body .col-md-3.left_col {
	width: auto;
	padding: 0;
	z-index: 99;
}
.nav-sm .container.body .col-md-3.left_col.menu_fixed {
	position: fixed;
	height: 100%
}
.nav-sm .container.body .col-md-3.left_col .mCSB_container, .nav-sm .container.body .col-md-3.left_col .mCustomScrollBox {
	overflow: visible
}
.nav-md .container.body .col-md-3.left_col {
	width: 230px;
	padding: 0;
	z-index: 1
}
.nav-md .container.body .col-md-3.left_col.menu_fixed {
	height: 100%;
	position: fixed
}

.menu_section {
	margin-bottom: 35px
}
.menu_section h3 {
	padding-left: 15px;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: .5px;
	font-weight: 700;
	font-size: 11px;
	margin-bottom: 0;
	margin-top: 0;
	text-shadow: 1px 1px #000
}
.menu_section>ul {
	margin-top: 10px
}
.nav-sm .menu_section {
	margin: 0
}
.nav.side-menu>li {
	position: relative;
	display: block;
	cursor: pointer
}
.nav.side-menu>li>a {
	margin-bottom: 1px;
	width: 100%;
	display: inline-flex;
}
.nav.side-menu>li:not(.active)>a:hover {
	color: #FFF!important;
	background: linear-gradient(#222, #333), #313131 !important;
	box-shadow: rgba(0, 0, 0, .25) 0 1px 0, inset rgba(255, 255, 255, .16) 0 1px 0;
}
.nav.side-menu>li>a:hover, .nav>li>a:focus {
	text-decoration: none;
	background: 0 0
}
.nav.child_menu li.active, .nav.child_menu li:hover {
	background-color: var(--child-menu-hover);
}
.nav.child_menu>li>a, .nav.side-menu>li>a {
	color: var(--left-col-text-color);
	font-weight: 500
}

.nav.side-menu>li:not(.active)>a{
 background-color:	var(--main-menu-bg-color);
}

.nav.side-menu>li.active, .nav.side-menu>li.current-page {
	border-right: 5px solid #333
}
.nav.side-menu>li.active>a {
	text-shadow: rgba(0, 0, 0, .25) 0 -1px 0;
	background: var(--active-menu-bg-color);
	box-shadow: rgba(0, 0, 0, .25) 0 1px 0, inset rgba(255, 255, 255, .16) 0 1px 0;
	color: var(--active-menu-color);;
}
.nav-sm .nav.side-menu li a {
	text-align: center!important;
	font-weight: 400;
	font-size: 10px;
	padding: 10px 5px;
	flex-direction: column;
}
.nav-sm .nav.child_menu li.active, .nav-sm .nav.side-menu li.active-sm {
	border-right: 5px solid;
	border-right-color: var(--active-menu-bg-color);
}
.nav-sm .nav.side-menu li.active-sm ul ul, .nav-sm ul.nav.child_menu ul {
	position: static;
	width: 200px;
	background: 0 0
}
.nav-sm>.nav.side-menu>li.active-sm>a {
	color: #CC00CC!important
}
.nav-sm .nav.side-menu li a i.toggle-up {
	display: none!important
}
.nav-sm .nav.side-menu li a i {
	font-size: 25px!important;
	text-align: center;
	width: 100%!important;
	margin-bottom: 5px
}
.nav.side-menu>li.active .child_menu{
	background: var(--active-child-menu-bg-color);
}
.nav.side-menu>li.active a span.far:before{
	content: "\F054";
}
.nav-sm ul.nav.child_menu {
	left: 100%;
	position: absolute;
	top: 0;
	width: 210px;
	z-index: 4000;
	background: #111;
	max-height: 0;
}
.nav-sm ul.nav.child_menu li {
	padding: 0 10px
}
.nav-sm ul.nav.child_menu li a {
	text-align: left!important
}
/* .nav.child_menu {
	display: none
} */
.nav.child_menu li {
	padding-left: 36px
}
.nav-md ul.nav.child_menu li:before {
	color: #423a44;
	bottom: auto;
	left: 17px;
	top: -4px;
	margin-top: 14px;
	position: absolute;
	right: auto;
	z-index: 1;
	display: inline-block;
	font: normal normal normal 14px/1 Font Awesome\ 5 Pro;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: "\F192"
}
.nav-md ul.nav.child_menu li:after {
	border-left: 1px solid #423a44;
	bottom: 0;
	content: "";
	left: 23px;
	position: absolute;
	top: 0
}
.nav li li.current-page a, .nav.child_menu li li a.active, .nav.child_menu li li a:hover {
	color: var(--active-menu-color);
}
.nav.child_menu li li.active, .nav.child_menu li li:hover {
	background: 0 0
}
.nav-md ul.nav.child_menu li.current-page:before{
	font-weight: 900;
	content: "\F0A9";
	color: #FFF
}
.nav>li>a:focus, .nav>li>a:hover {
	background-color: var(--header-hover-color);
}
.nav.child_menu>li>a {
	color: rgba(255, 255, 255, .5);
	font-size: 12px;
	padding: 9px
}
.nav li.current-page {
	background: var(--current-view-bg-color);
}
.nav li li li.current-page {
	background: 0 0
}
.active a span.far {
	text-align: right!important;
	margin-right: 4px;
	flex: auto;	
}

.block-display {
	max-height: 250px !important;
}

.no-display {
    display: none
}

.slideup, .slidedown {
	max-height: 0;            
	overflow-y: hidden;
	transition: max-height 0.5s ease-in-out;
}

.slidedown {            
	max-height: 250px !important ;                    
}    

.height-zero {
	max-height: 0;            
	overflow-y: hidden;
	transition: max-height 0s ease-in-out;
}

.main_menu span.far {
	float: right;
	text-align: center;
	margin-top: 5px;
	font-size: 10px;
	min-width: inherit;
	color: #C4CFDA
}

.main_menu .far, .main_menu .fal, .main_menu .fad, .main_menu .far, .main_menu .fab {
	width: 26px;
	opacity: .99;
	display: inline-block;
	font-style: normal;
	/* font-weight: 400; */
	font-size: 15px;
	-moz-osx-font-smoothing: grayscale
}

.main_menu_side {
    padding: 0;
    margin-top: -10px;
}

.nav>li {
    position: relative;
    display: block;
}

.height-adjust {
    max-height: 0px;
}

.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav.top_menu>li>a, .nav>li>a {
    position: relative;
    display: block;
}

.x_title_breadcrumbs {
    padding-right: 20px;
	padding-left: 20px;
	margin-top: 10px;
}

.breadcrumbs {
    padding: 0px;
}

.breadcrumbs li {
    display: inline-block;
    padding-right: 5px;
    font-size: 12px;
}

.breadcrumbs li+li:before{
	display: inline-block;
	font: normal normal normal 11px/1 Font Awesome\ 5 Pro;
	content: "\F101";
	padding-right: 3px;
	color: #999;
}

.right_content {
	min-height: 86vh;
}
.nav-md .container.body .col-md-3.left_col {
    display: none;
}

@media (min-width: 700px) {
    .nav-md .container.body .col-md-3.left_col {
        display: block !important;
    }
}

.nav.side-menu>li.expanded ul a {
	color: var(--expanded-childMenu-font-color);
}

.nav.side-menu>li.expanded>a {
	background-color: var(--expanded-menu-bg-color);
}



.nav.side-menu>li.active{
	border-right-color: var(--active-menu-rightBorder-color);
}


/* Software update - css begins */ 
.main_menu_side .softwareWarning{
	color: #E7E7E7;
	background: #737373;
	margin-top:20px;
}

.main_menu_side .softwareWarning .title{
	padding: 10px;
}

.main_menu_side .softwareWarning .fa-laptop-code{
	width:25px;
}

.main_menu_side .softwareWarning .warningMessage{
	padding: 0px 0px 10px 37px;	
}

.nav-sm .softwareWarning{
	max-width: 84px;
}

.nav-sm .softwareWarning .warningMessage{
	padding: 0px 0px 10px 10px !important;	
}

.nav-sm .softwareWarning .fa-laptop-code {
	width: 100%;
	text-align: center;
}
/* Software update - css ends */
footer {
    background: #E3E1DE ;
    display: block;
    min-height: 48px;
    border-top: 1px Solid #CCC;
    /* margin-left: -12px; */
    word-break: break-word;
}

.footer-content {
    text-align: center;
}

.footer-content ul {
    margin: 18px 0 0;
    padding: 0;
}

.footer-content ul li {
    display: inline-block;
    margin-left: 5px;
}


@media (max-width: 670px){
    .footer-content ul {
        margin: 10px 0 0;
    }

   .footer-content li {
        font-size: 10px;
    }

   .footer-content ul>li:nth-last-child(1) {
        display: block;
    }

   .footer-content ul>li:nth-last-child(2) span {
        display: none;
    }
}
.navbar {
    margin-bottom: 0;
}

.nav-sm .navbar.nav_title {
	width: 75.25px;
}
.nav-sm .navbar.nav_title a span {
	display: none
}
.nav-sm .navbar.nav_title a i {
	font-size: 27px;
	margin: 13px 0 0 3px
}

.nav_title {
	width: 230px;
	float: left;
	background: var(--navtitle-bg-color);
	border-radius: 0;
    height: 57px;
	border: 0;
	padding: 0px 6px;
}

.acc-logo {
	width: 100%;
	height: 55px;
}

.site_title {
	display: block;
    height: 57px;
    background-repeat: no-repeat;
    background-size: contain;
	background-position: center;
}

.nav-md .site_title:focus, .nav-md .site_title:hover{
	color: var(--primary-color);
}

.nav-md .site_title {
	display: block;
    height: 57px;
    background-repeat: no-repeat;
    background-size: contain;
	background-position: left;
	background-size: 50px;
	margin-left: 2%;
}
.acc-name {
	top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    position: absolute;
	right: 2%;
	color: var(--main-font-color);
}

.err-main {
    background-color: #e3e1de;
    width: 100%;
    height: 100vh;
}

.err-content {
    position: absolute;
    background-color: #ffffff;
    border: transparent;
    width: 50%;
    top: 10%;
    left: 25%;
    height: 300px;
    text-align: center;
}

.err-header {
    text-align: center;
    padding: 2%;
}

.err-icon {
    text-align: center;
    padding-top: 10%;
    font-size: 2em;
    color: #C00000;
}

.browser-err-icon {
    text-align: center;
    padding-top: 4%;
    font-size: 2em;
    color: #C00000;
}

.lower-browser-msg {
    color: var(--primary-color);
    padding: 0 10px;
}


.link-btn {
    text-align: right;
    margin: 15px 20px 11px 0px;
}

@media (min-width: 0px) and (max-width: 400px) {
    .link-btn {
      text-align: center !important;
    }
}

.btn.btn-primary:active, .btn.btn-primary:hover, .btn.btn-primary:focus, .btn.btn-primary:active:hover{
    background-color: var(--btnPrimary-hover-bg-color) ;
    border-color: var(--btnPrimary-default-border-color); 
    color: var(--btnPrimary-hover-color);
}

.btn-primary {
    background-color: var(--btnPrimary-default-bg-color);
    color: var(--btnPrimary-default-color);
    border-color: var(--btnPrimary-default-border-color) !important;
}

.btn.btn-default:hover {
    background-color: var(--btnDefault-hover-bg-color) ;
    border-color: var(--btnDefault-default-border-color); 
    color: var(--btnDefault-default-color);
}

.btn.btn-default {
    background-color: var(--btnDefault-default-bg-color);
    border-color: var(--btnDefault-default-border-color); 
    color: var(--btnDefault-default-color);
}

button#verify {
    margin-top: 22px;
    margin-left: -10px;
}
.partnerIntegration_partnerIntegrateLayout__32iE7{
    background-color: #ffffff;
    padding: 10px 20px;
    padding-left: 2%;
    margin: 2%;
    margin-top: 0;
    position: relative;
    max-width: 80vw;
}

.partnerIntegration_cardLayout__25lHx {
    padding-bottom: 25px;
}

.partnerIntegration_account__gS7m4{
    font-size: 18px;
    color: #555555;
    margin-left: -0.5%;
    margin-bottom: 8px;
}
.partnerIntegration_accountName__3efr0{
    color: #CC00CC;
    font-size: 20px;
    padding: 5px;
}
.partnerIntegration_editPartnerIntegration__3-svm{
    margin-left: 2%;
    background: #ffff;
    margin-right: 2%;
    padding: 20px;
    min-height: 470px;
    position: absolute;
    width: 80%;
    height: auto;
    z-index: 3;
}
.partnerIntegration_overlay__5CvYu{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #F5F5F5;
    opacity: 0.65;
    z-index: 2;
}
.partnerIntegration_editIcon__2Txc2{
    font-size: 14px;
    cursor: pointer;
    margin-left: 7px;
}
.partnerIntegration_editIcon__2Txc2:hover {
    opacity: 0.5;
}
.partnerIntegration_viewabilitytrackers__3UFQW{
    color: #333333;
    font-size: 18px;
}
.partnerIntegration_card__1bqPo{
    height: 180px;
    width: 200px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000029;
    border: 1px solid #EAEAEA;
    border-radius: 4px;
    opacity: 1;
    padding: 10px;
    cursor: pointer;
}
.partnerIntegration_cardArea__1SCW-{
    margin-top: 2%;
}
.partnerIntegration_cardWrapper__2bp7d{
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
}
.partnerIntegration_cardheader__2_xxl{
    color: #CC00CC;
    font-size: 13px;
    border-bottom: 1px solid #F2F2F2;
    opacity: 1;
    padding-bottom: 7px;
}
.partnerIntegration_cardbody__B-o_k{
    display: flex;
    justify-content: center;
    align-items: center;
}
.partnerIntegration_imagewrapper__C-F-G{
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding-top: 15px;
}
.partnerIntegration_card__1bqPo img{
    display: block;
    max-width: 100%;
    max-height: 100%;
}

.partnerIntegration_circleColor__1jSWX {
    color: #71A2FF;
}
.partnerIntegration_viewTrackersForm__3ZYTr{
    padding-top: 2%;
}

.partnerIntegration_formTitle___KLF9{
    background: #EFEFEF 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 45px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
    color: Black;
    align-items: center;
    padding: 12px;
    font-size: 16px;

}

.partnerIntegration_closeIcon__1k6XT {
    cursor: pointer;
    align-self: center;
    justify-self: end;
}

.partnerIntegration_dcmDetails__Qe4MV{
    display: grid;
    grid-template-columns: 120px 200px 120px 200px 200px;
    grid-column-gap: 30px;
    padding: 10px;
}

.partnerIntegration_divHeading__1Ojzc {
    font-size: 13px;
    font-weight: 700;
}

.partnerIntegration_trackersTab__2GyAr {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.partnerIntegration_divValue__7W_tK {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow:hidden;
}

.partnerIntegration_tableCell__-v4Jz {
    display: flex;
    grid-gap: 2px;
    gap: 2px;
}
.partnerIntegration_configurationFormContainer__1vpau {
    margin-top: 20px;
}
.partnerIntegration_configurationForm__3hMH9 {
    margin-bottom: 20px;
}
.partnerIntegration_warningModalBody__2d21M {
    margin: 0 10px;
}
.partnerIntegration_templateAccMessage__3oQHo{
	color: black;
	padding: 2rem 0;
}
