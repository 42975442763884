.partnerIntegrateLayout{
    background-color: #ffffff;
    padding: 10px 20px;
    padding-left: 2%;
    margin: 2%;
    margin-top: 0;
    position: relative;
    max-width: 80vw;
}

.cardLayout {
    padding-bottom: 25px;
}

.account{
    font-size: 18px;
    color: #555555;
    margin-left: -0.5%;
    margin-bottom: 8px;
}
.accountName{
    color: #CC00CC;
    font-size: 20px;
    padding: 5px;
}
.editPartnerIntegration{
    margin-left: 2%;
    background: #ffff;
    margin-right: 2%;
    padding: 20px;
    min-height: 470px;
    position: absolute;
    width: 80%;
    height: auto;
    z-index: 3;
}
.overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #F5F5F5;
    opacity: 0.65;
    z-index: 2;
}
.editIcon{
    font-size: 14px;
    cursor: pointer;
    margin-left: 7px;
}
.editIcon:hover {
    opacity: 0.5;
}
.viewabilitytrackers{
    color: #333333;
    font-size: 18px;
}
.card{
    height: 180px;
    width: 200px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000029;
    border: 1px solid #EAEAEA;
    border-radius: 4px;
    opacity: 1;
    padding: 10px;
    cursor: pointer;
}
.cardArea{
    margin-top: 2%;
}
.cardWrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.cardheader{
    color: #CC00CC;
    font-size: 13px;
    border-bottom: 1px solid #F2F2F2;
    opacity: 1;
    padding-bottom: 7px;
}
.cardbody{
    display: flex;
    justify-content: center;
    align-items: center;
}
.imagewrapper{
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding-top: 15px;
}
.card img{
    display: block;
    max-width: 100%;
    max-height: 100%;
}

.circleColor {
    color: #71A2FF;
}
.viewTrackersForm{
    padding-top: 2%;
}

.formTitle{
    background: #EFEFEF 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 45px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
    color: Black;
    align-items: center;
    padding: 12px;
    font-size: 16px;

}

.closeIcon {
    cursor: pointer;
    align-self: center;
    justify-self: end;
}

.dcmDetails{
    display: grid;
    grid-template-columns: 120px 200px 120px 200px 200px;
    grid-column-gap: 30px;
    padding: 10px;
}

.divHeading {
    font-size: 13px;
    font-weight: 700;
}

.trackersTab {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.divValue {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow:hidden;
}

.tableCell {
    display: flex;
    gap: 2px;
}
.configurationFormContainer {
    margin-top: 20px;
}
.configurationForm {
    margin-bottom: 20px;
}
.warningModalBody {
    margin: 0 10px;
}
.templateAccMessage{
	color: black;
	padding: 2rem 0;
}