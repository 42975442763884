.settings-modal-tabs {
    height:37px;
    padding: 1px 0 0 0;
    overflow:visible;
    cursor: pointer;
    margin: 0 0 20px 0;
    border-bottom: 1px solid #efefef;
  }
  .settings-modal-tab {
    min-width:150px;
    text-align: center;
    height:36px;
    overflow:hidden;
    float:left;
    margin:0 -33px 0 0;
  }
  .settings-modal-tab-box {
    color: var(--inactive-tab-color);
    background: var(--inactive-tab-bg-color);
    font-size: 14px;
    padding: 10px;
    height:50px;
    border-radius: 4px;
    border:1px solid #ccc;
    margin: 0 23px 0 16px;
    box-shadow: 0 0 2px  #fff inset;    
  }

  .settings-modal-tab.settings-modal-active {
    z-index:40;
    position:relative;
    padding-bottom:1px;
  }
  .settings-modal-tab.settings-modal-active .settings-modal-tab-box{
    background-color: var(--active-tab-bg-color);
    box-shadow: 0 0 2px 0 #fff inset;
    color: var(--active-tab-color);
  }
  i.spinner {
    position: absolute;
    float: right;
    right: 118px;
    top: 30px;
    z-index: 1;
    font-size: 19px;
    color: #909;
}