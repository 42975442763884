:root {
  --primary-color: #909;
}
.primary-color {
  color: var(--primary-color);
}
.x_title {
  border-bottom: 2px solid #E6E9ED;
  padding: 0 15px 5px 15px;
}
.notification-success {
  background-color: var(--notification-success-bgColor);
  border-color: var(--notification-success-borderColor);
  color: var(--notification-success-color)
}

.notification-warning {
  background-color: var(--notification-warning-bgColor);
  border-color: var(--notification-warning-borderColor);
  color: var(--notification-warning-color)
}

.notification-error {
  background-color: var(--notification-error-bgColor);
  border-color: var(--notification-error-borderColor);
  color: var(--notification-error-color)
} 

.notification-info {
  background-color: var(--notification-info-bgColor);
  border-color: var(--notification-info-borderColor);
  color: var(--notification-info-color)
}

.show-cursor {
  cursor: pointer;
}

.ln_solid {
  border-top: 1px solid #e5e5e5;
  color: #fff;
  background-color: #fff;
  height: 1px;
  margin: 30px 0;
  padding: 0px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-15 {
  margin-right: 15px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px
}

.margin-top-30 {
  margin-top: 30px
}

.margin-left-10 {
  margin-left: 10px;
}

a {
  cursor: pointer;
}
.no-pointer{
  cursor: not-allowed;
  pointer-events: none;
}
.clear-float:after {
  content: "";
  display: table;
  clear: both;
}

.span-nodisplay {
  visibility: hidden;
  margin-top: 5px;
}

.span-nodisplay[data-display-none="true"] {
  display: none;
}

.divError, .divSuccess, .divWarning {
  display: flex;
  align-items: baseline;
  gap:5px;
}

.divError {
  margin-bottom: 1rem;
  color: #FF0000;
}

.divSuccess {
  color: #4ACA75;
}

.divWarning {
  color: #FF9103;
}

.span-err-margin {
  margin-top: 5px;
}

.alert {
  margin-bottom: 0;
}

#init-modal, #info-modal {
  background: #FFF;
  color: #333;
  opacity: 0.95;
  position: fixed;
  z-index: 24801;
  display: table;
  height: 100%;
  width: 100%;
}

.loader-content{
    display: table-cell;
    vertical-align: middle;
}

.component-content, .loader-content{
    text-align: center;
    color: var(--primary-color);
}

.no-data {
  margin: 15px 0px;
  padding: 10px;
  border: 1px dashed gainsboro;
  text-align: center;
  font-weight: bold;
}

.loader-span {
  display: block;
  margin-top: 0.6%;
  color: var(--primary-color);
}

.margin-0 {
  margin: 0px;
  min-height: 80px;
}
.height-adjust{
  min-height: 75px;
}

.Select-control {
  border-radius: 2px;
}
.form-control {
  color: var(--form-control-text-color);
}
.form-control:focus, .Select.is-focused:not(.is-open) > .Select-control {
  border-color: var(--form-control-border); /* #909 */
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px var(--form-control-border), 0 0 8px var(--form-control-border);
  box-shadow: inset 0 1px 1px var(--form-control-border), 0 0 8px var(--form-control-border);
}

.Select-placeholder, .Select--single > .Select-control .Select-value {
  font-size: 14px;
  color: #999;
}

.Select.has-value.Select--single > .Select-control .Select-value .Select-value-label, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  color: #555;
}

.Select--multi .Select-value{
  margin-top: 6px !important;
}

.tenant-session-msg {
  position: absolute;
  top: 50px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  text-align: center
}

.show {
  display: block;
}

.hide {
  display: none;
}

.blur {
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');
  -webkit-filter: url(#blur-filter);
  filter: url(#blur-filter);
  -webkit-filter: blur(3px);
  filter: blur(3px);
  pointer-events: none;
}

.layout {
  display: grid;
  grid-row-gap: 10px;
  grid-column-gap: 20px;
  margin: 0 20px;
  background: #ffffff;
  grid-template-columns:1fr;
  margin-bottom: 20px;
}

.outlined-div {
    position: relative;
    height: auto;
    border: 2px solid #EEE;
    padding: 5px 10px 5px 15px;
    box-sizing: border-box;
    background-color: #EEE;
    min-height: 400px;
  }
  
  .outlined-div:after{
    position: absolute;
    width: 33px;
    height: 33px;
    border-top: 2px solid #EEE;
    border-right: 2px solid #EEE;
    top: 50%;
    left: 3%;
    margin-left: -27px;
    content: '';
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-top: -25px;
    background: #ffffff;
  }

.info-icon {
  position: relative;
  top: 6px;
  right: 5px;
  color: var(--primary-color);
} 
  
.list-icons {
  margin-right: 10px;
}

.paracontent{
  color: #909;
}

button.is-disabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

.list-btn:hover {
  color: #909;
}

.border-bg-none {
  border: none;
  background-color: inherit;
}

.tab {
    width: 180px;
    float: left;
    margin: 0 -16px 0 0;
    cursor: pointer;
}

.tab1 {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.tab2 {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.trackersTab {
  font-size: 14px;
  text-align: center;
  color: var(--inactive-tab-color);
  background: var(--inactive-tab-bg-color);
  padding-top: 6px;
  height: 35px;
  border: 1px solid #C0C;
  margin: 0 7px 0;
  box-shadow: 0 0 2px #fff inset;
}

.tab.setting-active .trackersTab{
  background-color: #C0C;
  color: #FFF;
}

form.gcmForm {
  padding: 0px !important;
}

.toolTipAnchorClass{
  padding: 20px 10px 0px 10px;
}

.buttonClass{
  display: flex;
  align-items: center; 
  justify-content: flex-start;
}
