.settings-container{
    position: sticky;
    top: -15px;
    z-index: 1;
    background: #FFF;
    overflow-y: hidden;
}
.general-settings{
    overflow-y: auto;
    max-height: 400px;
}
.setting-input{
	width: 290px;
    border: 1px solid #CCC;
    padding-left: 6px;
}

.content-row{
	margin: 8px;
}
.content-row label{
    width: 107px;
    margin-right: 3px;
}

.update-details{
	float: right;
}
.button-row{
	margin-top: 10px;
}
.settings-dialog{
    position: relative;
    left: -8px;
}
.settings-dialog .modal-body{
    overflow:auto;
}
.settings-dialog .modal-button{
    margin-left: 10px;
}
.organization-dialog .modal-body{
    max-height: 80vh;
    min-height: 60vh;
}
.user-address{
    resize: none;
}
.change-password-title{
    margin: 0;
    color: #C0C;
    margin-bottom: 16px;
}
.seperator{
    margin-right: 20px;
}

.update-password-details{
    float:right;
}
/* menu start */


.setting-tabs {
    height:37px;
    padding: 1px 0 0 0;
    overflow:visible;
    cursor: pointer;
    margin: 0 0 20px 0;
    border-bottom: 1px solid #efefef;
  }
  .setting-tab {
    min-width:150px;
    text-align: center;
    height:36px;
    overflow:hidden;
    float:left;
    margin:0 -15px 0 0;
  }
  .setting-tab-box {
    font-size: 14px;
    color: var(--inactive-tab-color);
    background: var(--inactive-tab-bg-color);
    padding: 10px;
    height:50px;
    border-radius: 4px;
    border:1px solid #ccc;
    margin:0 10px 0;
    box-shadow: 0 0 2px  #fff inset;    
  }

  .setting-tab.setting-active {
    z-index:40;
    position:relative;
    padding-bottom:1px;
  }
  .setting-tab.setting-active .setting-tab-box{
    background-color: var(--active-tab-bg-color);
    box-shadow: 0 0 2px 0 #fff inset;
    color: var(--active-tab-color);
  }
  .settings-dialog i.spinner {
    position: absolute;
    float: right;
    right: 118px;
    top: 25px;
    z-index: 1;
    font-size: 19px;
    color: #909;
}
.settings-container .loading-modal{
    position: fixed;
    width: auto;
    z-index: 9999999;
    width: 100%;
    margin: -15px 0 0 -16px;
    border-radius: 6px;
    text-align: center;
    height: 100%;
    top: 15px;
    font-size: 13px;
    background-color: rgba(255, 255, 255, 0.8);
}
.loading-modal span{
    position: absolute;
    color: var(--primary-color);
    top: 50%;
    left: 50%;
    padding: 15px;
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,0%);
}

.loading-modal > div{
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 15px;
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

.hide-loading{
    height:0;
}

/* white label settings css change start */
.settings-dialog.organization-dialog{
    width:855px !important;
    min-height: 700px !important;
}
.settings-dialog.organization-dialog .modal-body{
    overflow-x: hidden;
}
.organization-dialog .sliderLabel{
    font-size: 12px;
    width:150px;
    text-align: center;
    position: absolute;
}

.settings-dialog.organization-dialog .content-area{
    margin-top: 37px;
    /* overflow: scroll; */
    min-height:261px;
}

.settings-dialog.organization-dialog .content-area .has-feedback-left{
    padding-left: 2px;
}
.active-slider{
    border: 1px solid #ac3cbf;
    opacity:1;
}
.non-active-slider{
    border: 1px solid #8b8b8b;
    opacity:0.3;
}
.active-slider-font{
    font-weight: bold;
    font-size: 11px !important;
}
.active-slider-title{
    color: var(--pageTitle-font-color);
}
.toolTipAnchor{
    position: absolute;
    cursor: pointer;
}
.customToolTip{
    position: absolute;
    left: 0;
    top: 2px;
    z-index: 5000;
    transform: translate(-23%,30px);
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid lightgrey;
    box-shadow: 2px 2px #f0f0f0;
}
.customToolTip:before, .customToolTip:after {
    content: "";
    display: block;
    height: 0;
    left: 20%;
    position: absolute;
    width: 0;
}
.customToolTip:before {
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 18px solid lightgray;
    margin-left: -5px;
    top: -18px;
    z-index: 4;
}
.customToolTip:after {
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    border-bottom: 17px solid #fff;
    margin-left: -3px;
    top: -17px;
    z-index: 5;
}

#show-footer{
    padding: 2px 15px 15px 15px;
}
.footerSwitchWrapper{
    display: flex;
    align-items: center;
}
.footerMessage{
    margin-left: 25px;
    background-color:#f0f0f0;
    padding: 5px 15px;
    font-size: 12px;
    color: #666666;
}
.footerMessage i {
    margin-right: 10px;
}
.footerConfigWrapper {
    margin-top: 20px;
    position: relative;
    color: #666666 !important;
}
.footerConfigWrapper button{
    margin-left: auto;
}
.footerOverlay{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #FFF;
    opacity: .8;
}
.footerConfigHeader{
    display: flex;
    align-items: center;
}
.footerConfigHeader h5{
    margin: 0px;
}
.noFooterMessage{
    text-align: center;
    font-size: 12px;
}
.footerTable{
    width: 100%;
}
.footerTableHeader div{
    color:var(--primary-color);
    font-size: 12px;
    padding-left:7px;
}
.footerTableRow, .footerTableHeader{
    display: grid;
    width: 100%;
    padding: 7px 0px;
    grid-template-columns: 1fr 1fr 25px;
    align-items: center;
}
.footerTableHeader{
    padding: 0px;
}
.footerTableRow.even{
    background-color: #f0f0f0;
}
.footerTableRow.odd{
    background-color: #ffffff;
}
.footerTableRow input{
    width: 95%;
    outline:rgba(255, 255, 255, 0.8);
    border: 1px solid #ccc;
    height: 30px;
}
.footerTableRow div{
    padding: 0px 5px;
}
.footerError{
    display: none;
    grid-column-end: span 2;
    grid-template-columns: 1fr 1fr;
}
/* white label settings css change end */