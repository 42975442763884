.navbar {
    margin-bottom: 0;
}

.nav-sm .navbar.nav_title {
	width: 75.25px;
}
.nav-sm .navbar.nav_title a span {
	display: none
}
.nav-sm .navbar.nav_title a i {
	font-size: 27px;
	margin: 13px 0 0 3px
}

.nav_title {
	width: 230px;
	float: left;
	background: var(--navtitle-bg-color);
	border-radius: 0;
    height: 57px;
	border: 0;
	padding: 0px 6px;
}

.acc-logo {
	width: 100%;
	height: 55px;
}

.site_title {
	display: block;
    height: 57px;
    background-repeat: no-repeat;
    background-size: contain;
	background-position: center;
}

.nav-md .site_title:focus, .nav-md .site_title:hover{
	color: var(--primary-color);
}

.nav-md .site_title {
	display: block;
    height: 57px;
    background-repeat: no-repeat;
    background-size: contain;
	background-position: left;
	background-size: 50px;
	margin-left: 2%;
}
.acc-name {
	top: 50%;
    transform: translateY(-50%);
    position: absolute;
	right: 2%;
	color: var(--main-font-color);
}
