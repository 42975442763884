.left_col {
	background: var(--left-col-bg-color);
}
.nav-sm .container.body .col-md-3.left_col {
	width: auto;
	padding: 0;
	z-index: 99;
}
.nav-sm .container.body .col-md-3.left_col.menu_fixed {
	position: fixed;
	height: 100%
}
.nav-sm .container.body .col-md-3.left_col .mCSB_container, .nav-sm .container.body .col-md-3.left_col .mCustomScrollBox {
	overflow: visible
}
.nav-md .container.body .col-md-3.left_col {
	width: 230px;
	padding: 0;
	z-index: 1
}
.nav-md .container.body .col-md-3.left_col.menu_fixed {
	height: 100%;
	position: fixed
}

.menu_section {
	margin-bottom: 35px
}
.menu_section h3 {
	padding-left: 15px;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: .5px;
	font-weight: 700;
	font-size: 11px;
	margin-bottom: 0;
	margin-top: 0;
	text-shadow: 1px 1px #000
}
.menu_section>ul {
	margin-top: 10px
}
.nav-sm .menu_section {
	margin: 0
}
.nav.side-menu>li {
	position: relative;
	display: block;
	cursor: pointer
}
.nav.side-menu>li>a {
	margin-bottom: 1px;
	width: 100%;
	display: inline-flex;
}
.nav.side-menu>li:not(.active)>a:hover {
	color: #FFF!important;
	background: linear-gradient(#222, #333), #313131 !important;
	box-shadow: rgba(0, 0, 0, .25) 0 1px 0, inset rgba(255, 255, 255, .16) 0 1px 0;
}
.nav.side-menu>li>a:hover, .nav>li>a:focus {
	text-decoration: none;
	background: 0 0
}
.nav.child_menu li.active, .nav.child_menu li:hover {
	background-color: var(--child-menu-hover);
}
.nav.child_menu>li>a, .nav.side-menu>li>a {
	color: var(--left-col-text-color);
	font-weight: 500
}

.nav.side-menu>li:not(.active)>a{
 background-color:	var(--main-menu-bg-color);
}

.nav.side-menu>li.active, .nav.side-menu>li.current-page {
	border-right: 5px solid #333
}
.nav.side-menu>li.active>a {
	text-shadow: rgba(0, 0, 0, .25) 0 -1px 0;
	background: var(--active-menu-bg-color);
	box-shadow: rgba(0, 0, 0, .25) 0 1px 0, inset rgba(255, 255, 255, .16) 0 1px 0;
	color: var(--active-menu-color);;
}
.nav-sm .nav.side-menu li a {
	text-align: center!important;
	font-weight: 400;
	font-size: 10px;
	padding: 10px 5px;
	flex-direction: column;
}
.nav-sm .nav.child_menu li.active, .nav-sm .nav.side-menu li.active-sm {
	border-right: 5px solid;
	border-right-color: var(--active-menu-bg-color);
}
.nav-sm .nav.side-menu li.active-sm ul ul, .nav-sm ul.nav.child_menu ul {
	position: static;
	width: 200px;
	background: 0 0
}
.nav-sm>.nav.side-menu>li.active-sm>a {
	color: #CC00CC!important
}
.nav-sm .nav.side-menu li a i.toggle-up {
	display: none!important
}
.nav-sm .nav.side-menu li a i {
	font-size: 25px!important;
	text-align: center;
	width: 100%!important;
	margin-bottom: 5px
}
.nav.side-menu>li.active .child_menu{
	background: var(--active-child-menu-bg-color);
}
.nav.side-menu>li.active a span.far:before{
	content: "\f054";
}
.nav-sm ul.nav.child_menu {
	left: 100%;
	position: absolute;
	top: 0;
	width: 210px;
	z-index: 4000;
	background: #111;
	max-height: 0;
}
.nav-sm ul.nav.child_menu li {
	padding: 0 10px
}
.nav-sm ul.nav.child_menu li a {
	text-align: left!important
}
/* .nav.child_menu {
	display: none
} */
.nav.child_menu li {
	padding-left: 36px
}
.nav-md ul.nav.child_menu li:before {
	color: #423a44;
	bottom: auto;
	left: 17px;
	top: -4px;
	margin-top: 14px;
	position: absolute;
	right: auto;
	z-index: 1;
	display: inline-block;
	font: normal normal normal 14px/1 Font Awesome\ 5 Pro;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: "\f192"
}
.nav-md ul.nav.child_menu li:after {
	border-left: 1px solid #423a44;
	bottom: 0;
	content: "";
	left: 23px;
	position: absolute;
	top: 0
}
.nav li li.current-page a, .nav.child_menu li li a.active, .nav.child_menu li li a:hover {
	color: var(--active-menu-color);
}
.nav.child_menu li li.active, .nav.child_menu li li:hover {
	background: 0 0
}
.nav-md ul.nav.child_menu li.current-page:before{
	font-weight: 900;
	content: "\f0a9";
	color: #FFF
}
.nav>li>a:focus, .nav>li>a:hover {
	background-color: var(--header-hover-color);
}
.nav.child_menu>li>a {
	color: rgba(255, 255, 255, .5);
	font-size: 12px;
	padding: 9px
}
.nav li.current-page {
	background: var(--current-view-bg-color);
}
.nav li li li.current-page {
	background: 0 0
}
.active a span.far {
	text-align: right!important;
	margin-right: 4px;
	flex: auto;	
}

.block-display {
	max-height: 250px !important;
}

.no-display {
    display: none
}

.slideup, .slidedown {
	max-height: 0;            
	overflow-y: hidden;
	-webkit-transition: max-height 0.5s ease-in-out;
	-moz-transition: max-height 0.5s ease-in-out;
	-o-transition: max-height 0.5s ease-in-out;
	transition: max-height 0.5s ease-in-out;
}

.slidedown {            
	max-height: 250px !important ;                    
}    

.height-zero {
	max-height: 0;            
	overflow-y: hidden;
	-webkit-transition: max-height 0s ease-in-out;
	-moz-transition: max-height 0s ease-in-out;
	-o-transition: max-height 0s ease-in-out;
	transition: max-height 0s ease-in-out;
}

.main_menu span.far {
	float: right;
	text-align: center;
	margin-top: 5px;
	font-size: 10px;
	min-width: inherit;
	color: #C4CFDA
}

.main_menu .far, .main_menu .fal, .main_menu .fad, .main_menu .far, .main_menu .fab {
	width: 26px;
	opacity: .99;
	display: inline-block;
	font-style: normal;
	/* font-weight: 400; */
	font-size: 15px;
	-moz-osx-font-smoothing: grayscale
}

.main_menu_side {
    padding: 0;
    margin-top: -10px;
}

.nav>li {
    position: relative;
    display: block;
}

.height-adjust {
    max-height: 0px;
}

.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav.top_menu>li>a, .nav>li>a {
    position: relative;
    display: block;
}

.x_title_breadcrumbs {
    padding-right: 20px;
	padding-left: 20px;
	margin-top: 10px;
}

.breadcrumbs {
    padding: 0px;
}

.breadcrumbs li {
    display: inline-block;
    padding-right: 5px;
    font-size: 12px;
}

.breadcrumbs li+li:before{
	display: inline-block;
	font: normal normal normal 11px/1 Font Awesome\ 5 Pro;
	content: "\f101";
	padding-right: 3px;
	color: #999;
}

.right_content {
	min-height: 86vh;
}
.nav-md .container.body .col-md-3.left_col {
    display: none;
}

@media (min-width: 700px) {
    .nav-md .container.body .col-md-3.left_col {
        display: block !important;
    }
}

.nav.side-menu>li.expanded ul a {
	color: var(--expanded-childMenu-font-color);
}

.nav.side-menu>li.expanded>a {
	background-color: var(--expanded-menu-bg-color);
}



.nav.side-menu>li.active{
	border-right-color: var(--active-menu-rightBorder-color);
}


/* Software update - css begins */ 
.main_menu_side .softwareWarning{
	color: #E7E7E7;
	background: #737373;
	margin-top:20px;
}

.main_menu_side .softwareWarning .title{
	padding: 10px;
}

.main_menu_side .softwareWarning .fa-laptop-code{
	width:25px;
}

.main_menu_side .softwareWarning .warningMessage{
	padding: 0px 0px 10px 37px;	
}

.nav-sm .softwareWarning{
	max-width: 84px;
}

.nav-sm .softwareWarning .warningMessage{
	padding: 0px 0px 10px 10px !important;	
}

.nav-sm .softwareWarning .fa-laptop-code {
	width: 100%;
	text-align: center;
}
/* Software update - css ends */