.err-main {
    background-color: #e3e1de;
    width: 100%;
    height: 100vh;
}

.err-content {
    position: absolute;
    background-color: #ffffff;
    border: transparent;
    width: 50%;
    top: 10%;
    left: 25%;
    height: 300px;
    text-align: center;
}

.err-header {
    text-align: center;
    padding: 2%;
}

.err-icon {
    text-align: center;
    padding-top: 10%;
    font-size: 2em;
    color: #C00000;
}

.browser-err-icon {
    text-align: center;
    padding-top: 4%;
    font-size: 2em;
    color: #C00000;
}

.lower-browser-msg {
    color: var(--primary-color);
    padding: 0 10px;
}

