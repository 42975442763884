.Select--multi .Select-value {
    background-color: var(--select-menu-bg-color); /* #909; */
    color: var(--select-menu-color); /* #ffffff; */
}

.Select-option.is-selected {
    background-color: var(--select-menu-bg-color); /* #909; */
    color: var(--select-menu-color); /* #ffffff; */
}

.Select-option.is-focused {
    background-color: var(--select-menu-hover-bg-color); /* #909; */
    color: var(--select-menu-hover-color); /* #ffffff; */
}

.Select-clear-zone:hover {
    color: var(--primary-hover-color);
}
.form-control:focus, .Select.is-focused:not(.is-open) > .Select-control, .css-1pahdxg-control {
    border-color: var(--form-control-border) !important; /* #909 */
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px var(--form-control-border), 0 0 8px var(--form-control-border) !important;
    box-shadow: inset 0 1px 1px var(--form-control-border), 0 0 8px var(--form-control-border) !important;
}

.css-26l3qy-menu {
    z-index: 2 !important;
}

.css-1n7v3ny-option {
    background-color: var(--select-menu-hover-bg-color) !important; /* #C0C; */
    color: var(--select-menu-hover-color) !important; /* #ffffff; */
}
.css-9gakcf-option {
    background-color: var(--select-menu-bg-color) !important; /* #909; */
    }
.css-1rhbuit-multiValue {
    background-color: var(--select-menu-bg-color) !important; /* #909; */
    border: 1px solid var(--select-menu-border-color) !important;
}
.css-12jo7m5 {
    color: var(--select-menu-color) !important; /* #ffffff; */
}
  
.css-1alnv5e {
    border-left: 1px solid var(--select-menu-border-color) !important;
    color: #fff !important;
}

.css-1alnv5e:hover {
    background-color: var(--select-menu-bg-color) !important; /* #909; */
    color: var(--select-menu-hover-color) !important; /* #ffffff; */
}

.css-yk16xz-control {
    border-radius: 2px !important;
}

.css-tlfecz-indicatorContainer .css-tj5bde-Svg:hover, .css-1gtu0rj-indicatorContainer .css-tj5bde-Svg:hover{
    color: var(--primary-hover-color) !important;
    cursor: pointer;
}

.css-1wa3eu0-placeholder{
    color: #CCC;
    font-size: 14px;
    font-weight: 100;
}