.link-btn {
    text-align: right;
    margin: 15px 20px 11px 0px;
}

@media (min-width: 0px) and (max-width: 400px) {
    .link-btn {
      text-align: center !important;
    }
}

.btn.btn-primary:active, .btn.btn-primary:hover, .btn.btn-primary:focus, .btn.btn-primary:active:hover{
    background-color: var(--btnPrimary-hover-bg-color) ;
    border-color: var(--btnPrimary-default-border-color); 
    color: var(--btnPrimary-hover-color);
}

.btn-primary {
    background-color: var(--btnPrimary-default-bg-color);
    color: var(--btnPrimary-default-color);
    border-color: var(--btnPrimary-default-border-color) !important;
}

.btn.btn-default:hover {
    background-color: var(--btnDefault-hover-bg-color) ;
    border-color: var(--btnDefault-default-border-color); 
    color: var(--btnDefault-default-color);
}

.btn.btn-default {
    background-color: var(--btnDefault-default-bg-color);
    border-color: var(--btnDefault-default-border-color); 
    color: var(--btnDefault-default-color);
}

button#verify {
    margin-top: 22px;
    margin-left: -10px;
}