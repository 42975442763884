footer {
    background: #E3E1DE ;
    display: block;
    min-height: 48px;
    border-top: 1px Solid #CCC;
    /* margin-left: -12px; */
    word-break: break-word;
}

.footer-content {
    text-align: center;
}

.footer-content ul {
    margin: 18px 0 0;
    padding: 0;
}

.footer-content ul li {
    display: inline-block;
    margin-left: 5px;
}


@media (max-width: 670px){
    .footer-content ul {
        margin: 10px 0 0;
    }

   .footer-content li {
        font-size: 10px;
    }

   .footer-content ul>li:nth-last-child(1) {
        display: block;
    }

   .footer-content ul>li:nth-last-child(2) span {
        display: none;
    }
}