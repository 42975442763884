.modal-title{
    color: var(--primary-color);
}
.modal{
    display: block;
    padding-left: 17px;
}

.modal-body {
    color: var(--main-font-color);
}

.modal-height {
    height: 250px;
}

#modal-loader {
    margin: -15px 0 0 -1px;
}

#modal-loader span{
    padding: 15px 0 0 16px;
}
.modal-footer {
    border: none;
    padding: 0 15px 15px;
}
.modal-footer .btn + .btn {
    margin-bottom: 0;
    margin-right: 15px;
}

.loading-modal-margin {
    margin: 2% 0%;
}

.requiredFieldModal:before{
    content:"*";
    font-size: 16px;
    position:absolute;
    top:1px;
    left:-9px;
}

span.requiredFieldModal {
    font-size: 12px;
    color: var(--main-font-color);
    position:absolute;
    top:39px;
    right:35px;
}

.modal-size {
    width: 70%;
    max-height: 50% !important;
}

.modal-size-50 {
    width: 50%;
    max-height: 50%;
}

.modal-size-35 {
    width: 35%;
    max-height: 35%;
}

@media(max-width:775px){
    .modal-size {
        width: auto;
        margin: auto;
    }
}

.modal-header {
    margin: 0px 15px 0px 10px;
}

.modal-zero-top-padding {
    padding-top: 0px;
}

.modal-dialog{
    overflow-y: initial !important
}