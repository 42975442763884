input[type=text][disabled], input[type=simpleInput][disabled] {
    color: #999;
}
.has-feedback-left {
    padding-left: 45px;
}
.form-control {
    width: 100%;
    border-radius: 2px;
    height: 38px;
}
.form-group {
    margin-bottom: 3px;
    padding: 0;
}
.form-group-div {
    padding: 0 15px;
}
.invalid {
    border: 1px solid red;
    background-color: #FDA49A;
}
.form-control-feedback.left {
    border-right: 1px solid #ccc;
    left: 18px;
}
span.optional {
    color: #999;
}
.form-control-feedback {
    top: 3px;
    margin-top: 24px;
    height: 23px;
    color: var(--primary-color);
    line-height: 24px;
    font-size: 15px;
    z-index: 0 !important;
}
.image-selector {
    display: none !important;
}

label.upload-label {
    float: right;
    position: relative;
    top: -37px;
    height: 36px;
    border-radius: 0px;
}

.remove-file, .remove-file-modal {
    position: absolute;
    float: right;
    right: 115px;
    width: 30px;
    font-size: 15px;
    top: 20px;
    z-index: 1;
    height: 38px;
    color: #000;
    border: 1px solid #ccc;
}

.image, .image-modal{
    width: 37px;
    height: 38px;
    position: absolute;
    top: 20px;
    cursor: pointer;
}

.modal-body .remove-file, .modal-body .image{
    top: 21px;
}

.remove-file:hover{
    background: #FFF;
    color: var(--primary-hover-color);

}

input:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0px 9999px white;
    box-shadow: inset 0 0 0px 9999px white;
}

/* ========= */

.user-input-wrp .floating-label {
	position: absolute;
	pointer-events: none;
	top: 28px;
    transition: 0.2s ease all;
    color: #CCC;
    font-size: 14px;
}
.floatinglabel-left-60 {
    left: 60px;
}
.floatinglabel-left-30 {
    left: 30px;
}
.user-input-wrp input:focus ~ .floating-label,
.user-input-wrp input:not(:focus):not(:placeholder-shown) ~ .floating-label, .floating-label.showPrefix {
	top: -2px;
	left: 15px;
    opacity: 1;
    color:#666;
    font-size: 12px;
}
.textLabel{
    color:#666;
    font-weight: normal;
    margin-bottom: 0px;
    position: relative;
    font-size: 12px;
}

.customRadioGroupLabel{
    position: absolute;
    top:10px;
}

.customRadioLabel {
    font-size: 14px !important;
}
.checkboxLabel{
    font-size: 12px !important;
}

.MuiRadio-colorSecondary.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
}

div[role='radiogroup'] .MuiSvgIcon-root {
    font-size: 2rem !important;
}
label {
    color: var(--main-font-color);
    font-size: var(--main-font-size);
    font-weight: inherit;
}
.labelOut{
    top: 0px;
    transition: 0.2s ease all;
    line-height: 0px;
}
.labelIn{
    top: 28px;
	left: 12px;
    transition: 0.2s ease all;
    color: #CCC;
    font-size: 14px;
    line-height: 0px;
}
.form-row{
    min-height: 85px;
}

.dynamic-form-row {
    min-height: 0px;
}
.dynamic-row{
    min-height: 85px;
}
.ext-height{
    min-height: 80px;
}

.clipboard-icon {
    position: absolute;
    right: 25px;
    top: 21px;
    cursor: pointer;
}

.inputColorText{
    margin-left: 11px;
    width: 73%;
    display: inline;
    position: absolute;
    top: 18px;
    left:40px;
}

.color-label{
    position: absolute;
    left: 15px;
    top: 0px;
    color: #666;
    font-weight: normal;
    margin-bottom: 0px;
    font-size: 12px;
    top:0px;
}

/* css for <input type = 'number'> */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  
    cursor: pointer;
    opacity: 1;
}
input[type=number]::-webkit-inner-spin-button {  
    width: 30px;
    height: 100px;
    margin-right:-50px;
}

/* CSS for Prefix in account name inputbox - Begins */
.prefixHolder span.expand{
    background-color: #E4E4E4;
    border-radius: 5px;
    width: 9px;
    word-wrap: break-word;
    line-height: 4px;
    height: 24px;
    padding: 2px;
    border: 1px solid #AAA;
    display: inline-block;
    cursor: pointer;
}

.prefixHolder{
    position: absolute;
    top: 24px;
    left: 58px;
}

.prefixList span{
    display: inline-block;
    padding: 3px 2px;
    background-color: #E4E4E4;
	border-radius: 5px;
}

.user-input-wrp.accountName .has-feedback-left{
    padding-left: var(--left-padding);
}
/* CSS for Prefix in account name inputbox - Ends */

.clientTitle{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    color: #666666;
    margin-top: 2.5%;
}
.viewabilityTrackers{
    font-size: 18px;
    color: #333333;
    margin-left: -2%;
}
.partnerIntegration_editPartnerIntegration__31r1e .content-title.x_title{
    border-bottom: none;
}
.simpleInput{
    background: #EFEFEF 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 45px;
    display: flex;
    align-items: center;
    padding-left: 2%;
}
.simpleInput .form-control{
    width: 300px;
    height: 30px;
}
.asyncSelect-tooltip{
    display: flex;
}
.asyncSelect-tooltip .info{
    margin-left: 3px;
    display: flex;
    align-items: center;
}
.asyncSelect-tooltip .css-2b097c-container,.css-14jk2my-container{
    flex: auto;
}
.select-tooltip{
    display: flex;
}
.select-tooltip .info{
    margin-left: 3px;
    display: flex;
    align-items: center;
}
.select-tooltip .css-2b097c-container,.css-14jk2my-container{
    flex: auto;
}
.moatParamHeader{
    padding: 10px 0px 10px 15px;
}

.trackerParamHeader {
    padding: 10px 0px 0px 15px;
    margin-bottom: 16px;
}

.moatParamSubHeader{
    font-weight: 600;
}

.trackerSectionHeader {
    font-weight: 600;
    color: #333333;
    border-bottom: 1px solid #E6E6E6;
    padding: 0 0 5px;
    margin: 0 45px 15px 15px;
    width: calc(100% - 35px);
}

.trackerUrlBlock {
    padding-bottom: 15px;
    font-size: 12px;
    word-break: break-all;
}

.trackerUrlBlock.active {
    color: var(--primary-hover-color);
}

.trackerUrlBlock.disabled {
    opacity: 50%;
}

.hideElement {
    display: none !important;
}

.helpTextAnchor{
    font-size: 15px;
    margin-left: 5px;
    margin-right: 10px;
}

.customRadioContainer{
    display: flex;
    align-items: center; 
    justify-content: flex-start;
}