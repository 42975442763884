.cardBeatLoader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.card {
    /* min-height: 220px;
    height: 220px;
    padding: 0px 20px 20px 0;
    position: relative; */
    min-height: 220px;
    height: 25vh;
    padding: 10px 0px 0px 0;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    transition: transform 200ms;
    margin:10px;
}

.navStyleMd .card {
    height: 25vh;
}

/* iPhone 6, 7, & 8 Plus in portrait */
@media only screen 
and (min-device-width : 414px) 
and (max-device-width : 736px) 
and (orientation : portrait) { 
    .navStyleSm .card {
        height: 25vh;
    }
}

 /* iPhone 5 & 5S in portrait */
@media only screen 
and (min-width : 320px) 
and (max-width : 568px) 
and (orientation : portrait) {
    .navStyleSm .card {
        height: 45vh;
    }
}

/* iPhone 6, 7, & 8 in portrait */
@media only screen 
and (min-width : 375px) 
and (max-width : 667px) 
and (orientation : portrait) { 
    .navStyleSm .card {
        height: 35vh;
    }
}

 /* iphone X */
 @media only screen 
 and (min-width: 375px) 
 and (max-width: 812px) 
 and (-webkit-device-pixel-ratio: 3) 
 and (orientation: portrait) { 
     .navStyleSm .card {
         height: 25vh;
     }
 }
 
/* ----------- Google Pixel ----------- */
/* Target Portrait and Landscape */
@media only screen and (min-width: 411px) and (max-width: 767px) 
and (orientation : portrait) {
    .navStyleSm .card {
        height: 31vh;
    }
}

/* ----------- Galaxy S4, S5 and Note 3 ----------- */
/* Portrait */
@media screen 
  and (min-width: 360px) 
  and (max-height: 640px) 
  and (-webkit-device-pixel-ratio: 3) 
  and (orientation: portrait) {
    .navStyleSm .card {
        height: 40vh;
    }
}

.cardContainer {
    position: relative;
    margin: 35px 10px;
    background: #ffffff;
}

.canHover .card:hover {
    /* transform: scale(1.048) translateZ(0); */
    /* transition-duration: 200ms;
    transition-delay: 0ms;
    transition-timing-function: cubic-bezier(0.25,0.1,0.25,1); */
    /* -webkit-font-smoothing: subpixel-antialiased; */
}

/* media queries to making the all cards responsive starts */
@media (max-width: 1200px) {
    .cardContainer {
        margin: 10px auto;
        background: #ffffff;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 20px;
        grid-auto-flow: rows;
        margin-bottom: 20px;
    }
  }
  
  @media (min-width: 1000px) and (max-width: 1200px) {
    .cardContainer {
        margin: 10px auto;
        background: #ffffff;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 20px;
        grid-auto-flow: rows;
        margin-bottom: 20px;
    }
  }
  
  @media (min-width: 900px) and (max-width: 1000px) {
    .cardContainer {
        margin: 10px auto;
        background: #ffffff;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 20px;
        grid-auto-flow: rows;
        margin-bottom: 20px;
    }
  }
  
  @media (min-width: 400px) and (max-width: 900px) {
    .cardContainer {
        margin: 10px auto;
        background: #ffffff;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 20px;
        grid-auto-flow: rows;
        margin-bottom: 20px;
    }
  }
  
  @media (min-width: 0px) and (max-width: 400px) {
    .cardContainer {
        margin: 10px auto;
        background: #ffffff;
        grid-template-columns: 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 20px;
        grid-auto-flow: rows;
        margin-bottom: 20px;
    }
  }
  
/* media queries to making the all cards responsive ends */