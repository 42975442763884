.container {
	width: 100%;
	padding: 0
}

.body {
	color: var(--main-font-color);
	font-size: var(--main-font-size);
	font-weight: 400;
	line-height: 1.471
}

.nav-sm .main_container .top_nav {
	display: block;
	z-index: 2
}
.main_container .top_nav {
	display: block;
}
.menu_section>ul {
	margin-top: 10px
}
@media (max-width:991px) {
	.nav-md .container.body .right_col, .nav-md .container.body .top_nav {
		width: 100%;
		margin: 0
	}
	.nav-md .container.body .right_col {
		width: 100%;
		padding-right: 0
	}
	.right_col {
		padding: 10px!important
	}
}
.navbar-brand, .navbar-nav>li>a {
	color: #ECF0F1!important;
	margin-left: 0!important;
	font-weight: 500;
	line-height: 32px;
}

.detail a, .expand, .jqstooltip, .paging_full_numbers a:hover, a, a:focus, a:hover {
	text-decoration: none
}

.overflow_hidden, .sidebar-widget, .tile, .weather-days .col-sm-2, .x_title h2, table.tile_info td p {
	overflow: hidden
}

.top_nav .nav .open>a, .top_nav .nav .open>a:focus, .top_nav .nav .open>a:hover, .top_nav .nav>li>a:focus, .top_nav .nav>li>a:hover {
	background: var(--header-hover-color);
}


.top_nav .navbar-right {
	margin: 0;
	width: 82%;
	float: right !important;
}
.top_nav .navbar-right li {
	display: inline-block;
	float: right;
	position: static
}
@media (min-width:480px) {
	.top_nav .navbar-right li {
		position: relative
	}
}
.top_nav .dropdown-menu li {
	width: 100%
}
.top_nav .dropdown-menu li a {
	width: 100%;
	padding: 10px;
}
.top_nav li a i {
	font-size: 15px
}
.dropdown-menu > .active > a {
	background-color: var(--primary-color);
	white-space: normal;
}

.dropdown-menu>li>a:hover, .dropdown-menu > .active > a:hover {
	background-color: var(--primary-hover-color);
}
.dropdown-menu {
	box-shadow: none;
	display: none;
	float: left;
	font-size: 12px;
	left: 0;
	list-style: none;
	padding: 0;
	position: absolute;
	text-shadow: none;
	top: 100%;
	z-index: 9998;
	border: 1px solid #D9DEE4;
	border-top-left-radius: 0;
	border-top-right-radius: 0
}
.nav_menu {
	float: left;
	background: var(--header-bg-color);
	border-bottom: 1px solid #D9DEE4;
	margin-bottom: 10px;
	width: 100%;
	position: relative
}
@media (min-width:480px) {
	.nav_menu {
		position: static
	}
}

.nav li.current-page {
	background: var(--current-view-bg-color);
}

.nav li li li.current-page {
	background: 0 0
}

.toggle {
	float: left;
	margin: 0;
	padding-top: 16px;
	width: 50px
}
.toggle a {
	padding: 15px 15px 0;
	margin: 0;
	cursor: pointer
}
.toggle a i {
	font-size: 26px
}


.nav.navbar-nav>li>a {
	color: #222!important;
}

.nav.navbar-nav>li>a.info-number {
	background: #222!important;
}

.navbar-nav .open .dropdown-menu {
	position: absolute;
	background: #fff;
	margin-top: 0;
	border: 1px solid #D9DEE4;
    -webkit-box-shadow: none;
    box-shadow: none;
	right: 0;
	left: auto;
	min-width:90px;
	width:100%;
}
.navbar-nav .open .dropdown-menu.msg_list {
	width: 300px
}

a.user-profile {
	color: #5E6974!important;
	min-width:90px;
}
.user-brand {
	height: 57px;
    background-repeat: no-repeat;
    background-size: 100px;
	background-position: center;
	width: 100px;
}

.user-brand::before:hover{
	background-color: rgba(0, 0, 0, 0.37);
}

.user-brand:hover, .user-brand:focus{
    display: block;
    height: 57px;
    background-repeat: no-repeat !important;
    background-size: 100px !important;
	background-position: center !important;
	text-decoration: none;
}

.user-profile img {
	width: 29px;
	height: 29px;
	border-radius: 50%;
	margin-right: 10px;
	border: 1px Solid #DDD
}

a.user-profile .user-title{
	position: absolute;
	left: 50px;
	top: 22px;
	font-size: 9px;
	color: #AAA;
}

.user-profile .far {
	position: relative;
	left: 5px;
}

.btn-success.active, .btn-success:active, .btn-success:focus, .btn-success:hover, .open .dropdown-toggle.btn-success {
	background: #169F85
}
.btn-dark.active, .btn-dark:active, .btn-dark:focus, .btn-dark:hover, .open .dropdown-toggle.btn-dark {
	color: #FFF;
	background-color: #394D5F;
	border-color: #394D5F
}

.badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #777;
    border-radius: 10px;
}

.info-number .badge {
	font-size: 10px;
	font-weight: 400;
	line-height: 13px;
	padding: 2px 6px;
	position: absolute;
	right: 4px;
	top: 8px
}

.mail_list .badge {
	padding: 3px 6px;
	font-size: 8px;
	background: #BAB7B7
}

.bg-green {
	background: #CC00CC!important;
	border: 1px solid #CC00CC!important;
	color: #fff
}
ul.msg_list li {
	background: #f7f7f7;
	padding: 5px;
	display: -ms-flexbox;
	display: flex;
	margin: 6px 6px 0;
	width: 96%!important
}
ul.msg_list li:last-child {
	margin-bottom: 6px;
	padding: 10px
}
ul.msg_list li a {
	padding: 3px 5px!important
}
ul.msg_list li a .image img {
	border-radius: 2px;
	-webkit-border-radius: 2px;
	float: left;
	margin-right: 10px;
	width: 11%
}

ul.msg_list li a .time {
	font-size: 11px;
	font-style: italic;
	font-weight: 700;
	position: absolute;
	right: 35px
}
ul.msg_list li a .message {
	display: block!important;
	font-size: 11px
}
a, .fav-color {
	color: var(--primary-color);
}

a:hover {
	color: var(--primary-hover-color);
	opacity: .7;
}
.nav-sm .menu_section h3, .nav-sm .profile, .nav-sm .menu_section span.far {
	display: none
}
.nav>li>a {
	padding: 11px 15px 12px
}
.navbar-brand, .navigation-nav>li>a {
	color: #ECF0F1!important;
	margin-left: 0!important;
	font-weight: 500;
	line-height: 32px;
}

.navigation-right {
	margin-right: 0
}
.top_nav .navigation-right {
	margin: 0;
	width: 82%;
	float: right
}
.top_nav .navigation-right li {
	display: inline-block;
	float: right;
	position: static
}

.nav.navigation-nav>li>a {
	color: #CCC!important
}
.navigation-nav .open .dropdown-menu {
	position: absolute;
	background: #fff;
	margin-top: 0;
	border: 1px solid #D9DEE4;
    -webkit-box-shadow: none;
    box-shadow: none;
	right: 0;
	left: auto;
	min-width:90px;
}
.navigation-nav .open .dropdown-menu.msg_list {
	width: 300px
}

img {
    vertical-align: middle;
}

.dropdown-menu.list-unstyled.msg_list{
	display: none;
}

.navbar-brand, .navbar-nav>li>a, .site_title {
    color: #ECF0F1!important;
    margin-left: 0!important;
}

.nav.top_menu>li>a, .nav>li>a {
    position: relative;
    display: block;
}

.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus, :active, :focus, :visited, a, a:active, a:focus, a:visited {
    outline: 0;
}

a {
    color: var(--primary-color);
    text-decoration: none;
    background-color: transparent;
}

.detail a, .expand, .jqstooltip, .paging_full_numbers a:hover, .site_title:focus, .site_title:hover, a, a:focus, a:hover {
    text-decoration: none;
}

.user-name {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    height: 22px;
	display: inline-block;
	color: var(--main-font-color)
}

.toprightList .dropdown-menu a{
	padding: 5px 10px !important;
}

/* Support widget - css begins */

div.supportButtonHeader{
	padding: 13px 15px 12px;
    line-height: 32px;
	cursor: pointer;
}
.supportHelpIcon i{
	font-weight: 500;
	padding-right: 5px;
}
/* Support widget - css ends */

/* Maintenance warning - css begins */
div.maintenance_warning {
	padding: 13px 15px 12px;
	line-height: 32px;
	text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
div.maintenance_warning .fa-wrench {
	width:20px;
}

.nav.navbar-nav>li:after{
	content: '';
    position: absolute;
    top: 25%;
    height: 50%;
    border-left: 1px solid #999;
}
/* Maintenance warning - css ends */

/* grid for top header lists  - css begins for Responsive */
@media only screen and (max-width: 480px) {
	#profileOption {
		grid-area: profile-option;
	} 

	#userBrand {
		grid-area: user-brand;
	} 

	#systemMaintenance{
		grid-area: system-maintenance;
	}

	ul.toprightList {
		display: grid;
		grid-row-gap: 2px;
		grid-template-areas: 
			"user-brand profile-option"
			"system-maintenance system-maintenance";
			
	}

	div.maintenance_warning{
		max-width: 280px;
		padding: 0px; 
	}

	ul.toprightList .user-name{
		max-width: 70px;
	}

	.user-brand{
		max-width: 100px;
	}

	.toprightList li:after{
		border-width:0px !important;
	}

	.top_nav .navbar-right li{
		position: relative;
	}

	.main_container{
		grid-template-rows: 92px 1fr !important;
	}
}

@media only screen and (min-width: 480px) and (max-width: 1100px){
	.user-name{
		max-width:100px;
	}
}

@media only screen and (max-width: 350px), screen and (min-width: 880px) and (max-width: 990px){
	div.maintenance_warning{
		max-width:254px;
	}
}

@media only screen and (min-width: 350px) and (max-width: 480px){
	div.maintenance_warning{
		max-width:280px;
	}
}

@media only screen and (min-width: 480px) and (max-width: 700px){
	div.maintenance_warning{
		max-width: 135px;
	} 
}

@media only screen and (min-width: 700px) and (max-width: 795px){
	div.maintenance_warning{
		max-width: 108px;
	}
	
}

@media only screen and (min-width: 795px) and (max-width: 880px){
	div.maintenance_warning{
		max-width: 186px;
	} 
	
}

@media only screen and (min-width: 990px) and (max-width: 1100px){
	div.maintenance_warning{
		max-width: 350px;
	} 
} 

@media only screen and (min-width: 1100px)  {
	div.maintenance_warning{
		max-width: 400px;
	}
	.user-name{
		max-width:150px;
	}
}

/* grid for top header lists  - css ends */
