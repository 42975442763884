.helpText{
    border-radius: 6px !important;
    font-size: 11px !important;
    padding: 5px 10px !important;
    font-weight: bold !important;
	max-width: 30rem;
}

/* 
TODO: Check if any tooltip with position set to bottom is affected.
.helpText.place-bottom:after{
    border-left: 4px solid transparent !important;
    border-right: 4px solid transparent !important;
    left:55% !important;
} */

.image-preview{
    font-size: 20px !important;
    pointer-events: auto !important;
    padding: 10px 10px 0 10px !important;
    width: 250px;
}
.image-preview:hover {
    visibility: visible !important;
    opacity: 1 !important;
}
.image-preview img{
    margin: auto;
    display: block;
    display: block;
    width: 100%;
    height: auto;
}
span.img-name{
    max-width:210px;
    color: var(--primary-color);
    font-size: 12px;
    cursor: pointer;
    white-space: nowrap; 
    text-overflow: ellipsis;
    overflow-x: hidden;
    margin: 0;
    float: left;
}
i.img-name{ 
    width: 10px;
    position: relative;
    top: 2px;
    float:left;
    color: var(--primary-color);
    font-size: 12px;
    cursor: pointer;
}

.type-light{
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 0px 9px #00000066 !important;
    opacity: 1;
    text-align: left;
    font-size: 13px medium;
    letter-spacing: 0px;
    color: #363636 !important;
}
.__react_component_tooltip.place-bottom:after {
    left: 90%;
}